var PageTitleModel = function($log, $rootScope) {
    let titlePrefix = '';
    let titleText = '';
    let titlePostfix = '- Presence';

    this.pageTitle = function() {
        let pageTitle = titlePrefix;
        if(titleText) {
            pageTitle += ' ' + titleText;
        }
        if(titlePostfix) {
            pageTitle += ' ' + titlePostfix;
        }
        return pageTitle;
    };
    this.set = function(title) {
        titleText = title;
        $rootScope.pageTitle = this.pageTitle();
    };
    this.setPrefix = function(prefix) {
        titlePrefix = prefix;
        $rootScope.pageTitle = this.pageTitle();
    };
    this.setPostfix = function(postfix) {
        titlePostfix = postfix;
        $rootScope.pageTitle = this.pageTitle();
    };
};

PageTitleModel.$inject = ['$log', '$rootScope'];
module.exports = PageTitleModel;