var ReviewModel = function($log, drfModel, applications) {

    var Review = new drfModel({
        apiUrl: `${applications.platform.url}/api/v1/review/`,
    });

    Object.assign(Review.Model.prototype, {
    });

    Object.assign(Review.Collection.prototype, {
    });

    return Review;
};

ReviewModel.$inject = ['$log', 'drfModel', 'applications'];
module.exports = ReviewModel;