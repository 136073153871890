"use strict";
var EventQueue = function($log, Snapshot, Events) {
    /**************************************
     * EventQueue
     * @param repo
     * @param methodLatency
     * @constructor
     * ***************************************/
    class EventQueue {
        constructor(repo, methodLatency){
            $log.debug("[Mock] new EventQueue");
            this.repo = repo;
            this._methodLatency = methodLatency;
            this._queue = [];
            this._listeners = {};
            this.flushPending = false;
        }
        triggerFlush() {
            //start the flush counter
            if (!this.flushPending) {
                this.flushPending = true;
                _.delay(_.bind(this.flush, this), this._methodLatency);
            }
        };

        flush() {
            // leave out the onComplete event, that one is treated a bit differently
            // these will be dispatched in the order listed here (first to last)
            var eventNames = [Events.CHILD_ADDED, Events.CHILD_MOVED, Events.CHILD_CHANGED,
                Events.CHILD_REMOVED, Events.VALUE];

            //do the event flush
            //loop through each ref queue in the queue
            //dispatch the deepest refs first
            var onComplete;
            var sortedQueue = _.sortBy(this._queue, function (ref) {
                return ref._url;
            });
            for (var i = sortedQueue.length - 1; i >= 0; i--) {
                var ref = sortedQueue[i];
                var events, j;
                //order of events (added/moved/changed, removed, value)
                for(var k = 0; k<eventNames.length; k++) { //must be deterministic
                    var name = eventNames[k];
                    $log.debug('Flush: ' + name);
                    events = ref._events[name];
                    for(j=0; j<events.length; j++){
                        this.dispatchEvent(ref, events[j]);
                    }
                    ref._events[name] = [];
                }

                var callback = ref._events[Events.ONCOMPLETE];
                if (callback && callback[0]) { //we only take one callback
                    onComplete = callback[0];
                }
                ref._events['onComplete'] = [];

            }
            if (onComplete) {
                this.dispatchOnComplete(onComplete);
            }
            this.cleanQueue();
            this.flushPending = false;
        };

        cleanQueue() {
            $log.debug("[Mock] EventQueue cleaning queue.");
            var queueLen = this._queue.length - 1;
            for (var i = queueLen; i > -1; i--) {
                var ref = this._queue[i];
                if (!this._listeners[ref._url]) {
                    $log.debug("[EventQueue] pruning ref: " + ref._url);
                    this._queue.splice(i, 1);
                }
            }
        };

        addOnComplete(ref, callback) {
            ref._events['onComplete'].push(callback);
        };

        addEvent(ref, event) {
            $log.debug("[Mock] EventQueue addEvent: " + event.type);
            //var ref = event.ref;
            ref._events[event.type].push(event);
            if (!_.includes(this._queue, ref)) {
                this._queue.push(ref)
            }
            this.triggerFlush();
        };


        removeEvent() {
            $log.debug("[Mock] EventQueue removeEvent: " + event);
            throw new Error('Not Implemented');
        };

        setListener(key, callback) {
            this._listeners[key] = callback;
        };

        getListener(key) {
            return this._listeners[key];
        };

        dispatchEvent(ref, event) {
            $log.debug("[Mock] EventQueue dispatchEvent: " + event);
            if (event == null)
                return;

            $log.debug("[Mock] dispatching event: " + event.type);
            var listeners = this._listeners[ref._url];
            if (listeners) {
                var callbacks = listeners[event.type];
                var expiredCallbacks = [];
                if (callbacks) {
                    var i;
                    for (i = 0; i < callbacks.length; i++) {
                        var callback = callbacks[i];
                        callback.callback.apply(callback.context, [event.snapshot, event.previousChildId]);
                        if (!callback.persistent) {
                            $log.debug("removing expired callback");
                            expiredCallbacks.push(callback);
                        }
                    }
                    //remove any one time callbacks
                    for (i = 0; i < expiredCallbacks.length; i++) {
                        var index = _.indexOf(callbacks, expiredCallbacks[i]);
                        callbacks.splice(index, 1);
                    }
                }
            }
        };

        dispatchOnComplete(callback) {
            _.defer(_.bind(callback.callback, callback.ref, _), callback.error);
        };

        generateAddEvent(ref, data) {
            $log.debug("[Mock] generating add event");
            var event = {};
            event.ref = ref;
            event.type = "child_added";
            event.previousChildId = this.repo.findPreviousChildId(ref._url, data);
            event.snapshot = new Snapshot(ref, data);
            //TODO find previousChildId
            return event;
        };

        generateChangeEvent(ref, data) {
            $log.debug("[Mock] generating change event");
            var event = {};
            event.ref = ref;
            event.type = "child_changed";
            event.snapshot = new Snapshot(ref, data);
            return event;
        };

        generateRemoveEvent(ref, data) {
            $log.debug("[Mock] generating remove event");
            var event = {};
            event.ref = ref;
            event.type = "child_removed";
            event.snapshot = new Snapshot(ref, data);
            return event;
        };

        generateMoveEvent(ref, data) {
            $log.debug("[Mock] generating move event");
            var event = {};
            event.ref = ref;
            event.type = "child_moved";
            event.previousChildId = this.repo.findPreviousChildId(ref._url, data);
            event.snapshot = new Snapshot(ref, data);
            return event;
        };

        generateValueEvent(ref, data) {
            $log.debug("[Mock] generating value event");
            var event = {};
            event.ref = ref;
            event.type = "value";
            event.snapshot = new Snapshot(ref, data);
            return event;
        };

        generateOnCompleteCallback(ref, callback, error) {
            var event = {};
            event.ref = ref;
            event.callback = callback;
            event.error = error;
            return event;
        };
    }

    return EventQueue;
};
EventQueue.$inject = ['$log', 'Snapshot', 'Events'];
module.exports = EventQueue;