CreateFlashcardsController.$inject = ['$scope', '$state', 'FlashcardsWizardService'];

function CreateFlashcardsController($scope, $state, FlashcardsWizardService) {
    $scope.panelNavigation.addPanels([{
        title: 'Create/Edit',
        state: 'app.store.activities.create.flashcards.select',
        key: 'select-flashcards',
        locked: (key) => (key === 'select-activity'),
        removeEmptyAssets: removeEmptyAssets
    }, {
        title: 'Description',
        state: 'app.store.activities.create.flashcards.describe',
        key: 'describe-flashcards',
        locked: (key) => (key === 'select-activity' ||
            FlashcardsWizardService.flashcard.images.length == 0 ||
            FlashcardsWizardService.flashcard.images.length == emptyAssetsLength() ||
            !allAssetsUploaded()
            /*|| key === 'select-flashcards'*/ ) // If we want disable description, then we should uncomment condition
    }]);

    /*  Note for future heroes and myself:
        this function is intended to check
        whether there are any images are being uploaded
        or not. Please keep it in mind.
        Thanks :)
     */
    function allAssetsUploaded() {
        var retVal = !_.find(FlashcardsWizardService.flashcard.images,
            (image) => image.showProgress);
        return retVal;
    }

    function emptyAssetsLength() {
        let counter = 0;

        _.forEach(FlashcardsWizardService.flashcard.images, function(image) {
            if(!image.title && !image.thumbnail_url){
                counter++;
            }
        });

        return counter;
    }

    function removeEmptyAssets() {
        FlashcardsWizardService.flashcard.images =
            FlashcardsWizardService.flashcard.images.filter( (image) => {
              return (image.title && image.title != "") ||
                  (image.thumbnail_url && image.thumbnail_url != "");
            });
    }
}

module.exports = CreateFlashcardsController;