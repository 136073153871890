/**
 * The hijack drawer is a UI component that displays a floating bar
 * at the bottom of the screen when a user is in hijack mode. The
 * floating bar indicates the hijacked Principal and provies an
 * affordance to release the hijacked account.
 *
 * Usage is to add the following directive to a template, typically
 * the app global index.html:
 *
 *   <pl-hijack-drawer-directive ng-if="plAppGlobal.user" user="plAppGlobal.user"></pl-hijack-drawer-directive>
 *
 * Note that this relies on plAppGlobal in scope. Use plAppGlobal.setUser()
 * in the Authentication resolver (typically in a route resolve function).
 */
function plHijackDrawer($timeout, $window, currentUserModel, applications, plHijackHelper, plAppGlobal) {
  return {
    restrict: 'E',
    scope: {
      user: '=',
    },
    replace: true,
    templateUrl: '/core/pl-modules/src/plHijack/plHijackDrawer/plHijackDrawer.tpl.html',
    link: ($scope) => {

      document.domain = document.domain;
      if (document.domain.indexOf('presencetest') > -1) {
        document.domain = 'presencetest.com';
      }
      if (plAppGlobal.isStudentMode()) {
        $scope.user = null;
      } else {
        $scope.username = $scope.user.username;
      }

      $scope.isCurrentUserHijacked = () => {
        if (!$scope.user) {
          return false;
        }
        return plHijackHelper.isCurrentUserHijacked($scope.user);
      };

      $scope.onClickTab = () => {
        const isExpanded = plHijackHelper.isDrawerExpanded();
        plHijackHelper.setDrawerExpanded(!isExpanded);

      };

      $scope.isExpanded = () => {
        return plHijackHelper.isDrawerExpanded();
      }

      $scope.onClickReleaseUser = () => {
        $window.location.href = applications.auth.hijackReleaseUrl;
      };
    },
  }
}

plHijackDrawer.$inject = ['$timeout', '$window', 'currentUserModel', 'applications', 'plHijackHelper', 'plAppGlobal'];
module.exports = plHijackDrawer;
