var AssessmentResolver = function(drfAssessmentModel) {
    this.resolve = function(params) {
        var assessment = new drfAssessmentModel.Model();
        var promise = assessment.$getViaSlug(params.slug);
        return promise;
    };
    return this;
};
AssessmentResolver.$inject = ['drfAssessmentModel'];
module.exports = AssessmentResolver ;
