function plGlobalUtil (applications) {
  const thisObj = {};

  thisObj.createCookie = (name, value, days) => {
    const _days = days || 1000;
    var date = new Date();
    date.setTime(date.getTime() + (_days*24*60*60*1000));
    const expires = "; expires=" + date.toUTCString();
    document.cookie = name + "=" + value + expires + `; path=/; domain=${applications.apps.cookieDomain}`;
  };

  thisObj.readCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  };

  return thisObj;
}

plGlobalUtil.$inject = ['applications'];
module.exports = plGlobalUtil;
