function plRecordAppointmentFactory($q, plRecordParticipants, plBillingCodes, plClient,
 currentUserModel) {
    const plRecordAppointment = {};

    // `appointment` could be an event.
    plRecordAppointment.formTitle = (appointment, userUuid1 = null) => {
        const deferred = $q.defer();

        let title = '';
        const userUuid = userUuid1 ? userUuid1 : currentUserModel.user.uuid;
        plRecordParticipants.formRecords(appointment,
         { client: true, billing: true, location: true }, userUuid)
            .then((records) => {
                if (!records || !records.length) {
                    deferred.resolve(title);
                } else {
                    const eventBillingCode = appointment.billing_code || appointment.event.billing_code;
                    const billingName = plBillingCodes.codeInfo(eventBillingCode).name;
                    // buildup a list of client names to populate the appointment title.
                    // NOTE: PII may be denied (e.g. if client was removed from caseload after an appt)
                    const clientNames = [];
                    appointment.clients.reduce((clientNames, itemClient) => {
                      // check current PII access
                      if (itemClient.first_name && itemClient.last_name) {
                        clientNames.push (plClient.formatName(itemClient));
                      }
                      return clientNames;
                    }, clientNames);

                    // TODO (REVISIT): location is record level, but for the time being,
                    // we assume an appointment's records occur at a singular location

                    if (records[0].location_expanded && !clientNames.length) {
                        // Format is [location name] - [billing code name] for location.
                        // E.g. 'Medford Middle School - Parent Contact'
                        const locationName = records[0].location_expanded ? records[0].location_expanded.name : '';
                        title = `${locationName} - ${billingName}`;
                    } else if (clientNames.length) {
                        // Format is [first name last initial., ..] - [billing code name] for clients.
                        // E.g. 'Jimmy H., Bob J. - Parent Contact'
                        const nameString = clientNames.join(', ');
                        title = `${nameString} - ${billingName}`;
                    } else {
                        title = `${billingName}`;
                    }
                    deferred.resolve(title);
                }
            });

        return deferred.promise;
    };

    return plRecordAppointment;
}
plRecordAppointmentFactory.$inject = ['$q', 'plRecordParticipants', 'plBillingCodes', 'plClient',
 'currentUserModel'];
module.exports = plRecordAppointmentFactory;
