function RoomResolver($q, CurrentUser, drfRoomModel, roomnameModel, $stateParams) {
    const deferred = $q.defer();
    const rooms = new drfRoomModel.Collection();
    if (!$stateParams.clinician_username && CurrentUser.isAuthenticated) {
        rooms.$filter({
            user__userprofile__uuid: CurrentUser.uuid,
        });
    } else if($stateParams.clinician_username !== '') {
        rooms.$filter({
            user__username: $stateParams.clinician_username,
        });
    } else {
        deferred.reject('InvalidRoom');
    }

    let room = null;
    rooms.$fetch().then(() => {
        if (rooms.length === 0) {
            deferred.reject('InvalidRoom');
        } else {
            room = rooms[0];
            roomnameModel.value = room;
            deferred.resolve(room);
        }
    }, () => deferred.reject('InvalidRoom'));
    return deferred.promise;
}
RoomResolver.$inject = ['$q', 'CurrentUser', 'drfRoomModel', 'roomnameModel', '$stateParams'];
module.exports = RoomResolver;
