var WjAudioDirective = function ($log, $q, $window, $timeout, $compile, $stateParams, $sce, firebaseModel,
                                 AssessmentModel, currentUserModel, remoteDispatcherService) {
    return {
        restrict: "E",
        replace: true,
        templateUrl: '/core/toys/app/src/toys/modules/assessment/wjaudioassessment/audio/wjAudioDirective.tpl.html',

        link: function ($scope, element, attrs) {

            //plugin for jquery to detect scrollbars
            (function($) {
                $.fn.hasScrollBar = function() {
                    return this.get(0).scrollHeight > this.height();
                }
            })(jQuery);

            const HEADER_HEIGHT = 40;

            $scope.showAudioControls = false;

            AssessmentModel.foundationLoaded.then(init);

            $scope.$on('$destroy', () => {
                refs.state.update({ playing: false});
            });

            function init() {

                $log.debug("[WjAudioDirective init()");
                let descriptor = JSON.parse(AssessmentModel.configModel.descriptor);
                let audio = descriptor['audio'];
                let version = descriptor['version'];
                //setup permissions
                if(currentUserModel.user.isClinicianOrExternalProvider()) {
                    $scope.showAudioControls = true;

                    var w = angular.element('.workspace');

                    $scope.$watch(() => w.width(), _.debounce(function () {
                        $log.debug('[WjAudioDirective] resize');
                        //height
                        let newHeight = w.height() - HEADER_HEIGHT;
                        let audiocontrols = element.find('.audio-controls');
                        let audioelement = element.find('.audio-controls')[0];
                        audioelement.setAttribute("style","height:" + newHeight + "px");
                        //width
                        let hasVScroll = audiocontrols.hasScrollBar();
                        $log.debug('[WjAudioDirective] hasScroll:' + hasVScroll);
                        $scope.hasScroller = hasVScroll;

                    }, 333))

                }

                //setup audio data to render the tracks
                if (version == 1 && audio != null && audio.length > 0) {
                    $scope.getProtectedAudio(audio).then((data) => {
                        $scope.audio = data;
                    });
                }

                $timeout(loadState);

            }

            $scope.audioControls = false;
            $scope.currentTrack = null;
            $scope.currentTime = 0;

            let refs = {};
            function loadState() {
                //get from firebase
                let base = 'activities/sessions/' + AssessmentModel.getSessionId() + '/' + AssessmentModel.activity.configId + "/audio";

                refs.state = AssessmentModel.getRef(base + '/state');
                refs.state.on('value', handleAudioState, handleActivityError);
                setDisconnect();
            }

            function handleAudioState(snap) {
                $log.debug("[WjAudioDirective] handleAudioState");
                let state = snap.val();
                if (state != null && state.trackId != undefined) {
                    //check if we're playing a different track
                    if ($scope.currentId != null && $scope.currentId != state.trackId) {
                        //pause the old one
                        let oldtrack = getAudioElement($scope.currentId);
                        if (oldtrack) {
                            $log.debug("[WjAudioDirective] pausing old element: " + state.trackId);
                            oldtrack.pause();
                        }
                    }
                    //set the local state from the firebase data
                    $scope.currentTime = state.time;
                    $scope.currentId = state.trackId;
                    let audiotrack = getAudioElement(state.trackId);
                    if (audiotrack) {
                        $scope.tracktime = true;
                        watch(audiotrack);
                        //play if it's not already
                        if (state.playing && !isPlaying(audiotrack)) {
                            $log.debug("[WjAudioDirective] firebase state event: playing time: " + state.currentTime);
                            audiotrack.currentTime = $scope.currentTime;
                            $log.debug("[WjAudioDirective] firebase state event: playing id: " +  state.trackId);
                            audiotrack.play();
                            //
                        } else if (!state.playing) {
                            audiotrack.currentTime = $scope.currentTime;
                            $log.debug("[WjAudioDirective] firebase state event: pausing element: " + state.trackId);
                            audiotrack.pause();
                        }
                    }
                }
            };

            function handleActivityError(error) {
                $log.debug("[WjAudioDirective] handleActivityError")
            };

            function setDisconnect() {
                if(currentUserModel.user.isClinician() && refs.state.onDisconnect) {
                    refs.state.onDisconnect().cancel();
                    refs.state.onDisconnect().update({ playing: false});
                }
            };

            function getAudioElement(id) {
                let audiotrack = element.find('.audio-' + id)[0];
                return audiotrack;
            };

            function isPlaying(trackElement) {
                if (trackElement.duration > 0 && !trackElement.paused) {
                    return true;
                } else {
                    return false;
                }
            };

            //HTML AUDIO EVENT HANDLERS
            function handleTimeUpdate(event) {
                let audiotrack = getAudioElement($scope.currentId);
                $scope.currentTime = audiotrack.currentTime;
                let state = {
                    time: $scope.currentTime
                };
                refs.state.update(state);
            };

            function handleEndTrack(id) {
                //this may cause problems if the tracks are not entirely synced
                $scope.pause($scope.currentId);
                let audiotrack = getAudioElement($scope.currentId);
                //this is not synced to the student playback
                if (audiotrack.currentTime != audiotrack.duration) {
                    audiotrack.currentTime = audiotrack.duration;
                }
            }

            function handleSeeked(id) {
            }

            function handlePlaybackEvents(event) {
                //$log.debug("[Audio] playback: " + event.type);
                switch (event.type) {
                    case 'ended':
                        $log.debug("media: ended");
                        handleEndTrack($scope.currentId);
                        break;
                    case 'seeking':
                        $log.debug("media: seeking");
                        break;
                    case 'seeked':
                        $log.debug("media: seeked");
                        handleSeeked($scope.currentId);
                        break;
                    case 'play':
                        $log.debug("media: play");
                        break;
                    case 'pause':
                        $log.debug("media: pause");
                        break;
                }
            };

            function handleStartupEvents(event) {
                $log.debug("[Audio] media: " + event.type);
            };

            function handleMediaErrorEvents(event) {
               //$log.debug("[Audio] media event: " + event.type);
                switch (event.type) {
                    case 'waiting':
                        $log.debug("media: waiting");
                        break;
                    case 'stalled':
                        $log.debug("media: stalled");
                        break;
                    case 'suspended':
                        $log.debug("media: suspended");
                        break;
                }
            };

            let watchedTracks = [];
            function watchAll(trackElement) {
                //$log.debug("[WjAudioDirective] watch: " + trackElement);
                //don't add listeners again
                if (!_.includes(watchedTracks, trackElement)) {
                    watchedTracks.push(trackElement);
                    trackElement.addEventListener('timeupdate', (event) => handleTimeUpdate(event));
                    trackElement.addEventListener('ended', (event) => handlePlaybackEvents(event));
                    trackElement.addEventListener('seeking', (event) => handlePlaybackEvents(event));
                    trackElement.addEventListener('seeked', (event) => handlePlaybackEvents(event));
                    trackElement.addEventListener('play', (event) => handlePlaybackEvents(event));
                    trackElement.addEventListener('pause', (event) => handlePlaybackEvents(event));
                    //startup
                    trackElement.addEventListener('canplaythrough', (event) => handleStartupEvents(event));
                    trackElement.addEventListener('canplay', (event) => handleStartupEvents(event));
                    //errors
                    trackElement.addEventListener('waiting', (event) => handleMediaErrorEvents(event));
                    trackElement.addEventListener('stalled', (event) => handleMediaErrorEvents(event));
                    trackElement.addEventListener('suspended', (event) => handleMediaErrorEvents(event));
                }
            };

            function watch(trackElement) {
                //$log.debug("[WjAudioDirective] watch: " + trackElement);
                //don't add listeners again
                if (!_.includes(watchedTracks, trackElement)) {
                    watchedTracks.push(trackElement);
                    trackElement.addEventListener('play', (event) => handlePlaybackEvents(event));
                    trackElement.addEventListener('pause', (event) => handlePlaybackEvents(event));
                    //startup
                    trackElement.addEventListener('canplaythrough', (event) => handleStartupEvents(event));
                    trackElement.addEventListener('canplay', (event) => handleStartupEvents(event));
                    //errors
                    trackElement.addEventListener('waiting', (event) => handleMediaErrorEvents(event));
                    trackElement.addEventListener('stalled', (event) => handleMediaErrorEvents(event));
                    trackElement.addEventListener('suspended', (event) => handleMediaErrorEvents(event));
                }
            };

            /**
             * Audio assessment mp3's must be protected so we pass each entry to the Model and get back a
             * protected temporary url e.g. protected_url which we can then stream for this session.
             * @param audio (array)
             * @returns promise
             */
            $scope.getProtectedAudio = function(audio) {
                $log.debug("[WjAudioDirective] getProtectedAudio");
                $scope.audioReady = $q.defer();
                for (var i=0; i<audio.length; i++) {
                    let audiotrack = audio[i];
                    audiotrack.ready = false;
                    AssessmentModel.getProtectedContentUrl(audiotrack.src).then( (data) => {
                        audiotrack.protected_url = data.assets[audiotrack.src];
                        audiotrack.ready = true;
                        if (isAudioResolved(audio)) {
                            $scope.audioReady.resolve(audio)
                        }
                    });
                }
                return $scope.audioReady.promise
            };

            function isAudioResolved(audio) {
                if (audio.find(notReady)) {
                    return false
                }
                return true
            }

            function notReady(a) {
                return !a.ready
            }

            $scope.play = function(id) {
                $log.debug("[WjAudioDirective] play: " + id);
                if ($scope.currentId != null && $scope.currentId != id) {
                    //clean up old track
                    let oldtrack = getAudioElement($scope.currentId);
                    if (oldtrack) {
                        $log.debug("[WjAudioDirective] pausing old element: " + id);
                        oldtrack.pause();
                    }
                }
                let audiotrack = getAudioElement(id);
                watchAll(audiotrack);
                setDisconnect();
                let startFromTime = audiotrack.currentTime;
                if (audiotrack.currentTime >= audiotrack.duration) {
                    startFromTime = 0;
                };
                $log.debug("[WjAudioDirective] play from startFromTime: " + startFromTime);
                let state = {
                    trackId: id,
                    playing: true,
                    time: startFromTime
                };
                refs.state.update(state);
            };

            $scope.pause = function(id) {
                if ($scope.currentId == id) {
                    let audiotrack = getAudioElement(id);
                    setDisconnect();
                    let state = {
                        trackId: id,
                        playing: false,
                        time: audiotrack.currentTime
                    };
                    refs.state.update(state);
                }
            };

            $scope.seek = function(id, value) {
                $log.debug("[audio] seek");
                let audiotrack = getAudioElement(id);
                if ($scope.currentId == id && isPlaying(audiotrack)) {
                    //if they did a seek on the currently playing track
                    //we should update the time in fb
                    setDisconnect();
                    let state = {
                        trackId: id,
                        playing: false,
                        time: value
                    };
                    refs.state.update(state);
                } else {
                    audiotrack.currentTime = value;
                    let state = {
                        trackId: id,
                        playing: false,
                        time: value
                    };
                    refs.state.update(state);
                }

            };

            $scope.collapsed = false;
            $scope.collapseToggle = function(collapse) {
                $log.debug("collapse audio player");
                $scope.collapsed = collapse;
            };

            $scope.getTrustedUrl = function(src) {
                return $sce.trustAsResourceUrl(src);
            };
        }
    }
};

WjAudioDirective.$inject = ['$log', '$q', '$window', '$timeout', '$compile', '$stateParams', '$sce', 'firebaseModel', 'AssessmentModel', 'currentUserModel', 'remoteDispatcherService'];
module.exports = WjAudioDirective;

