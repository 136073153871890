"use strict";
Routes.$inject = ['$stateProvider'];

function Routes($stateProvider) {
    $stateProvider
        .state('app.store.activities.create', {
            abstract: true,
            url: '/create',
            views: {
                creation: {
                    templateUrl: '/toychest/modules/store/activityCreation/activityCreation.tpl.html',
                    controller: 'ActivityCreationController'
                }
            }
        })
        .state('app.store.activities.create.selectActivity', {
            url: '/activity',
            templateUrl: '/toychest/modules/store/activityCreation/panels/selectActivity/selectActivity.tpl.html',
            controller: 'SelectActivityController'
        })
        .state('app.store.activities.create.video', {
            url: '/video',
            template: '<div><div ui-view class="activity-creation-panel"></div></div>',
            controller: 'CreateVideoController'
        })
        .state('app.store.activities.create.video.select', {
            url: '/select',
            templateUrl: '/toychest/modules/store/activityCreation/panels/selectVideo/selectVideo.tpl.html',
            controller: 'SelectVideoController'
        })
        .state('app.store.activities.create.video.describe', {
            url: '/describe',
            templateUrl: '/toychest/modules/store/activityCreation/panels/describeActivity/describeActivity.tpl.html',
            controller: 'DescribeActivityController'
        })
        .state('app.store.activities.create.document', {
            url: '/document',
            controller: 'CreateDocumentController',
            template: '<div><div ui-view class="activity-creation-panel"></div></div>',
        })
        .state('app.store.activities.create.document.upload', {
            url: '/upload',
            templateUrl: '/toychest/modules/store/activityCreation/panels/selectPDF/selectPDF.tpl.html',
            controller: 'SelectPDFController'
        })
        .state('app.store.activities.create.document.describe', {
            url: '/describe',
            templateUrl: '/toychest/modules/store/activityCreation/panels/describeActivity/describeActivity.tpl.html',
            controller: 'DescribeActivityController'
        })
        .state('app.store.activities.create.flashcards', {
            url: '/flashcards/:mode/:type/:slug',
            controller: 'CreateFlashcardsController',
            template: '<div><div ui-view class="activity-creation-panel"></div></div>',
            resolve: {
                Flashcard: ['$stateParams', 'activityResolver', 'localStorageService', 'FlashcardsWizardService',
                    function ($stateParams, activityResolver, localStorageService, FlashcardsWizardService) {
                        FlashcardsWizardService.mode = $stateParams.mode;
                        FlashcardsWizardService.type = $stateParams.type;

                        var local = localStorageService.get('FLASHCARD_' + FlashcardsWizardService.mode);

                        if (local && (local.slug === $stateParams.slug)) {
                            return FlashcardsWizardService.flashcard = local;
                        }

                        if ($stateParams.slug) {
                            if (FlashcardsWizardService.flashcard && 'create' === FlashcardsWizardService.mode) {
                                if (FlashcardsWizardService.flashcard.slug === $stateParams.slug) {
                                    return FlashcardsWizardService.flashcard;
                                }
                            }

                            return activityResolver.resolve($stateParams).then(function (flashcard) {
                                let descriptor = JSON.parse(flashcard.descriptor);

                                let activity = JSON.parse(JSON.stringify(flashcard));
                                activity.images = descriptor.cards;

                                return FlashcardsWizardService.flashcard = activity;
                            });
                        }

                        return FlashcardsWizardService.flashcard = {
                            slug: $stateParams.slug,
                            images: []
                        };
                    }
                ]
            }
        })
        .state('app.store.activities.create.flashcards.select', {
            url: '/select',
            templateUrl: '/toychest/modules/store/activityCreation/panels/selectFlashcards/selectFlashcards.tpl.html',
            controller: 'SelectFlashcardsController',
            controllerAs: 'selectFlashcardsController'
        })
        .state('app.store.activities.create.flashcards.describe', {
            url: '/describe',
            templateUrl: '/toychest/modules/store/activityCreation/panels/describeFlashcards/describeFlashcards.tpl.html',
            controller: 'DescribeFlashcardsController',
            controllerAs: 'describeFlashcardsController'
        });
}

module.exports = Routes;