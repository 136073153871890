function plRecords(guidService, plLodash, plRecord) {
    return {
        restrict: 'E',
        scope: {
            event: '=',
            records: '=',
            saveRecord: '&?',
            submitLoading: '=?',
            user: '=',
            reInit: '=?',
        },
        replace: true,
        templateUrl: '/core/pl-records/src/plRecords/plRecords.tpl.html',
        link: ($scope) => {
            function init() {
                $scope.recordOpts = [];
                $scope.selectedRecord = {};
                $scope.selectedRecordUuid = null;
                if ($scope.records && $scope.records.length) {
                    let label;
                    $scope.recordOpts = $scope.records.map((record) => {
                        label = plRecord.formTitle(record);
                        return { value: record._uuid, label };
                    });
                }
            }
            init();
            $scope.$watch('records', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal)) {
                    init();
                }
            });
            $scope.$watch('reInit', (newVal, oldVal) => {
                init();
            });

            $scope.selectRecord = () => {
                const index = plLodash.findIndex($scope.records, '_uuid', $scope.selectedRecordUuid);
                $scope.selectedRecord = (index > -1) ? $scope.records[index] : {};
            };

            $scope.saveRecordWrapper = (record) => {
                $scope.saveRecord()(record);
            };
        },
    };
}

plRecords.$inject = ['guidService', 'plLodash', 'plRecord'];
module.exports = plRecords;
