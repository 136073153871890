function plCheckboxGroup() {
    return {
        restrict: 'E',
        scope: {
            options: '=',
            ngModel: '=',
            horizontal: '&?',
            change: '&?',
            label: '@?',
            classOpt: '@?',
            disabled: '=?',
        },
        replace: true,
        templateUrl: '/core/pl-inputs/src/plCheckboxGroup/plCheckboxGroup.tpl.html',
        link: ($scope) => {
            $scope.getClasses = () => {
                return {
                    horizontal: $scope.horizontal ? true : false,
                };
            };

            $scope.opts = [];

            function init() {
                if (!$scope.ngModel) {
                    $scope.ngModel = [];
                }
                if (!$scope.options || !$scope.options.length) {
                    $scope.opts = [];
                } else {
                    $scope.opts = $scope.options.map((opt) => {
                        return angular.extend({}, opt, {
                            selected: ($scope.ngModel && ($scope.ngModel.indexOf(opt.value)) > -1) ? true : false,
                        });
                    });
                }
            }
            init();

            $scope.$watch('ngModel', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal)) {
                    init();
                }
            // Watcher sometimes does not fire without 3rd argument as true..
            // }, true);
            });
            $scope.$watch('options', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal)) {
                    init();
                }
            });

            $scope.changeOpt = (opt) => {
                const index = $scope.ngModel.indexOf(opt.value);
                if (opt.selected) {
                    if (index < 0) {
                        $scope.ngModel.push(opt.value);
                    }
                } else {
                    if (index > -1) {
                        $scope.ngModel.splice(index, 1);
                    }
                }
                if ($scope.change) {
                    $scope.change();
                }
            };
        },
    };
}

plCheckboxGroup.$inject = [];
module.exports = plCheckboxGroup;
