function plAvailabilityFactory(plGraphQL) {
    const plAvailability = {};

    // =========================
    // Availability 2.0
    // =========================

    // how many days until refresh is required/forced
    const MAX_STALE_DAYS = 30;
    // how many days to show update warning message
    const WARNING_GRACE_PERIOD_DAYS = 5;

    /**
     * get availability block and freshness data
     */
    plAvailability.getUserAvailability = () => {
        return new Promise((resolve, reject) => {
            plGraphQL.query(GQL_GET_AVAILABILITY).then((res) => {
                resolve(res);
            }, (err) => {
                console.log('--- get user availability error', err);
                reject(err);
            });
        })
    }

    /**
     * Get availability freshness info
     * {
     *   userAvailabilityRaw, // obj
     *   daysStale,           // num
     *   daysLeft,            // num
     *   isFirstTimeUser,     // boolean
     *   status,              // obj { code, value }  code: (READY | WARNING | FORCE), value: (0 | 1 | 2)
     * }
     */
    plAvailability.getFreshnessInfo = (userAvailability) => {
        // set some default values
        const result = {
            userAvailabilityRaw: userAvailability,
            isFirstTimeUser: true,
            status: {
                code: "FORCE",
                value: 2,
            }
        };

        const prefs = userAvailability.availabilityPreference;
        if (prefs) {
            const today = moment().startOf('day');
            const lastModified = moment(prefs.modified).startOf('day');
            result.daysStale = today.diff(lastModified, 'days');
            result.daysLeft = MAX_STALE_DAYS - result.daysStale;
            result.isFirstTimeUser = false;
            if (result.daysStale >= MAX_STALE_DAYS - WARNING_GRACE_PERIOD_DAYS && result.daysStale < MAX_STALE_DAYS) {
                result.status = {
                    code: "WARNING",
                    value: 1,
                }
            } else if (result.daysStale < MAX_STALE_DAYS - WARNING_GRACE_PERIOD_DAYS) {
                result.status = {
                    code: "READY",
                    value: 0,
                }
            }
        }
        return result;
    }

    return plAvailability;
}

const GQL_GET_AVAILABILITY = `
  {
    availabilityPreference {
      maxWeeklyHours
      modified
    }
    availabilityBlocks {
      day
      start
      end
      uuid
      modified
    }
  }
`;
plAvailabilityFactory.$inject = ['plGraphQL'];
module.exports = plAvailabilityFactory;