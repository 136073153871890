var Routes = function($stateProvider) {
    $stateProvider
        .state('app.store.lessons', {
            url: '/lessons',
            abstract: true,
            // resolve: {
            //     authenticate: ['$q', '$state', 'currentUserModel', authenticate],
            // },
            views: {
                content: {
                    templateUrl: '/toychest/modules/store/lessons/lessons.tpl.html',
                    controller: 'LessonsController as lessonsCtrl'
                }
            }
        })
        .state('app.store.lessons.all', {
            url: '?q&type&tag',
            views: {
              main: {
                    templateUrl: '/toychest/modules/store/activities/activities.list.tpl.html',
                    controller: 'LessonsListController as listCtrl'
                }
            }
        })
        .state('app.store.lessons.recent', {
            url: '?q&type&tag',
            views: {
              main: {
                    templateUrl: '/toychest/modules/store/activities/activities.list.tpl.html',
                    controller: 'LessonsListController as listCtrl'
                }
            }
        })
        .state('app.store.lessons.detail', {
            url: '/lesson-detail/:slug',
            params: {
                prevState: null
            },
            views: {
                main: {
                    templateUrl: '/toychest/modules/store/lessons/lesson.detail.tpl.html',
                    controller: 'LessonDetailController as detailCtrl'
                }
            },
            resolve: {
                Lesson: ['$stateParams', 'lessonResolver',
                    function($stateParams, lessonResolver) {
                        return lessonResolver.resolve($stateParams);
                    }
                ],
                PreviousState: [
                    '$state',
                    '$stateParams',
                    function($state, $stateParams) {
                        var currentStateData = $stateParams.prevState || {
                                name: $state.current.name,
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                        return currentStateData;
                    }
                ]
            }
        });
};

Routes.$inject = ['$stateProvider'];
module.exports = Routes;