function plInputSearchBelowDirective(plInputSearchService, $timeout, plLodash) {
    return {
        restrict: 'E',
        scope: {
            model: '=',
            opts: '=',
            expandOnTop: '=',
            label: '@?',
            name: '@?',
            max: '=?',
            // min: '=?',
            // required: '=?',
            focus: '&?',
            blur: '&?',
            change: '&?',
            disabled: '=?',
        },
        replace: true,
        templateUrl: '/core/pl-inputs/src/plInputSearch/plInputSearchBelow/plInputSearchBelow.tpl.html',
        link: ($scope, $element, $attrs, formCtrl) => {
            $scope.disabled = ($scope.disabled !== undefined) ? $scope.disabled : false;
            $scope.disabledMax = false;
            $scope.disabledActual = false;
            $scope.modelSearch = {
                selectedOpts: [],
            };

            // If two updates (e.g. model & opts at the same time) happen together, it can
            // cause the selected opts to not be selected, so prevent re-runs when one
            // is already running.
            let initRunning = false;

            function init() {
                if (!initRunning) {
                    setDisabled();
                    initRunning = true;
                    if (!$scope.model || !$scope.model.length) {
                        $scope.model = [];
                    }

                    // Remove any selected opts.
                    $scope.modelSearch.selectedOpts.forEach((opt) => {
                        $scope.removeSelectedOpt(opt, false);
                    });
                    // Use timeout to ensure opts have all been removed first.
                    $timeout(() => {
                        $scope.modelSearch.selectedOpts = [];
                        if ($scope.model && $scope.model.length) {
                            $scope.model.forEach((val) => {
                                let index = plLodash.findIndex($scope.opts, 'value', val);
                                if (index > -1) {
                                    $scope.selectOpt($scope.opts[index]);
                                }
                            });
                        }
                        setValid();
                        initRunning = false;
                    }, 0);
                }
            }

            function setDisabled() {
                $scope.disabledActual = ($scope.disabled || $scope.disabledMax) ? true : false;
            }

            $scope.$watch('model', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal)) {
                    init();
                }
            });
            $scope.$watch('opts', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal)) {
                    init();
                }
            });

            function isValidMax() {
                let valid = true;
                if($scope.max) {
                    $scope.max = parseInt($scope.max, 10);
                }
                if (!$scope.modelSearch.selectedOpts) {
                    $scope.modelSearch.selectedOpts = [];
                }
                if ($scope.max && $scope.modelSearch.selectedOpts.length >= $scope.max) {
                    valid = false;
                    $scope.disabledMax = true;
                } else {
                    $scope.disabledMax = false;
                }
                setDisabled();
                return valid;
            }

            function setValid() {
                // let valid = true;
                // if($scope.min) {
                //     $scope.min = parseInt($scope.min, 10);
                // } else if ($scope.required) {
                //     $scope.min = 1;
                // }
                // if ($scope.min && $scope.modelSearch.selectedOpts.length < $scope.min) {
                //     valid = false;
                //     if ($scope.min) {
                //         $scope.model.$setValidity('min', false);
                //     } else if ($scope.required) {
                //         $scope.model.$setValidity('required', false);
                //     }
                // }
                // return valid;
            }

            $scope.selectOpt = (opt, callback) => {
                const valid = isValidMax();
                if (valid) {
                    plInputSearchService.selectOpt(opt, $scope.opts, $scope.modelSearch.selectedOpts);

                    // Add to model
                    let index = $scope.model.indexOf(opt.value);
                    if (index < 0) {
                        $scope.model.push(opt.value);
                    }

                    // Blank out input.
                    $timeout(() => {
                        $scope.modelSearch.opt = '';
                        // May need to removed disabled since could be valid now.
                        isValidMax();
                        if ($scope.change) {
                            $scope.change();
                        }
                    }, 250);
                } else {
                    let index = $scope.model.indexOf(opt.value);
                    if (index > -1) {
                        $scope.model.splice(index, 1);
                    }
                }
                if (callback) {
                    callback(valid);
                }
            };
            $scope.removeSelectedOpt = (opt, updateModel = true) => {
                if (!$scope.modelSearch.selectedOpts) {
                    $scope.modelSearch.selectedOpts = [];
                }
                plInputSearchService.removeSelectedOpt(opt, $scope.opts, $scope.modelSearch.selectedOpts);

                // Remove from model
                if (updateModel) {
                    let index = $scope.model.indexOf(opt.value);
                    if (index > -1) {
                        $scope.model.splice(index, 1);
                    }
                }

                // May need to removed disabled since could be valid now.
                isValidMax();
                setValid();
                if ($scope.change) {
                    $scope.change();
                }
            };

            init();
        },
    };
}

plInputSearchBelowDirective.$inject = ['plInputSearchService', '$timeout', 'plLodash'];
module.exports = plInputSearchBelowDirective;
