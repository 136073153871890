"use strict";

class GuessWhoCommunicatorController {
    constructor() {
    }

    registerBoard(board) {
        this.board = board;
    }

    registerDrawer(drawer) {
        this.drawer = drawer;
    }

    reset() {
        this.board && this.board.reset();
    }

    setXRay(value) {
        this.board && this.board.setXRay(value);
    }

    setAutoSelect(value) {
        this.board && this.board.setAutoSelect(value);
    }

    getAutoSelect() {
        return this.board && this.board.getAutoSelect();
    }

    getAlertText() {
        return this.board && this.board.getAlertText();
    }
}

GuessWhoCommunicatorController.$inject = [];

function GuessWhoCommunicatorDirective() {
    return {
        scope: true,
        restrict: 'A',
        link: ($scope, $element, $attributes, $controller) => {
            function handler (evt) {
                if (evt.keyCode == 27) {
                    $scope.$evalAsync(() =>
                        $controller.board &&
                        $controller.board.hideMyAvatar &&
                        $controller.board.hideMyAvatar()
                    );
                }
            }

            $(document).on('keydown', handler);

            $scope.$on('$destroy', () => $(document).off('keydown', handler));
        },
        controller: GuessWhoCommunicatorController,
        controllerAs: 'guessWhoCommunicatorController'
    };
}

GuessWhoCommunicatorDirective.$inject = [];

module.exports = GuessWhoCommunicatorDirective;
