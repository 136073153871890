function plButtonGroupDirective() {
    return {
        restrict: 'C',
        link: ($scope, $element, $attrs) => {
            // Copy classes of (first) button to outer element so we can style
            // with the appropriate color. But do NOT copy `pl-button`.
            let classes = $element.children().first().attr('class').replace('pl-button', '');
            $element.addClass(classes);

            // Wrap with another div that is inline-block since the pl-button-group
            // is flexbox and would take the full width otherwise.
            const inlineWrapper = document.createElement('div');
            inlineWrapper.classList.add('pl-button-group-wrapper');
            $element.wrapAll(inlineWrapper);
        },
    };
}

plButtonGroupDirective.$inject = [];
module.exports = plButtonGroupDirective;
