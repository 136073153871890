var jsChannelMock = function($log) {

    var jsChannelMock = function() {
        this.bind = function(){
        };
        this.call = function(){
        };
        this.notify = function() {
        };
    };

    return jsChannelMock;
};

jsChannelMock.$inject = ['$log'];
module.exports = jsChannelMock;