function plSaveEditModalConfig($timeout, plOverlayModal) {
  return {
    init: (modalId, stateObject, selectedObject, initFn) => {
      const config = {};
      config.modalId = modalId;
      config.stateObject = stateObject;
      config.selectedObject = selectedObject;
      config.initFn = initFn;
      config.openAddEditModal = valueObject => {
        config.stateObject.modalMode = valueObject.uuid ? 'Edit' : 'Add';
        Object.assign(config.selectedObject, valueObject);
        config.stateObject.show = true;
        plOverlayModal.show(config.modalId);
        $timeout(()=> {
          config.initFn();
        });
      };
      return config;
    }
  }
}

plSaveEditModalConfig.$inject = ['$timeout', 'plOverlayModal'];
module.exports = plSaveEditModalConfig;
