function plGraphQLFactory(plHttp, plLodash, applications) {
    const plGraphQL = {};

    plGraphQL.query = (query, variables, options1, tokenUsername) => {
        return plGraphQL.go('query', query, variables, options1, tokenUsername);
    };

    plGraphQL.mutate = (query, variables, options1, tokenUsername) => {
        return plGraphQL.go('mutate', query, variables, options1, tokenUsername);
    };

    plGraphQL.go = (type, query, variables, options1, tokenUsername) => {
        const options = Object.assign({}, {
            simplify: true,
        }, options1);
        return new Promise((resolve, reject) => {
            const operationName = plGraphQL.getOperationName(query);
            // const gqlQuery = gql`${query}`;
            const gqlQuery = query;
            const data = {
                operationName: null,
                query: gqlQuery,
                variables: variables || {},
            };

            let apolloUrl = applications.apollo && applications.apollo.url || `${applications.apiWorkplace.url}/graphql/v1/`;
            let httpOpts = {
                url: apolloUrl,
                method: 'POST',
                data: data,
            };

            if (options.debug) {
                console.log(`apllo.${type} query`, tokenUsername, query, JSON.stringify(variables),
                httpOpts.url, httpOpts.method, httpOpts.headers);
            }

            plHttp.go(httpOpts, false).then((res) => {
                if (!res || !res.data) {
                    console.log('no res or no res.data', res);
                }
                const resData = (res && res.data) ? res.data : {};
                if (options.debug) {
                    console.log(`apllo.${type} res`, tokenUsername, resData, query, JSON.stringify(variables));
                }
                plGraphQL.handleResponse(resData, type, resolve, reject, operationName, options);
            }, (err) => {
                if (options.debug) {
                    console.log(`apllo.${type} err`, tokenUsername, err, query, JSON.stringify(variables));
                }
                plGraphQL.handleError(err, {});
                reject(err);
            });
        });
    };

    plGraphQL.getOperationName = (query) => {
        let indexSpace = query.indexOf(' ');
        let indexParen = query.indexOf('(');
        return query.slice((indexSpace + 1), indexParen);
    };

    /**
    Strip out nested `edges` and `node` and make object mutable.
    https://github.com/apollographql/apollo-angular/issues/272
    */
    plGraphQL.toSimpleData = (data1, operationName) => {
        let data = data1.data;
        if (operationName && data[operationName]) {
            data = data[operationName];
        }
        return plGraphQL.simplifyData(data);
    };

    plGraphQL.simplifyData = (item) => {
        let itemCopy;
        if (Array.isArray(item)) {
            itemCopy = item.slice();
            return itemCopy.map((item1) => {
                return plGraphQL.simplifyData(item1);
            });
        } else if (item !== null && typeof item === 'object') {
            itemCopy = {};
            const skip = ['__typename'];
            for (let key in item) {
                if (skip.indexOf(key) < 0) {
                    itemCopy[key] = plLodash.copy(item[key]);
                }
            }
            itemCopy = plGraphQL.stripEdgesAndNodes(itemCopy);
            for (let key in itemCopy) {
                if (skip.indexOf(key) < 0) {
                    itemCopy[key] = plGraphQL.simplifyData(itemCopy[key]);
                } else {
                    delete itemCopy[key];
                }
            }
            return itemCopy;
        }
        return item;
    };

    plGraphQL.stripEdgesAndNodes = (item) => {
        if (item === null || typeof item !== 'object') {
            return item;
        }
        const skip = ['__typename'];
        let hadEdgesOrNode = false;
        for (let xx in item) {
            if (skip.indexOf(xx) > -1) {
                delete item[xx];
            }
            if (item[xx] !== null && typeof item[xx] === 'object') {
                if (item[xx].edges) {
                    for (let yy in item[xx]) {
                        if (yy !== 'edges' && yy !== 'node' && skip.indexOf(yy) < 0) {
                            let prefixedKey = `${xx}_${yy}`;
                            item[prefixedKey] = item[xx][yy];
                        }
                    }
                    if (item[xx].edges) {
                        item[xx] = item[xx].edges;
                        delete item[xx].edges;
                        hadEdgesOrNode = true;
                    }
                }
                if (item.node) {
                    item = item.node;
                    hadEdgesOrNode = true;
                }
            }
        }
        // Since changed structure of item, need to check it again, otherwise
        // could miss a level of edges / node.
        if (hadEdgesOrNode) {
            item = plGraphQL.stripEdgesAndNodes(item);
        }
        return item;
    };

    plGraphQL.handleError = (err, options = { suppressError: false }) => {
        if (!options.suppressError) {
            const message = plGraphQL.getError(err);
            console.log('plGraphQL.handleError', message);
        }
    };

    plGraphQL.findErrors = (res, type) => {
        if (type === 'query') {
            if (res.errors) {
                return res.errors;
            }
        } else if (type === 'mutate') {
            if (res.data) {
                for (let key in res.data) {
                    if (res.data[key].errors) {
                        return res.data[key].errors;
                    }
                }
            }
        }
        return null;
    };

    plGraphQL.handleResponse = (res, type, resolve, reject, operationName, options) => {
        const errors = plGraphQL.findErrors(res, type);
        if (errors) {
            plGraphQL.handleError({ errors: errors });
            reject(res);
        } else {
            if (options.simplify) {
                resolve(plGraphQL.toSimpleData(res, operationName));
            } else {
                resolve(res);
            }
        }
    };

    plGraphQL.getError = (response) => {
        const message = plGraphQL.getGenericError(response);
        return message;
    };

    plGraphQL.getGenericError = (response) => {
        if (response.errors) {
            for (let ii = 0; ii < response.errors.length; ii++) {
                if (response.errors[ii].code) {
                    let code = response.errors[ii].code;
                    if (code === 'authentication_failed' || code === 'not_authenticated') {
                        return 'Unauthorized. Please make sure you are logged in and have '
                        + 'privileges to complete this action.';
                    }
                }
                if (response.errors[ii].message) {
                    return response.errors[ii].message;
                }
            }
        }
        return 'Oops, something went wrong. Please try again. If the problem '
        + 'persists please contact us for support.';
    };

    return plGraphQL;
}
plGraphQLFactory.$inject = ['plHttp', 'plLodash', 'applications'];
module.exports = plGraphQLFactory;

