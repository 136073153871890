var YoutubeDrawerController = function($log, $scope, $stateParams, activityModel) {
    activityModel.foundationLoaded.then(function() {
        console.log('Foundation Loaded');
    });
    $scope.activity = activityModel.activity;
    $scope.send = function() {
        activityModel.channel.call({
            method:'play',
            success: function(v) {
                console.log('playing!!!');
            }
        });
    };
    function init() {
        $log.debug("Youtube Controller");
    };
    init();
};

YoutubeDrawerController.$inject = ['$log', '$scope', '$stateParams', 'activityModel'];
module.exports = YoutubeDrawerController;
