class API {
  constructor(applications, $resource) {
    // api urls with :variables
    const urls = {
      activities: {
        url: `${applications.apiWorkplace.url}/api/v1/activities/`,
        defaults: {
          uuid: '@uuid'
        }
      },
      appointments: {
        url: `${applications.apiWorkplace.url}/api/v1/appointments/`,
      },
      areasOfConcern: {
        url: `${applications.apiWorkplace.url}/api/v1/areas-of-concern/`,
      },
      assessments: {
        url: `${applications.apiWorkplace.url}/api/v1/assessments/`,
      },
      availabilitySettings: {
        url: `${applications.apiWorkplace.url}/api/v1/availability/preferences/`,
      },
      billingCodes: {
        url: `${applications.apiClient.url}/api/v1/billing_codes/`,
      },
      clients: {
        url: `${applications.apiClient.url}/api/v1/clients/:uuid/:objects/`,
        defaults: {
          uuid: '@uuid',
          objects: '@objects'
        },
      },
      clientServices: {
        url: `${applications.apiWorkplace.url}/api/v2/client-services/`,
      },
      clientTransferCheck: {
        url: `${applications.apiWorkplace.url}/api/v1/clients/:uuid/transfer/check/`,
        defaults: {
          uuid: '@uuid'
        }
      },
      contacts: {
        url: `${applications.apiClient.url}/api/v1/contacts/:uuid/`,
        defaults: {
          uuid: '@uuid'
        },
      },
      contact_types: {
        url: `${applications.apiClient.url}/api/v1/contact-types/`,
      },
      directServices: {
        url: `${applications.apiWorkplace.url}/api/v1/direct-services/:uuid/`,
        defaults: {
          uuid: '@uuid'
        },
      },
      documents: {
        url: `${applications.apiWorkplace.url}/api/v1/documents/`,
      },
      document_types: {
        url: `${applications.apiClient.url}/api/v1/document-types/`,
      },
      ethnicities: {
        url: `${applications.apiClient.url}/api/v1/ethnicities/`,
      },
      evaluations: {
        url: `${applications.apiClient.url}/api/v3/evaluations/:uuid/`,
        defaults: {
          uuid: '@uuid'
        },
      },
      evaluation_activities: {
        url: `${applications.apiClient.url}/api/v3/evaluations/:evaluation_uuid/activities/:uuid/`,
        defaults: {
          evaluation_uuid: '@evaluation_uuid',
          uuid: '@uuid'
        },
      },
      events: {
        url: `${applications.apiWorkplace.url}/api/v1/events/`,
      },
      invoices: {
        url: `${applications.apiWorkplace.url}/api/v1/invoices/`,
      },
      invoicesPreview: {
        url: `${applications.apiWorkplace.url}/api/v1/invoices/preview/`,
      },
      languages: {
        url: `${applications.apiClient.url}/api/v1/languages/`,
      },
      locations: {
        url: `${applications.apiClient.url}/api/v1/locations/:uuid/`,
        defaults: {
          uuid: '@uuid'
        },
      },
      metrics: {
        url: `${applications.apiClient.url}/api/v1/metrics/:uuid/`,
        defaults: {
          uuid: '@uuid'
        },
      },
      noms: {
        url: `${applications.apiClient.url}/api/v1/noms/`,
      },
      nomsEntry: {
        url: `${applications.apiClient.url}/api/v1/noms-entry/:uuid/`,
        defaults: {
          uuid: '@uuid'
        },
      },
      notesExport: {
        url: `${applications.apiClient.url}/api/v1/notes/exports/`,
      },
      notesSchemas: {
        url: `${applications.apiClient.url}/api/v1/notes/schemas/:uuid/`,
        defaults: {
          uuid: '@uuid'
        },
      },
      organizations: {
        url: `${applications.apiClient.url}/api/v1/organizations/:uuid/`,
        defaults: {
          uuid: '@uuid'
        },
      },
      permissions: {
        url: `${applications.apiClient.url}/api/v1/permissions/`,
      },
      providerTypes: {
        url: `${applications.apiWorkplace.url}/api/v1/provider-types/`,
      },
      providers: {
        url: `${applications.apiWorkplace.url}/api/v1/providers/:user__uuid/`,
        defaults: {
          user__uuid: '@user__uuid'
        },
      },
      races: {
        url: `${applications.apiClient.url}/api/v1/races/`,
      },
      records: {
        url: `${applications.apiWorkplace.url}/api/v1/records/`,
      },
      services: {
        url: `${applications.apiWorkplace.url}/api/v1/services/`,
      },
      serviceTypes: {
        url: `${applications.apiClient.url}/api/v1/service-types/:uuid/`,
        defaults: {
          uuid: '@uuid'
        },
      },
      upload: {
        url: `${applications.apiWorkplace.url}/api/v1/upload/`,
      },
      jiraBugReport: {
        url: `${applications.apiJira.url}/api/v3/jira/bug/`,
      }
    };
    // each key in urls will create an angular $resource obj at API.key
    Object.assign(this, Object.keys(urls).reduce((apis, name) => {
      const endpoint = urls[name];
      apis[name] = $resource(endpoint.url, endpoint.defaults, {
        patch: {
          method: 'PATCH'
        },
        put: {
          method: 'PUT'
        },
      });
      return apis;
    }, {}));
  }
}

API.$inject = ['applications', '$resource'];
module.exports = API;
