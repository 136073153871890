function plOverlayModalFactory() {
    const plOverlayModal = {};
    const _plOverlayModal = {};

    _plOverlayModal.getElement = (id) => {
        return angular.element(document.getElementById(id));
    };

    plOverlayModal.show = (id) => {
        _plOverlayModal.getElement(id).addClass('visible');
    };

    plOverlayModal.hide = (id) => {
        _plOverlayModal.getElement(id).removeClass('visible');
    };

    plOverlayModal.destroy = (id) => {
        const child = _plOverlayModal.getElement(id)[0];
        if (child && child.parentNode) {
            child.parentNode.removeChild(child);
        }
    };

    return plOverlayModal;
}
plOverlayModalFactory.$inject = [];
module.exports = plOverlayModalFactory;
