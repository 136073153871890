function plRecordNotes(plRecordMetrics, localStorageService, plRecordSPI) {
    function formatMetrics(metrics) {
        let metricsList = [];
        metrics.forEach((metric) => {
            metricsList.push(`${metric.metric_expanded.name}: ${metric.correct}/${metric.trials} (${metric.percent_correct}%)`);
        });
        return metricsList.join('; ');
    }

    return {
        restrict: 'E',
        scope: {
            type: '=',
            model: '=',
            generalNotes: '@?',
            whenBlur: '&?',
            whenKeyUp: '&?',
            disabled: '=?',
            recordSavingMessage: '=?',
            checkMetrics: '=?',
        },
        replace: true,
        templateUrl: '/core/pl-records/src/plRecordNotes/plRecordNotes.tpl.html',
        link: ($scope) => {
            let metrics = {};
            $scope.plRecordSPI = plRecordSPI;
            function init() {
                $scope.soapNotes = [];
                $scope.generalNotes = $scope.generalNotes !== undefined ? parseInt($scope.generalNotes, 10) : 1;
                $scope.haveNewMetrics = false;
                metrics = {};

                formNotes();
                checkForMetrics();
            }

            function checkForMetrics() {
                // TODO - pass in record id?

                if ($scope.model.uuid) {
                    plRecordMetrics.getPoints({ record: $scope.model.uuid })
                        .then((resMetrics) => {
                            if (resMetrics && resMetrics.length) {
                                metrics = resMetrics;
                            }
                        });
                }
            }

            $scope.$watch( () => metrics, (oldVal, newVal) => {
                if (!angular.equals(oldVal, newVal)) {
                    $scope.haveNewMetrics = true;
                }
            });


            function fillBlanks(fields) {
                if (!$scope.model.notes) {
                    $scope.model.notes = {};
                } else if (typeof $scope.model.notes === 'string') {
                    $scope.model.notes = JSON.parse($scope.model.notes);
                }
                fields.forEach((field) => {
                    if (!$scope.model.notes[field]) {
                        $scope.model.notes[field] = '';
                    }
                });
            }

            function formNotes() {
                if ($scope.type === 'soap') {

                    let storedActiveNote = localStorageService.get($scope.model.uuid + "storedActiveNote");
                    let sObj = { buttonName: 'S', modelKey: 'subjective', active: false, classes: { button: 'subjective' } };
                    let oObj = { buttonName: 'O', modelKey: 'objective', active: false, classes: { button: 'objective' } };
                    let aObj = { buttonName: 'A', modelKey: 'assessment', active: false, classes: { button: 'assessment' } };
                    let pObj = { buttonName: 'P', modelKey: 'plan', active: false, classes: { button: 'plan' } };

                    if (storedActiveNote) {
                        switch(storedActiveNote){
                            case "subjective":
                                sObj.classes.active = 'active';
                                sObj.active = true;
                                break;
                            case "objective":
                                oObj.classes.active = 'active';
                                pObj.active = true;
                                break;
                            case "assessment":
                                aObj.classes.active = 'active';
                                aObj.active = true;
                                break;
                            case "plan":
                                pObj.classes.active = 'active';
                                pObj.active = true;
                                break;
                        }
                    } else {
                        sObj.classes.active = 'active';
                        sObj.active = true;
                    }

                    $scope.soapNotes = [
                        sObj,
                        oObj,
                        aObj,
                        pObj,
                    ];
                    let fillBlanksFields = ['subjective', 'objective', 'assessment', 'plan', 'notes'];
                    fillBlanks(fillBlanksFields);
                } else {
                    fillBlanks(['notes']);
                }
            }

            init();

            $scope.$watch('type', (newVal, oldVal) => {
                // For room notes, needed to remove the check as it was the same value here
                // but was undefined in init previously..
                // if (!angular.equals(oldVal, newVal)) {
                    init();
                // }
            });

            $scope.$watch('model', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal)) {
                    init();
                }
            });

            $scope.$watch('checkMetrics', (newVal, oldVal) => {
                // if (!angular.equals(oldVal, newVal)) {
                    checkForMetrics();
                // }
            });

            $scope.selectSoapNote = (soapNote) => {

                localStorageService.set($scope.model.uuid + "storedActiveNote", soapNote.modelKey )

                $scope.soapNotes.forEach((note) => {
                    if (note.buttonName === soapNote.buttonName) {
                        note.active = true;
                        note.classes.active = 'active';
                    } else {
                        note.active = false;
                        note.classes.active = '';
                    }
                });
            };

            $scope.importMetrics = () => {
                $scope.model.notes.objective = `${$scope.model.notes.objective}\n\n${formatMetrics(metrics)}`;
                $scope.haveNewMetrics = false;
                if ($scope.whenKeyUp) {
                    $scope.whenKeyUp()();
                } else if ($scope.whenBlur) {
                    $scope.whenBlur()();
                }
            };

            $scope.blurNote = () => {
                if ($scope.whenBlur) {
                    // Was getting infinite digest errors if passed with arguments
                    // so need the extra parenthesis for the (empty) arguments.
                    $scope.whenBlur()();
                }
            }

            $scope.keyupNote = () => {
                if ($scope.whenKeyUp) {
                    // Was getting infinite digest errors if passed with arguments
                    // so need the extra parenthesis for the (empty) arguments.
                    $scope.whenKeyUp()();
                }
            }
        },
    };
}

plRecordNotes.$inject = ['plRecordMetrics', 'localStorageService', 'plRecordSPI'];
module.exports = plRecordNotes;
