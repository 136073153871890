import ActivitiesListController from '../activities/ActivitiesListController';

class AssessmentsListController extends ActivitiesListController {
    constructor($state, $stateParams, CurrentUser, drfAssessmentModel, permissionsHelper, drfStoreHeroModel, heroService) {
        super($state, $stateParams, CurrentUser, drfAssessmentModel, permissionsHelper, drfStoreHeroModel, heroService);
        this.type = 'assessment';
        this.showTags = false;
        heroService.getAssessmentHero().then((hero) => {
            this.hero = hero;
        })
    }

    query() {
        this._$state.go('app.store.assessments.all', this.filter.getRouteParams());
    }
}

AssessmentsListController.$inject = ['$state', '$stateParams', 'CurrentUser', 'drfAssessmentModel', 'permissionsHelper', 'drfStoreHeroModel', 'heroService'];
module.exports = AssessmentsListController;
