var AssetModel = function($log,
                          $q,
                          $http,
                          $timeout,
                          drfModel,
                          applications) {

    var Asset = new drfModel({
        apiUrl: `${applications.platform.url}/api/v1/asset/`,
    });

    this.ready = false;

    Object.assign(Asset.Model.prototype, {

        _doFormUpload: function(file, manifest) {
            var deferred = $q.defer();

            var form = new FormData();
            _.each(manifest, function(value, key) {
                form.append(key, value);
            });
            form.append('file', file);

            var _oXHR = new XMLHttpRequest();

            _oXHR.onreadystatechange = (event) => {
                if (_oXHR.status === 400) {
                    deferred.reject(event);
                    _oXHR.abort();
                } else if (_oXHR.status >=200 && _oXHR.status<=299) {
                    uploadComplete();
                }
            }

            var uploadComplete = (event) => {
                this['status'] = 'complete';
                this._progress = 100;
                this.$save().then(
                    () => {
                        return deferred.resolve(this);
                    }, (error) => {
                        return deferred.reject(this);
                    }
                );
            }

            _oXHR.addEventListener('abort', 
                (e) =>  {
                    $log.debug(e);
                }
            , false);

            _oXHR.addEventListener('error', 
                (e) =>  {
                    $log.debug(e);
                }
            , false);

            _oXHR.upload.addEventListener('error', 
                (e) =>  {
                    $log.debug(e);
                }
            , false);

            _oXHR.upload.addEventListener('progress', (e) => {
                var percentDone = e.loaded / e.total;
                this._progress = (percentDone * 100).toFixed();
                $timeout(function() {}, 0);
            }, false);

            _oXHR.open('POST', this.upload_url, true);
            _oXHR.send(form);

            return deferred.promise;
        },
        $upload: function(file, isPrivate) {
            var deferred = $q.defer();
            var promise = deferred.promise;
            // save the data in a data url so we can render it when it's done uploading
            var reader = new FileReader();
            reader.onloadend = _.bind(function() {
                if (file['type'].indexOf('image') >= 0) {
                    this._dataUrl = reader.result;
                } else {
                    //placeholderimg
                    this._dataUrl = 'https://cdn3.iconfinder.com/data/icons/document-icons-2/30/647716-pdf-512.png';
                }
            }, this);
            reader.readAsDataURL(file);

            //can change this back
            if (file['type'].indexOf('pdf') >= 0) {
                this.file_type = 'document';
            } else if (file['type'].indexOf('jpeg') >= 0) {
                this.file_type = 'image';
            } // was jpeg
            else if (file['type'].indexOf('png') >= 0) {
                this.file_type = 'image';
            } // png
            else if (file['type'].indexOf('gif') >= 0) {
                this.file_type = 'image';
            } //gif
            else {
                this.file_type = 'image';
            }
            this.filename = file['name'];
            this.title = file['name']
            // remove - or _ from string
                .replace(/[-_]/g, ' ')
                // remove the file type extension
                .replace(/\.\w{3,4}$/, '')
                // capitalize all words
                .replace(/\w\S*/g, function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });
            this.size = parseFloat(parseInt(file['size']) / 1000000).toFixed(2);
            this._progress = 0;
            this.tags = [];
            this.private = isPrivate;
            this.mime_type = file.type;
            var self = this;

            // audio & video types will be added later
            if (this.file_type === 'document') {
                this.$save()
                    .then(_.bind(function(obj) {
                        this.upload_url = obj.upload_url;
                        this.ready = true;
                        this._doFormUpload(file, obj.manifest).then(_.bind(function() {
                            return deferred.resolve(this);
                        }, this), _.bind(function(e) {
                            return deferred.reject(e);
                        }, this));
                    }, this));
            } else {
                var fileReader = new FileReader;

                fileReader.onload = function() {
                    var img = new Image;

                    img.onload = function() {
                        self.width = img.width;
                        self.height = img.height;
                        self.$save()
                            .then(_.bind(function(obj) {
                                this.upload_url = obj.upload_url;
                                this.ready = true;
                                this._doFormUpload(file, obj.manifest);
                            }, self));
                    };
                    img.src = fileReader.result;
                };
                fileReader.readAsDataURL(file);
            }

            return promise;
        },

        '$getViaSlug': function(slug) {
            var url = this.resource_uri + 'slug/' + slug + '/';
            return $http.get(url).then((data) => {
                    angular.forEach(data.data, (value, key) => {
                    this[key] = value;
                    });
                return this;
            });
        },
        '$addTag': function(tag) {
            var url = this.resource_uri + 'tag/' + tag + '/';
            return $http.post(url).then(() => this.$get());
        },
        '$removeTag': function(tag) {
            var url = this.resource_uri + 'tag/' + tag + '/';
            return $http.delete(url).then(() => this.$get());
        }
    });

    Object.assign(Asset.Collection.prototype, {});

    return Asset;
};

AssetModel.$inject = ['$log',
    '$q',
    '$http',
    '$timeout',
    'drfModel',
    'applications'];

module.exports = AssetModel;