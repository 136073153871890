const ActivityFilter = require('./ActivityFilter');
const ActivitySort = require('./ActivitySort');

class ActivitiesListController {
    constructor($state, $stateParams, CurrentUser, drfActivityModel, permissionsHelper, drfStoreHeroModel, heroService) {
        this.list = new drfActivityModel.Collection();
        this.filter = new ActivityFilter();
        this.sort = new ActivitySort();
        this.loading = false;
        this._$state = $state;
        this.type = 'activity';
        this.showTags = true;
        this.listType = $state.params.listType;
        this.filter['exclude_activity_type'] = 'imageDeck';
        this.hideSearch = $state.is('app.store.games.list');
        this.drfStoreHeroModel = drfStoreHeroModel;

        if ($stateParams.section) {
            this.filter.setSection($stateParams.section);
        }

        if (($state.is('app.store.activities.list') ||
            $state.is('app.store.games.list')) &&
            $state.params.listType === 'favorites') {
            this.filter.favorites(CurrentUser.uuid);
        } else if ($state.is('app.store.activities.list') && $state.params.listType === 'trash') {
            this.filter.setSection(null);
            this.filter.deleted(CurrentUser.uuid);
        } else if ($state.is('app.store.activities.list') && $state.params.listType === 'my_activities') {
            this.filter.setSection(null);
            this.filter.mine(CurrentUser.uuid);
        } else if (($state.is('app.store.activities.list') ||
            $state.is('app.store.games.list')) &&
            $state.params.listType === 'recent') {
            this.list._resource_uri += 'recent/';
        } else if ($state.is('app.store.assessments.recent')) {
            this.list._resource_uri += 'recent/';
        }

        // Community Library is not longer available
        if ($state.params.section === 'ugc') {
            this.filter.setSection('pldrl');
        }

        if ($stateParams.q) {
            this.filter.q = $stateParams.q;
        }

        if ($stateParams.type) {
            this.filter.setType($stateParams.type);
        }

        if ($stateParams.tag) {
            this.filter.addTag($stateParams.tag);
        }

        if ($stateParams.sort) {
            this.sort.setSort($stateParams.sort);
        }

        this.orderBy = this.sort.order_by;

        this.loading = true;
        this.list.$filter(this.filter);
        this.list.$orderBy(this.sort.order_by);
        this.list.$fetch().then(() => {
            this.loading = false;
        });
        this.searchPlaceholder = this.getSearchPlaceholder();

        if ($state.params.listType === 'all' && $state.params.section === 'pldrl' && $state.params.type) {
            heroService.getHeroByActivityType($state.params.type).then(hero => this.hero = hero);
        }

    }

    queryCallback() {
        this.query();
    }

    query() {
        this._$state.go('app.store.activities.list', this.getRouteParams());
    }

    // Both filter and sort set params, so need to get both.
    getRouteParams() {
        let obj = angular.extend({
            listType: this.listType
        }, this.filter.getRouteParams(), this.sort.getRouteParams());

        return obj;
    }

    isMyActivities() {
        return this._$state.params.listType === 'trash' || this._$state.params.listType === 'my_activities';
    }

    getSearchPlaceholder() {
        if (this._$state.params.listType === 'favorites' || this._$state.params.listType === 'my_activities') {
            return 'Narrow Current Search';
        }
        if (this._$state.current.name.indexOf('assessments') > -1) {
            return 'Search through all assessments';
        }
        if (this._$state.params.listType === 'recent') {
            return 'Search Recently Used in the Presence Library';
        }
        if (this._$state.is('app.store.lessons.all')) {
            return 'Search All Presence Lessons';
        }
        return 'Search All Presence Activities';
    }

}

ActivitiesListController.$inject = ['$state', '$stateParams', 'CurrentUser', 'drfActivityModel', 'permissionsHelper', 'drfStoreHeroModel', 'heroService'];
module.exports = ActivitiesListController;
