var Routes = function($stateProvider) {
    $stateProvider
        .state('app.store.assets', {
            url: '/assets',
            abstract: true,
            views: {
                content: {
                    templateUrl: '/toychest/modules/store/activities/activities.tpl.html',
                    controller: 'AssetsController',
                    controllerAs: 'assetsCtrl'
                }
            }
        })
        .state('app.store.assets.my_images', {
            url: '/my_images',
            views: {
                main: {
                    templateUrl: '/toychest/modules/store/assets/assets.list.tpl.html',
                    controller: 'AssetsListController',
                    controllerAs: 'assetsListCtrl'
                }
            }
        })
};

Routes.$inject = ['$stateProvider'];
module.exports = Routes;