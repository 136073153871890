"use strict";
var Repo = function ($log, Assert, DataNode) {
    /******************************
     * Repo
     * @constructor
     ******************************/
    class Repo {
        constructor(){
            this._graphView = {};
        }
        getParentPath(path) {
            return path.substring(0, path.lastIndexOf('/'));
        }

        //return an array of all ancestor's up to root in order from deepest to root
        getAncestry(ref){
            var ancestors = [];
            var parent = ref.parent();
            while(parent) {
                ancestors.push(parent);
                parent = parent.parent();
            }
            return ancestors;
        };

        getNode(path) {
            path = _.trim(path, "/");
            var pattern = new RegExp('\/', 'g');
            var objectPath = path.replace(pattern, '.');
            var graph = this._graphView;
            var node = _.get(graph, objectPath);
            if (node) {
                return DataNode.convert(node);
            }
            return null;
        };

        addNode(path, value) {
            $log.debug("[Repo] addNode: " + path);
            //remove any trailing path separators
            path = _.trim(path, "/");
            var pattern = new RegExp('\/', 'g');
            var objectPath = path.replace(pattern, '.');
            var node = this._graphView;
            _.set(node, objectPath, value);
        };

        mergeNode(path, node) {
            $log.debug("[Repo] mergeNode: " + path);
            Assert.equal(node instanceof DataNode, true, "node must be of type DataNode");
            path = _.trim(path, "/");
            var oldData = null;
            var oldNode = this.getNode(path);
            if (oldNode) {
                oldData = oldNode.getValue();
            }
            var newData = node.getValue();
            if (oldData == null) {
                //this is really an add not an update so we have to check for null child values
                _.forIn(newData, function (val, key) {
                    if (val == null) {
                        delete newData[key];
                    }
                });
                this.addNode(path, node.getValue());
            } else {
                if (oldData && newData) {
                    if (node.hasObjectValue()) {
                        //merge any object values
                        _.forIn(newData, function (val, key) {
                            if (val == null) {
                                //remove item
                                delete oldData[key];
                            } else {
                                oldData[key] = val;
                            }
                        });
                        //merge priority
                        oldNode.setPriority(node.getPriority());
                        //update node data with setValue to maintain node types and structure
                        oldNode.setValue(oldData);
                    } else {
                        //primitives should just be set
                        this.addNode(path, node);
                    }
                }
            }
        };

        removeNode(path) {
            path = _.trimRight(path, "/");
            var parentData = this.getParentData(path);
            if (parentData) {
                parentData = parentData.getValue();
            }
            var keys = _.keys(parentData);
            var key = this.getPathKey(path);
            if (_.includes(keys, key)) {
                delete parentData[key];
            }
        };

        getPathKey(path) {
            $log.debug('[Mock] getPathKey()');
            path = _.trim(path, "/");

            // if we have an empty path, just return null
            if(path.length == 0) {
                return null;
            }

            var key;
            var parts = path.split('/');
            key = parts.pop();
            return key;
        };

        findPreviousChildId(path) {
            $log.debug("[Mock] findPreviousChildId:" + path);
            var prevChildId = null;
            //get the parent path and data..
            var parentData = this.getParentData(path);
            var keys = this.sortKeys(parentData);
            var index = keys.indexOf(this.getPathKey(path));
            if (index > 0) {
                prevChildId = keys[index - 1];
            }
            return prevChildId;
        };

        getParentData(path) {
            var parentPath = this.getParentPath(path);
            return this.getNode(parentPath);
        };

        sortKeys(parentData) {
            Assert.equal(parentData instanceof DataNode, true, "parentData must be of type DataNode");
            var children = [];
            _.forEach(parentData.getValue(), function (value, key) {
                var childNode = DataNode.convert(value);
                children.push({key: key, priority: childNode.getPriority()});
            });

            children.sort(_.bind(this.compareNodes, this));

            var sortedKeys = [];
            for (var i = 0; i < children.length; i++) {
                sortedKeys.push(children[i].key);
            }
            return sortedKeys;
        };

        compareNodes(a, b) {
            var x = this.comparePriority(a.priority, b.priority);
            if (x === 0) {
                if (a.key !== b.key) {
                    x = a.key < b.key ? -1 : 1;
                }
            }
            return x;
        };

        comparePriority(a, b) {
            if (a !== b) {
                if (this.isNull(a) || this.isNull(b)) {
                    return this.isNull(a) ? -1 : 1;
                }
                if (typeof a !== typeof b) {
                    return typeof a === 'number' ? -1 : 1;
                } else {
                    return a > b ? 1 : -1;
                }
            }
            return 0;
        };

        isNull(value) {
            return !!(value == null || value == undefined);
        };

    }

    return Repo;
};
Repo.$inject = ['$log', 'Assert', 'DataNode'];
module.exports = Repo;