function plAppRecorder () {

  // ====== VERSION ======
  const VERSION = '0.2.0';
  // =====================


  const MAX_HISTORY_SIZE = 150;
  const INITIAL_VALUE = JSON.stringify([]);

  localStorage.plAppRecorderLog = localStorage.plAppRecorderLog || INITIAL_VALUE;
  let _recorder = {};

  window.plAppRecorder = _recorder;
  _recorder.set = (key, value) => {
    return; // DISABLED for now Aug 23, 2016 (JH)
    _obj[key] = value;
    _recorder.log(value);
  }

  _recorder.get = key => {
    return; // DISABLED for now Aug 23, 2016 (JH)
    _obj[key];
  };

  _recorder.log = obj => {
    if (!_recorder.checkVersion()) {
      _recorder.resetAndSetVersion(); 
    }
    const temp = _recorder.getHistory();

    if (temp.length >= MAX_HISTORY_SIZE) {
      temp.pop();
    }
    obj.timestamp = new Date().toString();
    temp.unshift(obj);
    localStorage.plAppRecorderLog = JSON.stringify(temp);
  };

  _recorder.onBeforeRouteChange = () => {
    const temp = _recorder.getHistory();
    if (temp.length > 0) {
      temp[0]['END-ROUTE-MARKER'] = true;
    }
    localStorage.plAppRecorderLog = JSON.stringify(temp);
  };

  _recorder.getHistory = () => {
    return JSON.parse(localStorage.plAppRecorderLog);
  };

  _recorder.getHistoryTop = (depth) => {
    if (depth) {
      return _recorder.getHistory().slice(0, depth);
    }
    return _recorder.getHistory()[0];
  };

  _recorder.getRouteChanges = () => {
    return _recorder.getHistory().reduce((accumulator, item)=>{
      if (item['ROUTE-CHANGE']) {
        accumulator = [...accumulator, item];
      }
      return accumulator;
    },[]);
  };

  _recorder.getErrors = () => {
    return _recorder.getHistory().reduce((accumulator, item)=>{
      if (item['JS-ERROR']) {
        accumulator = [...accumulator, item];
      }
      return accumulator;
    },[]);
  };

  _recorder.getApiCalls = () => {
    return _recorder.getHistory().reduce((accumulator, item)=>{
      if (item['API-CALL']) {
        accumulator = [...accumulator, item];
      }
      return accumulator;
    },[]);
  };

  _recorder.printHistory = (chronologicalOrder, depth) => {
    const items = chronologicalOrder?_recorder.getHistory().reverse():_recorder.getHistory();
    let obj;
    items.some((item, i) => {
      const index = i+1;
      if (item['API-CALL']) {
        obj = item['API-CALL'];
        const request = obj._response.config;
        const method = request.method;
        const status = obj._response?obj._response.status:'';
        const statusText = obj._response?obj._response.statusText:'';
        console.log(`  ${index} (API) - ${method} ${request.url.substring(request.url.indexOf('/api/'))} - ${status} ${statusText}`, [item]);
      } else if (item['ROUTE-CHANGE']) {
        obj = item['ROUTE-CHANGE'];
        console.log(`  ${index} (ROUTE) - ${obj.url}`, [item]);
      } else if (item['JS-ERROR']) {
        obj = item['JS-ERROR'];
        console.log(`  ${index} (ERROR) - ${obj.errorMsg}`, [item]);
      }
      if (item['END-ROUTE-MARKER']) {
        console.log(`> route boundary - ${item.timestamp}`);
      }
      if (depth) {
        return index >= depth;
      }
    });
  };

  _recorder.printHistoryTop = (depth) => {
    return _recorder.printHistory(false, depth);
  }

  _recorder.printHistoryBottom = (depth) => {
    return _recorder.printHistory(true, depth);
  }

  _recorder.printRouteChanges = (chronologicalOrder) => {
    const items = chronologicalOrder?_recorder.getRouteChanges().reverse():_recorder.getRouteChanges();
    items.map((item, index) => {
      console.log(`-- ${index} (ROUTE) ${item['ROUTE-CHANGE'].url}`, item);
    });
  };

  _recorder.printApiCalls = (chronologicalOrder) => {
    const items = chronologicalOrder?_recorder.getApiCalls().reverse():_recorder.getApiCalls();
    items.map((item, index) => {
      const obj = item['API-CALL'];
      const method = obj.request.method;
      const status = obj.response?obj.response.status:'';
      const statusText = obj.response?obj.response.statusText:'';
      console.log(`-- ${index} (API) ${method} ${obj.request.url.substring(obj.request.url.indexOf('/api/'))} - ${status} ${statusText}`, item);
    });
  };

  _recorder.printErrors = (chronologicalOrder) => {
    const items = chronologicalOrder?_recorder.getErrors().reverse():_recorder.getErrors();
    items.map((item, index) => {
      console.log(`-- ${index} (ERROR) ${item['JS-ERROR'].errorMsg}`, item);
    });
  };

  // TODO: check localStorage.sessionKey = sessionStorage.key on (app.run) startup.
  _recorder.reset = () => {
    localStorage.plAppRecorderLog = INITIAL_VALUE;
  };

  _recorder.getVersion = () => {
    return VERSION;
  }
  _recorder.checkVersion = () => {
    return localStorage.plAppRecorderVersion === VERSION;
  }
  _recorder.resetAndSetVersion = () => {
    _recorder.reset();
    localStorage.plAppRecorderVersion = VERSION;
  }

  _recorder.apiInterceptorConfig = () => {
    return {
      request: config => { return config; },
      response: response => {
        const _response = Object.assign({}, response);
        const url = _response.config.url;
        if (url.indexOf('/api/') > -1
          && url.indexOf('/status/') === -1) {
            if (_response.data) {
              delete _response.data;
            }

            const _request = _response.config;
            if (_request.headers) {
              delete _request.headers;
            }
            if (_request.transformRequest) {
              delete _request.transformRequest;
            }
            if (_request.transformResponse) {
              delete _request.transformResponse;
            }
            if (_request.data) {
              delete _request.data;
            }
            const noise = _request.params && _request.params.limit && _request.params.limit === 1000;
            const keep = _request.url.indexOf('/client-services/') !== -1;
            let providerTypes = _request.url.indexOf('/provider-types/') !== -1;
            let billingCodes = _request.url.indexOf('/billing_codes/') !== -1;
            if (!providerTypes && !billingCodes && (!noise || keep)) {
              _recorder.log({'API-CALL': {_response}});
            }
          }
        return response;
      }
    }
  }

  _recorder.appInit = ($rootScope, $location) => {
    // $rootScope.$on('$stateChangeStart', function() {
    //   _recorder.onBeforeRouteChange();
    // });
    // $rootScope.$on('$stateChangeSuccess', function(e, current, previous) {
    //   _recorder.log({"ROUTE-CHANGE": {url: $location.path()}});
    // });
    // window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
    //   _recorder.log({"JS-ERROR": {errorMsg, url, lineNumber, column}});
    // }
  }

  return _recorder;
}

plAppRecorder.$inject = [];
module.exports = plAppRecorder;
