module.exports = angular.module('toychest.store.activityCreation', [])
    .controller('ActivityCreationController', require('./activityCreationController'))
    .controller('CreateDocumentController', require('./createDocumentController'))
    .controller('CreateVideoController', require('./createVideoController'))
    .controller('DescribeActivityController', require('./panels/describeActivity/describeActivityController'))
    .controller('SelectActivityController', require('./panels/selectActivity/selectActivityController'))
    .controller('SelectPDFController', require('./panels/selectPDF/selectPDFController'))
    .controller('SelectVideoController', require('./panels/selectVideo/SelectVideoController'))
    .controller('CreateFlashcardsController', require('./createFlashcardsController'))
    .controller('DescribeFlashcardsController', require('./panels/describeFlashcards/describeFlashcardsController'))
    .controller('SelectFlashcardsController', require('./panels/selectFlashcards/selectFlashcardsController'))

    .service('uploadImageService', require('./panels/selectImage/uploadImageService'))
    .directive('cardItem', require('././panels/selectFlashcards/cardItemDirective/CardItemDirective'))

    .config(require('./routes'))
;
