ActivityPreviewController.$inject = ['$scope', '$window', 'Activity', 'PreviousState'];

function ActivityPreviewController($scope, $window, Activity, PreviousState) {
    const assessmentType = 'assessment';
    $scope.prevState = PreviousState;
    if(!$scope.prevState) {
        $scope.prevState = {
            name: assessmentType === Activity.resource_type
                    ? 'app.store.assessments.featured'
                    : 'app.store.activities.featured',
            params:{}
        }
    }
    $scope.route = assessmentType === Activity.resource_type
                    ? 'app.store.assessments.detail'
                    : 'app.store.activities.detail';
    $scope.activity = Activity;

    $scope.closePreview = function() {
        $window.close();
    };
}

module.exports = ActivityPreviewController;