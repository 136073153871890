var SelectVideoController = function ($scope, hotkeys, drfActivityModel) {

    var $videoList = $('.video-list');
    var rowSpacer = $videoList.find('.preview');
    var rowSpacerArrow = rowSpacer.find('.arrow');
    var rowSpacerVideo = rowSpacer.find('.player');

    const youtubeAPIkey = 'AIzaSyBpXfscTZy4Ap6eaVV2UGwwVX1mn5e59HI';
    var baseSearchURL = `https://www.googleapis.com/youtube/v3/search?part=snippet&key=${youtubeAPIkey}`;
    var baseVideoURL = `https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails,player&key=${youtubeAPIkey}`;

    var baseSearchByIdURL = `https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails,player&key=${youtubeAPIkey}`;
    var videosPerPage = 20;
    var selectedVideoIndex;
    rowSpacer.append(rowSpacerArrow);
    $scope.searchResults = [];
    $scope.videoIDSearchQuery = '';
    $scope.searchModeSelected = false;
    $(window).on('resize', function () {
        if (selectedVideoIndex) {
            openPreviewArea(selectedVideoIndex);
        }
    });
    $videoList.on('scroll', onScrollVideoList);
    hotkeys.bindTo($scope)
        .add({
            combo: 'left',
            description: 'Goto the previous video',
            callback: function () {
                $scope.openPreviousPreview();
            }
        })
        .add({
            combo: 'right',
            description: 'Goto the next video',
            callback: function () {
                $scope.openNextPreview();
            }
        });

    $scope.selectKeywordSearch = () => {
        $scope.searchModeSelected = true;
        $scope.keywordSearchMode= true;
    }

    $scope.selectIdSearch = () => {
        $scope.searchModeSelected = true;
        $scope.idSearchMode= true;
    }

    $scope.cancelSearch = () => {
        $scope.searchModeSelected = false;
        $scope.idSearchMode= false;
        $scope.keywordSearchMode= false;
        $scope.searchResults = [];
        $scope.selectedVideo = null;
        $scope.videoIDSearchQuery = '';
        $scope.videoSearchQuery = '';
        rowSpacerVideo.empty();
        $scope.youtubeIDSearchComplete = false;
    }

    $scope.initialYoutubeSearch = _.debounce( () => {
        $scope.youtubeKeywordSearching = true;
        $scope.idSearchMode = false;
        $scope.searchResults.length = 0;
        $scope.$apply();

        $scope.nextPageToken = null;
        $scope.searchYoutube();
        const stopResultWatch = $scope.$watch('nextPageToken', function (newVal, oldVal) {
            if (newVal && isNearBottom() ) {
                $scope.searchYoutube($scope.nextPageToken);
            } else if (newVal && newVal !== oldVal) {
                stopResultWatch();
            }
        });
    }, 300);

    $scope.searchYoutube = _.debounce(function (token) {
        if ($scope.isLoadingVideos) {
            return false;
        }
        // abort if no query
        if (!$scope.videoSearchQuery || $scope.videoSearchQuery.trim() === '') {
            $scope.searchResults.length = 0;
            $scope.youtubeKeywordSearching = false;
            $scope.$apply();
            return false;
        }
        if (!token) {
            $scope.searchResults.length = 0;
        }

        // generate search url for api
        var searchPathArray = [baseSearchURL, 'maxResults=' + videosPerPage, 'safeSearch=strict', 'type=video', 'q=' + $scope.videoSearchQuery];
        if (token) {
            searchPathArray.push('pageToken=' + token);
        }
        var searchURL = searchPathArray.join('&');

        // execute request
        var searchRequest = new XMLHttpRequest();
        searchRequest.open('GET', searchURL, true);
        $scope.isLoadingVideos = true;
        $scope.$apply()
        searchRequest.onload = function () {
            if (searchRequest.readyState == 4 && searchRequest.status == 200) {
                var results = JSON.parse(searchRequest.responseText);
                $scope.nextPageToken = results.nextPageToken;
                if(results.items.length === 0){
                    $scope.nextPageToken = undefined;
                    $scope.isLoadingVideos = false;
                    return;
                }
                $scope.searchResults = $scope.searchResults.concat(results.items);

            }
            $scope.isLoadingVideos = false;
            $scope.$apply();
        };
        searchRequest.send();
        searchRequest.onerror = function (e) {
            console.error(searchRequest.statusText, e);
            $scope.isLoadingVideos = false;
            $scope.$apply();
        };
    }, 300);

    function youTubeGetID(url){
        var ID = '';
        url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        if(url[2] !== undefined) {
            ID = url[2].split(/[^0-9a-z_\-]/i);
            ID = ID[0];
        }
        else {
            ID = url;
        }
        return ID;
    }

    $scope.youtubeIDSearch = function() {
        $scope.youtubeIDSearching = true;
        $scope.youtubeIDSearchComplete = false;
        $scope.searchResults.length = 0;
        $scope.idSearchMode = true;
        const id = youTubeGetID($scope.videoIDSearchQuery);
        let searchURL = baseSearchByIdURL + '&id=' + id;

        // execute request
        var searchRequest = new XMLHttpRequest();
        searchRequest.open('GET', searchURL, true);
        $scope.isLoadingVideos = true;
        searchRequest.onload = function () {
            $scope.youtubeIDSearchComplete = true;
            let results;
            if (searchRequest.readyState == 4 && searchRequest.status == 200) {
                results = JSON.parse(searchRequest.responseText);
                $scope.nextPageToken = results.nextPageToken;
                if(results.items.length === 0){
                    $scope.nextPageToken = undefined;
                    $scope.isLoadingVideos = false;
                    return;
                }
                $scope.searchResults = results.items;
            }
            $scope.isLoadingVideos = false;
            $scope.$apply();
            if (results) {
                $scope.previewSearchIdResult(results.items[0], 0)
            }
        };
        searchRequest.send();
        searchRequest.onerror = function (e) {
            console.error(searchRequest.statusText, e);
            $scope.isLoadingVideos = false;
            $scope.$apply();
        };
    }

    $scope.checkClearVideoID = function() {
        $scope.youtubeIDSearching = $scope.videoIDSearchQuery.trim() !== '';
    }

    $scope.previewSearchIdResult = function (video, index) {
        selectedVideoIndex = index;
        getVideoDetails(video.id);
        openPreviewArea(index);
    };

    $scope.preview = function (video, index) {
        selectedVideoIndex = index;
        getVideoDetails(video.id.videoId);
        openPreviewArea(index);
    };

    let isNearBottom = () => {
        var vidList = $('.video-list')[0];

        let distanceFromBottom = vidList.scrollHeight - vidList.clientHeight - vidList.scrollTop;
        return distanceFromBottom < 300;
    }

    function openPreviewArea(index) {
        var $videos = $videoList.find('.video-list-item');
        var videosPerRow = Math.floor($(window).width() / $videos.first().outerWidth(true));
        var rowEndIndex = Math.min(index + videosPerRow - 1 - index % videosPerRow, $videos.length - 1);
        var rowSpacerSizing = {
            'height': 400,
            'marginTop': 15
        };
        var rowSpacerAnimationDuration = 300;
        var rowSpacerArrowOffset = $videos.eq(index).offset().left + $videos.first().outerWidth() / 2;
        if (rowSpacer.rowEndIndex !== rowEndIndex) {
            if (!rowSpacer.open) {
                // open spacer
                $videoList
                    .stop()
                    .animate({
                        scrollTop: getVideoListScrollTop()
                    });
                rowSpacerArrow.css('left', rowSpacerArrowOffset);
                rowSpacer
                    .stop()
                    .insertAfter($videos.eq(rowEndIndex))
                    .show()
                    .animate(rowSpacerSizing, rowSpacerAnimationDuration);
                rowSpacer.open = true;
            } else {
                // open spacer in new row
                rowSpacer
                    .stop()
                    .animate({
                        height: 0
                    }, rowSpacerAnimationDuration, function () {
                        $videoList
                            .stop()
                            .animate({
                                scrollTop: getVideoListScrollTop()
                            });
                        rowSpacer
                            .insertAfter($videos.eq(rowEndIndex))
                            .show();
                        rowSpacerArrow.css('left', rowSpacerArrowOffset);
                    })
                    .animate(rowSpacerSizing, rowSpacerAnimationDuration);
            }
        } else {
            // move the rowSpacerArrow
            $videoList
                .stop()
                .animate({
                    scrollTop: getVideoListScrollTop()
                });
            rowSpacerArrow.animate({
                left: rowSpacerArrowOffset
            }, 150);
        }
        rowSpacer.rowEndIndex = rowEndIndex;

        function getVideoListScrollTop() {
            return $videos.eq(index).position().top + $videoList.scrollTop() + 75;
        }
    }

    $scope.openNextPreview = function () {
        var nextVid = $scope.searchResults[selectedVideoIndex + 1];
        if (nextVid) {
            $scope.preview(nextVid, selectedVideoIndex + 1);
        }
    };
    $scope.openPreviousPreview = function () {
        var prevVid = $scope.searchResults[selectedVideoIndex - 1];
        if (prevVid) {
            $scope.preview(prevVid, selectedVideoIndex - 1);
        }
    };

    function getVideoDetails(id) {
        if (!id) {
            return false;
        }
        var searchRequest = new XMLHttpRequest();
        var searchURL = baseVideoURL + '&id=' + id;
        searchRequest.open('GET', searchURL, true);
        searchRequest.onload = function () {
            if (searchRequest.readyState == 4 && searchRequest.status == 200) {
                var results = JSON.parse(searchRequest.responseText);
                if (results.items.length) {
                    $scope.selectedVideo = results.items[0];
                    $scope.selectedVideo.duration = moment.duration($scope.selectedVideo.contentDetails.duration);
                    createVideoPlayer();
                    $scope.$apply();
                }
            }
        };
        searchRequest.send();
        searchRequest.onerror = function (e) {
            console.error(searchRequest.statusText, e);
        };
    }

    function createVideoPlayer() {
        var player = $($scope.selectedVideo.player.embedHtml);
        player.attr({
            width: '100%',
            height: '100%'
        });
        rowSpacerVideo
            .empty()
            .delay(300)
            .append(player);
    }

    $scope.selectVideo = function () {
        $scope.setActivity(_.extend(new drfActivityModel.Model(), {
            'name': $scope.selectedVideo.snippet.title,
            'activity_type': 'youtube',
            'tags': [],
            'thumbnail_url': $scope.selectedVideo.snippet.thumbnails.medium.url,
            'descriptor': JSON.stringify({
                'videoId': $scope.selectedVideo.id
            }),
            'description': $scope.selectedVideo.snippet.description,
            'section': 'ugc'
        }));
        $scope.panelNavigation.gotoNextPanel();
    };

    function onScrollVideoList(event) {
        //var videoList = event.target,
        //    distanceFromBottom = videoList.scrollHeight - videoList.clientHeight - videoList.scrollTop,
        //    isNearBottom = distanceFromBottom < 300;
        if (isNearBottom() & $scope.nextPageToken != undefined) {
            rowSpacer.open = false;
            rowSpacer.rowEndIndex = -1;
            $scope.searchYoutube($scope.nextPageToken);
        }
    }

    $scope.getSelectVideoPanelClasses = function () {
        return {
            'panel-select-video': true,
            'central-search': $scope.searchResults.length === 0 && !$scope.videoSearchQuery
        };
    };

    $scope.title = 'Video';
    $scope.panelNavigation.current = 'find-video';

};

SelectVideoController.$inject = ['$scope', 'hotkeys', 'drfActivityModel'];
module.exports = SelectVideoController;