export default angular.module('pl-apis', [])
    .factory('plFileAmazon', require('./src/plFileAmazon/plFileAmazonFactory'))

    .factory('plAppointment', require('./src/plAppointment/plAppointmentFactory'))
    .factory('plBillingCodes', require('./src/plBillingCodes/plBillingCodesFactory'))
    .factory('plClient', require('./src/plClient/plClientFactory'))
    .factory('plLocation', require('./src/plLocation/plLocationFactory'))

    .factory('plClientServices', require('./src/plServices/plClientServicesFactory'))
    .factory('plAssessments', require('./src/plServices/plAssessmentsFactory'))
    .factory('plAreasOfConcern', require('./src/plServices/plAreasOfConcernFactory'))
    .factory('plServices', require('./src/plServices/plServicesFactory'))
    .factory('plServiceTypes', require('./src/plServices/plServiceTypesFactory'))
    .factory('plServiceOpts', require('./src/plServices/plServiceOptsFactory'))
    .factory('plServiceDocuments', require('./src/plServices/plServiceDocumentsFactory'))

    .factory('plApiInvoice', require('./src/plApiInvoice/plApiInvoiceFactory'))
    .factory('plApiLanguage', require('./src/plApiLanguage/plApiLanguageFactory'))
    .factory('plApiMetrics', require('./src/plApiMetrics/plApiMetricsFactory'))
    .factory('plApiNotesExport', require('./src/plApiNotesExport/plApiNotesExportFactory'))
    .factory('plApiOrganization', require('./src/plApiOrganization/plApiOrganizationFactory'))
    .factory('plApiProvider', require('./src/plApiProvider/plApiProviderFactory'))
    .factory('plApiRoom', require('./src/plApiRoom/plApiRoomFactory'))
    .factory('plApiServiceSave', require('./src/plServices/plApiServiceSaveFactory'))
    .factory('plApiServiceUploadDocuments', require('./src/plServices/plApiServiceUploadDocumentsFactory'))
    ;
