function FlashcardsDirective($timeout) {
    return {
        restrict:'EA',
        scope: {
            flashcard: '='
        },
        link: function(scope, element) {

            scope.$watch(function() {

                var card = scope.flashcard;

                return {
                    'left': card.x + 'px',
                    'top': card.y + 'px',
                    'z-index': card.index,
                    'font-size': card.size,
                    'transform': 'rotate(' + card.angle + 'rad)'
                };

            }, function(newValues, oldValues) {

                if (!_.isEqual(newValues, oldValues))
                {
                    element.css(newValues);
                }

            }, true);

        }
    };
};

FlashcardsDirective.$inject = ['$timeout'];

module.exports = FlashcardsDirective;