function plServiceDocumentsFactory(plHttp, $q, plLodash) {
    const plServiceDocuments = {};
    let _documentTypes = [];

    plServiceDocuments.getTypes = () => {
        const deferred = $q.defer();

        if (_documentTypes && _documentTypes.length) {
            deferred.resolve(_documentTypes);
        } else {
            plHttp.get('documentTypes', {})
                .then((res) => {
                    _documentTypes = res.data.results;
                    deferred.resolve(res.data.results);
                }, (err) => {
                    deferred.reject(err);
                });
        }
        return deferred.promise;
    };

    plServiceDocuments.save = (doc, type) => {
        const deferred = $q.defer();

        plServiceDocuments.getTypes()
            .then((types) => {
                const index = plLodash.findIndex(types, 'code', type);
                const typeUuid = (index > -1) ? types[index].uuid : null;
                if (!typeUuid) {
                    deferred.reject({ msg: 'Invalid type' });
                } else {
                    const doc1 = Object.assign({}, doc, {
                        document_type: typeUuid,
                    });
                    plHttp.save('documents', doc1)
                        .then((res) => {
                            deferred.resolve(res.data);
                        }, (err) => {
                            deferred.reject(err);
                        });
                }
            }, (err) => {
                deferred.reject(err);
            });

        return deferred.promise;
    };

    plServiceDocuments.delete = (docUuid) => {
        const deferred = $q.defer();
        plHttp.delete('documents', { uuid: docUuid })
            .then(() => {
                deferred.resolve();
            }, (err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    };

    return plServiceDocuments;
}
plServiceDocumentsFactory.$inject = ['plHttp', '$q', 'plLodash'];
module.exports = plServiceDocumentsFactory;
