function plPermissions() {
  return {
    ADD_CLIENT: 'client.add_client',
    TRANSFER_LOCATION: 'client.transfer_location',
    CLIENT_CHANGE_PII: 'client.change_pii',
    ORGANIZATION_VIEW_ORGANIZATION: 'organization.view_organization',
    EXPORT_NOTES: 'organization.export_notes',
    PHI_ONLY: 'phi_only',
    VIEW_ADMIN:  'plauth.view_admin',
    VIEW_PROVIDERS:  'plauth.view_providers',
    PROVIDE_SERVICES:  'plauth.provide_services',
    MANAGE_REFERRALS:  'plauth.manage_referrals',
    VIEW_OPEN_REFERRALS:  'plauth.view_open_referrals',
    trimContext: fullPermName => {
      const contextIdx = fullPermName.indexOf('.');
      return (contextIdx>0)?fullPermName.substring(contextIdx+1):fullPermName;
    }
  };
}

module.exports = plPermissions;
