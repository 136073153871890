module.exports = {
    grades: {
        title: 'age/grade',
        tags: {
            'grade-pre-k': 'Pre-K',
            'grade-k-2': 'Lower Elementary (Grades K-2)',
            'grade-3-5': 'Elementary (Grades 3-5)',
            'grade-6-8': 'Middle (Grades 6-8)',
            'grade-9-12': 'High School (Grades 9-12)',
            'grade-adult': 'Adult'
        }
    },
    SLT: {
        title: 'Speech Therapy',
        tags: {
            'articulation': 'Articulation',
            'assistive-technology-at': 'Assistive Technology (AT)',
            'auditory-comprehension': 'Auditory Comprehension',
            'augmentative-and-alternative-communication-aac': 'Augmentative and Alternative Communication (AAC)',
            'categorizing': 'Categorizing',
            'expressive-language': 'Expressive Language',
            'figurative-language': 'Figurative Language',
            'fluency': 'Fluency',
            'following-directions': 'Following Directions',
            'grammar': 'Grammar',
            'inference': 'Inference',
            'learning-coach': 'Learning Coach',
            'locative': 'Locative',
            'non-verbal': 'Non-Verbal',
            'oral-motor': 'Oral Motor',
            'perspective-taking': 'Perspective Taking',
            'phonemic-awareness': 'Phonemic Awareness',
            'phonology': 'Phonology',
            'pragmatics': 'Pragmatics',
            'prediction': 'Prediction',
            'problem-solving': 'Problem Solving',
            'receptive-language': 'Receptive Language',
            'sequencing': 'Sequencing',
            'social-language': 'Social Language',
            'speech': 'Speech',
            'summarizing': 'Summarizing',
            'syntax': 'Syntax',
            'vocabulary': 'Vocabulary',
            'voice': 'Voice',
            'wh-questions': 'WH-Questions',
            'writing': 'Writing'
        }
    },
    OT: {
        title: 'Occupational Therapy',
        tags: {
            'assistive-technology-at': 'Assistive Technology (AT)',
            'augmentative-and-alternative-communication-aac': 'Augmentative and Alternative Communication (AAC)',
            'learning-coach': 'Learning Coach',
            'community-integration': 'Community Integration',
            'fine-motor-coordination': 'Fine Motor Coordination',
            'gross-motor': 'Gross Motor',
            'group-dynamics': 'Group Dynamics',
            'handwriting': 'Handwriting',
            'life-transitions': 'Life Transitions',
            'motor-planning': 'Motor Planning',
            'muscle-tone-management': 'Muscle Tone Management',
            'postural-mechanisms': 'Postural Mechanisms',
            'self-care-skills': 'Self Care Skills',
            'sensory-processing': 'Sensory Processing',
            'visual-motor': 'Visual Motor'
        }
    },
    'SPED Instruction': {
        title: 'SPED Instruction',
        tags: {
            'learning-coach': 'Learning Coach',
            'addition': 'Addition',
            'algebra': 'Algebra',
            'decoding': 'Decoding',
            'division': 'Division',
            'geometry': 'Geometry',
            'math': 'Math',
            'multiplication': 'Multiplication',
            'numbers': 'Numbers',
            'pre-algebra': 'Pre-Algebra',
            'reading': 'Reading',
            'reading-comprehension': 'Reading Comprehension',
            'study-skills': 'Study Skills',
            'subtraction': 'Subtraction'
        }
    },
    BMH: {
        title: 'Behavior & Mental Health',
        tags: {
            'learning-coach': 'Learning Coach',
            'anger-management': 'Anger Management',
            'cognitive-behavior-therapy-or-cbt': 'Cognitive-Behavior-Therapy OR CBT',
            'emotional-regulation': 'Emotional Regulation',
            'social-skills': 'Social Skills'
        }
    }
};