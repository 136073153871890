class NotificationService {
    constructor() {
        if ('Notification' in window) {
            Notification.requestPermission();
        }
    }

    notify(title, message, icon, audioUrl) {
        let notification = null;
        if ('Notification' in window && Notification.permission === 'granted') {
            const options = {
                body: message,
                icon,
            };
            notification = new Notification(title, options);

            // Firefox on Mac plays its own audio during a Notification, so don't
            // play audio there.
            const isMac = navigator.appVersion.indexOf('Mac') !== -1;
            const isFirefox = $('html').hasClass('firefox');
            if (audioUrl && !(isFirefox && isMac)) {
                new Audio(audioUrl).play();
            }
        } else if (audioUrl) {
            // if Notifications aren't supported or are disabled, just play the sound
            new Audio(audioUrl).play();
        }
        return notification;
    }

}

NotificationService.$inject = [];
module.exports = NotificationService;
