var AssessmentDetailController = function($window, $scope, $stateParams, $state, $sce, $injector, reviewModel, Assessment, Room, PreviousState, applications) {
    // Activities queue is only needed if there's a room
    var activitiesQueue = null;
    if(Room) {
        activitiesQueue = $injector.get('activitiesQueue');
    }
    $scope.queue = activitiesQueue;
    $scope.editTitle = false;
    $scope.editDescription = false;

    $scope.assessment = Assessment;
    $scope.prevState = PreviousState;
    $scope.showPrintConfirm = false;
    $scope.printing = false;
    $scope.iframe;
    $scope.printerUrl = `${applications.lightyear.url}/pl/pdf-print`

    $scope.isRecordForm = () => {
        return $scope.assessment.activity_type === 'recordform';
    }

    $scope.printItem = () => {
        try {
            if ($scope.printing) {
                return;
            }
            $scope.printing = true;
            const frameId = 'pdf-print-iframe';
            $scope.iframe = document.getElementById(frameId);
            const rightsMgmtService = $injector.get('rightsMgmtService');
            const descriptor = JSON.parse($scope.assessment.descriptor);
            const assetKey = descriptor.url;
            rightsMgmtService.getProtectedContentUrl($scope.assessment.id, assetKey).then((res) => {
                const assetUrl = res.assets[assetKey];
                window.addEventListener('message', $scope.afterPrintListener);
                if (!$scope.iframe) {
                    $scope.iframe = document.createElement('iframe');
                    $scope.iframe.id = frameId;
                    $scope.iframe.src = $scope.printerUrl;
                    $scope.iframe.width = 0;
                    $scope.iframe.height = 0;
                    $scope.iframe.style = 'display: hidden;';
                    $scope.iframe.onload = () => {
                        $scope.iframe.contentWindow.postMessage({ pdf: assetUrl }, '*');
                    }
                    document.body.appendChild($scope.iframe);
                } else {
                    $scope.iframe.contentWindow.postMessage({ pdf: assetUrl }, '*');
                }
            });
        }
        catch (err) {
            console.error(`Unable to Print record form: ${err.message}`);
            $scope.togglePrintConfirm();
            $scope.printing = false;
        }

        
    }

    $scope.afterPrintListener = (event) => {
        if (event.data === 'afterprint') {
            // Wait for the print dialog
            setTimeout(() => {
                $scope.printing = false; 
                $scope.showPrintConfirm = false;
                window.removeEventListener('message', $scope.afterPrintListener);
            }, 1000);
        }
    }

    $scope.togglePrintConfirm = () => {
        $scope.showPrintConfirm = !$scope.showPrintConfirm;
        if (!$scope.showPrintConfirm) {
            window.removeEventListener('message', $scope.afterPrintListener);
            if ($scope.iframe) {
                document.body.removeChild($scope.iframe);
            }
        }
    }
};

AssessmentDetailController.$inject = ['$window', '$scope', '$stateParams', '$state', '$sce','$injector', 'reviewModel', 'Assessment', 'Room', 'PreviousState', 'applications'];
module.exports = AssessmentDetailController;
