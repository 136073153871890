function plApiNotesExportFactory(plHttp, $q) {
    const plApiNotesExport = {};

    plApiNotesExport.saveReport = (data) => {
        const deferred = $q.defer();

        plHttp.save('notesExportsOrganizations', data)
            .then((res) => {
                deferred.resolve();
            }, (err) => {
                deferred.reject(err);
            });

        return deferred.promise;
    };

    plApiNotesExport.delete = (reportUuid) => {
        const deferred = $q.defer();
        plHttp.delete('notesExportsOrganizations', { uuid: reportUuid })
            .then(() => {
                deferred.resolve();
            }, (err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    };

    return plApiNotesExport;
}
plApiNotesExportFactory.$inject = ['plHttp', '$q'];
module.exports = plApiNotesExportFactory;