DropZoneDirective.$inject = ['$sce', '$parse'];

function DropZoneDirective($sce, $parse) {
    return {
        restrict: 'A',
        link: function link($scope, $element, $attr) {
            const onDropFilesExpression = $parse($attr.onDropFiles);
            const onDropErrorExpression = $parse($attr.onDropError);
            const correctFileTypes = $attr.dropZoneFiletypes.split(',').map(item => item.trim().toLowerCase());

            function isCorrectFileType(files) {
                return Object.keys(files)
                    .map(key => files[key].type)
                    .every(fileType => correctFileTypes.indexOf(fileType.toLowerCase()) > -1);
            }

            function hasCorrectAndIncorrectFileTypes(files) {
                let numberOfIncorrectFileTypes = 0;

                Object.keys(files)
                    .map(key => files[key].type)
                    .every(fileType => {
                        if (correctFileTypes.indexOf(fileType.toLowerCase()) == -1) {
                            numberOfIncorrectFileTypes++;
                        }
                    });

                    return numberOfIncorrectFileTypes < files.length;
            }

            function onDragEnter(event) {
                event.preventDefault();
                event.stopPropagation();

                // Note dataTransfer.items is null in Firefox, we simply bypass the test here if that is the case
                if (!event.originalEvent.dataTransfer.items ||
                    isCorrectFileType(event.originalEvent.dataTransfer.items)) {
                    $element.addClass('dragging-over');
                } else {
                    $element.addClass('bad-file-type');
                }
            }

            function onDragOver(event) {
                event.preventDefault();
                event.stopPropagation();

                // Note dataTransfer.items is null in Firefox, we simply bypass the test here if that is the case
                if (isCorrectFileType(!event.originalEvent.dataTransfer.items ||
                        event.originalEvent.dataTransfer.items)) {
                    $element.addClass('dragging-over');
                } else {
                    $element.addClass('bad-file-type');
                }
            }

            function onDragLeave(event) {
                event.preventDefault();
                event.stopPropagation();

                $element.removeClass('dragging-over bad-file-type');
            }

            function onDropFile(event) {
                event.preventDefault();
                event.stopPropagation();

                if (isCorrectFileType(event.originalEvent.dataTransfer.files)) {
                    $element.removeClass('dragging-over');
                    onDropFilesExpression($scope, {
                        files: event.originalEvent.dataTransfer.files,
                    });
                } else if (hasCorrectAndIncorrectFileTypes(event.originalEvent.dataTransfer.files)) {
                        $element.removeClass('dragging-over');
                        onDropFilesExpression($scope, {
                            files: event.originalEvent.dataTransfer.files,
                        });
                    } else {
                        onDropErrorExpression($scope, {
                            error: "Incorrect file type"
                        });

                        $element.addClass('bad-file-type');
                    }
            }

            $scope.browseFiles = () => {
                $element.find('.browse-input').trigger('click');
            };

            $element.on('dragenter', onDragEnter);
            $element.on('dragover', onDragOver);
            $element.on('dragleave', onDragLeave);
            $element.on('drop', onDropFile);
        },
    };
}

module.exports = DropZoneDirective;
