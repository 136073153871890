function plBrowserFactory($rootScope, $q, $http, applications, $interval) {
    const plBrowser = {};
    const _plBrowser = {
        intervalVersion: false,
    };

    const BROWSER_CHECK_DEV = localStorage.getItem('BROWSER_CHECK_DEV');

    _plBrowser.detectBrowser = () => {
        const browser = {};
        // http://stackoverflow.com/a/9851769/1429106
        // Opera 8.0+
        browser.opera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        // Firefox 1.0+
        browser.firefox = typeof InstallTrigger !== 'undefined';
        // At least Safari 3+: "[object HTMLElementConstructor]"
        browser.safari = /Apple/.test(navigator.vendor)
        // Internet Explorer 6-11
        browser.ie = /*@cc_on!@*/false || !!document.documentMode;
        // Edge 20+
        browser.edge = !browser.ie && !!window.StyleMedia;
        // Chrome 1+
        browser.chrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        // Blink engine detection
        browser.blink = (browser.chrome || browser.opera) && !!window.CSS;
        // Cypress headless (chromium)
        browser.cy_headless = navigator.userAgent.indexOf('Cypress') >= 0;
        return browser;
    };

    plBrowser.isSupported = () => {
        const browser = _plBrowser.detectBrowser();
        if (browser.chrome || browser.firefox || browser.cy_headless) {
            return true;
        };

        if (BROWSER_CHECK_DEV && browser.safari) {
            return true;
        }
        return false;
    };

    plBrowser.isCodeOutdated = () => {
        const deferred = $q.defer();
        if ($rootScope.appName === undefined  || $rootScope.sha === undefined) {
            deferred.reject('plBrowser.isCodeOutdated: $rootScope.appName and $rootScope.sha are required');
        } else {
            // Localhost and deployed have different locations for the healthz file
            // so have to try both.
            const codeStatus = {
                outdated: false,
            };
            const appMaps = {
                woody: 'schedule',
                rex: 'clients',
                hamm: 'billing',
                toychest: 'toychest',
            };
            let urlBase = `${applications[$rootScope.appName].url}/${appMaps[$rootScope.appName]}`;
            if (applications[$rootScope.appName].url.indexOf('localhost') > -1) {
                urlBase = `/../${$rootScope.appName}`;
            }
            const url = `${urlBase}/healthz.json`;
            $http.get(url)
                .then((data) => {
                    if (data.data && data.data.sha && data.data.sha !== $rootScope.sha) {
                        codeStatus.outdated = true;
                    }
                    deferred.resolve(codeStatus);
                }, (err) => {
                    deferred.reject(err);
                });
        }
        return deferred.promise;
    };

    plBrowser.checkVersionLoop = (timeoutSeconds, callbackEvt) => {
        if (timeoutSeconds) {
            plBrowser.checkVersion(callbackEvt);
            plBrowser.checkVersionInterval(timeoutSeconds, callbackEvt);
        }
    };

    plBrowser.checkVersionInterval = (timeoutSeconds, callbackEvt) => {
        _plBrowser.intervalVersion = $interval(() => {
            plBrowser.checkVersion(callbackEvt);
        }, (timeoutSeconds * 1000));
    };

    plBrowser.checkVersion = (callbackEvt) => {
        plBrowser.isCodeOutdated()
            .then((codeStatus) => {
                $rootScope.$broadcast(callbackEvt, codeStatus);
            });
    };

    return plBrowser;
}
plBrowserFactory.$inject = ['$rootScope', '$q', '$http', 'applications', '$interval'];
module.exports = plBrowserFactory;
