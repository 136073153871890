var CreateVideoController = function($scope) {
    $scope.panelNavigation.addPanels([{
        title: 'Find Video',
        state: 'app.store.activities.create.video.select',
        key: 'find-video',
        locked: (key) => (key === 'select-activity'),
    }, {
        title: 'Describe Video',
        state: 'app.store.activities.create.video.describe',
        key: 'describe-document',
        locked: (key) => (key === 'select-activity' || key === 'find-video'),
    }]);
};

CreateVideoController.$inject = ['$scope'];
module.exports = CreateVideoController;