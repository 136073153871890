function HeroDirective($state) {
    return {
        restrict: 'E',
        replace: false,
        templateUrl: '/toychest/modules/store/activities/hero.tpl.html',
        scope: {
            hero: '='
        },
        link: function link(scope, element, attrs) {
            scope.$state = $state;
        },
    };
}

HeroDirective.$inject = ['$state'];
module.exports = HeroDirective;