var ImageOnLoadDirective = function () {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            element.bind('load', function() {
                //call the function that was passed
                scope.$evalAsync(attrs.imageOnLoad);
            });
        }
    };
};

ImageOnLoadDirective.$inject = ['$log', '$document'];
module.exports = ImageOnLoadDirective;
