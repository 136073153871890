var TagModel = function($log, drfModel, applications) {

    var Tag = new drfModel({
        apiUrl: `${applications.platform.url}/api/v1/tag/`,
    });

    Object.assign(Tag.Model.prototype, {
    });

    Object.assign(Tag.Collection.prototype, {
    });

    return Tag;
};

TagModel.$inject = ['$log', 'drfModel', 'applications'];
module.exports = TagModel;
