/**
 * Returns exclude filter function
 *
 * @returns {Function}
 */
module.exports = function() {
    return (activities) => {
        return (activities || []).filter((activity) => {
            return !!activity.name;
        });
    };
};
