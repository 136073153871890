function plInputService(guidService, $timeout) {

    this.createNameIfNone = function createNameIfNone($scope) {
        if (!$scope.name) {
            $scope.name = guidService.generateUUID();
        }
    };

    this.setupOnChange = function setupOnChange($scope, $attrs) {
        if ($attrs.change) {
            $scope.$watch('model', (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    $scope.change();
                }
            });
        }
    };

    this.addInputToFormForValidation = function addInputToFormForValidation($scope, $element, formCtrl) {
        $scope.$input = this.getNgModelControllerFromElement($element);
        $scope.$input.name = $scope.name;
        if (formCtrl) {
            formCtrl[$scope.name] = $scope.$input;
        }
    };

    this.setupFocusAndBlur = function setupFocusAndBlur($scope, $attrs) {
        $scope.onFocus = function inputOnFocus() {
            $scope.focused = true;
            if ($attrs.focus) {
                $scope.focus();
            }
        };
        $scope.onBlur = function inputOnBlur() {
            $scope.focused = false;
            if ($attrs.blur) {
                $scope.blur();
            }
            $timeout(() => {
                if ($scope.closeSelect) {
                    $scope.closeSelect();
                }
            }, 250);
        };
    };

    this.getNgModelControllerFromElement = function getNgModelControllerFromElement($element) {
        return $element.find('input, textarea, select').data().$ngModelController;
    };

    this.isTextStyleInputInvalid = function isTextStyleInputInvalid($scope, formCtrl) {
        if ($scope.disabled) {
            return false;
        }
        if (formCtrl) {
            return (formCtrl.$submitted || $scope.$input.$touched) && $scope.$input.$invalid;
        } else {
            return $scope.$input.$touched && $scope.$input.$invalid;
        }
    };

    this.setupSelectDropdown = ($scope, $element, $attrs, params1 = {scrollToTime : false}) => {
        const optionsContainer = $element.find('.options-container');
        const input = $element.find('input');
        const params = Object.assign({}, {
            mousedownPreventDefault: true,
        }, params1);
        let menuOpen = false;

        input.on('mousedown', event => {
            // TODO - with this the time native input can not be clicked to change the time (up / down).
            // But without this, the selectOption function is not always called if an option is clicked..
            // if (params.mousedownPreventDefault) {
            //     event.preventDefault();
            // }
            if (!$element.hasClass('ready-to-open')) {
                openMenu(params1);
            }
        });
        input.on('keyup', event => {
            if (!$element.hasClass('ready-to-open')) {
                openMenu();
            }
        });

        // open / close
        function openMenu(params) {
            if (!params) {
                params = {};
            }

            $element.addClass('ready-to-open');
            optionsContainer.css('max-height', getOptionsContainerMaxHeight);

            if (params.scrollToTime) {
                const timeVal = angular.copy($scope.model);
                const hrAndMin = timeVal.split(':');
                const readableTime = moment().hour(hrAndMin[0]).minute(hrAndMin[1]).format('h:mma');
                const optionToScrollTo = $(optionsContainer[0]).find(`div.option:contains('${readableTime}')`);
                if (optionToScrollTo.length) {
                    const sTop = optionToScrollTo[optionToScrollTo.length - 1].offsetTop;
                    $(optionsContainer[0]).scrollTop(sTop);
                }
            }

            $(document).on('click', handleDocumentClick);
            menuOpen = true;
        }

        function closeMenu() {
            if (menuOpen) {
                $(document).off('click', handleDocumentClick);
                optionsContainer.css('max-height', '0');
                setTimeout(function() {
                    $element.removeClass('ready-to-open');
                }, 200);
                menuOpen = false;
            }
        }

        function getOptionsContainerMaxHeight() {
            const maxHeight = $attrs.maxHeight || 506;
            return `${maxHeight}px`;
        }

        function handleDocumentClick(e) {
            if (input.is(e.target) === false && input.has(e.target).length === 0
             && optionsContainer.is(e.target) === false && optionsContainer.has(e.target).length === 0) {
                $scope.$apply(() => {
                    closeMenu();
                });
            }
        }

        // select
        $scope.selectOption = function selectOption(option) {
            $scope.model = option.value;
            closeMenu();
        };

        $scope.closeSelect = () => {
            closeMenu();
        };
    };

};

plInputService.$inject = ['guidService', '$timeout'];
module.exports = plInputService;
