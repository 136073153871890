function plInfoHint() {
    return {
        restrict: 'E',
        scope: {
            model: '=',
        },
        replace: true,
        templateUrl: '/core/pl-modules/src/plInfoHint/plInfoHint.tpl.html',
        link: ($scope) => {
            $scope.toggle = () => {
                $scope.model = !$scope.model;
            };
        },
    };
}

plInfoHint.$inject = [];
module.exports = plInfoHint;
