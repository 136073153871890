/**
 * Pdf rendering directive (works with pdf.js)
 *
 * @param $log
 * @constructor
 */

var PdfViewerDirective = function($log, $compile, $sce, $timeout, firebaseModel, activityModel, dispatcher) {
    return {
        restrict    : 'E',
        replace: true,
        templateNamespace: 'svg',
        templateUrl : '/toys/modules/activity/components/pdfViewer/pdfViewer.tpl.html',
        controller  : 'PdfViewerController',

        link        : function($scope, element, attrs, controller) {

            var loadState = function() {
                if (activityModel.share) {
                    //get the state from firebase
                    $scope.ref = firebaseModel.getFirebaseRef('activities/sessions/' + activityModel.getSessionId() + "/" + $scope.data.id);
                    $scope.ref.on("value", _.bind(this.handleActivityData, this), _.bind(this.handleActivityError, this));
                } else {
                    //use the defaults
                    this.setDefaultState();
                }
            };

            this.setDefaultState = function() {
                $scope.page = $scope.data.shared.page;
                $scope.scale = $scope.data.shared.scale;
                $scope.src = $scope.data.shared.url;
                $scope.componentId = '' + $scope.data.id;
                loadDocument($scope.src);

                $timeout(_.bind(function() {
                    dispatcher.dispatch('ComponentEvent-FirebaseActivityChange', $scope.componentId, {data: $scope.data.shared});
                }, this), 1000);

            };

            /**
             * Handlers
             */
            this.handleActivityData = function(snapshot) {
                $log.debug("[PdfViewerDirective] activity data loaded:" + snapshot.val());
                var sharedData = snapshot.val();
                if (sharedData == null) {
                    sharedData = {};
                    $scope.ref.set($scope.data.shared);
                }

                //existing shared data has priority over the defaults
                _.defaults(sharedData, $scope.data.shared);
                $scope.page = sharedData.page;
                $scope.scale = sharedData.scale;
                $scope.src = sharedData.url;
                $scope.componentId = '' + $scope.data.id;
                loadDocument($scope.src);

                dispatcher.dispatch('ComponentEvent-FirebaseActivityChange', $scope.componentId, {data: sharedData});
            };

            this.handleActivityError = function(error) {
                $log.debug("[PdfViewerDirective] activity ref load error:" + error.code);
            };

            /***************************
             *  pdf rendering
             *************************/
            var canvas; //placeholder for the inserted canvas
            var image;  //placeholder for the image render target
            var activityAnchor = $.find('.activityContentAnchor'); //the container for the inserted canvas
            var context;  //2D context for the rendered canvas
            var pdf = null; //the pdfjs document

            var createCanvas = function() {
                var newScope = $scope.$new();
                var newElTmpl = "<canvas id='pdfCanvas" + $scope.data.id + "' class='pdfCanvas hiddenContent' ng-attr-width='{{width}}' ng-attr-height='{{height}}'></canvas>";
                var newEl = $(newElTmpl);

                var linkFn = $compile(newEl[0]);
                var element = linkFn(newScope);
                return element;
            };

            var addToAnchor = function(element) {
                if (element) {
                    $log.debug("[PdfViewerDirective] inserting element");
                    if (activityAnchor) {
                        $(activityAnchor).append(element[0]);
                    }
                }
            };

            var loadDocument = function(src) {
                //!!ABL For testing locally. It can be removed once we have everything working end-to-end
                src = src.replace('http://127.0.0.1:8000/lightyear', '/toys');
                //src = "https://media.readthedocs.org/pdf/flask-cors/latest/flask-cors.pdf";
                //src = "https://70a6ec85380735019a08-df8d1402537510247fcf4de2e60db998.ssl.cf1.rackcdn.com/test.pdf";
                PDFJS.getDocument(src).then(
                    function handleDocument(pdfDocument) {
                        $log.debug("[PdfViewerDirective] doc loaded");
                        pdf = pdfDocument;
                        $scope.data.numPages = pdf.pdfInfo.numPages;
                        gotoPage($scope.page);
                    },
                    function handleDocumentError(exception) {
                        var message = exception && exception.message;
                        var loadingErrorMessage = 'An unknown error occurred while loading the PDF.';

                        if (exception instanceof PDFJS.InvalidPDFException) {
                            loadingErrorMessage = 'Invalid or corrupted PDF file.';
                        } else if (exception instanceof PDFJS.MissingPDFException) {
                            loadingErrorMessage = 'Missing PDF file.';
                        } else if (exception instanceof PDFJS.UnexpectedResponseException) {
                            loadingErrorMessage = 'Unexpected server response.';
                        }

                        //TODO use system error handler
                        $log.error("[PDFViewerDirective] Error: " + loadingErrorMessage + " " + message);
                    }
                )
            };

            var gotoPage = function(pageNumber) {
                $log.debug('[PdfViewerDirective] gotoPage()');
                if (pageNumber == undefined) {
                    pageNumber = 1;
                }
                if(pdf) {
                    pdf.getPage(pageNumber).then(function(page){
                        $scope.loading = false;
                        var viewport = page.getViewport($scope.scale);
                        $scope.height = viewport.height;
                        $scope.width = viewport.width;
                        var pageRendering = page.render({
                            canvasContext   : context,
                            viewport        : viewport
                        });
                        $scope.$apply();

                        var pageComplete = pageRendering.internalRenderTask.callback;
                        pageRendering.internalRenderTask.callback = function(error) {
                            pageComplete.call(this, error);
                            convertCanvasToImage();
                        }
                    });
                }
            };

            $scope.showImage = false;

            var convertCanvasToImage = function() {
                $log.debug('[PdfViewerDirective] convertCanvasToImage()');
                //extract data uri from the rendered canvas
                var pdfImage = canvas[0].toDataURL();
                $scope.imageSrc = $sce.trustAsResourceUrl(pdfImage);
                //manually add the xlink:href source and avoid some of the angular stupidity
                //var image = $(element).find('.pdfImage');
                image = element[0];  //TODO fix this because it's fragile
                image.setAttribute('xlink:href', $scope.imageSrc);
                //toggle the image visible to avoid showing the initial dummy image
                $scope.showImage = true;
                dispatcher.dispatch('PdfViewer-DocumentLoaded', $scope.componentId, {data: $scope.data});
                $scope.$apply();
            };

            var handlePageChange = function(event, data) {
                $log.debug('[PdfViewerDirective] handlePageChange: ' + data);
                var page = data['page'];
                if ( !isNaN(page) ) {
                    $scope.page = page;
                    $scope.loading = true;
                    if (activityModel.share) {
                        $scope.ref.child('page').set($scope.page);
                    } else {
                        gotoPage($scope.page);
                    }
                }
            };

            var handleScaleChange = function(event, data) {
                $log.debug('[PdfViewerDirective] handleScaleChange: ' + data);
                var scale = data['scale'];
                if ( !isNaN(scale) ) {
                    $scope.scale = scale;
                    $scope.loading = true;
                    if (activityModel.share) {
                        $scope.ref.child('scale').set($scope.scale);
                    } else {
                        gotoPage($scope.page);
                    }
                }
            };

            /***************************
             *  initialize
             *************************/
            var initialize = function() {
                $log.debug('[PdfViewerDirective] init()');
                var data = $scope.data;
                $scope.width = data.width;
                $scope.height = data.height;
                $scope.x = data.x;
                $scope.y = data.y;
                loadState();
                canvas = createCanvas();
                addToAnchor(canvas);
                context = canvas[0].getContext('2d');

                dispatcher.addListener('pdfToolbar-PageChangeEvent', data.id, handlePageChange, this);
                dispatcher.addListener('pdfToolbar-ScaleChangeEvent', data.id, handleScaleChange, this);

            };
            initialize();
        }
    }
};

PdfViewerDirective.$inject = ['$log', '$compile', '$sce', '$timeout', 'firebaseModel', 'activityModel', 'dispatcher'];
module.exports = PdfViewerDirective;
