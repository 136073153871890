/**
 * Service for retrieving Giphy search results
 */

const giphyKey = 'OArVRbIcBmsAU';
const giphyBaseUrl = 'https://api.giphy.com/v1/gifs';
class GiphyService {
    constructor($http, $q) {
        this._http = $http;
        this._q = $q;
    }

    /**
    *
    *
    * @param terms (required) - some set of search terms as an array of strings or as one string delimited by spaces
    * @param rating (optional) - filter to y,g, pg, pg-13 or r rated gifs. Argument is the desired rating as a string.
    * @param offset (optional) - offset to start returning results from
    * @return - array of gif urls
    */
    search(terms, rating, offset) {
        const deferred = this._q.defer();
        let searchUrl = giphyBaseUrl + '/search?api_key=' + giphyKey;
        let query = terms.constructor === Array ? terms.join('+') : terms;
        query = escape(query);
        searchUrl += '&q=' + query;
        if (rating) {
            searchUrl += '&rating=' + rating;
        }
        if (offset) {
            searchUrl += '&offset=' + offset;
        }
        this._http.get(searchUrl).then((result) => {
            const data = result.data.data;
            const output = [];
            data.forEach((datum) => {
                const item = {
                    url: datum.images.downsized.url,
                    previewUrl: datum.images.fixed_width.url,
                    previewSmallUrl: datum.images.fixed_width_small.url,
                };
                output.push(item);
            });
            deferred.resolve(output);
        });
        return deferred.promise;
    }

}

GiphyService.$inject = ['$http', '$q'];
export default GiphyService;
