var SortedCollectionService = function($log) {

    var baseRef = null;
    var eventCallback = null;
    var elements = [];
    var listeners = [];

    /*********************************
     * API METHODS
     ********************************/
    this.createCollection = function(firebaseRef, callback) {
        $log.debug('[SortedCollection] createCollection');
        baseRef = firebaseRef;
        eventCallback = callback;
        resetList();
        setupListeners();
        setupApi();
        //return the list
        return elements;
    };

    var generateId = function() {
        $log.debug('[SortedCollection] creating sorted key');
        var childRef = baseRef.push();
        return childRef.key;
    };

    var add = function(key, element, priority) {
        $log.debug('[SortedCollection] add');
        if (arguments.length == 2) {
            //push and set
            set(key, element);
        } else if (arguments.length == 3) {
            //push and set with priority
            setWithPriority(key, element, priority);
        }
    };

    var set = function(key, value) {
        $log.debug('[SortedCollection] set');
        baseRef.child(key).set(value, _.bind(handleComplete, this));
    };

    var setWithPriority = function(key, value, priority) {
        baseRef.child(key).setWithPriority(value, priority);
    };

    var update = function(key, value) {
        $log.debug('[SortedCollection] update');
        baseRef.child(key).update(value, _.bind(handleComplete, this));
    };

    var bulkUpdate = function(bulkObject) {
        $log.debug('[SortedCollection] bulk update');
        baseRef.update(bulkObject, _.bind(handleComplete, this));
    };

    var setPriority = function(key, priority) {
        $log.debug('[SortedCollection] setPriority');
        baseRef.child(key).setPriority(priority);
    };

    var remove = function(key) {
        $log.debug('[SortedCollection] remove');
        baseRef.child(key).remove(_.bind(handleComplete, this));
    };

    var removeAll = function() {
        $log.debug('[SortedCollection] removing all from the collection');
        baseRef.remove();
    };


    var getElement = function(key) {
        $log.debug('[SortedCollection] getElement');
        var i = findKeyIndex(key, true);
        if (i != -1) {
            return elements[i];
        }
    };

    /*********************************
     * PRIVATE METHODS
     ********************************/
    var resetList = function() {
        //clean up?
        elements = [];
    };

    var setupListeners = function() {
        addListener('child_added', handleChildAdded);
        addListener('child_removed', handleChildRemoved);
        addListener('child_changed', handleChildChanged);
        addListener('child_moved', handleChildMoved);
        addListener('value', handleValueChange);
    };

    var setupApi = function() {
        elements.indexOf = _.bind(findKeyIndex, this);
        elements.generateId = _.bind(generateId, this);
        elements.add = _.bind(add, this);
        elements.set = _.bind(set, this);
        elements.setWithPriority = _.bind(setWithPriority, this);
        elements.update = _.bind(update, this);
        elements.bulkUpdate = _.bind(bulkUpdate, this);
        elements.setPriority = _.bind(setPriority, this);
        elements.remove = _.bind(remove, this);
        elements.removeAll = _.bind(removeAll, this);
        elements.getElement = _.bind(getElement, this);
    };

    var handleChildAdded = function(snapshot, previousChildId) {
        $log.debug('[SortedCollection] handleChildAdded');
        moveTo(snapshot.key, snapshot.val(), previousChildId);
        dispatchEvent('child_added', snapshot.key, snapshot.val());
    };

    var handleChildRemoved = function(snapshot) {
        $log.debug('[SortedCollection] handleChildRemoved');
        var key = snapshot.key;
        var index = findKeyIndex(key, true);
        if (index !== -1) {
            elements.splice(index, 1);
            dispatchEvent('child_removed', snapshot.key, snapshot.val());
        }
    };

    var handleChildChanged = function(snapshot) {
        $log.debug('[SortedCollection] handleChildChanged');
        var key = snapshot.key;
        var index = findKeyIndex(key, true);
        if (index != -1) {
            elements[index] = updateObject(elements[index], snapshot.val());
            dispatchEvent('child_changed', snapshot.key, snapshot.val());
        }
    };

    var handleChildMoved = function(snapshot, previousChildId) {
        $log.debug('[SortedCollection] handleChildMoved');
        var key = snapshot.key;
        var oldIndex = findKeyIndex(key, true);
        if (oldIndex != -1) {
            var data = elements[oldIndex];
            elements.splice(oldIndex, 1);
            moveTo(key, data, previousChildId);
            dispatchEvent('child_moved', snapshot.key, data, previousChildId);
        }
    };

    var handleValueChange = function() {
        $log.debug('[SortedCollection] handleValueChange');
    };

    var dispatchEvent = function(eventType, elementId, data, previousChildId) {
        eventCallback && eventCallback(eventType, elementId, data, previousChildId);
    };

    //mutation
    var moveTo = function(key, value, previousChildId) {
        var index = findIndex(key, previousChildId);
        elements.splice(index, 0, value);
    };

    var updateObject = function(oldValue, newValue) {
        //we can do an incremental add here instead
        return newValue;
    };

    var findIndex = function(key, prevChildId) {
        var index;
        if (prevChildId === null) {
            index = 0;
        } else {
            var i = findKeyIndex(prevChildId, true);
            if (i === -1) {
                index = elements.length;
            } else {
                index = i + 1;
            }
        }
        return index;
    };

    var findKeyIndex = function(key, suppressVerify) {
        var index = -1;
        for (var i = 0; i < elements.length; i++) {
            var element = elements[i];
            if (elements[i].name === key) {
                index = i;
                if (suppressVerify != true) {
                    if (i !== element.index) {
                        _.defer(function() {
                            element.index = index;
                            update(element.name, element);
                            setPriority(element.name, element.index);
                        });
                    }
                }
                break;
            }
        }
        return index;
    };

    var addListener = function(event, handler) {
        listeners.push([event, baseRef.on(event, handler.bind(this))]);
    };

    var handleComplete = function(error) {
        if (error) {
            $log.debug('[SortedCollection] fb sync error: ' + error);
        } else {
            $log.debug('[SortedCollection] fb sync complete');
        }
    };

    var init = function() {
        $log.debug('[SortedCollectionService] creating SortedCollectionService');
    };
    init();

};

SortedCollectionService.$inject = ['$log'];
module.exports = SortedCollectionService;
