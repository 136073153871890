import applicationsModule from 'pl-core/src/modules/applications';

import plApis from 'pl-apis';
import plButtons from 'pl-buttons';
import plInputs from 'pl-inputs';
import plModels from 'pl-models';
import plModules from 'pl-modules';
import plNavs from 'pl-navs';
import plOverlays from 'pl-overlays';
import plRecords from 'pl-records';

require('babel-polyfill');

function App($urlRouterProvider) {
    // $urlRouterProvider.otherwise('/activities');
}
App.$inject = ['$urlRouterProvider'];

const app = angular.module('toychest', [
    'ngAnimate',
    'ngResource',
    'ngSanitize',
    'LocalStorageModule',
    'ui.router',
    'cfp.hotkeys',
    'dibari.angular-ellipsis',

    require('../../../.tmp/toychest/config').name,

    require('pl-core').name,
    require('pl-queue').name,
    require('toys').name,
    require('firebasemock').name,

    plApis.name,
    plButtons.name,
    plInputs.name,
    plModels.name,
    plModules.name,
    plNavs.name,
    plOverlays.name,
    plRecords.name,

    // common items in store
    require('./common/models').name,
    require('./common/resolvers').name,
    require('./common/directives').name,
    require('./common/helpers').name,

    // module level items
    require('./modules/store').name,
    require('./modules/store/activities').name,
    require('./modules/store/activityCreation').name,
    require('./modules/store/assessments').name,
    require('./modules/store/lessons').name,
    require('./modules/store/games').name,
    require('./modules/store/assets').name,

    require('./modules/store/uploader').name,
    require('./modules/store/preview').name,
    applicationsModule.name,

], App);

function Config($stateProvider,
    $logProvider,
    $urlRouterProvider,
    $resourceProvider,
    $locationProvider,
    $urlMatcherFactoryProvider
) {
    // global config settings
    $locationProvider.html5Mode({
        enabled: true,
        requireBase: false,
    });
    $resourceProvider.defaults.stripTrailingSlashes = false;
    $urlMatcherFactoryProvider.strictMode(false);

    // disable debug logging
    $logProvider.debugEnabled(true);

    // create root state
    $stateProvider
        .state('app', {
            abstract: true,
            url: '',
            templateUrl: '/toychest/common/templates/master.tpl.html'
        });
}
Config.$inject = [
    '$stateProvider',
    '$logProvider',
    '$urlRouterProvider',
    '$resourceProvider',
    '$locationProvider',
    '$urlMatcherFactoryProvider',
];
app.config(Config);

function AnalyticsConfig(analytics_conf) {
    if (analytics_conf.sentry != '') {
        Raven.config(analytics_conf.sentry, {}).install();
    }
}
AnalyticsConfig.$inject = ['analytics_conf'];
app.config(AnalyticsConfig);

function APIInterceptor($httpProvider) {
  $httpProvider.interceptors.push(['plAppRecorder', function (plAppRecorder) {
    return plAppRecorder.apiInterceptorConfig();
  }]);
}

APIInterceptor.$inject = ['$httpProvider'];
app.config(APIInterceptor);

function AuthInterceptorConfig($httpProvider) {
    $httpProvider.interceptors.push([
        'authInterceptor',
        '$window',
        '$injector', (authInterceptor, $window, $injector) => {
            authInterceptor.onUnauthorized(() => {
                $injector.get('authenticationService').login();
            });
            return authInterceptor;
        },
    ]);
}
AuthInterceptorConfig.$inject = ['$httpProvider'];
app.config(AuthInterceptorConfig);

function LocalStorageConfig(localStorageServiceProvider) {
    localStorageServiceProvider.setPrefix('toychest');
}
LocalStorageConfig.$inject = ['localStorageServiceProvider'];
app.config(LocalStorageConfig);

function StateChangeError($rootScope, stateChangeErrorHandler) {
    function stateChangeError(event, toState, toParams, fromState, fromParams, error) {
        event.preventDefault();
        stateChangeErrorHandler.handleError(event, toState, toParams, fromState, fromParams, error);
    }
    $rootScope.$on('$stateChangeError', stateChangeError);
}

StateChangeError.$inject = ['$rootScope', 'stateChangeErrorHandler'];
app.run(StateChangeError);


function Run($rootScope,
    $location,
    $log,
    $state,
    $stateParams,
    sha,
    flavor,
    lastCommit,
    authenticationService,
    versions,
    plAppRecorder,
    appName) {
    $rootScope.log = (value) => {
        console.log(value);
        return value;
    };

    $rootScope.debug = (value) => {
        debugger;
        return value;
    };

    // set the default format for moment string rendering
    moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ss';

    // these are for convenience, see ui-router docs
    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;
    $rootScope.pageTitle = 'Presence';
    $rootScope.sha = sha;
    $rootScope.flavor = flavor;
    $rootScope.lastCommit = lastCommit;
    $rootScope.appName = appName;

    PDFJS.workerSrc = '/toychest/js/pdfjs/' + versions.pdfjs + '/pdf.worker.js';

    authenticationService.afterLogout(() => {
        authenticationService.redirectToAuth();
    });

    plAppRecorder.appInit($rootScope, $location);
}
Run.$inject = [
    '$rootScope',
    '$location',
    '$log',
    '$state',
    '$stateParams',
    'sha',
    'flavor',
    'lastCommit',
    'authenticationService',
    'versions',
    'plAppRecorder',
    'appName',
];
app.run(Run);
