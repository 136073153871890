var UploadImageService = function(imageAssetModel) {

    /**
     *
     * @type {Array} Array of assetModels
     */
    this.models = [];
    // @TODO: move to config
    this.numberOfSimultaneousUploads = 5;
    this.lastUploadedImageIndex = -1;
    this.isUploading = false;

    /**
     *
     * Gets array of image files and returns
     * array of assetModels binded to such files
     *
     * @param files Files that contain required images
     * @returns {Promise}
     */
    this.initImageUploads = function(files, isPublic) {

        var promises = [];
        var me = this;

        _.forEach(files, function(file) {
            var currentModel = new imageAssetModel.Model();
            var promise = currentModel.initWithParameters(file, !isPublic);

            me.models.push(currentModel);
            promises.push(promise);
        });

        return Promise.all(promises);
    };

    /**
     *
     * Starts upload of previously added (via initImageUploads)
     * images
     *
     * @param generationCallback
     * @param downloadCallback
     */
    this.startImageUploads = function(generationCallback, downloadCallback, downloadFailedCallback) {

        this.isUploading = true;

        var promises = [];
        var generationPromises = [];

        var me = this;

        this.models = this.models.filter( (model) => {
            return model.valid;
        });

        _.forEach(this.models, (model) => {

            var generationPromise = model.generateCardImage(model.xOffset, model.yOffset);

            generationPromises.push(generationPromise);
        });

        Promise.all(generationPromises).then((models) => {
            generationCallback(models);

            var uploadNext = function() {

                if (me.models.length > 0) {
                    var model = me.models[0];
                    var promise = model.uploadImages();

                    promise.then(
                        (uploadedModel) => {
                            downloadCallback([uploadedModel]);
                            uploadNext();
                        },
                        (failedModel) => {
                            failedModel.uploadFailed = true;
                            downloadFailedCallback([failedModel]);
                            uploadNext();
                        }
                    );

                    me.models.shift();

                    promises.push(promise);
                } else {
                    me.isUploading = false;
                }
            };

            // Make first 3 uploads
            for (var i = 0; i < me.numberOfSimultaneousUploads; i++) {
                uploadNext();
            }
        });

    };

    /**
     * Returns array of image asset models stored in service
     * @returns {Array} Array of image asset models
     */
    this.getModels = function() {
        return this.models;
    };

    /**
     * Clears image models array
     */
    this.clearImageData = function() {
        this.models = [];
    };
};

UploadImageService.$inject = ['imageAssetModel'];
module.exports = UploadImageService;