function plInputUploadDirective() {
    return {
        restrict: 'E',
        templateUrl: '/core/pl-inputs/src/plInputUpload/plInputUpload.tpl.html',
        scope: {
            onFiles: '&',
            mimeTypes: '@',
            multiple: '@?',
            keep: '@?',
            disabled: '=?',
        },
        link: ($scope, $element, attrs) => {
            $scope.files = [];
            $scope.keep = ( $scope.keep !== undefined ) ? $scope.keep : 'distinct';
            // Need extra quotes.
            if($scope.keep === 'distinct') {
                $scope.keep = '\'distinct\'';
            }
            $scope.multiple = ( $scope.multiple !== undefined ) ? $scope.multiple : false;

            $scope.upload = function upload(files) {
                $scope.onFiles()({files: files});
            };
        },
    };
}

module.exports = plInputUploadDirective;
