function plBillingCodesFactory(plHttp, $q, plLodash) {
    const billing = {};
    const _billing = {};
    // current transient copy (e.g. for api-filtered codes)
    let _codes;
    // permanent cached unfiltered copy
    let _codesUnfiltered;

    _billing.eventFilters = [
        {
            key: 'clients',
            backend: ['Work with Clients'],
            name: 'Work with Clients',
        },
        {
            key: 'student_absence',
            backend: ['Absent'],
            name: 'Student Absence',
        },
        {
            key: 'documentation_planning',
            backend: ['Documentation and Planning'],
            name: 'Documentation & Planning',
        },
        {
            key: 'cancellation',
            backend: ['Cancelled'],
            name: 'Cancellation',
        },
        {
            key: 'personal',
            backend: ['Personal'],
            name: 'Personal',
        },
        {
            key: 'other',
            backend: ['Other'],
            name: 'Other',
        },
    ];

    billing.getFilters = (billingInfo) => {
        if (billingInfo.event_category && billingInfo.event_category.name) {
            for (let ii = 0; ii < _billing.eventFilters.length; ii++) {
                if (_billing.eventFilters[ii].backend.indexOf(billingInfo.event_category.name)
                 > -1) {
                    return _billing.eventFilters[ii];
                }
            }
        }
        return null;
    };

    billing.formFiltersOpts = () => {
        return _billing.eventFilters.map((filter) => {
            return {
                value: filter.key,
                label: filter.name,
                class: filter.key,
            };
        });
    };

    billing.get = (params = {}) => {
        const deferred = $q.defer();

        const noParams = (Object.keys(params).length === 0) ? true : false;
        if (_codesUnfiltered && noParams) {
            _codes = _codesUnfiltered;
            deferred.resolve(_codes);
        } else {
            plHttp.get('billingCodes', params)
                .then((res) => {
                    _codes = res.data.results;
                    if (noParams) {
                      _codesUnfiltered = _codes;
                    }
                    deferred.resolve(_codes);
                }, (err) => {
                    deferred.reject(err);
                });
        }
        return deferred.promise;
    };

    billing.formatCodes = (format = 'raw', codes1 = null) => {
        let codes = codes1 ? codes1 : _codes;
        if (format === 'groupByCreationCategory') {
            codes = _billing.groupByCreationCategory(codes);
        }
        return codes;
    };

    billing.removeCode = (code) => {
        _codes = _codes.filter( (item) => {
            return item.code !== code; 
        });
    };

    _billing.groupByCreationCategory = (codes) => {
        const groupedCodes = {};
        let category;
        codes.forEach((code) => {
            category = plLodash.snakeCase(code.event_creation_category);
            if (category) {
                if (!groupedCodes[category]) {
                    groupedCodes[category] = [];
                }
                groupedCodes[category].push(plLodash.pick(code, ['uuid', 'name', 'event_creation_category',
                 'client_participates', 'location_participates', 'event_repeatable', 'can_provide']));
            }
        });
        return groupedCodes;
    };

    billing.formCreationCategoryOpts = (groupedCodes) => {
        const opts = [];
        for (let category in groupedCodes) {
            if (groupedCodes[category].length) {
                opts.push({
                    value: category,
                    label: groupedCodes[category][0].event_creation_category,
                });
            }
        }
        return opts;
    };

    billing.formOneCategoryOpts = (groupedCodes, category) => {
        let opts = [];
        if (!groupedCodes[category]) {
            return opts;
        }
        groupedCodes[category].forEach((code) => {
            opts.push({
                value: code.uuid,
                label: code.name,
                canProvide: code.can_provide,
            });
        });
        return opts;
    };

    billing.formAllOpts = (codes1 = null, activeOnly = false) => {
        const codes = codes1 ? codes1 : _codes;
        const codeOpts = [];
        codes.forEach((code) => {
            if (!activeOnly || code.is_active) {
                codeOpts.push({
                    value: code.uuid,
                    label: code.name,
                    canProvide: code.can_provide,
                });
            }
        });
        return codeOpts;
    };

    billing.codeInfo = (uuid) => {
        if (!uuid) {
            return null;
        }
        const index = plLodash.findIndex(_codes, 'uuid', uuid);
        return (index > -1) ? _codes[index] : null;
    };

    billing.codeCategory = (uuid, groupedCodes) =>  {
        let selectedCategory = '';
        for (let category in groupedCodes) {
            for (let ii = 0; ii < groupedCodes[category].length; ii++) {
                if (groupedCodes[category][ii].uuid === uuid) {
                    selectedCategory = category;
                    break;
                }
            }
        }
        return selectedCategory;
    };

    billing.filterClientParticipates = (codes) => {
        return codes.filter((code) => {
            return code.client_participates !== 'NONE' ? true : false;
        });
    };

    billing.filterLocationParticipates = (codes) => {
        return codes.filter((code) => {
            return code.location_participates !== 'NONE' ? true : false;
        });
    };

    billing.filterNoClientNorLocationParticipates = (codes) => {
        return codes.filter((code) => {
            return (code.location_participates === 'NONE' && code.client_participates === 'NONE')
             ? true : false;
        });
    };

    // Filter to billing codes that match a particular codes participates value.
    billing.filterParticipates = (codeInfo, codes) => {
        if (!codeInfo) {
            return codes;
        }
        if (codeInfo.client_participates !== 'NONE') {
            return billing.filterClientParticipates(codes);
        } else if (codeInfo.location_participates !== 'NONE') {
            return billing.filterLocationParticipates(codes);
        } else {
            return billing.filterNoClientNorLocationParticipates(codes);
        }
    };

    billing.isClientServiceRequired = (billingCodeUuid) => {
        // Some billing codes do NOT have any client services (just client).
        const billingInfo = billing.codeInfo(billingCodeUuid);
        if (billingInfo && billingInfo.services && billingInfo.services.length) {
            return true
        } else {
            return false;
        }
    };

    return billing;
}
plBillingCodesFactory.$inject = ['plHttp', '$q', 'plLodash'];
module.exports = plBillingCodesFactory;
