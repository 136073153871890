/**
 * Equivalent to ActivityService but using LessonModel instead of ActivityModel
 */

 var LessonService = function($log, $rootScope, $location, LessonModel, guidService) {

  this.startup = (activity) => {
    console.log('startup lesson: ', activity);
      if(activity.id === LessonModel.activity.activityId) {
          return LessonModel.foundationLoaded;
      }
      this.reset();
      $log.debug("[LessonService] startup");
      LessonModel.setActivityId(activity.id);
      LessonModel.setConfigId(activity.slug);
      LessonModel.setType(activity.resource_type);
      var shareId = activity.session_id;
      if (shareId) {
          //enable firebase
          LessonModel.setSessionId(shareId);
          LessonModel.setShared(true);
          $log.debug("[LessonService] sharing enabled.");
      } else {
          //skip firebase
          LessonModel.setSessionId(guidService.generateUUID());
          LessonModel.setShared(false);
          $log.debug("[LessonService] sharing disabled.");
      }
      LessonModel.initialize();
      return LessonModel.foundationLoaded;
  };

  this.reset = function() {
      LessonModel.reset();
  };
};

LessonService.$inject = ['$log', '$rootScope', '$location', 'LessonModel', 'guidService'];
module.exports = LessonService;
