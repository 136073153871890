/**
TODO
- better handling of browsers that do not support time input masking / formatting
    - Firefox, Safari, IE, other?
*/

function plInputTimeDirective(plInputService, $timeout) {
    // function isTimeSupported() {
    //     try {
    //         var input = document.createElement("input");
    //         input.type = "time";
    //         if (input.type === "time") {
    //             return true;
    //         } else {
    //             return false;
    //         }
    //     } catch(e) {
    //         return false;
    //     }
    // }

    return {
        restrict: 'E',
        require: '?^form',
        scope: {
            model: '=',
            options: '=',
            focus: '&?',
            blur: '&?',
            change: '&?',
            disabled: '=?',
            required: '=?',
            name: '@?',
            label: '@?',
            maxHeight: '=?',
        },
        replace: true,
        templateUrl: '/core/pl-inputs/src/plInputTime/plInputTime.tpl.html',
        link: ($scope, $element, $attrs, formCtrl) => {
            plInputService.createNameIfNone($scope);
            plInputService.setupOnChange($scope, $attrs);
            plInputService.setupFocusAndBlur($scope, $attrs);
            plInputService.addInputToFormForValidation($scope, $element, formCtrl);
            plInputService.setupSelectDropdown($scope, $element, $attrs, {scrollToTime : true});

            $scope.getStateClasses = function() {
                return {
                    focused: $scope.focused,
                    disabled: $scope.disabled,
                    invalid: plInputService.isTextStyleInputInvalid($scope, formCtrl),
                };
            };

            $scope.type = 'time';

            // // The native time input (when supported) takes a date object as the value.
            // // So we'll convert that to a string for use.
            // const timeFormat = 'HH:mm A';

            // function alterOpts() {
            //     $scope.options = $scope.options.map((opt) => {
            //         return {
            //             value: moment(opt.value).format(timeFormat),
            //             label: opt.label,
            //         }
            //     });
            // }

            // function modelToInput() {
            //     $scope.modelInput = moment($scope.model).format(timeFormat);
            //     return $scope.modelInput;
            // }

            // function inputToModel() {
            //     if (!timeSupport) {
            //         $scope.model = moment($scope.modelInput, timeFormat).toDate();
            //     } else {
            //         $scope.model = $scope.modelInput;
            //     }
            //     return $scope.model;
            // }

            // const timeSupport = isTimeSupported();

            // $scope.type = timeSupport ? 'time' : 'text';
            // $scope.modelInput = timeSupport ? $scope.model : modelToInput();

            // if (!timeSupport) {
            //     alterOpts();

            //     $scope.$watch('options', (newVal, oldVal) => {
            //         if (!angular.equals(oldVal, newVal)) {
            //             alterOpts();
            //         }
            //     });
            // }

            // $scope.$watch('modelInput', (newVal, oldVal) => {
            //     if (!angular.equals(oldVal, newVal)) {
            //         inputToModel();
            //     }
            // });
        },
    };
}

plInputTimeDirective.$inject = ['plInputService', '$timeout'];
module.exports = plInputTimeDirective;
