function plDateTimeRangeDirective(plDateTimeRange, plTimezone, $timeout) {
    function dateTimesToPieces(model = {}, dateFormat, timeFormat) {
        const dateTimes = {};
        // Only continue if have both a start and end.
        if (!model.start || !model.end) {
            // Default to now, with end date same as start date and end
            // time 30 minutes after start time.
            const nowTime = moment();
            dateTimes.startDate = nowTime.format(dateFormat);
            dateTimes.startTime = nowTime.format(timeFormat);
            dateTimes.endDate = nowTime.format(dateFormat);
            dateTimes.endTime = nowTime.add(30, 'minutes').format(timeFormat);
            dateTimes.timezone = model.timezone || plTimezone.guessTimezone();
            return dateTimes;
        }

        // Convert the datetime to the user's timezone.
        dateTimes.timezone = model.timezone || plTimezone.guessTimezone() ||
         plTimezone.getZone(moment.tz(model.start, plTimezone.formatDateTime));

        const localStart = moment.tz(model.start, plTimezone.formatDateTime, dateTimes.timezone);
        dateTimes.startTime = localStart.format(timeFormat);
        dateTimes.startDate = localStart.format(dateFormat);
        const localEnd = moment.tz(model.end, plTimezone.formatDateTime, dateTimes.timezone);
        dateTimes.endTime = localEnd.format(timeFormat);
        dateTimes.endDate = localEnd.format(dateFormat);

        return dateTimes;
    }

    function updateModel(dateTimes, dateFormat, timeFormat, dateTimeFormat) {
        // Combine start & end date with time and handle timezone.
        const startTime = dateTimes.startTime;
        const start = moment.tz(`${dateTimes.startDate} ${startTime}:00`, dateTimeFormat, dateTimes.timezone);
        const endTime = dateTimes.endTime;
        const end = moment.tz(`${dateTimes.endDate} ${endTime}:00`, dateTimeFormat, dateTimes.timezone);
        const modelVals = {
            start: start.format(dateTimeFormat),
            end: end.format(dateTimeFormat),
            timezone: dateTimes.timezone,
        };
        modelVals.valid = (modelVals.start < modelVals.end) ? true : false;
        if (!modelVals.valid) {
            modelVals.start = '';
            modelVals.end = '';
        }
        return modelVals;
    }

    return {
        restrict: 'E',
        scope: {
            model: '=',
            disabled: '=?',
            change: '&?',
        },
        replace: true,
        templateUrl: '/core/pl-inputs/src/plDateTimeRange/plDateTimeRange.tpl.html',
        link: ($scope) => {
            $scope.dateFormat = 'YYYY-MM-DD';
            $scope.timeFormat = 'HH:mm';
            $scope.dateTimeFormat = `${$scope.dateFormat} ${$scope.timeFormat}:ss`;

            let dateTimeVals = {};

            $scope.dateTimes = {};

            $scope.timeOptsStart = plDateTimeRange.formTimeOpts();
            $scope.timeOptsEnd = plDateTimeRange.formTimeOpts();

            let inited = false;
            let skipModelUpdate = false;

            function init() {
                inited = false;
                $scope.touched = false;
                $scope.dateTimes = dateTimesToPieces($scope.model, $scope.dateFormat, $scope.timeFormat);
                // Set in case no changes are made; always want timezone to be set.
                $scope.model.timezone = $scope.dateTimes.timezone;
                dateTimeVals = {
                    startDate: $scope.dateTimes.startDate,
                    startTime: $scope.dateTimes.startTime,
                    endDate: $scope.dateTimes.endDate,
                    endTime: $scope.dateTimes.endTime,
                };
                // Prevent date time auto changes on load.
                $timeout(() => {
                    inited = true;
                }, 750);
            }
            init();
            $scope.$watch('model', (newVal, oldVal) => {
                if (!skipModelUpdate && !angular.equals(oldVal, newVal)) {
                    init();
                }
            });

            $scope.changeDateTime = (key) => {
                // Do not change on initial load.
                if (inited) {
                    skipModelUpdate = true;
                    const startDate = $scope.dateTimes.startDate ?
                     moment($scope.dateTimes.startDate, $scope.dateFormat) : null;
                    const endDate = $scope.dateTimes.endDate ?
                     moment($scope.dateTimes.endDate, $scope.dateFormat) : null;
                    const startTime = $scope.dateTimes.startTime ?
                     moment($scope.dateTimes.startTime, $scope.timeFormat) : null;
                    const endTime = $scope.dateTimes.endTime ?
                     moment($scope.dateTimes.endTime, $scope.timeFormat) : null;
                    const oldValFormat = (key === 'startDate' || key === 'endDate') ?
                     $scope.dateFormat : $scope.timeFormat;
                    const oldVal = moment(dateTimeVals[key], oldValFormat);
                    const updates = plDateTimeRange.guessChange(startDate, startTime,
                     endDate, endTime, key, oldVal);
                    // Update values on $scope
                    for (let updateKey in updates) {
                        if (updateKey === 'startDate' || updateKey === 'endDate') {
                            $scope.dateTimes[updateKey] = updates[updateKey].format($scope.dateFormat);
                        } else {
                            $scope.dateTimes[updateKey] = updates[updateKey].format($scope.timeFormat);
                        }
                    }

                    // Update time opts if necessary
                    if (key === 'startTime' || updates.startTime) {
                        const startString = updates.startTime ? updates.startTime.format($scope.timeFormat)
                         : startTime.format($scope.timeFormat);
                        $scope.timeOptsEnd = plDateTimeRange.formTimeOpts(startString, 24, 30,
                         $scope.dateTimes.startDate, $scope.dateTimes.endDate);
                    }

                    // Save the values for next time.
                    dateTimeVals = {
                        startDate: $scope.dateTimes.startDate,
                        startTime: $scope.dateTimes.startTime,
                        endDate: $scope.dateTimes.endDate,
                        endTime: $scope.dateTimes.endTime,
                    };

                    const model = updateModel($scope.dateTimes, $scope.dateFormat,
                     $scope.timeFormat, $scope.dateTimeFormat);
                    $scope.model.start = model.start;
                    $scope.model.end = model.end;
                    $scope.model.valid = model.valid;
                    $scope.touched = true;

                    if ($scope.change) {
                        $scope.change();
                    }

                    $timeout(() => {
                        skipModelUpdate = false;
                    }, 250);
                }
            };
        },
    };
}

plDateTimeRangeDirective.$inject = ['plDateTimeRange', 'plTimezone', '$timeout'];
module.exports = plDateTimeRangeDirective;
