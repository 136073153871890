const KEYS = {
  PL_TABLE_PAGE_LENGTH: 'PL-TABLE-PAGE-LENGTH'
};

function UserPreferences () {
  let _obj = localStorage.preferences = localStorage.preferences || JSON.stringify({});

  return {
    set: (key, value) => {
      const temp = JSON.parse(_obj);
      temp[key] = value;
      localStorage.preferences = _obj = JSON.stringify(temp);
    },
    get: key => {
      return null; // DISABLED FOR NOW Aug 23, 2016 (JH)
      return JSON.parse(_obj)[key]
    },
    getPreferences: () => {
      return JSON.parse(_obj);
    },
    KEYS: KEYS
  }
}

UserPreferences.$inject = [];
module.exports = UserPreferences;

