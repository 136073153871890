module.exports = angular.module('toychest.store.activities', [])
    .constant('activityTags', require('./ActivityTags'))

    .controller('ActivitiesFeaturedController', require('./ActivitiesFeaturedController'))
    .controller('ActivitiesController', require('./ActivitiesController'))
    .controller('ActivitiesListController', require('./ActivitiesListController'))
    .controller('ActivityDetailController', require('./ActivityDetailController'))

    .directive('activitiesHomeList', require('./ActivitiesHomeListDirective'))
    .directive('activitySearch', require('./ActivitySearchDirective'))
    .directive('activitiesDetailFooter', require('./ActivitiesDetailFooterDirective'))
    .directive('flashcardsPreview', require('./flashcardsPreviewDirective/flashcardsPreviewDirective'))
    .directive('hero', require('./HeroDirective'))

    .service('ActivityTagModel', require('./ActivityTagModel'))
    .service('heroService', require('./HeroService'))

    .config(require('./routes'))
;
