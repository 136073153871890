var SonarDirective = function($log, $timeout) {
    return {
        restrict: 'E',
        scope : {},
        templateUrl: '/toychest/common/directives/sonar/sonar.tpl.html',
        link: function ($scope, $element, $attr) {

             var removeElement = function(){
                $scope.$destroy();
                $element.remove();
            }

            var initialize = function(){
                var animatedDiv = $element.find('.sonar')[0];
                animatedDiv.addEventListener("animationend", removeElement, false);
            }
            initialize();
        }
    }
};

SonarDirective.$inject = ['$log', '$timeout'];
module.exports = SonarDirective;
