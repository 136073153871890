function plAssessmentsFactory(plHttp, $q) {
    const plAssessments = {};

    plAssessments.get = (data = {}) => {
        const deferred = $q.defer();

        plHttp.get('assessments', data)
            .then((res) => {
                deferred.resolve(res.data.results);
            }, (err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    };

    plAssessments.formSelectOpts = (opts) => {
        return opts.map((opt) => {
            return { value: opt.uuid, label: opt.long_name };
        });
    };

    return plAssessments;
}
plAssessmentsFactory.$inject = ['plHttp', '$q'];
module.exports = plAssessmentsFactory;
