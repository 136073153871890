/**
 * StateChangeHandler
 *
 * This class encapsulates the logic that determines how application routing errors will be handled.
 */
class StateChangeErrorHandler  {

    /**
     * Constructor
     * @param $log
     * @param $state
     * @param authenticationService
     */
    constructor($log, $state, authenticationService) {
        this.$log = $log;
        this.$state = $state;
        this.authenticationService = authenticationService;
    }

    /**
     * StateChangeError handler.
     *
     * This is invoked by the top level app when it gets a StateChangeError. This signature matches that error.
     *
     * This could cause an infinite loop. Care should be taken.
     *
     * @param event
     * @param toState
     * @param toParams
     * @param fromState
     * @param fromParams
     * @param error
     */
    handleError(event, toState, toParams, fromState, fromParams, error) {
        if (this.isInvalidActivity(toParams, error)) {
            //invalid activity should go to special page
            this.$log.debug("[StateChangeErrorHandler] Invalid activity.");
            this.$state.go('app.store.invalid');
        } else {
            //otherwise forward to login
            this.authenticationService.login();
        }
    }

    /**
     * Given toParams and error determine if this is an invalid state.
     * @param toParams
     * @param error
     * @returns {boolean}
     */
    isInvalidActivity(toParams, error) {
        if (toParams.hasOwnProperty('slug') && error.status === 404) {
            return true;
        }
        return false;
    };
}

StateChangeErrorHandler.$inject = ['$log', '$state', 'authenticationService'];
module.exports = StateChangeErrorHandler;
