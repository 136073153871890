var DrfAssessmentModel = function($log, $q, $http, drfModel, applications) {

    var Assessment = new drfModel({
        apiUrl: `${applications.platform.url}/api/v2/assessment/`,
    });

    Object.assign(Assessment.Model.prototype, {
        '$use': function() {
            if (!this.hasOwnProperty('resource_uri')) {
                throw new Error('This model hasn\'t been saved yet.');
            }
            var drf = new drfModel({
                apiUrl: this.resource_uri
            });
            var model = new drf.Model().$setKey('use');
            return model.$save().then(() => this.$get());
        },

        '$getViaSlug': function(slug) {
            var url = this.resource_uri + 'slug/' + slug + '/';
            return $http.get(url).then((data) => {
                angular.forEach(data.data, (value, key) => {
                    this[key] = value;
                });

                return this;
            });
        }
    });

    Object.assign(Assessment.Collection.prototype, {});

    return Assessment;
};

DrfAssessmentModel.$inject = ['$log', '$q', '$http', 'drfModel', 'applications'];
module.exports = DrfAssessmentModel;