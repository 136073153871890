import plAvatar from './src/plAvatar/plAvatarDirective.js';
import plButton from './src/plButton/plButtonDirective.js';
import plToggle from './src/plButton/plToggleDirective.js';
import plButtonGroup from './src/plButtonGroup/plButtonGroupDirective.js';

export default angular.module('pl-buttons', [])
    .directive('plAvatar', plAvatar)
    .directive('plButton', plButton)
    .directive('plToggle', plToggle)
    .directive('plButtonGroup', plButtonGroup)
;
