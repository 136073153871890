'use strict';

class Transaction {
    constructor(listener) {
        this.listener = listener;
        this.delay = false;
    }

    delayReturn(delay) {
        if (typeof delay === 'boolean') {
            this.delay = delay;
        }
        return this.delay;
    }

    complete(params) {
        this.listener && this.listener(params);
        return this;
    }
}

class Channel {

    constructor(options) {
        this.em = {};
        if (!options) {
            return this;
        }
        options.onReady && options.onReady();
    }

    static makeDataObj(message) {
        return {
            trans: new Transaction(message.success),
            params: message.params
        };
    }

    call(message) {
        $(this.em).trigger('channel::' + message.method, Channel.makeDataObj(message));
    }
    
    bind(method, listener) {
        $(this.em).bind('channel::' + method, (event, data) => {
            let result = listener(data.trans, data.params);
            if (data.trans.delayReturn()) {
                return;
            }
            data.trans.complete(result);
        });
    }
}


function ChannelService() {
    this.build = function() {
        return new Channel(this.$rootScope);
    }
}
module.exports = ChannelService;