var DrfAssessmentRightsModel = function($log, $q, $http, drfModel, applications) {

    var Assessment = new drfModel({
        apiUrl: applications.auth.rightsUrl,
    });

    Object.assign(Assessment.Model.prototype, {});

    Object.assign(Assessment.Collection.prototype, {});

    return Assessment;
};

DrfAssessmentRightsModel.$inject = ['$log', '$q', '$http', 'drfModel', 'applications'];
module.exports = DrfAssessmentRightsModel;