function plRecordRoomFactory($q, currentUserModel, plTimezone, plHttp, plRecordAppointment,
 plRecordParticipants, plAppointment, plRecord, plRecordMetrics, plLodash) {
    const plRecordRoom = {};
    const _plRecordRoom = {};

    _plRecordRoom.getUser = (userUuid = null) => {
        return userUuid || currentUserModel.user.uuid;
    };

    plRecordRoom.getTodaysClients = (userUuid1 = null, currentUser1 = null) => {
        const deferred = $q.defer();
        const userUuid = _plRecordRoom.getUser(userUuid1);
        const currentUser = currentUser1 || currentUserModel.user || null;

        // Get start and end dates based on current user's timezone.
        const userTz = plTimezone.getUserZone(currentUser);
        const todayDate = plTimezone.getUserToday(currentUser);

        // Get all appointments for today.
        // Convert times to UTC for lookup.
        const queryParams = {
            provider: userUuid,
            start: moment.tz(`${todayDate} 00:00:00`, 'YYYY-MM-DD HH:mm:ss', userTz)
             .utc().format('YYYY-MM-DDTHH:mm'),
            end: moment.tz(`${todayDate} 23:59:00`, 'YYYY-MM-DD HH:mm:ss', userTz)
             .utc().format('YYYY-MM-DDTHH:mm'),
            calendar_view: true,
        };
        plHttp.get('appointments', queryParams)
            .then((res) => {
                res.data.results = plRecordRoom.formatAppointments(res.data.results, currentUser);
                plRecordRoom.getClientsFromAppointments(res.data.results)
                    .then((clients) => {
                        deferred.resolve(clients);
                    }, (err) => {
                        deferred.reject(err);
                    });
            }, (err) => {
                deferred.reject(err);
            });

        return deferred.promise;
    };

    plRecordRoom.formatAppointments = (appointments, user) => {
        let fields = ['start', 'end', 'original_start', 'original_end'];
        let eventTimes;
        appointments.forEach((appointment) => {
            // Convert to user / local timezone.
            // Copy over start and end in case they change. Need original for backend calls.
            appointment.original_start = appointment.original_start;
            appointment.original_end = appointment.original_end;
            eventTimes = plTimezone.convertAppointmentTime(appointment, user, ['start', 'end']);
            appointment.start = eventTimes.start;
            appointment.end = eventTimes.end;
            if (appointment.event) {
                appointment.event = plTimezone.convertAppointmentTime(appointment.event, user, ['start', 'end']);
            }
        });
        return appointments;
    };

    /**
    Will create appointment from `event` if necessary (if `appointmentUuid` is null).
    Will also create record if necessary (if `record.uuid` is null).
    */
    plRecordRoom.saveRecord = (record1, clientUuid, appointmentUuid, event, userUuid1 = null) => {
        const deferred = $q.defer();

        const USER_UUID = userUuid1 || currentUserModel.user.uuid;
        let APPT_UUID = appointmentUuid;

        // Create a copy since we alter the notes format from string to JSON and that can create issues.
        let record = Object.assign({}, record1);

        var reject = (err) => {
            // record.notes = (typeof(record.notes) === 'string') ? JSON.parse(record.notes) : record.notes;
            deferred.reject(err);
        };

        // (jh) - hotfix for PL-3286, TODO: upgrade to latest pl-apis for the "proper fix"
        plAppointment.checkForExisting({ event: event.uuid,
          original_start: event.original_start, original_end: event.original_end }, USER_UUID)
          .then(
            result  => {
              if (result && result.uuid) {
                APPT_UUID = result.uuid;
              }
              plRecordRoom.getAppointment(APPT_UUID, event, USER_UUID)
                .then((apptUuid) => {
                  if (!record.uuid) {
                    record.appointment = apptUuid;
                    record.provider = USER_UUID;
                    record.client = clientUuid;
                  }
                  record = plRecord.formatForBackend(record);
                  plRecord.save(record)
                    .then((resRecord) => {
                      const recordFormatted = plRecordRoom.formatRecord(resRecord, { uuid: apptUuid });
                      deferred.resolve({ record: recordFormatted, apptUuid });
                    }, (err) => {
                      reject(err);
                    });
                }, (err) => {
                  reject(err);
                });
            },
            error => {
              if (console) console.error('Error retrieving appointment', error);
              reject(err);
            }
          );

        return deferred.promise;
    };

    /**
    Will create appointment from `event` if necessary (if `appointmentUuid` is null).
    Will also create record if necessary.
    */
    plRecordRoom.saveMetricsPoint = (metrics, record, clientUuid, appointmentUuid, event) => {
        const deferred = $q.defer();

        const ret = {};
        if (!record.uuid) {
            plRecordRoom.saveRecord(record, clientUuid, appointmentUuid, event)
                .then((resRecord) => {
                    ret.record = resRecord.record;
                    ret.appointmentUuid = resRecord.appointmentUuid;
                    plRecordRoom.saveMetricsPointExistingRecord(metrics, resRecord.record.uuid)
                        .then((resSave) => {
                            ret.metricPoint = resSave.metricPoint;
                            deferred.resolve(ret);
                        }, (err) => {
                            deferred.reject(err);
                        });
                }, (err) => {
                    deferred.reject(err);
                });
        } else {
            plRecordRoom.saveMetricsPointExistingRecord(metrics, record.uuid)
                .then((resSave) => {
                    ret.metricPoint = resSave.metricPoint;
                    deferred.resolve(ret);
                }, (err) => {
                    deferred.reject(err);
                });
        }

        return deferred.promise;
    };

    plRecordRoom.saveMetricsPointExistingRecord = (metrics1, recordUuid) => {
        const deferred = $q.defer();
        const metrics = Object.assign({}, metrics1, {
            record: recordUuid,
        });
        plRecordMetrics.setPoint(metrics)
            .then((resMetricPoint) => {
                deferred.resolve({ metricPoint: resMetricPoint });
            }, (err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    };

    plRecordRoom.getAppointment = (appointmentUuid, event, userUuid) => {
        const deferred = $q.defer();
        if (appointmentUuid) {
            deferred.resolve(appointmentUuid);
        } else {
            plAppointment.saveFromEvent(event, null, userUuid)
                .then((appointment) => {
                    deferred.resolve(appointment.uuid);
                }, (err) => {
                    deferred.reject(err);
                });
        }
        return deferred.promise;
    };

    plRecordRoom.getClientsFromAppointments = (appointments, clientFields1 = []) => {
        const deferred = $q.defer();
        let clients = [];
        const deferreds = [];
        const promises = [];
        appointments.forEach((appointment, index) => {
            deferreds[index] = $q.defer();
            promises.push(deferreds[index].promise);
            plRecordRoom.formAppointmentClients(appointment, clientFields1)
                .then((resClients) => {
                    if (resClients && resClients.length) {
                        clients = clients.concat(resClients);
                    }
                    deferreds[index].resolve();
                }, (err) => {
                    deferreds[index].reject(err);
                });
            
        });
        $q.all(promises)
            .then(() => {
                deferred.resolve(clients);
            }, (err) => {
                deferred.reject(err);
            });

        return deferred.promise;
    };

    plRecordRoom.formAppointmentClients = (appointment, clientFields1 = []) => {
        const deferred = $q.defer();

        if (!appointment.clients || !appointment.clients.length) {
            deferred.resolve([]);
        } else {
            // TODO: do not allow removing required fields - uuid, name, etc.
            const clientFields = (clientFields1 && clientFields1.length) ? clientFields1 : ['uuid', 'first_name', 'last_name'];
            const clients = [];
            let curClient;
            let record;
            let curParticipant;
            plRecordAppointment.formTitle(appointment)
                .then((appointmentTitle) => {
                    const billingCode = appointment.billing_code ? appointment.billing_code :
                     (appointment.event && appointment.event.billing_code) ?
                     appointment.event.billing_code : null;
                    appointment.clients.forEach((client) => {
                        curParticipant = {
                            client_expanded: client,
                            uuid: client.uuid,
                            billing_code: billingCode,
                        };
                        record = plRecordParticipants.findOrFormMatchingRecord(curParticipant, appointment.records);
                        curClient = plLodash.pick(client, clientFields);
                        curClient.appointment = {
                            uuid: appointment.uuid,
                            event: {
                                uuid: appointment.event.uuid,
                                // start: appointment.event.start || appointment.original_start || appointment.start,
                                // end: appointment.event.end || appointment.original_end || appointment.end,
                                original_start: plTimezone.fromTZFormat(appointment.original_start) || plTimezone.fromTZFormat(appointment.start),
                                original_end: plTimezone.fromTZFormat(appointment.original_end) || plTimezone.fromTZFormat(appointment.end),
                                start: appointment.original_start || appointment.start,
                                end: appointment.original_end || appointment.end,
                            },
                            title: appointmentTitle,
                            start: appointment.start,
                            end: appointment.end,
                        };
                        curClient.record = plRecordRoom.formatRecord(record, appointment);
                        clients.push(curClient);
                    });
                    deferred.resolve(clients);
                }, (err) => {
                    deferred.reject();
                });
        }

        return deferred.promise;
    };

    plRecordRoom.formatRecord = (record, appointment) => {
        record = plLodash.omit(record, ['client_expanded', '_participantUuid']);
        record = Object.assign({}, {
            appointment: appointment.uuid,
        }, record);
        if (record.notes) {
            record.notes = (typeof(record.notes) === 'string') ? JSON.parse(record.notes) : record.notes;
        }
        return record;
    };

    return plRecordRoom;
}
plRecordRoomFactory.$inject = ['$q', 'currentUserModel', 'plTimezone', 'plHttp', 'plRecordAppointment',
 'plRecordParticipants', 'plAppointment', 'plRecord', 'plRecordMetrics', 'plLodash'];
module.exports = plRecordRoomFactory;
