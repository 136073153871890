/**
 * Define the application directives
 */
module.exports = angular.module('toychest.common.directives', [])
    .directive('addRating', require('./addRating/AddRatingDirective'))
    .directive('rating', require('./rating/RatingDirective'))
    .directive('tagSwitch', require('./tagSwitch/TagSwitchDirective'))
    .directive('textareaAutoResize', require('./textareaAutoResize/TextareaAutoResizeDirective'))
    .directive('onResize', require('./OnResizeDirective'))
    .directive('activityListItem', require('./activityListItem/activityListItemDirective'))
    .directive('activityPreview', require('./activityPreview/ActivityPreviewDirective'))
    .directive('circularProgressBar', require('./CircularProgressBar/CircularProgressBarDirective'))
    .directive('onDrop', require('./draggable/OnDrop'))
    .directive('onDragenter', require('./draggable/OnDragEnter'))
    .directive('onDragleave', require('./draggable/OnDragLeave'))
    .directive('onDragover', require('./draggable/OnDragOver'))
    .directive('dropZone', require('./dropZone/dropZoneDirective'))
    .directive('fileInputItems', require('./fileInputItems/fileInputItems'))
    .directive('sonar', require('./sonar/SonarDirective'))
    .directive('tagInput', require('./TagInput/TagInputDirective'))
    .directive('tagToggle', require('./TagToggle/TagToggleDirective'))
    .directive('flashcardEditor', require('./Flashcards/FlashcardEditorDirective'))
    .directive('flashcardPreview', require('./Flashcards/FlashcardPreviewDirective'))
    .directive('radialProgress', require('./Flashcards/RadialProgressDirective'))
    .directive('formError', require('./Flashcards/FormErrorDirective'))
    .directive('titleInput', require('./titleInput/TitleInput'))
    .directive('imageEditing', require('./ImageEditingTool/ImageEditingToolDirective'))
    .directive('imageSelection', require('./ImageSelectionDialog/ImageSelectionDirective'))
    .directive('confirmPopup', require('./confirmPopup/ConfirmPopupDirective'))
    .directive('sideNav', require('./sidenav/SidenavDirective'))
    .directive('requestContentButton', require('./requestContentButton/requestContentButton'))
    .directive('newLibraryBanner', require('./newLibraryBanner/NewLibraryBannerDirective'))

;
