/**
 * Model encapsulates firebase references
 * @param $log
 * @param roomnameModel
 */
var FirebaseModel = function ($log, roomnameModel, emulateFirebase) {

    /**
     * Firebase references
     */
    var firebaseObj = {};
    if (!roomnameModel.value) {
        firebaseObj.baseUrl = '';
        firebaseObj.roomname = '';
        firebaseObj.auth_token = '';
    } else {
        firebaseObj.baseUrl = roomnameModel.value.firebase_baseurl;
        firebaseObj.roomname = roomnameModel.value.name;
        firebaseObj.auth_token = roomnameModel.value.firebase_auth_token;
    }

    /**
     * Gets the current roonmame
     * @returns {*}
     */
    this.getRoomname = function () {
        return firebaseObj.roomname;
    };

    /**
     * Gets the current firebase reference by object name
     * @returns {*}
     */
    var getFirebaseRef = function (name) {
        if (firebaseObj.roomname !== undefined || name !== undefined) {
            if (firebaseObj[name] === undefined) {
                firebaseObj[name] = createFirebaseRef(name);
            }
            return firebaseObj[name];
        }
    };
    this.getFirebaseRef = getFirebaseRef;

    /**
     * Create the firebase ref for a given top level object name
     * @param name
     * @returns firebaseRef
     */
    var createFirebaseRef = function (name) {
        var path = firebaseObj.roomname + "/" + name;
        var ref = firebase.database().ref(path);
        return ref;
    };

    var login = function() {
        setupFirebaseApp(roomnameModel);
        if (emulateFirebase) {
          $log.debug('[FirebaseModel] signing into emulated Firebase');
          firebase.auth().useEmulator('http://localhost:3015');
          firebase.database().useEmulator('localhost', 3020);
          return firebase.auth().signInAnonymously()
          .then((user) => {
              $log.info("fb emulator authentication success");
          })
          .catch((error) => {
              alert("Firebase Emulator Login Failed");
              $log.error('Firebase Emulator Login Failed');
              if (error)
                  $log.error(error);
          });
        }
        $log.debug('[FirebaseModel]  signing into non-emulated Firebase');
        return firebase.auth().signInWithCustomToken(roomnameModel.value.firebase_auth_token)
            .then((user) => {
                $log.info("fb authentication success");
            })
            .catch((error) => {
                alert("Sorry, that didn't work. Please try reloading the page. If you see this message again contact support at 844.415.4592");
                $log.error('Firebase Login Failed');
                if (error)
                    $log.error(error);
            });
    };
    this.login = login;

    var setupFirebaseApp = function(roomnameModel) {
        if (firebase.apps.length === 0) {
            $log.debug('[FirebaseModel] initializing Firebase');
            var config = {
                apiKey: roomnameModel.value.firebase_app_config.apiKey,
                authDomain: roomnameModel.value.firebase_app_config.authDomain,
                databaseURL: roomnameModel.value.firebase_baseurl,
                storageBucket: roomnameModel.value.firebase_app_config.storageBucket,
                messagingSenderId: roomnameModel.value.firebase_app_config.messageSenderId
            };
            firebase.initializeApp(config);
        } else {
            $log.debug('[FirebaseModel] firebase app already initialized');
        }
    };

    /**
     * Invoked on startup, like a constructor.
     */
    var init = function () {
        $log.debug("[FirebaseModel] creating firebase model");
    };
    init();

};

FirebaseModel.$inject = ['$log', 'roomnameModel', 'emulateFirebase'];
module.exports = FirebaseModel;
