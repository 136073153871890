// This leads to an error where moment.tz is undefined.
// import moment from 'moment';

function plTimezoneFactory() {
    const plTimezone = {};

    plTimezone.formatDateTime = 'YYYY-MM-DD HH:mm:ssZ';
    plTimezone.formatDateTimeNoTz = 'YYYY-MM-DD HH:mm:ss';
    plTimezone.formatDate = 'YYYY-MM-DD';

    plTimezone.curDateTime = (format1, nowTime1) => {
        const format = format1 || plTimezone.formatDateTime;
        const nowTime = nowTime1 || moment();
        const dtMoment = nowTime.utc();
        return (format === 'moment') ? dtMoment : dtMoment.format(format);
    };

    plTimezone.curDateTimeTz = (format1, nowTime1, tz) => {
        const format = format1 || plTimezone.formatDateTime;
        const nowTime = nowTime1 || moment();
        return moment.tz(nowTime.format(format), format, tz);
    };

    plTimezone.getTimezones = () => {
        // Get the 70ish most common timezones out of the 350+ total
        // https://github.com/thespacedojo/timezone-picker/blob/master/jstz.js
        return {
            'Pacific/Majuro': { name: 'Majuro', offset: '-12:00' },
            'Pacific/Pago_Pago': { name: 'Samoa', offset: '-11:00' },
            'America/Adak': { name: 'Adak', offset: '-10:00' },
            'Pacific/Honolulu': { name: 'Honolulu', offset: '-10:00' },
            'Pacific/Marquesas': { name: 'Marquesas', offset: '-09:30' },
            'Pacific/Gambier': { name: 'Gambier', offset: '-09:00' },
            'America/Anchorage': { name: 'Anchorage', offset: '-09:00' },
            'America/Los_Angeles': { name: 'Los Angeles', offset: '-08:00' },
            'Pacific/Pitcairn': { name: 'Pitcairn', offset: '-08:00' },
            'America/Phoenix': { name: 'Phoenix', offset: '-07:00' },
            'America/Denver': { name: 'Denver', offset: '-07:00' },
            'America/Guatemala': { name: 'Guatemala', offset: '-06:00' },
            'America/Chicago': { name: 'Chicago', offset: '-06:00' },
            'America/Bogota': { name: 'Bogota', offset: '-05:00' },
            'America/New_York': { name: 'New York', offset: '-05:00' },
            'America/Caracas': { name: 'Caracas', offset: '-04:30' },
            'America/Santiago': { name: 'Santiago', offset: '-04:00' },
            'America/Santo_Domingo': { name: 'Santo Domingo', offset: '-04:00' },
            'America/St_Johns': { name: 'St Johns', offset: '-03:30' },
            'America/Montevideo': { name: 'Montevideo', offset: '-03:00' },
            'America/Argentina/Buenos_Aires': { name: 'Buenos Aires', offset: '-03:00' },
            'America/Noronha': { name: 'Noronha', offset: '-02:00' },
            'Atlantic/Azores': { name: 'Azores', offset: '-01:00' },
            'Atlantic/Cape_Verde': { name: 'Cape Verde', offset: '-01:00' },
            'UTC': { name: 'UTC', offset: '+00:00' },
            'Europe/London': { name: 'London', offset: '+00:00' },
            'Europe/Berlin': { name: 'Berlin', offset: '+01:00' },
            'Africa/Lagos': { name: 'Lagos', offset: '+01:00' },
            'Asia/Beirut': { name: 'Beirut', offset: '+02:00' },
            'Africa/Johannesburg': { name: 'Johannesburg', offset: '+02:00' },
            'Asia/Baghdad': { name: 'Baghdad', offset: '+03:00' },
            'Europe/Moscow': { name: 'Moscow', offset: '+03:00' },
            'Asia/Tehran': { name: 'Tehran', offset: '+03:30' },
            'Asia/Dubai': { name: 'Dubai', offset: '+04:00' },
            'Asia/Baku': { name: 'Baku', offset: '+04:00' },
            'Asia/Kabul': { name: 'Kabul', offset: '+04:30' },
            'Asia/Yekaterinburg': { name: 'Yekaterinburg', offset: '+05:00' },
            'Asia/Karachi': { name: 'Karachi', offset: '+05:00' },
            'Asia/Kolkata': { name: 'Kolkata', offset: '+05:30' },
            'Asia/Kathmandu': { name: 'Kathmandu', offset: '+05:45' },
            'Asia/Dhaka': { name: 'Dhaka', offset: '+06:00' },
            'Asia/Omsk': { name: 'Omsk', offset: '+06:00' },
            'Asia/Rangoon': { name: 'Rangoon', offset: '+06:30' },
            'Asia/Krasnoyarsk': { name: 'Krasnoyarsk', offset: '+07:00' },
            'Asia/Jakarta': { name: 'Jakarta', offset: '+07:00' },
            'Asia/Shanghai': { name: 'Shanghai', offset: '+08:00' },
            'Asia/Irkutsk': { name: 'Irkutsk', offset: '+08:00' },
            'Australia/Eucla': { name: 'Eucla', offset: '+08:45' },
            'Asia/Yakutsk': { name: 'Yakutsk', offset: '+09:00' },
            'Asia/Tokyo': { name: 'Tokyo', offset: '+09:00' },
            'Australia/Darwin': { name: 'Darwin', offset: '+09:30' },
            'Australia/Adelaide': { name: 'Adelaide', offset: '+09:30' },
            'Australia/Brisbane': { name: 'Brisbane', offset: '+10:00' },
            'Australia/Sydney': { name: 'Sydney', offset: '+10:00' },
            'Australia/Lord_Howe': { name: 'Lord Howe', offset: '+10:30' },
            'Asia/Kamchatka': { name: 'Kamchatka', offset: '+11:00' },
            'Pacific/Noumea': { name: 'Noumea', offset: '+11:00' },
            'Pacific/Norfolk': { name: 'Norfolk', offset: '+11:30' },
            'Pacific/Auckland': { name: 'Auckland', offset: '+12:00' },
            'Pacific/Tarawa': { name: 'Tarawa', offset: '+12:00' },
            'Pacific/Chatham': { name: 'Chatham', offset: '+12:45' },
            'Pacific/Tongatapu': { name: 'Tongatapu', offset: '+13:00' },
            'Pacific/Apia': { name: 'Apia', offset: '+13:00' },
            'Pacific/Kiritimati': { name: 'Kiritimati', offset: '+14:00' },
        };
    };

    plTimezone.getTimezonesSelect = () => {
        const timezones = plTimezone.getTimezones();
        let options = [];
        for (let key in timezones) {
            options.push({
                value: key,
                label: `${timezones[key].offset} ${timezones[key].name}`,
            });
        }
        return options;
    };

    plTimezone.guessTimezone = (allZones = true) => {
        const guess = moment.tz.guess();
        if (!allZones) {
            const timezones = plTimezone.getTimezones();
            if (timezones[guess]) {
                return guess;
            }
            return '';
        }
        return guess;
    };

    plTimezone.getZone = (moment) => {
        const offset = moment.format('Z');
        const timezones = plTimezone.getTimezones();
        for (let key in timezones) {
            if (timezones[key].offset === offset) {
                return key;
            }
        }
        // Default to UTC if not found.
        return 'UTC';
    };

    plTimezone.fromTZFormat = (datetime) => {
        if (!datetime) {
            return datetime;
        }
        return datetime.replace('Z', '+00:00').replace('T', ' ');
    };

    plTimezone.toUserZone = (datetime, format, tz) => {
        const format1 = format || plTimezone.formatDateTime;
        // Convert the datetime to the user's timezone.
        const timezone1 = tz || plTimezone.guessTimezone() ||
         plTimezone.getZone(moment.tz(datetime, format1));
        return moment.tz(datetime, format1, timezone1);
    };

    plTimezone.getUserZone = (currentUser) => {
        let zone = false;
        if (currentUser) {
            if (currentUser.timezone) {
                zone = currentUser.timezone;
            } else if (currentUser.provider && currentUser.provider.timezone) {
                zone = currentUser.provider.timezone;
            }
        }
        return zone ? zone : ( plTimezone.guessTimezone() || 'UTC' );
    };

    plTimezone.getUserToday = (currentUser = null, format = 'YYYY-MM-DD') => {
        const tz = plTimezone.getUserZone(currentUser);
        const nowTime = plTimezone.toUserZone(moment().format(plTimezone.formatDateTime), plTimezone.formatDateTime, tz);
        return nowTime.format(format);
    };

    plTimezone.toUTCNoSeconds = (datetime, format1) => {
        const format = format1 || plTimezone.formatDateTime;
        const datetimeObj = moment(datetime, format).utc();
        // moment switches the `T` to an `A` so we manually form the string..
        return `${datetimeObj.format('YYYY-MM-DD')}T${datetimeObj.format('HH:mm')}`;
    };

    plTimezone.toUTCBackend = (datetime, format1) => {
        const format = format1 || plTimezone.formatDateTime;
        const datetimeObj = moment(datetime, format).utc();
        // moment switches the `T` to an `A` so we manually form the string..
        return `${datetimeObj.format('YYYY-MM-DD')}T${datetimeObj.format('HH:mm:ss')}`;
    };

    plTimezone.toUTC = (datetime, format1) => {
        const format = format1 || plTimezone.formatDateTime;
        return moment(datetime, format).utc().format(format);
    };

    plTimezone.convertToOffset = (datetime, format1, offset) => {
        const format = format1 || plTimezone.formatDateTime;
        if (offset < 0) {
            return moment(datetime, format).utc().subtract((offset * -1), 'minutes');
        }
        return moment(datetime, format).utc().add(offset, 'minutes');
    };

    /**
    For repeating events, we do NOT want to switch the time across daylight savings.
    For example, an event created at 9am should show up at 9am always, not 9am during half the year
     and 10am the other half of the year. So, we need to take the FIRST appointment's start
     date and use that (with the timezone) to calculate the offset. Then use that offset for
     the current appointment date to get the appropriate time.
    */
    plTimezone.convertAppointmentTime = (appointment, user, appointmentFields = ['start', 'end'], revert = false) => {
        const offsetDiff = plTimezone.getAppointmentOriginalOffset(appointment, user);
        const userTz = plTimezone.getUserZone(user);

        appointmentFields.forEach((field) => {
            if (appointment[field]) {
                appointment[field] = plTimezone.fromTZFormat(appointment[field]);
                if (offsetDiff > 0) {
                    if (revert) {
                        appointment[field] = moment(appointment[field], plTimezone.formatDateTime).subtract(offsetDiff, 'minutes');
                    } else {
                        appointment[field] = moment(appointment[field], plTimezone.formatDateTime).add(offsetDiff, 'minutes');
                    }
                } else if (offsetDiff < 0) {
                    if (revert) {
                        appointment[field] = moment(appointment[field], plTimezone.formatDateTime).add((offsetDiff * -1), 'minutes')
                         .format(plTimezone.formatDateTime);
                    } else {
                        appointment[field] = moment(appointment[field], plTimezone.formatDateTime).subtract((offsetDiff * -1), 'minutes')
                         .format(plTimezone.formatDateTime);
                    }
                }
                appointment[field] = moment.tz(appointment[field], plTimezone.formatDateTime, userTz)
                 .format(plTimezone.formatDateTime);
            }
        });
        return appointment;
    };

    /**
   * For appointments on a recurring event that are shifted after a DST crossover
   * adjust by the amount of the utcOffsetDiff to preserve the provider's appointment time.
   * @param forDisplay - controls the direction of the adjustment (for display or save)
   */
    plTimezone.computeAppointmentLocalDateTimes = (A, timezone, forDisplay = true) => {
        const OFFSET_DIFF = moment.tz(A.event.start, timezone).utcOffset() - moment.tz(A.start, timezone).utcOffset();
        const DIRECTION = forDisplay ? 'add' : 'subtract';
        const dates =  {
            start: moment.tz(A.start, timezone)[DIRECTION](OFFSET_DIFF, 'minutes'),
            end: moment.tz(A.end, timezone)[DIRECTION](OFFSET_DIFF, 'minutes'),
        }
        return dates;
    }

    plTimezone.getAppointmentOriginalOffset = (appointment, user) => {
        let start = plTimezone.fromTZFormat(appointment.start);
        // If appointment has been altered (original_start is different than start), just use time as is.
        // Otherwise can not edit the appointment time without it jumping if it WAS
        // part of a repeating event. Only alter times if an unaltered event.
        if (plTimezone.toUTC(plTimezone.fromTZFormat(appointment.original_start)) !== plTimezone.toUTC(start)) {
            return 0;
        }
        let originalStart = (appointment.event && appointment.event.start) ? appointment.event.start : appointment.start;
        // Get offset for the original start date.
        originalStart = plTimezone.fromTZFormat(originalStart);
        let end = plTimezone.fromTZFormat(appointment.end);
        const userTz = plTimezone.getUserZone(user);
        // We'll compare the original offset from the current offset and adjust the UTC time accordingly.
        const offsetOriginal = moment.tz(originalStart, plTimezone.formatDateTime, userTz).utcOffset();
        const offsetNow = moment.tz(start, plTimezone.formatDateTime, userTz).utcOffset();
        const offsetDiff = offsetOriginal - offsetNow;
        return offsetDiff;
    };

    return plTimezone;
  }
plTimezoneFactory.$inject = [];
module.exports = plTimezoneFactory;
