PreloadImageDirective.$inject = ['PreloadImageService', '$animate'];

function PreloadImageDirective(PreloadImageService, $animate) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: '/toychest/modules/store/assets/preloadImageDirective/preloadImageDirective.tpl.html',
        scope: {
            imageUrl: '='
        },
        link: function(scope, element) {
            let noPreviewImageUrl = '/toychest/assets/images/no_preview.png';
            var image = $('.image', element);
            var spinner = $('spinner', element);

            function hideImage() {
                spinner.css({
                    opacity: 1
                });
                image.css({
                    opacity: 0
                });
                scope.spinnerActive = true;
                scope.src = false;
            }

            function showImage() {
                scope.spinnerActive = false;
                spinner.css({
                    opacity: 0
                });
                image.css({
                    opacity: 1
                });
            }

            function setValueAndShowImage(value) {
                scope.src = value;
                showImage();
            }

            scope.$watch('imageUrl', function(value) {
                if (!$animate.enabled()) {
                    setValueAndShowImage(value);
                    return;
                }
                hideImage();
                PreloadImageService
                    .preloadImage(value)
                    .then(setValueAndShowImage, setValueAndShowImage.bind(null, noPreviewImageUrl));
            });
        }
    };
}
module.exports = PreloadImageDirective;