var LessonDetailController = function($scope, $injector, Lesson, Room, PreviousState) {
    // Activities queue is only needed if there's a room
    var activitiesQueue = null;
    if(Room) {
        activitiesQueue = $injector.get('activitiesQueue');
    }
    $scope.queue = activitiesQueue;
    $scope.editTitle = false;
    $scope.editDescription = false;

    $scope.lesson = Lesson;
    $scope.prevState = PreviousState;

};

LessonDetailController.$inject = ['$scope', '$injector', 'Lesson', 'Room', 'PreviousState'];
module.exports = LessonDetailController;
