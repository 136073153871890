function plFormatterFactory() {
    const plFormatter = {};

    plFormatter.phone = (number) => {
        if (!number) {
            return number;
        }
        let value = typeof(number) !== 'string' ? number.toString() : number;
        // Remove any non digit characters.
        value = value.replace(/[^\d]/g, '');
        // If 11 digits with first digit of 1, remove (US) country code.
        if (value.length === 11 && value[0] === '1') {
            value = value.slice(1, value.length);
        }
        if (value.length === 7) {
            value = value.replace(/(\d{3})(\d{4})/, "$1-$2");
        }
        if (value.length === 10) {
            value = value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        }
        return value;
    };

    // plFormatter.twoDigits = (number1) => {
    //     let number = typeof(number1) !== 'string' ? number1.toString() : number1;
    //     if (number.length === 1) {
    //         number = `0${number}`;
    //     }
    //     return number;
    // };

    plFormatter.numberToString = (number1, decimals = 0) => {
        let number = typeof(number1) === 'string' ? parseFloat(number1) : number1;
        return number.toFixed(decimals);
    };

    plFormatter.currency = (number) => {
        if (!number) {
            return number;
        }
        let value = plFormatter.numberToString(number, 2);
        // Remove any non digit characters EXCEPT a dot.
        value = value.replace(/[^\d\.]/g, '');
        let { base: dollars, decimal: cents } = plFormatter.splitPeriod(value);
        dollars = plFormatter.commify(dollars);
        return `$${dollars}.${cents}`;
    };

    plFormatter.commify = (number) => {
        let value = typeof(number) !== 'string' ? number.toString() : number;
        let pieces = [];
        let piece;
        for( let ii = value.length; ii > 0; ii = ii-3) {
            piece = (ii >= 3) ? value.slice((ii-3), ii) : value.slice(0, ii);
            pieces.unshift(piece);
        }
        return pieces.join(',');
    };

    plFormatter.splitPeriod = (number) => {
        let base = typeof(number) !== 'string' ? number.toString() : number;
        let decimal = '00';
        let posPeriod = base.indexOf('.');
        if (posPeriod > -1) {
            decimal = base.slice((posPeriod + 1), base.length);
            base = base.slice(0, posPeriod);
        }
        return {
            base,
            decimal
        };
    };

    return plFormatter;
}
plFormatterFactory.$inject = [];
module.exports = plFormatterFactory;
