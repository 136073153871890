module.exports = angular.module('toys.assessment', [
        require('./doubleassessment').name,
        require('./wjaudioassessment').name,
        require('./assessmentpdfcontent').name,
        require('./youtube').name

    ])
    .service('assessmentService', require('./AssessmentService'))
    .service('AssessmentModel', require('./AssessmentModel'))
;
