var DotLoaderDirective = function() {
    return {
        replace: true,
        restrict: 'AE',
        scope: {
            align: '@?'
        },
        templateUrl : '/core/pl-core/src/directives/dot-loader/dot-loader.tpl.html',
        link: ($scope) => {
            $scope.align = $scope.align ? $scope.align : 'center';
        },
    };
};

DotLoaderDirective.$inject = [];
module.exports = DotLoaderDirective;
