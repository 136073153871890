function plApiInvoiceFactory(plHttp, $q) {
    const plApiInvoice = {};
    let _preview = null;
    let _invoices = null;
    
    plApiInvoice.getPreview = (useCache = false) => {
        const deferred = $q.defer();

        if (useCache && _preview) {
            deferred.resolve(_preview);
        } else {
            plHttp.get('invoicesPreview')
                .then((res) => {
                    _preview = res.data;
                    deferred.resolve(res.data);
                }, (err) => {
                    deferred.reject(err);
                });
        }
        return deferred.promise;
    };

    plApiInvoice.get = (params = {}, useCache = false) => {
        const deferred = $q.defer();
        const noParams = (Object.keys(params).length === 0) ? true : false;
        if (useCache && _invoices && _invoices.length && noParams) {
            deferred.resolve(_invoices);
        } else {
            plHttp.get('invoices', params)
                .then((res) => {
                    if (noParams) {
                        _invoices = res.data.results;
                    }
                    let ret = res.data.results ? res.data.results : res.data;
                    deferred.resolve(ret);
                }, (err) => {
                    deferred.reject(err);
                });
        }
        return deferred.promise;
    };

    plApiInvoice.save = (invoice) => {
        const deferred = $q.defer();

        plHttp.save('invoices', invoice)
            .then((res) => {
                deferred.resolve(res.data);
            }, (err) => {
                deferred.reject(err);
            });

        return deferred.promise;
    };

    plApiInvoice.retract = (invoiceUuid) => {
        const deferred = $q.defer();

        const httpOpts = {
            url: `${plHttp.formUrl('invoices')}${invoiceUuid}/retract/`,
            data: {},
            method: 'PUT',
        };
        plHttp.go(httpOpts)
            .then((res) => {
                deferred.resolve(res.data);
            }, (err) => {
                deferred.reject(err);
            });

        return deferred.promise;
    };

    return plApiInvoice;
}
plApiInvoiceFactory.$inject = ['plHttp', '$q'];
module.exports = plApiInvoiceFactory;
