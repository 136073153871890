var SelectPDFController = function($scope, assetModel, drfActivityModel) {

    $scope.fileTooLarge = false;

    $scope.$watch('fileInputItems', function(newVal) {
        if (newVal) {
            $scope.fileTooLarge = false;
            $scope.uploadPDF(newVal);
        }
    });

    $scope.mainHeaderText = "Drag & Drop";

    $scope.uploadPDF = function uploadPDF(files) {
        $scope.fileSizeMb = parseFloat(parseInt(files[0].size) / 1000000).toFixed(2);
        if ($scope.fileSizeMb > 10) {
            $scope.fileTooLarge = true;
            return;
        }
        $scope.fileInProgress = new assetModel.Model();
        $scope.fileName = files[0].name;
        $scope.fileInProgress
            .$upload(files[0], true)
            .then(function(uploadedFile) {
                $scope.setActivity(_.extend(new drfActivityModel.Model(), {
                    'activity_type': 'pdfviewer',
                    'name': uploadedFile.title,
                    'tags': uploadedFile.tags,
                    'descriptor': JSON.stringify({
                        'url': uploadedFile.download_url
                    }),
                    'description': uploadedFile.description,
                    'section': 'ugc'
                }));
                $scope.panelNavigation.gotoNextPanel();
            }, function(e) {
                console.log('upload error: ', e);
                $scope.uploadError = e;
                $scope.fileInProgress = null;
                $scope.mainHeaderText = "Upload Error";
            });
    };
    $scope.panelNavigation.current = 'upload-document';
};

SelectPDFController.$inject = ['$scope', 'assetModel', 'drfActivityModel'];
module.exports = SelectPDFController;
