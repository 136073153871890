/**
 * plUser gets an augmented form of the Current (logged-in) User object.
 * It is adorned with Authorization helpers and specialized information.
 * It includes:
 *   - isAuthorized(context, params) - rules-based permissions control
 *   - isProvider()
 *   - isSuperuser()
 *   - isDemo()
 *   - isPseudoProvider() - one without a provider profile (e.g. sales user with Provider + Demo group)
 *   - getProvider()
 *   - isUserInGroup(groupName)
 *   - hasProviderType(typeCode) // only applicable if isProvider() === true
 *   - provider
 *   - providerTypes
 */
function plUser(UserPreferences, AppRecorder, AuthContext, plRemoteLogger) {

  // we need to be able to supply a callback to act on the resolve result
  const addUserAuthorizationRules = ($q, currentUserModel, API, $location, callback, useProfile=false) => {
    const deferred = $q.defer();
    const cypressUser = window._cypress?window._cypress.userObject:null;
    if (cypressUser) {
      provisionUser(cypressUser, UserPreferences, AppRecorder, deferred, $q, API, $location, callback);
    } else {
      currentUserModel
        .getAuthenticatedUser(useProfile)
        .then(user => {
          currentUserModel.startInactivityLogoutService();
          const perms = API.permissions.query().$promise;
          perms.then(
            result => {
              const permsMap = result.reduce((output, item) => {
                output[item] = item;
                return output;
              },{});
              user.hasPermission = perm => {
                return !!permsMap[perm];
              };
              user.hasPermissions = permsArray => {
                return permsArray.reduce((result, item) => {
                  const hasPerm = !!permsMap[item];
                  return result && hasPerm;
                }, true);
              };
              user.getPermissions = () => {
                return permsMap;
              };
              user.permissions = result;
              provisionUser(user, UserPreferences, AppRecorder, deferred, $q, API, $location, callback, AuthContext);
            },
            error => {
              if (console) {console.error('could not get API permissions data', error)}
            }
          );
        }, (error) => {
          if (console) console.log("Unauthenticated", error);
          deferred.reject('unauthenticated');
        });
    }
    return deferred.promise;
  };
  return addUserAuthorizationRules;
}

function provisionUser(user, UserPreferences, AppRecorder, deferred, $q, API, $location, callback, AuthContext) {
  if(window) {
    window.plUser = user;
  }
  user.preferences = UserPreferences;
  user.recorder = AppRecorder;
  user.isInGroup = group => user.groups.indexOf(group) > -1;
  const authContext = AuthContext(user);
  user.isSuperuser = () => user.is_superuser;
  user.isProvider = () => user.isInGroup('Provider');
  user.isLead = () => user.isInGroup('LeadClinician');
  user.isDemo = () => user.isInGroup('Demo');
  user.isPseudoProvider = () => user.isDemo();
  user.isServiceAndSupport = () => user.isInGroup('Service & Support');
  user.isAccountManager = () => user.isInGroup('Account Managers'); // TODO: this will be renamed SCC, per francois
  user.isClinicalAccountManager = () => user.isInGroup('Clinical Account Manager');
  user.isAdmin = () => user.isSuperuser() || user.isServiceAndSupport() || user.isAccountManager();

  let _provider, _providerTypes, _providerTypesMap;
  let _promises = [];

  if (user.isProvider() && !user.isPseudoProvider()) {
    _provider = API.providers.get({user__uuid: user.uuid});
    _providerTypes = API.providerTypes.get();
    _providerTypesMap = {};
    _promises = [_provider.$promise, _providerTypes.$promise];
  }
  $q.all(_promises)
    .then(() => {
      if (user.isProvider() && !user.isPseudoProvider()) {
        user.provider = _provider;
        user.getProvider = () => _provider;
        _providerTypes.results.map(item => {
          _providerTypesMap[item.uuid] = item;
        });

        user.providerTypes = _provider.provider_types.reduce((result, item) => {
          return result.concat(_providerTypesMap[item]);
        }, []);
        user.hasProviderType = providerTypeCode => !!user.providerTypes.find(item => item.code === providerTypeCode)
        user.serviceTypes = user.providerTypes.reduce((result, item) => {
          if (item.code === 'slp') {
            result.push({code: 'slt', name: 'SLT'});
          }
          if (item.code === 'ot') {
            result.push ({code: 'ot', name: 'OT'});
          }
          if (item.code === 'mhp') {
            result.push ({code: 'bmh', name: 'BMH'});
          }
          if (item.code === 'sped') {
            result.push ({code: 'sped', name: 'SPED Instruction'});
          }
          return result;
        }, []);
      }

      // Usages:
      //   - user.isAuthorized('allow-admin-routes')
      //   - user.isAuthorized('edit-service-plan', {serviceType: 'slt'})
      user.isAuthorized = (contextKey, params) => {
        return authContext[contextKey](params);
      };

      if (callback) {
        const callbackResult = callback(user, $location);

        if (callbackResult.isRouteAuthorized) {
          deferred.resolve(user);
        } else {
          $location.path('/nowhere/dev/null')
        };
      } else {
        deferred.resolve(user);
      }
    });

}

plUser.$inject = ['plUserPreferences', 'plAppRecorder', 'plAuthContext', 'plRemoteLogger'];
module.exports = plUser;
