var SidenavDirective = function(appModel, currentUserModel, applications, permissionsHelper) {
    return {
        restrict: 'E',
        transclude: true,
        templateUrl: '/toychest/common/directives/sidenav/sidenav.tpl.html',
        link: function($scope, $element, $attr) {
            $scope.currentUser = currentUserModel;

            $scope.roomUrl = applications.lightyear.url;
            $scope.storeUrl = applications.toychest.url;

            $scope.showAssessments = currentUserModel.user.isClinician() || currentUserModel.user.isInGroup('Assessment Users');

            $scope.showLessons = currentUserModel.user.isInGroup('Lesson Users');

            $scope.showGames = currentUserModel.user.isClinician() || currentUserModel.user.isInGroup('Activity Users');

            $scope.showActivities = currentUserModel.user.isClinician() || currentUserModel.user.isInGroup('Activity Users');
            
        }
    };
};

SidenavDirective.$inject = ['appModel', 'currentUserModel', 'applications', 'permissionsHelper'];
module.exports = SidenavDirective;