export default angular.module('pl-modules', [])
    .factory('plBrowser', require('./src/plBrowser/plBrowserFactory'))
    .factory('plAvailability', require('./src/plAvailability/plAvailabilityFactory'))
    .factory('plErrorMessage', require('./src/plErrorMessage/plErrorMessageFactory'))
    .factory('plFormatter', require('./src/plFormatter/plFormatterFactory'))
    .factory('plHttp', require('./src/plHttp/plHttpFactory'))
    .factory('plHistory', require('./src/plHistory/plHistoryFactory'))
    .factory('plLodash', require('./src/plLodash/plLodashFactory'))
    .factory('plMay', require('./src/plMay/plMayFactory'))
    .factory('plSaveEditModalConfig', require('./src/plConfig/plSaveEditModalConfig'))
    .factory('plTimezone', require('./src/plTimezone/plTimezoneFactory'))

    .service('plUserPreferences', require('./src/plUserPreferences/plUserPreferences'))
    .service('plAppRecorder', require('./src/plAppRecorder/plAppRecorder'))
    .service('plRemoteLogger', require('./src/plRemoteLogger/plRemoteLogger'))
    .factory('plToast', require('./src/plToast/plToastFactory'))
    .service('plAppContext', require('./src/shared/global/plAppContext'))
    .service('plAuthContext', require('./src/shared/global/plAuthContext'))
    .service('plPermissions', require('./src/shared/global/plPermissions'))
    .service('plUser', require('./src/shared/global/plUser'))
    .service('plAppGlobal', require('./src/shared/global/plAppGlobal'))
    .service('plGlobalUtil', require('./src/shared/global/plGlobalUtil'))
    .service('plGraphQL', require('./src/plGraphQL/plGraphQLFactory'))
    .service('plHijackHelper', require('./src/plHijack/plHijackHelper'))
    .service('plFeatureSwitches', require('./src/plFeatureSwitches/plFeatureSwitches'))

    .directive('plBrowserCheckDirective', require('./src/plBrowser/plBrowserCheck/plBrowserCheck'))
    .directive('plBrowserCheckOverlayDirective', require('./src/plBrowser/plBrowserCheckOverlay/plBrowserCheckOverlay'))
    .directive('plHijackDrawerDirective', require('./src/plHijack/plHijackDrawer/plHijackDrawer'))
    .directive('plInfoHint', require('./src/plInfoHint/plInfoHint'))
    ;
