function MemoryActivityDirective() {
    return {
        restrict: 'E',
        controller:'MemoryController',
        scope: {},
        templateUrl:'/core/toys/app/src/toys/modules/activity/memory/memory.tpl.html'
    };
}

module.exports = MemoryActivityDirective;
