var FirebaseMockFactory = function ($log, Assert) {

    //default config
    this.config = {
        connectionDelay: 500,
        methodLatency: 500,
        domain: "firebasemock"
    };

    //default data
    this.data = {
    };

    var FirebaseMock = function (config, data) {

        this.mockConfig = config;
        this.mockData = data;

        /**************************************
         * EventQueue
         * @param repo
         * @param methodLatency
         * @constructor
         ***************************************/
        var EventQueue = function (repo, methodLatency) {
            $log.debug("[Mock] new EventQueue");
            this.repo = repo;
            this._methodLatency = methodLatency;
            this._queue = [];
            this._listeners = {};
            this.flushPending = false;
        };

        EventQueue.prototype.triggerFlush = function () {
            //start the flush counter
            if (!this.flushPending) {
                this.flushPending = true;
                _.delay(_.bind(this.flush, this), this._methodLatency);
            }
        };

        EventQueue.prototype.flush = function () {
            //do the event flush
            //loop through each ref queue in the queue
            //dispatch the deepest refs first
            var onComplete;
            var sortedQueue = _.sortBy(this._queue, function (ref) {
                return ref.toString();
            });
            for (var i = sortedQueue.length - 1; i >= 0; i--) {
                var ref = sortedQueue[i];
                var events, j;
                //order of events (added/moved/changed, removed, value)
                //child added
                events = ref._events['child_added'];
                for (j = 0; j < events.length; j++) {
                    this.dispatchEvent(ref, events[j]);
                }
                ref._events['child_added'] = [];

                //child moved
                events = ref._events['child_moved'];
                for (j = 0; j < events.length; j++) {
                    this.dispatchEvent(ref, events[j]);
                }
                ref._events['child_moved'] = [];

                //child changed
                events = ref._events['child_changed'];
                for (j = 0; j < events.length; j++) {
                    this.dispatchEvent(ref, events[j]);
                }
                ref._events['child_changed'] = [];

                //child removed
                events = ref._events['child_removed'];
                for (j = 0; j < events.length; j++) {
                    this.dispatchEvent(ref, events[j]);
                }
                ref._events['child_removed'] = [];

                //refs value
                events = ref._events['value'];
                for (j = 0; j < events.length; j++) {
                    this.dispatchEvent(ref, events[j]);
                }
                ref._events['value'] = [];

                var callback = ref._events['onComplete'];
                if (callback && callback[0]) { //we only take one callback
                    onComplete = callback[0];
                }
                ref._events['onComplete'] = [];

            }
            if (onComplete) {
                this.dispatchOnComplete(onComplete);
            }
            this.cleanQueue();
            this.flushPending = false;
        };

        EventQueue.prototype.cleanQueue = function() {
            $log.debug("[Mock] EventQueue cleaning queue.");
            var queuelen = this._queue.length - 1;
            for (var i = queuelen; i > -1; i--) {
                var ref = this._queue[i];
                if (!this._listeners[ref.toString()]) {
                    $log.debug("[EventQueue] pruning ref: " + ref.toString());
                    this._queue.splice(i, 1);
                }
            }
        };

        EventQueue.prototype.addOnComplete = function (ref, callback) {
            ref._events['onComplete'].push(callback);
        };

        EventQueue.prototype.addEvent = function (ref, event) {
            $log.debug("[Mock] EventQueue addEvent: " + event.type);
            //var ref = event.ref;
            ref._events[event.type].push(event);
            if (!_.includes(this._queue, ref)) {
                this._queue.push(ref)
            }
            this.triggerFlush();
        };


        EventQueue.prototype.removeEvent = function () {
            $log.debug("[Mock] EventQueue removeEvent: " + event);
        };

        EventQueue.prototype.setListener = function (key, callback) {
            this._listeners[key] = callback;
        };

        EventQueue.prototype.getListener = function (key) {
            return this._listeners[key];
        };

        EventQueue.prototype.dispatchEvent = function (ref, event) {
            $log.debug("[Mock] EventQueue dispatchEvent: " + event);
            if (event == null)
                return;

            $log.debug("[Mock] dispatching event: " + event.type);
            var listeners = this._listeners[ref.toString()];
            if (listeners) {
                var callbacks = listeners[event.type];
                var expiredCallbacks = [];
                if (callbacks) {
                    var i;
                    for (i = 0; i < callbacks.length; i++) {
                        var callback = callbacks[i];
                        callback.callback.apply(callback.context, [event.snapshot, event.previousChildId]);
                        if (!callback.persistent) {
                            $log.debug("removing expired callback");
                            expiredCallbacks.push(callback);
                        }
                    }
                    //remove any one time callbacks
                    for (i = 0; i < expiredCallbacks.length; i++) {
                        var index = _.indexOf(callbacks, expiredCallbacks[i]);
                        callbacks.splice(index, 1);
                    }
                }
            }
        };

        EventQueue.prototype.dispatchOnComplete = function (callback) {
            _.defer(_.bind(callback.callback, callback.ref, _), callback.error);
        };

        EventQueue.prototype.generateAddEvent = function (ref, data) {
            $log.debug("[Mock] generating add event");
            var event = {};
            event.ref = ref;
            event.type = "child_added";
            event.previousChildId = this.repo.findPreviousChildId(ref.toString(), data);
            var priority = data ? data.$priority : null;
            event.snapshot = new Snapshot(ref, data, priority);
            //TODO find previousChildId
            return event;
        };

        EventQueue.prototype.generateChangeEvent = function (ref, data) {
            $log.debug("[Mock] generating change event");
            var event = {};
            event.ref = ref;
            event.type = "child_changed";
            var priority = data ? data.$priority : null;
            event.snapshot = new Snapshot(ref, data, priority);
            return event;
        };

        EventQueue.prototype.generateRemoveEvent = function (ref, data) {
            $log.debug("[Mock] generating remove event");
            var event = {};
            event.ref = ref;
            event.type = "child_removed";
            var priority = data ? data.$priority : null;
            //clone so the event data remains past the node deletion
            event.snapshot = new Snapshot(ref, _.cloneDeep(data), priority);
            return event;
        };

        EventQueue.prototype.generateMoveEvent = function (ref, data) {
            $log.debug("[Mock] generating move event");
            var event = {};
            event.ref = ref;
            event.type = "child_moved";
            event.previousChildId = this.repo.findPreviousChildId(ref.toString(), data);
            var priority = data ? data.$priority : null;
            event.snapshot = new Snapshot(ref, data, priority);
            return event;
        };

        EventQueue.prototype.generateValueEvent = function (ref, data) {
            $log.debug("[Mock] generating value event");
            var event = {};
            event.ref = ref;
            event.type = "value";
            var priority = data ? data.$priority : null;
            event.snapshot = new Snapshot(ref, data, priority);
            return event;
        };

        EventQueue.prototype.generateOnCompleteCallback = function (ref, callback, error) {
            var event = {};
            event.ref = ref;
            event.callback = callback;
            event.error = error;
            return event;
        };

        /******************************
         * Repo
         * @constructor
         ******************************/
        var Repo = function () {
            //some data holder
            this._graphView = {};
        };

        Repo.prototype.getParentPath = function (path) {
            return path.substring(0, path.lastIndexOf('/'));
        };

        //return an array of all ancestor's up to root
        Repo.prototype.getAncestry = function (path) {
            var ancestors = [];
            var parentPath;
            while (parentPath = this.getParentPath(path)) {
                ancestors.push(parentPath);
                path = parentPath;
            }
            return ancestors;
        };

        //return an array of all ancestor's up to root
        Repo.prototype.getDescendents = function (path) {
            var descendents = [];
            var data = this.getNode(path);
            if (data) {
                if (_.isObject(data)) { //use util
                    descendents.push({path: path, data: data});
                    _.forEach(data, function (value, key) {
                        descendents = _.union(descendents, _flattenGraph(path + '/' + key, value));
                        $log.debug("[Descendents] value/key/path: " + value + " : " + key + " : " + path);
                    });
                } else {
                    descendents.push({path: path, data: data});
                }
            }
            return descendents;
        };

        var _flattenGraph = function (path, data) {
            var affectedPaths = [];
            if (data) {
                if (_.isObject(data)) { //use util
                    affectedPaths.push({path: path, data: data});
                    _.forEach(data, function (value, key) {
                        affectedPaths = _.union(affectedPaths, _flattenGraph(path + '/' + key, value));
                        $log.debug("[PATHS] value/key/path: " + value + " : " + key + " : " + path);
                    });
                } else {
                    affectedPaths.push({path: path, data: data});
                }
            }
            return affectedPaths;
        };

        Repo.prototype.getNode = function (path) {
            path = _.trimRight(path, "/");
            var pattern = new RegExp('\/', 'g');
            var objectPath = path.replace(pattern, '.');
            var graph = this._graphView;
            var node = _.get(graph, objectPath);
            return node;
        };

        Repo.prototype.addNode = function (path, value) {
            $log.debug("[Repo] addNode: " + path);
            //remove any trailing path separators
            path = _.trimRight(path, "/");
            var pattern = new RegExp('\/', 'g');
            var objectPath = path.replace(pattern, '.');
            var node = this._graphView;
            value = _.isObject(value) ? value : value.toString();
            _.set(node, objectPath, value);
        };

        Repo.prototype.mergeNode = function (path, value) {
            $log.debug("[Repo] mergeNode: " + path);
            path = _.trimRight(path, "/");
            var pathData = this.getNode(path);
            if (pathData == null) {
                //this is an inferred add not an update but we have to check for null child values
                _.forIn(value, function (val, key) {
                    if (val == null) {
                        delete value[key];
                    }
                });
                this.addNode(path, value);
            } else {
                if (pathData && value) {
                    _.forIn(value, function (val, key) {
                        if (val == null) {
                            //remove item
                            delete pathData[key];
                        } else {
                            pathData[key] = val;
                        }
                    });
                }
            }
        };

        Repo.prototype.removeNode = function (path) {
            path = _.trimRight(path, "/");
            var parentData = this.getParentData(path);
            var keys = _.keys(parentData);
            var key = this.getPathKey(path);
            if (_.includes(keys, key)) {
                delete parentData[key];
            }
        };

        Repo.prototype.getPathKey = function (path) {
            path = _.trimRight(path, "/");
            $log.debug('[Mock] getPathKey()');
            var key;
            var parts = path.split('/');
            if (parts.length == 1) {
                key = null;
            } else {
                key = parts.pop();
            }
            return key;
        };

        Repo.prototype.findPreviousChildId = function (path) {
            $log.debug("[Mock] findPreviousChildId:" + path);
            var prevChildId = null;
            //get the parent path and data..
            var parentData = this.getParentData(path);
            var keys = this.sortKeys(parentData);
            var index = keys.indexOf(this.getPathKey(path));
            if (index > 0) {
                prevChildId = keys[index - 1];
            }
            return prevChildId;
        };

        Repo.prototype.getParentData = function (path) {
            var parentPath = this.getParentPath(path);
            return this.getNode(parentPath);
        };

        Repo.prototype.sortKeys = function (parentData) {
            var children = [];
            _.forEach(parentData, function (value, key) {
                children.push({key: key, priority: value.$priority});
            });

            children.sort(_.bind(this.compareNodes, this));

            var sortedKeys = [];
            for (var i = 0; i < children.length; i++) {
                sortedKeys.push(children[i].key);
            }
            return sortedKeys;
        };

        Repo.prototype.compareNodes = function (a, b) {
            var x = this.comparePriority(a.priority, b.priority);
            if (x === 0) {
                if (a.key !== b.key) {
                    x = a.key < b.key ? -1 : 1;
                }
            }
            return x;
        };

        Repo.prototype.comparePriority = function (a, b) {
            if (a !== b) {
                if (this.isNull(a) || this.isNull(b)) {
                    return this.isNull(a) ? -1 : 1;
                }
                if (typeof a !== typeof b) {
                    return typeof a === 'number' ? -1 : 1;
                } else {
                    return a > b ? 1 : -1;
                }
            }
            return 0;
        };

        Repo.prototype.isNull = function (value) {
            return !!(value == null || value == undefined);
        };


        Repo.prototype.getNodePriority = function (path) {

        };


        var datastore = null;

        /**************************************
         * DataStore
         * @constructor
         *************************************/
        var DataStore = function (mockConfig, mockData) {
            this.config = mockConfig;
            this.initialData = mockData;
            this.repo = new Repo();
            this.eventQueue = new EventQueue(this.repo, mockConfig.methodLatency);
            this._references = {};

            this.lastPushTime = 0;
            this.lastRandChars = [];
        };

        DataStore.prototype.registerCallback = function (callback) {
            $log.debug("[Mock] registering callback for: " + callback.key + " : " + callback.eventType);
            var eventType = callback.eventType;
            var currentListeners = this.eventQueue.getListener(callback.key);
            if (currentListeners == null) {
                //first listener
                var callbackHolder = {};
                callbackHolder.ref = callback.ref;
                callbackHolder[eventType] = [callback];
                this.eventQueue.setListener(callback.key, callbackHolder);
            } else {
                var typearray = currentListeners[callback.eventType];
                if (typearray) {
                    //dont add the same listener for the same type
                    if (_.includes(typearray, callback)) {
                        $log.debug("[Mock] callback already registered for this event.");
                    } else {
                        typearray.push(callback);
                    }
                } else {
                    currentListeners[callback.eventType] = [callback];
                }

            }
            this.queueInitialEvents(callback);
        };

        DataStore.prototype.removeCallback = function (callback) {
            var currentListeners = this.eventQueue.getListener(callback.key);
            if (currentListeners) {
                var typearray = currentListeners[callback.eventType];
                for (var i = 0; i < typearray.length; i++) {
                    var existingCallback = typearray[i];
                    if (existingCallback.callback === callback.callback) {
                        typearray.splice(index, 1);
                        break;
                    }
                }
            }
        };

        DataStore.prototype.queueInitialEvents = function (callbackDef) {
            $log.debug('[Mock] DataStore.dispatchInitialEvents()');
            var ref = callbackDef.ref;
            var data = _.cloneDeep(this.repo.getNode(ref.toString()));
            if (data) {
                if (callbackDef.eventType == "child_added") {
                    //handle add
                    var isObject = _.isObject(data);
                    if (isObject) {
                        var keys = _.keys(data);
                        //TODO sort keys
                        if (keys && keys.length) {
                            for (var i = 0; i < keys.length; i++) {
                                var childRef = new Firebase(callbackDef.ref.toString() + "/" + keys[i]);
                                var addEvent = this.eventQueue.generateAddEvent(childRef, data[keys[i]]);
                                this.eventQueue.addEvent(ref, addEvent);
                            }
                        }
                    }
                } else if (callbackDef.eventType == "value") {
                    //handle value
                    var valueEvent = this.eventQueue.generateValueEvent(ref, data);
                    this.eventQueue.addEvent(ref, valueEvent);
                }
            }
        };

        // this overrides everything
        DataStore.prototype.set = function (ref, data, callback, priority, merge) {
            $log.debug('[Mock] DataStore.set()');
            var opEvent = null;
            var valueEvent;
            var path = ref.toString();

            var oldData = this.repo.getNode(path);
            //priority
            if (oldData || priority) {
                var priorityChanged = true;
                if (oldData && oldData["$priority"] == priority) {
                    priorityChanged = false;
                }
            }
            //don't dispatch any events if the data is already there
            if (_.isEqual(data, oldData) && !priorityChanged) {
                return;
            }

            //check before we add
            if (oldData && data == null) {
                //this is a remove!
                opEvent = this.eventQueue.generateRemoveEvent(ref, oldData);
                //remove from the datastore
                this.repo.removeNode(path);
            } else {
                //update the node's priority
                if (priorityChanged) {
                    if (priority) {
                        data["$priority"] = priority;
                    } else {
                        $log.debug("[Mock] deleting priority");
                        delete data["$priority"];
                    }

                }
                //update datastore
                if (merge) {
                    this.repo.mergeNode(path, data);
                } else {
                    //a normal set overwrites the whole object
                    this.repo.addNode(path, data);
                }

                if (oldData && priorityChanged) {
                    var preOpEvent = this.eventQueue.generateMoveEvent(ref, data);
                }

                if (oldData == null || oldData == undefined) {
                    opEvent = this.eventQueue.generateAddEvent(ref, data);
                } else {
                    opEvent = this.eventQueue.generateChangeEvent(ref, data);
                }
            }

            //create the root value event
            valueEvent = this.eventQueue.generateValueEvent(ref, this.repo.getNode(path));
            //add events to this ref's queue
            if (preOpEvent) {
                this.eventQueue.addEvent(ref, preOpEvent);
            }
            this.eventQueue.addEvent(ref, opEvent);
            this.eventQueue.addEvent(ref, valueEvent);

            //generate events up the chain of ancestors
            var childref = ref;
            var childdata = data;
            var ancestors = this.repo.getAncestry(path);
            for (var i = 0; i < ancestors.length; i++) {
                var tempRef = new Firebase(ancestors[i]);

                if (ancestors[i] == this.repo.getParentPath(path)) {
                    var ancestorData = this.repo.getNode(ancestors[i]);
                    if (oldData != null) {
                        var changeEvent = this.eventQueue.generateChangeEvent(childref, childdata);
                        tempRef.dataStore.eventQueue.addEvent(tempRef, changeEvent);
                    } else {
                        var addEvent = this.eventQueue.generateAddEvent(childref, childdata);
                        tempRef.dataStore.eventQueue.addEvent(tempRef, addEvent);
                    }
                    childref = tempRef;
                    childdata = ancestorData;
                } else {
                    //ancestors further up get change events
                    var changeEvent = this.eventQueue.generateChangeEvent(childref, childdata);
                    tempRef.dataStore.eventQueue.addEvent(tempRef, changeEvent);
                }
                //every ancestor gets a value event.
                valueEvent = this.eventQueue.generateValueEvent(tempRef, ancestorData);
                tempRef.dataStore.eventQueue.addEvent(tempRef, valueEvent);
            }

            if (callback) {
                //todo add error conditions
                var onCompleteCallback = this.eventQueue.generateOnCompleteCallback(ref, callback, null);
                this.eventQueue.addOnComplete(ref, onCompleteCallback)
            }
        };

        DataStore.prototype.getServerTime = function () {
            return new Date().getTime();
        };

        DataStore.prototype.generatePushKey = function (now) {
            //modified directly from firebase-debug.js
            var PUSH_CHARS = "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz";
            var duplicateTime = now === this.lastPushTime;
            this.lastPushTime = now;
            var timeStampChars = new Array(8);
            for (var i = 7; i >= 0; i--) {
                timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
                now = Math.floor(now / 64);
            }
            Assert(now === 0, "Cannot push at time == 0");
            var id = timeStampChars.join("");
            if (!duplicateTime) {
                for (i = 0; i < 12; i++) {
                    this.lastRandChars[i] = Math.floor(Math.random() * 64);
                }
            } else {
                for (i = 11; i >= 0 && this.lastRandChars[i] === 63; i--) {
                    this.lastRandChars[i] = 0;
                }
                this.lastRandChars[i]++;
            }
            for (i = 0; i < 12; i++) {
                id += PUSH_CHARS.charAt(this.lastRandChars[i]);
            }
            Assert(id.length === 20, "nextPushId: Length should be 20.");
            return id;
        };

        DataStore.prototype.initDataStore = function (mockConfig, mockData) {
            this.mockData = mockData;
            this.mockConfig = mockConfig;
            if (datastore == null) {
                datastore = new DataStore(this.mockConfig, this.mockData);
                if (mockData) {
                    datastore.repo.addNode(mockConfig.domain, mockData);
                }
            }
        };
        DataStore.prototype.initDataStore(this.mockConfig, this.mockData);

        var Snapshot = function (ref, data, priority) {
            this._ref = ref;
            //TODO remove all $priority properties
            this._data = createSnapshotCopy(data);
            $log.debug("ref: " + ref._id + " Creating snapshot with data: " + this._data);
            this._priority = priority;
        };

        var createSnapshotCopy = function (data) {
            //var newData = _.clone(data, true);
            //if(_.isObject(newData)) {
            //    if (_.has(newData, '$priority')) {
            //        delete newData['$priority'];
            //    }
            //}
            //return newData;
            return _.cloneDeep(data);
        };

        Snapshot.prototype.exists = function () {
            return this._data == null;
        };

        Snapshot.prototype.val = function () {
            return this._data;
        };

        Snapshot.prototype.child = function (childPath) {
            Assert(childPath, 'A childpath is required');

            var parts = childPath.split('/');
            var currData = this._data;
            _.each(parts, _.bind(function (item) {
                currData = this._data[item]
            }, this));
            var priority = currData ? currData.priority : null;
            return new Snapshot(this.ref.child(childPath), currData, priority);
        };

        Snapshot.prototype.forEach = function (actionFn) {
            Assert(actionFn, 'An actionFn is required');
            _.each(this._data, function (item) {
                return actionFn(item);
            })
        };

        Snapshot.prototype.hasChild = function (childPath) {
            Assert(childPath, 'A childpath is required');

            var childSnap = this.child(childPath);
            return childSnap.exists();
        };

        Snapshot.prototype.hasChildren = function () {
            return this.exists();
        };

        Snapshot.prototype.key = function () {
            return this._ref.key();
        };

        Snapshot.prototype.name = function () {
            $log.debug('Firebase.name() being deprecated. Please use Firebase.key() instead.');
            return this.key();
        };

        Snapshot.prototype.numChildren = function () {
            return this._data.length;
        };

        Snapshot.prototype.ref = function () {
            return this._ref;
        };

        Snapshot.prototype.getPriority = function () {
            return this._priority == undefined ? null : this._priority;
        };

        Snapshot.prototype.exportVal = function () {
            $log.debug('Not Implemented');
        };

        var Firebase = function (url) {
            this._id = Math.random() * 1000;
            Assert(url, 'A url is required');
            $log.debug('[Mock] new Firebase()');
            $log.debug("[Mock] creating Firebase mock for path: " + url);
            this.dataStore = datastore;
            this._events = {
                value: [],
                child_added: [],
                child_removed: [],
                child_changed: [],
                child_moved: [],
                onComplete: []
            };

            // remove https://
            var regex = '^https:\/\/|^http:\/\/';
            url = trim(url.replace(regex, ''));
            //append the config url if it's missing (this is for convenience)
            var mockDomain = this.dataStore.config.domain;
            if (url.indexOf(mockDomain) == -1 ) {
                url = mockDomain + "/" + url;
            }
            this._url = url;
        };

        /**
         * on
         * @param eventType
         * @param callback
         * @param cancelCallback
         * @param context
         */
        Firebase.prototype.on = function (eventType, callback, cancelCallback, context) {
            $log.debug('[Mock] on()');
            //TODO handle cancelcallback
            var callbackObject = {};
            callbackObject.ref = this;
            callbackObject.key = this._url;
            callbackObject.eventType = eventType;
            callbackObject.callback = callback;
            callbackObject.context = context;
            callbackObject.persistent = true;
            this.dataStore.registerCallback(callbackObject);
        };

        /**
         * once
         * @param eventType
         * @param callback
         * @param context
         */
        Firebase.prototype.once = function (eventType, callback, context) {
            var callbackObject = {};
            callbackObject.ref = this;
            callbackObject.key = this._url;
            callbackObject.eventType = eventType;
            callbackObject.callback = callback;
            callbackObject.context = context;
            callbackObject.persistent = false;
            this.dataStore.registerCallback(callbackObject);
        };

        /**
         * off
         * @param eventType
         * @param callback
         * @param context
         */
        Firebase.prototype.off = function (eventType, callback, context) {
            var callbackObject = {};
            callbackObject.ref = this;
            callbackObject.key = this._url;
            callbackObject.eventType = eventType;
            callbackObject.callback = callback;
            callbackObject.context = context;
            this.dataStore.removeCallback(callbackObject);
        };

        Firebase.prototype.auth = function () {
        };

        // Append the provided path to the ref path
        // For example: https://foo.com/car + 'dog/bar' -> https://foo.com/car/dog/bar
        Firebase.prototype.child = function (childPath) {
            Assert(childPath, 'A childpath is required');
            return new Firebase(this._url + "/" + childPath);
        };

        // Rip off the last location element
        // For example: https://foo.com/car/dog/bar -> https://foo.com/car/dog
        Firebase.prototype.parent = function () {
            $log.debug('[Mock] parent()');
            var parts = this._url.split('/');
            parts.splice(-1, 1);
            var path = parts.join('/');
            if (path == "") {
                return this;
            }
            return new Firebase(parts.join('/'));
        };

        // Rip off the whole subpath and just return the root url
        // For example: https://foo.com/cat/dog/bar -> https://foo.com
        Firebase.prototype.root = function () {
            //could just use key e.g. return key == null;
            $log.debug('[Mock] root()');
            var isRoot = false;
            var parts = this._url.split('/');
            parts.splice(-1, 1);
            var path = parts.join('/');
            if (path == "") {
                isRoot = true;
            }
            return isRoot;
        };

        // Return the last token in a firebase location
        // For example: https://foo.com/cat/dog -> dog
        Firebase.prototype.key = function () {
            $log.debug('[Mock] key()');
            var key;
            var parts = this._url.split('/');
            if (parts.length == 1) {
                key = null;
            } else {
                key = parts.pop();
            }
            return key;
        };

        // Deprecated, use key()
        Firebase.prototype.name = function () {
            $log.debug('Deprecated: use key() instead');
            return this.key();
        };

        /**
         * toString()
         * Returns full url path of this reference
         * @returns {*}
         */
        Firebase.prototype.toString = function () {
            return this._url;
        };

        // Write data to the location
        // Passing null will remove the data
        /**
         * set
         * @param value
         * @param onComplete
         */
        Firebase.prototype.set = function (value, onComplete) {
            $log.debug("MockFirebase SET()");
            this.dataStore.set(this, value, onComplete, null, false);
        };


        /**
         * setWithPriority
         * @param value
         * @param priority
         * @param onComplete
         */
        Firebase.prototype.setWithPriority = function (value, priority, onComplete) {
            $log.debug("MockFirebase setWithPriority()");
            this.dataStore.set(this, value, onComplete, priority, false);
        };

        /**
         * setPriority
         * @param priority
         * @param onComplete
         */
        Firebase.prototype.setPriority = function (priority, onComplete) {
            $log.debug("MockFirebase setPriority()");
            var data = this.dataStore.repo.getNode(this.toString());
            //set priority as really an update on the $priority field
            this.dataStore.set(this, data, onComplete, priority, true);
        };

        // Update data at the location. Changes the specified children but leaves unspecified children alone.
        /**
         * update
         * @param value
         * @param onComplete
         */
        Firebase.prototype.update = function (value, onComplete) {
            $log.debug('[Mock] update()');
            this.dataStore.set(this, value, onComplete, null, true);
        };

        /**
         * remove
         * @param onComplete
         */
        Firebase.prototype.remove = function (onComplete) {
            $log.debug('Mock: Remove');
            this.set(null, onComplete);
        };

        /**
         * push
         * @param value
         * @param onComplete
         * @returns {Firebase}
         */
        Firebase.prototype.push = function (value, onComplete) {
            $log.debug('Mock: Push');
            //if no value set we'll write an empty string
            value = (value) ? value : "";
            var now = this.dataStore.getServerTime();
            var childRef = new Firebase(this._url + "/" + this.dataStore.generatePushKey(now));
            childRef.set(value, onComplete);
            return childRef;
        };

        /**
         * transaction
         * @param updateFn
         * @param onComplete
         * @param applyLocally
         */
        Firebase.prototype.transaction = function (updateFn, onComplete, applyLocally) {
            $log.debug('Not Implemented');
        };

        Firebase.prototype.authWithCustomToken = function (token, onComplete) {
            //temp
            if (token != undefined) {
                if (onComplete) {
                    onComplete.call(this, null, {});
                }
            } else {
                if (onComplete) {
                    var error = {error: "Invalid Auth Token"};
                    onComplete.call(this, error, {});
                }
            }
        };

        //misc
        var trim = function (path) {
            return path.replace(/\/$/, '');
        };


        this.createRef = function (baseUrl) {
            $log.debug("[FirebaseMock] creating new firebaseMock ref: " + baseUrl);
            return new Firebase(baseUrl);
        };

    };

    this.createMock = function (data, config) {
        $log.debug("[FirebaseMockFactory] create new firebaseMock");
        if (config == undefined) { config = {} }
        if (data == undefined) { data = {} }
        this.config = _.defaults(config, this.config);
        this.data = _.defaults(data, this.data);
        return new FirebaseMock(this.config, this.data);
    };
    return this;
};

FirebaseMockFactory.$inject = ['$log', 'Assert'];
module.exports = FirebaseMockFactory;