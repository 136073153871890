function ActivityDrawerDirective(
    firebaseAppModel,
    activityService,
    assessmentService,
    lessonService,
    appModel
) {
    return {
        restrict: 'E',
        scope: {
            storeActivity: '='
        },
        replace: true,
        templateUrl:'/core/toys/app/src/toys/common/directives/activitydrawer/activitydrawer.tpl.html',
        link:function(scope) {
            const typeActivity = 'activity';
            const typeAssessment = 'assessment';
            const typeGame = 'game';
            const typeLesson = 'lesson';
            const types = [typeActivity, typeAssessment, typeGame];
            const typeFieldName = 'type';
            const typeFieldNameStore = 'activity_type';
            const activityAssessmentFieldName = 'activity_type';
            const activityAssessmentFieldNameStore = 'resource_type';
            scope.showActivity = false;
            scope.currentService = null;

            var checkActivity = function() {
                if(appModel.app.store) {
                    return scope.storeActivity;
                } else {
                    return firebaseAppModel.app.activeActivity;
                }
            };

            var removeWatcher = scope.$watch(checkActivity, function(activity) {
                scope.showActivity = false;
                if(!activity) {
                    scope.currentService && scope.currentService.reset();
                    return;
                }
                let typeField = scope.storeActivity ? typeFieldNameStore : typeFieldName;
                let isActivity = (activity[activityAssessmentFieldName] === typeActivity ||
                                activity[activityAssessmentFieldNameStore] === typeActivity);
                let isGame = (activity[activityAssessmentFieldName] === typeGame ||
                    activity[activityAssessmentFieldNameStore] === typeGame);
                let isLesson = (activity[activityAssessmentFieldName] === typeLesson ||
                    activity[activityAssessmentFieldNameStore] === typeLesson);
                let serviceData = scope.storeActivity ? angular.copy(activity) : activity;
                if (-1 !== types.indexOf(serviceData.activity_type)) {
                    serviceData.resource_type = serviceData.activity_type;
                }
                scope.activityType = _.propertyOf(activity)(typeField);

                if(!isGame) {
                    scope.currentService = isActivity ? activityService : ( isLesson ? lessonService : assessmentService);
                    scope.currentService.startup(serviceData).then(function () {
                        scope.showActivity = true
                    });
                } else {
                    scope.showActivity = true
                }

                if(scope.storeActivity) {
                    removeWatcher();
                }
            });

            scope.$on('$destroy', function() {
                if(!scope.currentService) {
                    return;
                }
                scope.currentService.reset();
                scope.currentService = null;
            });
        }
    }
}
ActivityDrawerDirective.$inject = [
    'firebaseAppModel',
    'activityService',
    'assessmentService',
    'lessonService',
    'appModel'
];
module.exports = ActivityDrawerDirective;