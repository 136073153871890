function HeroService($q, drfStoreHeroModel) {
    let allHeros = [];

    function getHeros() {
        if (allHeros.length) {
            return new Promise((resolve) => resolve(allHeros));
        } else {
            return new drfStoreHeroModel.Model().$list().then(function(data) {
                const heros = data.results.map((hero) => {
                    Object.assign(hero,
                        {
                            styles: `.btn-details:hover { background-color: ${shadeButtonBackgroundColor(hero.detail_button_background_color)} !important;}
                            .btn-queue:hover {background-color: ${shadeButtonBackgroundColor(hero.queue_button_background_color)} !important;}`,
                        }
                    );
                    return hero;
                });
                allHeros = heros;
                return heros;
            });
        }
    }

    function shade(color, percent) {
        if (color.length > 7) return shadeRGBColor(color, percent);
        else return shadeColor(color, percent);
    }

    this.shadeRGBColor = (color, percent) => {
        var f = color.split(','),
            t = percent < 0 ? 0 : 255,
            p = percent < 0 ? percent * -1 : percent,
            R = parseInt(f[0].slice(4)),
            G = parseInt(f[1]),
            B = parseInt(f[2]);
        return 'rgb(' + (Math.round((t - R) * p) + R) + ',' + (Math.round((t - G) * p) + G) + ',' + (Math.round((t - B) * p) + B) + ')';
    }

    function shadeButtonBackgroundColor(color) {
        if(color === 'transparent') {
            return 'rgba(255,255,255,0.1)';
        } else {
            return shade(color, -0.10);
        }
    }

    function shadeColor(color, percent) {
        var f = parseInt(color.slice(1), 16),
            t = percent < 0 ? 0 : 255,
            p = percent < 0 ? percent * -1 : percent,
            R = f >> 16,
            G = f >> 8 & 0x00FF,
            B = f & 0x0000FF;
        return '#' + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    }

    this.getRandomHero = () => {
        return getHeros().then((heros) => {
            if (heros.length) {
                const rand = Math.floor(Math.random() * heros.length);
                return heros[rand];
            }
            return null;
        });
    }

    this.getHeroByActivityType = (type) => {
        return getHeros().then((heros) => {
            const hero = heros.find(h => {
                return h.hero_type === 'activity' && h.hero && h.hero.activity_type === type;
            });
            return hero;
        });
    }

    this.getAssessmentHero = () => {
        return getHeros().then((heros) => {
            const hero = heros.find(h => {
                return h.hero_type === 'assessment';
            });
            return hero;
        });
    }

    this.getLessonHero = () => {
        return getHeros().then((heros) => {
            const hero = heros.find(h => {
                return h.hero_type === 'lesson';
            });
            return hero;
        });
    }


    this.getGameHero = () => {
        return getHeros().then((heros) => {
            const hero = heros.find(h => {
                return h.hero_type === 'game';
            });
            return hero;
        });
    }
}

HeroService.$inject = ['$q', 'drfStoreHeroModel'];
module.exports = HeroService;