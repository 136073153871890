var LiquidAssetContainerDirective = function($log, $interval, $window){
    return {
        restrict: 'A',
        replace: false,
        scope : {
            collection : '='
        },
        link: function (scope, element, attrs, controller) {

            var generate_hash = function(str){
                var hash = 0, i, chr, len;
                if (str.length == 0)
                    return hash;
                for (i = 0, len = str.length; i < len; i++) {
                    chr   = str.charCodeAt(i);
                    hash  = ((hash << 5) - hash) + chr;
                    hash |= 0; // Convert to 32bit integer
                }
                return hash;
            };

            // use the mutation observer when possible, otherwise just check it 10 times per second
            if(window.MutationObserver) {
                var observer = new window.MutationObserver(function() {
                    scope.doLayout();
                });
                var config = {
                    childList : true
                };
                observer.observe(element[0], config);
            }else{
                var last_hash = 0;
                $interval(function(){
                    var html = element.html();
                    var hash = generate_hash(html);
                    if(last_hash != hash) {
                        scope.doLayout();
                        last_hash = hash;
                    }
                }, 100);
            }

            var w = angular.element($window);

            w.bind('resize', function(){
                scope.doLayout();
            });

            var MARGIN = 7;
            var WIDTH = 1000;

            scope.doLayout = function(){
                var scrollbarWidth = element[0].offsetWidth - element[0].clientWidth;

                WIDTH = element.innerWidth() - scrollbarWidth - MARGIN;

                var assets = element.children();

                var ROW_HEIGHT = 140;

                // Scale all photos to height of 100px
                _.each(assets, function(asset){
                    var dataWidth = $(asset).attr('data-width');
                    var dataHeight = $(asset).attr('data-height');

                    // set min height/width
                    var actualWidth = dataWidth > 0 ? dataWidth : ROW_HEIGHT;
                    var actualHeight = dataHeight > 0 ? dataHeight : ROW_HEIGHT;

                    var ratio =   ROW_HEIGHT / actualHeight;

                    var scaledWidth = actualWidth * ratio;

                    $(asset).width(scaledWidth + 'px');
                    $(asset).height(ROW_HEIGHT + 'px');
                });

                var accumulatedWidth = 0;
                var row = [];

                for(var i = 0; i < assets.length; i++){

                    var assetWidth = $(assets[i]).width();

                    if(accumulatedWidth + assetWidth < WIDTH){
                        row.push(assets[i]);
                        accumulatedWidth += $(assets[i]).width() + MARGIN;

                        if(i == assets.length - 1){
                            rowRatio = WIDTH / accumulatedWidth;
                            rowRatio = rowRatio > 1.2 ? 1.2 : rowRatio;

                            scope.makeRow(row, rowRatio);
                        }
                    } else {
                        var rowRatio = WIDTH / accumulatedWidth;
                        rowRatio = rowRatio > 1.2 ? 1.2 : rowRatio;

                        scope.makeRow(row, rowRatio);

                        row = [assets[i]];
                        accumulatedWidth = $(assets[i]).width() + MARGIN;
                    }
                }

                if(row.length>0) {
                    var rowRatio = WIDTH / accumulatedWidth;
                    rowRatio = rowRatio > 1.2 ? 1.2 : rowRatio;

                    scope.makeRow(row, rowRatio);
                }
            };



            scope.makeRow = function(row, rowRatio){
                var totalWidth = 0;

                for(var i = 0; i < row.length; i++){
                    var newWidth = Math.floor($(row[i]).width() * rowRatio);
                    var newHeight = Math.floor($(row[i]).height() * rowRatio);

                    totalWidth += (newWidth + MARGIN);

                    var ass =  $(row[i]);

                    ass.css({
                        width: newWidth,
                        height: newHeight
                    });

                    var image = ass.find('img');

                    image.css({
                       'display' : 'inline'
                    });
                }

                var numLeftOverPixels = WIDTH - totalWidth;

                var pixelsPerImage = Math.floor(numLeftOverPixels / row.length);

                pixelsPerImage = Math.min(pixelsPerImage, 5);


                for(var i = 0; i < row.length; i++){
                    if(numLeftOverPixels < 1){
                        break;
                    }
                    numLeftOverPixels-=pixelsPerImage;

                    $(row[i]).css({
                        width: $(row[i]).width() + pixelsPerImage
                    })
                }
            };

            $log.debug("[LiquidAssetContainerDirective] link function()");
        }
    };
};

LiquidAssetContainerDirective.$inject = ['$log', '$interval', '$window'];
module.exports = LiquidAssetContainerDirective;
