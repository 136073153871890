var ExampleController = function($log, $scope, $stateParams) {

    var init = function () {
        $log.debug("ExampleController init");
    };
    init();
};

ExampleController.$inject = ['$log', '$scope', '$stateParams'];
module.exports = ExampleController;
