const defaultSort = {
    order_by: ''
};

/**
 * A helper class representing the sort to drfActivities.
 * TODO: should these methods be included in drfActivities directly?
 */
class ActivitySortModel {
    constructor() {
        this.reset();
    }

    reset() {
        this.order_by =defaultSort.order_by;
        return this;
    }

    setSort(orderBy) {
        this.order_by = orderBy;
        return this;
    }

    getRouteParams() {
        return {
            sort: this.order_by
        };
    }

}

module.exports = ActivitySortModel;