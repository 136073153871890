function plRecordFactory($q, plHttp, plLodash, plTimezone, plDateTimeRange, plRecordParticipants) {
    const plRecord = {};

    plRecord.save = (record) => {
        const deferred = $q.defer();

        plHttp.save('records', record)
            .then((res) => {
                deferred.resolve(res.data);
            }, (err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    };

    plRecord.saveBulk = (records) => {
        const deferred = $q.defer();
        const promises = [];
        records.forEach((record, index) => {
            promises.push(plRecord.save(record));
        });
        $q.all(promises)
            .then((resRecords) => {
                plRecordParticipants.expandData(resRecords, { client: true, billing: true, location: true })
                    .then((resRecords1) => {
                        deferred.resolve(resRecords1);
                    });
            }, (err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    };

    plRecord.updateAppointmentRecords = (appointmentRecords, records) => {
        let index;
        if (!appointmentRecords || !appointmentRecords.length) {
            appointmentRecords = [];
        }
        records.forEach((record) => {
            index = plLodash.findIndex(appointmentRecords, 'uuid', record.uuid);
            if (index < 0) {
                appointmentRecords.push(record);
            } else {
                appointmentRecords[index] = Object.assign({}, appointmentRecords[index], record);
            }
        });
        return appointmentRecords;
    };

    plRecord.getRecordChanges = (record, recordOriginal) => {
        const fields = ['billing_code', 'location', 'start', 'end', 'notes',
         'signed_on', 'signed_by', 'client_service', 'client', 'tracking_type'];
        let recordUpdates = {};
        let atLeastOneUpdate = false;
        let compareVal;
        fields.forEach((field) => {
            // To handle notes (where we remove blanks for comparison but want
            // to KEEP blanks for submitting), we distinguish between a compare
            // value and the actual value we'll save.
            compareVal = angular.copy(record[field]);
            if (field === 'notes') {
                compareVal = plRecord.removeBlankNotes(compareVal);
                // if (!record[field]) {
                //     delete record[field];
                // }
            }
            if (compareVal && (!recordOriginal[field] ||
             compareVal !== recordOriginal[field])) {
                recordUpdates[field] = record[field];
                atLeastOneUpdate = true;
            }
        });

        if (atLeastOneUpdate) {
            recordUpdates = plRecord.formatForBackend(recordUpdates);

            // On create, copy over required fields.
            if (!recordOriginal.uuid) {
                recordUpdates.billing_code = record.billing_code;
                recordUpdates.note_schema = record.note_schema;
            } else {
                recordUpdates.uuid = recordOriginal.uuid;
            }

            // Copy over schema.
            if (recordUpdates.notes) {
                recordUpdates.note_schema = record.note_schema;
            }
        }

        return atLeastOneUpdate ? recordUpdates : null;
    };

    plRecord.formatForBackend = (record) => {
        // Handle signed.
        if (!record.signed_on) {
            record.signed = false;
            record.signed_on = null;
            record.signed_by = null;
        } else {
            record.signed = true;
        }
        if (record.notes && typeof(record.notes) !== 'string') {
            record.notes = angular.toJson(record.notes);
        }
        return record;
    };

    plRecord.getChangedRecords = (records, recordsOriginal) => {
        const changedRecords = [];
        let recordChanges;
        let index;
        records.forEach((record) => {
            index = plLodash.findIndex(recordsOriginal, '_uuid', record._uuid);
            if (index > -1) {
                recordChanges = plRecord.getRecordChanges(record, recordsOriginal[index]);
                if (recordChanges) {
                    changedRecords.push(recordChanges);
                }
            }
        });
        return changedRecords.length ? changedRecords : null;
    };

    plRecord.removeBlankNotes = (notes) => {
        let atLeastOneNote = false;
        for (let note in notes) {
            if (!notes[note]) {
                delete notes[note];
            } else {
                atLeastOneNote = true;
            }
        }
        if (!atLeastOneNote) {
            return null;
        }
        return notes;
    };

    plRecord.recordToView = (record, user) => {
        if (!record) {
            return {};
        }
        record.notes = plRecord.removeBlankNotes(record.notes);
        const userTz = plTimezone.getUserZone(user);
        const start = plTimezone.toUserZone(record.start, plTimezone.dateTimeFormat, userTz);
        const end = plTimezone.toUserZone(record.end, plTimezone.dateTimeFormat, userTz);

        let signedNote = false;
        if (record.signed_on) {
            const dateSigned = moment(record.signed_on, plTimezone.dateTimeFormat).format('M/D/YY h:mma');
            signedNote = `Signed on ${dateSigned}. Any changes require re-signing.`;
        }
        let hasNotes = false;
        if (record.notes) {
            for (let note in record.notes) {
                if (note) {
                    hasNotes = true;
                    break;
                }
            }
        }

        const title = plRecord.formTitle(record);
        const requiredFields = plRecord.getRequiredFields(record);
        // Do not show location if already set OR if not required.
        const showLocation = requiredFields.showLocation;
        const locationName = record.location_expanded ? record.location_expanded.name : '[no location name]';
        const billingCodeName = record.billing_expanded ? record.billing_expanded.name : '[no billing name]';

        const recordView = {
            signedNote,
            hasNotes,
            title,
            showLocation,
            requiredFields,
            locationName,
            getLocationTracking: record.location_expanded && record.location_expanded.record_tracking_type,
            signed: !!record.signed_on,
            dateRange: plDateTimeRange.formatRange(start, end, 'dateFirst', true, 'minutes'),
            billingCodeName,
            notes: (typeof(record.notes) === 'string') ? JSON.parse(record.notes) : record.notes,
            recordClientNameHidden: (record.client_expanded && !record.client_expanded.first_name),
        };
        return recordView;
    };

    plRecord.formTitle = (record) => {
        let title;
        if (record.client_expanded && record.client_expanded.first_name) {
            title = `${record.client_expanded.first_name} ${record.client_expanded.last_name}`;
        } else if (record.client_expanded && !record.client_expanded.first_name) {
            title = 'client name hidden';
        } else if (record.location_expanded) {
            title = record.location_expanded.name;
        } else {
            title = record.billing_expanded ? record.billing_expanded.name : '[no billing name]';
        }
        return title;
    };

    plRecord.getRequiredFields = (record) => {
        const required = {
            clients: true,
            location: true,
            showLocation: true,
        };
        if (record.billing_expanded) {
            const billingInfo = record.billing_expanded;
            required.clients = (billingInfo.client_participates === 'NONE') ? false : true;
            // For records, location IS required if clients, even if location is not required.
            // Show location is false if location does not participate OR clients does not participate,
            // in which case we already have the location in the title and do not want to double select it.
            if (billingInfo.client_participates === 'NONE') {
                if (billingInfo.location_participates === 'NONE') {
                    required.location = false;
                    required.showLocation = false;
                }
            }
        }
        return required;
    };

    return plRecord;
}
plRecordFactory.$inject = ['$q', 'plHttp', 'plLodash', 'plTimezone', 'plDateTimeRange', 'plRecordParticipants'];
module.exports = plRecordFactory;
