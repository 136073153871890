// https://github.com/mrdoob/eventdispatcher.js/
var EventDispatcherFactory = function() {
    var EventDispatcher = function() {};

    EventDispatcher.prototype = {
        //constructor: EventDispatcher,
        on: function(type, listener) {
            if(this._listeners === undefined) {
                this._listeners = {};
            }

		    var listeners = this._listeners;

    		if(listeners[type] === undefined) {
			    listeners[type] = [];
		    }

		    if(listeners[type].indexOf(listener) === -1) {
			    listeners[type].push(listener);
		    }
        },
        off: function(type, listener) {
            if (this._listeners === undefined) {
                return;
            }

		    var listeners = this._listeners;
		    var listenerArray = listeners[type];

            if (listenerArray !== undefined) {
                var index = listenerArray.indexOf(listener);
                if (index !== -1) {
                    listenerArray.splice(index, 1);
                }
            }
        },
        once: function(type, listener) {
            throw Error('Not Implemented');
        },
        hasListener: function(type, listener) {
            if(this._listeners === undefined){
                return false;
            }

		    var listeners = this._listeners;

	    	if(listeners[type] !== undefined && listeners[type].indexOf(listener) !== -1) {
			    return true;
		    }
		    return false;
        },
        dispatchEvent: function(event) {
            if(this._listeners === undefined) {
                return;
            }

            var listeners = this._listeners;
            var listenerArray = listeners[event.type];

            if(listenerArray !== undefined) {
                event.target = this;
                var array = [];

                var length = listenerArray.length;
                for (var i=0; i < length; i++) {
                    array[i] = listenerArray[i];
                }

                for (var i=0; i < length; i++) {
                    array[ i ].call(this, event);
                }
            }
        }
    };

    EventDispatcher.apply = function(object) {
        var methods = ['on', 'off', 'once', 'dispatchEvent', 'hasListener'];
        _.each(methods, function(item) {
            object.prototype[item] = this.prototype[item];
        }, this);
    };

    return EventDispatcher;
};

EventDispatcherFactory.$inject = [];
module.exports = EventDispatcherFactory;