var YouTubeController = function($log, $scope, $stateParams) {

    function init() {
        $log.debug("YouTubeController init");
    };
    init();
};

YouTubeController.$inject = ['$log', '$scope', '$stateParams'];
module.exports = YouTubeController;
