import toastr from 'toastr';

function plToastFactory($timeout) {
  toastr.options.showMethod = 'fadeIn';
  toastr.options.hideMethod = 'fadeOut';
  toastr.options.hideEasing = 'swing';
  toastr.options.hideDuration = '10'
  toastr.options.closeMethod = 'fadeOut';
  toastr.options.timeOut = 3000;
  toastr.options.positionClass='toast-center-screen';

  const plToast = {};

  plToast.toastSuccess = (message, options) => {
    _toast(`<div class="pl-toast-message">${message}</div>`, options, 'success');
  }

  plToast.toastFailure = (message, options) => {
    const _options = Object.assign({
      tapToDismiss: false,
      closeButton: true,
      timeOut: 0,
      extendedTimeOut: 0,
    }, options);
    console.log('toast options', _options)
    _toast(`<div class="pl-toast-message">${message}</div>`, _options, 'error');
  }

  plToast.toastInfo = (message, options) => {
    _toast(`<div class="pl-toast-message">${message}</div>`, options, 'info');
  }

  plToast.toastWarning = (message, options) => {
    _toast(`<div class="pl-toast-message">${message}</div>`, options, 'warning');
  }

  /**
   * toastType (String): success | error | info | warning
   */
  const _toast = (formattedMessage, options, toastType) => {
    options = options || {}
    const toastrOptionsCopy = Object.assign({}, toastr.options);
    Object.assign(toastr.options, options);
    $timeout(()=>{
      toastr[toastType](formattedMessage).css(options.css||{});
      toastr.options = toastrOptionsCopy;
    }, 500);
  };

  plToast.options = toastr.options;
  return plToast;
}

plToastFactory.$inject = ['$timeout'];
module.exports = plToastFactory;
