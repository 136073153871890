var RadialProgressDirective = function($timeout) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            progress: '=',
            showProgress: '='
        },
        templateUrl: '/toychest/common/directives/Flashcards/RadialProgressDirective.tpl.html',
        link: function($scope, $element, $attr) {
            var transform_styles = ['-webkit-transform', '-ms-transform', 'transform'];

            $scope.$watch('progress', function(newProgress) {
                updateProgress(newProgress);
                if(newProgress === 100) {
                    finishUpload();
                }
            });

            function updateProgress(newProgress) {
                var rotation = newProgress * 1.8;
                var fill_rotation = rotation;
                var fix_rotation = rotation * 2;
                for(let i in transform_styles) {
                    $element.find('.circle .fill, .circle .mask.full').css(transform_styles[i], 'rotate(' + fill_rotation + 'deg)');
                    $element.find('.circle .fill.fix').css(transform_styles[i], 'rotate(' + fix_rotation + 'deg)');
                }

                $element.find('.progress-title').html(newProgress + '% done');
            }

            function finishUpload() {
                $timeout(function() {
                    $scope.showProgress = false;
                }, 200);
            }
        }
    };
};

RadialProgressDirective.$inject = ['$timeout'];
module.exports = RadialProgressDirective;