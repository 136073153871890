var AssetSearchDirective = function(AssetSearchModel, $timeout, drfSearchQueryModel, $state, activityTags, currentUserModel) {
    return {
        restrict: 'E',
        replace: false,
        templateUrl: '/toychest/modules/store/assets/assets.search.tpl.html',
        scope: {
            baseFilter: '=',
            drfModel: '=',
            loading: '=',
            redirectOnSearch: '@',
            type: '@',
            showSpinner: '='
        },
        link: function(scope, element, attrs) {
            if (!scope.baseFilter) {
                scope.baseFilter = {};
            }

            scope.showTags = scope.$eval(attrs.showTags);

            var recentSearchModel = new drfSearchQueryModel.Collection();

            var suggestLimit = 5;
            var inputEl = element.find('input');

            if (scope.type === 'asset') {
                scope.drfType = 'Asset';
            }

            scope.search = AssetSearchModel;
            scope.suggestions = {
                index: -1
            };
            const isMyImages = $state.current.name.includes('my_images');
            scope.searchPlaceholder = isMyImages ? 'Search My Images' : 'Search through all assets';

            function storeQuery() {
                if (scope.search.filter.q) {
                    var sqm = new drfSearchQueryModel.Model();
                    sqm.$save({
                        type: scope.drfType,
                        query: scope.search.filter.q
                    });
                }
            }

            function filter() {
                if (scope.redirectOnSearch) {
                    scope.search.filter.q = scope.queryInput;
                    $state.go(scope.redirectOnSearch, {
                        clear: false,
                        q : scope.queryInput,
                        activity : scope.search.filter.activity_type,
                        tag : scope.search.filter.tag

                    }, {
                        location: false,
                    });
                    return;
                }
                scope.queryFocused = false;
                scope.recentFocused = false;
                scope.loading = true;
                scope.showSpinner = true;
                var filterObject= _.merge(scope.baseFilter, scope.search.filter);
                scope.drfModel.$filter(filterObject);
                scope.drfModel.$fetch().then(function() {
                    scope.loading = false;
                    scope.showSpinner = false;
                });
                storeQuery();
            }

            function resetSuggestIndex() {
                scope.suggestions.index = -1;
                scope.origionalQueryInput = undefined;
            }

            function resetSuggest() {
                scope.suggestions.data = undefined;
                resetSuggestIndex();
            }

            scope.suggest = function() {
                var suggest, recent, query, label;

                if (!scope.queryInput || scope.queryInput.length === 0) {
                    suggest = false;
                    recent = true;
                    query = scope.search.filter.q;
                    label = 'Search suggestions';
                } else {
                    suggest = true;
                    recent = false;
                    query = scope.queryInput;
                    label = 'Suggested queries';
                }
                recentSearchModel.$clear();
                recentSearchModel.$limit(suggestLimit);
                var filterObject = _.merge(scope.baseFilter, {
                    'type': scope.drfType,
                    'q': query,
                    'suggest': suggest,
                    'recent': recent
                });
                recentSearchModel.$filter(filterObject);
                recentSearchModel.$fetch().then(function(data) {
                    resetSuggest();
                    scope.suggestions.data = data;
                    scope.suggestions.label = label;
                    scope.suggestions.query = query;
                });
            };

            scope.onKeyDown = function(e) {
                if (e.keyCode === 38) {
                    scope.onUpArrow();
                    e.preventDefault();
                } else if (e.keyCode == 40) {
                    scope.onDownArrow();
                    e.preventDefault();
                } else {
                    resetSuggestIndex();
                }
            };

            function changeSuggestion() {
                if (scope.origionalQueryInput === undefined) {
                    scope.origionalQueryInput = scope.queryInput;
                }
                if (scope.suggestions.index < 0) {
                    scope.queryInput = scope.origionalQueryInput;
                } else {
                    scope.queryInput = scope.suggestions.data[scope.suggestions.index].query;
                }
            }

            scope.changeSuggestion = function(index) {
                //Note index = 0 is ok, so we cannot check use "!index" as a check
                if (index === undefined || index === null) {
                    return;
                }
                scope.suggestions.index = index;
                changeSuggestion();
            };

            scope.clearSuggestion = function() {
                scope.suggestions.index = -1;
                changeSuggestion();
            };

            scope.onDownArrow = function() {
                if (scope.suggestions.index >= scope.suggestions.data.length - 1) {
                    scope.suggestions.index = -1;
                } else {
                    scope.suggestions.index++;
                }
                changeSuggestion();
            };

            scope.onUpArrow = function() {
                if (scope.suggestions.index < 0) {
                    scope.suggestions.index = scope.suggestions.data.length - 1;
                } else {
                    scope.suggestions.index--;
                }
                changeSuggestion();
            };

            scope.addTag = function(tag) {
                scope.search.addTag(tag);
                filter();
            };

            scope.removeTag = function(tag) {
                scope.search.removeTag(tag);
                filter();
            };

            scope.removeActivityType = function(){
                scope.search.removeActivityType();
                filter();
            }

            scope.setActivityType = function(type) {
                scope.search.setActivityType(type);
                filter();
            };

            scope.removeType = function() {
                scope.search.removeType();
                filter();
            };

            scope.query = function(input) {
                inputEl.blur();
                scope.queryInput = input;
                scope.search.filter.q = input;
                filter();
            };

            scope.removeQuery = function() {
                scope.search.filter.q = '';
                scope.queryInput = '';
                filter();
            };

            scope.removeFacet = function() {
                $state.go('app.store.activities.all');
            };

            scope.drfModel.$clear();
            scope.queryInput = scope.search.filter.q;
            if (!scope.redirectOnSearch) {
                filter();
            }
        }
    };
};

AssetSearchDirective.$inject = ['AssetSearchModel', '$timeout', 'drfSearchQueryModel', '$state', 'activityTags', 'currentUserModel'];
module.exports = AssetSearchDirective;