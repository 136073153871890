module.exports = angular.module('toys.activity.flashcards', [])
	.constant('FLASH_CARDS_OPTIONS', {
		GRID: {
			MAX_COLS: 7,
			MAX_ROWS: 5
		}
	})
	.config(['$animateProvider', function($animateProvider) {
		// We need to explicitly disable angular animation for cards and selection tool.
		// Otherwise ng-hide will work with significant delay (due to 'animated' css class).
		$animateProvider.classNameFilter(/^((?!(card|select)).)*$/);
	}])
	.controller('FlashcardsController', require('./controllers/FlashcardsController'))
	.controller('FlashcardsDrawerController', require('./controllers/FlashcardsDrawerController'))
    .directive('flashcards', require('./directives/FlashcardsDirective'))
    .directive('plTextOverflow', require('./directives/plTextOverflowDirective'))
    .service('flashCards', require('./services/FlashCards'))

    .directive('flashcardsActivity', require('./directives/FlashcardsActivityDirective'))
    .directive('flashcardsActivityDrawer', require('./directives/FlashcardsActivityDrawerDirective'))
;
