function plEventRecords(plRecordParticipants, currentUserModel, plRecord, plLodash) {
    return {
        restrict: 'E',
        scope: {
            event: '=',
            triggerSave: '=',
            save: '&?',
            saveRecord: '&?',
            submitLoading: '=?',
            user: '=?',
            reInit: '=?',
        },
        replace: true,
        templateUrl: '/core/pl-records/src/plEventRecords/plEventRecords.tpl.html',
        link: ($scope) => {
            $scope.errorMessage = '';

            let recordsOriginal = [];
            if (!$scope.user) {
                $scope.user = {
                    uuid: currentUserModel.user.uuid,
                };
            }
            $scope.viewOnly = ($scope.user.uuid !== currentUserModel.user.uuid) ? true : false;

            function init() {
                plRecordParticipants.formRecords($scope.event,
                 { client: true, billing: true, location: true }, $scope.user.uuid)
                    .then((resRecords) => {
                        recordsOriginal = resRecords;
                        $scope.records = angular.copy(recordsOriginal);
                    });
            }
            init();
            $scope.$watch('event', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal)) {
                    init();
                }
            });
            $scope.$watch('event.records', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal)) {
                    init();
                }
            });
            $scope.$watch('reInit', (newVal, oldVal) => {
                init();
            });

            function isFormValid(record) {
                let valid = false;
                if (record._formValid || !record.client_expanded) {
                    // Check to see if documents are there (if required).
                    const csModel = record.clientServiceModel;
                    if (!csModel || !csModel.documentRequired || csModel.hasFiles) {
                        valid = true;
                    } else {
                      console.log('-- [plEventRecordsDirective] unexpected state in record.clientServiceModel', record.clientServiceModel);
                    }
                }
                return valid;
            }

            $scope.saveRecordWrapper = (record) => {
                if (isFormValid(record)) {
                    let clientService = null;
                    if (record.clientServiceModel) {
                        if (Object.keys(record.clientServiceModel).length !== 0) {
                            clientService = Object.assign({}, record.clientServiceModel);
                        }
                        // delete record.clientServiceModel;
                    }
                    // Still need to do some formatting for saving, so mimic the bulk save.
                    const index = plLodash.findIndex(recordsOriginal, '_uuid', record._uuid);
                    const changedRecords = plRecord.getChangedRecords([record],
                     [recordsOriginal[index]]);
                    if (changedRecords && changedRecords[0]) {
                        $scope.saveRecord()(changedRecords[0], clientService);
                    }
                }
            };

            $scope.$watch('triggerSave', (newVal, oldVal) => {
                if (newVal && !angular.equals(oldVal, newVal)) {
                    let allValid = true;
                    // Pull out client services.
                    const clientServices = [];
                    $scope.records.forEach((record) => {
                        if (!record._formValid) {
                            allValid = false;
                        } else {
                            if (record.clientServiceModel) {
                                if (Object.keys(record.clientServiceModel).length !== 0) {
                                    clientServices.push(Object.assign({}, record.clientServiceModel));
                                }
                                // delete record.clientServiceModel;
                            }
                        }
                    });
                    if (allValid) {
                        $scope.errorMessage = '';
                        const changedRecords = plRecord.getChangedRecords($scope.records,
                         recordsOriginal);
                        $scope.save()(changedRecords, clientServices);
                    } else {
                        $scope.errorMessage = 'Please fill out all fields then re-submit.';
                        // Have to re-toggle trigger save so it will work again next time.
                        $scope.triggerSave = !$scope.triggerSave;
                    }
                }
            });
        },
    };
}

plEventRecords.$inject = ['plRecordParticipants', 'currentUserModel', 'plRecord', 'plLodash'];
module.exports = plEventRecords;
