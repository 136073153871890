"use strict";
let DataNode = function($log, Assert) {

    class DataNode {

        //convert raw json into a DataNode
        //this lets mock data use $priority
        static convert(data, priority) {
            if (data instanceof DataNode) {
                return data;
            } else if (_.isObject(data)) {
                if (_.has(data, "$priority")) {
                    priority = data["$priority"];
                    delete data["$priority"];
                }
                return new DataNode(data, priority);
            } else {
                return new DataNode(data, priority);
            }
        };

        constructor(value, priority) {
            this.value = value;
            this.priority = priority;
        };

        getValue() {
            return this.value;
        };

        setValue(value) {
            this.value = value;
        };

        getPriority() {
            return this.priority != undefined ? this.priority : null;
        };

        setPriority(priority) {
            this.priority = priority;
        };

        hasObjectValue() {
            return _.isObject(this.value);
        };

        clone() {
            return new DataNode(_.cloneDeep(this.value), _.cloneDeep(this.priority));
        };

        exportVal() {
            let result = {};
            if (this.hasObjectValue()) {
                result = this.value;
                if (this.priority) {
                    result['.priority'] = this.priority;
                }
            } else {
                if (this.priority) {
                    result['.value'] = this.value;
                    result['.priority'] = this.priority;
                } else {
                    result = this.value;
                }
            }
            return result;
        };

    };
    return DataNode;
};
DataNode.$inject = ['$log', 'Assert'];
module.exports = DataNode;
