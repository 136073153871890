/**
 * Tooltip directive
 *
 * @param $log
 * @param $document
 * @constructor
 */

var RatingDirective = function() {
	return {
		replace: true,
		restrict: 'E',
		scope: {
			rating: "@",
			count: "@"
		},
		templateUrl: '/toychest/common/directives/rating/rating.tpl.html',
		link: function($scope, $element, $attr) {
			// round a 0-1 range into one of the following set
			// [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50]
			// $scope.$watch('percent', function(newValue, oldValue){
			//     if(newValue !== undefined){
			//         $scope.amount = Math.round(($scope.percent * 100 / 2) / 5) * 5;
			//     }
			// });
			$scope.getStarClasses = function(ind) {
				var fullStar = ind <= $scope.rating;
				var halfStar = (ind-1)+0.5 <= $scope.rating && !fullStar;
				return {
					'full': fullStar,
					'half': halfStar
				};
			};
		}
	}
};

module.exports = RatingDirective;