var TagInputDirective = function ($log, $state, tagModel) {
    return {
        restrict: 'E',
        templateUrl: '/toychest/common/directives/TagInput/tagInput.tpl.html',
        scope : {
            tags: '=',
            saveObject: '=',
            onChange: '&',
            tagAdded: '&',
            tagRemoved: '&',
            readonlyMode: '=',
        },
        link: function ($scope, $element, $attr) {
            var $input = $element.find('input');

            $scope.removeTag = function(item, e) {
                e.preventDefault();
                _.remove($scope.tags, function(tag){
                    return item.name == tag.name;
                });

                if($scope.onChange) {
                    $scope.onChange();
                }
                if($scope.tagRemoved){
                    $scope.tagRemoved({tag:item.name})
                }

            };

            $scope.getStateForTag = function(tag) {
                if ($state.includes('app.store.activities')) {
                    return 'app.store.activities.tag({ tag: "' + tag.slug + '" })';
                }
                if ($state.includes('app.store.assets')) {
                    return 'app.store.assets.tag({ tag: "' + tag.slug + '" })';
                }
            };

            $scope.inputChanged = (event) => {
                switch(event.keyCode) {
                    case 32:    // space
                    case 13:    // enter
                        handleWhiteSpace(event);
                        break;
                }
            };


            var handleWhiteSpace = function(event) {
                var val = event.target.value;
                var tags = val.split(' ');
                var newTags = [];
                _.each(tags, function(item) {
                    item = item.trim();
                    if(item == ''){
                        return;
                    }

                    if(_.findIndex($scope.tags, {name:item}) < 0){
                        var newTag = new tagModel.Model();
                        newTag['name'] = item;
                        delete newTag['resource_uri'];
                        $scope.tags.push(newTag);
                        newTags.push(newTag);
                    }

                });

                event.target.value = '';

                if($scope.onChange) {
                    $scope.onChange();
                }

                if($scope.tagAdded){
                    _.each(newTags, function(tag) {
                        $scope.tagAdded({tag:tag.name})
                    });
                }

                if ($scope.saveObject != undefined) {
                    $scope.saveObject.$save();
                }
            };

        }
    }
};

TagInputDirective.$inject = ['$log', '$state', 'tagModel'];
module.exports = TagInputDirective;
