var GameDetailController = function(
    $window,
    $scope,
    $stateParams,
    $state,
    $sce,
    $injector,
    Game,
    Room,
    PreviousState,
    currentUserModel
) {
    // Activities queue is only needed if there's a room
    var activitiesQueue = null;
    if(Room) {
        activitiesQueue = $injector.get('activitiesQueue');
    }
    $scope.queue = activitiesQueue;
    $scope.editTitle = false;
    $scope.editDescription = false;

    $scope.username = currentUserModel.user.username;

    $scope.game = Game;
    $scope.prevState = PreviousState;

};

GameDetailController.$inject = [
    '$window',
    '$scope',
    '$stateParams',
    '$state',
    '$sce',
    '$injector',
    'Game',
    'Room',
    'PreviousState',
    'currentUserModel'
];
module.exports = GameDetailController;
