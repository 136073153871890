var DrfSearchQueryModel = function(drfModel, applications) {

    var SearchQuery = new drfModel({
        apiUrl: `${applications.platform.url}/api/v1/searchQuery/`,
    });

    _.extend(SearchQuery.Model.prototype, {

    });

    _.extend(SearchQuery.Collection.prototype, {
    });

    return SearchQuery;
};

DrfSearchQueryModel.$inject = ['drfModel', 'applications'];
module.exports = DrfSearchQueryModel;
