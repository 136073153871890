function plOverlayDropdownFactory($timeout, guidService) {
    const odp = {};
    const _odp = {};
    let _singleDropdown = null;
    let _singleDropContent = null;
    let _singleElement = null;
    let _singleContainer = null;
    let _singleAppendTo = null;
    let _singleId = null;
    let _clickEvent = null;
    let _clickFn = null;
    let _containerClass = 'pl-overlay-dropdown-container';
    let _contentClass = 'pl-overlay-dropdown-content-wrapper';

    odp.createDropdown = ($element, appendTo, dropContent, fromBottom = false, classes = '', id1 = null, cloneContent = false) => {
        const id = id1 || guidService.generateUUID();
        let container = $('<div>', {
                id: id,
                class: `${_containerClass} ${classes}`
            }),
            arrow = $('<figure>', {
                class: 'arrow'
            }),
            side1 = $('<div>', {
                class: 'side-1'
            }),
            side2 = $('<div>', {
                class: 'side-2'
            });
        let content = $('<div>', {
            class: _contentClass,
        });
        if (cloneContent) {
            content.append($(dropContent).clone());
        } else {
            content.append($(dropContent));
        }
        if (content.length) {
            arrow
                .append(side1)
                .append(side2);
            container
                .append(arrow)
                .append(content);
            container.arrow = side1.add(side2);
            if (appendTo) {
                $(appendTo).append(container);
            } else {
                $element.after(container);
            }
            if (fromBottom) {
                container.addClass('from-bottom');
            }
            return container;
        } else {
            return null;
        }
    };

    odp.updateContent = (dropdown, contentSelector) => {
        const contentEle = dropdown.find(`.${_contentClass}`);
        contentEle.html($(contentSelector).html());
        contentEle.addClass($(contentSelector).attr('class'));
    };

    odp.positionDropdown = ($element, dropdown, container, appendTo, offsetX1 = 0, fromBottom = false) => {
        const MIN_CONTAINER_MARGIN = 10;
        const ARROW_HEIGHT = 10;
        let appendOffset = {
            left: 0,
            top: 0,
        };
        if (appendTo) {
            const appendToEle = $(appendTo);
            appendOffset.left = (appendToEle.offset() ? appendToEle.offset().left : 0) - appendToEle.scrollLeft();
            appendOffset.top = (appendToEle.offset() ? appendToEle.offset().top : 0) - appendToEle.scrollTop();
        }
        let eleLeft = appendTo ? ($element.offset().left - appendOffset.left) : $element.position().left;
        let eleWidth = $element.outerWidth(true);
        let eleXCenter = eleLeft + eleWidth / 2;
        let eleTop = appendTo ? ($element.offset().top - appendOffset.top) : $element.position().top;
        let eleHeight = $element.outerHeight(true);
        let eleBottom = eleTop + eleHeight;

        let dropdownWidth = dropdown.outerWidth();
        let dropdownHeight = dropdown.outerHeight();
        let dropdownLeft = eleXCenter - dropdownWidth / 2;
        let dropdownRight = dropdownLeft + dropdownWidth;
        let dropdownTop = eleBottom;
        let dropdownBottom = dropdownTop + dropdownHeight;

        let containerLeft = appendTo ? (container.offset().left - appendOffset.left) : container.offset().left;
        let containerTop = appendTo ? (container.offset().top - appendOffset.top) : container.offset().top;
        let containerRight = containerLeft + container.width();
        let containerBottom = containerTop + container.height();

        let containerDeltaLeft = dropdownLeft - containerLeft;
        let containerDeltaRight = containerRight - dropdownRight;
        let containerDeltaTop = dropdownTop - containerTop;
        let containerDeltaBottom = containerBottom - dropdownBottom;

        if (containerDeltaLeft < MIN_CONTAINER_MARGIN) {
            dropdownLeft = dropdownLeft - containerDeltaLeft + MIN_CONTAINER_MARGIN;
        } else if (containerDeltaRight < MIN_CONTAINER_MARGIN) {
            dropdownLeft = dropdownLeft + containerDeltaRight - MIN_CONTAINER_MARGIN;
            // dropdown.arrow.css('left', width / 2 + 10 - dropRightFromContainer);
        }
        if (containerDeltaTop < MIN_CONTAINER_MARGIN) {
            dropdownTop = dropdownTop - containerDeltaTop + MIN_CONTAINER_MARGIN;
        } else if (containerDeltaBottom < MIN_CONTAINER_MARGIN) {
            dropdownTop = dropdownTop + containerDeltaBottom - MIN_CONTAINER_MARGIN;
            // dropdown.arrow.css('left', width / 2 + 10 - dropRightFromContainer);
        }

        if (offsetX1) {
            let offsetX = parseInt(offsetX1, 10);
            dropdownLeft += offsetX;
            // dropdown.find('.arrow').css({
            //     'position': 'relative',
            //     'left': -offsetX + 'px'
            // });
        }

        if (fromBottom) {
            dropdown.css({
                top: 'auto',
                bottom: eleBottom + ARROW_HEIGHT,
            });
        } else {
            dropdown.css('top', dropdownTop);
        }
        dropdown.css('left', dropdownLeft);
        dropdown.arrow.css('left', eleXCenter - dropdownLeft);
    };

    odp.closeDropdown = ($element, dropdown, container, appendTo, offsetX, fromBottom) => {
        $(window).off('resize', function(evt) {
            odp.positionDropdown($element, dropdown, container, appendTo, offsetX, fromBottom);
        });
        dropdown
            .removeClass('animate-in')
            .addClass('animate-out');
        $timeout(function() {
            dropdown.removeClass('animate-out');
        }, 400);
        dropdown.active = false;
    };

    odp.openDropdown = ($element, dropdown, container, appendTo, offsetX, fromBottom) => {
        odp.positionDropdown($element, dropdown, container, appendTo, offsetX, fromBottom);

        $(window).on('resize', function(evt) {
            odp.positionDropdown($element, dropdown, container, appendTo, offsetX, fromBottom);
        });
        dropdown
            .removeClass('animate-out')
            .addClass('animate-in');
        dropdown.active = true;
    };

    odp.createSingleDropdown = ($element, dropContent, options1 = {}) => {
        const container1 = options1.container || null;
        const appendTo = options1.appendTo || 'body';
        const offsetX = (options1.offsetX !== undefined) ? options1.offsetX : 0;
        const fromBottom = options1.fromBottom || false;
        const multiclick = (options1.multiclick !== undefined) ? options1.multiclick : 0;
        const classes = options1.classes || '';
        // By default, if update content, clone too.
        const cloneContent = options1.cloneContent ? options1.cloneContent : options1.updateContent;
        const id = guidService.generateUUID();
        let create = false;
        let open = false;
        let close = false;
        let setDocumentEvent = false;
        if (_singleElement && _singleElement[0] !== $element[0]) {
            if (_singleElement) {
                _singleElement = $element;
                _odp.offEventsSingleDropdown();
                setDocumentEvent = true;
            }
            open = true;
        } else if (!_singleDropdown) {
            create = true;
        }
        else if (_singleElement && _singleDropdown) {
            if (!_singleDropdown.active) {
                open = true;
            } else {
                close = true;
            }
        }

        if (create) {
            setDocumentEvent = true;
            _singleDropdown = odp.createDropdown($element, appendTo, dropContent, fromBottom,
             classes, id, cloneContent);
            _singleDropContent = dropContent;
            _singleElement = $element;
            let container = container1 ? $(container1) : $('body');
            _singleContainer = container;
            _singleAppendTo = appendTo;
            _singleId = id;
            
            odp.openDropdown($element, _singleDropdown, container, appendTo, offsetX, fromBottom);
        } else if (open) {
            if (options1.updateContent) {
                odp.updateContent(_singleDropdown, dropContent);
            }
            odp.openDropdown($element, _singleDropdown, _singleContainer, _singleAppendTo, offsetX, fromBottom);
        } else if (close) {
            odp.closeDropdown($element, _singleDropdown, _singleContainer, _singleAppendTo);
        }
        if (setDocumentEvent) {
            // This needs to be on mouseup to allow button events to fire on mouse down.
            _clickEvent = 'ontouchend' in document.documentElement ? 'touchend' : 'mouseup';
            _clickFn = function(evt) {
                odp.onDocumentClickSingle(evt, _singleElement, _singleDropdown, _singleContainer,
                 _singleAppendTo, offsetX, fromBottom, multiclick);
            };
            $(document).on(_clickEvent, _clickFn);
        }
    };

    odp.closeSingleDropdown = (destroy = false) => {
        if (_singleDropdown) {
            odp.closeDropdown(_singleElement, _singleDropdown, _singleContainer, _singleAppendTo);
            if (destroy) {
                _odp.destroySingleDropdown();
            }
        }
    };

    _odp.removeSingleDropdown = () => {
        if (_singleAppendTo === 'body') {
            $(`body #${_singleId}`).remove();
        } else {
            // Do not need to remove because if the parent element is removed it will be?
        }
    };

    _odp.offEventsSingleDropdown = () => {
        $(document).off(_clickEvent, _clickFn);
        _clickFn = null;
    };

    _odp.destroySingleDropdown = () => {
        _odp.removeSingleDropdown();

        _odp.offEventsSingleDropdown();
        _singleDropdown = null;
        _singleElement = null;
        _clickEvent = null;
    };

    odp.onDocumentClickSingle = (e, $element, dropdown, container, appendTo, offsetX, fromBottom, multiclick) => {
        odp.onDocumentClick(e, $element, dropdown, container, appendTo, offsetX, fromBottom, multiclick);
    };

    odp.onDocumentClick = (e, $element, dropdown, container, appendTo, offsetX, fromBottom, multiclick) => {
        if (dropdown) {
            let clickInElement = $.contains($element[0], e.target) || $element.is(e.target);
            let clickInDropdown = $.contains(dropdown[0], e.target) || dropdown.is(e.target);
            if (clickInElement && !_singleDropdown) {
                if (dropdown.active) {
                    odp.closeDropdown($element, dropdown, container, appendTo, offsetX, fromBottom);
                } else {
                    odp.openDropdown($element, dropdown, container, appendTo, offsetX, fromBottom);
                }
            } else if (dropdown.active && multiclick && !_singleDropdown) {
                odp.closeDropdown($element, dropdown, container, appendTo, offsetX, fromBottom);
            } else if (dropdown.active && !clickInElement && !clickInDropdown) {
                //a click on the document outside the button or target dropdown always closes.
                odp.closeDropdown($element, dropdown, container, appendTo, offsetX, fromBottom);
            }
        }
    };

    return odp;
}
plOverlayDropdownFactory.$inject = ['$timeout', 'guidService'];
module.exports = plOverlayDropdownFactory;
