function plFileAmazonFactory($q, plHttp) {
    const plFileAmazon = {};

    plFileAmazon.getAmazonUrl = (data) => {
        const deferred = $q.defer();

        plHttp.save('upload', data)
            .then((res) => {
                deferred.resolve(res.data);
            }, (err) => {
                deferred.reject(err);
            });

        return deferred.promise;
    };

    plFileAmazon.getKey = (keyOriginal, keyBatchPrefix, index, fileName) => {
        const keyIndex = keyOriginal.lastIndexOf('/');
        const keyStart = keyOriginal.slice(0, keyIndex);
        const keyEnd = keyOriginal.slice(keyIndex, keyOriginal.length);
        let key = `${keyStart}/${keyBatchPrefix}${index}${keyEnd}`;
        // The AWS call returns a 204 no content but we need to send back the
        // evaluated key to our backend. So we'll replace it here.
        key = key.replace('${filename}', fileName);
        return key;
    };

    plFileAmazon.uploadBulk = (files, url, fieldData, keyBatchPrefix = '', filenames = []) => {
        const deferred = $q.defer();

        const resFiles = [];
        const promises = [];
        const deferreds = [];
        const keyOriginal = fieldData.key;
        files.forEach((file, index) => {
            deferreds[index] = $q.defer();
            promises.push(deferreds[index].promise);

            let filename = file.name;
            if(filenames[index] !== undefined){
                filename = filenames[index];
            }
            // Can NOT update file name, so update key to ensure unique.
            fieldData.key = plFileAmazon.getKey(keyOriginal, keyBatchPrefix, index, filename);

            plFileAmazon.uploadOne(file, url, fieldData)
                .then((resFile) => {
                    resFiles.push(resFile);
                    deferreds[index].resolve();
                }, (err) => {
                    deferreds[index].reject();
                });
        });
        $q.all(promises)
            .then(() => {
                deferred.resolve(resFiles);
            }, (err) => {
                deferred.reject(err);
            });

        return deferred.promise;
    };

    plFileAmazon.uploadOne = (file, url, fieldData) => {
        const deferred = $q.defer();

        const fields = Object.assign({}, {
            acl: 'private',
        }, fieldData);

        const formData = new FormData();
        for (let key in fields) {
            formData.append(key, fields[key]);
        }
        formData.append('file', file);

        const request = new XMLHttpRequest();
        request.open('POST', url);
        request.onreadystatechange = () => {
            if (request.readyState === XMLHttpRequest.DONE
             && (request.status === 200 || request.status === 204)) {
                deferred.resolve({ key: fields.key });
            }
        };
        request.send(formData);

        return deferred.promise;
    };

    return plFileAmazon;
}
plFileAmazonFactory.$inject = ['$q', 'plHttp'];
module.exports = plFileAmazonFactory;
