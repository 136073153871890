class ActivitiesController {
    constructor($rootScope, $state, $injector, CurrentUser, ActivityTagModel, Room) {
        this._$state = $state;

        if (!Room) {
            throw 'No Room could be found. Cannot create activitiesQueue';
        }
        this.showQueue = true;
        // this.queue = $injector.get('activitiesQueue');
        this.tags = ActivityTagModel;
    }

    hasListType(listType) {
        return this._$state.params.listType === listType;
    }

    hasSection(section) {
        return this._$state.params.section === section;
    }

    hasType(type) {
        return this._$state.params.type === type;
    }

    // activityIsQueued(activity) {
    //     return this.queue.contains(activity);
    // }

    // toggleQueue(activity) {
    //     if (!this.activityIsQueued(activity)) {
    //         this.addToQueue(activity);
    //     } else {
    //         this.removeFromQueue(activity);
    //     }
    // }

    // addToQueue(activity) {
    //     this.queue.add(activity, this.queue.activities.length);
    // }
    //
    // removeFromQueue(activity) {
    //     var fbActivity = this.queue.activities.find((item) => {
    //         return item.id == activity.id && this.queue.queues.list[this.queue.index] === item.queue;
    //     });
    //
    //     if (fbActivity) {
    //         this.queue.remove(fbActivity.$id);
    //     }
    // }
}

ActivitiesController.$inject = ['$rootScope', '$state', '$injector', 'CurrentUser', 'ActivityTagModel', 'Room'];
module.exports = ActivitiesController;