function plBrowserCheck() {
    return {
        restrict: 'E',
        scope: {
        },
        replace: true,
        templateUrl: '/core/pl-modules/src/plBrowser/plBrowserCheck/plBrowserCheck.tpl.html',
        link: ($scope) => {
        },
    };
}

plBrowserCheck.$inject = [];
module.exports = plBrowserCheck;
