"use strict";
Routes.$inject = ['$stateProvider'];

function Routes($stateProvider) {
    const assessmentType = 'assessment';
    const lessonType = 'lesson';

    $stateProvider
        .state('app.store.preview', {
            url: '/preview/:type/:slug',
            params: {
                prevState: null
            },
            views: {
                content: {
                    templateUrl: '/toychest/modules/store/preview/preview.tpl.html',
                    controller: 'ActivityPreviewController'
                }
            },
            onEnter: function(appModel){
                appModel.previewMode = true;
            },
            resolve: {
                appModel: 'appModel',
                // Current user is added to ensure that the activity is retreived using the
                // correct credentials. Some fields such as favorite rely on this.
                Activity: ['$stateParams', 'activityResolver', 'assessmentResolver', 'lessonResolver',
                    function ($stateParams, activityResolver, assessmentResolver, lessonResolver) {
                          if ($stateParams.type === assessmentType) {
                              return assessmentResolver.resolve($stateParams);
                          } else if ($stateParams.type === lessonType) {
                              return lessonResolver.resolve($stateParams);
                          }
                          return activityResolver.resolve($stateParams);
                    }
                ],
                PreviousState: ['$stateParams', function($stateParams) {
                    return $stateParams.prevState;
                }]
            }
        });
}
module.exports = Routes;