function plInputTextDirective(plInputService) {
    return {
        restrict: 'E',
        require: '?^form',
        scope: {
            model: '=',
            type: '@?',
            change: '&?',
            focus: '&?',
            blur: '&?',
            disabled: '=?',
            required: '=?',
            minlength: '=?',
            maxlength: '=?',
            max: '@?',
            min: '@?',
            pattern: '=?',
            placeholder: '@?',
            clearButton: '@?',
            name: '@?',
            label: '@?',
            icon: '@?',
            autocomplete: '@?',
        },
        templateUrl: '/core/pl-inputs/src/plInputText/plInputText.tpl.html',
        link: ($scope, $element, $attrs, formCtrl) => {
            const inputElement = $element.find('input');
            plInputService.createNameIfNone($scope);
            plInputService.setupOnChange($scope, $attrs);
            plInputService.setupFocusAndBlur($scope, $attrs);
            plInputService.addInputToFormForValidation($scope, $element, formCtrl);

            // avoid naming collision with type attribute
            $scope.props = {};
            $scope.props.type = $scope.type || 'text';

            $scope.showClearButton = function showClearButton() {
                return $scope.model && $scope.model.length;
            };

            $scope.clearInput = function clearInput() {
                $scope.model = '';
            };

            $scope.getStateClasses = function getStateClasses() {
                return {
                    focused: $scope.focused,
                    disabled: $scope.disabled,
                    invalid: plInputService.isTextStyleInputInvalid($scope, formCtrl),
                };
            };
            if ($scope.autocomplete) {
                inputElement.attr('autocomplete', $scope.autocomplete);
            }
            if ($scope.maxlength) {
                inputElement.attr('maxlength', $scope.maxlength);
            }

            // Min & max on numbers not working..
            if (formCtrl && $scope.type === 'number' && ($scope.min || $scope.max)) {
                inputElement.on('change', (event) => {
                    const min = $scope.min ? parseFloat($scope.min) : null;
                    const max = $scope.max ? parseFloat($scope.max) : null;
                    const value = parseFloat($scope.model);
                    if ($scope.min !== undefined) {
                        if (value < min) {
                            formCtrl[$scope.name].$setValidity('min', false);
                        } else {
                            formCtrl[$scope.name].$setValidity('min', true);
                        }
                    }
                    if ($scope.max !== undefined) {
                        if (value > max) {
                            formCtrl[$scope.name].$setValidity('max', false);
                        } else {
                            formCtrl[$scope.name].$setValidity('max', true);
                        }
                    }
                });
            }
        },
    };
}

plInputTextDirective.$inject = ['plInputService'];
module.exports = plInputTextDirective;
