function PreloadImageService($q) {
    this.preloadImage = function(src) {
        var defer = $q.defer();
        var image = new Image();
        image.onload = defer.resolve.bind(defer, src);
        image.onerror = defer.reject;
        image.src = src;
        return defer.promise;
    };
}

PreloadImageService.$inject = ['$q'];
module.exports = PreloadImageService;