const EXCLUDED_PROPERTIES = ['name', 'port'];

class ApplicationConfigController {
    constructor(applicationDefaults, applications, $location) {
        this.defaults = applicationDefaults;
        this.applications = applications;
        this._$location = $location;
        this.EXCLUDED_PROPERTIES = EXCLUDED_PROPERTIES;
    }

    updateProperty(app, prop, value) {
        this.applications.setProperty(app, prop, value);
    }

    getDomain() {
        return `${this._$location.protocol()}://${this._$location.host()}:${this._$location.port()}`;
    }

    returnToApp() {
        this._$location.path(this.getDomain());
    }

    resetToDefaults() {
        this.applications.resetToDefaults();
    }
}

ApplicationConfigController.$inject = ['applicationDefaults', 'applications', '$location'];
export default ApplicationConfigController;
