function WorkspaceDirective(
    firebaseAppModel,
    activityService,
    assessmentService,
    lessonService,
    drfActivityModel,
    drfAssessmentModel,
    drfGameModel,
    currentUserModel,
    appModel
) {
    return {
        restrict: 'E',
        scope: {
            storeActivity: '='
        },
        replace: true,
        templateUrl:'/core/toys/app/src/toys/common/directives/workspace/workspace.tpl.html',
        link:function(scope, element, attr) {
            scope.appModel = appModel;
            const typeActivity = 'activity';
            const typeAssessment = 'assessment';
            const typeGame = 'game';
            const typeLesson = 'lesson';
            const types = [typeActivity, typeAssessment];
            const typeFieldName = 'type';
            const typeFieldNameStore = 'activity_type';
            const activityAssessmentFieldName = 'activity_type';
            const activityAssessmentFieldNameStore = 'resource_type';
            scope.showActivity = false;
            scope.currentService = null;

            var setSharedCursor = function(status) {
                if (_.isUndefined(attr.showSharedCursor)) {
                    return;
                }
                scope.$parent.$eval(attr.showSharedCursor, {
                    $showCursor: status
                });
            };

            var checkActivity = function() {
                return scope.storeActivity || firebaseAppModel.app.activeActivity;
            };

            function recordActivityUse(activityId) {
                if (currentUserModel.user.isClinician()) {
                    new drfActivityModel.Model().$setKey(activityId).$use();
                }
            }

            function recordAssessmentUse(activityId) {
                if (currentUserModel.user.isClinician()) {
                    new drfAssessmentModel.Model().$setKey(activityId).$use();
                }
            }

            function recordGameUse(activityId) {
                if (currentUserModel.user.isClinician()) {
                    new drfGameModel.Model().$setKey(activityId).$use();
                }
            }

            scope.$watch(scope.shouldShowWorkspace, (value) => {
               scope.showActivity = value;
            });

            var removeWatcher = scope.$watch(checkActivity, function(activity) {
                scope.showActivity = false;
                if(!activity) {
                    scope.currentService && scope.currentService.reset();
                    setSharedCursor(true);
                    return;
                }
                let typeField = scope.storeActivity ? typeFieldNameStore : typeFieldName;
                let isActivity = (activity[activityAssessmentFieldName] === typeActivity ||
                    activity[activityAssessmentFieldNameStore] === typeActivity);
                let isGame = (activity[activityAssessmentFieldName] === typeGame ||
                    activity[activityAssessmentFieldNameStore] === typeGame);
                let isLesson = (activity[activityAssessmentFieldName] === typeLesson ||
                    activity[activityAssessmentFieldNameStore] === typeLesson);
                let serviceData = scope.storeActivity ? angular.copy(activity) : activity;
                let shouldShareCursor = typeActivity === serviceData.activity_type
                                        || typeGame === serviceData.activity_type
                                        || false === scope.shouldShowWorkspace();

                setSharedCursor(shouldShareCursor);
                if (-1 !== types.indexOf(serviceData.activity_type)) {
                    serviceData.resource_type = serviceData.activity_type;
                }
                scope.activityType = _.propertyOf(activity)(typeField);

                if(!isGame) {
                    scope.currentService = isActivity ? activityService : ( isLesson ? lessonService : assessmentService);
                    scope.currentService.startup(serviceData).then(function () {
                        scope.showActivity = true;
                    });
                } else {
                    scope.showActivity = true;
                }

                if(isActivity) {
                    recordActivityUse(activity.id);
                } else if(isGame) {
                    recordGameUse(activity.id);
                } else {
                    recordAssessmentUse(activity.id);
                }

                if(scope.storeActivity) {
                    removeWatcher();
                }
            });

            scope.$on('$destroy', function() {
                setSharedCursor(true);
                if(!scope.currentService) {
                    return;
                }
                scope.currentService.reset();
                scope.currentService = null;
            });

            scope.isTeamWriteActive = function() {
                return firebaseAppModel.app.teamWriteActive;
            };

            scope.isWhiteboardActive = function() {
                return firebaseAppModel.app.whiteboardActive;
            };

            scope.shouldShowWorkspace = function() {
                return firebaseAppModel.app.shouldShowWorkspace || scope.appModel.previewMode;
            };
        }
    }
}
WorkspaceDirective.$inject = [
    'firebaseAppModel',
    'activityService',
    'assessmentService',
    'lessonService',
    'drfActivityModel',
    'drfAssessmentModel',
    'drfGameModel',
    'currentUserModel',
    'appModel'
];
module.exports = WorkspaceDirective;