function MemoryActivityDrawerDirective() {
    return {
        restrict: 'E',
        controller:'MemoryDrawerController',
        scope: {},
        templateUrl:'/core/toys/app/src/toys/modules/activity/memory/memoryDrawer.tpl.html'
    };
}

module.exports = MemoryActivityDrawerDirective;
