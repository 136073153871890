var WizardStateResolver = function($q) {
    this.resolve = function(params) {
        this.clearPanelsCallback();
        return $q.when();
    };
    this.clearPanelsCallback = function () {};
    return this;
};
WizardStateResolver.$inject = ['$q'];
module.exports = WizardStateResolver ;
