/**
 * Central location for making calls to server side logging services and analytics (Raven/Sentry, Heap, JIRA, etc...)
 */

class RemoteLoggingService {
    constructor($log, currentUserModel, roomnameModel) {
        this._currentUserModel = currentUserModel;
        this._roomnameModel = roomnameModel;
        this.log = $log;
    }

    setUser() {
        const name = this._currentUserModel.user ? this._currentUserModel.user.getName() : '';
        Raven.setUser({
            username: name,
        });
    }

    logToSentry(message, data) {
        this.setUser();
        const options = {};
        if (this._roomnameModel.value && this._roomnameModel.value.id) {
            options.tags = {
                roomID: this._roomnameModel.value.id,
            };
        }
        if (data) {
            options.extra = data;
        }
        Raven.captureMessage(message, options);
    }

    logTokboxErrorToSentry(context, error, session) {
        // only actually log a fraction of these calls
        if (Math.random() > 0.01) {
            this.log.debug('Rate limiting sentry log...');
            return;
        }

        const message = context + ': ' + error.code + ' - ' + error.message;
        this.setUser();
        const tokboxSessionId = session ? session.sessionId : 'unknown';
        const tokboxConnectionId = (session && session.connection) ? session.connection.connectionId : 'unknown';
        const options = {
            tags: {
                tokboxSessionId,
                tokboxConnectionId,
            },
        };
        if (this._roomnameModel.value && this._roomnameModel.value.id) {
            options.tags.roomID = this._roomnameModel.value.id;
        }
        Raven.captureMessage(message, options);
    }

}

RemoteLoggingService.$inject = ['$log', 'currentUserModel', 'roomnameModel'];
export default RemoteLoggingService;
