/**
 * Equivalent to ActivityService but using AssessmentModel instead of ActivityModel
 */

var AssessmentService = function($log, $rootScope, $location, AssessmentModel, guidService) {

    this.startup = (activity) => {
        if(activity.id === AssessmentModel.activity.activityId) {
            return AssessmentModel.foundationLoaded;
        }
        this.reset();
        $log.debug("[AssessmentService] startup");
        AssessmentModel.setActivityId(activity.id);
        AssessmentModel.setConfigId(activity.slug);
        AssessmentModel.setType(activity.resource_type);
        var shareId = activity.session_id;
        if (shareId) {
            //enable firebase
            AssessmentModel.setSessionId(shareId);
            AssessmentModel.setShared(true);
            $log.debug("[AssessmentService] sharing enabled.");
        } else {
            //skip firebase
            AssessmentModel.setSessionId(guidService.generateUUID());
            AssessmentModel.setShared(false);
            $log.debug("[AssessmentService] sharing disabled.");
        }
        AssessmentModel.initialize();
        return AssessmentModel.foundationLoaded;
    };

    this.reset = function() {
        AssessmentModel.reset();
    };
};

AssessmentService.$inject = ['$log', '$rootScope', '$location', 'AssessmentModel', 'guidService'];
module.exports = AssessmentService;
