var FormErrorDirective = function() {
    return {
        restrict: 'E',
        transclude: true,
        scope: {
            formError: '=',
            updateModel: '='
        },
        templateUrl: '/toychest/common/directives/Flashcards/FormErrorDirective.tpl.html',
        link: function($scope, $element, $attr) {
            let targetElement = $element.find('.error-target');

            $scope.$watch('formError', function(formError) {
                (formError) ? enable() : disable();
            });

            targetElement.on('keyup', () => {
                $scope.updateModel = targetElement.val();
                $scope.$apply();
            });

            let changeHandler = () => {
                targetElement.removeClass('form-error');
                $element.addClass('error-handled');
            };

            function enable() {
                $scope.errorMessage = $scope.formError[0];
                targetElement.addClass('form-error');
                $element.removeClass('error-handled');
                targetElement.on('keydown', changeHandler);
            }

            function disable() {
                targetElement.removeClass('form-error');
                targetElement.off('keydown', changeHandler);
            }
        }
    };
};

FormErrorDirective.$inject = [];
module.exports = FormErrorDirective;