import ApplicationService from './src/services/ApplicationService';
import RemoteLoggingService from './src/services/RemoteLoggingService';
import GiphyService from './src/services/GiphyService';

module.exports = angular.module('pl-core', [])

	// models
	// DRF models
    .service('applications', ApplicationService)
    .service('assetModel', require('./src/models/DRF/AssetModel'))
    .service('imageAssetModel', require('./src/models/DRF/ImageAssetModel'))
    .service('drfActivityModel', require('./src/models/DRF/DRFActivityModel'))
    .service('drfGameModel', require('./src/models/DRF/DRFGameModel'))
    .service('drfModel', require('./src/models/DRF/DRFModel'))
    .service('drfRoomModel', require('./src/models/DRF/DRFRoomModel'))
    .service('drfAssessmentModel', require('./src/models/DRF/DRFAssessmentModel'))
    .service('drfLessonModel', require('./src/models/DRF/DRFLessonModel'))
    .service('drfLessonRightsModel', require('./src/models/DRF/DRFLessonRightsModel'))
    .service('drfAssessmentRightsModel', require('./src/models/DRF/DRFAssessmentRightsModel'))
    .service('drfSearchQueryModel', require('./src/models/DRF/DRFSearchQueryModel'))
    .service('tagModel', require('./src/models/DRF/TagModel'))
    .service('drfStoreHeroModel', require('./src/models/DRF/DRFStoreHeroModel'))
    .service('flashcardModel', require('./src/models/DRF/FlashcardModel'))

// firebase models
.service('activitiesQueue', require('./src/models/firebase/ActivitiesQueue'))
    .service('firebaseAppModel', require('./src/models/firebase/FirebaseAppModel'))
    .service('firebaseModel', require('./src/models/firebase/FirebaseModel'))

// tasty models
.service('tastyModel', require('./src/models/tasty/TastyModel'))
    .service('studentModel', require('./src/models/tasty/StudentModel'))
    .service('metricModel', require('./src/models/tasty/MetricModel'))
    .service('recordModel', require('./src/models/tasty/RecordModel'))
    .service('occurrenceModel', require('./src/models/tasty/OccurrenceModel'))
    .service('noteModel', require('./src/models/tasty/NoteModel'))
    .service('dataPointModel', require('./src/models/tasty/DataPointModel'))


// Users
.service('authenticationService', require('./src/models/users/AuthenticationService'))
    .service('inactivityLogoutService', require('./src/models/users/InactivityLogoutService'))
    .service('userFactory', require('./src/models/users/UserFactory'))

// angular models
.service('currentUserModel', require('./src/models/CurrentUserModel'))
    .service('pageTitleModel', require('./src/models/PageTitleModel'))
    .service('roomnameModel', require('./src/models/RoomnameModel'))
    .service('uploaderModel', require('./src/models/UploaderModel'))

// services
    .factory('authInterceptor', require('./src/services/AuthInterceptor'))
    .service('dispatcherService', require('./src/services/DispatcherService'))
    .service('remoteDispatcherService', require('./src/services/RemoteDispatcherService'))
    .service('guidService', require('./src/services/GuidService'))
    .service('closureService', require('./src/services/ClosureService'))
    .service('liveagentService', require('./src/services/LiveagentService'))
    .service('notificationService', require('./src/services/NotificationService'))
    .service('sortedCollectionService', require('./src/services/SortedCollectionService'))
    .service('thumbnailCreator', require('./src/services/ThumbnailCreator'))
    .service('imageStorage', require('./src/services/ImageStorage'))
    .service('RoomClickService', require('./src/services/RoomClickService'))
    .service('remoteLogging', RemoteLoggingService)
    .service('giphy', GiphyService)
    .service('rightsMgmtService', require('./src/services/RightsMgmtService'))

// directives
.directive('activityTypeIcon', require('./src/directives/activityTypeIcon/ActivityTypeIcon'))
    .directive('dotLoader', require('./src/directives/dot-loader/DotLoaderDirective'))
    .directive('notify', require('./src/directives/notify/NotifyDirective'))
    .directive('permissions', require('./src/directives/permissions/PermissionsDirective'))
    .directive('plBugReport', require('./src/directives/plBugReport/plBugReportDirective'))
    .directive('plIdeaReport', require('./src/directives/plIdeaReport/plIdeaReportDirective'))
    .directive('plDropdown', require('./src/directives/pl-dropdown/PLDropdownDirective'))
    .directive('plWindowWarning', require('./src/directives/plWindowWarning/plWindowWarningDirective'))
    .directive('slider', require('./src/directives/slider/SliderDirective'))
    .directive('takeover', require('./src/directives/takeover/TakeoverDirective'))
    .directive('radioInput', require('./src/directives/radioInput/RadioInputDirective'))
    .directive('spinner', require('./src/directives/spinner/SpinnerDirective'))
    .directive('sortable', require('./src/directives/sortableDirective/sortableDirective'))
    .directive('sortableItem', require('./src/directives/sortableDirective/sortableItemDirective'))

// filters
.filter('timeFormatter', require('./src/filters/TimeFormatter'))
    .filter('capitalize', require('./src/filters/Capitalize'))
    .filter('humanize', require('./src/filters/Humanize'))
    .filter('urlfilter', require('./src/filters/UrlFilter'))


// resolvers
.factory('currentUserResolver', require('./src/resolvers/CurrentUserResolver'))
    .factory('authenticatedUserResolver', require('./src/resolvers/AuthenticatedUserResolver'))
    .factory('roomResolver', require('./src/resolvers/RoomResolver'))

// mocks
.service('jsChannelMock', require('./src/mocks/jsChannelMock'));
