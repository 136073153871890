function plNavStepper($state) {
    return {
        restrict: 'E',
        templateUrl: '/core/pl-navs/src/plNavStepper/plNavStepper.tpl.html',
        scope: {
            stages: '=',
            currentStage: '=',
            selectable: '=',
        },
        link: $scope => {
            function isActive(stage) {
                const active = stage.sref === $state.current.name;
                if (active) {
                    stage.visited = true;
                }
                return active;
            }
            $scope.getStageButtonClasses = function getStageButtonClasses(stage) {
                return {
                    active: isActive(stage),
                    visited: stage.visited,
                };
            };
            $scope.onClickStageButton = function onClickStageButton(stage) {
                if (!stage.visited && !$scope.selectable) { return false; }
                $state.go(stage.sref);
            };
        },
    };
}

plNavStepper.$inject = ['$state'];
export default plNavStepper;
