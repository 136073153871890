function PlButton() {
    return {
        restrict: 'C',
        link: ($scope, $element) => {
            const $burst = $('<div>', { class: 'burst' });
            $element.append($burst);
            $element.$burst = $burst;
            const burstWidth = $element.$burst.width();
            const burstHeight = $element.$burst.height();

            function getBurstPosition(mouseEvent) {
                const buttonOffset = $element.offset();
                return {
                    top: mouseEvent.pageY - buttonOffset.top - burstHeight / 2,
                    left: mouseEvent.pageX - buttonOffset.left - burstWidth / 2,
                };
            }

            $element.on('mousedown ontouchstart', mouseEvent => {
                $burst
                    .css(getBurstPosition(mouseEvent))
                    .addClass('animating')
                    .addClass('animate');
                window.setTimeout(() => {
                    $burst
                        .removeClass('animating')
                        .removeClass('animate');
                }, 300);
            });
        },
    };
}

module.exports = PlButton;
