function plLocationFactory(plHttp, $q, plLodash) {
    const plLocation = {};
    let _locations = [];
    let _locationsLoaded = false;
    let _lastGetParams = null;

    plLocation.get = (data1 = {}) => {
        const deferred = $q.defer();

        const defaultParams = {
            limit: 1000,
            is_active: true,
        };
        if (!_lastGetParams) {
            _lastGetParams = defaultParams;
        }
        const data = Object.assign({}, defaultParams, data1);
        const sameParams = plLodash.equals(data, _lastGetParams, ['provider']);
        _lastGetParams = data;

        if (_locationsLoaded && sameParams) {
            deferred.resolve(_locations);
        } else {
            plHttp.get('locations', data)
                .then((res) => {
                    if (sameParams) {
                        _locations = res.data.results;
                        _locationsLoaded = true;
                    }
                    deferred.resolve(res.data.results);
                }, (err) => {
                    deferred.reject(err);
                });
        }
        return deferred.promise;
    };

    plLocation.formSelectOpts = (locations1 = false) => {
        const locations = locations1 || _locations;
        return locations.map((location) => {
            return {
                value: location.uuid,
                label: location.name,
            };
        });
    };

    plLocation.getInfo = (uuid) => {
        if (!uuid) {
            return null;
        }
        const index = plLodash.findIndex(_locations, 'uuid', uuid);
        return (index > -1) ? _locations[index] : null;
    };

    return plLocation;
}
plLocationFactory.$inject = ['plHttp', '$q', 'plLodash'];
module.exports = plLocationFactory;
