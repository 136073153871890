var Routes = function($stateProvider) {
    $stateProvider
        .state('app.store.games', {
            url: '/games',
            abstract: true,
            views: {
                content: {
                    templateUrl: '/toychest/modules/store/games/games.tpl.html',
                    controller: 'GamesController as gamesCtrl'
                }
            }
        })
        .state('app.store.games.list', {
            url: '/{listType:|all|recent|favorites}?q&type&tag&sort',
            views: {
                main: {
                    templateUrl: '/toychest/modules/store/activities/activities.list.tpl.html',
                    controller: 'GamesListController as listCtrl'
                }
            }
        })
        .state('app.store.games.detail', {
            url: '/detail/:slug',
            params: {
                prevState: null
            },
            views: {
                main: {
                    templateUrl: '/toychest/modules/store/games/games.detail.tpl.html',
                    controller: 'GameDetailController as detailCtrl'
                }
            },
            resolve: {
                Game: ['$stateParams', 'gameResolver',
                    function($stateParams, gameResolver) {
                        return gameResolver.resolve($stateParams);
                    }
                ],
                PreviousState: [
                    '$state',
                    '$stateParams',
                    function($state, $stateParams) {
                        var currentStateData = $stateParams.prevState || {
                                name: $state.current.name,
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                        return currentStateData;
                    }
                ]
            }
        });
};

Routes.$inject = ['$stateProvider'];
module.exports = Routes;