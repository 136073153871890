import plCheckboxGroup from './src/plCheckboxGroup/plCheckboxGroup';
import plRadioGroup from './src/plRadioGroup/plRadioGroup';
import plDateTimeRange from './src/plDateTimeRange/plDateTimeRangeDirective';
import plDateTimeRangeService from './src/plDateTimeRange/plDateTimeRangeFactory';
import plInputCheckbox from './src/plInputCheckbox/plInputCheckboxDirective';
import plInputDateTime from './src/plInputDateTime/plInputDateTimeDirective';
import plInputDropdown from './src/plInputDropdown/plInputDropdownDirective';
import plInputRadio from './src/plInputRadio/plInputRadioDirective';
import plInputSearch from './src/plInputSearch/plInputSearchDirective';
import plInputSearchBelow from './src/plInputSearch/plInputSearchBelow/plInputSearchBelowDirective';
import plInputSearchService from './src/plInputSearch/plInputSearchFactory';
import plInputSelect from './src/plInputSelect/plInputSelectDirective';
import plInputSelectApi from './src/plInputSelectApi/plInputSelectApiDirective';
import plInputService from './src/plInputService';
import plInputSwitch from './src/plInputSwitch/plInputSwitchDirective';
import plInputText from './src/plInputText/plInputTextDirective';
import plInputTextarea from './src/plInputTextarea/plInputTextareaDirective';
import plInputTime from './src/plInputTime/plInputTimeDirective';
import plInputUpload from './src/plInputUpload/plInputUploadDirective';
import plInputUploadService from './src/plInputUpload/plInputUploadService';
require('ng-file-upload');

export default angular.module('pl-inputs', ['ngFileUpload'])
    .service('plInputService', plInputService)
    .service('plDateTimeRange', plDateTimeRangeService)
    .service('plInputUploadService', plInputUploadService)
    .service('plInputSearchService', plInputSearchService)
    .directive('plCheckboxGroup', plCheckboxGroup)
    .directive('plRadioGroup', plRadioGroup)
    .directive('plDateTimeRangeDirective', plDateTimeRange)
    .directive('plInputCheckbox', plInputCheckbox)
    .directive('plInputDateTime', plInputDateTime)
    .directive('plInputDropdown', plInputDropdown)
    .directive('plInputRadio', plInputRadio)
    .directive('plInputSearch', plInputSearch)
    .directive('plInputSearchBelow', plInputSearchBelow)
    .directive('plInputSelect', plInputSelect)
    .directive('plInputSelectApi', plInputSelectApi)
    .directive('plInputSwitch', plInputSwitch)
    .directive('plInputText', plInputText)
    .directive('plInputTextarea', plInputTextarea)
    .directive('plInputTime', plInputTime)
    .directive('plInputUpload', plInputUpload);
