function plServiceTypesFactory(plHttp, $q, plLodash) {
    const plServiceTypes = {};
    let _serviceTypes = [];

    plServiceTypes.get = () => {
        const deferred = $q.defer();

        if (_serviceTypes && _serviceTypes.length) {
            deferred.resolve(_serviceTypes);
        } else {
            plHttp.get('serviceTypes', {})
                .then((res) => {
                    _serviceTypes = res.data.results;
                    deferred.resolve(res.data.results);
                }, (err) => {
                    deferred.reject(err);
                });
        }
        return deferred.promise;
    };

    plServiceTypes.getInfo = (uuid) => {
        const index = plLodash.findIndex(_serviceTypes, 'uuid', uuid);
        return (index > -1) ? _serviceTypes[index] : null;
    };

    plServiceTypes.formSelectOpts = (opts) => {
        return opts.map((opt) => {
            return { value: opt.uuid, label: opt.long_name };
        });
    };

    return plServiceTypes;
}
plServiceTypesFactory.$inject = ['plHttp', '$q', 'plLodash'];
module.exports = plServiceTypesFactory;
