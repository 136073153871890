/**
 * Pdf rendering directive (works with pdf.js)
 *
 * @param $log
 * @constructor
 */
var PdfToolbarDirective = function($log, $compile, $sce, firebaseModel, activityModel, dispatcher) {
    return {
        restrict    : 'E',
        replace: true,
        templateNamespace: 'svg',
        templateUrl : '/toys/modules/activity/components/pdfToolbar/pdfToolbar.tpl.html',
        controller  : 'PdfToolbarController',

        link        : function($scope, element, attrs, controller) {

            var loadState = function() {
                if (activityModel.share) {
                    $scope.ref = firebaseModel.getFirebaseRef('activities/sessions/' + activityModel.getSessionId() + "/" + $scope.data.id);
                    $scope.ref.on("value", _.bind(this.handleActivityData, this), _.bind(this.handleActivityError, this));
                } else {
                    //use the defaults
                    this.setDefaultState();
                }
            };

            this.setDefaultState = function() {
                $scope.componentId = '' + $scope.data.id;
                $scope.linkedPdf = '' + $scope.data.shared.linkedPdf;
                dispatcher.dispatch('ComponentEvent-FirebaseActivityChange', $scope.componentId, {data: $scope.data});
            };


            /**
             * Handlers
             */
            this.handleActivityData = function(snapshot) {
                $log.debug("[PdfToolbarDirective] activity data loaded:" + snapshot.val());
                var sharedData = snapshot.val();
                if (sharedData == null) {
                    sharedData = {};
                    $scope.ref.set($scope.data.shared);
                }
                //existing shared data has priority over the defaults
                _.defaults(sharedData, $scope.data.shared);
                $scope.componentId = '' + $scope.data.id;
                $scope.linkedPdf = '' + sharedData.linkedPdf;

                dispatcher.dispatch('ComponentEvent-FirebaseActivityChange', $scope.componentId, {data: sharedData});

            };

            this.handleActivityError = function(error) {
                $log.debug("[PdfToolbarDirective] activity ref load error:" + error.code);
            };
            /***************************
             *  control handlers
             *************************/
            $scope.loading = true;
            $scope.numPages = 0;

            $scope.prevPage = function() {
                if ($scope.page > 1) {
                    $scope.page = $scope.page - 1;
                    setLoadingState(true);
                    dispatcher.dispatch("pdfToolbar-PageChangeEvent", '' + $scope.linkedPdf, {'page': $scope.page});
                }
            };

            $scope.nextPage = function() {
                if ($scope.page < $scope.numPages) {
                    $scope.page = $scope.page + 1;
                    setLoadingState(true);
                    dispatcher.dispatch("pdfToolbar-PageChangeEvent", '' + $scope.linkedPdf, {'page': $scope.page});
                }
            };

            $scope.zoomIn = function() {
                $scope.scale = $scope.scale * 1.1;
                setLoadingState(true);
                dispatcher.dispatch("pdfToolbar-ScaleChangeEvent", '' + $scope.linkedPdf, {'scale': $scope.scale});
            };

            $scope.zoomOut = function(){
                $scope.scale = $scope.scale * 0.9;
                setLoadingState(true);
                dispatcher.dispatch("pdfToolbar-ScaleChangeEvent", '' + $scope.linkedPdf, {'scale': $scope.scale});
            };

            var setupHandlers = function() {
                $(element).find('.prevPage').on('click', $scope.prevPage);
                $(element).find('.nextPage').on('click', $scope.nextPage);
                $(element).find('.zoomIn').on('click', $scope.zoomIn);
                $(element).find('.zoomOut').on('click', $scope.zoomOut);

                dispatcher.addListener('ComponentEvent-FirebaseActivityChange', '' +  $scope.data.shared.linkedPdf, handlePdfFirebaseActivityChange, this);
                dispatcher.addListener('PdfViewer-DocumentLoaded', '' +  $scope.data.shared.linkedPdf, handlePdfDocumentLoaded, this);

            };

            var setLoadingState = function(loading) {
                if (loading) {
                    $scope.loading = true;
                } else {
                    $scope.loading = false;
                }
            };

            var handlePdfDocumentLoaded = function(event, data) {
                $log.debug("[PdfToolbarDirective] pdf doc loaded");
                var pdfData = data.data; //TODO clean up this pathing
                $scope.numPages = pdfData.numPages;
                setLoadingState(false);
            };

            /***************************
             *  initialize
             *************************/
            var handlePdfFirebaseActivityChange = function(event, data) {
                $log.debug("[PdfToolbarDirective] pdf component initialized");
                var pdfData = data.data; //TODO clean up this pathing
                $scope.page = pdfData.page;
                $scope.scale = pdfData.scale;
            };

            var initialize = function() {
                var data = $scope.data;
                setupHandlers();
                loadState();

                PDFJS.disableWorker = false;
            };
            initialize();
        }
    }
};

PdfToolbarDirective.$inject = ['$log', '$compile', '$sce', 'firebaseModel', 'activityModel', 'dispatcher'];
module.exports = PdfToolbarDirective;
