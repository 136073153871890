import { sortBy } from 'lodash';

function plInputSelectDirective(plInputService, $timeout) {
    return {
        restrict: 'E',
        require: '?^form',
        scope: {
            model: '=',
            options: '=',
            focus: '&?',
            blur: '&?',
            change: '&?',
            mouseover: '=?',
            mouseleave: '=?',
            customSearch: '=',
            disabled: '=?',
            required: '=?',
            name: '@?',
            label: '@?',
            maxHeight: '=?',
            expandContainer: '=?',
            autoSelectIfOne: '@?',
            sortField: '@?',
            disableSearchFilter: '@?',
            searchText: '=?',
            loading: '=?',
            htmlLabels: '=?',
            isVirtualList: '=?',
        },
        replace: true,
        templateUrl: '/core/pl-inputs/src/plInputSelect/plInputSelect.tpl.html',
        link: ($scope, $element, $attrs, formCtrl) => {
            plInputService.createNameIfNone($scope);
            plInputService.setupOnChange($scope, $attrs);
            plInputService.setupFocusAndBlur($scope, $attrs);
            plInputService.addInputToFormForValidation($scope, $element, formCtrl);

            $scope.classes = {
                disabled: '',
            };
            $scope.showSearch = false;
            $scope.searchFilterDisabled = $scope.disableSearchFilter || false;
            $scope.loading = ($scope.loading !== undefined) ? $scope.loading : false;

            // select
            $scope.selectOption = function selectOption(option) {
                if (option.disabled) {
                    return;
                }
                $scope.model = option.value;
                // $scope.searchText = $scope.model;
                // Reset for next time.
                $scope.searchText = '';
                closeMenu();
            };

            if (!$scope.expandContainer) {
              $element.find('.options-container-copy').remove();
            }
            const searchInput = $element.find('.select-search-input');
            const optionsContainer = $element.find('.options-container');
            const options = $element.find('.options');
            const searchInputContainer = $element.find('.select-search');
            const loadingEle = $element.find('.loading');
            let searchHeight = (32 + 3);      //hardcoded to avoid timing / visible issues.
            let loadingHeight = (22);      //hardcoded to avoid timing / visible issues.

            function init() {
                // $scope.searchText = $scope.model;
                $scope.classes.disabled = $scope.disabled ? 'disabled' : '';
                if ($attrs.sortField && $scope.options && $scope.options.length) {
                    $scope.options = Object.assign($scope.options, sortBy($scope.options, $attrs.sortField));
                }
                if ($scope.autoSelectIfOne && $scope.options && $scope.options.length === 1) {
                    $scope.selectOption($scope.options[0]);
                }
            }
            init();

            // $element.find('select').on('mousedown', event => {
            $element.find('.select-button').on('mousedown', event => {
                event.preventDefault();
                if ($element.hasClass('ready-to-open')) {
                    closeMenu();
                } else {
                    openMenu();
                }
            });

            $scope.getCurrentLabel = function getCurrentLabel() {
                if ($scope.options && $scope.options.length) {
                    const activeOption = $scope
                        .options
                        .find(opt => opt.value === $scope.model);
                    if (activeOption) {
                        return activeOption.label;
                    }
                }
                return $attrs.placeholder || 'Pick Option';
            };

            $scope.filterOptions = (option) => {
                if ($scope.searchText && $scope.searchText.length) {
                  return option.label.toLowerCase().indexOf($scope.searchText.toLowerCase()) != -1;
                }
                return true;
            }

            // open / close
            function openMenu() {
                if (!$scope.disabled) {
                    $element.addClass('ready-to-open');
                    setOptionsHeight();
                    $(document).on('click', handleDocumentClick);
                }
                $scope.$apply(() => {
                    $scope.showSearch = true;
                });
                if (searchInput && searchInput.length) {
                  searchInput[0].focus();
                }
            }

            function closeMenu() {
                $(document).off('click', handleDocumentClick);
                optionsContainer.css('max-height', '0');
                options.css('max-height', '0');
                setTimeout(function() {
                    $element.removeClass('ready-to-open');
                }, 200);
                $scope.showSearch = false;
            }

            function resetOptionsHeight() {
                if ($element.hasClass('ready-to-open')) {
                    setOptionsHeight();
                }
            }

            function setOptionsHeight() {
                const optionsHeight = getOptionsMaxHeight();
                optionsContainer.css('max-height', `${(optionsHeight + searchHeight + loadingHeight)}px`);
                options.css('max-height', `${(optionsHeight)}px`);
            }

            function getOptionsMaxHeight() {
                const maxHeight = $attrs.maxHeight || 506;
                // Reset height first.
                options.css('max-height', '9999px');
                const optionsHeight = options.height();
                return optionsHeight < maxHeight ? optionsHeight : maxHeight;
            }

            function handleDocumentClick(e) {
                if ($element.is(e.target) === false && $element.has(e.target).length === 0) {
                    $scope.$apply(() => {
                        closeMenu();
                    });
                }
            }


            $scope.$watch('disabled', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal)) {
                    init();
                }
            });
            $scope.$watch('options', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal)) {
                    if ($scope.isVirtualList && oldVal && $scope.selectedModel) {
                      $scope.selectedOption = oldVal.find(item => {
                        return item.value === $scope.selectedModel;
                      });
                      $scope.options.push($scope.selectedOption);
                      $scope.model = $scope.selectedModel;
                    }
                    $timeout(() => {
                      init();
                      resetOptionsHeight();
                    }, 1);
                }
            });
            $scope.$watch('model', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal)) {
                    if (newVal) {
                      $scope.selectedModel = $scope.model;
                    }
                    init();
                }
            });
            $scope.$watch('searchText', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal)) {
                  if ($scope.customSearch) {
                    $scope.customSearch(newVal)
                  }
                }
            });
            $scope.$watch('loading', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal)) {
                    resetOptionsHeight();
                }
            });
        },
    };
}

plInputSelectDirective.$inject = ['plInputService', '$timeout'];
module.exports = plInputSelectDirective;
