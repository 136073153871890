function ActivitySearchDirective($timeout, drfSearchQueryModel, $state, activityTags, currentUserModel) {
    return {
        restrict: 'E',
        replace: false,
        templateUrl: '/toychest/modules/store/activities/activities.search.tpl.html',
        scope: {
            queryCallback: '&', // callback to filter model.
            filter: '=',
            type: '@', // 'activity' || 'assessment'
            sort: '=',
            searchPlaceholder: '@',
            orderBy: '@',    // Current (or default) sort
            hero: '='
        },
        link: function link(scope, element, attrs) {
            scope.showTags = scope.$eval(attrs.showTags);
            const recentSearchModel = new drfSearchQueryModel.Collection();
            const suggestLimit = 5;
            const inputEl = element.find('input');
            const isGame = scope.type === 'game';

            if (scope.type === 'assessment') {
                scope.drfType = 'Assessment';
            } else if (scope.type === 'lesson') {
                scope.type = 'lesson';
                scope.drfType = 'Lesson';
            } else {
                scope.type = 'activity';
                scope.drfType = 'Activity';
            }

            scope.suggestions = {
                index: -1,
            };

            function storeQuery() {
                if (scope.filter.q) {
                    const sqm = new drfSearchQueryModel.Model();
                    sqm.$save({
                        type: scope.drfType,
                        query: scope.filter.q,
                    });
                }
            }

            function filter() {
                storeQuery();
                scope.queryCallback();
            }

            function sort() {
                scope.queryCallback();
            }

            function resetSuggestIndex() {
                scope.suggestions.index = -1;
                scope.origionalQueryInput = undefined;
            }

            function resetSuggest() {
                scope.suggestions.data = undefined;
                resetSuggestIndex();
            }

            scope.suggest = () => {
                let suggest;
                let recent;
                let query;
                let label;

                if (!scope.queryInput || scope.queryInput.length === 0) {
                    suggest = false;
                    recent = true;
                    query = scope.filter.q;
                    label = 'Search suggestions';
                } else {
                    suggest = true;
                    recent = false;
                    query = scope.queryInput;
                    label = 'Suggested queries';
                }
                recentSearchModel.$clear();
                recentSearchModel.$limit(suggestLimit);
                recentSearchModel.$filter({
                    suggest,
                    recent,
                    type: scope.drfType,
                        q: query,
                });
                recentSearchModel.$fetch().then((data) => {
                    resetSuggest();
                    scope.suggestions.data = data;
                    scope.suggestions.label = label;
                    scope.suggestions.query = query;
                });
            };

            scope.onKeyDown = (e) => {
                if (e.keyCode === 38) {
                    scope.onUpArrow();
                    e.preventDefault();
                } else if (e.keyCode === 40) {
                    scope.onDownArrow();
                    e.preventDefault();
                } else {
                    resetSuggestIndex();
                }
            };

            function changeSuggestion() {
                if (scope.origionalQueryInput === undefined) {
                    scope.origionalQueryInput = scope.queryInput;
                }
                if (scope.suggestions.index < 0) {
                    scope.queryInput = scope.origionalQueryInput;
                } else {
                    scope.queryInput = scope.suggestions.data[scope.suggestions.index].query;
                }
            }

            scope.changeSuggestion = (index) => {
                // Note index = 0 is ok, so we cannot check use "!index" as a check
                if (index === undefined || index === null) {
                    return;
                }
                scope.suggestions.index = index;
                changeSuggestion();
            };

            scope.clearSuggestion = () => {
                scope.suggestions.index = -1;
                changeSuggestion();
            };

            scope.onDownArrow = () => {
                if (scope.suggestions.index >= scope.suggestions.data.length - 1) {
                    scope.suggestions.index = -1;
                } else {
                    scope.suggestions.index++;
                }
                changeSuggestion();
            };

            scope.onUpArrow = () => {
                if (scope.suggestions.index < 0) {
                    scope.suggestions.index = scope.suggestions.data.length - 1;
                } else {
                    scope.suggestions.index--;
                }
                changeSuggestion();
            };

            scope.addTag = (tag) => {
                scope.filter.addTag(tag);
                filter();
            };

            scope.removeTag = (tag) => {
                scope.filter.removeTag(tag);
                filter();
            };

            scope.removeActivityType = () => {
                scope.filter.removeType();
                filter();
            };

            scope.setType = (type) => {
                scope.filter.setType(type);
                filter();
            };

            scope.removeType = () => {
                scope.filter.removeType();
                filter();
            };

            scope.query = (input) => {
                inputEl.blur();
                scope.queryInput = input;
                scope.filter.q = input;
                filter();
            };

            scope.removeQuery = () => {
                scope.filter.q = '';
                scope.queryInput = '';
                filter();
            };

            scope.removeFacet = () => {
                scope.filter.facet = 'all';
                filter();
            };

            scope.tagName = (tagKey) => {
                return _.chain(scope.menuItems)
                    .map((category) => category.tags)
                    .flatten(true)
                    .filter((tag) => tag.tag === tagKey)
                    .first()
                    .thru((value) => _.isUndefined(value) ? {
                        name: tagKey
                    } : value)
                    .value()
                    .name;
            };

            let sortItems = [
                {
                    value: '',
                    label: 'Most Relevant',
                },
                {
                    value: '-date_created',
                    label: 'Newest to Oldest'
                },
                {
                    value: 'date_created',
                    label: 'Oldest to Newest'
                },
                // Backend bug prevents sort by name.
                // {
                //     value: 'name',
                //     label: 'Alphabetical A to Z'
                // },
                // {
                //     value: '-name',
                //     label: 'Alphabetical Z to A'
                // },
            ];
            
            if (!isGame) {
                sortItems.push(
                    {
                        value: '-rating',
                        label: 'Top Rated'
                    },
                );
            }

            scope.sortItems = sortItems;

            scope.setSort = () => {
                scope.sort.setSort(scope.querySort);
                sort();
            };

            function getSortValue() {
                let ii;
                for(ii =0; ii<scope.sortItems.length; ii++) {
                    if( scope.sortItems[ii].value === scope.orderBy ) {
                        return scope.sortItems[ii].value;
                    }
                }
                // Default to the first item if not found.
                return scope.sortItems[0].value;
            }

            scope.querySort = getSortValue();

            scope.queryInput = scope.filter.q;

            inputEl.attr('placeholder', scope.searchPlaceholder);

            scope.menuItems = {
                activities: {
                    addHandler: scope.setType,
                    tags: [
                        [
                            [{
                                tag: 'youtube',
                                name: 'Video'
                            }, {
                                tag: 'pdfviewer',
                                name: 'Document'
                            }, {
                                tag: 'flashcards',
                                name: 'Flashcards'
                            }, {
                                tag: 'memory',
                                name: 'Memory'
                            }],
                        ],
                    ],
                },
                grades: {
                    addHandler: scope.addTag,
                    tags: [],
                },
                subjects: {
                    addHandler: scope.addTag,
                    tags: [],
                },
            };

            /**
             * A object such as activityTags which contains tags in this form:
             * {
             *     slug: name
             *     slug2: name2
             * }
             * is transformed into this form:
             * [
             *  {
             *      tag: slug,
             *      name: name,
             *  },
             *  {
             *      tag: slug2,
             *      name: name2,
             *  }
             * ]
             * @param  {[type]} tagsObject [description]
             * @return {[type]}            [description]
             */
            function tagKeyValueObjectToTagNameObject(tagsObject) {
                const tags = [];
                _.each(tagsObject, (value, key) => {
                    tags.push({
                        tag: key,
                        name: value,
                    });
                });
                return tags;
            }

            function isLongTagName(tag) {
                return tag.name.length > 30;
            }

            function createRow(tags) {
                const row = [];
                let column = [];
                tags.forEach((tag, index) => {
                    if (index % 11 === 0) {
                        column = [];
                        row.push(column);
                    }
                    column.push(tag);
                });
                return row;
            }

            function createRows(tags) {
                const shortTagNames = [];
                const longTagNames = [];
                const rows = [];

                // Seperate into short and long tag names
                tags.forEach((tag) => {
                    if (isLongTagName(tag)) {
                        longTagNames.push(tag);
                    } else {
                        shortTagNames.push(tag);
                    }
                });

                rows.push(createRow(shortTagNames));
                rows.push(createRow(longTagNames));
                return rows;
            }

            function configureGradeMenuItems() {
                scope.menuItems.grades.tags = createRows(tagKeyValueObjectToTagNameObject(activityTags.grades.tags));
            }
            configureGradeMenuItems();

            function configureSubjectMenuItems() {
                let clinicianTypes = ['SLT', 'OT', 'SPED Instruction', 'BMH'];
                if (currentUserModel.user.serviceTypes) {
                    clinicianTypes = currentUserModel.user.serviceTypes.map( type => type.name );
                }
                let subjects = {};
                clinicianTypes.forEach(clinicianType => {
                    if (activityTags[clinicianType]) {
                        subjects = Object.assign(subjects, activityTags[clinicianType].tags);
                    }
                });
                let subjectTags = [];
                subjectTags = tagKeyValueObjectToTagNameObject(subjects);
                subjectTags = _.sortBy(subjectTags, 'name');
                scope.subjectTagCount = subjectTags.length;
                scope.menuItems.subjects.tags = createRows(subjectTags);
            }
            configureSubjectMenuItems();
        },
    };
}

ActivitySearchDirective.$inject = ['$timeout', 'drfSearchQueryModel', '$state', 'activityTags', 'currentUserModel'];
module.exports = ActivitySearchDirective;