function plDateTimeRangeFactory() {
    const dtRange = {};

    dtRange.formTimeOpts = (startString = '06:00', numHours = 24, minutesStep = 30,
     startDate = null, endDate = null) => {
        let timeFormat = 'HH:mm';
        let dateFormat = 'YYYY-MM-DD';
        let time = moment(startString, timeFormat);
        const opts = [];
        const numOpts = Math.floor(numHours * 60 / minutesStep);
        let counter = 0;
        let endLabel;
        let dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
        let startDateTime = startDate ? moment(`${startDate} ${startString}:00`, dateTimeFormat) : null;
        let endDateTime;
        let endTime;
        let duration;
        let durationUnit;
        let lastTime = time.format(timeFormat);
        for ( let ii = 0; ii < numOpts; ii++) {
            endTime = time.format('HH:mm');
            endLabel = time.format('h:mma');
            if (startDateTime && endDate) {
                if (lastTime !== '00:00' && endTime === '00:00') {
                    endDate = moment(endDate, dateFormat).add(1, 'days').format(dateFormat);
                }
                endDateTime = moment(`${endDate} ${endTime}:00`, dateTimeFormat);
                if (endDateTime.diff(startDateTime, 'days') >= 1) {
                    duration = (endDateTime.diff(startDateTime, 'days', true)).toFixed(1);
                    durationUnit = (duration === '1.0') ? 'day' : 'days';
                } else {
                    duration = (endDateTime.diff(startDateTime, 'hours', true)).toFixed(1);
                    durationUnit = (duration === '1.0') ? 'hr' : 'hrs';
                }
                endLabel += ` (${duration} ${durationUnit})`;
                lastTime = time.format(timeFormat);
            }
            opts.push({
                value: endTime,
                // Angular requires a date object for a `time` input type.
                // value: time.clone().toDate(),
                label: endLabel,
            });
            time.add(minutesStep, 'minutes');
        }
        return opts;
    };

    dtRange.guessChange = (startDate, startTime, endDate, endTime, changedKey, oldVal) => {
        const updates = {};
        let diff;
        if (changedKey === 'startDate') {
            if (oldVal && endDate && startDate) {
                // Change end date by the same amount. E.g. if start date is moved to
                // 1 day later, move end date to 1 day later also.
                diff = startDate.diff(oldVal, 'days');
                updates.endDate = (diff < 0) ? endDate.subtract((diff * -1), 'days')
                 : endDate.add(diff, 'days');
            } else if (!endDate && startDate) {
                // If no end date, set it to the same day (or 1 day later if
                // start time is after end time).
                if (startTime && endTime &&
                 startTime.format('HH:mm:ss') >= endTime.format('HH:mm:ss')) {
                    updates.endDate = startDate.add(1, 'days');
                } else {
                    updates.endDate = startDate;
                }
            }
        } else if (changedKey === 'startTime') {
            if (oldVal && endTime) {
                // Change end time by the same amount. E.g. if start time is moved to 30
                // minutes earlier, move end time to 30 minutes earlier also.
                diff = startTime.diff(oldVal, 'minutes');
                updates.endTime = (diff < 0) ? endTime.subtract((diff * -1), 'minutes')
                 : endTime.add(diff, 'minutes');
            } else if (!endTime) {
                // If no end time, set it to 30 minutes later.
                updates.endTime = startTime.add(30, 'minutes');
            }
        } else if (changedKey === 'endTime' && endDate && startTime && startDate) {
            // If end time is less than start time, and start date is greater
            // than or equal to end date increment end date to start date plus
            // 1 day. E.g. if start time is 9:00pm and end time is 1:30am, both
            // on Apr 26, make end Apr 27.
            const startDateFormat = startDate.format('YYYY-MM-DD');
            const endDateFormat = endDate.format('YYYY-MM-DD');
            // Add user behavior to change end date so leaving this alone for now.
            // if (startDateFormat > endDateFormat ||
            //  (startDateFormat === endDateFormat && startTime.format('HH:mm:ss')
            //   >= endTime.format('HH:mm:ss'))) {
            //     updates.endDate = startDate.add(1, 'days');
            // }
        } else if (changedKey === 'endDate' && startDate) {
            // If end date time is less than start date time, reduce start date
            // to end date or 1 day before end date.
            const startDateFormat = startDate.format('YYYY-MM-DD');
            const endDateFormat = endDate.format('YYYY-MM-DD');
            const startTimeFormat = startTime.format('HH:mm:ss');
            const endTimeFormat = endTime.format('HH:mm:ss');
            if (startDateFormat > endDateFormat || (startDateFormat === endDateFormat
             && startTimeFormat >= endTimeFormat)) {
                if (endTimeFormat > startTimeFormat) {
                    updates.startDate = endDate;
                } else {
                    updates.startDate = endDate.subtract(1, 'days');
                }
            }
        }
        return updates;
    };

    dtRange.formatRange = (start, end, format = 'dateFirst', duration1 = false, durationUnits = 'guess') => {
        let duration = false;
        if (duration1) {
            if (durationUnits === 'guess') {
                duration = end.from(start, true);
            } else {
                let diff = end.diff(start, durationUnits);
                duration = `${diff} ${durationUnits}`;
            }
        }
        let range = '';
        const dateFormat = 'YYYY-MM-DD';
        if (start.format(dateFormat) === end.format(dateFormat)) {
            if (format === 'dateFirst') {
                range = `${start.format('ddd M/D/YY h:mm A')} - ${end.format('h:mm A')}`;
            } else if (format === 'timeFirst') {
                range = `${start.format('h:mm A')} - ${end.format('h:mm A')}, ${start.format('ddd, MMM D')}`;
            }
        } else {
            if (format === 'dateFirst') {
                range = `${start.format('ddd M/D/YY h:mm A')} - ${end.format('ddd M/D/YY h:mm A')}`;
            } else if (format === 'timeFirst') {
                range = `${start.format('h:mm A, MMM D')} - ${end.format('h:mm A MMM D')}`;
            }
        }
        if (duration) {
            range += ` (${duration})`;
        }
        return range;
    };

    return dtRange;
}
plDateTimeRangeFactory.$inject = [];
module.exports = plDateTimeRangeFactory;
