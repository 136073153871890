module.exports = {
    // Min dimentions of the availWidth and availHeight
    // See https://developer.mozilla.org/en-US/docs/Web/API/Screen/availWidth
    minWidth: 800,
    minHeight: 600,

    // Min screen resolution. This is an approximation that takes into account the OS and browser display elements
    // that are not part of the availWidth and availHeight such as window border, menu bar, tabs, system tray and bars.
    minScreenResolutionWidth: 1366,
    minScreenResolutionHeight: 768,
};
