/**
 * The base data model
 * @param $log
 */
var DRFModel = function($log, $http, $q) {
    var Model = function() {};

    Model.prototype.$patch = function(fields) {
        var props = {};
        angular.forEach(fields, function(item) {
            props[item] = this[item];
        }, this);
        var method = 'PATCH';
        var url = this.resource_uri;

        return $http({
                method: method,
                url: url,
                data: props
            }).
            then((result) => {
                    angular.forEach(result.data, function(value, key) {
                        this[key] = value;
                    }, this);

                    return this;
                }
            )
            .catch (() => {
                    return this;
                }
            );
    };

    Model.prototype.$isSaved = function() {
        return this.hasOwnProperty('resource_uri');
    };

    Model.prototype.$save = function(obj) {
        var props = {};
        angular.forEach(this, function(value, key) {
            if (key.charAt(0) !== '$' && key.charAt(0) !== '_') {
                props[key] = value;
            }
        }, this);

        props = angular.extend(props, obj);

        var method = 'POST';
        var url = '';
        if (props.hasOwnProperty('resource_uri')) {
            method = 'PUT';
            url = props.resource_uri;
            delete props.resource_uri;
        } else {
            url = this._resource_uri;
        }

        return $http({
                method: method,
                url: url,
                data: props
            }).
            then((result) => {
                    angular.forEach(result.data, function(value, key) {
                        this[key] = value;
                    }, this);

                    return this;
                }
            ).
            catch ((errorObject) => {
                    /** We need possibility to manage server errors */
                    this.errorObject = errorObject;
                    return this;
                }
            );
    };

    Model.prototype.$setKey = function(key) {
        this.resource_uri = this._resource_uri + key + '/';
        return this;
    };

    Model.prototype.$get = function() {
        if (!this.hasOwnProperty('resource_uri')) {
            throw new Error('This model hasn\'t been saved yet.');
        }

        return $http.get(this.resource_uri).then((result) => {
                angular.forEach(result.data, (value, key) => {
                this[key] = value;
    });
        return this;
    });
    };

    Model.prototype.$delete = function() {
        if (!this.hasOwnProperty('resource_uri')) {
            throw new Error('This model hasn\'t been saved yet.');
        }

        return $http.delete(this.resource_uri)
            .then(() => {
                return this;
            })
            .catch(() => {
                return this;
            });
    };

    // We use a custom array so that we can add some parameters to it.
    var Collection = function(modelClass) {
        this.Model = modelClass;
    };

    Collection.prototype = new Array;

    Collection.prototype.$next = function(preserveExisting = false) {
        if (!_.isString(this._next)) {
            return;
        }
        return $http.get(this._next, this._options).
            then((u) => {
                if (u.data.limit) {
                    this.limit = u.data.limit;
                }
                this.totalCount = u.data.count;
                this._next = u.data.next;
                this._prev = u.data.previous;

                if (preserveExisting == false) {
                    this.splice(0, this.length);
                }

                for (var i = 0; i < u.data.results.length; i++) {
                    var model = new this.Model(u.data.results[i]);
                    this.push(model);
                    //this.splice(i, 0, model); // why did I do it this way?
                }

                this.currentPage += 1;
                this.totalPages = Math.ceil(u.data.count / this.limit);

               return this;
            }).
            catch(() => {
                return this;
            });
    };

    Collection.prototype.$onLastPage = function() {
        return this.currentPage == this.totalPages;
    };

    Collection.prototype.$prev = function() {
        if (!_.isString(this._prev)) {
            return;
        }

        return $http.get(this._prev, this._options).
            then((u) => {
                if (u.data.limit) {
                    this.limit = u.data.limit;
                }
                this.totalCount = u.data.count;
                this._next = u.data.next;
                this._prev = u.data.previous;
                this.splice(0, this.length);
                for (var i = 0; i < u.data.results.length; i++) {
                    var model = new this.Model(u.data.results[i]);
                    this.splice(i, 0, model);
                }
                this.currentPage -= 1;
                this.totalPages = Math.ceil(u.data.count / this.limit);

                return this;
            }).
            catch(() => {
                return this;
            });
    };

    Collection.prototype.$filter = function(obj) {
        angular.forEach(obj, function(value, key) {
            if (value !== null && value !== '') {
                this._filter[key] = value;
            } else {
                delete this._filter[key];
            }
        }, this);
        return this;
    };

    Collection.prototype.$clearFilter = function() {
        this._filter = {};
        return this;
    };

    Collection.prototype.$limit = function(count) {
        this.limit = count;
        return this;
    };

    Collection.prototype.$orderBy = function(sortField) {
        this._orderBy = sortField;
        return this;
    };

    Collection.prototype.$clear = function() {
        this.limit = 20;
        this._orderBy = null;
        this.offset = 0;
        this._filter = {};
        this._next = null;
        this.splice(0, this.length);
        return this;
    };

    Collection.prototype.$fetchPage = function(pageNumber) {
        return this.$fetch({
            offset: this.limit * (pageNumber - 1) // minus 1 to make the first page, page 1
        });
    };

    Collection.prototype.$fetch = function(obj) {
        var params = angular.copy(this._filter);
        params.limit = this.limit;
        params.offset = this.offset;
        if (this._orderBy !== null) {
            params.order_by = this._orderBy;
        }
        params = angular.extend(params, obj);

        return $http.get(this._resource_uri, {
                params: params,
            }).
            then(_.bind(function(u) {
                if (u) {

                    if (u.data.limit) {
                        this.limit = u.data.limit;
                    }
                    this.totalCount = u.data.count;
                    this._next = u.data.next;
                    this._prev = u.data.previous;
                    if (u.data.offset) {
                        this.currentPage = Math.floor(u.data.offset / u.data.limit) + 1;
                    }

                    if (u.data.results.length < this.limit) {
                        this.totalPages = 1;
                    } else {
                        this.totalPages = Math.ceil(u.data.count / this.limit);
                    }

                    this.splice(0, this.length);

                    for (var i = 0; i < u.data.results.length; i++) {
                        var model = new this.Model(u.data.results[i]);
                        this.splice(i, 0, model);
                    }

                }
                return this;
            }, this)).
            catch(angular.bind(this, function() {
                return this;
            }));
    };

    var DRFModel = function(params) {
        if (!params.hasOwnProperty('apiUrl')) {
            throw new Error('You must include an apiUrl in the params.');
        }

        var resource_uri = params.apiUrl;
        this._resource_uri = params.apiUrl;

        this.Model = function(properties) {
            angular.forEach(properties, function(value, key) {
                this[key] = value;
            }, this);

            this._resource_uri = resource_uri;
        };
        this.Model.prototype = new Model();
        this.Model.prototype.resource_uri = this._resource_uri;

        this.Collection = function() {
            this.limit = 20;
            this.offset = 0;
            this._orderBy = null;
            this.totalCount = null;
            this._next = null;
            this._prev = null;
            this._filter = {};
            this.currentPage = 1;
            this.totalPages = 1;
        };

        this.Collection.prototype = new Collection(this.Model);
        this.Collection.prototype._resource_uri = this._resource_uri;
    };
    return DRFModel;
};

DRFModel.$inject = ['$log', '$http', '$q'];
module.exports = DRFModel;
