function plRecordMetricsFactory($q, plHttp, plLodash) {
    const plRecordMetrics = {};

    plRecordMetrics.getMetricsWithPoints = (clientServiceUuid, recordUuid) => {
        const deferred = $q.defer();

        let points = [];

        plRecordMetrics.get({ client_service: clientServiceUuid })
            .then((resMetrics) => {
                if (!recordUuid) {
                    points = plRecordMetrics.mergePoints(resMetrics, [], recordUuid);
                    deferred.resolve(points);
                } else {
                    plRecordMetrics.getPoints({ record: recordUuid })
                        .then((resPoints) => {
                            points = plRecordMetrics.mergePoints(resMetrics, resPoints, recordUuid);
                            deferred.resolve(points);
                        });
                }
            });

        return deferred.promise;
    };

    plRecordMetrics.get = (params) => {
        const deferred = $q.defer();

        plHttp.get('metrics', params)
            .then((res) => {
                deferred.resolve(res.data.results);
            }, (err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    };

    plRecordMetrics.getPoints = (params) => {
        const deferred = $q.defer();

        plHttp.get('metricsPoints', params)
            .then((res) => {
                deferred.resolve(res.data.results);
            }, (err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    };

    plRecordMetrics.mergePoints = (metrics, points, recordUuid) => {
        // If any metrics do not have points, add them.
        let index;
        metrics.forEach((metric) => {
            index = plLodash.findIndex(points, 'metric_expanded.uuid', metric.uuid);
            if (index < 0) {
                points.push({
                    uuid: null,
                    trials: 0,
                    correct: 0,
                    metric_expanded: metric,
                    record: recordUuid,
                });
            }
        });
        return points;
    };

    plRecordMetrics.setPoint = (metrics) => {
        const deferred = $q.defer();
        const httpOpts = {
            url: `${plHttp.formUrl('metrics')}${metrics.uuid}/set/`,
            data: metrics,
            method: 'PATCH',
        };
        plHttp.go(httpOpts)
            .then(() => {
                deferred.resolve();
            }, (err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    };

    return plRecordMetrics;
}
plRecordMetricsFactory.$inject = ['$q', 'plHttp', 'plLodash'];
module.exports = plRecordMetricsFactory;
