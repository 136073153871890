var AssessmentThumbnailDirective = function ($log, $q, $window, $timeout, $compile, $stateParams) {
    return {
        restrict: "E",
        scope : {
            pagenum : '=',
            isdouble : '=',
            thumb : '=',
        },
        templateUrl: '/core/toys/app/src/toys/modules/assessment/doubleassessment/assessmentthumbnaildirective.tpl.html',

        link: function (scope, element, attrs) {

            scope.jump = function (pageNum, evt) {
                $log.debug('[AssessmentThumbnailDirective] jump: ' + pageNum);
            }
        }
    }
};

AssessmentThumbnailDirective.$inject = ['$log', '$q', '$window', '$timeout', '$compile', '$stateParams'];
module.exports = AssessmentThumbnailDirective;

