module.exports = angular.module('toys.activity', [

        //events
        require('./event').name,

        //activities
        require('./example').name,
        require('./youtube').name,
        require('./pdfviewer').name,
        require('./flashcards').name,
        require('./memory').name,

        require('./dynamic').name,

        //components
        require('./components/pdfViewer').name,
        require('./components/pdfToolbar').name,
        require('./components/dynamic').name

    ])
    .service('activityService', require('./ActivityService'))
    .service('activityModel', require('./ActivityModel'))
;
