var ActivitiesHomeListDirective = function($timeout) {
    return {
        restrict: 'E',
        replace: false,
        templateUrl: '/toychest/modules/store/activities/activities.home.list.tpl.html',
        scope: {
            name: '@',
            activities: '=', // array of activities
        },
        link: function(scope, element) {
            const MAX_RESULTS = 40;
            const PDF_ACTIVITY_TYPE = 'pdfviewer';

            var list = element.find('.activities-list');
            var container = $(element).find('.card-container');
            var viewport = element.find('.card-viewport');
            scope.offset = 0; //container.offset().left;

            container.context.scrollLeft = 100;

            var calculate_offset = function() {
                // -><-
                //This assumes that the viewport has one negative margin on the right
                var viewport_width = viewport.width() + (viewport.width() - viewport.outerWidth(true));
                var item_outer_width = container.children().outerWidth(true);
                var scroll_offset = parseInt(viewport_width / item_outer_width) * item_outer_width;

                return scroll_offset;
            };

            $(window).resize(function() {
                $timeout();
            });

            scope.has_next = function() {
                var container_right_width = scope.offset + container.width();
                return list.outerWidth() < container_right_width;
            };

            scope.has_prev = function() {
                return scope.offset < 0;
            };

            scope.num_reviews = function(num) {
                if (num < 0) {
                    num = 0;
                }
                var reviews = num + ' review';
                if (num !== 1) {
                    reviews = reviews + 's';
                }
                return reviews;
            };

            scope.next = function() {
                var offset = calculate_offset();
                scope.offset = scope.offset - offset;
                if(scope.activities.length < MAX_RESULTS) {
                    scope.activities.$next(true);
                }
            };

            scope.previous = function() {
                var offset = calculate_offset();
                scope.offset = scope.offset + offset;
                if (scope.offset > 0) {
                    scope.offset = 0;
                }
            };

            scope.getThumbnailUrl = function(activity) {
                return activity.activity_type === PDF_ACTIVITY_TYPE ? activity.large_thumbnail_url : activity.thumbnail_url;
            };
        }
    };
};

ActivitiesHomeListDirective.$inject = ['$timeout'];
module.exports = ActivitiesHomeListDirective;