function plAreasOfConcernFactory(plHttp, $q) {
    const plAreasOfConcern = {};

    plAreasOfConcern.get = (data = {}) => {
        const deferred = $q.defer();

        plHttp.get('areasOfConcern', data)
            .then((res) => {
                deferred.resolve(res.data.results);
            }, (err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    };

    plAreasOfConcern.formSelectOpts = (opts) => {
        return opts.map((opt) => {
            return { value: opt.uuid, label: opt.name };
        });
    };

    return plAreasOfConcern;
}
plAreasOfConcernFactory.$inject = ['plHttp', '$q'];
module.exports = plAreasOfConcernFactory;
