module.exports = ConfirmPopupDirective;

function ConfirmPopupDirective() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            show: '=',
            text: '='
        },
        templateUrl : '/toychest/common/directives/confirmPopup/confirmPopup.tpl.html',
        link: function ($scope, $elem, $attr) {
            $scope.onSuccess = function() {
                if (!$attr.onsuccess) {
                    return;
                }
                $scope.$parent.$eval($attr.onsuccess);

                $scope.textID = $scope.text.replace(/[^a-z0-9+]+/gi, '+');
            };

            $scope.onCancel = function() {
                if (!$attr.oncancel) {
                    return;
                }
                $scope.$parent.$eval($attr.oncancel);
            };
        }
    }
}