export default angular.module('pl-records', [])
    .factory('plRecord', require('./src/plRecordFactory'))
    .factory('plRecordBO', require('./src/plRecordBO'))
    .factory('plRecordSPI', require('./src/plRecordSPI'))
    .factory('PsychAssessmentBO', require('./src/PsychAssessmentBO'))
    .factory('plRecordAppointment', require('./src/plRecordAppointmentFactory'))
    .factory('plRecordMetrics', require('./src/plRecordMetricsFactory'))
    .factory('plRecordNotes', require('./src/plRecordNotesFactory'))
    .factory('plRecordParticipants', require('./src/plRecordParticipantsFactory'))
    .factory('plRecordRoom', require('./src/plRecordRoomFactory'))

    .directive('plEventRecords',
     require('./src/plEventRecords/plEventRecordsDirective'))
    .directive('plRecords',
     require('./src/plRecords/plRecordsDirective'))
    .directive('plRecord',
     require('./src/plRecord/plRecordDirective'))
    .directive('plRecordPreviewDirective',
     require('./src/plRecordPreview/plRecordPreviewDirective'))
    .directive('plRecordMetric',
     require('./src/plRecordMetric/plRecordMetricDirective'))
    .directive('plRecordNotes',
     require('./src/plRecordNotes/plRecordNotesDirective'))
    .directive('plRecordServiceUpdate',
     require('./src/plRecordServiceUpdate/plRecordServiceUpdateDirective'))
    .directive('plRecordsView',
     require('./src/plRecordsView/plRecordsViewDirective'))
    .directive('plRecordView',
     require('./src/plRecordView/plRecordViewDirective'))
    ;
