import plCore from 'pl-core';
import plNavGlobal from './src/plNavGlobal/plNavGlobalDirective.js';
// import plNavSidebar from './src/plNavSidebar/plNavSidebarDirective.js';
import plNavStepper from './src/plNavStepper/plNavStepperDirective.js';
import plNavTab from './src/plNavTab/plNavTabDirective.js';

export default angular.module('pl-stepper-nav', [plCore.name])
    .directive('plNavGlobal', plNavGlobal)
    // .directive('plNavSidebar', plNavSidebar)
    .directive('plNavStepper', plNavStepper)
    .directive('plNavTab', plNavTab);
