function plRecordsView() {
    return {
        restrict: 'E',
        scope: {
            records: '=',
            user: '=',
        },
        replace: true,
        templateUrl: '/core/pl-records/src/plRecordsView/plRecordsView.tpl.html',
        link: ($scope) => {
        },
    };
}

plRecordsView.$inject = [];
module.exports = plRecordsView;
