module.exports = angular.module('toys.lesson', [
        // require('./doublelesson').name,

    ])
    .service('lessonService', require('./LessonService'))
    .service('LessonModel', require('./LessonModel'))
    .directive('pdfLesson', require('./PdfLessonDirective'))


    .controller('PdfLessonDrawerController', require('./PdfLessonDrawerController'))
    .directive('pdfLessonDrawer', require('./PdfLessonDrawerDirective'))
;
