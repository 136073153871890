export default class PdfLessonDrawerController {
  constructor($scope, $timeout, LessonModel, $q) {
      'use strict';

      const DEFAULT_PAGE_NUM = 1;
      const DEFAULT_ZOOM = 1;
      const DBL_PAGE_EVEN_MODE = 2;
      const DBL_PAGE_ODD_MODE = 3;
      const SINGLE_PAGE_MODE = 1;

      let element = $('.pdf-lesson-drawer');
      let pdfDoc;
      let page;
      let totalPages = 0;
      let pagesRendered = [];
      let rotateDeg = 0;
      let pageRotations = {};
      let customZoomInputField = element.find('#customZoomInputField');
      $scope.bookMode = SINGLE_PAGE_MODE;
      $scope.doublePages = [];

      $scope.totalPages = 0;

      console.log('[PdfLessonDrawerController] LessonModel: ', LessonModel);

      function initialize() {
          $scope.thumbs = LessonModel.activity.page_thumbnails;
          $scope.hasThumbs = LessonModel.activity.page_thumbnails && LessonModel.activity.page_thumbnails.length > 0;

          if (!$scope.hasThumbs) {
              element.find('main').on('scroll', _.throttle(function () {
                  renderThumbnails(Object.keys(pageRotations).map((val) => parseInt(val.slice(4))));
              }, 100));
          }

          scrollToPage($scope.currentPage);

          LessonModel.getSessionRef().child('pageRotation').on('value', (snap) => {
              pageRotations = snap.val() || {};

              for (let pageToRotate in pageRotations) {
                  if ($scope.hasThumbs) {
                      $scope.thumbRotations = pageRotations;
                  } else {
                      renderRotate(
                          parseInt(pageToRotate.slice(4), 10),
                          pageRotations[pageToRotate],
                      );
                  }
              }
          });
      }

      $scope.addFoundationListener = () => {
          LessonModel.foundationLoaded.then(() => {
                  $scope.currentScale = 1;

                  LessonModel.channel.bind('gotoPage', (trans, pageNum) => {
                      $scope.currentPage = pageNum;
                      $scope.selectedPage = $scope.currentPage;
                      $scope.$evalAsync();
                  });

                  loadState().then(() => {
                      $scope.setCustomZoomDisplayValue($scope.currentScale);
                      LessonModel.getSessionRef().child('pageRotation').once('value', (snap) => {
                          pageRotations = snap.val() || {};
                          $scope.hasThumbs = LessonModel.activity.page_thumbnails && LessonModel.activity.page_thumbnails.length > 0;

                          if (!$scope.hasThumbs) {
                              loadPDF(LessonModel.activity.file_url)
                                  .then(() => renderThumbnails([]))	                            
                                  .then(initialize);
                          } else {
                              totalPages = LessonModel.activity.page_thumbnails.length;
                              configurePages(totalPages);
                              $timeout();
                              try {
                                  $scope.$digest();
                              } catch (e) {
                                  $log.debug('PdfLessonDrawerController error on scope digest in getDocument in pdfViewerDrawerController');
                              }
                              initialize();
                          }
                      });
                  });
              },
              undefined,
              (value) => $scope.addFoundationListener());
      };

      $scope.getThumbStyle = (pageNum) => {
          const style = {};
          if ($scope.thumbRotations) {
              const degrees = $scope.thumbRotations[ 'page' + pageNum];
              if (degrees) {
                  style.transform = `rotate(${degrees}deg)`
                  if (degrees === 90 || degrees === 270) {
                      style['max-width'] = '97px';
                  }
              }
          }
          return style;
      }

      $scope.addFoundationListener();

      $scope.zoomOptions = {
          '10%': 0.10,
          '25%': 0.25,
          '50%': 0.5,
          '75%': 0.75,
          '100%': 1,
          '125%': 1.25,
          '150%': 1.5,
          "175%": 1.75,
          '200%': 2,
          '300%': 3,
          '600%': 6
      };

      $scope.bookOptions = {
          'Single': 1,
          'Double (start pg 1)': 2,
          'Double (start pg 2)': 3
      };

      $scope.setCustomZoomDisplayValue = zoomScale => {
          $scope.customZoomDisplayValue = Number((zoomScale * 100).toFixed(0));
      };


      $scope.fixCustomZoomDisplayValueAndUpdateScale = () => {
          let zoom = Number($scope.customZoomDisplayValue);
          zoom = zoom < 10 ? 10 : (zoom > 600 ? 600 : zoom);
          $scope.currentScale = (zoom / 100);
          $scope.customZoomDisplayValue = zoom;
          $scope.zoomTo($scope.currentScale);
      };

      /**
       * ALLOW:
       *   - number keys
       *   - shift with left, right arrow keys
       *   - delete, backspace keys
       * DISALLOW:
       *   - shift with number keys
       *   - alt with number keys
       *   -
       * @param event
       */
      $scope.restrictToNumericEntry = event => {
          let isNumberKey = (event.keyCode >= 48 && event.keyCode <= 57); // numbers
          let isValidKey = isNumberKey
              || event.keyCode === 8 // backspace
              || event.keyCode === 13 // enter
              || event.keyCode === 37 // left arrow
              || event.keyCode === 39 // right arrow
              || event.keyCode === 46; // delete
          let isModifierKey = event.altKey || event.shiftKey || event.ctrlKey;

          if (!isValidKey || (isNumberKey && isModifierKey)) {
              event.preventDefault();
          }
      };

      $scope.zoomInputFieldOnBlur = event => {
          // reset zoom input field if user clicks outside the input field without pressing Enter
          if ($scope.currentScale !== ($scope.customZoomDisplayValue / 100)) {
              $scope.setCustomZoomDisplayValue($scope.currentScale);
          }

          ZoomUpdateHandler.onBlurCustomZoomInput();
      };

      function calcScale(pdfW, pdfH, containerW, containerH, scale) {
          let newScale = scale;
          let scaleX, scaleY;

          if (pdfW < containerW && pdfH < containerH) {
              scaleX = containerW / pdfW;
              scaleY = containerH / pdfH;
              newScale = Math.min(scaleX, scaleY) * scale;
          }

          else if (pdfW > containerW && pdfH > containerH) {
              scaleX = containerW / pdfW;
              scaleY = containerH / pdfH;
              newScale = Math.min(scaleX, scaleY) * scale;
          }

          else if (pdfW < containerW && pdfH > containerH) {
              // it's taller than the container, make the height smaller
              scaleY = containerH / pdfH;
              newScale = scaleY * scale;
          }

          else if (pdfW > containerW && pdfH < containerH) {
              // it's wider than the container, make it narrower
              scaleX = containerW / pdfW;
              newScale = scaleX * scale;
          }

          return newScale;
      }

      $scope.getDisplayMode = () => {
          if ($scope.bookMode === SINGLE_PAGE_MODE) {
              return "Single";
          } else if ($scope.bookMode === DBL_PAGE_EVEN_MODE) {
              return "Double (pg 2)";
          } else {
              return "Double (pg 1)";
          }
      }

      $scope.next = () => {

          let pageDest;
          if ($scope.bookMode == SINGLE_PAGE_MODE) {
              pageDest = Math.min($scope.currentPage + 1, totalPages);

          } else {
              pageDest = Math.min($scope.currentPage + 2, totalPages);
              if ($scope.bookMode === DBL_PAGE_EVEN_MODE && $scope.currentPage === 1) {
                  pageDest = 2;
              }
          }
          $scope.gotoPage(pageDest);

      }

      $scope.prev = () => {
          let pageDest;
          if ($scope.bookMode === SINGLE_PAGE_MODE) {
              pageDest = Math.max($scope.currentPage - 1, 1);

          } else {
              pageDest = Math.max($scope.currentPage - 2, 1);
              if ($scope.bookMode === DBL_PAGE_EVEN_MODE && $scope.currentPage === totalPages && totalPages % 2 !== 0) {
                  pageDest = totalPages - 1;
              }
          }
          $scope.gotoPage(pageDest);
      }

      $scope.switchModeTo = (bookVal) => {

          pagesRendered = [];
          $scope.bookMode = bookVal;
          $timeout();
          if (bookVal == DBL_PAGE_EVEN_MODE) {
              $scope.doublePages = $scope.doublePagesEven;
          } else if (bookVal == DBL_PAGE_ODD_MODE) {
              $scope.doublePages = $scope.doublePagesOdd;
          }

          setTimeout(function () {
              $scope.$apply();
              renderThumbnails(Object.keys(pageRotations).map((val) => parseInt(val.slice(4))));
          }, 100);


          $scope.$evalAsync();

          LessonModel.channel.call({
              method: 'switchMode',
              params: bookVal,
              success: () => {

              }
          })
      }

      $scope.zoomTo = (zoomVal) => LessonModel.channel.call({
          method: 'zoom',
          params: zoomVal,
          success: () => {
              $scope.currentScale = zoomVal;
              $scope.setCustomZoomDisplayValue($scope.currentScale);
              ZoomUpdateHandler.onClickZoomOptions();
              $scope.$evalAsync();
          }
      });

      // When the custom zoom input field blurs, we want to close the zoom options dropdown
      // However, if the blur happened from clicking a zoom option, closing it immediately
      // would inappropriately short-circuit the zoom option selection. To remedy this,
      // delay the field onBlur action to give the select onClick action a chance to set
      let ZoomUpdateHandler = {
          onBlurCustomZoomInput: () => {
              $timeout(() => $(document).trigger("closeAllDropdowns"), 100);
          },
          onClickZoomOptions: () => {
              customZoomInputField.blur();
              $(document).trigger("closeAllDropdowns");
          }
      };

      $scope.rotate90 = function () {
          $scope.gotoPage($scope.currentPage);

          rotateDeg = 90; //default
          window.heap && window.heap.track('Rotation', {document_name: LessonModel.configModel.name});

          let existingRotation = Object.keys(pageRotations).find((pageToRotate) =>
              pageToRotate.slice(4) == $scope.currentPage
          );

          existingRotation = pageRotations[existingRotation];

          if (existingRotation) {
              rotateDeg += existingRotation;
          }

          LessonModel.getSessionRef()
              .child('pageRotation')
              .child('page' + $scope.currentPage)
              .set(rotateDeg % 360);
      };

      $scope.zoom = () => {
          let scale = $scope.currentScale;

          LessonModel.channel.call({
              method: 'zoom',
              params: scale,
              success: () => {
                  $scope.currentScale = scale;
                  $scope.$evalAsync();
              }
          });
      };

      $scope.gotoPageChannel = _.debounce((pageNum) => {
          LessonModel.channel.call({
              method: 'gotoPage',
              params: pageNum,
              success: () => {
                  checkDisableNavButtons();
              }
          });
      }, 200);

      $scope.gotoPage = (pageNum) => {
          if (pageNum === undefined) {
              pageNum = Math.min(totalPages, Math.max(1, parseInt($scope.currentPage)));
          }

          $scope.currentPage = $scope.selectedPage = pageNum;
          if ($scope.bookMode === DBL_PAGE_ODD_MODE && pageNum % 2 === 0) {
              pageNum--;
          }
          if ($scope.bookMode === DBL_PAGE_EVEN_MODE && pageNum % 2 === 1 && pageNum !== 1) {
              pageNum--;
          }

          scrollToPage(pageNum);

          $scope.gotoPageChannel(pageNum);
      };

      function escapeHandler(e) {
          if (e.keyCode === 27) {
              $('.jumpToInput').blur();
          }
      }

      $(document).on('keyup', escapeHandler);
      $scope.$on('$destroy', () => $(document).off('keyup', escapeHandler));

      function renderRotate(pageNum, rotateDeg) {
          if ($scope.hasThumbs || $scope.bookMode > SINGLE_PAGE_MODE) {
              return;
          }

          let canv = $('#pageCanvas' + pageNum)[0];
          let loader = $('.loader' + pageNum);
          let isVertical = rotateDeg === 0 || rotateDeg % 180 === 0;

          if (rotateDeg > 270) {
              rotateDeg = rotateDeg % 360;
          }

          if (!pdfDoc) {
              return;
          }

          pdfDoc.getPage(pageNum).then((p) => {
              page = p;
              canv.height = 97;
              canv.width = 140;

              let viewport = page.getViewport(1.0);
              let newScale;

              if (isVertical) {
                  newScale = calcScale(viewport.width, viewport.height, canv.width, canv.height, 1);
              }

              else {
                  newScale = calcScale(viewport.height, viewport.width, canv.width, canv.height, 1);
              }

              let context = canv.getContext('2d');

              viewport = page.getViewport(newScale, rotateDeg);

              if (viewport.height < canv.height) {
                  canv.height = viewport.height;
              }

              if (viewport.width <= canv.width) {
                  let offset = (element.find('#pdfThumbnails').width() - viewport.width) / 2;

                  $(canv).css('left', offset);

                  canv.width = viewport.width;
              }

              let renderContext = {
                  canvasContext: context,
                  viewport: viewport
              };

              loader.removeClass('hide');

              page.render(renderContext);
          }).then(() => loader.addClass('hide'));
      }

      function renderPage(pageNum) {
          if (~pagesRendered.indexOf(pageNum)) {
              return;
          }
          pagesRendered.push(pageNum);

          let defer = $q.defer();

          let canv = $('#pageCanvas' + pageNum)[0];
          let canvHolder = $('.canvasContainer')[0];
          let loader = $('.loader' + pageNum);

          loader.removeClass('hide');

          if (!pdfDoc) {
              return;
          }

          let rotation = Object.keys(pageRotations).find((pageToRotate) =>
              pageToRotate.slice(4) == pageNum
          );

          rotation = pageRotations[rotation];

          if (!rotation) {
              rotation = 0;
          }


          let containerWidth = canv.clientWidth;
          let containerHeight = canv.height;

          if ($scope.bookMode > SINGLE_PAGE_MODE) {
              containerWidth = (canvHolder.clientWidth / 2) - 10;
              containerHeight = canvHolder.clientHeight
          }

          pdfDoc.getPage(pageNum).then((p) => {
              page = p;
              canv.height = 97;

              let viewport = page.getViewport(1.0, rotation);
              let viewportScale;
              if (viewport.width > viewport.height || $scope.bookMode === SINGLE_PAGE_MODE) {
                  viewportScale = calcScale(viewport.width, viewport.height, containerWidth, containerHeight, 1);
              } else {
                  viewportScale = calcScale(viewport.height, viewport.width, containerWidth, containerHeight, 1);
              }

              //  = calcScale(viewport.width, viewport.height, containerWidth, containerHeight, 1);
              let context = canv.getContext('2d');

              viewport = page.getViewport(viewportScale, rotation);

              if (viewport.height < canv.height) {
                  canv.height = viewport.height;
              }

              if (viewport.width <= canv.width) {
                  let offset = (element.find('#pdfThumbnails').width() - viewport.width) / 2;

                  $(canv).css('left', offset);

                  canv.width = viewport.width;
              }

              let renderContext = {
                  canvasContext: context,
                  viewport: viewport
              };



              return page.render(renderContext);
          }).then(() => {

              loader.addClass('hide');

              defer.resolve();
          });

          return defer.promise;
      }

      function scrollToPage(pageNum) {
          if (!pageNum) {
              pageNum = 1;
          }

          if ($scope.bookMode === DBL_PAGE_EVEN_MODE) {
              pageNum = Math.ceil((pageNum / 2) + 1);
          } else if ($scope.bookMode === DBL_PAGE_ODD_MODE) {
              pageNum = Math.ceil(pageNum / 2);
          }

          let selector = '.thumbnails > button:nth-child(' + (pageNum) + ')';
          let thumbnail = element.find(selector);
          let main = element.find('main');

          if (!thumbnail) {
              return;
          }

          let thumbnailOffset = thumbnail.offset();
          let thumbnailTop = thumbnailOffset.top;
          let thumbnailBottom = thumbnailOffset.top + thumbnail.height();

          let mainOffset = main.offset();
          let mainTop = mainOffset.top;
          let mainBottom = mainTop + main.height();

          let scrollPos = main.scrollTop();

          // scroll up
          if (thumbnailTop < mainTop) {
              let delta = mainTop - thumbnailTop;
              main.scrollTop(scrollPos - delta);
          }

          //scroll down
          if (thumbnailBottom > mainBottom) {
              let delta = thumbnailBottom - mainBottom;
              main.scrollTop(scrollPos + delta);
          }
      }

      function percentVisible(pageNum) {


          if ($scope.bookMode === DBL_PAGE_EVEN_MODE) {
              pageNum = Math.ceil((pageNum / 2) + 1);
          } else if ($scope.bookMode === DBL_PAGE_ODD_MODE) {
              pageNum = Math.ceil(pageNum / 2);
          }
          if($scope.doublePages.length > 0){ // if in double modes
              pageNum = Math.min($scope.doublePages.length, pageNum);
          }

          let selector = '.thumbnails > button:nth-child(' + (pageNum) + ')';
          let thumbnail = element.find(selector);
          let main = element.find('main');
          let thumbnailPos = thumbnail.offset();
          let thumbnailTop;
          if(thumbnailPos){
              thumbnailTop = thumbnailPos.top;
          } else {
              return 1;
          }
          let thumbnailBottom = thumbnailTop + thumbnail[0].clientHeight;
          let mainTop = main.offset().top;
          let mainBottom = mainTop + main.height();

          // the thumbnail is above main
          if (thumbnailBottom < mainTop) {
              return 0;
          }

          // the thumbnail is below main
          if (thumbnailTop > mainBottom) {
              // thumbnail will be shown soon
              // so we have to render it already
              if (thumbnailTop - mainBottom < thumbnail.height() * 2) {
                  return 1.0;
              }

              return 0;
          }

          // the thumbnail is fully visible
          if (thumbnailTop >= mainTop && thumbnailBottom <= mainBottom) {
              return 100.0;
          }

          // the thumbnail is partially visible at the top of main
          if (thumbnailTop < mainTop && thumbnailBottom > mainTop) {
              return (mainBottom - thumbnailTop) / thumbnail.height();
          }

          // the thumbnail is partially visible at the bottom of main
          if (thumbnailBottom > mainBottom && thumbnailTop < mainBottom) {
              return (thumbnailBottom - mainTop) / thumbnail.height();
          }

          return 0;
      }

      function renderThumbnails(skip) {
          if ($scope.hasThumbs) {
              return;
          }
          //if ($scope.bookMode > 1){
          skip = [];
          //}

          let promises = [];

          for (let i = 0; i < totalPages; i++) {
              let pageNum = i + 1;

              if (percentVisible(pageNum) > 0 && (!skip || skip.indexOf(parseInt(pageNum)) === -1)) {
                  promises.push($q.when(renderPage(pageNum)));
              }
          }

          return $q.all(promises);
      }

      $scope.getThumbnailClasses = (page) => {
          let classes = [];

          if (page == $scope.selectedPage) {
              classes.push('active');
          }

          return classes;
      };

      function loadState() {
          let deferPageNum = $q.defer();
          let deferZoom = $q.defer();

          LessonModel.getSessionRef().child('pageNum')
              .once('value', (snap) => {
                  let pageNum = snap.val();

                  if (pageNum) {
                      $scope.selectedPage = $scope.currentPage = pageNum;
                      $scope.$evalAsync();
                  }

                  else {
                      $scope.selectedPage = $scope.currentPage = DEFAULT_PAGE_NUM;
                  }

                  deferPageNum.resolve();
              });

          LessonModel.getSessionRef().child('scale')
              .once('value', (snap) => {
                  let zoom = snap.val();

                  if (zoom) {
                      $scope.currentScale = zoom;
                      $scope.$evalAsync();
                  }

                  else {
                      $scope.currentScale = DEFAULT_ZOOM;
                  }

                  deferZoom.resolve();
              });

          LessonModel.getSessionRef().child('bookMode')
              .once('value', (snap) => {
                  let mode = snap.val();

                  if (mode) {
                      $scope.bookMode = mode;
                      $scope.$evalAsync();
                  }
                  else {
                      $scope.bookMode = SINGLE_PAGE_MODE;
                  }


              });

          return $q.all([deferPageNum.promise, deferZoom.promise]);
      }

      function checkDisableNavButtons() {
          let prevBtn = element.find('.prevBtn');
          let nextBtn = element.find('.nextBtn');

          if ($scope.currentPage === 1) {
              $(prevBtn).addClass('disabled');
              $(prevBtn).prop('disabled', true);

              $(nextBtn).removeClass('disabled');
              $(nextBtn).prop('disabled', false);
          }

          else if ($scope.currentPage === totalPages || ($scope.currentPage + 1 === totalPages && $scope.bookMode > SINGLE_PAGE_MODE)) {
              $(prevBtn).removeClass('disabled');
              $(prevBtn).prop('disabled', false);

              $(nextBtn).addClass('disabled');
              $(nextBtn).prop('disabled', true);
          }
          else {
              $(prevBtn).removeClass('disabled');
              $(prevBtn).prop('disabled', false);

              $(nextBtn).removeClass('disabled');
              $(nextBtn).prop('disabled', false);
          }
      }

      function loadPDF(url) {
        let defer = $q.defer();
        const loadProtectedPdf = (protectedUrl) => {
            PDFJS.getDocument(protectedUrl).then((loadedPdf) => {
            pdfDoc = loadedPdf;
            totalPages = pdfDoc.pdfInfo.numPages;
            configurePages(totalPages);
            $timeout();
            
            $scope.$digest();
            defer.resolve();
          });
        }
        // check if this url is already cached before we ask for it
        let protectedUrl = LessonModel.cachedProtectedAsset(url);
        if (protectedUrl == null) {
            $scope.secureUrl = LessonModel.getProtectedContentUrl(url).then( (data) => {
                protectedUrl = data.assets[url];
                loadProtectedPdf(protectedUrl);
            });
        } else {
          loadProtectedPdf(protectedUrl);
        }

        return defer.promise;
      }

      function configurePages(count) {
          $scope.totalPages = count;
          $scope.pages = [];
          $scope.doublePagesEven = [];
          $scope.doublePagesOdd = [];

          for (let i = 1; i <= totalPages; i++) {
              $scope.pages.push(i);
          }
          for (let i = 1; i <= totalPages; i = i + 2) {
              if (i + 1 <= totalPages) {
                  $scope.doublePagesOdd.push([i, i + 1]);
              } else {
                  $scope.doublePagesOdd.push([i]);
              }
          }
          $scope.doublePagesEven.push([1]);
          for (let i = 2; i < totalPages; i = i + 2) {
              if (i + 1 <= totalPages) {
                  $scope.doublePagesEven.push([i, i + 1]);
              } else {
                  $scope.doublePagesEven.push([i]);
              }

          }
          if (totalPages % 2 === 0) {
              $scope.doublePagesEven.push([totalPages]);
          }

          if ($scope.bookMode === DBL_PAGE_EVEN_MODE) {
              $scope.doublePages = $scope.doublePagesEven;
          } else if ($scope.bookMode === DBL_PAGE_ODD_MODE) {
              $scope.doublePages = $scope.doublePagesOdd;
          }
          $timeout();
      }

      $scope.pageExists = (pageNum) => {
          if (isNaN(pageNum)) {
              return true;
          }
          return false;
      }

      $scope.$on('$destroy', function () {
          pdfDoc && pdfDoc.destroy();
          pdfDoc = null;
      });
  }
}

PdfLessonDrawerController.$inject = [
  '$scope',
  '$timeout',
  'LessonModel',
  '$q'
];

module.exports = PdfLessonDrawerController;
