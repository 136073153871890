console.log('store index');
module.exports = angular.module('toychest.store', ['dndLists'])
    .controller('StoreController', require('./StoreController'))
    .controller('FavoritesController', require('./favorites/FavoritesController'))
    .controller('PlaylistsController', require('./playlists/PlaylistsController'))
    .controller('InvalidActivityController', require('./invalid/InvalidActivityController'))

    .service('FlashcardsWizardService', require('./wizards/FlashcardsWizardService'))

    .config(require('./routes'))
;
