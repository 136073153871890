/**
 * InvalidActivityController
 *
 * Controller for the invalid activity route
 * @param $log
 * @param $scope
 * @param $state
 * @constructor
 */
function InvalidActivityController($log, $scope, $state) {

    /**
     * Return user back to library activities
     */
    $scope.returnToLibc = function() {
        $log.debug("[InvalidActivityController] returning user to library route.");
        //Forward to all activities
        $state.go('app.store.activities.list');
    };
}

InvalidActivityController.$inject = ['$log', '$scope', '$state'];
module.exports = InvalidActivityController;
