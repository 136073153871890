function plRecord(plBillingCodes, plTimezone, plLocation, plRecordNotes,
  currentUserModel, plClientServices, plRecord, plRecordSPI, PsychAssessmentBO, $state,
  $timeout, $q, plHistory, plOverlayModal) {
  return {
    restrict: 'E',
    scope: {
      record: '=',
      saveRecord: '&?',
      submitLoading: '=?',
      user: '=',
    },
    replace: true,
    templateUrl: '/core/pl-records/src/plRecord/plRecord.tpl.html',
    link: ($scope) => {

      let billingInfo;
      let notesLoaded;
      let clientServiceMap;
      let activitiesMap, activitiesDetailsMap;

      $scope.locationTrackingOpts = [
        { value: 'regular', label: 'Regular' },
        { value: 'extended_school_year', label: 'Extended School Year' },
        { value: 'compensatory_time', label: 'Compensatory Time' },
      ];

      //------------------------
      // Initialization
      //------------------------

      /**
       * @side-effects
       *   $scope.record
       *   $scope.recordView
       */
      const init = async () => {
        $scope.hasRecord = $scope.record && Object.keys($scope.record).length !== 0;
        if ($scope.hasRecord) {
          billingInfo = null;
          notesLoaded = false;
          $scope.recordView = {};
          $scope.clientServiceRequired = false;
          $scope.locationRequired = false;
          $scope.noteSchemaType = '';
          $scope.clientServicesLoaded = false;
          $scope.expanded = true;
          $scope.requiredFieldsFilled = false;
          $scope.loadingData = true;
          $scope.clientServiceType = 'evaluation'; // TODO: what is the significance of this?

          await Promise.all([loadLocations(), loadBillingCodes(), loadNotesSchema()]);

          $scope.loadingData = false;
          if ($scope.record.client_service) {
            $scope.changeClientService();
          }

          if (!$scope.record.client && $scope.record.client_expanded) {
            $scope.record.client = $scope.record.client_expanded.uuid;
          }
          if (!$scope.record.location && $scope.record.location_expanded) {
            $scope.record.location = $scope.record.location_expanded.uuid;
          }

          $scope.recordView = plRecord.recordToView($scope.record, $scope.user);
          $scope.disableSave = $scope.recordView.signed;
          setRequiredFields();
          $scope.checkRequiredFieldsFilled();
          if ($scope.recordForm) {
            $scope.record._formValid = $scope.recordForm.$valid;
          }
          $timeout(()=>{
            $scope.changeLocation();
          })
        }
        $timeout();
      }

      //------------------------
      // Load data from API
      //------------------------
      const loadClientServices = () => {
        return new Promise(async (resolve, reject) => {
          const filters = {
            provider: $scope.user.uuid,
          };
          if ($scope.record.client_expanded) {
            filters.client = $scope.record.client_expanded.uuid;
          }
          if ($scope.record.billing_code) {
            filters.billing_code = $scope.record.billing_code;
          }

          const clientServices = await plClientServices.get(filters);

          $scope.clientServicesOpts = plClientServices.formSelectOpts(clientServices, true, $scope.record.end);
          clientServiceMap = clientServices.reduce((result, item) => {
            result[item.uuid] = item;
            return result;
          },{});
          updateNoteSchema();
          $scope.clientServicesLoaded = true;
          resolve();
          $timeout();
        });
      }

      const loadBillingCodes = () => {
        return new Promise(async (resolve, reject) => {
          const filters = {
            'with_can_provide': 1
          };
          if ($scope.record.client_expanded) {
            filters.client = $scope.record.client_expanded.uuid;
          }

          let codes = await plBillingCodes.get(filters);

          if ($scope.record.client_expanded) {
            codes = plBillingCodes.filterClientParticipates(codes);
          } else if ($scope.record.location_expanded) {
            codes = plBillingCodes.filterLocationParticipates(codes);
          }
          billingInfo = plBillingCodes.codeInfo($scope.record.billing_code);
          codes = plBillingCodes.filterParticipates(billingInfo, codes);

          // TODO: separate side-effect - set $scope.billingOpts
          $scope.billingOpts = plBillingCodes.formAllOpts(codes, true);
          $scope.billingOpts = $scope.billingOpts.filter((item) => {
            return item.canProvide;
          });

          setRequiredFields();

          await loadClientServices();

          resolve();
          $timeout();
        });
      }

      const loadLocations = () => {
        return new Promise(async (resolve, reject) => {
          let data = {
            provider: $scope.user.uuid,
          };
          if ($scope.record.client_expanded) {
            data.client = $scope.record.client_expanded.uuid;
          }

          const locations = await plLocation.get(data);

          $scope.locationsExpanded = locations;
          $scope.locationOpts = plLocation.formSelectOpts(locations);

          resolve();
          $timeout();
        });
      }

      const loadNotesSchema = () => {
        return new Promise(async (resolve, reject) => {
          await plRecordNotes.get();
          notesLoaded = true;
          updateNoteSchema();
          resolve();
          $timeout();
        });
      }

      //------------------------
      // UI Event Handlers
      //------------------------
      $scope.changeBillingCode = async () => {
        await loadClientServices();
        await loadBillingCodes();
        setClientServiceRequired();
        $scope.checkRequiredFieldsFilled();
      };

      /**
       * @side-effects
       *   $scope.pa.state (for PA Eval)
       */
      $scope.changeClientService = async () => {
        $scope.record.clientServiceModel = clientServiceMap[$scope.record.client_service]
        if ($scope.isEvalPsychAssessment()) {
          // get the activities that have been saved to the evaluation
          const savedEvaluationActivities
            = await PA_BO.getEvaluationActivities($scope.record.clientServiceModel.uuid);
          $scope.pa.state.savedEvaluationActivities = savedEvaluationActivities;
        }
        updateNoteSchema();
        $scope.checkRequiredFieldsFilled();
      };

      /**
       * @side-effects
       *   $scope.recordView
       */
      $scope.changeLocation = () => {
        if($scope.record && $scope.record.billing_expanded &&
          ($scope.record.billing_expanded.code === 'direct_services' || $scope.record.billing_expanded.code === 'direct_makeup') ) {
          let locationExpanded = $scope.locationsExpanded.find( (loc) => {
            return loc.uuid === $scope.record.location;
          } );
          if(locationExpanded){
            $scope.recordView.getLocationTracking = locationExpanded.record_tracking_type;
          }
        }

        $scope.checkRequiredFieldsFilled();
      };

      /**
       * @side-effects
       *   $scope.record
       *   $scope.recordView
       */
      $scope.changeSigned = () => {
        if ($scope.recordView.signed) {
          $scope.record.signed_on = plTimezone.curDateTime();
          $scope.record.signed_by = currentUserModel.user.uuid;
          $scope.record.signed = true;
        } else {
          $scope.record.signed = false;
          $scope.record.signed_on = null;
          $scope.record.signed_by = null;
          $scope.recordView.signedNote = '';
          $scope.disableSave = false;
        }
      };


      //------------------------
      // Page Action Buttons
      //------------------------

      /**
       * @uses
       *  plHistory.back()
       */
      $scope.cancel = () => {
        plHistory.back('app.calendar');
      }

      $scope.submit = () => {
        $scope.disableSave = $scope.recordView.signed;
        if ($scope.isEvalPsychAssessment()) {
          $scope.pa.form.clickedSubmit = true;
          if (!validateAssessmentComponentList()) {
            return;
          }
        }
        if ($scope.requiredFieldsFilled) {
          $scope.saveRecord()($scope.record);
        }
      };

      //------------------------
      // Watchers
      //------------------------
      $scope.$watch('record', (newVal, oldVal) => {
        if (!angular.equals(oldVal, newVal)) {
          init();
        }
      });
      $scope.$watch('recordForm.$valid', (newVal, oldVal) => {
        if (!angular.equals(oldVal, newVal)) {
          $scope.record._formValid = $scope.recordForm.$valid;
        }
      });

      //------------------------
      // Helpers
      //------------------------

      /**
       * @uses
       *   plBillingCodes.isClientServiceRequired()
       * @side-effects
       *   $scope.clientServiceRequired
       */
      function setClientServiceRequired() {
        // Must check client expanded first as location expanded will always exist, even for client record.
        if ($scope.record.client_expanded) {
          $scope.clientServiceRequired = plBillingCodes.isClientServiceRequired($scope.record.billing_code);
        } else {
          $scope.clientServiceRequired = false;
        }
      }

      /**
       * @uses
       *   plRecord.getRequiredFields()
       * @side-effects
       *  $scope.locationRequired
       */
      function setRequiredFields() {
        setClientServiceRequired();
        const required = plRecord.getRequiredFields($scope.record);
        $scope.locationRequired = required.location;
        $timeout();
      }

      /**
       * @uses
       *   plRecordNotes .getSchemaInfo()
       *
       * @side-effects
       *   $scope.noteSchemaType
       *   $scope.record.note_schema
       */
      function updateNoteSchema() {
        if (billingInfo && notesLoaded) {
          const notesSchema = plRecordNotes.getSchemaInfo(billingInfo.record_note_type);
          if (notesSchema) {
            $scope.noteSchemaType = notesSchema.code;
            $scope.record.note_schema = notesSchema.uuid;
          }
        }
        $timeout();
      }

      /**
       * @side-effects
       *   $scope.requiredFieldsFilled
       */
      $scope.checkRequiredFieldsFilled = () => {
        if ((!$scope.clientServiceRequired || $scope.record.client_service) &&
          (!$scope.recordView.showLocation || $scope.record.location) && $scope.record.billing_code) {
          return $scope.requiredFieldsFilled = true;
        }
          return $scope.requiredFieldsFilled = false;
      };

      $scope.getClasses = () => {
        return {
          expanded: $scope.expanded,
        };
      };

      $scope.toggleExpanded = () => {
        $scope.expanded = !$scope.expanded;
      };

      //----------------------------------


      // ==================================
      // ===== Psych Assessment Evals =====
      // ==================================
      const PA_BO = PsychAssessmentBO;

      $scope.pa = {
        state: {
          // selectedActivityUuid             - selected activity in modal
          // selectedAcvitityDetailUuid       - selected activityDetail in modal
          // activityDetailListDisabled       - disabled state on activityDetail in modal
          // savedEvaluationActivities        - the set of all saved activities on the eval
          // currentEvaluationActivity        - the current evaluation activity in edit mode
          // onMouseoverActivity              - the activity option on mouseover in modal
        },
        form: {
          // isValidActivity                  - selection validation in modal
          // isValidActivityDetail            - selection validation in modal
          // isValidAssessmentComponentList   - list of saved eval assessments must be non-empty
          // clickedSubmit                    - user clicked save on the documentation form
        },
        data: {
          // activitySelectOptions            - used for dropdown in modal
          // activityDetailSelectionOptions   - used for dropdown in modal
        }
      };

      const PA_init = async () => {
        // get the static list of activities
        const activities = $scope.activities = await PA_BO.getActivities();
        $scope.pa.data.activitySelectOptions = activities.map(item => {
          return {label: item.name, value: item.uuid};
        });
        $scope.pa.state.activityDetailListDisabled = true;

        $scope.plRecordSPI = plRecordSPI;

        // is a psych assessment eval
        $scope.isEvalPsychAssessment = () => {
          return $scope.record.clientServiceModel &&
            $scope.record.clientServiceModel.service_expanded.code === 'eval_pa';
        };

        // use the SPI to send implementation functions across directive boundaries
        // from plRecord to lower components
        plRecordSPI.setIsPsychAssessmentFn($scope.isEvalPsychAssessment);
      };

      PA_init();

      // ------------------------------
      // modal window show/hide control
      // ------------------------------
      const clearFormValidation = () => {
        $scope.pa.form.isValidActivity = true;
        $scope.pa.form.isValidActivityDetail = true;
        $scope.pa.form.isValidAssessmentComponentList = true;
        $scope.pa.form.clickedSubmit= true;
      };

      const showPsychAssessmentComponentModal = () => {
        clearFormValidation();
        plOverlayModal.show('psychAssessmentComponentModal');
      };

      $scope.onClickAddAssessmentComponentButton = () => {
        showPsychAssessmentComponentModal();
      };

      $scope.onCloseAssessmentComponentModal = () => {
        $scope.pa.data.activityDetailSelectOptions = null;
        $scope.pa.state.selectedActivityUuid = null;
        $scope.pa.state.selectedActivityDetailUuid = null;
        $scope.pa.state.currentEvaluationActivity = null;
        clearFormValidation();
        plOverlayModal.hide('psychAssessmentComponentModal');
      };

      // ---------------------------------------------
      // functions for assessment component activities
      // within modal window
      // ---------------------------------------------
      const setActivityDetailOptions = () => {
        const activity
          = PA_BO.findActivityByUuid($scope.pa.state.selectedActivityUuid);

        // no activity can mean we cleared $scope.pa.state (when closing the modal)
        if (!activity) return;

        const activityDetails = activity.details_expanded;
        $scope.pa.state.activityDetailListDisabled = activityDetails.length === 0;
        if (activityDetails.length) {
          $scope.pa.data.activityDetailSelectOptions = activityDetails.map(item => {
            return {label: item.name, value: item.uuid};
          });
        } else {
          $scope.pa.data.activityDetailSelectOptions = null;
        }
      };

      $scope.onChangeSelectAssessmentComponent = () => {
        $scope.pa.state.selectedActivityDetailUuid = null;
        setActivityDetailOptions();
        clearFormValidation();
        $scope.pa.state.onMouseoverActivity = null;
      };

      $scope.onChangeSelectActivityDetail = () => {
        clearFormValidation();
      }

      const validateActivitySelectionForm = () => {
        const form = $scope.pa.form;
        const state = $scope.pa.state;
        const data = $scope.pa.data;
        form.isValidActivity = !!state.selectedActivityUuid;
        form.isValidActivityDetail =
          !form.isValidActivity
          ||
          (!data.activityDetailSelectOptions || state.selectedActivityDetailUuid);

        return form.isValidActivity && form.isValidActivityDetail;
      };

      const validateAssessmentComponentList = () => {
        const form = $scope.pa.form;
        const state = $scope.pa.state;
        return form.isValidAssessmentComponentList
          = state.savedEvaluationActivities.length > 0;
      }

      const saveActivitySelectionsFromModal = async () => {
        if (!validateActivitySelectionForm()) return;

        const eval_uuid = $scope.record.clientServiceModel.uuid;
        const activity = PA_BO.findActivityByUuid($scope.pa.state.selectedActivityUuid);
        const activityDetail = PA_BO.findActivityDetailByUuid($scope.pa.state.selectedActivityDetailUuid);

        // In the Edit flow, we set currentEvaluationActivity state
        const currentEvalActivity = $scope.pa.state.currentEvaluationActivity;
        const isEditFlow = !!currentEvalActivity;

        if (isEditFlow) {
          await PA_BO.editEvaluationActivity(
            eval_uuid,
            currentEvalActivity.evalActivityUuid
          );
          const index = $scope.pa.state.savedEvaluationActivities.findIndex(item => {
            return item.evalActivityUuid === currentEvalActivity.evalActivityUuid;
          });
          $scope.pa.state.savedEvaluationActivities[index]
            = {activity, activityDetail, evalActivityUuid: currentEvalActivity.evalActivityUuid};
        } else {
          const saveActivityResult = await PA_BO.saveActivityToEval(
            eval_uuid,
            activity.uuid,
            activityDetail ? activityDetail.uuid : null);
          $scope.pa.state.savedEvaluationActivities = [
            {activity, activityDetail, evalActivityUuid: saveActivityResult.uuid},
            ...$scope.pa.state.savedEvaluationActivities || []
          ];
        }

        $timeout();
        $scope.onCloseAssessmentComponentModal();
      };

      $scope.onClickSaveActivity = () => {
        saveActivitySelectionsFromModal();
      };

      $scope.onClickCancelSaveActivity = () => {
        $scope.onCloseAssessmentComponentModal();
      };

      $scope.onMouseoverActivityOption = (ev, activityUuid) => {
        $scope.pa.state.onMouseoverActivity = PA_BO.findActivityByUuid(activityUuid);
      };

      $scope.onMouseleaveActivityOption = (ev) => {
        $scope.pa.state.onMouseoverActivity = null;
      };

      // --------------------------------------------
      // evaluation activities action button handlers
      // --------------------------------------------
      $scope.onClickDeleteEvaluationActivity = async (evaluationActivityUuid) => {
        const eval_uuid = $scope.record.clientServiceModel.uuid;
        PA_BO.deleteEvaluationActivity(eval_uuid, evaluationActivityUuid);
        // locate and remove the entry from the view
        const index = $scope.pa.state.savedEvaluationActivities.findIndex(item => {
          return item.evalActivityUuid === evaluationActivityUuid;
        });
        $scope.pa.state.savedEvaluationActivities.splice(index, 1);
      };

      $scope.onClickEditEvaluationActivity = (evalActivity) => {
        $scope.pa.state.currentEvaluationActivity = evalActivity;
        $scope.pa.state.selectedActivityUuid = evalActivity.activity.uuid;
        setActivityDetailOptions();
        $timeout(()=>{
          $scope.pa.state.selectedActivityDetailUuid
            = evalActivity.activityDetail ? evalActivity.activityDetail.uuid : null;
        });
        $timeout(()=>{
          showPsychAssessmentComponentModal();
        }, 100);
      };

      // ---------------------------
      $scope.$on('$destroy', () => {
        plOverlayModal.destroy('psychAssessmentComponentModal');
      });

      init();
    },
  };
}

plRecord.$inject = ['plBillingCodes', 'plTimezone', 'plLocation', 'plRecordNotes',
  'currentUserModel', 'plClientServices', 'plRecord', 'plRecordSPI', 'PsychAssessmentBO', '$state',
  '$timeout', '$q', 'plHistory', 'plOverlayModal'];
module.exports = plRecord;
