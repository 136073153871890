var AssetsStateModel = function($log) {
    var state = {
        search : '',
        lastState : '',
        lastTag : ''
    };
    return state;
};

AssetsStateModel.$inject = ['$log'];
module.exports = AssetsStateModel;