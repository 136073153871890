/**
 * Manages access to protected digital content
 *
 * @param $log
 * @param $http
 * @param $q
 * @param firebaseAppModel
 * @param applications
 * @param dispatcherService
 * @constructor
 */
var RightsMgmtService = function($log, $http, $q, firebaseAppModel, applications, dispatcherService) {

    this.assets = {};
    this.rightsPromise = null;

    /**
     * Get the transient url to a protected asset given a key, assessment id, and user token. Clinician tokens
     * are their authentication jwt token. student tokens are fetched by clinicians and stored in firebase.
     *
     * A null token implies a call from a clinician who's token is already in the header.
     *
     * @param assessmentId
     * @param assetkey
     * @param token
     * @returns {*|Promise.<T>}
     */
    this.getProtectedContentUrl = function(assessmentId, assetkey, token, isLesson) {
        const service = isLesson? 'lesson' : 'assessment';
        let url = applications.platform.url + "/api/v1/" + service + "/" + assessmentId + "/download/";
        if (!isLesson) url += "?url=" + encodeURIComponent(assetkey);
        $log.debug("[RightsMgmtService] returning protected url: " + url);
        var config = {
            url: url,
            method: 'GET'
        };
        if (token != null && token !== "") {
            config['headers'] = {'Authorization': `JWT ${token}`}
        }
        return $http(config).then((data) => {
            this.assets[assetkey] = data.data.url;
            return this;
        });
    };

    /**
     * Ok here's what happens with this call....
     *
     * 1. If the student tries to get an asset url with a bad token (via getProtectedContentUrl), the call to
     * get the url returns a 401.
     * 2. The authinterceptor then catches that and invokes this method.
     * 3. This adds a listener to the firebaseAppModel and sets the token to null
     * 4. On the clinician side, that update will trigger the clinicians app to get a new token
     * 5. The clinician app will add the new token to firebaseAppModel
     * 6. Once that is complete the handler here will trigger with a non-null value
     * 7. When we have the new token we'll retry the original request again (see the handler below)
     *
     * (Or you know, we could instead log students into an account, check their perms,
     * return a valid token and avoid all of this.)
     * @param rightsPromise
     * @param response
     */
    this.resetCredentials = function(rightsPromise, response) {
        $log.debug("[RightsMgmtService] resetCredentials");
        this.rightsPromise = rightsPromise;
        this.response = response;
        dispatcherService.addListener('firebaseAppModelChange', null, this.handleFirebaseAppModelChange, this);
        firebaseAppModel.setRightsToken("");
    };

    this.handleFirebaseAppModelChange = function(eventType, event) {
        if (event.data.rightsToken != "" && event.data.rightsToken != undefined ) {
            this.response.config['headers'] = {'Authorization': `JWT ${event.data.rightsToken}`};
            $http(this.response.config).then(this.handleHttpResponse.bind(this));
        }
    };

    this.handleHttpResponse = function(data) {
        let assetkey = this.findKeyFromUrl(data.config.url);
        this.assets[assetkey] = data.data.url;
        this.rightsPromise.resolve(data);
        this.rightsPromise = null;
    };

    this.findKeyFromUrl = function(url) {
        $log.debug("[RightsMgmtService] parsing url: ", url);
        let assetkey = ""
        let queryindex = url.indexOf('?url=');
        if (queryindex !== -1) {
            assetkey = url.substring(queryindex+5)
        }
        $log.debug("[RightsMgmtService] found key: ", assetkey);
        return assetkey
    };

    /**
     * This basically sleeps the thread if we've already started one.
     * @returns {boolean}
     */
    this.isResetPending = function() {
        if (this.rightsPromise != null) {
            return true
        }
        return false
    };


    var init = function () {
        $log.debug("[RightsMgmtService] init");
    };
    init();

};
RightsMgmtService.$inject = ['$log', '$http', '$q', 'firebaseAppModel', 'applications', 'dispatcherService'];
module.exports = RightsMgmtService;