module.exports = angular.module('firebase-mock', [])
    .factory('firebaseMockFactory', require('./src/factory/firebase/FirebaseMockFactory'))
    .service('EventQueue', require('./src/service/firebase/EventQueue'))
    .service('DataStore', require('./src/service/firebase/DataStore'))
    .service('DataNode', require('./src/service/firebase/DataNode'))
    .service('firebaseMock', require('./src/service/firebase/FirebaseMock'))
    .service('Repo', require('./src/service/firebase/Repo'))
    .service('Snapshot', require('./src/service/firebase/Snapshot'))
    .service('Events', require('./src/service/firebase/Events'))
    .service('Assert', require('./src/service/Assert'))
    .service('Pushkey', require('./src/service/firebase/Pushkey'))
    .service('Reference', require('./src/service/firebase/Reference'))
;
