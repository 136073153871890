/*
    // http://stackoverflow.com/questions/5737975/circle-drawing-with-svgs-arc-path/10477334#10477334
    var pathProto = "M cx cy m -r, 0 a r,r 0 1,0 (r * 2),0 a r,r 0 1,0 -(r * 2),0";
*/
var CircularProgressBar = function($log, $timeout) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            progress : '='
        },
        templateUrl : '/toychest/common/directives/CircularProgressBar/circularprogressbar.tpl.html',
        link: function ($scope, $element, $attr) {
            $scope.width = 200;
            $scope.height= 200;
            $scope.textX = 140;
            $scope.textY = 160;
            $scope.cx = 100;
            $scope.cy = 100;
            $scope.outerRadius = 95;
            $scope.innerRadius = 90;
            $scope.r = ($scope.outerRadius - $scope.innerRadius)/2 + $scope.innerRadius;
            $scope.circleLength = 0;
            $scope.dashoffset = 0;

            var circlePath = $element.find('path');
            var label = $element.find('.label');

            // These need to be delayed until the svg is rendered
            $timeout(function() {
                $scope.circleLength = circlePath[0].getTotalLength();
            }, 0);

            $scope.$watch('progress', function(newVal, oldVal) {
                circlePath.addClass('animated');
                var currentOffset = $scope.circleLength - $scope.progress/100.0 * $scope.circleLength;
                $scope.dashoffset = currentOffset;

                var box = label[0].getBBox();
                var extraOffset = -10;
                if($scope.progress < 10){
                    extraOffset = 20;
                }
                // extra offset accounts for 2 digit or 1 digit percentages
                $scope.textX = $scope.width/2 - box.width/2 - extraOffset;
            });
        }
    }
};

CircularProgressBar.$inject = ['$log', '$timeout'];
module.exports = CircularProgressBar;