"use strict";
let MersenneTwister = require('mersenne-twister');

var Pushkey = function($log, Assert) {
    var pushkey = null;
    const DICTIONARY = "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz";
    const PUSHKEY_LENGTH = 20;
    const TIMESTAMP_LENGTH = 8;
    const RANDOM_LENGTH = 12;

    class Pushkey {
        static generate(){
            var pushkey = Pushkey.get();
            return pushkey.generate();
        }
        static get(){
            if(!pushkey){
                pushkey = new Pushkey();
            }
            return pushkey;
        }

        constructor(){
            this.lastPushTime = 0;
            this.lastRandChars = [];
            this._twister = new MersenneTwister();
        }
        clear(){
            this.lastPushTime = 0;
            this.lastRandChars = [];
        }
        seed(value){
            this._twister = new MersenneTwister(value);
        }

        //modified directly from firebase-debug.js
        generate(timestamp=null) {

            if(!timestamp){
                timestamp = new Date().getTime();
            }
            var duplicateTime = timestamp === this.lastPushTime;
            this.lastPushTime = timestamp;
            var timeStampChars = new Array(TIMESTAMP_LENGTH);
            for (var i = TIMESTAMP_LENGTH-1; i >= 0; i--) {
                timeStampChars[i] = DICTIONARY.charAt(timestamp % DICTIONARY.length);
                timestamp = Math.floor(timestamp / DICTIONARY.length);
            }
            Assert(timestamp === 0, "Cannot push at time == 0");
            var id = timeStampChars.join("");
            if(duplicateTime) {
                for (i = RANDOM_LENGTH-1; i >= 0 && this.lastRandChars[i] === DICTIONARY.length-1; i--) {
                    this.lastRandChars[i] = 0;
                }
                this.lastRandChars[i]++;
            }else{
                for (i = 0; i < RANDOM_LENGTH; i++) {
                    var rnd = this._twister.random_excl();
                    this.lastRandChars[i] = Math.floor(rnd * DICTIONARY.length);
                }
            }

            for (i = 0; i < RANDOM_LENGTH; i++) {
                id += DICTIONARY.charAt(this.lastRandChars[i]);
            }
            Assert(id.length === PUSHKEY_LENGTH, "nextPushId: Length should be 20.");
            return id;
        }
    };
    return Pushkey;
};
Pushkey.$inject = ['$log', 'Assert'];
module.exports = Pushkey;
