function plRecordView(plRecord, plClientServices, plRecordBO) {
    return {
        restrict: 'E',
        scope: {
            record: '=',
            user: '=',
        },
        replace: true,
        templateUrl: '/core/pl-records/src/plRecordView/plRecordView.tpl.html',
        link: ($scope) => {
            $scope.canViewRecord = () => {
              return !$scope.recordView.recordClientNameHidden;
            }
            function init() {
                $scope.recordView = plRecord.recordToView($scope.record, $scope.user);
                getClientServices($scope.record, $scope.user);
            }
            init();
            $scope.$watch('record', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal)) {
                    init();
                }
            });

            function getClientServices(record, user) {
                if (!$scope.canViewRecord()) {
                  return;
                }
                const filters = {
                    provider: user.uuid,
                };
                if (record.client_expanded) {
                    filters.client = record.client_expanded.uuid;
                }
                if (record.billing_code) {
                    filters.billing_code = record.billing_code;
                }
                plClientServices.get(filters)
                    .then((clientServices) => {
                        const clientService = plClientServices.getInfo($scope.record.client_service);
                        if (clientService && clientService.service_expanded) {
                          $scope.recordView.clientServiceName = clientService.service_expanded.name;
                        }
                    });
            }
        },
    };
}

plRecordView.$inject = ['plRecord', 'plClientServices', 'plRecordBO'];
module.exports = plRecordView;
