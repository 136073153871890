class plUploadPrompt {
    constructor(Upload, $timeout) {
        this._upload = Upload;
        this._$timeout = $timeout;
    }
    upload(file, url) {
        if(file.uploaded === true) {
            return;
        }
        file.progress = 0;
        return this._upload.upload({
            url: url,
            data: {file: file}
        }).then((resp) => {
            console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
            file.uploaded = true;
        }, (resp) => {
            console.log('Error status: ' + resp.status);
            file.error = resp.status;
        }, (evt) => {
            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            console.log('progress: ' + progressPercentage + '% ' + file.name);
            file.progress = progressPercentage;
        });
    }

    /**
     * A dummy uploader that updates the progress as if it was really uploading, but
     * actually does not make a network connection. Useful for testing
     * @param  {[type]} file [description]
     * @return {[type]}      [description]
     */
    mockUploadFile(file, count = 0) {
        if(file.uploaded === true) {
            return;
        }
        let sleep = 20;
        let limit = 100;
        file.progress = 0;
        this._$timeout(() => {
            count++;
            console.log('file.progress', file.progress);
            if(count <= limit) {
                this.mockUploadFile(file, count);
            } else {
                file.progress = 1; // 100%;
                file.uploaded = true;
            }
        }, sleep);
        file.progress = (count * sleep) / (limit * sleep);
        console.log('mockUploadFile', count, file.progress);
    }

    mockUploadFiles(files) {
        files.forEach(file => this.mockUploadFile(file));
    }

}
plUploadPrompt.$inject = ['Upload', '$timeout']
export default plUploadPrompt