"use strict";

ImageSelectionDirective.$inject = ['$q',
    '$timeout',
    'uploadImageService',
    'FlashcardsWizardService',
    'imageStorage',
    'currentUserModel'];

function ImageSelectionDirective($q,
                                 $timeout,
                                 UploadImageService,
                                 FlashcardsWizardService,
                                 imageStorage,
                                 currentUserModel) {
    return {
        restrict: 'E',
        templateUrl: '/toychest/common/directives/ImageSelectionDialog/ImageSelectionDirective.tpl.html',
        controller: function($attrs, $scope) {

        },
        controllerAs: "imageSelectionController",
        link: function ($scope, $element, $attrs) {

            var flashcard = FlashcardsWizardService.flashcard;

            var selected_cards = [];

            $scope.flashcard = flashcard;
            $scope.loadedImages = flashcard.images;

            $scope.failedUploads = [];
            $scope.awaitedUploadsCount = 0;

            $scope.model = {
                publicImages: false,
            }

            $scope.tabsConfig = {
                myImages: 'myImages',
                allImages: 'allImages',
                myComputer: 'myComputer',
                myUploads: 'myUploads',
                editUploads: 'editUploads',
                pcsSymbols: 'pcsSymbols',
            };

            $scope.activeTab = $scope.tabsConfig.allImages;
            $scope.showImageSelector = false;

            $scope.uploadedImagesModels = [];
            $scope.draggingCropImage = false;
            $scope.draggingScaleArea = false;
            $scope.cropImageOffsetX = 0;
            $scope.cropImageOffsetY = 0;
            $scope.selectImage = false;
            $scope.lastMouseX = 0;
            $scope.lastMouseY = 0;

            $scope.currentModel = null;

            $scope.canShareAssets = false;

            currentUserModel.getPermissions().then(permissions => {
                $scope.canShareAssets = permissions.includes('lightyear.share_asset');
            });

            var generateNormalUploadStrings = function () {
                $scope.uploadHeader = "DRAG & DROP";
                $scope.uploadFileTip = "Your files anywhere, or";
                $scope.uploadError = null;
            };

            var generateErrorUploadStrings = function () {
                $scope.uploadError = "error";
                $scope.uploadHeader = "OOPS!";
                $scope.uploadFileTip = "Drag & Drop or";
            };

            generateNormalUploadStrings();

            $scope.$watch('imageSelectionController.fileInputItems', function (files) {
                if (files) {
                    $scope.addImagesForUpload(files);
                }
            });

            $scope.backgroundClickDisabled = false;

            $scope.isCardChecked = function(image) {
                return _.findIndex(selected_cards, {id:image.id}) != -1;
            };

            function initImagesUploadCallback(models) {
                let max = getMaxId(flashcard.images);
                /**
                 * Required by
                 * https://presencelearning.atlassian.net/browse/PL-1599
                 * as well as changes in uploadImagesAfterSelection()
                 */

                var copiedImagesIndices = [];

                _.forEach(models, function (model) {
                    for (var i = 0; i < flashcard.images.length; i++) {
                        if (flashcard.images[i].filename == model.filename) {
                            copiedImagesIndices.push(i);
                        }
                    }
                });

                for (var i = copiedImagesIndices.length - 1; i >= 0; i--) {
                    flashcard.images.splice(copiedImagesIndices[i], 1);
                }

                /**/

                flashcard.images.push.apply(flashcard.images, models.map(function(card){
                    let newCard = {
                        id: ++max,
                        title: '',
                        url: '',
                        thumbnail_url: '',
                        filename: ''
                    };

                    newCard.title = card.title;
                    newCard.filename = card.filename;
                    newCard.cropped = imageStorage.retrieveSquaredImage(card.squaredImageStorageId)[0].src;
                    newCard.showProgress = true;
                    newCard.link = card;
                    return newCard;
                }));
            }

            function initImageUploadCallback(models) {
                let model = models[0];

                $scope.activeAssets[0].cropped = imageStorage.retrieveSquaredImage(model.squaredImageStorageId)[0].src;
                $scope.activeAssets[0].showProgress = true;
                $scope.activeAssets[0].link = model;
                $scope.$digest();

                if (models.length > 1) {
                    initImagesUploadCallback(models.slice(1));
                }
            }

            function createInitImagesUploadCallback() {
                if($scope.selectImage) {
                    return initImageUploadCallback;
                } else {
                    return initImagesUploadCallback;
                }
            }

            function createImagesUploadCallback(deferred) {
                return function (models) {
                    _.forEach(models, function(card) {
                        let index = _.findIndex($scope.loadedImages, {link:card});

                        if(index != -1) {
                            let oldCard = $scope.loadedImages[index];
                            oldCard.url = card.download_url;
                            oldCard.thumbnail_url = card.download_url;
                            delete oldCard.showProgress;
                            delete oldCard.link;
                        }
                    });

                    $scope.awaitedUploadsCount--;
                    if ($scope.awaitedUploadsCount === 0) {
                        checkFailedUploads();
                    }

                    deferred.resolve(models);

                    $scope.$eval($attrs["onsuccess"], { $models: models });
                }
            }

            function createImagesUploadFailedCallback(deferred) {
                return function (failedModels) {
                    _.forEach(failedModels, function(card) {
                        $scope.failedUploads.push(card);
                        let index = _.findIndex($scope.loadedImages, {link:card});

                        $scope.loadedImages.splice(index, 1);
                        $scope.uploadedImagesModels = failedModels.filter(function (model) {
                            return !model.uploadFailed;
                        });
                    });

                    $scope.awaitedUploadsCount--;
                    if ($scope.awaitedUploadsCount === 0) {
                        checkFailedUploads();
                    }
                    deferred.resolve(failedModels);
                }
            }

            function checkFailedUploads() {
                if ($scope.failedUploads.length) {
                    let failList = $scope.failedUploads.map(upload => upload.filename).join(', ');
                    $scope.failuresMessage = 'Some images could not be uploaded successfully:\n' + failList;
                    $scope.showFailuresConfirmPopup = true;
                }
            }

            $scope.isVertical = function (model) {
                if (model && model.getOriginalImage()) {
                    return model.getOriginalImage().height > model.getOriginalImage().width;
                } else {
                    return false;
                }
            };

            $scope.displayDropError = function(error) {
                UploadImageService.clearImageData();
                $scope.invalidFileNames = [];
                $scope.show($scope.tabsConfig.myComputer);
                generateErrorUploadStrings();
                $scope.$evalAsync();
            };

            $scope.show = function (type) {
                $scope.activeTab = type;
                $scope.searchQuery = '';
                $scope.setAssetsListByTab(type);
                $scope.assets_list.search($scope.searchQuery);
            };

            $scope.addImagesForUpload = function (files) {
                $scope.uploadError = null;
                $scope.invalidFileNames = [];
                $scope.uploadedImagesModels = [];

                UploadImageService.initImageUploads(files, $scope.model.publicImages).then(function initUploadedFiles() {
                    let models = UploadImageService.getModels();
                    $scope.awaitedUploadsCount = models.length;
                    $scope.failedUploads = [];
                    var hasInvalidFiles = false;

                    _.forEach(models, function (model) {
                        if (!model.valid) {
                            hasInvalidFiles = true;
                            $scope.invalidFileNames.push(model.filename);
                            $scope.uploadError = "error";
                        }
                    });

                    $scope.uploadedImagesModels = models.filter(function (model) {
                        return model.valid;
                    });

                    if (!$scope.uploadedImagesModels.length) {
                        UploadImageService.clearImageData();
                        $scope.invalidFileNames = [];
                        $scope.show($scope.tabsConfig.myComputer);
                        generateErrorUploadStrings();
                        $scope.$evalAsync();

                        return false;
                    }

                    if (hasInvalidFiles) {
                        $scope.$evalAsync();
                    }

                    _.forEach($scope.uploadedImagesModels, function (model) {

                        var distHeight;
                        if ($scope.isVertical(model)) {
                            distHeight = model.getOriginalImage().height * (150 / model.getOriginalImage().width);

                            model.setInitialSelectionDimension(150);
                            model.setBoundingBoxForSelection({width: 150, height: distHeight});
                            model.setMaximumOffsets(150 - model.selectionWidth, distHeight - model.selectionHeight);
                        } else {
                            distHeight = model.getOriginalImage().height * (180 / model.getOriginalImage().width);

                            model.setInitialSelectionDimension(distHeight);
                            model.setBoundingBoxForSelection({width: 180, height: distHeight});
                            model.setMaximumOffsets(180 - model.selectionWidth, distHeight - model.selectionHeight);
                        }
                        if($scope.maxCardTitleLength) {
                            model.title = model.title.substr(0, $scope.maxCardTitleLength);
                        }
                    });

                    angular.forEach(angular.element("input[type='file']"),
                        function(inputElem) {
                            angular.element(inputElem).val(null);
                        });

                    $scope.$evalAsync();
                }, function(err) {
                    console.warn(err);
                });

                $scope.show($scope.tabsConfig.editUploads);
            };

            $scope.uploadImagesAfterSelection = function () {
                var deferred = $q.defer();

                /**
                 * Required by
                 * https://presencelearning.atlassian.net/browse/PL-1599
                 * as well as changes in initImagesUploadCallback(models)
                 */

                var myAssets = $scope.assets_list.assets;

                _.forEach($scope.uploadedImagesModels, function (model) {
                    for (var i = 0; i < myAssets.length; i++) {
                        var asset = myAssets[i];

                        if (asset.filename == model.filename) {

                            /**
                             * Direct call for FlashcardsWizardServiceInstance.getMyAssetsList()
                             * doesn't return expected array - it misses last actual uploads.
                             * So we use currentUserModel directly
                             */

                            if (asset.owner == currentUserModel.user.username) {
                                asset.$delete();
                            }
                        }
                    }
                });

                /**/

                UploadImageService.startImageUploads(
                    createInitImagesUploadCallback(),
                    createImagesUploadCallback(deferred),
                    createImagesUploadFailedCallback(deferred)
                );

                $scope.invalidFileNames = [];
                $scope.uploadError = null;
                $scope.closeImageSelector();
                $scope.show($scope.tabsConfig.myComputer);

                $scope.uploadedImagesModels = [];
                return deferred.promise;
            };

            $scope.openSelectImage = function() {
                $scope.selectImage = true;
                $scope.showImageSelector = true;
            };

            $scope.chooseImage = function(selected_card) {
                $scope.chooseImageSilently(selected_card);
                $timeout(function() {}, 0);
            };

            $scope.chooseImageSilently = function(selected_card) {
                $scope.activeAssets[0].url = selected_card.download_url;

                /*
                    Fix for PL-2723. It seems that thumbnail_url is being set
                    to "" now for newly uploaded image models or else, so we need to check it.
                    May be considered as workaround but I don't see any
                    significant difference between purposes of thumbnail_url
                    and download_url.
                 */
                $scope.activeAssets[0].thumbnail_url =
                    selected_card.thumbnail_url && selected_card.thumbnail_url !== ""
                    ? selected_card.thumbnail_url
                    : selected_card.download_url;
                /* End of fix */

                delete $scope.activeAssets[0].cropped;
            };

            $scope.getImageUrl = function(image) {
                if (image.thumbnail_url) {
                    return image.thumbnail_url;
                } else {
                    return image.download_url;
                }
            };

            $scope.pushCardToSelected = function(selected_card) {
                selected_cards.push(selected_card);
            };

            $scope.selectedCardIndex = function(selected_card) {
                return _.findIndex(selected_cards, {id:selected_card.id});
            };

            $scope.pullCardFromSelected = function(index) {
                selected_cards.splice(index, 1);
            };

            $scope.imageTagAdded = function(model, tag) {
                model.$addTag(tag);
            };

            $scope.imageTagRemoved = function(model, tag) {
                model.$removeTag(tag);
            };

            $scope.openImageSelector = function () {
                $scope.showImageSelector = true;
            };

            $scope.closeImageSelector = function () {
                $scope.showImageSelector = false;

                if ($scope.selectImage) {
                    selected_cards = selected_cards.slice(1);
                }

                $scope.selectImage = false;

                let max = getMaxId(flashcard.images);

                flashcard.images.push.apply(flashcard.images, selected_cards.map(function(card){
                    let newCard = {
                        id: ++max,
                        title: '',
                        url: '',
                        thumbnail_url: ''
                    };

                    newCard.title = card.title;
                    newCard.url = card.download_url;
                    newCard.thumbnail_url = card.thumbnail_url;

                    return newCard;
                }));

                selected_cards = [];
                //$scope.pushSelectedCardsToState(cards);
            };

            /** Clear list of selected images models after closing popup */
            $scope.$watch('showImageSelector', function (showImageSelector) {
                if(!showImageSelector) {

                    if (!UploadImageService.isUploading) {
                        UploadImageService.clearImageData();
                        $scope.invalidFileNames = [];
                        $scope.uploadError = null;
                        generateNormalUploadStrings();
                        $scope.$evalAsync();

                        $scope.$eval($attrs["oncancel"]);
                    }

                    $scope.selectImage = false;
                    selected_cards = [];

                    $scope.fileInputItems = [];
                    $scope.uploadedImagesModels = [];
                    // Wait for the popup to close and set default tab
                    setTimeout(() => $scope.show($scope.tabsConfig.allImages), 1000);
                }
            });

            $scope.fileInputItems = [];
            $scope.invalidFileNames = [];

            $scope.currentDraggedModel = null;

            function getMaxId(images) {
                let max = 0;

                for (let image of images) {
                    max = Math.max(max, image.id);
                }

                return max;
            }
            //end link
        }
    }
};

module.exports = ImageSelectionDirective;
