var SharedCursorDirective = function ($log, $timeout, $compile, firebaseModel, AssessmentModel, guidService, localStorageService) {
    return {
        restrict: 'E',
        templateUrl: '/core/toys/app/src/toys/common/directives/sharedcursor/sharedcursor.tpl.html',
        link: function ($scope, $element, $attr) {

            let lastClick = 0;
            const THREE_SECONDS = 3000;

            // TODO - it looks like these "magic" OFFSET constants may come from the size of a sonar ping. Rather than
            // applyn the offsets to the hand, maybe they should apply to the sonar ping placing instead.
            const MY_X_OFFSET = 50;
            const MY_Y_OFFSET = 6;
            const parent = $element.parent();
            var myguid;

            let fbTimeDelta = 0;

            function addSonarLocal(snapshot) {
                if (snapshot.val() ) {
                    $timeout.cancel(timeoutPromise);
                    hideCursor();
                    let sonarData = snapshot.val();

                    if (sonarData.lastclick !== lastClick && !isOld(sonarData.lastclick)) {
                        lastClick = sonarData.lastclick;

                        let x = parent[0].clientWidth * sonarData.percentX;
                        let y = parent[0].clientHeight * sonarData.percentY;

                        let sonar = $compile("<sonar/>")($scope);
                        sonar.offset({left: x , top: y});
                        $element.parent().append(sonar);
                    }
                }
            };

            function loadState() {
                if (AssessmentModel.share) {
                    //setup firebase refs
                    $scope.cursorRef = firebaseModel.getFirebaseRef('activities/sessions/' + AssessmentModel.getSessionId() + "/" + AssessmentModel.activity.configId + "/cursor/" + $element.attr('guid'));
                    $scope.cursorRef.on("value", handleCursorMove, handleCursorError);

                    $scope.sonarRef = firebaseModel.getFirebaseRef('activities/sessions/' + AssessmentModel.getSessionId() + "/" + AssessmentModel.activity.configId + "/sonar/" + $element.attr('guid'));
                    $scope.sonarRef.on("value", addSonarLocal, handleCursorError);

                    $scope.deltaRef = firebaseModel.getFirebaseRef('activities/sessions/' + AssessmentModel.getSessionId() + "/" + AssessmentModel.activity.configId + "/timedelta/");
                    $scope.deltaRef.once("value", readFBServerTime, handleCursorError);
                    $scope.deltaRef.set({time: firebase.database.ServerValue.TIMESTAMP});
                }
            };

            function readFBServerTime(snapshot) {
                fbTimeDelta = Date.now() - snapshot.val().time;
            }

            function handleCursorError(error) {
                $log.debug("Shared cursor error:" + error.code);
            };

            function handleCursorMove(snapshot) {
                if (snapshot.val() && snapshot.key !== myguid) {

                    var cursorData = snapshot.val();

                    if (!isOld(cursorData.lastMoved)) {
                        var rect = parent.offset();
                        var newX = cursorData.x * parent[0].clientWidth + rect.left - MY_X_OFFSET;
                        var newY = cursorData.y * parent[0].clientHeight + rect.top - MY_Y_OFFSET;

                        $element.show();

                        $element.offset({top: newY, left: newX});

                        $timeout.cancel(timeoutPromise);
                        hideCursor();
                    }
                }
            };

            function isOld(lastMoved) {
                return Date.now() - fbTimeDelta - lastMoved > THREE_SECONDS;
            }

            let timeoutPromise;
            var hideCursor = _.debounce(function () {
                timeoutPromise = $timeout(function () {
                    $element.fadeOut();
                }, 3000);
            }, 1000);


            function initialize() {
                myguid = localStorageService.get('user_guid');

                if (myguid === null) {
                    myguid = guidService.generateUUID();
                    localStorageService.set('user_guid', myguid);
                }

                loadState();
            }

            initialize();
        }
    }
};

SharedCursorDirective.$inject = ['$log', '$timeout', '$compile', 'firebaseModel', 'AssessmentModel', 'guidService', 'localStorageService'];
module.exports = SharedCursorDirective;
