/**
TODO
- mobile (test & fix)
    - date
        - android
            - default value in picker dialog is wrong first time (if set initially).
            - input styling - text is too high.
        - ios
    - datetime (both android & ios)
*/

const Pikaday =require('pikaday-time');

function plInputDateTimeDirective(plInputService, $timeout) {
    function featureDetect() {
        let features = {
            mobile: false,
            deviceType: '',
        };
        //mobile - from http://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
        if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) ||
         navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) ||
         navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) ||
         navigator.userAgent.match(/Windows Phone/i)) {
            features.mobile = true;
            if (navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) ||
             navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i)) {
                features.deviceType = 'ios';
            }
            if (navigator.userAgent.match(/Android/i)) {
              features.deviceType = 'android';
            }
        }
        return features;
    }

    return {
        restrict: 'E',
        require: '?^form',
        scope: {
            model: '=',
            pikadayOpts: '=?',
            disabled: '=?',
            required: '=?',
            min: '@?',
            max: '@?',
            blur: '&?',
            focus: '&?',
            change: '&?',
            name: '@?',
            label: '@?',
            iconRight: '@?',
            placeholder: '@?',
            // 'date' or 'datetime'
            type: '@?',
            // 'birthdate'
            subType: '@?',
            formatModel: '@?',
            formatDisplay: '@?',
            minuteIncrement: '@?',
            allowTypedInput: '@?',
        },
        replace: true,
        templateUrl: '/core/pl-inputs/src/plInputDateTime/plInputDateTime.tpl.html',
        link: ($scope, $element, $attrs, formCtrl) => {
            plInputService.createNameIfNone($scope);
            plInputService.setupOnChange($scope, $attrs);
            plInputService.setupFocusAndBlur($scope, $attrs);
            plInputService.addInputToFormForValidation($scope, $element, formCtrl);

            $scope.hideInput = ($scope.pikadayOpts && !$scope.pikadayOpts.bound &&
             $scope.pikadayOpts.container) ? true : false;
            $scope.type = $scope.type || 'datetime';
            // For both pikaday and mobile want to be a text type.
            $scope.typeToUse = 'text';
            const features = featureDetect();

            const formatModel = $scope.formatModel ||
             (($scope.type ==='date') ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ssZ');
            let formatDisplay = $scope.formatDisplay ||
             (($scope.type ==='date') ? 'MMM D, YYYY' : 'MMM D, YYYY h:mmA');
            // datetime-local now so no timezone (including it will not properly set the input (default) value)
            let inputFormatMobile = false;
            if (features.mobile) {
                inputFormatMobile = ($scope.type === 'date') ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm:ss';
            }
            if (features.mobile && features.deviceType === 'android') {
              formatDisplay = inputFormatMobile;
            }

            let inited = false;
            // Prevent a skip watch on initialization; only meant to prevent changes from user input.
            // This is here because sometimes default values are loaded asynchronously and we
            // want to ensure the model gets these changes.
            $timeout(() => {
                inited = true;
            }, 1000);
            // To prevent the $watch from firing when date is selected from
            // within the picker.
            let skipWatch = false;
            function skipWatcher() {
                if (inited) {
                    skipWatch = true;
                    $timeout(() => {
                        skipWatch = false;
                    }, 150);
                }
            }

            // It's the 1st input.
            const $input = $element.find('input');
            // Hacky way to prevent typing in the input, which can lead to non-validated
            // (bad) dates - just clear it on keyup.
            const input = $input[0];
            let rawValue = '';
            if (!$scope.allowTypedInput) {
                // Blur on escape button press (mostly for use in e2e testing).
                $input.on('keydown', event => {
                    if (event.which === 27) {
                        $input.blur();
                    }
                });
                // If typed value does not (exactly) match the display format, blank it out.
                // This avoids bad values.
                $input.on('change', (evt) => {
                    let value = moment(input.value, formatDisplay, true);
                    if (!value.isValid()) {
                        input.value = '';
                        $scope.model = '';
                    }
                });
            }

            let picker = false;
            if (!features.mobile) {
                let pikadayOpts = angular.extend({}, $scope.pikadayOpts, {
                    field: input,
                    onSelect: function() {
                        skipWatcher();
                        $scope.model = this.getMoment().format(formatModel);
                    },
                    format: formatDisplay,
                    reposition: false,
                    showTime: ($scope.type === 'date') ? false : true,
                    // To change ONE field (months to display abbreviations), need to overwrite all fields.
                    i18n: {
                        previousMonth : 'Previous Month',
                        nextMonth     : 'Next Month',
                        months        : ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
                        weekdays      : ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
                        weekdaysShort : ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'],
                        midnight      : 'Midnight',
                        noon          : 'Noon'
                    },
                });

                // If readonly or disabled, create a hidden element to attach trigger to for preventing changing value.
                if ($scope.disabled) {
                    const newInput = document.createElement('input');
                    newInput.type = 'hidden';
                    $element[0].parentNode.appendChild(newInput);
                    pikadayOpts.trigger = newInput;
                }
                const formatMinMax = 'YYYY-MM-DD';
                if ($attrs.min) {
                    pikadayOpts.minDate = moment($attrs.min, formatMinMax).toDate();
                }
                if ($attrs.max) {
                    pikadayOpts.maxDate = moment($attrs.max, formatMinMax).toDate();
                }
                if ($scope.minuteIncrement) {
                    pikadayOpts.incrementMinuteBy = parseInt($scope.minuteIncrement, 10);
                }
                if ($scope.subType === 'birthdate') {
                  const now = moment();
                  pikadayOpts.maxDate = now.toDate();
                  pikadayOpts.yearRange = [1900, now.year()];
                }

                picker = new Pikaday(pikadayOpts);
            } else if (features.deviceType === 'android') {
              $input.css({'padding-top':'7px'});
            }

            function setValue(value) {
                if (features.mobile) {
                    if (!value) {
                        input.value = value;
                    } else {
                        input.value = moment(value, formatDisplay).format(inputFormatMobile);
                    }
                } else {
                    if (!value) {
                        $scope.modelPicker = value;
                    } else {
                        picker.setMoment(moment(value, formatDisplay));
                    }
                }
            }

            // Set initial value.
            if ($scope.model) {
                // Convert from non-display value to display value.
                const value = moment($scope.model, formatModel).format(formatDisplay);
                setValue(value);
            }

            $scope.$watch('model', (newVal, oldVal) => {
                if (!skipWatch && !angular.equals(oldVal, newVal)) {
                    // Convert from non-display value to display value.
                    let value = moment(newVal, formatModel, true);
                    if (!value.isValid()) {
                        value = '';
                    } else {
                        value = value.format(formatDisplay);
                    }
                    setValue(value);
                }
            });

            if (features.mobile) {
                // For datetime-local input type, we get an "invalid date" value if not formatted correctly on init
                // and that blanks out our value so to work around this, we just use a "text" type input and then
                // dynamically change it AFTER setting to the PROPERLY FORMATTED date / datetime in javascript.
                $scope.typeToUse = ($scope.type === 'date') ? 'date' : 'datetime-local';
            }

            // state classes
            $scope.getStateClasses = function() {
                return {
                    focused: $scope.focused,
                    disabled: $scope.disabled,
                    invalid: plInputService.isTextStyleInputInvalid($scope, formCtrl),
                    // For iOS, make sure inputs are not super small height when they have no value yet.
                    ios: (features.deviceType === 'ios') ? 'ios' : '',
                    'hide-input': $scope.hideInput,
                };
            };

            function wrapSelect() {
                // Add wrapper for styling time select boxes
                if ($scope.type !=='date') {
                    $(".pika-select-hour").wrapAll(
                        "<div class='pl-input-date-time-select pl-input-select'>" +
                        "<div class='select-button'></div></div>");
                    $(".pika-select-minute").wrapAll(
                        "<div class='pl-input-date-time-select pl-input-select'>" +
                        "<div class='select-button'></div></div>");

                    // Move the select dropdown
                    $('.pl-input-date-time-select-icon').clone().appendTo('.pl-input-date-time-select');
                }
            }

            function styleDom() {
                wrapSelect();
            }

            input.focus = function() {
                styleDom();
            };

            input.onchange = function() {
                const date = input.value;
                if (features.mobile) {
                    $timeout(() =>  {
                        skipWatcher();
                        // Convert from input value format to the format we want
                        // (use the display format for consistency with Pikaday, even
                        // though we will NOT actually change the display value the user sees).
                        if ($scope.type === 'date') {
                            $scope.model = moment(date, inputFormatMobile).format(formatDisplay);
                        } else {
                            let dateMoment;
                            // let tzFromMinutes = false;
                            // Assume javascript date object.
                            if (typeof(date) == 'object') {
                                dateMoment = moment(date);
                            // Assume Android, which apparently gives YYYY-MM-DDTHH:mmZ format..
                            } else if(typeof(date) == 'string') {
                                dateMoment = moment(date, 'YYYY-MM-DD HH:mm');
                                // if (date.indexOf('Z') > -1) {
                                //     tzFromMinutes = 0;
                                // }
                            }

                            // Convert to local timezone (so it matches what the user actually selected).
                            // TODO - convert (OR don't need to? Works fine on Android - test iOS).
                            // var format1 ='YYYY-MM-DD HH:mm:ssZ';
                            // var dtInfo =afDatetimepicker.convertTimezone(dateMoment, tzFromMinutes, false, {'format':format1});

                            // Update input value with non UTC value.
                            input.value = dateMoment.format(inputFormatMobile);
                            $scope.model = dateMoment.format(formatModel);
                        }
                    }, 25);
                } else {
                    // On desktop they could manually type / change the value rather than using the datepicker so handle this case.
                    // Or just disable the input to force using the picker?
                    if (!moment(date, formatDisplay, true).isValid()) {
                        skipWatcher();
                        input.value = '';
                        $scope.model = '';
                    }
                }
            };

            $element.on('$destroy', () => {
                if (picker && picker.destroy) {
                    picker.destroy();
                }
            });
        },
    };
}

plInputDateTimeDirective.$inject = ['plInputService', '$timeout'];
module.exports = plInputDateTimeDirective;
