var FileInputItems = function() {
    return {
        restrict: 'A',
        scope: {
            fileInputItems: '='
        },
        link: function($scope, $element, $attr) {
            $element.on('change', function(event) {
                $scope.$evalAsync(function SetFileInputItems() {
                    $scope.fileInputItems = Array.prototype.slice.apply(event.target.files);
                });
            });
        }
    }
};

FileInputItems.$inject = [];
module.exports = FileInputItems;
