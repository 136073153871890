import RoomResolver from 'pl-core/src/resolvers/RoomResolver';

function AuthenticatedUserResolver($q, $state, currentUserModel, plUser, API, $location, plAppGlobal, pendoLoggar) {
  return plUser($q, currentUserModel, API, $location, (user) => {
    plAppGlobal.setUser(user);
    pendoLoggar.setUser(user, plAppGlobal.isHijacked);
    return {isRouteAuthorized: true};
  }, true);
}


function resolveFirebaseUser(firebaseModel) {
    return firebaseModel.login();
}

function Routes($stateProvider) {
    $stateProvider
        .state('app.store', {
            url: '',
            // abstract: true,
            views: {
                content: {
                    templateUrl: '/toychest/modules/store/store.tpl.html',
                    controller: 'StoreController as storeCtrl',
                },
            },
            resolve: {
                Room: RoomResolver,
                CurrentUser: ['$q', '$state', 'currentUserModel', 'plUser', 'API', '$location', 'plAppGlobal', 'pendoLogger',
                    AuthenticatedUserResolver ],
                FirebaseUser: ['firebaseModel', 'Room', resolveFirebaseUser]
            },
        })
        .state('app.store.invalid', {
            url: '/invalid',
            views: {
                content: {
                    templateUrl: '/toychest/modules/store/invalid/invalid_activity.tpl.html',
                    controller: 'InvalidActivityController',
                },
            },
        });
}
Routes.$inject = ['$stateProvider'];
module.exports = Routes;
