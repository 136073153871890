function ApplicationRoutes($stateProvider) {
    $stateProvider
        .state('applicationsConfig', {
            url: '/config',
            templateUrl: '/core/pl-core/src/modules/applications/application-config.tpl.html',
            controller: 'ApplicationConfigController as config',
        })
}

ApplicationRoutes.$inject = ['$stateProvider'];
export default ApplicationRoutes;