/**
Usage:
Give the element an id, which is then used with the associated service to
 `plOverlayModal.show()` or `plOverlayModal.hide()` by passing that id.
The `headerText` scope paramater is optional and add a header, close button, and body.

html:
<pl-overlay-modal-directive id="{{ repeatModalId }}" header-text="repeatModalHeaderText">
    <div>Repeat Modal</div>
</pl-overlay-modal-directive>

javascript:
$scope.repeatModalId = 'someIdHere';
$scope.repeatModalHeaderText = 'My Header Text';
plOverlayModal.show($scope.repeatModalId);
*/
function plOverlayModalDirective(plOverlayModal) {
    return {
        restrict: 'E',
        scope: {
            headerText: '=?',
            onClose: '&?',
            backgroundClose: '=?',
            align: '=?',
        },
        transclude: true,
        templateUrl: '/core/pl-overlays/src/plOverlayModal/plOverlayModal.tpl.html',
        link: ($scope, $element, $attrs) => {
            $scope.align = ($attrs.align !== undefined) ? $attrs.align : 'center';
            const backgroundClose = ($scope.backgroundClose !== undefined) ? $scope.backgroundClose : true;
            $element.addClass('pl-overlay-modal');
            angular.element("body").append($element);

            const content = $element.find('.pl-overlay-modal-content');

            $scope.clickBackground = (evt) => {
                if (backgroundClose && content.is(evt.target) === false && content.has(evt.target).length === 0) {
                    $scope.close();
                }
            };

            $scope.close = () => {
                const eleId = $element.attr('id');
                plOverlayModal.hide(eleId);
                if ($scope.onClose) {
                    $scope.onClose();
                }
            };
        },
    };
}

plOverlayModalDirective.$inject = ['plOverlayModal'];
module.exports = plOverlayModalDirective;
