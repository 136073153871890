var Routes = function($stateProvider) {
    $stateProvider
        .state('app.store.activities', {
            url: '/activities',
            abstract: true,
            views: {
                content: {
                    templateUrl: '/toychest/modules/store/activities/activities.tpl.html',
                    controller: 'ActivitiesController as activitiesCtrl'
                }
            }
        })
        .state('app.store.activities.list', {
            url: '/{listType:|all|my_activities|recent|favorites|trash}/{section:|pldrl}?q&type&tag&sort',
            views: {
                main: {
                    templateUrl: '/toychest/modules/store/activities/activities.list.tpl.html',
                    controller: 'ActivitiesListController as listCtrl'
                }
            }
        })
        .state('app.store.activities.featured', {
            url: '',
            views: {
                main: {
                    templateUrl: '/toychest/modules/store/activities/activities.featured.tpl.html',
                    controller: 'ActivitiesFeaturedController as featuredCtrl'
                }
            }
        })
        .state('app.store.activities.detail', {
            url: '/detail/:slug',
            params: {
                prevState: null
            },
            views: {
                main: {
                    templateUrl: '/toychest/modules/store/activities/activities.detail.tpl.html',
                    controller: 'ActivityDetailController as detailCtrl'
                }
            },
            resolve: {
                // Current user is added to ensure that the activity is retreived using the
                // correct credentials. Some fields such as favorite rely on this.
                Activity: ['$stateParams', 'activityResolver', 'CurrentUser',
                    function ($stateParams, activityResolver, CurrentUser) {
                        return activityResolver.resolve($stateParams);
                    }
                ],
                PreviousState: [
                    '$state',
                    '$stateParams',
                    function($state, $stateParams) {
                        var currentStateData = $stateParams.prevState || {
                            name: $state.current.name,
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }
                ]
            }
        })
};

Routes.$inject = ['$stateProvider'];
module.exports = Routes;