var FlashcardModel = function($log, $q, $http, $timeout, drfModel, applications) {

    var Flashcard = new drfModel({
        apiUrl: `${applications.platform.url}/api/v1/activity/?type=flashcard`,
    });

    this.ready = false;

    Object.assign(Flashcard.Model.prototype, {

        _doFormUpload: function(file, manifest) {
            var deferred = $q.defer();

            var form = new FormData();
            _.each(manifest, function(value, key) {
                form.append(key, value);
            });
            form.append("file", file);

            var _oXHR = new XMLHttpRequest();
            this._oXHR = function () {
                "use strict";
                return _oXHR;
            };
            this._oXHR().upload.addEventListener('progress', _.bind(this._uploadProgress, this), false);
            this._oXHR().upload.addEventListener('load', _.bind(function(obj) {
                //uploading done
                this['status'] = 'complete';
                this._progress = 100;
                this.$save().then(_.bind(function() {
                    return deferred.resolve(this)
                }, this));

            }, this), false);
            this._oXHR().addEventListener('error', this._uploadError, false);
            this._oXHR().addEventListener('abort', this._uploadAbort, false);

            this._oXHR().open('POST', this.upload_url, true);
            this._oXHR().send(form);

            return deferred.promise;
        },
        $upload: function(file) {

            var deferred = $q.defer();
            var promise = deferred.promise;
            // save the data in a data url so we can render it when it's done uploading
            var reader = new FileReader();
            reader.onloadend = _.bind(function() {
                if (file['type'].indexOf("image") >= 0) {
                    this._dataUrl = reader.result;
                } else {
                    //placeholderimg
                    this._dataUrl = "https://cdn3.iconfinder.com/data/icons/document-icons-2/30/647716-pdf-512.png";
                }
            }, this);
            reader.readAsDataURL(file);

            //can change this back
            if (file['type'].indexOf('pdf') >= 0) {
                this.file_type = 'document';
            } else if (file['type'].indexOf('jpeg') >= 0) {
                this.file_type = 'image';
            } // was jpeg
            else if (file['type'].indexOf('png') >= 0) {
                this.file_type = 'image';
            } // png
            else if (file['type'].indexOf('gif') >= 0) {
                this.file_type = 'image';
            } //gif
            else {
                this.file_type = 'image';
            }
            this.filename = file['name'];
            this.title = file['name']
                // remove - or _ from string
                .replace(/[-_]/g, ' ')
                // remove the file type extension
                .replace(/\.\w{3,4}$/, '')
                // capitalize all words
                .replace(/\w\S*/g, function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });
            this.size = parseFloat(parseInt(file['size']) / 1000000).toFixed(2);
            this._progress = 0;
            this.tags = [];
            this.private = false;
            this.mime_type = file.type;
            var self = this;

            // audio & video types will be added later
            if (this.file_type === 'document') {
                this.$save()
                    .then(_.bind(function(obj) {
                        this.upload_url = obj.upload_url;
                        this.ready = true;
                        this._doFormUpload(file, obj.manifest).then(_.bind(function(obj) {
                            return deferred.resolve(this);
                        }, this))
                    }, this));
            } else {
                var fileReader = new FileReader;

                fileReader.onload = function() {
                    var img = new Image;

                    img.onload = function() {
                        self.width = img.width;
                        self.height = img.height;
                        self.$save()
                            .then(_.bind(function(obj) {
                                this.upload_url = obj.upload_url;
                                this.ready = true;
                                this._doFormUpload(file, obj.manifest)
                            }, self));
                    };
                    img.src = fileReader.result;
                };
                fileReader.readAsDataURL(file)
            }

            return promise;
        },
        _uploadError: function(e) {
            $log.debug(e);
        },
        _uploadAbort: function(e) {
            $log.debug(e);
        },
        _uploadProgress: function(e) {

            var percentDone = e.loaded / e.total;

            this._progress = (percentDone * 100).toFixed();

            $timeout(function() {}, 0);
        },
        '$getViaSlug': function(slug) {
            var deferred = $q.defer();
            var url = this.resource_uri + 'slug/' + slug + '/';
            $http.get(url)
                .then(angular.bind(this, function(data) {
                    angular.forEach(data, angular.bind(this, function(value, key) {
                        this[key] = value;
                    }));

                    this.$resolved = true;
                    deferred.resolve(this);
                }))
                .catch(angular.bind(this, function() {
                    deferred.reject(this);
                }));
            return deferred.promise;
        },
        '$addTag': function(tag) {
            var url = this.resource_uri + 'tag/' + tag + '/';
            var p = $http['post'](url);
            p.then(_.bind(function() {
                    this.$get();
                }, this)
            ).catch(_.bind(function() {
                this.$get();
            }, this));

        },
        '$removeTag': function(tag) {
            var url = this.resource_uri + 'tag/' + tag + '/';
            var p = $http['delete'](url);
            p.then(_.bind(function() {
                    this.$get();
                }, this)
            ).catch(_.bind(function() {
                this.$get();
            }, this));

        }
    });

    Object.assign(Flashcard.Collection.prototype, {});

    return Flashcard;
};

FlashcardModel.$inject = ['$log', '$q', '$http', '$timeout', 'drfModel', 'applications'];
module.exports = FlashcardModel;
