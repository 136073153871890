module.exports = angular.module('toychest.store.assets', [])
    .directive('liquidContainer', require('./LiquidAssetContainerDirective'))

    .controller('AssetsController', require('./AssetsController'))
    .controller('AssetsListController', require('./AssetsListController'))
    .controller('AssetDetailController', require('./AssetDetailController'))

    .service('AssetTagModel', require('./AssetTagModel'))
    .service('AssetsStateModel', require('./AssetsStateModel'))
    .service('AssetStateModel', require('./AssetStateModel'))
    .service('AssetSearchModel', require('./AssetSearchModel'))
    .service('PreloadImageService', require('./PreloadImageService'))

    .directive('assetSearch', require('./AssetSearchDirective'))
    .directive('preloadImage', require('./preloadImageDirective/PreloadImageDirective'))

    .config(require('./routes'))
;
