var DRFRoomModel = function($log, drfModel, applications) {

    var RoomModel = new drfModel({
        apiUrl: `${applications.platform.url}/api/v3/room/`,
    });

    Object.assign(RoomModel.Model.prototype, {
    });

    Object.assign(RoomModel.Collection.prototype, {
    });

    return RoomModel;
};

DRFRoomModel.$inject = ['$log', 'drfModel', 'applications'];
module.exports = DRFRoomModel;