var ActivityCreationController = function($scope, $state, currentUserModel) {
    const SECTIONS = [
        {
            value: 'pldrl',
            label: 'Presence Library'
        },
    ];

    function findPanelIndex(key) {
        if (!$scope.panelNavigation.panels) return;
        return $scope.panelNavigation.panels.findIndex((p) => p.key === key);
    }

    function findPanelByKey(key) {
        if (!$scope.panelNavigation.panels) return;
        return $scope.panelNavigation.panels.find((p) => p.key === key);
    }

    $scope.isAssessmentUser = () => currentUserModel.user.isAssessmentUser();
    $scope.sections = SECTIONS;
    $scope.isContentPublisher = currentUserModel.user.groups.indexOf('Content Publishers') >= 0;
    $scope.panelNavigation = {
        current: '',
        panels: [],
        nextPanel: () => {
            if (!$scope.panelNavigation.current) return;
            let index = findPanelIndex($scope.panelNavigation.current) + 1;
            if (index >= $scope.panelNavigation.panels.length) return; //If last one, return none
            else if (index < 0) return; //if not found, return none
            else return $scope.panelNavigation.panels[index];
        },
        previousPanel: () => {
            if (!$scope.panelNavigation.current) return;
            let index = findPanelIndex($scope.panelNavigation.current) - 1;
            if (index < 0) return; // if first one or not found, return none.
            else return $scope.panelNavigation.panels[index];
        },
        gotoPreviousPanel: () => {
            let panel = $scope.panelNavigation.previousPanel();
            $scope.panelNavigation.current = panel.key;
            $scope.panelNavigation.gotoPanel(panel.key);
        },
        gotoNextPanel: () => {

            let index = findPanelIndex($scope.panelNavigation.current);

            if ($scope.panelNavigation.current === "select-flashcards") {
                $scope.panelNavigation.panels[index].removeEmptyAssets &&
                    $scope.panelNavigation.panels[index].removeEmptyAssets();
            }

            let panel = $scope.panelNavigation.nextPanel();
            $scope.panelNavigation.current = panel.key;
            $scope.panelNavigation.gotoPanel(panel.key);
        },
        gotoPanel: (key) => {
            const panel = findPanelByKey(key);
            if (!panel || panel.locked($scope.panelNavigation.current)) {
                return false;
            }
            $state.go(panel.state, $state.params);
        },
        canGoForward: () => {
            const next = $scope.panelNavigation.nextPanel();
            return !!next && next.locked($scope.panelNavigation.current) === false;
        },
        canGoBack: () => {
            const prev = $scope.panelNavigation.previousPanel();
            return !!prev && prev.locked($scope.panelNavigation.current) === false;
        },
        addPanels: (panels) => {
            $scope.panelNavigation.panels = $scope.panelNavigation.panels.concat(panels);
        }
    };

    $scope.exitActivityCreator = function() {
        $state.go('app.store.activities.featured');
    };

    $scope.setActivity = (activity) => $scope.activity = activity;

    $scope.setSelectActivityPanels = function() {
        $scope.panelNavigation.panels = [{
            title: 'Select Activity',
            state: 'app.store.activities.create.selectActivity',
            key: 'select-activity',
            locked: () => (false)
        }];
    };

    $scope.setSelectActivityPanels();
};

ActivityCreationController.$inject = ['$scope', '$state', 'currentUserModel'];
module.exports = ActivityCreationController;
