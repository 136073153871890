function plDrop($timeout) {
    return {
        restrict:'A',
        scope:{
            plDrop:'=',
            plDragover:'=',
            plDragend: '=',
            plDragcancel: '=',
            plDragData:'='
        },
        link:function(scope, element, attrs) {
            var dropped = false;
            element[0].addEventListener('dragover', function(e) {
                dropped = false;
                e.preventDefault();
                if(_.isFunction(scope.plDragover)) {
                    scope.plDragover(e);
                }
            }, false);
            element[0].addEventListener('drop', function(e) {
                scope.plDrop(e, scope.plDragData);
                dropped = true;
            }, false);
            element[0].addEventListener('dragend', function(e) {
                if(_.isFunction(scope.plDragend)) {
                    scope.plDragend(e, scope.plDragData);
                }

                if(dropped === false && _.isFunction(scope.plDragcancel)) {
                    scope.plDragcancel(e, scope.plDragData);
                }
            }, false);
        }
    }
}

plDrop.$inject = ['$timeout'];

module.exports = plDrop;