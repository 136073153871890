function plInputRadioDirective(plInputService) {
    return {
        restrict: 'E',
        require: '?^form',
        scope: {
            model: '=',
            value: '@',
            label: '@',
            change: '&?',
            focus: '&?',
            blur: '&?',
            disabled: '=?',
            required: '=?',
            name: '@?',
        },
        templateUrl: '/core/pl-inputs/src/plInputRadio/plInputRadio.tpl.html',
        link: ($scope, $element, $attrs, formCtrl) => {
            plInputService.createNameIfNone($scope);
            plInputService.setupOnChange($scope, $attrs);
            plInputService.setupFocusAndBlur($scope, $attrs);
            plInputService.addInputToFormForValidation($scope, $element, formCtrl);

            $scope.getStateClasses = function() {
                return {
                    focused: $scope.focused,
                    active: $scope.model === $scope.value,
                    disabled: $scope.disabled,
                };
            };
        },
    };
}

plInputRadioDirective.$inject = ['plInputService'];
module.exports = plInputRadioDirective;
