// TODO - this authentication step is somehow breaking the global navs menu for unclear reasons
// I am punting on it for now, we need to press on. - JB
// function authenticate($q,  $state, currentUserModel) {
//     const deferred = $q.defer();

//         const checkGroups = (user) => {
//             if (currentUserModel.user && currentUserModel.user.groups.indexOf('School Staff Providers') >= 0) {
//                 $state.go('app.store.activities.list');
//                 deferred.reject(user);
//             } else {
//                 deferred.resolve();
//             }
//         }
    
//         if (currentUserModel.user.constructor.name !== 'GuestUser') {
//             checkGroups(currentUserModel.user);
//         } else {
//             setTimeout(
//                 () => {
//                     currentUserModel.getAuthenticatedUser().then(user => {
//                         checkGroups(user);
//                     },
//                     () => {
//                         $state.go('app.store.activities.list');
//                         deferred.reject('unauthenticated');
//                     });
//                 }
//             )
//         }
//     return deferred.promise;
// }

var Routes = function($stateProvider) {
    $stateProvider
        .state('app.store.assessments', {
            url: '/assessments',
            abstract: true,
            // resolve: {
            //     authenticate: ['$q', '$state', 'currentUserModel', authenticate],
            // },
            views: {
                content: {
                    templateUrl: '/toychest/modules/store/assessments/assessments.tpl.html',
                    controller: 'AssessmentsController as assessmentsCtrl'
                }
            }
        })
        .state('app.store.assessments.all', {
            url: '?q&type&tag',
            views: {
                main: {
                    templateUrl: '/toychest/modules/store/activities/activities.list.tpl.html',
                    controller: 'AssessmentsListController as listCtrl'
                }
            }
        })
        .state('app.store.assessments.recent', {
            url: '?q&type&tag',
            views: {
                main: {
                    templateUrl: '/toychest/modules/store/activities/activities.list.tpl.html',
                    controller: 'AssessmentsListController as listCtrl'
                }
            }
        })
        .state('app.store.assessments.detail', {
            url: '/detail/:slug',
            params: {
                prevState: null
            },
            views: {
                main: {
                    templateUrl: '/toychest/modules/store/assessments/assessments.detail.tpl.html',
                    controller: 'AssessmentDetailController as detailCtrl'
                }
            },
            resolve: {
                Assessment: ['$stateParams', 'assessmentResolver',
                    function($stateParams, assessmentResolver) {
                        return assessmentResolver.resolve($stateParams);
                    }
                ],
                PreviousState: [
                    '$state',
                    '$stateParams',
                    function($state, $stateParams) {
                        var currentStateData = $stateParams.prevState || {
                                name: $state.current.name,
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                        return currentStateData;
                    }
                ]
            }
        });
};

Routes.$inject = ['$stateProvider'];
module.exports = Routes;