"use strict";

class GuessWhoDrawerController {
    constructor() {
        this.autoSelect = false;
        this.reset = this.reset.bind(this);
        this.setXRay = this.setXRay.bind(this);
        this.setAutoSelect = this.setAutoSelect.bind(this);
    }

    registerCommunicator(communicator) {
        this.communicator = communicator;
    }

    setXRay(value) {
        value = value !== undefined ? value : this.xRay;
        this.communicator.setXRay(value)
    }

    setAutoSelect(value) {
        value = value !== undefined ? value : this.autoSelect;
        this.communicator.setAutoSelect(value)
    }

    getAutoSelect(value) {
        return this.communicator.getAutoSelect();
    }

    reset() {
        this.communicator.reset();
    }
}

GuessWhoDrawerController.$inject = [];

/**
 * Directive for text chat
 *
 * @param $log
 * @constructor
 */
function GuessWhoDrawerDirective($log, appModel, firebaseAppModel) {
    return {
        require: ['guessWhoDrawer', '^^guessWhoCommunicator'],
        restrict: 'E',
        templateUrl: '/core/toys/app/src/toys/modules/game/guesswho/guessWhoDrawer.tpl.html',
        scope: true,
        link: function link(scope, element, attributes, controllers) {
            let [guessWhoDrawer, guessWhoCommunicator] = controllers;

            guessWhoCommunicator.registerDrawer(guessWhoDrawer);
            guessWhoDrawer.registerCommunicator(guessWhoCommunicator);
            scope.$watch(() => guessWhoDrawer.getAutoSelect(),  (value) => guessWhoDrawer.autoSelect = value);
        },
        controller: GuessWhoDrawerController,
        controllerAs: 'guessWhoDrawerController'
    };
}

GuessWhoDrawerDirective.$inject = ['$log', 'appModel', 'firebaseAppModel'];
module.exports = GuessWhoDrawerDirective;
