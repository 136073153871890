function plInputDropdownDirective(plInputService, $timeout) {
    return {
        restrict: 'E',
        require: '?^form',
        scope: {
            model: '=',
            options: '=',
            focus: '&?',
            blur: '&?',
            change: '&?',
            disabled: '=?',
            required: '=?',
            name: '@?',
            label: '@?',
            maxHeight: '=?',
            type: '@?',
        },
        replace: true,
        templateUrl: '/core/pl-inputs/src/plInputDropdown/plInputDropdown.tpl.html',
        link: ($scope, $element, $attrs, formCtrl) => {
            plInputService.createNameIfNone($scope);
            plInputService.setupOnChange($scope, $attrs);
            plInputService.setupFocusAndBlur($scope, $attrs);
            plInputService.addInputToFormForValidation($scope, $element, formCtrl);
            plInputService.setupSelectDropdown($scope, $element, $attrs);

            $scope.type = $scope.type || 'text';

            $scope.getStateClasses = function() {
                return {
                    focused: $scope.focused,
                    disabled: $scope.disabled,
                    invalid: plInputService.isTextStyleInputInvalid($scope, formCtrl),
                };
            };
        },
    };
}

plInputDropdownDirective.$inject = ['plInputService', '$timeout'];
module.exports = plInputDropdownDirective;
