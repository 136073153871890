/**
  * AssessmentModel extends ActivityModel with assessment specific functionality, starting
  * with instructions.
  */

var AssessmentModel = function($log, $q, activityModel, drfAssessmentRightsModel, firebaseModel, firebaseAppModel, dispatcherService,
							   currentUserModel, rightsMgmtService) {

	angular.extend(this, activityModel);

    /***************************
	 * overridden functions
	 ***************************/
	this._reset = this.reset;
    this.reset = function() {
        $log.debug("[AssessmentModel] reset");
		this._clearCallbackRegistry(); //this must be before any persisted value changes

		var allowToggleInstructions = true;
		var showInstructions = false;
        this.instructionOpacity = 0.6; //default
		this.rightsToken = "";

        return this._reset(); //this must be last
    };

	this._initialize = this.initialize;
	this.initialize = function(model) {
		dispatcherService.addListener('firebaseAppModelChange', null, this.handleFirebaseAppModelChange, this);
		this.getAssessmentRightsToken();
		return this._initialize();
	};

	/***************************
	 * defaults
	 ***************************/
	this.DEFAULT_SCROLL_X = 0;
	this.DEFAULT_SCROLL_Y = 0;
	this.DEFAULT_OPACITY = 0.6;


	/***************************
	 * rights mgmt
	 ***************************/
	this.getAssessmentRightsToken = function() {
		if (currentUserModel.user.isAssessmentUser()) {
			$log.debug("[WorkspaceDirective] gettoken");
			this.tokenModel = new drfAssessmentRightsModel.Model()
			this.tokenModel.$setKey('generate_client_token')
			this.tokenModel.$get().then(_.bind(function () {
				this.handleTokenValue(this.tokenModel);
			}, this));
		}
	};

	this.handleTokenValue = function(tokenModel) {
		$log.debug("[AssessmentModel] handleTokenValue");
		if (tokenModel && tokenModel.token) {
			$log.debug("[AssessmentModel] fetched rights token");
			this.rightsToken = tokenModel.token;
			this.saveRightsToken(this.rightsToken);
		}
	};

    this.getProtectedContentUrl = function(key) {
        var token = null;
        if (!currentUserModel.user.isAssessmentUser()) {
            token = firebaseAppModel.app.rightsToken;
        }
        return rightsMgmtService.getProtectedContentUrl(this.getActivityId(), key, token);
    };

	this.cachedProtectedAsset = function(key) {
		return rightsMgmtService.assets[key];
	};

    /***************************
     * local fields
     ***************************/

	this.callbackRegistry = new Set();
	this.rightsToken = "";

	var allowToggleInstructions = true;
	var showInstructions = false;

	/***************************
	 * instruction visibility
	 ***************************/
	this.toggleInstructions = function() {
		if(this.canUserAccessInstructions()) {
			this.saveInstructionsVisible(!showInstructions);
		}
	};	

	this.setShowInstructions = function(value) {
		if(this.canUserAccessInstructions()) {
			showInstructions = value;
		}
	};

	this.getShowInstructions = function() {
		return showInstructions;
	};

	this.canUserAccessInstructions = function() {
		return currentUserModel.user.isAssessmentUser();
	};

	/***************************
	 * instruction opacity
	 ***************************/
	this.instructionOpacity = 0.6; //default

	this.setInstructionOpacity = function(value) {
		$log.debug("[AssessmentModel] setting instructionOpacity: " + value);
        this.instructionOpacity = value;
	};

    this.getInstructionOpacity = function() {
        return this.instructionOpacity;
    };

	/***************************
	 * scroll X percent
	 ***************************/
	this.scrollXPercent = 0; //default

	this.setScrollXPercent = function(value) {
		$log.debug("[AssessmentModel] setting scrollXPercent: " + value);
		this.scrollXPercent = value;
	};

	this.getScrollXPercent = function() {
		return this.scrollXPercent;
	};

	/***************************
	 * scroll Y percent
	 ***************************/
	this.scrollYPercent = 0; //default

	this.setScrollYPercent = function(value) {
		$log.debug("[AssessmentModel] setting scrollYPercent: " + value);
		this.scrollYPercent = value;
	};

	this.getScrollYPercent = function() {
		return this.scrollYPercent;
	};


	/***************************
	 * firebase mutation methods
	 ***************************/
	this.saveScrollPosition = function(scrollX, scrollY) {
		this.pageRef.update({'scrollXPercent': scrollX, 'scrollYPercent': scrollY});
	};

	this.saveOpacity = function(val) {
		this.preferencesRef.child('instructionsOpacity').set(val);
	};

	this.saveInstructionsVisible = function(val) {
		this.preferencesRef.child('instructionsVisible').set(val);
	};

	this.saveZoom = function(value) {
		this.pageRef.child('zoom').set(value);
	};

	this.savePage = function(page) {
		this.pageRef.update(page);
	};

	this.saveRightsToken = function(token) {
		firebaseAppModel.setRightsToken(token)
	};

	/***************************
	 * firebase listeners
	 ***************************/
	this.loadState = function() {

		this.preferencesRef = firebaseModel.getFirebaseRef(
			'activities/sessions/' + this.getSessionId() + '/' + this.activity.configId + '/preferences'
		);

		this.pageRef = firebaseModel.getFirebaseRef(
			'activities/sessions/' + this.getSessionId() + '/' + this.activity.configId + '/pageNum'
		);

		let eventType = 'value';

		let ref = this.pageRef;
		this._registerCallback(ref, eventType, ref.on(eventType, this._handlePageChange, this));

		ref = this.preferencesRef.child('instructionsOpacity');
		this._registerCallback(ref, eventType, ref.on(eventType, this._handleOpacityChange, this));

		ref = this.preferencesRef.child('instructionsVisible');
		this._registerCallback(ref, eventType, ref.on(eventType, this._handleInstructionsChange, this));

		ref = this.pageRef.child('zoom');
		this._registerCallback(ref, eventType, ref.on(eventType, this._handleZoomChange, this));

		ref = this.pageRef.child('offset');
		this._registerCallback(ref, eventType, ref.on(eventType, this._handleOffsetChange, this));

		ref = this.pageRef.child('scrollXPercent');
		this._registerCallback(ref, eventType, ref.on(eventType, this._handleScrollXPercent, this));

		ref = this.pageRef.child('scrollYPercent');
		this._registerCallback(ref, eventType, ref.on(eventType, this._handleScrollYPercent, this));
	};

	this._handlePageChange = function(snap) {
		$log.debug('[AssessmentModel] handlePageChange');
		dispatchEvent(snap, snap.val(), 'value', 'pageChangeEvent');
	};

	this._handleInstructionsChange = function(snap) {
		$log.debug('[AssessmentModel] handleInstructionsChange');
		this.setShowInstructions(snap.val());
		dispatchEvent(snap, this.getShowInstructions(), 'value', 'instructionsChangeEvent');
	};

	this._handleOpacityChange = function(snap) {
		$log.debug('[AssessmentModel] handleOpacityChange');
		this.instructionOpacity = (snap.val() == null) ? this.DEFAULT_OPACITY : snap.val();
		dispatchEvent(snap, this.instructionOpacity, 'value', 'opacityChangeEvent');
	};

	this._handleZoomChange = function(snap) {
		$log.debug('[AssessmentModel] handleOpacityChange');
		dispatchEvent(snap, snap.val(), 'value', 'zoomChangeEvent');
	};

	this._handleOffsetChange = function(snap) {
		$log.debug('[AssessmentModel] handleOffsetChange');
		dispatchEvent(snap, snap.val(), 'value', 'offsetChangeEvent');
	};

	this._handleScrollXPercent = function(snap) {
		$log.debug('[AssessmentModel] handleScrollXPercent');
		this.setScrollXPercent(this._getScrollXFromSnap(snap));
		dispatchEvent(snap, this.getScrollXPercent(), 'value', 'scrollXChangeEvent');
	};

	this._handleScrollYPercent = function(snap) {
		$log.debug('[AssessmentModel] handleScrollYPercent');
		this.setScrollYPercent(this._getScrollYFromSnap(snap));
		dispatchEvent(snap, this.getScrollYPercent(), 'value', 'scrollYChangeEvent');
	};

	this.handleFirebaseAppModelChange = function(eventType, event) {
		$log.debug("[AssessmentModel] handleFirebaseAppModelChange");
		if (event.key == "app" && event.data.hasOwnProperty('rightsToken')) {
			this._handleRightsToken(event.data.rightsToken)
		}
	};

	this._handleRightsToken = function(token) {
		$log.debug("[AssessmentModel] handleRightsToken");
		if (token === "") {
			if (currentUserModel.user.isAssessmentUser()) {
				this.getAssessmentRightsToken();
			}
		} else {
			this.rightsToken = token;
		}
	};

	function dispatchEvent(snap, data, srcType, targetType) {
		var event = {};
		event.type = srcType;
		event.data = data;
		event._snapshot = snap; //convenience
		dispatcherService.dispatch(targetType, null, event);
	}

	this._registerCallback = function(ref, event, callback, context) {
		$log.debug("[AssessmentModel] adding ", event, "to callback registry");
		this.callbackRegistry.add({'ref': ref, 'event': event, 'callback': callback, 'context':context});
	};

	/**
	 * Clean up all firebase liksteners that were registered.
	 * @private
     */
	this._clearCallbackRegistry = function() {
		$log.debug('[AssessmentModel] clearing ', this.callbackRegistry.size, 'items from the callback registry');
		for (let item of this.callbackRegistry.values()) {
			try {
				item.ref.off(item.event, item.callback, this);
			}
			catch (error) {
				$log.debug('[AssessmentModel] removing callback from ref failed');
			}
		}
		this.callbackRegistry.clear();
		$log.debug('[AssessmentModel] callback registry size: ', this.callbackRegistry.size);
	};

	/***************************
	 * util
	 ***************************/
	function getValueFromSnap(snap, key, defaultValue) {
		const val = snap.val();
		let newVal = val || defaultValue;
		if (val !== null && typeof val === 'object') {
			newVal = val[key];
		}
		return newVal || defaultValue;
	}

	this._getScrollXFromSnap = function(snap) {
		const scrollXPercent = getValueFromSnap(snap, fbScrollXPercentPath(), this.DEFAULT_SCROLL_X);
		return scrollXPercent;
	};

	this._getScrollYFromSnap = function(snap) {
		const scrollYPercent = getValueFromSnap(snap, fbScrollYPercentPath(), this.DEFAULT_SCROLL_Y);
		return scrollYPercent;
	};

	function fbScrollXPercentPath() {
		return 'scrollXPercent';
	}

	function fbScrollYPercentPath() {
		return 'scrollYPercent';
	}

};

AssessmentModel.$inject = ['$log', '$q', 'activityModel', 'drfAssessmentRightsModel', 'firebaseModel', 'firebaseAppModel', 'dispatcherService',
							'currentUserModel', 'rightsMgmtService'];
module.exports = AssessmentModel;