var FavoritesController = function($log, $scope, favoritesModel, folderModel) {

    $scope.favoritesModel = favoritesModel;

    $scope.treeOptions = {
        dirSelectable: false
    };

    $scope.selectActivity = function(activity) {
    };

    $scope.selectFavorite = function(node){
        folderModel.selectedFavorite = node;
    };

    $scope.toggleFavorite = function(node, expanded) {
        folderModel.selectedFavorite = node;
    };

    $scope.selectFavoriteFolder = function(node) {
        if(node) {
            favoritesModel.selectedFavoriteFolder = node;
            calculateFavoritePath();
        }
    };
    $scope.toggleFavoriteFolder = function(node){
        if(node) {
            favoritesModel.selectedFavoriteFolder = node;
            calculateFavoritePath();
        }
    };

    // !!ABL This feels stupid.
    // depth first search of the folders to build the path
    var getPath = function(folders, id) {
        var p = [];
        for(var i=0; i<folders.length; i++){
            if(folders[i].id == id) {
                p.push(folders[i]);
                return p;
            }else{
                var t = getPath(folders[i].children, id);
                if(t) {
                    p.push(folders[i]);
                    p = p.concat(t);
                    return p;
                }
            }
        }
        return null;
    };

    var calculateFavoritePath = _.bind(function() {
        var p = getPath(favoritesModel.folders, favoritesModel.selectedFavoriteFolder.id);
        favoritesModel.path = p ? p: [];
    }, this);
};

FavoritesController.$inject = ['$log', '$scope', 'favoritesModel', 'folderModel'];
module.exports = FavoritesController;
