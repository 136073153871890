var NotifyDirective = function($log, $timeout) {
    return {
        replace: true,
        restrict: 'E',
        templateUrl : '/core/pl-core/src/directives/notify/notify.tpl.html',
        scope: {},
        link: function ($scope, $element, $attr) {
            $scope.message = $attr.message;
            var timeout = 1000;
            var delay = 5000;
            if($attr.hasOwnProperty('timeout')){
                timeout = parseInt($attr.timeout);
            }
            if($attr.hasOwnProperty('delay')){
                delay = parseInt($attr.delay);
            }

            var height = $element.outerHeight(true);
            $timeout(function(){
                $element.animate({top: -height}, timeout);
            }, delay);
        }
    };
};

NotifyDirective.$inject = ['$log', '$timeout'];
module.exports = NotifyDirective;
