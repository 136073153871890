var FlashcardEditorDirective = function() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            asset: '=',
            selectImage: '&',
            onUpdate: '&'
        },
        templateUrl: '/toychest/common/directives/Flashcards/FlashcardEditorDirective.tpl.html',
        link: function($scope) {
            $scope.isAssetActive = isAssetActive;

            $scope.$watchGroup([
                'asset.title',
                'asset.url',
                'asset.thumbnail_url'
            ], function() {
                $scope.onUpdate();
            });

            function isAssetActive() {
                return $scope.asset != null;
            }
        }
    };
};

FlashcardEditorDirective.$inject = [];
module.exports = FlashcardEditorDirective;