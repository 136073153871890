function plClientFactory(plHttp, $q, plLodash) {
    const plClient = {};
    let _clients = [];
    let _clientsLoaded = false;
    let _lastGetParams = null;

    plClient.get = (data1 = {}) => {
        const deferred = $q.defer();

        const defaultParams = {
            limit: 1000,
        };
        if (!_lastGetParams) {
            _lastGetParams = defaultParams;
        }
        const data = Object.assign({}, defaultParams, data1);
        const sameParams = plLodash.equals(data, _lastGetParams, ['provider']);
        _lastGetParams = data;

        if (_clientsLoaded && sameParams) {
            deferred.resolve(_clients);
        } else {
            plHttp.get('clients', data)
                .then((res) => {
                    if (sameParams) {
                        _clients = res.data.results;
                        _clientsLoaded = true;
                    }
                    deferred.resolve(res.data.results);
                }, (err) => {
                    deferred.reject(err);
                });
        }
        return deferred.promise;
    };

    plClient.formSelectOpts = (clients) => {
        return clients.map((client) => {
            return {
                value: client.uuid,
                label: `${client.first_name} ${client.last_name}`,
            };
        });
    };

    plClient.getInfo = (uuid) => {
        if (!uuid) {
            return null;
        }
        const index = plLodash.findIndex(_clients, 'uuid', uuid);
        return (index > -1) ? _clients[index] : null;
    };

    plClient.formatName = (clientInfo, format = 'firstLastInitial') => {
        if (!clientInfo) {
            return '';
        }
        if (format === 'full') {
            return `${clientInfo.first_name} ${clientInfo.last_name}`;
        }
        return `${clientInfo.first_name} ${clientInfo.last_name.slice(0, 1)}.`;
    };

    plClient.save = (client) => {
        const deferred = $q.defer();

        plHttp.save('clients', client)
            .then((res) => {
                deferred.resolve(res.data);
            }, (err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    };

    return plClient;
}
plClientFactory.$inject = ['plHttp', '$q', 'plLodash'];
module.exports = plClientFactory;
