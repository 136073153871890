var PlIdeaReport= function ($http, appModel, applications) {
    'use strict';
    return {
        restrict: 'E',
        templateUrl : '/core/pl-core/src/directives/plIdeaReport/plIdeaReport.tpl.html',
        scope : true,
        link: function (scope) {

            const reportIdeaEndpoint = `${applications.platform.url}/api/v3/jira/idea/`

            scope.$watch(
                function() {
                    return appModel.app.ideaReportVisible;
                }, function(val) {
                    scope.ideaReportVisible = val;
                }
            );

            scope.$on('takeoverClosed', function(event) {
                appModel.app.ideaReportVisible = false;
                event.stopPropagation();
            });

            //for unclear reasons, title and message are bound to _this_ rather than scope
            scope.reportIdea = function() {
                var title = JSON.stringify(this.title);
                var message = JSON.stringify(this.message);

                var payload = '{"summary" : ' + title + ', "description" : ' + message + '}';
                $http.post(reportIdeaEndpoint, payload);

                this.title = '';
                this.message = '';
                appModel.app.ideaReportVisible = false;
            };
        },
    };
};

PlIdeaReport.$inject = ['$http', 'appModel', 'applications'];
module.exports = PlIdeaReport;

