function plRecordNotesFactory($q, plHttp, plLodash) {
    const rn = {};
    let _notesSchemas = [];

    rn.get = () => {
        const deferred = $q.defer();

        if (_notesSchemas && _notesSchemas.length) {
            deferred.resolve(_notesSchemas);
        } else {
            plHttp.get('notesSchemas')
                .then((res) => {
                    _notesSchemas = res.data.results;
                    deferred.resolve(_notesSchemas);
                }, (err) => {
                    deferred.reject(err);
                });
        }
        return deferred.promise;
    };

    rn.getSchemaInfo = (uuid) => {
        const index = plLodash.findIndex(_notesSchemas, 'uuid', uuid);
        return (index > -1) ? _notesSchemas[index] : null;
    };

    return rn;
}
plRecordNotesFactory.$inject = ['$q', 'plHttp', 'plLodash'];
module.exports = plRecordNotesFactory;
