/**
 * Metric model
 * @param $log
 */
var MetricModel = function($log, tastyModel, applications) {

    var Metric = new tastyModel({
        apiUrl: '/tasty/v2/studentdatametric/',
        base_uri: applications.platform.url,
    });

    Object.assign(Metric.Model.prototype, {
    });

    Object.assign(Metric.Collection.prototype, {
    });

    return Metric;
};

MetricModel.$inject = ['$log', 'tastyModel', 'applications'];
module.exports = MetricModel;