var ActivityModel = function($log, $q, $timeout, firebaseModel, firebaseMock, drfActivityModel, drfAssessmentModel, ChannelService) {

    var defaults = {};

    this.reset = function() {
        $log.debug('[ActivityModel] reset');
        this.activity = angular.copy(defaults);

        this.oldDefer = this._loadedDefer;
        this._loadedDefer = $q.defer();
        this.foundationLoaded = this._loadedDefer.promise;
        this.share = false;
        delete this.channel;
        this.channel = ChannelService.build();
        if(this.oldDefer) {
            this.oldDefer.notify('reset');
        }
        delete this.oldDefer;
        return this;
    };

    /**
     * Fields
     */

    /**
     * activityId (activity type)
     * @param id
     * @return ActivityModel
     */
    this.setActivityId = function(id) {
        this.activity.activityId = id;
        return this;
    };

    this.getActivityId = function() {
        return this.activity.activityId;
    };

    /**
     * configId (database location)
     * @param id
     * @return ActivityModel
     */
    this.setConfigId = function(id) {
        this.activity.configId = id;
        return this;
    };

    this.getConfigId = function() {
        return this.activity.configId;
    };

    this.setType = function(id) {
        this.activity.type = id;
        return this;
    };

    this.getType = function() {
        return this.activity.type;
    };

    this.setChannel = function(channel) {
        this.channel = channel;
        return this;
    };

    this.getChannel = function() {
        return this.channel;
    };

    /**
     * sessionId (firebase location)
     * @param id
     */
    this.setSessionId = function(id) {
        this.activity.sessionId = id;
        return this;
    };

    this.getSessionId = function() {
        return this.activity.sessionId;
    };


    /**
     * Set the mode to shared or not shared (e.g. remote saving)
     * @param value
     */
    this.setShared = function(value) {
        this.share = value;
        if (this.share) {
            this.getRef = firebaseModel.getFirebaseRef;
        } else {
            this.getRef = function(name) {
                return new firebaseMock(name);
            };
        }
        this.getSessionRef = function() {
            return this.getRef('activities/sessions/').child(this.getSessionId());
        };
        return this;
    };

    /**
     * Setup the load and value handlers for the remote object
     */
    this.initialize = function(model) {
        this.getActivityConfig(model);
        return this;
    };

    this.getActivityConfig = function(model) {
        switch (this.activity.type) {
            case 'assessment':
                this.configModel = new drfAssessmentModel.Model();
                break;
            case 'activity':
            default:
                this.configModel = new drfActivityModel.Model();
        }
        this.configModel.$setKey(this.getActivityId());
        this.configModel.$get().then(() => {
            this.handleConfigValue(this.configModel);
        });
        //}
        return this;
    };

    /**
     * Handlers
     */

    this.handleConfigValue = function(config) {
        if (config == undefined || config.descriptor == undefined) {
            $log.debug('[ActivityModel] no activity config.descriptor found');
            $log.error('Cannot load activity');
            return;
        }
        var configJson = JSON.parse(config.descriptor);

        if (configJson == null) {
            $log.error('[ActivityModel] config data is null');

        } else {
            $timeout(_.bind(function() {
                var keys = _.keys(configJson);
                var configRoot = {};
                if (keys.length == 1) {
                    configRoot = configJson[keys[0]];
                } else {
                    _.each(keys, function(item) {
                        configRoot[item] = configJson[item];
                    });
                }
                this.activity.config = configRoot;
                this.activity.thumbnails = config.page_thumbnails;
                this._loadedDefer.resolve(this);
            }, this));
        }
    };

    this.handleConfigError = function(error) {
        $log.debug('[ActivityModel] activity ref load error:' + error.code);
        this._loadedDefer.reject(this);
    };

    /**
     * Persistence methods
     */
    this.updateActivity = function() {
        if (this.share) {
            this.ref.set(this.activity);
        }
        return this;
    };

    /**
     * Init
     */
    var init = function() {
        $log.debug('[ActivityModel] Creating ActivityModel');
        this.reset();
    }.bind(this);
    init();

};

ActivityModel.$inject = ['$log', '$q', '$timeout', 'firebaseModel', 'firebaseMock', 'drfActivityModel', 'drfAssessmentModel', 'ChannelService'];
module.exports = ActivityModel;