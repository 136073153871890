"use strict";
let MersenneTwister = require('mersenne-twister');

var Reference = function($log, Assert, Pushkey, DataStore, Events) {
    var twister;
    class Reference {
        constructor(url) {
            $log.debug('[Mock] new Firebase()');
            $log.debug("[Mock] creating Firebase mock for path: " + url);

            Assert(url, 'A url is required');

            if(!twister) {
                twister = new MersenneTwister(0);
            }
            this._id = twister.random_excl() * 1000;

            this.dataStore = DataStore.getDataStore();

            //remove extra /
            url = _.trim(url, '/');

            this._events = {};
            _.each(Events.all(), function(value){
               this._events[value] = [];
            }, this);

            // capture and remove protocol
            this.protocol = 'https://'; //default

            if (_.startsWith(url, 'https://')) {
                this.protocol = 'https://'
            } else if (_.startsWith(url, 'http://')) {
                this.protocol = 'http://'
            }

            var regex = new RegExp('^https:\/\/|^http:\/\/');
            url = url.replace(regex, '');
            //trim again
            url = _.trim(url, '/');

            //append the config url if it's missing (this is for convenience)
            var mockDomain = this.dataStore.config.domain;
            if (url.indexOf(mockDomain) == -1 ) {
                if (url.length > 0) {
                    url = mockDomain + "/" + url;
                } else {
                    url = mockDomain;
                }
            }
            this._url = url;
        }

        /**
         * on
         * @param eventType
         * @param callback
         * @param cancelCallback
         * @param context
         */
        on(eventType, callback, cancelCallback, context) {
            $log.debug('[Reference] on()');
            //TODO handle cancelcallback
            var callbackObject = {};
            callbackObject.ref = this;
            callbackObject.key = this._url;
            callbackObject.eventType = eventType;
            callbackObject.callback = callback;
            callbackObject.context = context;
            callbackObject.persistent = true;
            this.dataStore.registerCallback(callbackObject);
        };

        /**
         * once
         * @param eventType
         * @param callback
         * @param context
         */
        once(eventType, callback, context) {
            var callbackObject = {};
            callbackObject.ref = this;
            callbackObject.key = this._url;
            callbackObject.eventType = eventType;
            callbackObject.callback = callback;
            callbackObject.context = context;
            callbackObject.persistent = false;
            this.dataStore.registerCallback(callbackObject);
        };

        /**
         * off
         * @param eventType
         * @param callback
         * @param context
         */
        off(eventType, callback, context) {
            var callbackObject = {};
            callbackObject.ref = this;
            callbackObject.key = this._url;
            callbackObject.eventType = eventType;
            callbackObject.callback = callback;
            callbackObject.context = context;
            this.dataStore.removeCallback(callbackObject);
        };

        auth() {
        };

        // Append the provided path to the ref path
        // For example: https://foo.com/car + 'dog/bar' -> https://foo.com/car/dog/bar
        child(childPath) {
            Assert(childPath, 'A childpath is required');
            return new Reference(this._url + "/" + childPath);
        };

        // Rip off the last location element
        // For example: https://foo.com/car/dog/bar -> https://foo.com/car/dog
        parent() {
            $log.debug('[Reference] parent()');
            var url = _.trim(this._url, '/');
            var parts = url.split('/');
            parts.splice(-1, 1);
            if (parts.length == 0) {
                //the ref is the root is it's at the top level object e.g. domain.com
                return null;
            }
            var path = parts.join('/');
            return new Reference(path);
        };

        // Rip off the whole subpath and just return the root url
        // For example: https://foo.com/cat/dog/bar -> https://foo.com
        root() {
            var url = _.trim(this._url, '/');
            var parts = url.split('/');
            var rootPath = parts.shift();
            return new Reference(this.protocol + rootPath);
        };

        // Return the last token in a firebase location
        // For example: https://foo.com/cat/dog -> dog
        key() {
            $log.debug('[Reference] key()');
            var key;
            var parts = this._url.split('/');
            if (parts.length == 1) {
                key = null;
            } else {
                key = parts.pop();
            }
            return key;
        };

        // Deprecated, use key()
        name() {
            $log.debug('Deprecated: use key() instead');
            return this.key();
        };

        /**
         * toString()
         * Returns full url path of this reference
         * @returns {*}
         */
        toString() {
            return this.protocol + this._url;
        };

        // Write data to the location
        // Passing null will remove the data
        /**
         * set
         * @param value
         * @param onComplete
         */
        set(value, onComplete) {
            $log.debug("Reference SET()");
            this.dataStore.set(this, value, onComplete, null, false);
        };


        /**
         * setWithPriority
         * @param value
         * @param priority
         * @param onComplete
         */
        setWithPriority(value, priority, onComplete) {
            $log.debug("Reference setWithPriority()");
            this.dataStore.set(this, value, onComplete, priority, false);
        };

        /**
         * setPriority
         * @param priority
         * @param onComplete
         */
        setPriority(priority, onComplete) {
            $log.debug("Reference setPriority()");
            var data = this.dataStore.repo.getNode(this._url);
            //set priority as really an update on the $priority field
            this.dataStore.set(this, data, onComplete, priority, true);
        };

        // Update data at the location. Changes the specified children but leaves unspecified children alone.
        /**
         * update
         * @param value
         * @param onComplete
         */
        update(value, onComplete) {
            $log.debug('[Reference] update()');
            //check for multipath update
            var keys = _.keys(value);
            var multi = false;
            for (var i=0; i<keys.length; i++) {
                if (keys[i].indexOf("/") > -1) {
                    multi = true;
                    break;
                }
            }
            if (multi) {
                _.forEach(keys, _.bind(function(key) {
                    var ref = new Reference(this.toString() + "/" + key);
                    this.dataStore.set(ref, value[key], onComplete, null, true);
                }, this));
            } else {
                this.dataStore.set(this, value, onComplete, null, true);
            }
        };

        /**
         * remove
         * @param onComplete
         */
        remove(onComplete) {
            $log.debug('[Reference] Remove');
            this.set(null, onComplete);
        };

        /**
         * push
         * @param value
         * @param onComplete
         * @returns {FirebaseMock}
         */
        push(value, onComplete) {
            $log.debug('Mock: Push');
            //if no value set we'll write an empty string
            value = (value) ? value : "";
            var now = this.dataStore.getServerTime();
            var childRef = new Reference(this._url + "/" + Pushkey.generate(now));

            // if there's no value, then don't set it
            if(value) {
                childRef.set(value, onComplete);
            }

            return childRef;
        };

        /**
         * transaction
         * @param updateFn
         * @param onComplete
         * @param applyLocally
         */
        transaction(updateFn, onComplete, applyLocally) {
            $log.debug('Not Implemented');
        };

        authWithCustomToken(token, onComplete) {
            //temp
            if (token != undefined) {
                if (onComplete) {
                    var payload = {
                        auth: token,
                        uid: "mock_uid",
                        provider: "mock_provider",
                        expires: new Date().getTime()
                    };
                    onComplete.call(this, null, payload);
                }
            } else {
                if (onComplete) {
                    var error = {error: "Invalid Auth Token"};
                    onComplete.call(this, error, {});
                }
            }
        };

    }

    return Reference;
};
Reference.$inject = ['$log', 'Assert', 'Pushkey', 'DataStore', 'Events'];
module.exports = Reference;