var ActivityResolver = function(drfActivityModel) {
    this.resolve = function(params) {
        var activity = new drfActivityModel.Model();
        var promise = activity.$getViaSlug(params.slug);
        return promise;
    };
    return this;
};
ActivityResolver.$inject = ['drfActivityModel'];
module.exports = ActivityResolver ;
