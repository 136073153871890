"use strict";
var DataStore = function ($log, Assert, EventQueue, Repo, DataNode) {
    var datastore = null;

    class DataStore {
        static getDataStore(mockConfig = {}, mockData = {}) {
            if (!datastore) {
                var config = {
                    connectionDelay: 500,
                    methodLatency: 500,
                    domain: "firebasemock"
                };
                //default data
                var data = {};
                config = _.defaults(mockConfig, config);
                data = _.defaults(mockData, data);

                datastore = new DataStore(config, data);
                if (data) {
                    datastore.repo.addNode(config.domain, data);
                }
            }
            return datastore;
        }

        constructor(config, data) {
            this.config = config;
            this.repo = new Repo();
            this.eventQueue = new EventQueue(this.repo, config.methodLatency);
            this._references = {};

            this.lastPushTime = 0;
            this.lastRandChars = [];
        }

        registerCallback(callback) {
            $log.debug("[Mock] registering callback for: " + callback.key + " : " + callback.eventType);
            var eventType = callback.eventType;
            var currentListeners = this.eventQueue.getListener(callback.key);
            if (currentListeners == null) {
                //first listener
                var callbackHolder = {};
                callbackHolder.ref = callback.ref;
                callbackHolder[eventType] = [callback];
                this.eventQueue.setListener(callback.key, callbackHolder);
            } else {
                var typearray = currentListeners[callback.eventType];
                if (typearray) {
                    //dont add the same listener for the same type
                    if (_.includes(typearray, callback)) {
                        $log.debug("[Mock] callback already registered for this event.");
                    } else {
                        typearray.push(callback);
                    }
                } else {
                    currentListeners[callback.eventType] = [callback];
                }

            }
            this.queueInitialEvents(callback);
        };

        removeCallback(callback) {
            var currentListeners = this.eventQueue.getListener(callback.key);
            if (currentListeners) {
                var typearray = currentListeners[callback.eventType];
                for (var i = 0; i < typearray.length; i++) {
                    var existingCallback = typearray[i];
                    if (existingCallback.callback === callback.callback) {
                        typearray.splice(i, 1);
                        break;
                    }
                }
            }
        };

        queueInitialEvents(callbackDef) {
            $log.debug('[Mock] DataStore.dispatchInitialEvents()');
            var ref = callbackDef.ref;
            var dataNode = this.repo.getNode(ref._url);
            if (callbackDef.eventType == "child_added") {
                //handle add
                var isObject = dataNode && dataNode.hasObjectValue();
                if (isObject) { //dont dispatch child_added for primitive values
                    var value = dataNode.getValue();
                    var keys = _.keys(value);
                    if (keys && keys.length) {
                        for (var i = 0; i < keys.length; i++) {
                            var childRef = callbackDef.ref.child(keys[i]);
                            var addEvent = this.eventQueue.generateAddEvent(childRef, value[keys[i]]);
                            this.eventQueue.addEvent(ref, addEvent);
                        }
                    }
                }
            } else if (callbackDef.eventType == "value") {
                //handle value
                var valueEvent = this.eventQueue.generateValueEvent(ref, dataNode);
                this.eventQueue.addEvent(ref, valueEvent);
            }
        };

        // this overrides everything
        set(ref, data, callback, priority, merge) {
            $log.debug('[Mock] DataStore.set()');
            var opEvent = null;
            var valueEvent;
            var path = ref._url;

            var oldData = this.repo.getNode(path);
            //wrap the new data
            data = DataNode.convert(data, priority);
            data.setPriority(priority);

            //priority
            if (oldData) {
                var priorityChanged = true;
                if (oldData && oldData.getPriority() == priority) {
                    priorityChanged = false;
                }
            }
            //don't dispatch any events if the data is already there
            if (oldData && _.isEqual(data.getValue(), oldData.getValue()) && !priorityChanged) {
                return;
            }

            //check before we add
            if (oldData && data.getValue() == null) {
                //this is a remove!
                var removeData = oldData; //this retains the data for ancestor events
                opEvent = this.eventQueue.generateRemoveEvent(ref, oldData);
                //remove from the datastore
                this.repo.removeNode(path);
            } else {
                //update datastore
                if (merge) {
                    this.repo.mergeNode(path, data);
                } else {
                    //a normal set overwrites the whole object
                    this.repo.addNode(path, data);
                }

                if (oldData && priorityChanged) {
                    var preOpEvent = this.eventQueue.generateMoveEvent(ref, data);
                }

                if (oldData == null || oldData == undefined) {
                    opEvent = this.eventQueue.generateAddEvent(ref, data);
                } else {
                    opEvent = this.eventQueue.generateChangeEvent(ref, data);
                }
            }

            //create the root value event
            valueEvent = this.eventQueue.generateValueEvent(ref, this.repo.getNode(path));
            //add events to this ref's queue
            if (preOpEvent) {
                this.eventQueue.addEvent(ref, preOpEvent);
            }
            this.eventQueue.addEvent(ref, opEvent);
            this.eventQueue.addEvent(ref, valueEvent);

            //generate events up the chain of ancestors
            var childref = ref;
            var childdata = data;
            var ancestors = this.repo.getAncestry(ref);
            for (var i = 0; i < ancestors.length; i++) {
                var ancestorRef = ancestors[i];
                var ancestorData = this.repo.getNode(ancestors[i]._url);
                if (i == 0) { //the first entry is the immediate parent of the current ref
                    if (oldData && data.getValue() == null ) {
                        var removeEvent = this.eventQueue.generateRemoveEvent(childref, removeData);
                        ancestorRef.dataStore.eventQueue.addEvent(ancestorRef, removeEvent);
                    } else if (oldData != null) {
                        var changeEvent = this.eventQueue.generateChangeEvent(childref, childdata);
                        ancestorRef.dataStore.eventQueue.addEvent(ancestorRef, changeEvent);
                    } else {
                        var addEvent = this.eventQueue.generateAddEvent(childref, childdata);
                        ancestorRef.dataStore.eventQueue.addEvent(ancestorRef, addEvent);
                    }
                    childref = ancestorRef;
                    childdata = ancestorData;
                } else {
                    //ancestors further up get change events
                    var changeEvent = this.eventQueue.generateChangeEvent(childref, childdata);
                    ancestorRef.dataStore.eventQueue.addEvent(ancestorRef, changeEvent);
                }
                //every ancestor gets a value event.
                valueEvent = this.eventQueue.generateValueEvent(ancestorRef, ancestorData);
                ancestorRef.dataStore.eventQueue.addEvent(ancestorRef, valueEvent);
            }

            if (callback) {
                //todo add error conditions
                var onCompleteCallback = this.eventQueue.generateOnCompleteCallback(ref, callback, null);
                this.eventQueue.addOnComplete(ref, onCompleteCallback)
            }
        };

        getServerTime() {
            return new Date().getTime();
        };
    }

    return DataStore;
};
DataStore.$inject = ['$log', 'Assert', 'EventQueue', 'Repo', 'DataNode'];
module.exports = DataStore;