function plApiRoomFactory(plHttp, $q) {
    const plApiRoom = {};

    plApiRoom.newRoom = (userUuid) => {
        const deferred = $q.defer();

        plHttp.save('room', { user_uuid: userUuid })
            .then((res) => {
                deferred.resolve();
            }, (err) => {
                deferred.reject(err);
            });

        return deferred.promise;
    };

    plApiRoom.resetWhiteboard = (userUuid) => {
        const deferred = $q.defer();

        plHttp.put('roomResetWhiteboard', { user_uuid: userUuid })
            .then((res) => {
                deferred.resolve();
            }, (err) => {
                deferred.reject(err);
            });

        return deferred.promise;
    };

    return plApiRoom;
}
plApiRoomFactory.$inject = ['plHttp', '$q'];
module.exports = plApiRoomFactory;
