var DrfStoreHeroModel = function($log, $q, $http, drfModel, tagModel, applications) {

    var StoreHero = new drfModel({
        apiUrl: `${applications.platform.url}/api/v1/hero/`,
    });

    _.extend(StoreHero.Model.prototype, {
        '$random': function() {
            var url = this.resource_uri + 'random/';
            return $http.get(url).then(function(response) {
                return response.data;
            });
        },
        '$list': function() {
            return $http.get(`${this.resource_uri}?is_active=True`).then(function(response) {
                return response.data;
            });
        }
    });

    return StoreHero;
};

DrfStoreHeroModel.$inject = ['$log', '$q', '$http', 'drfModel', 'tagModel', 'applications'];
module.exports = DrfStoreHeroModel;