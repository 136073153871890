function AssetDetailController(
    $log,
    $scope,
    $state,
    $stateParams,
    assetModel,
    currentUserModel
) {
    $scope.asset = new assetModel.Model();
    $scope.asset.$getViaSlug($stateParams.slug);
    $scope.editTitle = false;
    $scope.editDescription = false;

    $scope.canEdit = () => {
        const isAdmin = currentUserModel.user.isInGroup('Administrator');
        const isOwner = $scope.asset.owner === currentUserModel.user.username;
        return isAdmin || isOwner;
    };

    $scope.canTrash = () => {
        const isAdmin = currentUserModel.user.isInGroup('Administrator');
        const isOwner = $scope.asset.owner === currentUserModel.user.username;
        return (isAdmin || isOwner) && $scope.asset.disabled === false;
    };

    $scope.deleteAsset = () => {
        $scope.asset.disabled = true;
        $scope.asset.$save().then((asset) => {
            $state.go('app.store.assets.all');
        });
    };

    $scope.updateTitle = () => {
        $scope.asset.$save().then((asset) => {
            $state.go('app.store.assets.detail', {
                slug: asset.slug,
            });
        });
        $scope.editTitle = false;
    };

    $scope.beginEditTitle = () => {
        $scope.editTitle = true;
    };

    $scope.beginEditDescription = () => {
        $scope.editDescription = true;
    };
    $scope.updateDescription = () => {
        $scope.asset.$save();
        $scope.editDescription = false;
    };

    $scope.cancelEditTitle = () => {
        $scope.editTitle = false;
        $scope.asset.$get();
    };

    $scope.getClasses = () => {
        return {
            'edit-title': $scope.editTitle,
            'edit-description': $scope.editDescription,
        };
    };

    $scope.tagAdded = (tag) => $scope.asset.$addTag(tag);
    $scope.tagRemoved = (tag) => $scope.asset.$removeTag(tag);
}

AssetDetailController.$inject = [
    '$log',
    '$scope',
    '$state',
    '$stateParams',
    'assetModel',
    'currentUserModel',
];
module.exports = AssetDetailController;