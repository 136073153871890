var DropDirective = function($log, $parse, $timeout){
    return {
        restrict: 'A',
        compile : function($element, attr) {
            var eventName = 'drop';
            var directiveName = 'onDrop';
            // We expose the powerful $event object on the scope that provides access to the Window,
            // etc. that isn't protected by the fast paths in $parse.  We explicitly request better
            // checks at the cost of speed since event handler expressions are not executed as
            // frequently as regular change detection.
            var fn = $parse(attr[directiveName], /* interceptorFn */ null, /* expensiveChecks */ true);

            var ngEventHandler = function(scope, element) {
                element.on(eventName, function(event) {
                    var callback = function() {
                        return fn(scope, {$event: event});
                    };
                    var result = scope.$eval(callback); //();
                    $timeout(function(){}, 0);
                    return result;
                })
            };
            return ngEventHandler;
        }
    };
};
DropDirective.$inject = ['$log', '$parse', '$timeout'];
module.exports = DropDirective;