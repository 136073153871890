import plOverlayDropdownDirective from './src/plOverlayDropdown/plOverlayDropdownDirective';
import plOverlayDropdown from './src/plOverlayDropdown/plOverlayDropdownFactory';
import plOverlayLoadingDirective from './src/plOverlayLoading/plOverlayLoadingDirective';
import plOverlayLoading from './src/plOverlayLoading/plOverlayLoadingFactory';
import plOverlayModalDirective from './src/plOverlayModal/plOverlayModalDirective';
import plOverlayModal from './src/plOverlayModal/plOverlayModalFactory';

export default angular.module('pl-overlays', [])
    .service('plOverlayDropdown', plOverlayDropdown)
    .directive('plOverlayDropdownDirective', plOverlayDropdownDirective)

    .service('plOverlayLoading', plOverlayLoading)
    .directive('plOverlayLoadingDirective', plOverlayLoadingDirective)

    .service('plOverlayModal', plOverlayModal)
    .directive('plOverlayModalDirective', plOverlayModalDirective)
    ;
