function plInputSearchDirective(plInputService, $timeout) {
    return {
        restrict: 'E',
        require: '?^form',
        scope: {
            model: '=',
            options: '=',
            expandOnTop: '=?',
            focus: '&?',
            blur: '&?',
            change: '&?',
            disabled: '=?',
            required: '=?',
            name: '@?',
            label: '@?',
            maxHeight: '=?',
            icon: '@?',
            selectOpt: '&?',
        },
        replace: true,
        templateUrl: '/core/pl-inputs/src/plInputSearch/plInputSearch.tpl.html',
        link: ($scope, $element, $attrs, formCtrl) => {
            plInputService.createNameIfNone($scope);
            plInputService.setupOnChange($scope, $attrs);
            plInputService.setupFocusAndBlur($scope, $attrs);
            plInputService.addInputToFormForValidation($scope, $element, formCtrl);
            plInputService.setupSelectDropdown($scope, $element, $attrs, { mousedownPreventDefault: false });

            $scope.placeholder = $scope.placeholder || 'Type to search..';
            $scope.icon = $scope.icon || 'magnifier';

            if (!$scope.expandOnTop) {
                $element.find('.container-on-top').removeClass('container-on-top');
            }

            $scope.getStateClasses = function() {
                return {
                    focused: $scope.focused,
                    disabled: $scope.disabled,
                    invalid: plInputService.isTextStyleInputInvalid($scope, formCtrl),
                };
            };

            $scope.clearInput = () => {
                $scope.model = '';
            };

            $scope.selectOptionWrapper = (option) => {
                // If function is set, do not select option until AFTER a callback in case
                // want to PREVENT this option for some reason (validation).
                if ($scope.selectOpt) {
                    $scope.selectOpt()(option, (valid = true) => {
                        if (valid) {
                            $scope.selectOption(option);
                        }
                    });
                } else {
                    $scope.selectOption(option);
                }
            };
        },
    };
}

plInputSearchDirective.$inject = ['plInputService', '$timeout'];
module.exports = plInputSearchDirective;
