function plAppContext() {
  let _context = undefined;

  const _appContext = {
    getContext: () => {
      if (_context === undefined) {
        throw "No app context provided";
      }
      return _context;
    },
    setContext: context => {
      _context = context;
    },
    setClientsContext: () => {
      _appContext.setContext("clients");
    },
    setAdminContext: () => {
      _appContext.setContext("admin");
    },
  }
  return _appContext;
}

plAppContext.$inject = [];
module.exports = plAppContext;
