function PlToggle() {
    return {
        restrict: 'C',
        scope: {
            model: '=',
        },
        link: ($scope, $element) => {
            const checkMark = $('<div>', { class: 'check-mark' });
            const check = $('<div>', { class: 'check' });
            check
                .append($('<div>', { class: 'stem' }))
                .append($('<div>', { class: 'kick' }));
            checkMark.append(check);
            $element.prepend(checkMark);
            if ($scope.model) {
                $element.addClass('active');
            }

            $element.on('mouseup', () => {
                $scope.model = !$scope.model;
                if ($scope.model) {
                    $element.addClass('active');
                } else {
                    $element.removeClass('active');
                }
            });
        },
    };
}

module.exports = PlToggle;
