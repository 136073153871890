var DragOverDirective = function($log, $parse, $timeout){
    return {
        restrict: 'A',
        compile : function($element, attr) {
            var eventName = 'dragover';
            var directiveName = 'onDragover';
            // We expose the powerful $event object on the scope that provides access to the Window,
            // etc. that isn't protected by the fast paths in $parse.  We explicitly request better
            // checks at the cost of speed since event handler expressions are not executed as
            // frequently as regular change detection.
            var fn = $parse(attr[directiveName], /* interceptorFn */ null, /* expensiveChecks */ true);

            var ngEventHandler = function(scope, element) {
                element.on(eventName, function(event) {
                    var callback = function() {
                        fn(scope, {$event: event});
                    };
                    scope.$eval(callback); //callback();
                    $timeout(function(){
                    }, 0);
                })
            };
            return ngEventHandler;
        }
    };
};
DragOverDirective.$inject = ['$log', '$parse', '$timeout'];
module.exports = DragOverDirective;
