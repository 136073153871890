"use strict";


class QueueController {
    constructor (
        $scope,
        $log,
        appModel,
        firebaseModel,
        firebaseAppModel,
        $state,
        $sce,
        $timeout,
        $window,
        applications,
        guidService,
        closureService,
        localStorageService,
        $interval
    ) {
        this.$log = $log;
        this.$scope = $scope;
        this.$sce = $sce;
        this.$state = $state;

        this.$window = $window;
        this.$timeout = $timeout;
        this.$interval = $interval;

        this.appModel = appModel;
        this.guidService = guidService;

        this.applications = applications;
        this.closureService = closureService;

        this.activityUndeletes = {};
        this.firebaseAppModel = firebaseAppModel;
        this.localStorageService = localStorageService;

        this.queuesRef = firebaseModel.getFirebaseRef('activities/queues');

        this.originalQueueState = void 0;

        this.popup = 'step-1';
        this.queues = { items: {}, order: [] };

        this.queueName = '';

        this.lastDeletedQueueId = undefined;

        this.timeouts = [];

        this.queuesRef.child('order').on('value', (ref) => {

            let activeQueueId = this.firebaseAppModel.app.activeQueueId;

            if (activeQueueId) {
                this.activeQueueId = activeQueueId;
                this.firebaseAppModel.setActiveQueueId(null);
            }

            this.loadQueue(this.queues.order, ref.val() || []);
        });

    }

    loadQueue(orderCurr, orderNext) {
        if (this.activeActivity) {
            if (!this.appModel.app.store) {
                this.detailView = true;
                this.$scope.$evalAsync();
            }
        }

        let currQueueIds = new Set(orderCurr);
        let nextQueueIds = new Set(orderNext);

        let createdQueueIds = new Set([...nextQueueIds].filter(x => currQueueIds.has(x) === false)); // creates = next - curr
        let deletedQueueIds = new Set([...currQueueIds].filter(x => nextQueueIds.has(x) === false)); // deletes = curr - next

        for (let queueId of createdQueueIds) {
            this.queues.items[queueId] = this.queues.items[queueId] || {
                items: {},
                order: []
            };

            this.queuesRef.child(`items`).child(`${queueId}`).on('value', this.closureService.closureify(queueId,
                (queueId, ref) => {
                    let queue = ref.val();

                    if (queue) {
                        this.queues.items[queueId].name = queue.name;

                        let nextActivityIds = new Set(queue.order || []);
                        let currActivityIds = new Set(this.queues.items[queueId].order);

                        let createdActivityIds = new Set([...nextActivityIds].filter(x => currActivityIds.has(x) === false)); // creates = next - curr
                        let deletedActivityIds = new Set([...currActivityIds].filter(x => nextActivityIds.has(x) === false)); // deletes = curr - next

                        for (let activityId of createdActivityIds) {
                            this.queues.items[queueId].items[activityId] = queue.items[activityId];
                        }

                        for (let activityId of deletedActivityIds) {
                            delete this.queues.items[queueId].items[activityId];
                        }

                        this.queues.items[queueId].order.length = 0;
                        this.queues.items[queueId].order.push(...nextActivityIds);

                        this.$scope.$evalAsync();
                    } else {
                        // let's try to do nothing
                    }
                }
            ));
        }

        for (let queueId of deletedQueueIds) {
            delete this.queues.items[queueId];
        }

        this.queues.order.length = 0;
        this.queues.order.push(...orderNext);

        if (!this.queues.order.length) {
            let queueId = this.guidService.generateUUID();

            this.queues.items[queueId] = {
                name: 'Queue 1',
                items: {},
                order: []
            };

            this.queues.order.push(queueId);

            this.queuesRef.child('items').child(queueId).set(this.queues.items[queueId]);
            this.queuesRef.child('order').set(this.queues.order);
        }

        this.$scope.$evalAsync();
    }

    get activeDrawer()  {
        return this.appModel.app.activeDrawer;
    }

    get activeQueue() {
        if (this.queues) {
            return this.queues.items[this.activeQueueId];
        } else {
            return null;
        }
    }

    set activeQueue(value) {
        for (let queueId in this.queues.items) {
            if (this.queues.items.hasOwnProperty(queueId)) {
                let queue = this.queues.items[queueId];

                if (queue === value) {
                    this.activeQueueId = queueId;
                    break;
                }
            }
        }
    }

    get activeQueueId() {

        if (this.queues && this.queues.items) {
            // let queueId = this.firebaseAppModel.app.activeQueueId;
            let queueId = this.localStorageService.get('activeQueueId');

            if (queueId && this.queues.items[queueId]) {
                return queueId;
            }

            if (this.queues.order.length) {
                return this.queues.order[0];
            }
        }

        return null;
    }

    set activeQueueId(queueId) {
        // this.firebaseAppModel.setActiveQueueId(queueId);
        this.localStorageService.set('activeQueueId', queueId);
    }

    openActivity(activity) {
        if (!this.appModel.app.store) {
            this.detailView = true;
        }

        this.activeActivity = activity;
    }

    get activeActivity() {
        return this.firebaseAppModel.app.activeActivity
    }

    set activeActivity(activity) {
        if (this.appModel.app.store) {

            let itemState = 'app.store.activities.detail';
            if (activity.activity_type === 'assessment') {
                itemState = 'app.store.assessments.detail';
            } else if (activity.activity_type === 'game') {
                itemState = 'app.store.games.detail';
            } else if (activity.activity_type === 'lesson') {
              itemState = 'app.store.lessons.detail';
            }

            console.log('Item state (open store detail): ', itemState);

            this.$state.go(itemState, {
                slug: activity.slug
            });
        } else {

            if (!this.firebaseAppModel.app.activeActivity || activity.id != this.firebaseAppModel.app.activeActivity.id) {
                this.firebaseAppModel.setActiveActivity(activity);
                this.detailView = true;
            }
        }
    }

    toggle(value) {
        if (this.isQueued(value)) {
            this.removeFromActiveQueue(value);
        } else {
            this.addToActiveQueue(value);
        }
    }

    getResourceType(activity) {
        let isAssessment, isGame, isLesson, type = 'activity';
        if (activity.hasOwnProperty('resource_uri')) {
            isAssessment = activity.resource_uri.toLowerCase().indexOf('assessment') >= 0;
            isGame = activity.resource_uri.toLowerCase().indexOf('game') >= 0;
            isLesson = activity.resource_uri.toLowerCase().indexOf('lesson') >= 0;
        } else {
            isAssessment = activity.activity_type === 'assessment';
            isGame = activity.activity_type === 'game';
            isLesson =  activity.activity_type === 'lesson';
        }
        if(isAssessment) {
            type = 'assessment';
        } else if(isGame) {
            type = 'game';
        }else if(isLesson) {
          type = 'lesson';
      }
        return type;
    }

    isQueued(value) {
        return !!~this.indexOf(value);
    }

    indexOf(value) {
        var queue = this.activeQueue;
        if (queue) {
            return _.findIndex(queue.order.map(id => queue.items[id]), (a) => {
                if (a) {
                    let sameId = a.id === value.id;
                    let sameType = this.getResourceType(a) === this.getResourceType(value);
                    let sameQueue = a.queue === value.queue;

                    return sameId && sameType && sameQueue;
                } else {
                    return false;
                }
            });
        } else {
            return -1;
        }
    }

    editActiveQueueOrdering() {
        this.queuesRef.child('items').child(this.activeQueueId).child('order').set(this.activeQueue.order);
    }

    addToActiveQueue(value, index) {
        if (value && value.id) {
            if (!this.isQueued(value)) {
                const activityType = this.getResourceType(value);

                let queueId = this.activeQueueId;
                let activityId = this.guidService.generateUUID();
                let activity = {
                    id: value.id,
                    slug: value.slug,
                    queueId: queueId,
                    activityId: activityId,
                    thumbnail_url: value.thumbnail_url,
                    type: activityType === 'game' ? value.game_type : value.activity_type ,
                    activity_type: activityType,
                    name: value.name,
                    session_id: this.guidService.generateUUID() //TODO replace this by whatever mechanism creates the sessions
                };

                this.queuesRef.child('items').child(queueId).child('items').child(activityId).set(activity);

                this.activeQueue.items[activityId] = activity;

                if (_.isUndefined(index)) {
                    this.activeQueue.order.push(activityId);
                } else {
                    this.activeQueue.order.splice(index, 0, activityId);
                }

                this.queuesRef.child('items').child(this.activeQueueId).child('order').set(this.activeQueue.order);
            }
        }
    }

    removeFromActiveQueue(activity) {
        let queueId = this.activeQueueId;
        let queue = this.queues.items[queueId];
        let index = _.isString(activity) ? queue.order.indexOf(activity) : this.indexOf(activity);
        let activityId = queue.order[index];

        activity = queue.items[activityId];
        activity.softDeleted = true;

        let order = queue.order.slice();
        let items = queue.order.map(id => queue.items[id]);

        this.queuesRef.child('items').child(queueId).child('items').child(activityId).remove();
        this.queuesRef.child('items').child(queueId).child('order').set(queue.order.filter(id => queue.items[id] && !queue.items[id].softDeleted));

        for (let i = 0; i < order.length; i++) {
            queue.items[order[i]] = items[i];
        }

        queue.order.length = 0;
        queue.order.push(...order);

        if (!this.activityUndeletes[activityId]) {
            let timeout = this.$interval(() => { // Native setTimeout function is used intentionally to allow e2e tests react to dom change without waiting for angular $timeouts to finish
                this.commitActivityRemoval(activityId);
            }, 5000, 1);

            this.timeouts.push(timeout);

            this.activityUndeletes[activityId] = () => {
                this.$interval.cancel(timeout);
                this.timeouts.splice(this.timeouts.indexOf(timeout), 1);

                activity.softDeleted = false;

                let order = [];

                for (let i = 0; i < queue.order.length; i++) {
                    let queueId = queue.order[i];
                    if (queue.items[queueId] && !queue.items[queueId].softDeleted) {
                        order.push(queueId);
                    }
                }


                let orderOrig = queue.order.slice();
                let itemsOrig = queue.order.map(id => queue.items[id]);

                this.queuesRef.child('items').child(queueId).child('items').child(activityId).set(activity);
                this.queuesRef.child('items').child(queueId).child('order').set(order);

                for (let i = 0; i < orderOrig.length; i++) {
                    queue.items[orderOrig[i]] = itemsOrig[i];
                }

                queue.order.length = 0;
                queue.order.push(...orderOrig);

                delete this.activityUndeletes[activityId];
            };
        }
    }

    deletePendingActivities() {
        this.timeouts.forEach((timeout) => {
            this.$interval.cancel(timeout);
        });
        this.timeouts = [];
        let queueId = this.activeQueueId;
        let queue = this.queues.items[queueId];
        Object.keys(queue.items).forEach(activityId => {
            if (queue.items[activityId].softDeleted) {
                this.commitActivityRemoval(activityId);
            }
        });
    }

    commitActivityRemoval(activityId) {
        let queueId = this.activeQueueId;
        let queue = this.queues.items[queueId];
        let index = queue.order.indexOf(activityId);

        if (index > -1) {
            delete queue.items[activityId];
            queue.order.splice(index, 1);
        }
    }

    unremoveFromActiveQueue(activity) {
        let queueId = this.activeQueueId;
        let queue = this.queues.items[queueId];

        let index = _.isString(activity)
            ? queue.order.indexOf(activity)
            : this.indexOf(activity);

        let activityId = queue.order[index];

        this.activityUndeletes[activityId]();
    }

    create(queueName) {
        if (!queueName) {
            var i = 1;

            var list = this.queues.order.map((i) => this.queues.items[i].name);

            while (list.indexOf('Queue ' + i) >= 0) {
                i++;
            }

            queueName = 'Queue ' + i;
        }

        let queueId = this.guidService.generateUUID();
        let queue = {
            name: queueName,
            items: {},
            order: []
        };

        let newOrder = this.queues.order.slice();
        newOrder.unshift(queueId);

        // this.queues.order.unshift(queueId);
        this.queues.items[queueId] = queue;

        this.queuesRef.child('items').child(queueId).set(queue);
        this.queuesRef.child('order').set(newOrder);

        this.queues.order = newOrder;

        this.activeQueueId = queueId;
    }

    onSortQueues() {
        this.queuesRef.child('order').set(this.queues.order);
    }

    createDisabled () {
        return this.queues.order.length < 50;
    }

    editQueueName(e) {
        this.popup = 'step-2';
        this.$timeout( () => {
            this.directive.focusOnQueueName();
        }, 100);

    }

    openStore() {
        // Use FireBase as cross-window communication layer
        //
        this.firebaseAppModel.setActiveQueueId(this.activeQueueId);

        const storeURL = this.applications.toychest.url;
        const newWindow = this.$window.open(storeURL, '_blank');
        newWindow.focus();
    }

    removeActiveQueue(keepQueue = false) {
        this.deletePendingActivities();
        let queueId = this.activeQueueId;

        if (!this.originalQueueState) {
            this.originalQueueState = {
                deletes: 0,
                order: this.queues.order.slice(),
                items: this.queues.order.map(id => JSON.parse(JSON.stringify(this.queues.items[id])))
            }
        }

        this.originalQueueState.deletes++;

        if (keepQueue) {
            this.queuesRef.child('items').child(queueId).child('items').remove();
            this.queuesRef.child('items').child(queueId).child('order').remove();

            let activeQueue = this.activeQueue;

            this.lastDeletedQueueId = activeQueue.queueId;

            activeQueue.cleared = true;
            activeQueue.order.forEach(id => {
                delete activeQueue.items[id];
            });

            activeQueue.items.length = 0;

        } else {
            this.queuesRef.child('items').child(queueId).remove();

            delete this.queues.items[queueId];

            this.lastDeletedQueueId = queueId;

            let index = this.queues.order.indexOf(queueId);
            this.queues.order.splice(index, 1);
            this.queuesRef.child('order').set(this.queues.order);

            if (index >= this.queues.order.length) {
                this.activeQueueId = this.queues.order[this.queues.order.length - 1];
            } else {
                this.activeQueueId = this.queues.order[index];
            }
        }

        if (this.queueUndeleteTimer) {
            this.$timeout.cancel(this.queueUndeleteTimer);
        }

        this.queueUndeleteTimer = this.$timeout(() => {
            this.originalQueueState = null;
        }, 5000);

        var doc = angular.element(document);

        if (doc && doc.trigger) doc.trigger('closeAllDropdowns');
    }
    
    undoRemoveQueues() {
        this.$timeout.cancel(this.queueUndeleteTimer);

        this.queues.order.length = this.originalQueueState.order.length;

        for (let i = 0; i < this.originalQueueState.order.length; i++) {
            let queue = this.originalQueueState.items[i];
            let queueId = this.originalQueueState.order[i];

            if (!this.queues.items[queueId] || this.queues.items[queueId].cleared) {

                if (this.lastDeletedQueueId && queueId === this.lastDeletedQueueId) {
                    this.activeQueue = queue;
                    this.activeQueueId = queueId;
                }

                this.queues.items[queueId] = queue;
                this.queuesRef.child('items').child(queueId).set(queue);
            }

            this.queues.order[i] = queueId;
        }

        this.queuesRef.child('order').set(this.queues.order);

        this.originalQueueState = null;
    }

    selectQueue(queueId) {
        this.activeQueueId = queueId;
        this.popup = false;

        var doc = angular.element(document);

        if (doc && doc.trigger) doc.trigger('closeAllDropdowns');
    }

    getQueueItemClasses(activity) {
        if (activity) {
            let itemState;
            if (activity.activity_type === 'activity') {
                itemState = 'app.store.activities.detail';
            } else if (activity.activity_type === 'assessment') {
                itemState = 'app.store.assessments.detail';
            } else if (activity.activity_type === 'lesson') {
              itemState = 'app.store.lessons.detail';
            }

            let classes = {
                'activity': true,
                'store-queue': this.appModel.app.store,
                'no-thumbnail': !activity.thumbnail_url,
                'active': this.$state.is(itemState, { slug: activity.slug })
            };
            classes[activity.type] = true;
            classes.deleted = activity.softDeleted;

            return classes;
        } else {
            return { };
        }

    };

    handleDirectiveInitialize(directive) {
        this.directive = directive;
    }

    handleKeyPress(name, $event){
        if ($event.keyCode === 13) {
            if (name) this.activeQueue.name = name;
            this.queuesRef.child('items').child(`${this.activeQueueId}`).child('name').set(this.activeQueue.name);
            this.popup = 'step-1';
        }

        if ($event.keyCode === 27) {
            this.popup = 'step-1';
        }
    }

    getQueueClasses() {
        return {
            'in': this.appModel.app.activeDrawer === 'activities' && this.queues && !this.appModel.drawerIsHidden,
            'panel-2-active': this.detailView,
            'quick-add-active': this.appModel.app.quickAddOpen
        };
    };

    leaveDetail() {
        this.detailView = false;

        var doc = angular.element(document);

        if (doc && doc.trigger) doc.trigger('closeAllDropdowns');

        this.firebaseAppModel.setActiveActivity(null);
    }
}


QueueController.$inject = [
    '$scope',
    '$log',
    'appModel',
    'firebaseModel',
    'firebaseAppModel',
    '$state',
    '$sce',
    '$timeout',
    '$window',
    'applications',
    'guidService',
    'closureService',
    'localStorageService',
    '$interval'
];

function QueueDirective() {
    return {
        restrict: 'E',
        scope: true,
        require: ['queue', '^queueCommunicator'],
        controller: QueueController,
        controllerAs: 'queueCtrl',
        templateUrl: '/core/pl-queue/src/queue.tpl.html',
        link: function link(scope, element, attributes, controllers) {
            let queueNameInput = element.find('.queue-name-edit-input');

            let queueCtrl = controllers[0];
            let queueCommunicatorCtrl = controllers[1];

            queueCommunicatorCtrl.initializeQueueCtrl(queueCtrl);

            queueCtrl.handleDirectiveInitialize({
                focusOnQueueName: function () {
                    queueNameInput.focus();
                }
            });
        }
    };
}

QueueDirective.$inject = [
];

module.exports = QueueDirective;
