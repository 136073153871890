function plOverlayDropdownDirective($timeout, plOverlayDropdown) {
    return {
        restrict: 'A',
        scope: {
            dropContent: '@plOverlayDropdownContent',
            // A css selector of a container to keep the dropdown confined to. Defaults to window.
            container: '@',
            appendTo: '@',
            classes: '@',
            offsetX: '@',
            multiclick: '=',
            fromBottom: '=',
        },
        link: ($scope, $element) => {
            let container = $scope.container ? $($scope.container) : $(window);
            let appendTo = $scope.appendTo ? $scope.appendTo : 'body';

            let dropdown = plOverlayDropdown.createDropdown($element,
             appendTo, $scope.dropContent, $scope.fromBottom, $scope.classes);
            if (dropdown) {
                // This needs to be on mouseup to allow button events to fire on mouse down.
                const clickEvent = 'ontouchend' in document.documentElement ? 'touchend' : 'mouseup';
                $(document).on(clickEvent, function(evt) {
                    plOverlayDropdown.onDocumentClick(evt, $element, dropdown, container,
                     appendTo, $scope.offsetX, $scope.fromBottom, $scope.multiclick);
                });
                $(document).on('closeAllDropdowns', function() {
                    dropdown.removeClass('animate-in');
                    dropdown.active = false;
                });
            }
        },
    };
}

plOverlayDropdownDirective.$inject = ['$timeout', 'plOverlayDropdown'];
module.exports = plOverlayDropdownDirective;
