function plApiLanguageFactory(plHttp, $q, plLodash) {
    const plApiLanguage = {};
    let _languages = [];

    plApiLanguage.get = (data = {}) => {
        const deferred = $q.defer();

        if (_languages && _languages.length) {
            deferred.resolve(_languages);
        } else {
            plHttp.get('languages', data)
                .then((res) => {
                    _languages = res.data.results;
                    deferred.resolve(_languages);
                }, (err) => {
                    deferred.reject(err);
                });
        }
        return deferred.promise;
    };

    plApiLanguage.formSelectOpts = (languages1, skipCodes = []) => {
        const languages = languages1 || _languages;
        const opts = [];
        languages.forEach((language) => {
            if (skipCodes.indexOf(language.code) < 0) {
                opts.push({
                    value: language.code,
                    label: language.name,
                });
            }
        });
        return opts;
    };

    plApiLanguage.getInfo = (code) => {
        if (!code) {
            return null;
        }
        const index = plLodash.findIndex(_languages, 'code', code);
        return (index > -1) ? _languages[index] : null;
    };

    return plApiLanguage;
}
plApiLanguageFactory.$inject = ['plHttp', '$q', 'plLodash'];
module.exports = plApiLanguageFactory;
