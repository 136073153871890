var TagSwitchDirective = function() {
    return {
        restrict: 'E',
        scope: {
            tag: "@",
            activity: "="
        },
        replace: true,
        transclude: true,
        templateUrl: '/toychest/common/directives/tagSwitch/tagSwitch.tpl.html',
        link: function($scope, $element, attrs) {
            $scope.toggleTag = function() {
                var tagIndex = getTagIndex($scope.tag);
                if (tagIndex >= 0) {
                    $scope.activity.$removeTag($scope.tag);
                } else {
                    $scope.activity.$addTag($scope.tag);
                }
            };
            $scope.getSwitchClasses = function() {
                return {
                    'tag-switch': true,
                    'active': getTagIndex($scope.tag) >= 0
                };
            };

            function getTagIndex(tagName) {
                if(!$scope.activity) return;
                return _.findIndex($scope.activity.tags, function(tag) {
                    return tag.name === tagName;
                });
            }
        }
    }
};

TagSwitchDirective.$inject = [];
module.exports = TagSwitchDirective;
