var ImageEditingToolDirective = function($timeout) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            model: '=model'
        },
        templateUrl: '/toychest/common/directives/ImageEditingTool/ImageEditingToolDirective.tpl.html',
        link: function($scope) {

            var lastUsedHandleClass = "";
            var html = $("html");

            $scope.updateOffsets = function (model) {
                if ($scope.isVertical(model)) {
                    var distHeight = model.getOriginalImage().height * (150 / model.getOriginalImage().width);
                    model.setMaximumOffsets(150 - model.selectionWidth, distHeight - model.selectionHeight);
                } else {
                    distHeight = model.getOriginalImage().height * (180 / model.getOriginalImage().width);
                    model.setMaximumOffsets(180 - model.selectionWidth, distHeight - model.selectionHeight);
                }
            };

            $scope.isVertical = function (model) {
                if (model && model.getOriginalImage()) {
                    return model.getOriginalImage().height > model.getOriginalImage().width;
                } else {
                    return false;
                }
            };

            $scope.calculateContainerSize = function (model) {
                if ($scope.isVertical(model)) {
                    return model.getOriginalImage().height * (150 / model.getOriginalImage().width);
                } else {
                    return 180;
                }
            };

            $scope.calculateContainerTop = function (model) {
                var heightDiff;
                var distHeight;

                if ($scope.isVertical(model)) {
                    return 0;
                } else {
                    distHeight = model.getOriginalImage().height * (180 / model.getOriginalImage().width);
                    heightDiff = 180 - distHeight;
                    return heightDiff / 2;
                }
            };

            $scope.calculateContainerLeft = function (model) {
                if ($scope.isVertical(model)) {
                    return ($scope.calculateContainerSize(model) - 150) / 3;
                } else {
                    return 0;
                }
            };

            $scope.drag = function ($event) {
                if ($scope.draggingCropImage && $scope.currentDraggedModel) {
                    var diffX = $event.screenX - $scope.lastMouseX;
                    var diffY = $event.screenY - $scope.lastMouseY;

                    $scope.backgroundClickDisabled = true;

                    var temp = diffX;

                    if ($scope.currentDraggedModel.rotation === 90) {
                        diffX = diffY;
                        diffY = -temp;
                    } else if ($scope.currentDraggedModel.rotation === 180) {
                        diffX = -diffX;
                        diffY = -diffY;
                    } else if ($scope.currentDraggedModel.rotation === 270) {
                        diffX = -diffY;
                        diffY = temp;
                    }

                    $scope.currentDraggedModel.setOffsets($scope.currentDraggedModel.xOffset + diffX,
                        $scope.currentDraggedModel.yOffset + diffY);

                    $scope.lastMouseX = $event.screenX;
                    $scope.lastMouseY = $event.screenY;
                }

                if ($scope.draggingScaleArea && $scope.currentDraggedModel) {
                    $scope.resizeSelection($scope.currentDraggedModel, $event);
                }

                $event.stopPropagation();
                $event.preventDefault();

                return false;
            };

            $scope.becameDragged = function (model, $event) {
                if ($event.button === 0) {

                    $scope.currentDraggedModel = model;
                    $scope.draggingCropImage = true;
                    $scope.lastMouseX = $event.screenX;
                    $scope.lastMouseY = $event.screenY;

                    $scope.backgroundClickDisabled = true;

                    var lastUsedImageContainer = $($event.currentTarget);

                    let htmlMouseMove = function(e) {
                        $scope.drag(e);

                        lastUsedImageContainer.css({ left: $scope.currentDraggedModel.xOffset + 'px',
                            top:  $scope.currentDraggedModel.yOffset + 'px',
                            width: $scope.currentDraggedModel.selectionWidth + 'px',
                            height: $scope.currentDraggedModel.selectionHeight + 'px',
                            'background-position': '-' + $scope.currentDraggedModel.xOffset + 'px' +
                            ' -' + $scope.currentDraggedModel.yOffset + 'px'});
                    };


                    let htmlMouseUp = function(e) {
                        $scope.stoppedBeingDragged(e);

                        html.off('mouseup', htmlMouseUp);
                        html.off('mousemove', htmlMouseMove);
                    };


                    html.on('mousemove', htmlMouseMove);
                    html.on('mouseup', htmlMouseUp);

                    $event.stopPropagation();
                    $event.preventDefault();
                }
            };

            $scope.stoppedBeingDragged = function ($event) {

                if ($scope.draggingCropImage || $scope.draggingScaleArea) {
                    $scope.draggingCropImage = false;
                    $scope.draggingScaleArea = false;

                    $event.isImmediatePropagationStopped = function() {
                        return true;
                    };
                }

                $event.stopPropagation();
                $event.preventDefault();

                $timeout(function() {
                    $scope.backgroundClickDisabled = false;
                }, 20);

                $scope.currentDraggedModel = null;
            };

            $scope.startSelectionResize = function (model, $event) {
                if ($event.button === 0) {
                    $scope.lastMouseX = $event.screenX;
                    $scope.lastMouseY = $event.screenY;
                    $scope.currentDraggedModel = model;
                    $scope.draggingScaleArea = true;
                    $scope.backgroundClickDisabled = true;
                    lastUsedHandleClass = $event.target.className;

                    var lastUsedImageContainer = $($event.currentTarget.parentNode.parentNode);

                    let htmlMouseMove = function(e) {
                        $scope.resizeSelection($scope.currentDraggedModel, e);

                        lastUsedImageContainer.css({ left: $scope.currentDraggedModel.xOffset + 'px',
                            top:  $scope.currentDraggedModel.yOffset + 'px',
                            width: $scope.currentDraggedModel.selectionWidth + 'px',
                            height: $scope.currentDraggedModel.selectionHeight + 'px',
                            'background-position': '-' + $scope.currentDraggedModel.xOffset + 'px' +
                            ' -' + $scope.currentDraggedModel.yOffset + 'px'});
                    };


                    let htmlMouseUp = function(e) {
                        $scope.stoppedBeingDragged(e);

                        html.off('mouseup', htmlMouseUp);
                        html.off('mousemove', htmlMouseMove);
                    };


                    html.on('mousemove', htmlMouseMove);
                    html.on('mouseup', htmlMouseUp);

                    $event.stopPropagation();
                    $event.preventDefault();
                }
            };

            $scope.resizeSelection = function (model, $event) {
                if (!$scope.draggingScaleArea || !lastUsedHandleClass) return;

                var diffX = $event.screenX - $scope.lastMouseX;
                var diffY = $event.screenY - $scope.lastMouseY;

                var temp = diffX;

                if (model.rotation === 90) {
                    diffX = diffY;
                    diffY = -temp;
                } else if (model.rotation === 180) {
                    diffX = -diffX;
                    diffY = -diffY;
                } else if (model.rotation === 270) {
                    diffX = -diffY;
                    diffY = temp;
                }

                var max = diffX > diffY ? diffX : diffY;
                var selectionWasActuallyChanged = true;

                if (model.rotaton == 180) {
                    max = -max;
                }

                $scope.lastMouseX = $event.screenX;
                $scope.lastMouseY = $event.screenY;

                if (lastUsedHandleClass === "resize-lt") {

                    selectionWasActuallyChanged = model.setSelectionDimension(model.selectionWidth - max,
                        model.selectionHeight - max);

                    if (selectionWasActuallyChanged) {
                        $scope.updateOffsets(model);
                        model.setOffsets(model.xOffset + max, model.yOffset + max);
                    }

                } else if (lastUsedHandleClass === "resize-rt") {

                    if (diffX >= diffY) {
                        selectionWasActuallyChanged = model.setSelectionDimension(model.selectionWidth + max,
                            model.selectionHeight - max);
                    } else {
                        selectionWasActuallyChanged = model.setSelectionDimension(model.selectionWidth - max,
                            model.selectionHeight + max);
                    }

                    if (selectionWasActuallyChanged) {
                        $scope.updateOffsets(model);
                        if (diffX >= diffY) {
                            model.setOffsets(model.xOffset, model.yOffset - max);
                        } else {
                            model.setOffsets(model.xOffset, model.yOffset + max);
                        }

                    }

                } else if (lastUsedHandleClass === "resize-lb") {

                    if (diffX >= diffY) {
                        selectionWasActuallyChanged = model.setSelectionDimension(model.selectionWidth - max,
                            model.selectionHeight + max);
                    } else {
                        selectionWasActuallyChanged = model.setSelectionDimension(model.selectionWidth + max,
                            model.selectionHeight - max);
                    }

                    if (selectionWasActuallyChanged) {
                        $scope.updateOffsets(model);

                        if (diffX >= diffY) {
                            model.setOffsets(model.xOffset + max, model.yOffset);
                        } else {
                            model.setOffsets(model.xOffset - max, model.yOffset);
                        }
                    }

                } else if (lastUsedHandleClass === "resize-rb") {

                    selectionWasActuallyChanged = model.setSelectionDimension(model.selectionWidth + max,
                        model.selectionHeight + max);

                    if (selectionWasActuallyChanged) {
                        $scope.updateOffsets(model);
                    }
                }

                $event.stopPropagation();
                $event.preventDefault();
            };

            $scope.rotateClockwise = function (model) {
                model.setRotation(model.rotation + 90);
                if (model.rotation >= 360) model.setRotation(0);
            };
        }
    };
};

ImageEditingToolDirective.$inject = ['$timeout'];
module.exports = ImageEditingToolDirective;
