const ActivityFilter = require('./ActivityFilter');

var ActivitiesHomeController = function($log, $scope, $state, drfActivityModel, drfStoreHeroModel, heroService) {
    const FEATURED_ARROW_HEIGHT = 30;
    const PL_SECTION = 'pldrl';

    $scope.lists = [];
    $scope.featuredBorderTop = FEATURED_ARROW_HEIGHT;
    $scope.filter = new ActivityFilter();
    $scope.searchCallback = () => {
        $scope.filter.facet = 'all';
        $state.go(
            'app.store.activities.list',
            Object.assign(
                { listType: 'all', section: PL_SECTION },
                $scope.filter.getRouteParams()
            )
        );
    };

    var addList = function(title, filter, cssClass) {
        var list = {
            activities: new drfActivityModel.Collection(),
            filter: filter,
            loading: false,
            title: title,
            cssClass: cssClass,
        };

        list.activities.$clear();
        list.activities.$filter(filter);
        list.loading = true;
        list.activities.$fetch().then(function() {
            list.loading = false;
        });
        $scope.lists.push(list);
    };

    var fetchHero = function() {
        return heroService.getRandomHero();
    };

    addList('Most Popular', {
        'disabled': false,
        'private': false,
        'order_by': '-count',
        'exclude_activity_type': 'imageDeck',
        'section': PL_SECTION
    }, 'minted');
    addList('New Activities', {
        'disabled': false,
        'private': false,
        'order_by': 'created',
        'exclude_activity_type': 'imageDeck',
        'section': PL_SECTION
    }, 'lilac');

    fetchHero().then(function(hero) {
        $scope.hero = hero;
    });

    $('.featured main').scroll(function(e) {
        let scrollTop = e.target.scrollTop;
        if(scrollTop > FEATURED_ARROW_HEIGHT) {
            scrollTop = 0;
        } else {
            scrollTop = FEATURED_ARROW_HEIGHT - scrollTop;
        }
        $scope.featuredBorderTop = scrollTop;
        $scope.$apply();
    });
};

ActivitiesHomeController.$inject = ['$log', '$scope', '$state', 'drfActivityModel', 'drfStoreHeroModel', 'heroService'];
module.exports = ActivitiesHomeController;