"use strict";
var FlashcardPanelController = require('../flashcardsPanel/flashcardsPanelController');
DescribeFlashcardsController.$inject = ['$scope', '$state', 'localStorageService', 'FlashcardsWizardService', 'activityTags', 'currentUserModel', 'drfActivityModel'];

function DescribeFlashcardsController($scope, $state, localStorageService, FlashcardsWizardService, activityTags, currentUserModel, drfActivityModel) {
    FlashcardPanelController.apply(this, arguments);

    $scope.userCanShare = false;
    currentUserModel.getPermissions().then(permissions => {
        $scope.userCanShare = permissions.includes('lightyear.share_activity');
    });

    $scope.panelNavigation.current = 'describe-flashcards';
    $scope.currentUserModel = currentUserModel;

    $scope.activity =  _.extend(new drfActivityModel.Model(), {
        'activity_type': FlashcardsWizardService.type,
        'type': FlashcardsWizardService.type,
        'tags': [],
        private: true,
        section: 'ugc'
    }, FlashcardsWizardService.flashcard);

    //temporarily, just always make activities private upon entering this view
    // JB - 12/16/20
    $scope.activity.private = true;

    $scope.isPldrl = false;

    let isSaving = false;
    var resource_uri = $scope.activity.resource_uri;
    delete $scope.activity.resource_uri;

    function extractFlashcard(flashcard, resource_uri) {
        var copy = angular.merge({}, flashcard);

        for (var image of copy.images) {
            delete image.cropped;
        }

        if ('resource_uri' in copy) copy.resource_uri = resource_uri;

        return copy;
    }

    var cancelWatch = $scope.$watch(() => {
        return JSON.stringify(extractFlashcard($scope.activity, resource_uri))
    }, function () {
        FlashcardsWizardService.flashcard.name = $scope.activity.name;
        FlashcardsWizardService.flashcard.private = $scope.activity.private;
        FlashcardsWizardService.flashcard.description = $scope.activity.description;

        localStorageService.set('FLASHCARD_' + FlashcardsWizardService.mode, extractFlashcard($scope.activity, resource_uri));
    });

    var getDescriptor = (activity) => {
        return JSON.stringify({
            title: activity.name,
            description: activity.description,
            cards: activity.images.map((image) => {
                var copy = angular.merge({}, image);

                if (image.title && image.url) {
                    copy.type = "both";
                } else if (image.title ) {
                    copy.type = "title";
                } else if (image.url) {
                    copy.type = "image";
                } else {
                    copy.type = "unknown";
                }

                delete copy.cropped;

                return copy;
            })
        });
    };

    $scope.$watch('isPldrl', (isPldrl) => {
        if (isPldrl) {
            $scope.activity.section = 'pldrl';
            $scope.activity.private = false;
        } else {
            $scope.activity.section = 'ugc';
            $scope.activity.private = true;
        }
    });

    $scope.saveActivity = function () {
        if (isSaving) {
            return;
        }
        isSaving = true;
        $scope.activity.descriptor = getDescriptor($scope.activity);

        if (FlashcardsWizardService.flashcard.resource_uri) {
            $scope.activity.resource_uri = resource_uri;
        }

        $scope.activity.large_thumbnail_url = $scope.activity.images[0].url;
        $scope.activity.thumbnail_url = $scope.activity.images[0].url;

        $scope.activity
            .$save()
            .then(function(result) {
                delete $scope.activity.resource_uri;

                cancelWatch();

                localStorageService.remove('FLASHCARD_' + FlashcardsWizardService.mode);
                $state.go('app.store.activities.detail', {
                    slug: result.slug
                });
            }, function(result) {
                isSaving = false;
                $scope.errorObject = result.errorObject;
            });
    };

    const TAGS_PER_COLUMN = 8;
    const DEFAULT_CLINICIAN_TYPE = 'SLT';

    $scope.tags = {
        grades: activityTags.grades.tags
    };

    setTimeout(function() {
        $(window).trigger('resizeTextElements');
    }, 300);

    $scope.tagTabs = function() {
        let tabs = Object.keys(activityTags)
            .map((key) => {
                return {
                    key: key,
                    title: activityTags[key].title
                };
            }).filter((tab) => tab.title !== activityTags.grades.title);
        return _.sortBy(tabs, 'title');
    }();

    $scope.selectTab = function(tab) {
        $scope.currentTab = tab;
        $scope.tags.subjects = Object.keys(activityTags[$scope.currentTab.key].tags).map((key) => {
            return {
                key: key,
                name: activityTags[tab.key].tags[key]
            };
        });
    };

    function setDefaultTab() {
        let clinitianType = DEFAULT_CLINICIAN_TYPE;
        if (currentUserModel.user && currentUserModel.user.serviceTypes && currentUserModel.user.serviceTypes.length) {
            clinitianType = currentUserModel.user.serviceTypes[0].name;
        }
        let defaultTab = $scope.tagTabs.find((tab) => tab.key === clinitianType);
        $scope.tagTabs = [defaultTab, ..._.without($scope.tagTabs, defaultTab)];
        $scope.selectTab(defaultTab);
    }
    setDefaultTab();

    $scope.numOfSubjectColumns = function() {
        let numberOfColumns = Math.ceil($scope.tags.subjects.length / TAGS_PER_COLUMN);
        return Array.from(new Array(numberOfColumns).keys());
    };

    $scope.subjectTagsForColumn = function(column) {
        return $scope.tags.subjects.filter((tag, idx) => (idx < column * TAGS_PER_COLUMN && idx >= (column - 1) * TAGS_PER_COLUMN));
    };

    $scope.onTagsChange = $scope.activity.onTagsChange;

    $scope.getPreviewImage = () => {
        return FlashcardsWizardService.flashcard.images[0];
    }
}

module.exports = DescribeFlashcardsController;
