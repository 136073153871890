/**
 * @param {Object} $window
 * @param {Object} $scope
 * @param {Function} $timeout
 * @param {ActivityModel} activityModel
 * @param {FirebaseAppModel} fireBaseAppModel
 * @param {FlashCards} flashCards
 * @param {RoomClickService} RoomClickService
 * @constructor
 */
var FlashcardsDrawerController = function(
    $window,
    $scope,
    $timeout,
    activityModel,
    fireBaseAppModel,
    flashCards,
    RoomClickService
) {

    // notify FlashcardController about key event on drawer
    var fields = ['keyCode', 'charCode', 'shiftKey', 'metaKey', 'altKey', 'ctrlKey', 'type'];
    var html = $window.$('html');

    html.on('keydown', function(e) {
        activityModel.channel.call({
            method: 'PL-Remote-Dispatcher::event',
            params: _.pick(e, fields)
        });
    });

    $scope.flashcardsOnBoard = [];
    $scope.gotInitiatedList = false;

    function updateFlashcardsOnBoard(params) {
        $scope.$evalAsync(() => {

            if(!$scope.gotInitiatedList && params.isInitialized) {
                $scope.gotInitiatedList = true;
            }

            $scope.flashcardsOnBoard = params.cards;
        });
    }

    $scope.showCards = true;
    $scope.showTitle = true;
    $scope.showTitleGrayed = false;

    function updateShowTitleStatus(selectedCards) {
        let cards = selectedCards.length
                    ? selectedCards
                    : $scope.flashcardsOnBoard;

        var hideTitlesValues = _.pluck(cards, 'type');

        if(hideTitlesValues.length) {
            $scope.showTitle = hideTitlesValues[0] === 'both';
        }

        else {
            $scope.showTitle = true;
        }

        if (selectedCards.length) {
            checkShowTitleGrayed(hideTitlesValues);
        }

        $scope.$evalAsync();
    }

    function checkShowTitleGrayed(hideTitlesValues) {
        $scope.showTitleGrayed = _.uniq(hideTitlesValues).length > 1;

        if($scope.showTitleGrayed) {
            $scope.showTitle = false;
        }
    }

    $scope.onShowTitleChange = function() {
        const value = $scope.showTitle;
        activityModel.channel.call({
            method: 'flashcardsChangeShowTitle',
            params: value
        });

        $scope.showTitleGrayed = false;
        $timeout();
    };

    activityModel.foundationLoaded.then(function() {
        activityModel.channel.bind('flashcardsOnBoard', (trans, params) => updateFlashcardsOnBoard(params));
        activityModel.channel.bind('flashcardsSelected', (trans,  selectedCards) => updateShowTitleStatus(selectedCards));
    });

    $scope.activity = activityModel.activity;
    $scope.flashcardsModel = flashCards;

    $scope.showViewSwitcher = true; // May persist in cookies

    $scope.toggleViewSwitcher = function() {
        $scope.showViewSwitcher = !$scope.showViewSwitcher;
    };

    $scope.$watch(
        () => flashCards.hide,
        (hide) => ($scope.showCards = !hide)
    );

    $scope.activityLoaded = function() {
        return !!(_.propertyOf(fireBaseAppModel)('app.activeActivity') || activityModel.activity.config);
    };

    $scope.setFlashcardsNone = function(forceHide) {
        if(!$scope.activityLoaded() || !activityModel.channel) {
            return;
        }

        var newHideValue = forceHide;

        if(newHideValue === undefined) {
            newHideValue = !$scope.showCards;
        }
        
        activityModel.channel.call({
            method: 'flashcardsHide',
            params: newHideValue,
            success: function() {
                flashCards.setHideCards(newHideValue);
            }
        });

        $scope.$evalAsync();
    };

    $scope.setFlashcardsMode = function(mode) {

        if(!$scope.activityLoaded() || !activityModel.channel) {
            return;
        }

        activityModel.channel.call({
            method: 'flashcardsMode',
            params: mode,
            success: function() {
                flashCards.isLocalEvent = true;
                activityModel.activity.mode = mode;
                flashCards.setMode(mode);
                $scope.setFlashcardsNone(false);
            }
        });
    };

    $scope.deleteAllCards = () => {
        activityModel.channel.call({
            method: 'clearAnimation',
            success: function() {
                flashCards.deleteCards(flashCards.cards);
            }
        });

    };

    $scope.addAllCards = function() {
        if(!flashCards.isLinksReady()) {
            return;
        }

        var cards = $scope.getCards();

        if(!cards || !cards.length) {
            return;
        }
        
        activityModel.channel.call({
            method: 'addCardsOnWhiteboard',
            params: {
                cards: cards,
                all: true
            }
        })
    };

    var img = document.createElement('img');
    img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

    $scope.startDrag = function(e, card) {
        if(!$scope.gotInitiatedList) {
            return false;
        }

        RoomClickService.trigger('drawer', 'dragStart');
        e.dataTransfer.effectAllowed = "move";

        var data = JSON.stringify(card);

        e.dataTransfer.setData('flashcard', data);
        e.dataTransfer.setDragImage(img, 0, 0);
        
        activityModel.channel.call({
            method: 'flashcardDragging',
            params: card
        });
    };

    $scope.cancelDrag = function(e) {
        if(!activityModel.channel) {
            return false;
        }
        
        activityModel.channel.call({
            method: 'flashcardDragging',
            params: null
        });
    };

    $scope.getCards = function() {
        var cards = _.propertyOf($scope)('activity.config.cards');

        if (cards && cards.length) {
            cards = cards.filter(function(card) {
                return !_.find($scope.flashcardsOnBoard, {url:card.url, title:card.title});
            });
        }

        return cards;
    };
};

FlashcardsDrawerController.$inject = [
    '$window',
    '$scope',
    '$timeout',
    'activityModel',
    'firebaseAppModel',
    'flashCards',
    'RoomClickService'
];

module.exports = FlashcardsDrawerController;
