/**
 * datapoint model
 * @param $log
 */
var DataPointModel = function($log, tastyModel, applications) {

    var DataPoint = new tastyModel({
        apiUrl: '/tasty/v2/studentdatapoint/',
        base_uri: applications.platform.url,
    });

    Object.assign(DataPoint.Model.prototype, {

    });

    Object.assign(DataPoint.Collection.prototype, {
    });

    return DataPoint;
};

DataPointModel.$inject = ['$log', 'tastyModel', 'applications'];
module.exports = DataPointModel;