/**
 * Model that holds the state of the state of application controls and widgets
 * @param $log
 * @param $timeout
 * @param $firebaseModel
 */
var FirebaseAppModel = function ($log, $timeout, firebaseModel, dispatcherService) {
    this.ready = false;

    /**
     * Application state properties
     * @type {{commsMode: string, whiteboardActive: boolean, documentationActive: boolean, reward: null, reward_last_changed: null, bigVideoId: number, sharingMyScreen: boolean, screenShareFailed: boolean, screenShareSupported: boolean}}
     */
    var defaults = {
        whiteboardActive: false, //TODO fix disappearing wb button if false
        widgetsboardActive: false,
        reward: null,
        reward_last_changed: null,
        activeActivity: null,
        previousActiveActivity: null,
        guessWhoActive: false,
        compactMode: false,
        fullscreen: 'normal',
        mouseIsMuted: false,
        activeQueueId: null,
        shouldShowWorkspace: true,
        sameSiteUrl: '',
        token: null,
        promotedPersonas: {},
    };

    this.app = defaults;

    this.setFullscreen = (mode) => {
        this.app.fullscreen = mode;
        this.ref.update({
            fullscreen: mode
        });
    };

    this.setGuessWhoActive = (value) => {
        this.app.guessWhoActive = value;

        this.ref.update({
            guessWhoActive: value
        });
    };

    this.setPromotedPersonas = (guids) => {
        this.app.promotedPersonas = {};
        for (let i = 0; i < guids.length; i++) {
            this.app.promotedPersonas[guids[i]] = true;
        }
        this.ref.child('promotedPersonas').set(this.app.promotedPersonas);
    };

    this.promotePersona = (guid) => {
        if (this.app.promotedPersonas === null) {
            this.app.promotedPersonas = {};
        }
        this.app.promotedPersonas[guid] = true;
        const data = {};
        data[guid] = true;
        this.ref.child('promotedPersonas').update(data);
    };

    this.demotePersona = (guid) => {
        if (this.app.promotedPersonas === null) {
            this.app.promotedPersonas = {};
        }
        delete this.app.promotedPersonas[guid];
        this.ref.child('promotedPersonas').child(guid).remove();
    };

    this.setFullscreenBighead = function (guid) {
        this.app.bighead = guid;
        this.ref.update({
            bighead: guid
        });
    };

    this.setMouseMuted = function (value) {
        this.ref.update({
            mouseIsMuted: value,
        });
    };

    this.setChatMuted = function (value) {
        this.ref.update({
            chatIsMuted: value,
        });
    };

    /**
     * setWhiteboardActive
     * @param active
     */
    this.setWhiteboardActive = function (active) {
        this.app.whiteboardActive = active;
        this.ref.update({
            whiteboardActive: active
        });
    };

    /**
     * setGroupWriterActive
     * @param active
     */
    this.setTeamWriteActive = function (active) {
        this.app.teamWriteActive = active;
        this.ref.update({
            teamWriteActive: active
        });
    };

    /**
     *  setWidgetboardStatus
     *  @param active
     */
    this.setWidgetboardStatus = function (active) {
        this.app.widgetsboardActive = active;
        this.ref.update({
            widgetsboardActive: active
        });
    };

    /**
     *  shouldShowWorkspace
     *  @param {Boolean} value
     */
    this.showWorkspace = function (value) {
        this.app.shouldShowWorkspace = value;
        this.ref.update({
            shouldShowWorkspace: value
        });
    };

    /**
     *  setActiveActivity
     *  @param activityName
     */
    this.setActiveActivity = function (activity) {
        if (!this.ready) {
            return;
        }

        if (this.app.activeActivity) {
            this.app.previousActiveActivity = this.app.activeActivity;
        }

        this.ref.update({
            previousActiveActivity: null,
        });

        if (!activity) {
            this.ref.update({
                activeActivity: null
            });

            return;
        }

        this.app.activeActivity = activity;

        var sharedActivity = {};
        angular.forEach(activity, function (value, key) {
            if (key.indexOf('$') === -1) {
                sharedActivity[key] = value;
            }
        });
        this.ref.update({
            activeActivity: sharedActivity
        });
    };

    this.restorePreviousActivity = function () {
        if (this.app.previousActiveActivity) {
            this.setActiveActivity(this.app.previousActiveActivity);
        }

    };

    /**
     *  setActiveQueueId
     *  @param queueId
     */
    this.setActiveQueueId = function (queueId) {
        if (this.ready) {
            this.app.activeQueueId = queueId;
            this.ref.update({
                activeQueueId: queueId
            });
        }
    };

    this.setSameSiteUrl = (url) => {
        this.app.sameSiteUrl = url;
        this.ref.update({
            sameSiteUrl: url
        })
    }

    /**
     * setCoBrowseActive
     * @param active
     */
    this.setCoBrowseActive = function (active) {
        this.app.coBrowseActive = active;
        this.ref.update({
            coBrowseActive: active
        });
    };


    /**
     *  setRightsToken
     *  @param tokenValue
     */
    this.setRightsToken = function(token) {
        if (this.ready) {
            this.app.token = token;
            this.ref.update({
                rightsToken: token
            });
        }
    };

    /**
     * Screen share properties
     * @type {{name: string, style: {nameDisplayMode: string}, publishAudio: boolean, constraints: {video: {mandatory: {chromeMediaSource: string, maxWidth: Number, minWidth: Number, maxHeight: Number, minHeight: Number, maxFrameRate: number, minFrameRate: number}, optional: Array}, audio: boolean}, mirror: boolean, width: Number, height: Number, aspectRatio: number}}
     */
    var screenPublisherProps = {
        name: "screen",
        style: {
            nameDisplayMode: "off"
        },
        publishAudio: false,
        constraints: {
            video: {
                mandatory: {
                    chromeMediaSource: "screen",
                    maxWidth: screen.width,
                    minWidth: screen.width,
                    maxHeight: screen.height,
                    minHeight: screen.height,
                    maxFrameRate: 7,
                    minFrameRate: 7
                },
                optional: []
            },
            audio: false
        },
        mirror: false,
        width: screen.width,
        height: screen.height,
        aspectRatio: screen.width / screen.height
    };
    this.screenPublisherProps = screenPublisherProps;

    var onValue = _.bind(function (snap) {
        var snapDict = snap.val();
        if (snapDict == null) {
            //if the app object is null, let's put the defaults into fb
            this.ref.set(this.app);
            return;
        }
        var defaults = {};

        // build a set of defaults so the model can self configure
        _.each(this.app, function (value, key) {
            if (!snapDict.hasOwnProperty(key)) {
                defaults[key] = value;
            }
        }, this);

        // update the app model from firebase
        Object.assign(this.app, snapDict);
        this.ready = true;
        dispatchEvent(snap, snap.val(), 'value', 'firebaseAppModelChange', snap.key);
        $timeout(function () {
        }, 0);
    }, this);

    var onChanged = _.bind(function (snap) {
        if (this.ready) {
            this.app[snap.key] = snap.val();
            dispatchEvent(snap, snap.val(), 'change', 'firebaseAppModelChange', snap.key);
            $timeout(function () {
            }, 0);
        }
    }, this);

    var onChildRemoved = _.bind(function (snap) {
        if (this.ready) {
            this.app[snap.key] = null;
            dispatchEvent(snap, snap.val(), 'removed', 'firebaseAppModelChange', snap.key);
            $timeout(function () {
            }, 0);
        }
    }, this);

    function dispatchEvent(snap, data, srcType, targetType, key) {
        var event = {};
        event.type = srcType;
        event.data = data;
        event.key = key;
        event._snapshot = snap; //convenience
        dispatcherService.dispatch(targetType, null, event);
    }

    var setupHandlers = _.bind(function () {
        this.ref.on('value', onValue);
        this.ref.on('child_changed', onChanged);
        this.ref.on('child_removed', onChildRemoved);
    }, this);

    var init = _.bind(function () {
        console.log("[FirebaseAppModel] creating model");
        this.ref = firebaseModel.getFirebaseRef('app');
        setupHandlers();
    }, this);
    init();
};

FirebaseAppModel.$inject = ['$log', '$timeout', 'firebaseModel', 'dispatcherService'];
module.exports = FirebaseAppModel;
