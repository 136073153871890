/**
 * A SPI (service provider "interface") for our purposes
 * is a shared object between components of a service and
 * between the service and a higher level context/application,
 *
 * The service exposes an SPI that can be "implemented" via
 * passing functions that are used within the library components.
 */

function plRecordSPI() {

  const spi = {};
  spi.impl = {};

  spi.setIsPsychAssessmentFn = (fn) => {
    spi.impl.isPsychAssessment = fn;
  };

  return spi;

}

plRecordSPI.$inject = [];
module.exports = plRecordSPI;
