var AssetsController = function($log, $timeout, $scope, $state, $stateParams, $q, assetModel, uploaderModel, AssetTagModel, drfActivityModel, AssetsStateModel, AssetStateModel) {
    // this needs to be here so it can be updated after a file is uploaded
    $scope.assets = new assetModel.Collection();

    $scope.tags = AssetTagModel;
    $scope.dropzoneVisible = false;
    $scope.uploaderModel = uploaderModel;

    $scope.state = AssetsStateModel;
    $scope.assetState = AssetStateModel;

    $scope.dragEnter = function(event) {
        if (areValidFileTypes(event.originalEvent.dataTransfer.items)) {
            $scope.dropzoneVisible = true;
            event.preventDefault();
            return false;
        }
    };

    var areValidFileTypes = function(files) {
        var areValid = true;
        _.each(files, function(file) {
            var type = file.type;
            var isJPG = type.indexOf('jpg') > 0 || type.indexOf('jpeg') > 0;
            var isPDF = type.indexOf('pdf') > 0;
            var isPNG = type.indexOf('png') > 0;
            if (!isJPG && !isPDF && !isPNG) {
                areValid = false;
            }
        })
        return areValid;
    };

    $scope.dragOver = function(event) {
        event.preventDefault();
        return false;
    };

    $scope.dragLeave = function(event) {
        $scope.dropzoneVisible = false;
    };

    $scope.fileDropped = function(event) {
        event.stopPropagation();
        $scope.dropzoneVisible = false;

        var dt = event.originalEvent.dataTransfer;
        var files = dt.files;

        if (areValidFileTypes(files)) {
            $scope.selectFiles(files);
        }

        return false;
    };

    $scope.selectFiles = function(files) {
        _.each(files, function(file) {
            var asset = new assetModel.Model();
            uploaderModel.uploader.filesInProgress.push(asset);

            asset.$upload(file).then(function(obj) {
                //create activity, promise only returns if activity

                var activity = new drfActivityModel.Model();
                activity['name'] = obj['title'];
                activity['tags'] = obj['tags'];
                activity['activity_type'] = "pdfviewer";
                var objString = '{"url":"' + obj['download_url'] + '"}';
                activity['descriptor'] = objString;
                activity['description'] = obj['description'];

                uploaderModel.uploader.activitiesInProgress.push(activity);

                activity.$save();

            });
        });
    };

    $scope.doneEditingUploads = function() {
        var promises = [];
        _.each(uploaderModel.uploader.filesInProgress, function(file) {
            promises.push(file.$save());

            var activity = _.find(uploaderModel.uploader.activitiesInProgress, function(activity) {

                if (activity['descriptor'].indexOf(file['download_url']) > -1) {
                    return true;
                }
                return false;
            });
            if (activity) {
                activity['description'] = file['description'];
                activity['tags'] = file['tags'];
                activity['name'] = file['title'];
                activity['private'] = file['private'];
                promises.push(activity.$save());
            }
        });

        // wait for tem all to be done before we refetch
        $q.all(promises).then(function() {
            uploaderModel.uploader.activitiesInProgress = [];
            uploaderModel.uploader.filesInProgress = [];
            $scope.assets.$fetch();
        });
    };
};

AssetsController.$inject = ['$log', '$timeout', '$scope', '$state', '$stateParams', '$q', 'assetModel', 'uploaderModel', 'AssetTagModel', 'drfActivityModel', 'AssetsStateModel', 'AssetStateModel'];
module.exports = AssetsController;