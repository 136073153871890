var ImageAssetModel = function($log,
                               $q,
                               $http,
                               $timeout,
                               drfModel,
                               applications,
                               thumbnailCreator,
                               tagModel,
                               imageStorage) {

    var ImageAsset = new drfModel({
        apiUrl: `${applications.platform.url}/api/v1/asset/`,
    });

    this.ready = false;

    this.originalImage = null;
    this.squaredImage = null;
    this.thumbnail = null;
    this.squaredImageData = null;
    this.thumbnailData = null;

    let allowedMimeTypes = [
        "image/png",
        "image/jpeg",
        "image/gif"];

    Object.assign(ImageAsset.Model.prototype, {

        /**
         * Uploads card image and thumbnail images onto server
         * @returns {deferred.promise|{then}|{then, catch, finally}} Promise that will be resolved
         * after images uploading
         */
        uploadImages: function(file, callback) {
            var deferred = $q.defer();
            var me = this;

            // empty titles break the back end
            if (this.title === '') {
                this.title = 'image';
            }
            this.$save()
                .then(_.bind(function(obj) {
                    if (obj.errorObject) {
                        console.log('ImageAsset save error: ', obj.errorObject);
                        console.log('ImageAsset save error: ', me.filename);
                        return deferred.reject(me);
                    }
                    me.upload_url = obj.upload_url;
                    me.ready = true;

                    me.formUpload(obj.manifest, callback).then(_.bind(function() {
                        return deferred.resolve(me);
                    }, me));
                }, me));

            return deferred.promise;
        },

        formUpload: function(manifest, callback) {
            var deferred = $q.defer();

            var form = new FormData();
            _.each(manifest, function(value, key) {
                form.append(key, value);
            });

            /* Creating blob from image dataURL */
            var dataURL = imageStorage.retrieveSquaredImage(this.squaredImageStorageId)[1];
            var byteString = atob(dataURL.split(',')[1]);
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            var blob = new Blob([ab], { type: 'image/jpeg' });

            form.append('file', blob);

            var _oXHR = new XMLHttpRequest();
            this._oXHR = function () {
                "use strict";
                return _oXHR;
            };

            this._oXHR().upload.addEventListener('progress', ($event) => {

                var percentDone = $event.loaded / $event.total;

            this._progress = (percentDone * 100).toFixed();

            if (callback) {
                callback(this._progress);
            }

            $timeout(function() {}, 0);
        },
            false);

            this._oXHR().upload.addEventListener('load', _.bind(function() {
                this['status'] = 'complete';
                this._progress = 100;
                this.$save().then(() => {
                    deferred.resolve(this);
            });

            }, this), false);

            this._oXHR().upload.addEventListener('error', ($event) => {
                deferred.reject($event);
        }, false);

            this._oXHR().upload.addEventListener('abort', ($event) => {
                deferred.reject($event);
        }, false);

            this._oXHR().open('POST', this.upload_url, true);

            this._oXHR().send(form);

            return deferred.promise;
        },

        initWithParameters: function(file, isPrivate) {
            var deferred = $q.defer();

            this.getFile = function () {
                "use strict";
                return file;
            };

            this.filename = file.name;
            this.tags = [];
            this.rotation = 0;
            this.xOffset = 0;
            this.yOffset = 0;

            this.valid = allowedMimeTypes.indexOf(file.type) != -1;

            this.originalImageStorageIndex = -1;
            this.squaredImageStorageId = -1;

            this.maximumXOffset = 0;
            this.maximumYOffset = 0;

            this.selectionWidth = 0;
            this.selectionHeight = 0;

            this.file_type = 'image';
            this.title = file.name
                // remove - or _ from string
                .replace(/[-_]/g, ' ')
                // remove the file type extension
                .replace(/\.\w{3,4}$/, '')
                // capitalize all words
                .replace(/\w\S*/g, function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });

            this.size = parseFloat(parseInt(file.size) / 1000000).toFixed(2);
            this._progress = 0;
            this.private = isPrivate;
            this.mime_type = file.type;

            if (this.valid) {
                this.generateScaledImage().then(function (model) {
                    model.generatePreview().then(function (model) {
                        deferred.resolve(model);
                    }, () => {
                        this.valid = false;
                    deferred.resolve(this);
                });
                }, () => {
                    this.valid = false;
                deferred.resolve(this);
            });
                return deferred.promise;
            } else {
                return this;
            }
        },

        setInitialSelectionDimension: function(value) {
            this.selectionWidth = this.selectionHeight = value;
        },

        setBoundingBoxForSelection: function(rect) {
            this.imageBox = rect;
        },

        setSelectionDimension: function(value) {
            if (value >= 50) {
                if (value + this.xOffset <= this.imageBox.width &&
                    value + this.yOffset <= this.imageBox.height)
                {
                    this.selectionWidth = this.selectionHeight = value;
                    return true;
                } else {

                    if (value + this.xOffset > this.imageBox.width) {
                        this.setOffsets(this.xOffset - (value - this.selectionWidth), this.yOffset);
                    }
                    if (value + this.yOffset > this.imageBox.height) {
                        this.setOffsets(this.xOffset, this.yOffset - (value - this.selectionHeight));
                    }

                    if (value + this.xOffset <= this.imageBox.width &&
                        value + this.yOffset <= this.imageBox.height) {
                        this.selectionWidth = this.selectionHeight = value;
                    }

                    return false;
                }
            }
            return false;
        },

        setRotation: function(value) {
            this.rotation = value;
        },

        setMaximumOffsets: function(x, y) {
            this.maximumXOffset = x;
            this.maximumYOffset = y;
        },

        setOffsets: function(x, y) {

            if (x + this.selectionWidth > this.imageBox.width ||
                y + this.selectionHeight > this.imageBox.height) {
                return;
            }

            if (x < 0) {
                this.xOffset = 0;
            } else if (x > this.maximumXOffset) {

                this.xOffset = this.maximumXOffset;
            } else {
                this.xOffset = x;
            }

            if (y < 0) {
                this.yOffset = 0;
            } else if (y > this.maximumYOffset) {
                this.yOffset = this.maximumYOffset;
            } else {
                this.yOffset = y;
            }
        },

        onProgress: function(e, callback) {
            var percentDone = e.loaded / e.total;

            this._progress = (percentDone * 100).toFixed();

            if (callback) {
                callback(this._progress);
            }

            $timeout(function() {}, 0);
        },

        /**
         * Aborts current upload if any
         */
        abortUpload: function() {
            if (this._oXHR() != null && this._oXHR() !== undefined) {
                this._oXHR().abort();
            }
        },

        getProgress: function() {
            return this._progress;
        },

        uploadImageIntoBrowser: function (file) {

        },


        generateScaledImage: function() {
            var me = this;
            var deferred = $q.defer();

            thumbnailCreator.createScaledImage(this.getFile(), (image) => {
                me.scaledImage = {
                    src: image.src
                };
            deferred.resolve(me);
        }, (error) => {
                deferred.reject(error);
            });

            return deferred.promise;
        },

        /**
         * Generates DOM image from specified file
         * @returns {deferred.promise|{then}|{then, catch, finally}}
         */
        generatePreview: function() {
            var me = this;
            var deferred = $q.defer();

            thumbnailCreator.createImageFromFile(this.getFile(), (image) => {
                me.originalImageStorageIndex = imageStorage.saveOriginalImage(image) - 1;
            deferred.resolve(me);
        }, (error) => {
                deferred.reject(error);
            });

            return deferred.promise;
        },

        getOriginalImage: function() {
            return imageStorage.retrieveOriginalImage(this.originalImageStorageIndex);
        },

        getOriginalImageSource: function() {
            return imageStorage.retrieveOriginalImage(this.originalImageStorageIndex).src;
        },

        /**
         * Generates 150X150 thumbnail from original image
         *
         * @param offsetX Offset by X axis of new image in original image
         * @param offsetY Offset by Y axis of new image in original image
         */
        generateThumbnail: function(offsetX, offsetY) {
            var me = this;
            thumbnailCreator.createSquaredImage(this.getFile(),
                offsetX,
                offsetY,
                150,
                (image, data) => {
                me.thumbnail = image;
            me.thumbnailData = data;
        }
            );
        },

        /**
         * Generates 376X376 card image from original image
         *
         * @param offsetX Offset by X axis of new image in original image
         * @param offsetY Offset by Y axis of new image in original image
         * @returns {deferred.promise|{then}|{then, catch, finally}}
         */
        generateCardImage: function(offsetX, offsetY) {
            var deferred = $q.defer();

            var me = this;
            var selectionPreviewWidth = this.selectionWidth + this.maximumXOffset;
            var ratio = this.getOriginalImage().width / selectionPreviewWidth;

            thumbnailCreator.createSquaredImage(this.getFile(),
                offsetX * ratio,
                offsetY * ratio,
                this.selectionWidth * ratio,
                this.rotation,
                function (image, data) {
                    me.squaredImageStorageId = imageStorage.saveSquaredImage(image, data);
                    deferred.resolve(me);
                }
            );

            return deferred.promise;
        },

        '$addTag': function(tag) {
            var newTag = new tagModel.Model();
            newTag.name = tag;
            if (!this.hasOwnProperty('tags')) {
                this.tags = [];
            }
            this.tags.push(newTag);
        },

        '$removeTag': function(tag) {
            if (!this.hasOwnProperty('tags')) {
                return;
            }
            var index = _.findIndex(this.tags, function(item) {
                return item.name == tag;
            });
            if (index >= 0) {
                this.tags.splice(index, 1);
            }
        }

        // If you need to update image tags after uploading
        // uncomment and integrate code below into functions above.
        // Somehow.

        //'$addTag': function(tag) {
        //    var url = this.resource_uri + 'tag/' + tag + '/';
        //    return $http.post(url).then(() => this.$get());
        //},
        //
        //'$removeTag': function(tag) {
        //    var url = this.resource_uri + 'tag/' + tag + '/';
        //    return $http.delete(url).then(() => this.$get());
        //}
    });

    Object.assign(ImageAsset.Collection.prototype, {});

    return ImageAsset;
};

ImageAssetModel.$inject = ['$log',
    '$q',
    '$http',
    '$timeout',
    'drfModel',
    'applications',
    'thumbnailCreator',
    'tagModel',
    'imageStorage'];

module.exports = ImageAssetModel;