/**
 * Model for each record
 * @param $log
 */
var OccurrenceModel = function($log, tastyModel, applications) {

    var Occurrence = new tastyModel({
        apiUrl: '/tasty/v2/occurrence/',
        base_uri: applications.platform.url,
    });

    Object.assign(Occurrence.Model.prototype, {

    });

    Object.assign(Occurrence.Collection.prototype, {

    });

    return Occurrence;
};

OccurrenceModel.$inject = ['$log', 'tastyModel', 'applications'];
module.exports = OccurrenceModel;