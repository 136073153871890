let Assert = function() {

    let assert = function(value, message){
        if(!value) {
            throw new Error(message)
        }
    };
    assert.equal = function(actual, expected, message){
        if(actual != expected) {
            throw new Error(message)
        }
    };
    assert.notEqual = function(actual, expected, message){
        if(actual == expected) {
            throw new Error(message);
        }
    };

    return assert;
};
Assert.$inject = [];
module.exports = Assert;
