function ActivitiesDetailFooterDirective(
    currentUserModel,
    $rootScope,
    $state,
    applications,
    appModel
) {
    return {
        restrict: 'E',
        replace: false,
        templateUrl: '/toychest/modules/store/activities/activities.detail.footer.tpl.html',
        scope: {
            activity: '=',
            prevState: '=',
            type: '@' // "activity" or "assessment"
        },
        link: function link($scope, el, attrs) {
            $scope.favorite = $scope.$eval(attrs.favorite);
            $scope.preview = $scope.$eval(attrs.preview);
            $scope.trash = $scope.$eval(attrs.trash);
            const activityTypes = ['flashcards', 'memory'];
            $scope.appModel = appModel;

            let detailRoute;
            if ($scope.type === 'activity') {
                detailRoute = 'app.store.activities.detail';
            } else if ($scope.type === 'assessment') {
                detailRoute = 'app.store.assessments.detail';
            } else if ($scope.type === 'lesson') {
              detailRoute = 'app.store.lessons.detail';
          }

            $scope.showReportContent = () => $scope.type === 'activity';

            $scope.toggleFavorite = () => $scope.activity.$favorite(!$scope.activity.favorite);

            const isAdmin = currentUserModel.user.isInGroup('Administrator');
            const isOwner = $scope.activity.is_activity_owner;
            $scope.canPreview = () => {
                return $scope.preview;
            };

            $scope.canEdit = () => {
                let checkByType = -1 !== activityTypes.indexOf($scope.activity.type);

                return (isOwner || isAdmin) && checkByType && $scope.activity.disabled === false;
            };
            $scope.canDelete = () => {
                return (isOwner || isAdmin) && $scope.activity.disabled === false;
            };
            $scope.canUndelete = () => {
                return (isOwner || isAdmin) && $scope.activity.disabled === true;
            };

            $scope.reportActivity = () => {
                $rootScope.$broadcast('reportActivity', { });
            }

            $scope.updateActivity = () => {
                $state.go('app.store.activities.create.flashcards.select', {
                    mode: 'update',
                    slug: $state.params.slug
                });
            };

            $scope.disableActivity = () => {
                $scope.activity.disabled = true;
                $scope.activity.$save().then((activity) => {
                    if($scope.prevState.name && $scope.prevState.params) {
                        $state.go($scope.prevState.name, $scope.prevState.params);
                    } else {
                        $rootScope.navigateBack(true);
                    }
                });
            };

            $scope.enableActivity = () => {
                $scope.activity.disabled = false;
                $scope.activity.$save().then(() => {
                    if($scope.prevState.name && $scope.prevState.params) {
                        $state.go($scope.prevState.name, $scope.prevState.params);
                    } else {
                        $rootScope.navigateBack(true);
                    }
                });
            };

            $scope.previewUrl = () =>
                `${applications.toys.url}/${$scope.type}/${$scope.activity.activity_type}/${$scope.activity.slug}?activityId=${$scope.activity.id}`;
        }
    };
}

ActivitiesDetailFooterDirective.$inject = [
    'currentUserModel',
    '$rootScope',
    '$state',
    'applications',
    'appModel'
];
module.exports = ActivitiesDetailFooterDirective;
