var TagToggleDirective = function($log){
    return {
        restrict: 'E',
        replace: true,
        scope : {
            tags : '=',
            tag  : '='
        },
        templateUrl: '/toychest/common/directives/TagToggle/tagToggle.tpl.html',

        link: function (scope, element, attrs, controller) {

            $log.debug("[TagToggleDirective] link function()");

            scope.tagExists = function(){
                var found = _.find(scope.tags, function(t){
                            return scope.tag.name == t.name
                });
                return !!found;
            };

            scope.toggleTag = function(){
                var index = _.findIndex(scope.tags, function(item) {
                    return item.name == scope.tag.name;
                });
                if(index >= 0 ){
                    scope.tags.splice(index, 1);
                } else {
                    scope.tags.push(scope.tag);
                }
            }
        }
    };
};

TagToggleDirective.$inject = ['$log'];
module.exports = TagToggleDirective;
