function plOverlayLoadingFactory($timeout) {
    const plOverlayLoading = {};
    const _plOverlayLoading = {};

    _plOverlayLoading.getElement = (id) => {
        return angular.element(document.getElementById(id));
    };

    plOverlayLoading.show = (id) => {
        _plOverlayLoading.getElement(id).addClass('visible');
    };

    plOverlayLoading.hide = (id) => {
        _plOverlayLoading.getElement(id).removeClass('visible');
    };

    plOverlayLoading.destroy = (id) => {
        const child = _plOverlayLoading.getElement(id)[0];
        if (child && child.parentNode) {
            child.parentNode.removeChild(child);
        }
    };

    return plOverlayLoading;
}
plOverlayLoadingFactory.$inject = ['$timeout'];
module.exports = plOverlayLoadingFactory;
