/**
 * Controller for the pdf toolbar directive
 * @param $scope
 * @constructor
 */
var PdfToolbarController = function($log, $scope) {

    var init = function () {
        $log.debug("PdfToolbarController controller");
    };
    init();
};

PdfToolbarController.$inject = ['$log', '$scope'];
module.exports = PdfToolbarController;
