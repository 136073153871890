function plNavGlobal($rootScope, $timeout, $q, $location, currentUserModel, applications, API, plOverlayModal, plToast, plAppContext,
    plAppRecorder, plBrowser, liveagentService, appModel, plHijackHelper) {
    return {
        restrict: 'E',
        templateUrl: '/core/pl-navs/src/plNavGlobal/plNavGlobal.tpl.html',
        scope: {
            app: '@?',
            useAppContext: '=?',
            versionTimeoutSeconds: '=?',
        },
        link: $scope => {
            $scope.isCurrentUserHijacked = () => {
              if (!$scope.user) {
                return false;
              }
              return plHijackHelper.isCurrentUserHijacked($scope.user);
            };

            if ($scope.versionTimeoutSeconds === undefined) {
                $scope.versionTimeoutSeconds = 5 * 60;      // 5 minutes
            }
            $scope.versionOutdated = false;
            const versionEvtName = 'plNavGlobalVersionCheck';
            if ($scope.versionTimeoutSeconds) {
                plBrowser.checkVersionLoop($scope.versionTimeoutSeconds, versionEvtName);
            }
            $scope.$on(versionEvtName, (evt, codeStatus) => {
                $scope.versionOutdated = codeStatus.outdated;
            });

            $scope.updateVersion = () => {
                location.reload(true);
            };

            if ($rootScope.sha) {
              $scope.shaDisplay = $rootScope.sha.substring(0,4);
              $scope.showToastSha = () => {
                plToast.toastInfo(`Version: &nbsp; ${$scope.shaDisplay}`, {
                  timeOut: 7000,
                  positionClass: 'toast-top-right',
                  tapToDismiss: false,
                  closeButton: true,
                  css: {
                    color:'black',
                    'fontSize': '20px',
                    backgroundColor:'#E4F5FD',
                    'height': '37px',
                    position:'fixed',
                    top:'2px',
                    right:'2px',
                    'paddingTop': '4px',
                  }
                });
              }
            }
            $scope.$on('$stateChangeSuccess', function(event, to, toParams, from, fromParams) {
              if ($scope.useAppContext) {
                $timeout(() => {
                  $scope.app = plAppContext.getContext();
                })
              }
            });

            //for toychest specific features
            $scope.liveagent = liveagentService;
            $scope.appModel = appModel;

            if ($scope.useAppContext) {
              $timeout(()=>{
                $scope.app = plAppContext.getContext();
              })
            }
            $scope.applications = applications;
            $scope.logout = function() {
                currentUserModel.logout();
            };

            currentUserModel.onAuthentication(user => {
              $timeout(()=> {
                $scope.user = user;
                $scope.userIsSchoolStaff = user.groups.some(group => group.indexOf('School Staff') >= 0);
                $scope.userIsPrivatePractice = user.groups.indexOf('Private Practice') >=0;
                $scope.userIsSSorPP = $scope.userIsSchoolStaff || $scope.userIsPrivatePractice;
                $scope.userIsSaaS = user.groups.includes('SaaS');
                if ($scope.userIsSaaS) {
                  $scope.homeUrl = `${applications.therapy.url}/landing-home/`
                } else if ($scope.userIsSSorPP) {
                  $scope.homeUrl = `${applications.educlients.url}/landing-home/`
                }
                else {
                  $scope.homeUrl = applications.apps.url
                }
              }, 1);
            });

            $scope.forms = {};
            $scope.setForm = (name, form) => {
              $scope.forms[name] = form;
            };

            $scope.openBugReport = () => {
              $scope.bugReportInit();
              plOverlayModal.show('globalBugReport');
            };

            $scope.onCloseBugReport = () => {
              plOverlayModal.hide('globalBugReport');
            };

            $scope.submitBugReport = () => {
              if ($scope.bugReportState.updateInProgress) {
                return;
              }
              $scope.bugReportState.saveClicked = true;
              $scope.bugReportState.fields.title.setValidity();
              $scope.bugReportState.fields.message.setValidity();
              if (!$scope.forms.bugReportForm.$valid) {
                return;
              }
              $scope.sendBugReport();
            };

            $scope.sendBugReport = () => {
              $scope.bugReportState.updateInProgress = true;
              const info = $scope.bugReport;
              let message;
              function buildMessage(historySize) {
                message = info.message+`\n===== plAppRecorder log (version ${plAppRecorder.getVersion()}), ${$scope.app?$scope.app:'app'} version ${$rootScope.sha} =====\n${JSON.stringify(plAppRecorder.getHistory().slice(0,historySize))}\n==========\n`;
              }
              if (info.message.length < 6000) {
                buildMessage(100);
              } else if(info.message.length < 10000) {
                buildMessage(75);
              } else if(info.message.length < 15000) {
                buildMessage(40);
              }

              let saveBugReport;
              if ($scope.app === 'library') {
                  saveBugReport = API.jiraBugReport.save({
                      summary: info.title,
                      description: message,
                      project: 'Platform',
                      assignee: 'manulohiya',
                      epic: 'PL-1489',
                  }).$promise;
              } else {
                  saveBugReport = API.jiraBugReport.save({
                      summary: info.title,
                      description: message,
                      project: 'Workplace',
                      assignee: 'manulohiya',
                      epic: 'WP-680',
                  }).$promise;
              }

              saveBugReport.then(
                result => {
                  $scope.bugReportState.updateInProgress = false;
                  $scope.onCloseBugReport();
                  plToast.toastSuccess(`Thanks for your help!<p/>Your bug report has been submitted to PresenceLearning staff.`,{timeOut: 5000});
                },
                error => {
                  $scope.bugReportState.updateInProgress = false;
                  plToast.toastFailure(error.data.detail);
                }
              );
            };

            $scope.bugReport = {};
            $scope.bugReportInit = () => {
              $scope.bugReport.title = '';
              $scope.bugReport.message = '';
              $scope.bugReportState.saveClicked = false;
              $scope.bugReportState.updateInProgress = false;
              $scope.bugReportState.fields.title.setValidity(true);
              $scope.bugReportState.fields.message.setValidity(true);
            };

            $scope.bugReportState = {
              fields: {
                title: {
                  setValidity: (value) => {
                    const isValid = value || $scope.bugReport.title.length > 0;
                    $scope.forms.bugReportForm.bug_report_title.$setValidity('title', isValid);
                  }
                },
                message: {
                  setValidity: (value) => {
                    const isValid = value || $scope.bugReport.message.length > 0;
                    $scope.forms.bugReportForm.bug_report_message.$setValidity('message', isValid);
                  }
                }
              }
            };
        },
    };
};
plNavGlobal.$inject = [
  '$rootScope', '$timeout', '$q', '$location', 'currentUserModel', 'applications', 'API', 'plOverlayModal', 'plToast',
  'plAppContext', 'plAppRecorder', 'plBrowser', 'liveagentService', 'appModel', 'plHijackHelper'];
module.exports = plNavGlobal;
