function plInputTextareaDirective(plInputService, $timeout) {
    return {
        restrict: 'E',
        require: '?^form',
        scope: {
            model: '=',
            change: '&?',
            focus: '&?',
            blur: '&?',
            whenKeyUp: '&?',
            keyUpDebounce: '=?',
            disabled: '=?',
            required: '=?',
            minlength: '=?',
            maxlength: '=?',
            pattern: '=?',
            placeholder: '@?',
            name: '@?',
            label: '@?',
        },
        templateUrl: '/core/pl-inputs/src/plInputTextarea/plInputTextarea.tpl.html',
        link: ($scope, $element, $attrs, formCtrl) => {
            const inputElement = $element.find('textarea');
            plInputService.createNameIfNone($scope);
            plInputService.setupOnChange($scope, $attrs);
            plInputService.setupFocusAndBlur($scope, $attrs);
            plInputService.addInputToFormForValidation($scope, $element, formCtrl);

            if ($scope.maxlength) {
                inputElement.attr('maxlength', $scope.maxlength);
            }

            // state classes
            $scope.getStateClasses = function() {
                return {
                    focused: $scope.focused,
                    disabled: $scope.disabled,
                    invalid: plInputService.isTextStyleInputInvalid($scope, formCtrl),
                };
            };

            if ($scope.whenKeyUp) {
                $scope.keyUpDebounce = ($scope.keyUpDebounce !== undefined) ?
                 $scope.keyUpDebounce : 1000;
                let timeout = false;
                inputElement.on('keyup', evt => {
                    if (timeout) {
                        $timeout.cancel(timeout);
                    }
                    timeout = $timeout(() => {
                        $scope.whenKeyUp();
                    }, $scope.keyUpDebounce);
                });
            }
        },
    };
}

plInputTextareaDirective.$inject = ['plInputService', '$timeout'];
module.exports = plInputTextareaDirective;
