function plApiProviderFactory(plHttp, $q) {
    const plApiProvider = {};
    const _cache = {};

    plApiProvider.get = (data = {}) => {
        const deferred = $q.defer();

        if (_cache[data.user__uuid]) {
            deferred.resolve(_cache[data.user__uuid]);
        } else {
            const httpUrl = `${plHttp.formUrl('providers')}${data.user__uuid}/`;
            const httpOpts = {
                url: httpUrl,
                method: 'GET',
            };
            plHttp.go(httpOpts)
                .then((res) => {
                    _cache[data.user__uuid] = res.data;
                    deferred.resolve(_cache[data.user__uuid]);
                }, (err) => {
                    deferred.reject(err);
                });
        }
        return deferred.promise;
    };

    return plApiProvider;
}
plApiProviderFactory.$inject = ['plHttp', '$q'];
module.exports = plApiProviderFactory;
