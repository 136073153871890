var DrfLessonRightsModel = function($log, $q, $http, drfModel, applications) {

  var Lesson = new drfModel({
      apiUrl: applications.auth.rightsUrl,
  });

  Object.assign(Lesson.Model.prototype, {});

  Object.assign(Lesson.Collection.prototype, {});

  return Lesson;
};

DrfLessonRightsModel.$inject = ['$log', '$q', '$http', 'drfModel', 'applications'];
module.exports = DrfLessonRightsModel;