function plRecordPreview(plRecord, plBillingCodes) {
    return {
        restrict: 'E',
        scope: {
            record: '=',
            user: '=',
        },
        replace: true,
        templateUrl: '/core/pl-records/src/plRecordPreview/plRecordPreview.tpl.html',
        link: ($scope) => {
            const hasRecord = $scope.hasRecord = $scope.record.hasOwnProperty("signed_on");
            if (hasRecord) {
              $scope.billingInfo = plBillingCodes.codeInfo($scope.record.billing_code);
              $scope.billingFilter = plBillingCodes.getFilters($scope.billingInfo);
              $scope.billingCodeClass = $scope.billingFilter.key
            }
            $scope.recordView = plRecord.recordToView($scope.record, $scope.user);
        },
    };
}

plRecordPreview.$inject = ['plRecord', 'plBillingCodes'];
module.exports = plRecordPreview;
