var DoubleAssessmentViewerDirective = function ($log, $q, $window, $timeout, $compile, $stateParams, firebaseModel, AssessmentModel, remoteDispatcherService) {
    return {
        restrict: "E",
        templateUrl: '/core/toys/app/src/toys/modules/assessment/doubleassessment/doubleassessmentviewerdirective.tpl.html',

        link: function ($scope, element, attrs) {

            console.debug("[DoubleAssessmentViewerDirective] link");
        }
    }
};

DoubleAssessmentViewerDirective.$inject = ['$log',
    '$q',
    '$window',
    '$timeout',
    '$compile',
    '$stateParams',
    'firebaseModel',
    'AssessmentModel',
    'remoteDispatcherService'];

module.exports = DoubleAssessmentViewerDirective;

