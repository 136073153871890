var YouTubeAssessmentController = function($log, $scope, $stateParams) {

    var init = function () {
        $log.debug("YouTubeController init");
    };
    init();
};

YouTubeAssessmentController.$inject = ['$log', '$scope', '$stateParams'];
module.exports = YouTubeAssessmentController;
