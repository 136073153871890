function plHijackHelper($timeout, $window, plGlobalUtil) {
  const helper = {};

  // boolean
  const KEY_STATE_DEV_HIJACK_HIDE = 'dev.hijack-hide';
  // boolean
  const KEY_STATE_DRAWER_EXPANDED = 'plUiState.assume-login-bar-expanded';

  // throttle localStorage access for watched functions
  const throttleCheckHideHijack = _.throttle(() => {
    const val = plGlobalUtil.readCookie(KEY_STATE_DEV_HIJACK_HIDE);
    $timeout()
    return val;
  }, 200);

  const throttleCheckDrawerExpanded = _.throttle(() => {
    const val = plGlobalUtil.readCookie(KEY_STATE_DRAWER_EXPANDED);
    $timeout()
    return val;
  }, 200);

  helper.isCurrentUserHijacked = (user) => {
    return helper.isHideHijack() !== "true"
      && user.userStatus
      && user.userStatus.plru
      && (user.userStatus.plru !== user.userStatus.sub);
  };

  helper.isHideHijack = () => {
    return throttleCheckHideHijack();
  };

  helper.isDrawerExpanded = () => {
    const _isDrawerExpanded = throttleCheckDrawerExpanded();
    return _isDrawerExpanded === null || _isDrawerExpanded === 'true' || _isDrawerExpanded === true;
  };

  helper.setDrawerExpanded = (state) => {
    plGlobalUtil.createCookie(KEY_STATE_DRAWER_EXPANDED, state, 1000);
  };

  helper.hideHijack = (toggle) => {
    const _toggle = toggle === undefined || toggle;
    plGlobalUtil.createCookie(KEY_STATE_DEV_HIJACK_HIDE, _toggle, 1000);
  }

  $window['plHijackUtil'] = {
    hideHijack: helper.hideHijack,
  };

  return helper;
}


plHijackHelper.$inject = ['$timeout', '$window', 'plGlobalUtil'];
module.exports = plHijackHelper;
