/**
 * Banner with link to new Library
 * @constructor
 */

var NewLibraryBannerDirective = function(applications, currentUserModel) {

  return {
      restrict: 'E',
      scope: {},
      templateUrl: '/toychest/common/directives/newLibraryBanner/new_library_banner.tpl.html',
      controller: function($attrs, $scope) {

      },
      link: function ($scope) {
        $scope.showBanner = () => currentUserModel.user.isAssessmentUser();
        $scope.openNewLibrary = function() {
          window.open(applications.lightyear.url + '/library', '_blank');
        }
      },
  }
};

NewLibraryBannerDirective.$inject = ['applications', 'currentUserModel'];
module.exports = NewLibraryBannerDirective;
