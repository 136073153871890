/**
 * Tooltip directive
 *
 * @param $log
 * @param $document
 * @constructor
 */

var ActivityListItem = function($state) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            activity: '=',
        },
        templateUrl: '/toychest/common/directives/activityListItem/activityListItem.tpl.html',
        link: function($scope) {
            var isAssessment = $scope.activity._resource_uri.indexOf('assessment') >= 0;
            var isLesson = $scope.activity._resource_uri.indexOf('lesson') >= 0;
            $scope.isGame = $scope.activity.type === 'game';
            $scope.getListItemClasses = function() {
                var classes = {
                    'activity-list-item': true,
                    'assessment': isAssessment,
                    'game': $scope.isGame,
                    'draggable': $scope.isDraggable()
                };
                classes[$scope.activity.activity_type] = true;
                return classes;
            };
            $scope.getThumbnailURL = function() {
                return !!$scope.activity.thumbnail_url ? $scope.activity.thumbnail_url : '/toychest/assets/images/noImageAvailable.svg';
            };
            $scope.gotoActivity = function() {
                var itemState = 'app.store.activities.detail';
                if(isAssessment) {
                    itemState = 'app.store.assessments.detail';
                } else if(isLesson) {
                  itemState = 'app.store.lessons.detail';
                } else if($scope.isGame) {
                    itemState = 'app.store.games.detail';
                }
                $state.go(itemState, {
                    slug: $scope.activity.slug
                });
            };
            $scope.getTypeName = function() {
                if($scope.activity.type === 'game') {
                    return 'games';
                }
                if($scope.activity.type === 'lesson') {
                    return 'lesson';
                }
                switch ($scope.activity.activity_type) {
                    case 'youtube':
                        return 'video';
                    case 'pdfviewer':
                        return 'doc';
                    case 'doubleassessment':
                        return 'assessment';
                    case 'assessment':
                        return 'assessment';
                    case 'wjaudioassessment':
                        return 'audio';
                    case 'recordform':
                        return 'record form';
                    case 'flashcards':
                        return 'flashcards';
                    case 'memory':
                        return 'memory';
                    default:
                        return 'item';
                }
            };
            $scope.isDraggable = function() {
                return $scope.activity.activity_type !== 'recordform';
            };
        }
    };
};

ActivityListItem.$inject = ['$state'];
module.exports = ActivityListItem;