/**
 * Model for each record
 * @param $log
 */
/* global moment */
function RecordModel($q, tastyModel, studentModel, noteModel, occurrenceModel, currentUserModel, applications) {
    const MAX_STUDENTS_PER_DAY = 64; // 8 hour day filled with 15 min sessions, each w/ 2 students.

    const Record = new tastyModel({
        apiUrl: '/tasty/v2/record/',
        base_uri: applications.platform.url,
    });

    Object.assign(Record.Model.prototype, {
        // No use found in code. If using, write a test to go with this.
        // '$getNotes': function() {
        //     var notes = new noteModel.Collection();
        //     notes.$filter({

        //     });

        //     this.$notes = notes;
        //     return notes.$fetch();
        // }
    });

    Object.assign(Record.Collection.prototype, {
        $getStudents: function() {
            const studentPromises = [];
            this.forEach((record) => {
                const student = new studentModel.Model();
                student.resource_uri = record.student;
                studentPromises.push(student.$get().then((s) => {
                    record.student = s;
                }));
            });
            return $q.all(studentPromises);
        },
        $getOccurrences: function() {
            const occurrencePromises = [];
            this.forEach((record) => {
                const occurrence = new occurrenceModel.Model();
                occurrence.resource_uri = record.occurrence;
                occurrencePromises.push(occurrence.$get().then((o) => {
                    record.occurrence = o;
                }));
            });
            return $q.all(occurrencePromises);
        },
        $getTodaysRecords: function() {
            const start = moment().startOf('day').utc().format();
            const until = moment().endOf('day').utc().format();
            const todaysRecords = new Record.Collection();
            todaysRecords.limit = MAX_STUDENTS_PER_DAY;

            todaysRecords.$filter({
                occurrence__event__schedule__user__profile__uuid: currentUserModel.user.uuid,
                occurrence__startTime__gte: start,
                occurrence__endTime__lte: until,
            });

            return todaysRecords.$fetch().then((records) => {
                let i = records.length;
                while (i--) {
                    if (!records[i].student || !records[i].occurrence) {
                        records.splice(i, 1);
                    }
                }
                return $q.all([
                    records.$getStudents(),
                    records.$getOccurrences(),
                ]).then(() => {
                    this.$scheduled = todaysRecords;
                    return todaysRecords;
                }, () => {
                    this.$scheduled = todaysRecords;
                    return todaysRecords;
                });
            });
        }
    });

    return Record;
}

RecordModel.$inject = [
    '$q',
    'tastyModel',
    'studentModel',
    'noteModel',
    'occurrenceModel',
    'currentUserModel',
    'applications',
];
module.exports = RecordModel;
