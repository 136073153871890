import {
    GuestUser, UnauthenticatedUser,
}
from './users/UserModels';

/**
 * Model for the current user data
 */
class CurrentUserModel {
    constructor($q, applications, authenticationService, $window, $http) {
        this.onLogoutCallbacks = [];
        this.afterLogoutCallbacks = [];
        this._$q = $q;
        this._authUrl = applications.auth.statusUrl;
        this._platformUrl = applications.platform.url;
        this._logoutUrl = applications.auth.logoutUrl;
        this._userSelfUrl = `${applications.apiWorkplace.url}/graphql/v1/`;
        this._authenticationService = authenticationService;
        this._$window = $window;
        this._$http = $http;

        // Our default so that user is not undefined.
        this.user = new GuestUser();
        this.initialized = false;
    }

    /**
     * This will attempt to use the existing cookie to login the user. If a logged in user already exists,
     * it returns the currently logged in user.
     *
     * @return {nothing}
     */
    getAuthenticatedUser() {
        return this._authenticationService.authenticate().then((user) => {
            this.user = user;
            this.getUserFlags();
            if (this.hasOwnProperty('onAuthenticationCallback')) {
                this.onAuthenticationCallback(this.user);
            }
            return user;
        });
    }

    getUserFlags() {
        const httpOpts = {
            method: 'POST',
            url: this._userSelfUrl,
            headers: { 'Content-Type': 'application/json' },
            data: {
                query: `query UserSelf {
                    currentUser {
                        id
                        username
                        firstName
                        lastName
                        permissions {
                            manageCaseload
                            viewSchedule
                        }
                        globalPermissions {
                            addReferral
                            addReferrals
                            viewProviders
                            viewOpenReferrals
                            manageReferrals
                            addEvaluation
                            addDirectService
                            provideServices
                            viewCustomers
                            mergeClient
                        }
                        enabledUiFlags
                        enabledFeatures
                    }
                }`,
                variables: {
                },
            },
        };
        this._$http(httpOpts)
            .then((res) => {
                this.user.flags = res.data.data.currentUser;
            }, (err) => {
                console.log('err', err);
            });
    }

    getPermissions() {
        const deferred = this._$q.defer();
        const url = `${this._platformUrl}/api/v1/permissions`;
        this._$http.get(url).then(
            (permissionsResult) => {
                const permissions = permissionsResult.data;
                deferred.resolve(permissions);
            }
        );
        return deferred.promise;
    }

    startInactivityLogoutService() {
      if (!this.initialized) {
        // just in time injection
        angular.element(document.body)
          .injector()
          .get('inactivityLogoutService')
          .start();
        this.initialized = true;
      }
    }

    useUnauthenticatedUser(params) {
        this.user = new UnauthenticatedUser(params);
    }

    onAuthentication(callback) {
        this.onAuthenticationCallback = callback;
    }

    /**
     * See AuthenticationService.onLogout
     *
     * @param  {Function} callback
     */
    onLogout(callback) {
        this._authenticationService.onLogout(callback);
    }

    /**
     * See AuthenticationService.logoutgit
     */
    logout(redirectToAuth) {
        return this._authenticationService.logout(redirectToAuth).then(() => {
            this.user = new GuestUser();
            return this.user;
        });
    }

}

CurrentUserModel.$inject = ['$q', 'applications', 'authenticationService', '$window', '$http'];
module.exports = CurrentUserModel;
