function plNavTab() {
    return {
        restrict: 'E',
        transclude: true,
        templateUrl: '/core/pl-navs/src/plNavTab/plNavTab.tpl.html',
        scope: {},
        link: $scope => {},
    };
}

plNavTab.$inject = [];
export default plNavTab;
