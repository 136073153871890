var TimeFormatter = function() {
   return function(input) {
       // convert to local
       var localTime = moment.utc(input).toDate();
       // format local
       localTime = moment(localTime).format('h:mm A');
       return localTime;
   }
};
module.exports = TimeFormatter;
