function plRecordBO() {

  const MUST_EXIST = 'MUST_EXIST';
  const MUST_NOT_EXIST = 'MUST_NOT_EXIST';
  const ALLOWED = "ALLOWED";

  // expose constants so the caller can check return values
  const bo = { MUST_EXIST, MUST_NOT_EXIST, ALLOWED };

  // get a map indicating whether client/location are "required" to save the record
  // e.g.  { client: 'MUST_EXIST', location: 'MUST_NOT_EXIST' }
  bo.getRequiredFieldRules = async (recordBillingCode, billingCodes) => {
    const codes = await billingCodes.get();
    const bc = codes.find(item => recordBillingCode === item.uuid);

    if (!bc) {
      console.log('[plRecordBO.getRequiredFieldRules()]', `Bad State: no matching billing code`, recordBillingCode, codes);
      return {client: ALLOWED, location: ALLOWED};
    }

    // NOTE: Rules provided by BE team
    let client = MUST_NOT_EXIST;
    let location = MUST_NOT_EXIST;
    // Pass the clients and their locations if a client participates in the appointment
    // It is ok to first pass the client and include the location later when the provider signs the notes
    if (bc.client_participates !== 'NONE') {
      client = MUST_EXIST;
      location = ALLOWED;
    }
    // Pass a location if the appointment is whith a school
    if (bc.location_participates !== 'NONE') {
      location = MUST_EXIST;
    }

    return { client, location };
  }

  return bo;
}

plRecordBO.$inject = [];
module.exports = plRecordBO;
