function TitleInputDirective() {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: '/toychest/common/directives/titleInput/titleInput.tpl.html',
        scope: {
            value: '=',
            onChange: '=',
            placeholderValue: '='
        },
        link: function(scope, element, attrs) {
            let input = $('input', element);
            scope.title = '';

            scope.$watch('value', (newValue) => scope.title = newValue);

            scope.getLabel = () => scope.title ? scope.title : scope.placeholderValue;

            scope.saveByEnter = ($event) => {
                if ($event.keyCode !== 13) {
                    return;
                }
                scope.finishEditing();
            };

            scope.startEditing = () => {
                if (scope.editing) {
                    return;
                }
                scope.origionalTitle = scope.value;
                scope.editing = true;
                input.select();
            };

            scope.cancelEditing = ($event) => {
                if ($event) {
                    $event.stopPropagation();
                }
                if (!scope.editing) {
                    return;
                }
                scope.title = scope.origionalTitle;
                scope.editing = false;
            };

            scope.cancelEditing = ($event) => {
                console.log('cancelEditing');
                if ($event) {
                    $event.stopPropagation();
                }
                if (scope.editing) {
                    scope.title = scope.origionalTitle;
                    scope.editing = false;
                }
            };

            scope.finishEditing = () => {
                if (attrs.changed) {
                    // using $parent due to isolate scope in current directive
                    scope.$parent.$eval(attrs.changed, { $value: scope.title });
                }

                if (scope.onChange) {
                    scope.onChange(scope.title);
                }

                scope.editing = false;
            };
        }
    };
}

TitleInputDirective.$inject = [];
module.exports = TitleInputDirective;