/**
 * Model that holds the student data
 * @param $log
 */
var StudentModel = function($log, tastyModel, applications ) {

    var Student = new tastyModel({
        apiUrl: '/tasty/v2/student/',
        base_uri: applications.platform.url,
    });

    Object.assign(Student.Model.prototype, {

    });

    Object.assign(Student.Collection.prototype, {

    });

    return Student;
};

StudentModel.$inject = ['$log', 'tastyModel', 'applications'];
module.exports = StudentModel;