var WjAudioDirective = function ($log, $q, $window, $timeout, $compile, $stateParams, $sce, firebaseModel, AssessmentModel, currentUserModel, remoteDispatcherService) {
    return {
        restrict: "E",
        replace: true,
        scope: '=',
        templateUrl: '/core/toys/app/src/toys/modules/assessment/wjaudioassessment/audio/player/AudioPlayerDirective.tpl.html',

        link: function ($scope, element, attrs) {

            $scope.duration = "0:00";
            $scope.current = "0:00";
            $scope.playing = false;
            $scope.rangevalue = 0;


            $scope.$watch('audiotrack', function(newVal, oldVal) {
                $scope.audiotrack = newVal;
                $scope.audioElement = element.find('.audio-' + newVal.id)[0];
                $scope.audioElement.addEventListener('durationchange', (event) => handleDurationChange(event));
                $scope.audioElement.addEventListener('pause', (event) => handlePause(event));
                $scope.current = formatTime($scope.audioElement.currentTime);
            });

            function handleDurationChange(event) {
                $log.debug('[AudioPlayerDirective] handleDurationChange');
                $scope.duration = formatTime($scope.audioElement.duration);
                setupSeekbar();
                $scope.audioElement.addEventListener('timeupdate', (event) => handleTimeupdate(event));
            }

            function handlePause(event) {
                $scope.playing = false;
            }

            function handleTimeupdate(event) {
                $scope.current = formatTime($scope.audioElement.currentTime);
                $scope.seekbar.value =  $scope.audioElement.currentTime;
                setupSeekbar();
                $scope.$digest();
            }

            $scope.playing = false;
            $scope.playbackToggle = function(playing, id) {
                $scope.playing = playing;
                if (playing == true) {
                    $scope.play(id);
                } else {
                    $scope.pause(id);
                }
                $timeout(function () {
                });
            };

            $scope.seekControlChange = function(id, value) {
                $scope.playing = false;
                $scope.seek(id, $scope.seekbar.value);
            };

            function setupSeekbar() {
                $scope.seekbar = element.find('.seekbar-' + $scope.audiotrack.id)[0];
                $scope.seekbar.max = $scope.audioElement.duration;
                $scope.seekbar.min = 0;
                $scope.seekbar.value = $scope.audioElement.currentTime;
                $scope.rangevalue = $scope.audioElement.currentTime;
            }

            function formatTime(seconds) {
                var time = moment().startOf('day')
                                   .seconds(seconds)
                                   .format('mm:ss');
                return time;
            }

        }
    }
};

WjAudioDirective.$inject = ['$log', '$q', '$window', '$timeout', '$compile', '$stateParams', '$sce', 'firebaseModel', 'AssessmentModel', 'currentUserModel', 'remoteDispatcherService'];
module.exports = WjAudioDirective;

