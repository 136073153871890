var CreateDocumentController = function($scope) {
    $scope.panelNavigation.addPanels([{
        title: 'Upload Document',
        state: 'app.store.activities.create.document.upload',
        key: 'upload-document',
        locked: (key) => (key === 'select-activity'),
    }, {
        title: 'Describe Document',
        state: 'app.store.activities.create.document.describe',
        key: 'describe-document',
        locked: (key) => (key === 'select-activity' || key === 'upload-document'),
    }]);
};

CreateDocumentController.$inject = ['$scope'];
module.exports = CreateDocumentController;