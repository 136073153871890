var FileEditorDirective = function($log, tagModel, AssetTagModel, uploaderModel){
    return {
        restrict: 'E',
        replace: false,
        scope : {
            file : '='
        },
        templateUrl: '/toychest/modules/store/uploader/fileEditor.tpl.html',

        link: function (scope, element, attrs, controller) {

            $log.debug("[FileEditorDirective] link function()");

            scope.tags = AssetTagModel;

            scope.delete = function(){
                if(confirm("Delete?")){
                    //scope.file.$delete();

                    _.remove(uploaderModel.uploader.filesInProgress, function(asset){
                        return asset.id = scope.file.id;
                    });
                    scope.file.disabled = true;
                    scope.file.$save();
                    element.remove();
                    scope.$destroy();
                }
            };

            scope.togglePrivate = function(){
                scope.file.private = !scope.file.private;
                scope.file.$save();
            };
        }
    };
};

FileEditorDirective.$inject = ['$log', 'tagModel', 'AssetTagModel', 'uploaderModel'];
module.exports = FileEditorDirective;
