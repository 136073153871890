function plHistoryFactory($state) {
    const plHistory = {};

    plHistory.back = (defaultLoc, defaultParams) => {
        // TODO: prevent going "back" to a url that is the same (but maybe with different URL params).
        // ui-router does not seem to have a `back` function and `history` does not seem to
        // have a way to get the previous url before going back to it. So there's no easy
        // way to check first. Even if there was, determining what URL params should be
        // ignored and which are "different enough" pages is non-trivial.
        // if (history.length > 0 && defaultLoc.indexOf($state.current.name) < 0) {
        //     history.back();
        // } else {
            $state.go(defaultLoc, defaultParams);
        // }
    };

    return plHistory;
}
plHistoryFactory.$inject = ['$state'];
module.exports = plHistoryFactory;
