var FlashcardPreviewDirective = function() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            asset: '='
        },
        templateUrl: '/toychest/common/directives/Flashcards/FlashcardPreviewDirective.tpl.html',
        link: function($scope) {
            $scope.isAssetActive = isAssetActive;

            function isAssetActive() {
                return $scope.asset != null;
            }

            $scope.getClasses = () => {
                if (!$scope.asset) {
                    return {
                        'text-only': true
                    };
                }
                let thereAreNoImages = !$scope.asset.thumbnail_url && !$scope.asset.cropped;
                return {
                    'image-only': !$scope.asset.title && ($scope.asset.thumbnail_url || $scope.asset.cropped),
                    'text-only':  $scope.asset.title && thereAreNoImages || (!$scope.asset.title && thereAreNoImages)
                };
            };
        }
    };
};

FlashcardPreviewDirective.$inject = [];
module.exports = FlashcardPreviewDirective;