function plApiServiceUploadDocumentsFactory($q, plServiceDocuments, plFileAmazon, plClientServices) {
    const plApiServiceUploadDocuments = {};

    plApiServiceUploadDocuments.saveType = (files, type, clientService, signedOn, filenames) => {
        const deferred = $q.defer();

        plFileAmazon.getAmazonUrl({ namespace: type })
            .then((resAmazon) => {
                const awsInfo = resAmazon;
                plFileAmazon.uploadBulk(files, awsInfo.url, awsInfo.fields, `0_`, filenames)
                    .then((resFiles) => {
                        clientService.resFiles = resFiles;
                        plClientServices.saveFiles(clientService, type, signedOn)
                            .then(() => {
                                deferred.resolve();
                            }, (err) => {
                                deferred.reject(err);
                            });
                    });
            });

        return deferred.promise;
    };

    return plApiServiceUploadDocuments;
}
plApiServiceUploadDocumentsFactory.$inject = ['$q', 'plServiceDocuments', 'plFileAmazon', 'plClientServices'];
module.exports = plApiServiceUploadDocumentsFactory;
