function PlAvatar() {
    return {
        restrict: 'E',
        scope: {
            type: '@',
            size: '@?',
            photo: '@?',
        },
        templateUrl: '/core/pl-buttons/src/plAvatar/plAvatar.tpl.html',
        link: $scope => {
            $scope.getAvatarClasses = function getAvatarClasses() {
                return {
                    hasPhoto: $scope.photo,
                    'type-location': $scope.type === 'location',
                    small: $scope.size === 'small',
                    large: $scope.size === 'large',
                };
            };
        },
    };
}

module.exports = PlAvatar;
