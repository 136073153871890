/**
 * Controller for the pdf viewer directive
 * @param $scope
 * @constructor
 */
var PdfViewerController = function($log, $scope) {

    var init = function () {
        $log.debug("PdfViewController controller");
    };
    init();
};

PdfViewerController.$inject = ['$log', '$scope'];
module.exports = PdfViewerController;
