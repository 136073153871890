import ActivitiesListController from '../activities/ActivitiesListController';

class LessonsListController extends ActivitiesListController {
    constructor($state, $stateParams, CurrentUser, drfLessonModel, permissionsHelper, drfStoreHeroModel, heroService) {
        super($state, $stateParams, CurrentUser, drfLessonModel, permissionsHelper, drfStoreHeroModel, heroService);
        this.type = 'lesson';
        this.showTags = false;
        console.log('LessonsListController');
        heroService.getLessonHero().then((hero) => {
            this.hero = hero;
        })
    }

    query() {
        this._$state.go('app.store.lessons.all', this.filter.getRouteParams());
    }
}

LessonsListController.$inject = ['$state', '$stateParams', 'CurrentUser', 'drfLessonModel', 'permissionsHelper', 'drfStoreHeroModel', 'heroService'];
module.exports = LessonsListController;
