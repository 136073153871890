"use strict";


RoomClickService.$inject = [
    '$log',
    '$timeout'
];

/**
 * @param $log
 * @param $timeout
 */
function RoomClickService($log, $timeout) {
    var me = this;

    me.setShapesInteractive = () => {
        $('whiteboard, whiteboard .whiteboard-content').css('pointer-events', 'auto');
        $('whiteboard .whiteboard-content *').css('pointer-events', 'auto');
    };

    me.setShapesNonInteractive = () => {
        $('whiteboard, whiteboard .whiteboard-content').css('pointer-events', 'none');
        $('whiteboard .whiteboard-content *').css('pointer-events', 'none');
    };

    me.setWidgetboardInteractive = () => {
        $('widgets-board').css('pointer-events', 'auto');
    };

    me.setWidgetboardNonInteractive = () => {
        $('widgets-board').css('pointer-events', 'none');
    };

    me.dragstart = () => {
        $log.debug('[RoomClickService] Drag start, disable pointer events on svg and widget container');
        me.setShapesNonInteractive();
        me.setWidgetboardInteractive();

        $('widgets-board').addClass('interactive');
    };

    me.dragend = () => {
        $log.debug('[RoomClickService] Drag end, enable pointer events on svg and widget container');
        me.setShapesInteractive();
        me.setWidgetboardNonInteractive();
        $('widgets-board').removeClass('interactive');
    };


    let methods = {
        drawer: {
            dragStart: function () {
                console.log('Drawer: DragStart');
                me.setShapesNonInteractive();

                $('.whiteboard').css('pointer-events', 'none');
                $('widgets-board *').css('pointer-events', 'none');
            },

            dragEnd: function () {
                console.log('Drawer: DragEnd');
                me.setShapesInteractive();

                $('.whiteboard').css('pointer-events', '');
                $('widgets-board *').css('pointer-events', '');
            }
        },

        workspace: {
            dragStart: function () {
                console.log('Workspace: DragStart');

                me.setShapesNonInteractive();

                $('.whiteboard').css('pointer-events', 'none');
                $('widgets-board *').css('pointer-events', 'none');
            },

            dragEnd: function () {
                console.log('Workspace: DragEnd');
                me.setShapesInteractive();

                $('.whiteboard').css('pointer-events', '');
                $('widgets-board *').css('pointer-events', '');
            }
        }
    };

    this.trigger = (type, methodName) => {
        var method = methods[type];
        if (!method) {
            return;
        }
        method[methodName] && method[methodName]();
    }
}

module.exports = RoomClickService;