function DescribeActivityController($scope, $state, activityTags, currentUserModel) {
    const TAGS_PER_COLUMN = 8;
    const DEFAULT_CLINICIAN_TYPE = 'SLT';

    //temporarily, just always make activities private upon entering this view
    // JB - 12/16/20
    $scope.activity.private = true;
    $scope.userCanShare = false;
    currentUserModel.getPermissions().then(permissions => {
        $scope.userCanShare = permissions.includes('lightyear.share_activity');
    });

    $scope.tags = {
        grades: activityTags.grades.tags
    };

    if ($scope.activity === undefined) {
        $scope.panelNavigation.gotoPanel('select-activity');
        return false;
    }
    setTimeout(() => $(window).trigger('resizeTextElements'), 300);

    $scope.currentUserModel = currentUserModel;
    $scope.activity.private = false;
    $scope.isPldrl = false;

    $scope.$watch('isPldrl', (isPldrl) => {
        if (isPldrl) {
            $scope.activity.section = 'pldrl';
            $scope.activity.private = false;
        } else {
            $scope.activity.section = 'ugc';
            $scope.activity.private = true;
        }
    });

    $scope.saveActivity = () => {
        $scope.activity.$save().then((result) => {
            $state.go('app.store.activities.detail', {
                slug: result.slug,
            });
        });
    };

    function tagTabs() {
        const tabs = Object.keys(activityTags)
            .map((key) => {
                return {
                    key: key,
                    title: activityTags[key].title,
                };
            }).filter((tab) => tab.title !== activityTags.grades.title);
        return _.sortBy(tabs, 'title');
    }
    $scope.tagTabs = tagTabs();

    $scope.selectTab = (tab) => {
        $scope.currentTab = tab;
        $scope.tags.subjects = Object.keys(activityTags[$scope.currentTab.key].tags).map((key) => {
            return {
                key: key,
                name: activityTags[tab.key].tags[key],
            };
        });
    };

    function setDefaultTab() {
        let clinitianType = DEFAULT_CLINICIAN_TYPE;
        if (currentUserModel.user && currentUserModel.user.serviceTypes && currentUserModel.user.serviceTypes.length) {
            clinitianType = currentUserModel.user.serviceTypes[0].name;
        }
        const defaultTab = $scope.tagTabs.find((tab) => tab.key === clinitianType);
        $scope.tagTabs = [defaultTab, ..._.without($scope.tagTabs, defaultTab)];
        $scope.selectTab(defaultTab);
    }
    setDefaultTab();

    $scope.numOfSubjectColumns = () => {
        const numberOfColumns = Math.ceil($scope.tags.subjects.length / TAGS_PER_COLUMN);
        return Array.from(new Array(numberOfColumns).keys());
    };

    $scope.subjectTagsForColumn = (column) => $scope.tags.subjects
        .filter((tag, idx) => (idx < column * TAGS_PER_COLUMN && idx >= (column - 1) * TAGS_PER_COLUMN));

    $scope.panelNavigation.current = 'describe-document';
    return true;
}

DescribeActivityController.$inject = ['$scope', '$state', 'activityTags', 'currentUserModel'];
module.exports = DescribeActivityController;
