function plInputSelectApiDirective($timeout, plLodash) {
    return {
        restrict: 'E',
        scope: {
            model: '=',
            options: '=',
            onSearch: '&',
            onChange: '&?',
            label: '@?',
            debounce: '=?',
            loading: '=?',
            // expandContainer: '@?'
        },
        replace: true,
        templateUrl: '/core/pl-inputs/src/plInputSelectApi/plInputSelectApi.tpl.html',
        link: ($scope) => {
            $scope.searchText = '';
            $scope.optionsSelect = [];
            let selectedOption = {};
            // $scope.expandContainer = ($scope.expandContainer !== undefined) ?
            //  $scope.expandContainer : false;
            let timeoutSearch = false;
            $scope.debounce = ($scope.debounce !== undefined) ?
             $scope.debounce : 1000;
            $scope.loading = ($scope.loading !== undefined) ? $scope.loading : false;

            $scope.$watch('searchText', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal)) {
                    search();
                }
            });

            // We need to intercept any option changes to ensure the selected option is always present.
            // Otherwise changing options will blank out the model by default with Angular <select>.
            $scope.$watch('options', (newVal, oldVal) => {
                const optionsSelect = $scope.options;
                if ($scope.model) {
                    const index = plLodash.findIndex(optionsSelect, 'value', $scope.model);
                    if (index < 0) {
                        optionsSelect.push(selectedOption);
                    }
                }
                $scope.optionsSelect = optionsSelect;
            });

            // Save the selected option for re-inserting it on options change if current search does not include it.
            $scope.$watch('model', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal) && newVal) {
                    const index = plLodash.findIndex($scope.optionsSelect, 'value', $scope.model);
                    if (index >= 0) {
                        selectedOption = $scope.optionsSelect[index];
                    }
                }
            });

            function search() {
                if (timeoutSearch) {
                    $timeout.cancel(timeoutSearch);
                }
                timeoutSearch = $timeout(() => {
                    $scope.onSearch()($scope.searchText);
                }, $scope.debounce);
            }

            $scope.onChangeWrapper = () => {
                if ($scope.onChange) {
                    // Need timeout to ensure model is set before change is called.
                    $timeout(() => {
                        $scope.onChange();
                    }, 0);
                }
            };
        },
    };
}

plInputSelectApiDirective.$inject = ['$timeout', 'plLodash'];
module.exports = plInputSelectApiDirective;
