import ActivitiesListController from '../activities/ActivitiesListController';

class GamesListController extends ActivitiesListController {
    constructor($state, $stateParams, CurrentUser, drfGameModel, heroService) {
        super($state, $stateParams, CurrentUser, drfGameModel);
        this.type = 'game';
        this.showTags = false;
        heroService.getGameHero().then((hero) => {
            this.hero = hero;
        })
    }

    query() {
        this._$state.go('app.store.games.all', this.filter.getRouteParams());
    }
}

GamesListController.$inject = ['$state', '$stateParams', 'CurrentUser', 'drfGameModel', 'heroService'];
module.exports = GamesListController;
