var WjAudioAssessmentViewerDirective = function ($log, $q, $window, $timeout, $compile, $stateParams, firebaseModel, AssessmentModel, remoteDispatcherService) {
    return {
        restrict: "E",
        templateUrl: '/core/toys/app/src/toys/modules/assessment/wjaudioassessment/wjAudioAssessmentViewerDirective.tpl.html',

        link: function ($scope, element, attrs) {
            $scope.showAudio = true;
        }
    }
};

WjAudioAssessmentViewerDirective.$inject = ['$log', '$q', '$window', '$timeout', '$compile', '$stateParams', 'firebaseModel', 'AssessmentModel', 'remoteDispatcherService'];
module.exports = WjAudioAssessmentViewerDirective;

