function AssetsListController(
    $log,
    $timeout,
    $scope,
    $state,
    $stateParams,
    $window,
    assetModel,
    uploaderModel,
    currentUserModel,
    AssetTagModel,
    drfActivityModel,
    AssetsStateModel,
    $animate
) {
    // --------------- REFACTOR THIS CODE ---------------
    const assets = $scope.assets;
    // --------------- REFACTOR THIS CODE ---------------

    this.previewTags = [];
    this.preview = null;
    this.selectedAssetIndex = null;

    this.width = 0;
    this.dimensions = {};

    this.setPreview = (index) => {
        const asset = assets[index];
        if (!asset) {
            return;
        }
        this.selectedAssetIndex = index;
        this.preview = asset;
        cloneTags();
    };

    this.isPreviewVisisble = (asset) => {
        if (this.preview && assets && assets.length) {
            const cols = Math.floor(this.width / 124.5);

            const assetIndex = assets.indexOf(asset);
            const assetRow = Math.floor(assetIndex / cols);
            const assetCol = assetIndex % cols;

            const previewIndex = assets.indexOf(this.preview);
            const previewRow = Math.floor(previewIndex / cols);

            return previewRow === assetRow && (assetCol === cols - 1 || assetIndex === assets.length - 1);
        }

        return false;
    };

    this.triangleLocation = () => {
        if (this.preview) {
            const cols = Math.floor(this.width / 124.5);
            const previewIndex = assets.indexOf(this.preview);
            const previewCol = previewIndex % cols;

            return previewCol * 124.5 + 124.5 * 0.5;
        }

        return 0;
    };

    let resizeId;

    function turnOffAnimate() {
        $animate.enabled(false);
        $timeout.cancel(resizeId);
        resizeId = $timeout(turnOnAnimate, 500);
    }

    function turnOnAnimate(){
        $animate.enabled(true);
    }

    this.onResizeHandler = (assetId, data) => {
        turnOffAnimate();
        this.dimensions[assetId] = data;
    };


    // --------------- REFACTOR CODE BELOW ---------------
    $scope.tags = AssetTagModel;
    $scope.state = AssetsStateModel;

    // this has been moved to the AssetsController so that we can refresh it after uploads
    // $scope.assets = new assetModel.Collection();
    $scope.selectedTag = null;
    $scope.showSpinner = true;
    $scope.baseFilter = {};

    function createBaseFilter() {
        $scope.baseFilter = {};
        $scope.baseFilter.disabled = false;
        if ($state.is('app.store.assets.all')) {} else if ($state.is('app.store.assets.trash')) {
            $scope.baseFilter.disabled = true;
            $scope.baseFilter.owner__uuid = currentUserModel.user.uuid;
            $scope.baseFilter.private = false;
        } else if ($state.is('app.store.assets.my_images')) {
            $scope.baseFilter.owner__uuid = currentUserModel.user.uuid;
            $scope.baseFilter.file_type = 'image';
        } else if ($state.is('app.store.assets.tag')) {
            $scope.baseFilter.tag = $stateParams.tag;
            $scope.baseFilter.private = false;
            $scope.selectedTag = {
                'name': $stateParams.tag
            };
        }
    }

    const runBaseFilter = () => {
        $scope.state.search = '';
        createBaseFilter();
        $scope.assets.$clearFilter();
        $scope.assets.$filter($scope.baseFilter);
        $scope.showSpinner = true;
        $scope.assets.$fetch().then(stopShowSpinner, stopShowSpinner);
    };
    createBaseFilter();
    if ($scope.state.lastState != $state.current.name) {
        runBaseFilter();
        $scope.state.lastState = $state.current.name;
    } else if ($state.params.tag != $scope.state.lastTag) {
        runBaseFilter();
        $scope.state.lastTag = $state.params.tag;
    }

    $scope.dropzoneVisible = false;
    $scope.uploaderModel = uploaderModel;

    $scope.searchUpdate = () => {
        if ($scope.state.search.length > 2 || $scope.state.search.length == 0) {
            $scope.filter();
        }
    };

    $scope.filetypes = [{
        name: 'Any',
        value: null,
    }, {
        name: 'photo',
        value: 'photo',
    }, {
        name: 'pdf',
        value: 'pdf',
    }];

    $scope.tagUpdate = (tag) => {
        $scope.selectedTag = tag;
        $scope.filter()
    };

    $scope.filter = _.throttle(() => {
        $scope.showSpinner = true;
        let filter = _.merge($scope.baseFilter, {
            q: $scope.state.search,
            tag: $scope.selectedTag ? $scope.selectedTag.name : null,
            disabled: false
        });
        $scope.assets.$filter(filter);
        $scope.assets.$fetch().then(() => {
            stopShowSpinner();
            // HACK. Need to fix this.
            $timeout(() => {
                let w = angular.element($window);
                w.resize();
            }, 500);
        }, stopShowSpinner);
    }, 300, {
        leading: false,
    });

    $scope.filesSelected = (files) => $scope.$parent.selectFiles(files);

    $scope.isActive = (state, params) => {
        if ($state.is(state, params)) {
            return 'active';
        }
        return '';
    };

    $scope.assetDetailsNode = $('.asset-details');

    $scope.selectedItem = null;
    $scope.selectedNode = null;

    this.getValueFromAsset = () => {
        if (!this.preview) {
            return null;
        }
        return this.preview.title;
    };

    this.saveAsset = (value) => {
        if (!this.preview) {
            return;
        }
        this.preview.title = value;
        this.preview.$save();
    };

    this.closePreview = () => {
        this.preview = null;
    };

    function stopShowSpinner() {
        $scope.showSpinner = false;
    }

    const closePreview = () => {
        this.closePreview();
        $scope.showSpinner = true;

        $scope.assets.$fetch().then(stopShowSpinner, stopShowSpinner);
    };

    this.deletePreview = () => {
        if (!this.preview) {
            return;
        }
        this.preview.$delete().then(closePreview, closePreview);
    };

    const cloneTags = () => {
        this.previewTags = _.map(this.preview.tags, (item) => angular.copy(item));
    };

    this.addTag = (tag) => {
        this.preview.$addTag(tag);
        return true;
    };

    this.removeTag = (tag) => {
        this.preview.$removeTag(tag);
        return true;
    };

    this.moveToNextPreview = () => {
        if (_.isNull(this.selectedAssetIndex) || this.preview === assets[assets.length - 1]) {
            return;
        }
        this.setPreview(++this.selectedAssetIndex);
    };

    this.moveToPrevPreview = () => {
        if (!this.selectedAssetIndex || this.preview === assets[0]) {
            return;
        }
        this.setPreview(--this.selectedAssetIndex);
    };
}

AssetsListController.$inject = [
    '$log',
    '$timeout',
    '$scope',
    '$state',
    '$stateParams',
    '$window',
    'assetModel',
    'uploaderModel',
    'currentUserModel',
    'AssetTagModel',
    'drfActivityModel',
    'AssetsStateModel',
    '$animate'
];

module.exports = AssetsListController;
