"use strict";
var FirebaseMock = function($log, Assert, DataStore, Events, Pushkey, Reference) {
    class FirebaseMock {
        constructor(url){
            this._ref = new Reference(url);
        }

        /**
         * on
         * @param eventType
         * @param callback
         * @param cancelCallback
         * @param context
         */
        on(eventType, callback, cancelCallback, context) {
            return this._ref.on(eventType, callback, cancelCallback, context);
        };

        /**
         * once
         * @param eventType
         * @param callback
         * @param context
         */
        once(eventType, callback, context) {
            return this._ref.once(eventType, callback, context);
        };

        /**
         * off
         * @param eventType
         * @param callback
         * @param context
         */
        off(eventType, callback, context) {
            return this._ref.off(eventType, callback, context);
        };

        //auth() {
        //};

        // Append the provided path to the ref path
        // For example: https://foo.com/car + 'dog/bar' -> https://foo.com/car/dog/bar
        child(childPath) {
            return this._ref.child(childPath);
        };

        // Rip off the last location element
        // For example: https://foo.com/car/dog/bar -> https://foo.com/car/dog
        get parent() {
            return this._ref.parent();
        };

        // Rip off the whole subpath and just return the root url
        // For example: https://foo.com/cat/dog/bar -> https://foo.com
        get root() {
            return this._ref.root();
        };

        // Return the last token in a firebase location
        // For example: https://foo.com/cat/dog -> dog
        get key() {
            return this._ref.key();
        };

        // Deprecated, use key()
        get name() {
            return this._ref.name();
        };

        /**
         * toString()
         * Returns full url path of this reference
         * @returns {*}
         */
        toString() {
            return this._ref.toString();
        };

        // Write data to the location
        // Passing null will remove the data
        /**
         * set
         * @param value
         * @param onComplete
         */
        set(value, onComplete) {
            return this._ref.set(value, onComplete);
        };


        /**
         * setWithPriority
         * @param value
         * @param priority
         * @param onComplete
         */
        setWithPriority(value, priority, onComplete) {
            return this._ref.setWithPriority(value, priority, onComplete);
        };

        /**
         * setPriority
         * @param priority
         * @param onComplete
         */
        setPriority(priority, onComplete) {
            return this._ref.setPriority(priority, onComplete);
        };

        // Update data at the location. Changes the specified children but leaves unspecified children alone.
        /**
         * update
         * @param value
         * @param onComplete
         */
        update(value, onComplete) {
            return this._ref.update(value, onComplete);
        };

        /**
         * remove
         * @param onComplete
         */
        remove(onComplete) {
            return this._ref.remove(onComplete);
        };

        /**
         * push
         * @param value
         * @param onComplete
         * @returns {FirebaseMock}
         */
        push(value, onComplete) {
            return this._ref.push(value, onComplete);
        };

        /**
         * transaction
         * @param updateFn
         * @param onComplete
         * @param applyLocally
         */
        //transaction(updateFn, onComplete, applyLocally) {
        //    $log.debug('Not Implemented');
        //};

        authWithCustomToken(token, onComplete) {
            return this._ref.authWithCustomToken(token, onComplete);
        };
    }

    return FirebaseMock;
};
FirebaseMock.$inject = ['$log', 'Assert', 'DataStore', 'Events', 'Pushkey', 'Reference'];
module.exports = FirebaseMock;