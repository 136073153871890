const APPLICATION_SERVICE_LOCAL_STORAGE_PREFIX = 'applicationConfig';

class ApplicationService {
    constructor(applicationDefaults, localStorageService, $window) {
        this._localStorageService = localStorageService;
        this._applicationDefaults = this.serializeDefaults(applicationDefaults);
        this.overriden = {};
        // Deep copy
        this._copyDefaultsToThis();
        const _envConfig = $window['_envFileConfig'];
        const _envKeys = Object.keys(applicationDefaults);
        _envKeys.forEach(key => {
          if (this[key] && _envConfig && _envConfig.apps && _envConfig.apps[key]) {
            this[key].url = _envConfig.apps[key].url;
            applicationDefaults[key].url = _envConfig.apps[key].url;
          }
        });
        if (_envConfig) {
          const authUrl = this.auth.url;
          this.auth = Object.assign(this.auth, {
            changePassword: `${authUrl}/password/change`,
            hijackReleaseUrl: `${authUrl}/hijack/release-hijack/`,
            loginUrl: `${authUrl}/login/`,
            logoutUrl: `${authUrl}/logout/`,
            rightsUrl: `${authUrl}/api/v1/user/`,
            statusUrl: `${authUrl}/api/v1/status/`,
          });
          const apiWorkplace = _envConfig.apps && _envConfig.apps['apiWorkplace'];
          if (apiWorkplace) {
            this['apiSchedule'].url = apiWorkplace.url;
            this['apiClient'].url = apiWorkplace.url;
          }
        }

        // local storage overrides the default.
        _.each(this, (appConfig, app) => {
            _.each(appConfig, (value, key) => {
                let overriddenProp = this.getProperty(app, key);
                if (overriddenProp) {
                    this[app][key] = overriddenProp;
                    this._setOverriden(app, key);
                }
            })
        });
    }

    serializeDefaults(applicationDefaults) {
        const str = JSON.stringify(applicationDefaults);

        if (
            str.includes("presence.com") &&
            window.location.hostname.endsWith("presencelearning.com")
        ) {
            return JSON.parse(
                str.replace(/(presence\.com)/g, "presencelearning.com")
            );
        }

        return applicationDefaults;
    }

    getProperty(app, property) {
        return this._localStorageService.get(`${APPLICATION_SERVICE_LOCAL_STORAGE_PREFIX}.${app}.${property}`);
    }

    setProperty(app, property, value) {
        if (this[app] && this[app][property]) {
            this[app][property] = value;
            this._localStorageService.set(`${APPLICATION_SERVICE_LOCAL_STORAGE_PREFIX}.${app}.${property}`, value);
            this._setOverriden(app, property);
        }
    }

    removeProperty(app, property) {
        this._localStorageService.remove(`${APPLICATION_SERVICE_LOCAL_STORAGE_PREFIX}.${app}.${property}`);
    }

    _setOverriden(app, property) {
        this.overriden[app] = this.overriden[app] || {};
        this.overriden[app][property] = true;
    }

    _copyDefaultsToThis() {
        const copyOfDefaults = JSON.parse(JSON.stringify(this._applicationDefaults));
        _.each(copyOfDefaults, (config, app) => {
            this[app] = config;
        })
        this.overriden = {};
    }

    resetToDefaults() {
        this._copyDefaultsToThis();
        // local storage overrides the default.
        _.each(this, (appConfig, app) => {
            _.each(appConfig, (value, key) => {
                this.removeProperty(app, key);
            })
        });
    }

}
ApplicationService.$inject = ['applicationDefaults', 'localStorageService', '$window'];
export default ApplicationService;
