var TextareaAutoResize = function($log){
    return {
        restrict: 'A',

        link: function ($scope, $element, attrs) {
            $scope.resize = resize;
            var startHeight = $element.css('height');
            var intStartHeight = parseInt(startHeight) | 0;
            $element.on('input propertychange', resize);
            $(window).on('resizeTextElements resize', resize);

            $scope.$watch(function() {
                return $element.val();
            }, resize);

            function resize() {
                $element.css('height', startHeight);
                var scrollHeight = $element[0].scrollHeight;
                var newHeight = intStartHeight > scrollHeight
                                ? intStartHeight
                                : scrollHeight;
                $element.css('height', newHeight);
            }
        }
    };
};

TextareaAutoResize.$inject = ['$log'];
module.exports = TextareaAutoResize;
