function PdfLessonDirective($log, $timeout, $q, LessonModel) {
  return {
      restrict: 'E',
      templateUrl: '/core/toys/app/src/toys/modules/lesson/PdfLesson.tpl.html',

      link: ($scope, element) => {
          const DEFAULT_PAGE_NUM = 1;
          const DEFAULT_ROTATION_DEG = 0;
          const DEFAULT_MODE = 1;

          const DEFAULT_SCALE = 1.0;
          const DEFAULT_SCROLL_X = 0;
          const DEFAULT_SCROLL_Y = 0;
          let currentPageRotation = 0;
          const SINGLE_PAGE_MODE = 1;
          const DBL_PAGE_EVEN_MODE = 2;
          const DBL_PAGE_ODD_MODE = 3;
          let pagesRendering = [];
          let pageRotations = {};

          let canvas = element.find('canvas');
          let canvas2;
          let context2;
          let context; // = canvas[0].getContext('2d');
          let currentPage; // initialized in FB CB
          const holder = element.find('.pdf-holder');
          const body = angular.element('.workspace');
          let page = null;
          let page2 = null;
          let pdf = null;
          let scale; // initialized in FB CB
          let scrollXPercent = 0; // initialized in FB CB
          let scrollYPercent = 0; // initialized in FB CB
          let scrollOffset = {
              x: null,
              y: null
          };
          let totalPages = null;
          let boundingEl = angular.element('.aspect-ratio-constriction');
          const w = boundingEl.length ? boundingEl : angular.element('.lesson-holder');
          let updatingScrollFromFb = false;
          $scope.loading = false;
          $scope.bookMode = SINGLE_PAGE_MODE;
          $scope.soloPage = false;

          function setHolderHeight() {
              holder.height(w.height());
              holder.width(w.width());
          }

          function setupRotationListener() {
              LessonModel.getSessionRef().child('pageRotation')
                  .on('value', (snap) => {
                      pageRotations = snap.val();
                      const pageToRotate = pageRotations ? Object.keys(pageRotations).find(
                          (pageToRotate) => pageToRotate.slice(4) === currentPage
                      ) : [];
                      //const degreeToRotate = pageToRotate && pageRotations ? pageRotations[pageToRotate] || 0 : 0;

                      //if (degreeToRotate === currentPageRotation) {
                      //    return;
                      //}

                      //if (Number.isInteger(degreeToRotate)) {
                      //    render(currentPage, scale, degreeToRotate);
                      //}
                  });
          }

          function loadPDF(url) {
            $scope.loading = true;
            const loadProtectedPdf = (protectedUrl) => {
                PDFJS.getDocument(protectedUrl).then((loadedPdf) => {
                pdf = loadedPdf;
                loadState().then(setupRotationListener);
              }, (error) => {
                $log.warn("Error while getting PDF document:", error);
              });
            }
            // check if this url is already cached before we ask for it
            let protectedUrl = LessonModel.cachedProtectedAsset(url);
            if (protectedUrl == null) {
                $scope.secureUrl = LessonModel.getProtectedContentUrl(url).then( (data) => {
                    protectedUrl = data.assets[url];
                    loadProtectedPdf(protectedUrl);
                });
            } else {
              loadProtectedPdf(protectedUrl);
            }
          }

          function onMouseMove(event) {
              let deltaY = scrollOffset.y - event.clientY;
              let deltaX = scrollOffset.x - event.clientX;

              positionPage(deltaX, deltaY);
              saveScrollPosition(deltaX, deltaY);

              $timeout();
          }

          function onMouseUp() {
              holder.off('mousemove', onMouseMove);
              holder.off('mouseup', onMouseUp);

              scrollOffset.x = null;
              scrollOffset.y = null;

              $timeout();
          }

          $scope.onMouseDown = (event) => {
              holder.on('mousemove', onMouseMove);
              holder.on('mouseup', onMouseUp);

              scrollOffset.y = holder.scrollTop() + event.clientY;
              scrollOffset.x = holder.scrollLeft() + event.clientX;
          };

          // Zooming
          $scope.zoomIn = () =>
          !$scope.loading && $scope.setScale(scale * 1.1);

          $scope.zoomOut = () =>
          !$scope.loading && $scope.setScale(scale * 0.9);

          $scope.setScale = (s) =>
              LessonModel.getSessionRef().child(fbScalePath()).set(isNaN(s) ? 1.0 : s);


          $scope.rotate90 = (degree) =>
              LessonModel.getSessionRef().child(fbPageRotationPath()).update(degree);

          // Page Changing
          $scope.next = () =>
          !$scope.loading && currentPage < totalPages - 1 && $scope.gotoPage(currentPage + 1);

          $scope.prev = () =>
          !$scope.loading && currentPage > 1 && $scope.gotoPage(currentPage - 1);

          $scope.setMode = (mode) => {
              LessonModel.getSessionRef()
                  .child(fbModePath())
                  .set(mode);
          }

          $scope.gotoPage = (pageNum) => {
              LessonModel.getSessionRef()
                  .child(fbPageNumPath())
                  .set(pageNum);
          };

          /**
           * Determines the percentage scroll of the page with 0 being scrolled to the top
           * of the page and 100% being scrolled to the bottom of the page. If no scrolling
           * is present, returns 0;
           * @param  {[type]} x [description]
           * @return {[type]}   [description]
           */
          function calculatePagePercentY() {
              return holder.scrollTop() / canvas.height();
          }

          function calculatePagePercentX() {
              return holder.scrollLeft() / canvas.width();
          }

          function saveScrollPosition() {
              if (LessonModel.share && !updatingScrollFromFb) {
                  scrollYPercent = calculatePagePercentY();
                  scrollXPercent = calculatePagePercentX();

                  LessonModel.getSessionRef().child(fbScrollXPercentPath()).set(scrollXPercent);
                  LessonModel.getSessionRef().child(fbScrollYPercentPath()).set(scrollYPercent);
              }
              updatingScrollFromFb = false;
          }

          let scrollPage = _.debounce((e) => saveScrollPosition(
              e.currentTarget.scrollLeft,
              e.currentTarget.scrollTop
          ), 20);

          function calcScale(pdfW, pdfH, containerW, containerH, scale) {
              let newScale = scale;
              let scaleX;
              let scaleY;

              if (pdfW < containerW && pdfH < containerH) {
                  scaleX = containerW / pdfW;
                  scaleY = containerH / pdfH;
                  newScale = Math.min(scaleX, scaleY) * scale;
              }
              else if (pdfW > containerW && pdfH > containerH) {
                  scaleX = containerW / pdfW;
                  scaleY = containerH / pdfH;
                  newScale = Math.min(scaleX, scaleY) * scale;
              }
              else if (pdfW < containerW && pdfH > containerH) {
                  // it's taller than the container, make the height smaller
                  scaleY = containerH / pdfH;
                  newScale = scaleY * scale;
              }
              else if (pdfW > containerW && pdfH < containerH) {
                  // it's wider than the container, make it narrower
                  scaleX = containerW / pdfW;
                  newScale = scaleX * scale;
              }
              return newScale;
          }

          function positionPageX(x) {
              holder.scrollLeft(x);
          }

          function positionPageY(y) {
              holder.scrollTop(y);
          }

          function positionPage(x, y) {
              positionPageX(x);
              positionPageY(y);
          }

          function positionPageXFromPercentage(xPercent) {
              positionPageX(xPercent * canvas.width());
          }

          function positionPageYFromPercentage(yPercent) {
              positionPageY(yPercent * canvas.height());
          }

          function positionPageFromPercentage(xPercent, yPercent) {
              positionPageXFromPercentage(xPercent);
              positionPageYFromPercentage(yPercent);
          }


          function render(pageNum, scaleFactor, rotation, xPercent = scrollXPercent, yPercent = scrollYPercent) {
              //if(pagesRendering.indexOf(pageNum) > 0){
              //    return;
              //}
              //pagesRendering.push(pageNum);

              setHolderHeight();
              if (pageNum === totalPages || (pageNum === 1 && $scope.bookMode === DBL_PAGE_EVEN_MODE)) {
                  $scope.soloPage = true;
              } else {
                  $scope.soloPage = false;
              }

              if ($scope.bookMode === SINGLE_PAGE_MODE || $scope.soloPage) {
                  return renderSingle(pageNum, scaleFactor, rotation, xPercent, yPercent);
              } else {
                  return renderDouble(pageNum, scaleFactor, rotation, xPercent, yPercent);
              }
          }

          function renderSingle(pageNum, scaleFactor, rotation, xPercent = scrollXPercent, yPercent = scrollYPercent) {
              if ($scope.renderTask) {
                  $scope.renderTask.cancel();
              }

              pageNum = pageNum || currentPage;
              scale = scaleFactor || scale;

              if (rotation !== undefined && Number.isInteger(rotation)) {
                  currentPageRotation = rotation;
              }

              $scope.currentlyRenderingNum = pageNum;

              return pdf && pdf.getPage(pageNum).then((p) => {
                      if ($scope.currentlyRenderingNum !== pageNum) {
                          return;
                      }

                      // Assign here in case getPage fails.
                      currentPage = pageNum;
                      page = p;

                      let viewport = page.getViewport(1.0, currentPageRotation);
                      let viewportScale = calcScale(viewport.width, viewport.height, holder.width(), holder.height(), scale);

                      viewport = page.getViewport(viewportScale, currentPageRotation);
                      canvas = element.find('.pdf-holder-canvas');
                      canvas.attr('width', viewport.width);
                      if(scaleFactor === 1) {
                          canvas.attr('height', viewport.height - 10); //suck in the height by 10 or scrollbars appear prematurely
                      } else {
                          canvas.attr('height', viewport.height);
                      }

                      $('.pageHolder').width('100%');
                      $('.pageHolder').height('100%');


                      let pageTop;
                      if (viewport.height > viewport.width) {
                          pageTop = Math.max((w.height() - viewport.height) / 2, 0);
                      } else {
                          pageTop = Math.max((w.height() - viewport.width) / 2, 0);
                      }

                      $('.pageHolder').css('top', pageTop);

                      context = canvas[0].getContext('2d');

                      let renderContext = {
                          canvasContext: context,
                          viewport: viewport
                      };
                      $timeout();
                      $scope.renderTask = page.render(renderContext);

                      return $scope.renderTask.promise.then(() => {
                          $scope.loading = false;
                          positionPageFromPercentage(xPercent, yPercent);

                          $timeout();
                      }, (error) => {
                          $log.warn(error);
                      });
                  }, (err) => {
                      $log.debug('Error retreiving PDF page', err, arguments);
                  });
          }

          function renderDouble(pageNum, scaleFactor, rotation, xPercent = scrollXPercent, yPercent = scrollYPercent) {

              if ($scope.renderTask) {
                  $scope.renderTask.cancel();
              }

              pageNum = pageNum || currentPage;
              scale = scaleFactor || scale;

              if (rotation !== undefined && Number.isInteger(rotation)) {
                  currentPageRotation = rotation;
              }

              $scope.currentlyRenderingNum = pageNum;

              return pdf && pdf.getPage(pageNum).then((p) => {
                      if ($scope.currentlyRenderingNum !== pageNum) {
                          return;
                      }

                      // Assign here in case getPage fails.
                      currentPage = pageNum;
                      page = p;

                      let viewport = page.getViewport(1.0, currentPageRotation);
                      let viewportScale;

                      viewportScale = calcScale(viewport.width, viewport.height, holder.width() / 2, holder.height(), scale);

                      return pdf & pdf.getPage(pageNum + 1).then((p) => {

                              // Assign here in case getPage fails.
                              currentPage = pageNum;
                              page2 = p;

                              let rotationDeg;
                              if (pageRotations) {
                                  rotationDeg = Object.keys(pageRotations).find((pageToRotate) =>
                                      pageToRotate.slice(4) == (pageNum + 1)
                                  );

                                  rotationDeg = pageRotations[rotationDeg];
                              }

                              if (!rotationDeg) {
                                  rotationDeg = 0;
                              }

                              let viewport2 = page2.getViewport(1.0, rotationDeg);
                              let viewportScale2;

                              viewportScale2 = calcScale(viewport2.width, viewport2.height, holder.width() / 2, holder.height(), scale);

                              let trueViewportScale = Math.min(viewportScale, viewportScale2);

                              /////page 1 ////
                              viewport = page.getViewport(trueViewportScale, currentPageRotation);
                              canvas = element.find('.pdf-holder-canvas');
                              canvas.attr('width', 0.9 * viewport.width);
                              canvas.attr('height', 0.9 * viewport.height);

                              context = canvas[0].getContext('2d');

                              let renderContext = {
                                  canvasContext: context,
                                  viewport: viewport
                              };
                              $scope.renderTask = page.render(renderContext);
                              $timeout();
                              ////end page 1/////

                              //page2//
                              viewport2 = page2.getViewport(trueViewportScale, rotationDeg);
                              canvas2 = element.find('.page2-canvas');
                              canvas2.attr('width', 0.9 * viewport2.width);
                              canvas2.attr('height', 0.9 * viewport2.height); //suck in the height by 10 or scrollbars appear prematurely

                              $('.pageHolder').width(holder.width() * scale);

                              let delta = Math.max(viewport2.height, viewport2.width);
                              let pageTop = Math.max((w.height() - delta) / 2, 0);
                              $('.pageHolder').css('top', pageTop);

                              context2 = canvas2[0].getContext('2d');

                              let renderContext2 = {
                                  canvasContext: context2,
                                  viewport: viewport2
                              };
                              $timeout();
                              $scope.renderTask = page2.render(renderContext2);
                              // end page2 //

                              return $scope.renderTask.promise.then(() => {
                                  $scope.loading = false;
                                  positionPageFromPercentage(xPercent, yPercent);

                                  $timeout();
                              }, (error) => {
                                  $log.warn(error);
                              });
                          }, (err) => {
                              $log.debug('Error retreiving PDF page', err, arguments);
                          });
                  });
          }

          function fbPageNumPath() {
              return 'pageNum';
          }

          function fbModePath() {
              return 'bookMode';
          }

          function fbScalePath() {
              return 'scale';
          }

          function fbPageRotationPath() {
              return 'pageRotation';
          }

          function fbScrollXPercentPath() {
              return 'scrollXPercent';
          }

          function fbScrollYPercentPath() {
              return 'scrollYPercent';
          }

          function getValueFromSnap(snap, key, defaultValue) {
              const val = snap.val();
              let newVal = val || defaultValue;

              if (val !== null && typeof val === 'object') {
                  newVal = val[key];
              }

              return newVal || defaultValue;
          }

          function getPageRotationFromSnap(snap, pageNum) {
              let pageRotations = getValueFromSnap(snap, fbPageRotationPath(), DEFAULT_ROTATION_DEG);
              let existingRotationPage = Object.keys(pageRotations).find((pageToRotate) => {
                  return pageToRotate.slice(4) == pageNum;
              });

              if (existingRotationPage) {
                  return pageRotations[existingRotationPage];
              }

              return DEFAULT_ROTATION_DEG;
          }

          function getPageNumFromSnap(snap) {
              return getValueFromSnap(snap, fbPageNumPath(), DEFAULT_PAGE_NUM);
          }

          function getModeFromSnap(snap) {
              return getValueFromSnap(snap, fbModePath(), DEFAULT_MODE);
          }

          function getScaleFromSnap(snap) {
              return getValueFromSnap(snap, fbScalePath(), DEFAULT_SCALE);
          }

          function getScrollXFromSnap(snap) {
              scrollXPercent = getValueFromSnap(snap, fbScrollXPercentPath(), DEFAULT_SCROLL_X);
              return scrollXPercent;
          }

          function getScrollYFromSnap(snap) {
              scrollYPercent = getValueFromSnap(snap, fbScrollYPercentPath(), DEFAULT_SCROLL_Y);
              return scrollYPercent;
          }

          function handleActivityError(error) {
              $scope.loading = false;
              $log.debug('[PdfViewerDirective] lesson ref load error:', error.code);
          }

          function loadState() {
              const deferScrollX = $q.defer();
              const deferScrollY = $q.defer();
              const deferPage = $q.defer();

              LessonModel.getSessionRef().on('value', (snap) => {
                  let newPage = getPageNumFromSnap(snap);
                  const newScale = getScaleFromSnap(snap);
                  const newRotation = getPageRotationFromSnap(snap, newPage);
                  const newMode = getModeFromSnap(snap);

                  if (newPage !== currentPage ||
                      newScale !== scale ||
                      newMode !== $scope.bookMode ||
                      newRotation !== currentPageRotation) {

                      if ($scope.bookMode !== newMode && newMode === DBL_PAGE_ODD_MODE && newPage % 2 !== 1) {
                          newPage = Math.min(newPage - 1, totalPages);
                      } else if ($scope.bookMode !== newMode && newMode === DBL_PAGE_EVEN_MODE && newPage % 2 !== 0) {
                          newPage = Math.max(newPage - 1, 1);
                      }

                      $scope.bookMode = newMode;
                      currentPage = newPage;
                      $timeout(() => {
                      });
                      render(newPage, newScale, newRotation);
                  }

                  deferPage.resolve();
              }, (err) => handleActivityError(err));

              LessonModel.getSessionRef().child(fbScrollXPercentPath()).on('value', (snap) => {
                  updatingScrollFromFb = true;

                  const xPercent = getScrollXFromSnap(snap);

                  positionPageXFromPercentage(xPercent);
                  $scope.loading = false;
                  deferScrollY.resolve();
              }, (err) => handleActivityError(err));

              LessonModel.getSessionRef().child(fbScrollYPercentPath()).on('value', (snap) => {
                  updatingScrollFromFb = true;

                  let yPercent = getScrollYFromSnap(snap);

                  positionPageYFromPercentage(yPercent);
                  $scope.loading = false;
                  deferScrollX.resolve();
              }, (err) => handleActivityError(err));

              return $q.all([deferScrollX.promise, deferScrollY.promise, deferPage.promise]);
          }

          let resizeHandler = _.debounce(() => {
              $timeout(() => {
                  setHolderHeight();
                  render(currentPage);
              });
          }, 100, {trailing: true});

          function initialize() {
              loadPDF(LessonModel.activity.file_url);

              holder.on('scroll', scrollPage);
              $scope.$watch(() => body.width(), resizeHandler);
              $scope.$watch(() => sessionStorage.getItem('activeDrawer'), resizeHandler);
              setHolderHeight();
              LessonModel.channel.bind('prev', () => $scope.prev());
              LessonModel.channel.bind('next', () => $scope.next());
              LessonModel.channel.bind('rotate90', (trans, degree) => $scope.rotate90(degree));
              LessonModel.channel.bind('zoom', (trans, scale) => $scope.setScale(scale));
              LessonModel.channel.bind('gotoPage', (trans, page) => $scope.gotoPage(page));
              LessonModel.channel.bind('switchMode', (trans, mode) => $scope.setMode(mode));
          }

          LessonModel.foundationLoaded.then(function () {
              initialize();
          }, (error) => {
              $log.warn(error);
          });
          $scope.$on('$destroy', function () {
              pdf && pdf.destroy();
              pdf = null;
          });
      }
  };
}

PdfLessonDirective.$inject = ['$log', '$timeout', '$q', 'LessonModel'];
module.exports = PdfLessonDirective;
