"use strict";
CardItemDirective.$inject = ['$timeout'];
function CardItemDirective($timeout) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: "/toychest/modules/store/activityCreation/panels/selectFlashcards/cardItemDirective/cardItemDirective.tpl.html",
        scope:  {
            cardModel: "=",
            clickHandle: "=",
            activeAssets: "=",
            startDrag: "=",
            cancelDrag: "=",
            dragOver: "="
        },
        link: ($scope) => {
            let dragEvent = false;

            function isActiveItem() {
                if (!$scope.activeAssets.length) {
                    return false;
                }
                return _.find($scope.activeAssets, (item) => item.id === $scope.cardModel.id);
            }

            $scope.setClassToItem = () => {
                return {
                    'active': isActiveItem(),
                    'text-only': !$scope.cardModel.cropped &&!$scope.cardModel.thumbnail_url && $scope.cardModel.title,
                    'new-only': !$scope.cardModel.thumbnail_url && !$scope.cardModel.title,
                    'image-only': ($scope.cardModel.cropped || $scope.cardModel.thumbnail_url) && !$scope.cardModel.title
                }
            };

            $scope.click = ($event) => {
                if (dragEvent) {
                    dragEvent = false;
                    return;
                }
                if (!$scope.clickHandle) {
                    return;
                }
                $scope.clickHandle($scope.cardModel, $event.shiftKey)
            };
        }
    }
}

module.exports = CardItemDirective;