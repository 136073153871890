var UploaderModel = function($log) {

    var defaults = {
        filesInProgress : [],
        activitiesInProgress : []
    };
    this.uploader = defaults;

    var init = _.bind(function () {
        $log.debug("[UploaderModel] Creating UploaderModel");
    }, this);
    init();

};

UploaderModel.$inject = ['$log'];
module.exports = UploaderModel;