function RequestContentButtonDirective(applications) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: '/toychest/common/directives/requestContentButton/requestContentButton.tpl.html',
        link: function(scope) {
            
            scope.onRequestContent = () => {
                window.open(applications.contentRequest.url, '_blank');
            };

        }
    }
}

RequestContentButtonDirective.$inject = ['applications'];
module.exports = RequestContentButtonDirective;