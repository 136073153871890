function plApiOrganizationFactory(plHttp, $q, plLodash) {
    const plApiOrganization = {};
    let _organizations = [];
    let _organizationsLoaded = false;
    let _lastGetParams = null;

    plApiOrganization.get = (data1 = {}) => {
        const deferred = $q.defer();

        const defaultParams = {
            limit: 1000,
            is_active: true,
        };
        if (!_lastGetParams) {
            _lastGetParams = defaultParams;
        }
        const data = Object.assign({}, defaultParams, data1);
        const sameParams = plLodash.equals(data, _lastGetParams, ['provider']);
        _lastGetParams = data;

        if (_organizationsLoaded && sameParams) {
            deferred.resolve(_organizations);
        } else {
            plHttp.get('organizations', data)
                .then((res) => {
                    if (sameParams) {
                        _organizations = res.data.results;
                        _organizationsLoaded = true;
                    }
                    deferred.resolve(res.data.results);
                }, (err) => {
                    deferred.reject(err);
                });
        }
        return deferred.promise;
    };

    plApiOrganization.formSelectOpts = (organizations = []) => {
        return organizations.map((organization) => {
            return {
                value: organization.uuid,
                label: organization.name,
            };
        });
    };

    return plApiOrganization;
}
plApiOrganizationFactory.$inject = ['plHttp', '$q', 'plLodash'];
module.exports = plApiOrganizationFactory;
