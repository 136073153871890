var LessonResolver = function(drfLessonModel) {
    this.resolve = function(params) {
        var lesson = new drfLessonModel.Model();
        var promise = lesson.$getViaSlug(params.slug);
        return promise;
    };
    return this;
};
LessonResolver.$inject = ['drfLessonModel'];
module.exports = LessonResolver ;
