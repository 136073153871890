var AssetStateModel = function($log) {
    var state = {
        'title' : '',
        'tags' : [],
        'private' : false,
        'description' : ''
    };
    return state;
};

AssetStateModel.$inject = ['$log'];
module.exports = AssetStateModel;