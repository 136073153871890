function plAuthContext(Permissions) {
  // authContext is an authorization rules map
  const AuthContext = (user) => {
    return {
      "EDIT-SERVICE-PLAN": params => {
        const serviceType = params.serviceType;
        const assignedTo = params.assignedTo;
        const serviceCategory = params.serviceCategory;
        if (user.isSuperuser()) return true;
        if (user.isProvider()) {
          // See if have matching provider type
          if (user.hasProviderType('slp') && serviceType === 'slt'
            || user.hasProviderType('ot') && serviceType === 'ot'
              || user.hasProviderType('mhp') && serviceType === 'bmh'
              || user.hasProviderType('sped') && serviceType === 'sped') {
                // If eval, see if it's a self referral.

                if (serviceCategory !== 'evaluation') {
                  return true;
                } else {
                  if (assignedTo === user.uuid) {
                    return true;
                  }
                  return false;
                }
                return true;
              }
        }
        return false;
      },
      "ALLOW-ADMIN-ROUTES": () => {
        return user.hasPermission(Permissions.VIEW_ADMIN);
      },
      "ALLOW-LEADS-ROUTES": () => {
        return user.hasPermission(Permissions.VIEW_ADMIN) && user.isProvider();
      },
      "ADMIN-ADD-CLIENT": () => {
        return user.hasPermissions([Permissions.VIEW_ADMIN, Permissions.ADD_CLIENT]);
      },
      "ADMIN-EXPORT-REPORTS": () => {
        return user.hasPermissions([Permissions.VIEW_ADMIN, Permissions.EXPORT_NOTES]);
      },
      "ADMIN-TRANSFER-LOCATION": () => {
        return user.hasPermissions([
          Permissions.VIEW_ADMIN,
          Permissions.TRANSFER_LOCATION,
          Permissions.CLIENT_CHANGE_PII,
          Permissions.ORGANIZATION_VIEW_ORGANIZATION,
        ]);
      },
      "ALLOW_REFERRALS_ROUTES": () => {
        return user.hasPermissions([
          Permissions.MANAGE_REFERRALS,
          Permissions.VIEW_OPEN_REFERRALS,
        ]);
      },
      "PROVIDE_SERVICES": () => {
        return user.hasPermission(Permissions.PROVIDE_SERVICES);
      },
      "VIEW_PROVIDERS": () => {
        return user.hasPermission(Permissions.VIEW_PROVIDERS);
      },
    }
  }
  return AuthContext;
}

plAuthContext.$inject = ['plPermissions'];
module.exports = plAuthContext;

