var PlBugReport = function ($http, appModel, applications) {
    'use strict';
    return {
        restrict: 'E',
        templateUrl: '/core/pl-core/src/directives/plBugReport/plBugReport.tpl.html',
        scope: true,
        link: function (scope) {

            const reportBugEndpoint = `${applications.platform.url}/api/v3/jira/bug/`

            scope.$watch(
                function () {
                    return appModel.app.bugReportVisible;
                }, function (val) {
                    scope.bugReportVisible = val;
                }
            );

            scope.$on('takeoverClosed', function (event) {
                appModel.app.bugReportVisible = false;
                event.stopPropagation();
            });

            //for unclear reasons, title and message are bound to _this_ rather than scope
            scope.reportBug = function () {
                let bugInfo = this.message + " \\ " + navigator.userAgent;

                let title = JSON.stringify(this.title);
                let message = JSON.stringify(bugInfo);

                let payload = {};
                payload['summary'] = title;
                payload['description'] = message;

                $http.post(reportBugEndpoint, payload);

                this.title = '';
                this.message = '';
                appModel.app.bugReportVisible = false;
            };
        },
    };
};

PlBugReport.$inject = ['$http', 'appModel', 'applications'];
module.exports = PlBugReport;

