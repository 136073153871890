function plRecordParticipantsFactory(guidService, $q, plClient,
 plBillingCodes, plLocation, plLodash) {
    const plRecordParticipants = {};

    plRecordParticipants.allSigned = (event, userUuid = null) => {
        const deferred = $q.defer();
        plRecordParticipants.formRecords(event, {}, userUuid)
            .then((records) => {
                let allSigned = records.length ? true : false;
                for (let ii = 0; ii < records.length; ii++) {
                    if (!records[ii].signed_on) {
                        allSigned = false;
                        break;
                    }
                }
                deferred.resolve({ allSigned });
            }, (err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    };

    // `event` is either an event or an appointment, which (may) have records,
    // and which has an event object with the participants (clients, locations).
    // We take the participants and records, match them, and use the record,
    // if it exists, or create a new record placeholder from the participant.
    plRecordParticipants.formRecords = (event, expand = {}, userUuid = null) => {
        const deferred = $q.defer();

        if (!event || Object.keys(event).length === 0) {
            deferred.resolve([]);
        } else {
            // We need billing code information to see if client / locations participate.
            plRecordParticipants.loadExpandData({ billing: true }, userUuid)
                .then(() => {
                    const recordsMerged = [];
                    let records = event.records ? event.records : [];
                    const participants = plRecordParticipants.formParticipantsFromEvent(event);

                    // We will stuff in some event details needed for records too.
                    const eventDetails = {
                        start: event.start,
                        end: event.end,
                    };

                    // Go through each participant and use the corresponding record, if it exists.
                    // There should never be a record WITHOUT a participant. There may be
                    // a participant without a record.
                    participants.forEach((participant) => {
                        recordsMerged.push(Object.assign({},
                            plRecordParticipants.findOrFormMatchingRecord(participant, records), eventDetails, {
                                // Record may not exist yet, so assign a unique id.
                                _uuid: guidService.generateUUID(),
                            }));
                    });

                    plRecordParticipants.expandData(recordsMerged, expand, userUuid)
                        .then((resRecords) => {
                            deferred.resolve(resRecords);
                        });
                }, (err) => {
                    deferred.reject(err);
                });
        }

        return deferred.promise;
    };

    plRecordParticipants.formParticipantsFromEvent = (event) => {
        let participants = [];
        const billingCode = event.billing_code ? event.billing_code :
         (event.event && event.event.billing_code) ? event.event.billing_code : null;
        const billingInfo = billingCode ? plBillingCodes.codeInfo(billingCode) : null;
        const hasLocations = (billingInfo && billingInfo.location_participates === 'NONE')
         ? false : true;
        const hasClients = (billingInfo && billingInfo.client_participates === 'NONE')
         ? false : true;
        if (hasLocations) {
            // If has an event key, it's an appointment.
            if (event.locations) {
                event.locations.forEach((location) => {
                    participants.push({
                        location_expanded: location,
                        uuid: location.uuid,
                        billing_code: billingCode,
                    });
                });
            } else if (event.event && event.event.locations) {
                event.event.locations.forEach((location) => {
                    participants.push({
                        location_expanded: location,
                        uuid: location.uuid,
                        billing_code: billingCode,
                    });
                });
            }
        }
        if (hasClients) {
            if (event.clients) {
                event.clients.forEach((client) => {
                    participants.push({
                        client_expanded: client,
                        uuid: client.uuid,
                        billing_code: billingCode,
                    });
                });
            } else if (event.event && event.event.clients) {
                event.event.clients.forEach((client) => {
                    participants.push({
                        client_expanded: client,
                        uuid: client.uuid,
                        billing_code: billingCode,
                    });
                });
            }
        }
        // If neither, create a single record stub that has no clients or locations.
        if (!hasClients && !hasLocations) {
            participants.push({
                uuid: billingCode,
                billing_code: billingCode,
                noClientNorLocation: true,
            });
        }
        return participants;
    };

    // Look up a record by client or location. If not found, just return the participant.
    plRecordParticipants.findOrFormMatchingRecord = (participant, records) => {
        let record = Object.assign({}, participant, {
            uuid: null,
            _participantUuid: participant.uuid,
        });
        // If no clients and no locations, just use the (single) record.
        if (participant.noClientNorLocation) {
            if (records.length === 1) {
                record = records[0];
            }
            return record;
        }
        // Client takes priority as there could be multiple records with the
        // same location but clients should always be unique.
        let recordFound;
        if (participant.client_expanded) {
            recordFound = records.find(item => {
              return item.client_expanded
                && item.client_expanded.uuid === participant.client_expanded.uuid;
            });
        } else if (participant.location_expanded) {
            recordFound = records.find(item => {
              return item.location === participant.location_expanded.uuid;
            });
        }
        return recordFound || record;
    };

    plRecordParticipants.expandData = (records, expand = {}, userUuid = null) => {
        const deferred = $q.defer();

        plRecordParticipants.loadExpandData(expand, userUuid)
            .then(() => {
                records.forEach((record) => {
                    let clientInfo;
                    let billingInfo;
                    let locationInfo;
                    if (expand.client && !record.client_expanded) {
                        clientInfo = plClient.getInfo(record.client);
                        record.client_expanded = clientInfo ? clientInfo : null;
                    }
                    if (expand.billing) {
                        billingInfo = plBillingCodes.codeInfo(record.billing_code);
                        record.billing_expanded = billingInfo ? billingInfo : null;
                    }
                    if (expand.location && !record.location_expanded) {
                        locationInfo = plLocation.getInfo(record.location);
                        record.location_expanded = locationInfo ? locationInfo : null;
                    }
                });
                deferred.resolve(records);
            }, (err) => {
                deferred.reject(err);
            });

        return deferred.promise;
    };

    plRecordParticipants.loadExpandData = (expand = {}, userUuid = null) => {
        const deferred = $q.defer();

        const promises = [];
        const deferreds = {};
        if (expand.client && userUuid) {
            deferreds.client = $q.defer();
            promises.push(deferreds.client.promise);
            plClient.get({ provider: userUuid })
                .then(() => {
                    deferreds.client.resolve();
                });
        }
        if (expand.billing) {
            deferreds.billing = $q.defer();
            promises.push(deferreds.billing.promise);
            plBillingCodes.get()
                .then(() => {
                    deferreds.billing.resolve();
                });
        }
        if (expand.location) {
            deferreds.location = $q.defer();
            promises.push(deferreds.location.promise);
            plLocation.get({ provider: userUuid })
                .then(() => {
                    deferreds.location.resolve();
                });
        }

        if (!promises.length) {
            deferred.resolve();
        } else {
            $q.all(promises)
                .then(() => {
                    deferred.resolve();
                }, (err) => {
                    deferred.reject(err);
                });
        }

        return deferred.promise;
    };

    return plRecordParticipants;
}
plRecordParticipantsFactory.$inject = ['guidService', '$q', 'plClient',
 'plBillingCodes', 'plLocation', 'plLodash'];
module.exports = plRecordParticipantsFactory;
