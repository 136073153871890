var AppModel = function($log) {

    this.app = {
        store: true,
        documentationActive: false,
        activeDrawer: null,
        bugReportVisible: false,
        ideaReportVisible: false
    };

    this.openDrawer = function(drawerName) {
        if (drawerName == this.app.activeDrawer) {
            this.app.activeDrawer = null;
        } else {
            this.app.activeDrawer = drawerName;
        }
    };

    var setDocumentationActive = function(isActive) {
      this.app.documentationActive = isActive;
    };
    this.setDocumentationActive = setDocumentationActive;
    /**
     * Invoked on startup, like a constructor.
     */
    var init = function () {
        $log.debug("[AppModel] Creating AppModel");
    };
    init();

};

AppModel.$inject = ['$log'];
module.exports = AppModel;