module.exports = angular.module('toys', [
    require('./app/src/toys/modules/lesson').name,
    require('./app/src/toys/modules/activity').name,
    require('./app/src/toys/modules/assessment').name,
    require('./app/src/toys/modules/game').name,

    // require('./app/src/toys/common/resolvers').name,

    // directives
    require('./app/src/toys/common/directives/sharedcursor').name,
    require('./app/src/toys/common/directives/pl-dragndrop').name,
    require('./app/src/toys/common/directives/smallsharedcursor').name,
    require('./app/src/toys/common/directives/workspace').name,
    require('./app/src/toys/common/directives/activitydrawer').name,
    require('./app/src/toys/common/directives/imageOnLoad').name,


    // can optimize these
    require('./app/src/toys/common/service').name,

    // factory
    require('./app/src/toys/common/factory').name
]);
