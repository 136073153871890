import {
    AuthenticatedUser,
}
from './UserModels';

/**
 * Responsible for authentication with the Auth backend service.
 */
class AuthenticationService {
    constructor(applications, $window, $http, $q) {
        this.onLogoutCallbacks = [];
        this.afterLogoutCallbacks = [];
        this._applications = applications;
        this._$window = $window;
        this._$http = $http;
        this._$q = $q;
    }

    /**
     * [authenticate description]
     * @return {Promise(authUser)} promise for the authentication user object from the Auth backend service.
     */
    authenticate(lastActive = 0) {
        return this._$http.get(this._applications.auth.statusUrl, {
            withCredentials: true,
            params  : {
                last_active: lastActive
            }
        }).then(
            (response) => {
                const data = response.data;
                const user = data.user;
                // unpack jwt token for hijack information
                const jwtToken = data.token;
                if (jwtToken && window && window.atob) {
                  const userStatusFromJwtToken = JSON.parse(window.atob(jwtToken.split('.')[1]));
                  user.userStatus = userStatusFromJwtToken;

                }
                return new AuthenticatedUser(user)
            },
            (data, status, headers, config) => {
                console.log('ERROR: ' + data);
                console.log('ERROR: ' + status);
                console.log('ERROR: ' + headers);
                console.log('ERROR: ' + config);

            }
        );
    }

    /**
     * Redirects the user to the login page.
     *
     * @return {301} redirects immediatly.
     */
    login() {
        window.location = `${this._applications.auth.loginUrl}?next=${window.location.href}`;
    }

    /**
     * Subscribe to logout events.
     *
     * Called with one parameter, the logout context (See logout)
     *
     * @param  {Function} callback
     */
    onLogout(callback) {
        this.onLogoutCallbacks.push(callback);
    }

    /**
     * Subscribe to after logout events.
     *
     * Fired after logout callbacks have been fired.
     *
     * @param  {Function} callback [description]
     * @return {[type]}            [description]
     */
    afterLogout(callback) {
        this.afterLogoutCallbacks.push(callback);
    }

    /**
     * Redirects the browser to the login screen.
     *
     * @return {[type]} [description]
     */
    redirectToAuth() {
        this._$window.location = this._applications.auth.logoutUrl;
    }

    /**
     * Logs the user out of the application.
     *
     * Goes through all registered onLogout callbacks and fires them (and waits for them to complete)
     * Then redirects the user to the auth.logoutUrl
     *
     * @return {promise} which is pointless because of the redirect.
     */
    logout() {
        const promises = [];

        // first take care of notifying everyone who has subscribed.
        // NOTE: These fire immediatly, there is no waiting until each callback finishes. Your
        // callback should assume the current user is already logged out from the backend.
        this.onLogoutCallbacks.forEach((callback) => {
            const promise = callback();
            promises.push(this._$q.when(promise).then((a) => {
                return a;
            }));
        });

        const promise = this._$q.all(promises).then((a) => {
            return a;
        });
        promise.finally(() => {
            const afterPromises = [];
            this.afterLogoutCallbacks.forEach((callback) => {
                const promise = callback();
                afterPromises.push(this._$q.when(promise).then((a) => {
                    return a;
                }));
            });
            return this._$q.all(afterPromises).then((a) => {
                return a;
            });
        });
        return promise;
    }
}
AuthenticationService.$inject = ['applications', '$window', '$http', '$q'];

module.exports = AuthenticationService;
export default AuthenticationService;
