/**
 * Service for generate a guid
 *
 */
var ClosureService = function() {
    this.closureify = function (...args) {
        let fn = args.pop();

        return (...params) => fn(...[...args, ...params]);
    };
};

ClosureService.$inject = [];
module.exports = ClosureService;
