var SliderDirective = function($log, $document, $timeout) {
    return {
        replace: true,
        restrict: 'E',
        scope: {
            value : "=",
            change: "&"
        },
        templateUrl: '/core/pl-core/src/directives/slider/slider.tpl.html',
        link: function ($scope, $element, $attr) {
            // directive vars
            var min = $attr.min ? parseFloat($attr.min) : 0,
                max = $attr.max ? parseFloat($attr.max) : 100,
                steps = $attr.steps ? parseInt($attr.steps)  : min-max * 10,
                $control = $element.find('.slider-control'),
                $track = $element.find('.guideline'),
                $container = $element.find('.pl-dropdown-container'),
                //Control maintains it's height even when hidden, track will not.
                length = parseInt($control.height()),
                offset;

            // assign events
            $element.on('touchend mouseup', 'button', toggleSlider);
            $element.on('touchstart mousedown', '.slider-control', starthandle);
            $(document).on('touchend mouseup', onDocumentClick);

            function onDocumentClick(e) {
                var clickInElement =  $element.is(e.target) || $.contains($element[0], e.target);
                if (!clickInElement) {
                    $container.removeClass('active');
                }
            }

            // event handlers
            function toggleSlider(e) {
                if ($.contains($control, e.target) === false) {
                    $container.toggleClass('active');
                }
            }
            function starthandle(e) {
                offset = $track.offset();
                $element.removeClass('animated');
                $(document).on('touchmove', movehandler);
                $(document).on('mousemove', movehandler);
                $(document).on('mouseup', uphandler);

                movehandler(e);

                e.stopPropagation();
            }
            function movehandler(e) {
                updateValue(e);

                $timeout(function() {
                    $scope.change();
                });

                e.stopPropagation();
            }
            function uphandler(e) {
                $element.addClass('animated');
                updateValue(e);

                $timeout(function() {
                    $scope.change();
                });

                $(document).off('mousemove', movehandler);
                $(document).off('touchmove', movehandler);
                $(document).off('mouseup', uphandler);

                e.stopPropagation();
            }

            function updateValue(e){

                let pageY;

                if(e.type.includes('touch')){
                    pageY = e.originalEvent.touches[0].pageY;
                } else {
                    pageY = e.pageY;
                }

                var o = Math.max(0, Math.min(length, (length - (pageY - offset.top))));
                var ratio = o / length;
                var quantizedRatio = Math.round(ratio * steps) / steps;
                var range = max - min;
                var val = quantizedRatio * range + min;
                $scope.value = val;
            }

            $scope.getPos = function() {
                var pos = ((max-min) - ($scope.value-min)) / (max-min) * length;
                return {
                    top : pos + 'px'
                };
            };
        }
    };
};

SliderDirective.$inject = ['$log', '$document', '$timeout'];
module.exports = SliderDirective;
