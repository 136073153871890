import ActivitiesController from '../activities/ActivitiesController';

class LessonsController extends ActivitiesController {
    constructor($rootScope, $state, $injector, CurrentUser, ActivityTagModel, Room) {
        super($rootScope, $state, $injector, CurrentUser, ActivityTagModel, Room);
    }
}

LessonsController.$inject = ['$rootScope', '$state', '$injector', 'CurrentUser', 'ActivityTagModel', 'Room'];
module.exports = LessonsController;
