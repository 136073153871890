function plAppointmentFactory(plHttp, $q, plTimezone) {
    const appt = {};

    appt.fillEventFields = (event, fields = {}) => {
        return Object.assign({}, {
            event: event.uuid,
            start: event.start,
            end: event.end,
            original_start: event.original_start || event.start,
            original_end: event.original_end || event.end,
        }, fields);
    };

    appt.appointmentFromEvent = (start, end, eventUuid, original_start, original_end) => {
        const eventFill = {
            start,
            end,
            original_start,
            original_end,
            uuid: eventUuid,
        };
        return appt.fillEventFields(eventFill);
    };

    appt.saveFromEvent = (event, appointmentFields = {}, userUuid) => {
        const appointment = appt.fillEventFields(event, appointmentFields);
        return appt.save(appointment, userUuid);
    };

    appt.checkForExisting = (appointment, userUuid) => {
        const deferred = $q.defer();

        let existingAppointment = null;
        const queryParams = {
            event: appointment.event,
            original_start: plTimezone.toUTCNoSeconds(appointment.original_start),
            original_end: plTimezone.toUTCNoSeconds(appointment.original_end),
            persisted_only: true,
            provider: userUuid,
        };
        plHttp.get('appointments', queryParams)
            .then((res) => {
                if (res && res.data && res.data.results && res.data.results.length) {
                    // Should only be one, but in case of duplicates take the first one.
                    existingAppointment = res.data.results[0];
                }
                deferred.resolve(existingAppointment);
            }, (err) => {
                deferred.reject(err);
            });

        return deferred.promise;
    };

    appt.formatForBackend = (appointment1) => {
        const appointment = Object.assign({}, appointment1);
        const timeFields = ['start', 'end', 'original_start', 'original_end'];
        timeFields.forEach((field) => {
            if (appointment[field]) {
                appointment[field] = plTimezone.toUTC(appointment[field]);
            }
        });
        return appointment;
    };

    appt.save = (appointment1, userUuid) => {
        const deferred = $q.defer();

        const appointment = appt.formatForBackend(appointment1);

        const saveLocal = function(appointmentLocal) {
            appt.saveActual(appointmentLocal)
                .then((resAppt) => {
                    deferred.resolve(resAppt);
                }, (err) => {
                    deferred.reject(err);
                });
        }

        // If new, need to check for existing appointment first (in case of stale data).
        if (!appointment.uuid && appointment.event && appointment.original_start && appointment.original_end) {
            appt.checkForExisting(appointment, userUuid)
                .then((resExistingAppt) => {
                    if (resExistingAppt && resExistingAppt.uuid) {
                        appointment.uuid = resExistingAppt.uuid;
                    }
                    saveLocal(appointment);
                }, (err) => {
                    deferred.reject(err);
                });
        } else {
            saveLocal(appointment);
        }

        return deferred.promise;
    };

    appt.saveActual = (appointment) => {
        const deferred = $q.defer();

        plHttp.save('appointments', appointment)
            .then((res) => {
                deferred.resolve(res.data);
            }, (err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    };

    appt.delete = (appointmentUuid) => {
        const deferred = $q.defer();
        plHttp.delete('appointments', { uuid: appointmentUuid })
            .then(() => {
                deferred.resolve();
            }, (err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    };

    return appt;
}
plAppointmentFactory.$inject = ['plHttp', '$q', 'plTimezone'];
module.exports = plAppointmentFactory;
