"use strict";

plDrag.$inject = ['$timeout'];

function plDrag($timeout) {
    return {
        restrict:'A',
        scope: {
            plDrag:'=',
            plDragData:'=',
            plDragNoDigest:'='
        },
        link:function(scope, element) {
            element[0].draggable = true;
            element[0].addEventListener('dragstart', function(event) {
                scope.plDrag(event, scope.plDragData);
            }, false);
        }
    }
}


module.exports = plDrag;