"use strict";

FlashcardsPreviewDirective.$inject = [];

function FlashcardsPreviewDirective() {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: '/toychest/modules/store/activities/flashcardsPreviewDirective/flashcardsPreviewDirective.tpl.html',
        scope: {
            cards: '='
        },
        link: function(scope) {
            scope.selectedCard = (scope.cards && scope.cards.length ? scope.cards : [null])[0];
            let selectedIndex = 0;
            scope.prevBtnIsActive = function () {
                return scope.cards && scope.cards.length && selectedIndex > 0;
            };
            scope.nextBtnIsActive = function () {
                return scope.cards && scope.cards.length && selectedIndex < scope.cards.length - 1;
            };

            scope.getClassForSelectedCard = function() {
                if (!scope.selectedCard) {
                    return {};
                }
                var classes = {
                    'card': true,
                    'cards-presentation-cursor': true
                };
                classes['card-' + scope.selectedCard.type] = true;
                return classes;
            };

            scope.nextCard = function() {
                if (!scope.nextBtnIsActive) {
                    return;
                }
                selectedIndex++;
                scope.selectedCard = scope.cards[selectedIndex];
            };

            scope.prevCard = function() {
                if (!scope.prevBtnIsActive) {
                    return;
                }
                selectedIndex --;
                scope.selectedCard = scope.cards[selectedIndex];
            };
        }
    };
}

module.exports = FlashcardsPreviewDirective;