/**
 * Created by bohdanivanov on 5/25/16.
 */

function AssessmentPDFDrawerDirective ($timeout, AssessmentModel, currentUserModel, hotkeys) {
    return {
        restrict: 'E',
        controller:'assessmenpdfdrawercontroller',
        scope: {},
        templateUrl:'/core/toys/app/src/toys/modules/assessment/assessmentpdfcontent/assessmentpdfdrawer.tpl.html',
        link: function($scope) {
            if(!currentUserModel.user.isClinician()) {
                return;
            }
            const hotkeysConfig = [{
                combo: 'space',
                action: 'keydown',
                description: 'Show/hide instructions',
                callback: (event) => {
                    if (!checkElement(event.target)) {
                        return;
                    }
                    showHideInstruction(true);
                    event.preventDefault();
                }
            }, {
                combo: 'space',
                action: 'keyup',
                callback: () => showHideInstruction(false)
            }, {
                combo: 'up',
                description: 'Jump to previous page',
                callback: () => {
                    AssessmentModel.channel.call({
                        method: 'prev'
                    });
                }
            }, {
                combo: 'down',
                description: 'Jump to next page',
                callback: () => {
                    AssessmentModel.channel.call({
                        method: 'next'
                    });
                }
            }];
            $scope.assessmentModel = AssessmentModel;
            const editableElements = ['textarea', 'input'];

            let checkElement = (element) => {
                let isEditableElement = -1 !== editableElements.indexOf(element.tagName.toLowerCase());
                if (isEditableElement || 'true' === element.contentEditable) {
                    return false;
                }
                return true;
            };

            let showHideInstruction = (status) => {
                if ($scope.isDoubleSided && status != AssessmentModel.getShowInstructions()) {
                    //timeout is needed for pdfjs to measure its viewport properly
                    $timeout(function ( ) {
                        AssessmentModel.saveInstructionsVisible(status);
                    });
                }
            };

            $scope.$on('$destroy', () => {
                hotkeysConfig.forEach(item => hotkeys.del(item.combo));
            });

            hotkeysConfig.forEach(item => hotkeys.add(item));
        }
    }
}
AssessmentPDFDrawerDirective.$inject = ['$timeout', 'AssessmentModel', 'currentUserModel', 'hotkeys'];

module.exports = AssessmentPDFDrawerDirective;