var WjAudioAssessmentThumbnailDirective = function ($log, $q, $window, $timeout, $compile, $stateParams) {
    return {
        restrict: "E",
        scope : {
            pagenum : '=',
            isdouble : '='
        },
        templateUrl: '/core/toys/app/src/toys/modules/assessment/wjaudioassessment/wjAudioAssessmentThumbnailDirective.tpl.html',

        link: function (scope, element, attrs) {

            scope.jump = function (pageNum, evt) {
                console.log(pageNum);
            }
        }
    }
};

WjAudioAssessmentThumbnailDirective.$inject = ['$log', '$q', '$window', '$timeout', '$compile', '$stateParams'];
module.exports = WjAudioAssessmentThumbnailDirective;

