var ActivityTypeIcon = function() {
    return {
        restrict: 'E',
        templateUrl: '/core/pl-core/src/directives/activityTypeIcon/activity-type-icon.tpl.html',
        scope: {
            activity: '='
        },
        link: function() {

        }
    };
};
ActivityTypeIcon.$inject = [];
module.exports = ActivityTypeIcon;