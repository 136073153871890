const defaultFilter = {
    q: '',
    tag: [],
    activity_type: '',
    disabled: false,
    private: false,
    facet: 'all',
    section: 'pldrl'
};

/**
 * A helper class representing the filter to drfActivities.
 * TODO: should these methods be included in drfActivities directly?
 */
class ActivitySearchModel {
    constructor() {
        this.reset();
    }

    /**
     * We reset the properties on "this" to be the same as the default filter
     * removing any property not contained in the defaultFitler.
     * @return {[type]} [description]
     */
    reset() {
        let defaults = JSON.parse(JSON.stringify(defaultFilter));
        Object.keys(this).forEach((prop) => {
            delete this[prop];
        });
        Object.keys(defaults).forEach((prop) => {
            this[prop] = defaults[prop];
        });
        return this;
    }

    addTag(tags) {
        if (!(tags instanceof Array)) {
            tags = [tags];
        }
        tags.forEach((tag) => {
            if (!_.contains(this.tag, tag)) {
                this.tag.push(tag);
            }
        });
        return this;
    }

    removeTag(tag) {
        this.tag = _.filter(this.tag, function(t) {
            if (t !== tag) {
                return true;
            } else {
                return false;
            }
        });
        return this;
    }

    setType(type) {
        if (type) {
            this.activity_type = type;
        }
        return this;
    }

    removeType() {
        this.activity_type = defaultFilter.activity_type;
        return this;
    }

    isDefaultFilter() {
        return JSON.stringify(defaultFilter) === JSON.stringify(this);
    }

    setSection(section) {
        this.section = section;
        return this;
    }

    facetName() {
        let name;
        if (this.facet === 'all') {
            name = undefined;
        } else if (this.facet === 'favorites') {
            name = 'Favorite Activities';
        } else if (this.facet === 'trash') {
            name = 'Trash';
        } else if (this.facet === 'my_activities') {
            name = 'My Activities';
        } else if (this.facet === 'recent') {
            name = 'Recently used';
        }
        return name;
    }

    /**
     * Search for favorite activities
     * @param  {[type]} uuid [description]
     * @return {[type]}        [description]
     */
    favorites(uuid) {
        this.facet = 'favorites';
        this.favorite__user__uuid = uuid;
        this.section = undefined;
        return this;
    }

    /**
     * Search for deleted activities.
     * @param  {[type]} uuid [description]
     * @return {[type]}        [description]
     */
    deleted(uuid) {
        this.facet = 'trash';
        this.owner__uuid = uuid;
        this.disabled = true;
        return this;
    }

    /**
     * Search for only activities that belong to the
     * supplied user. The supplied user must be logged in.
     * @param  {[type]} uuid [description]
     * @return {[type]}        [description]
     */
    mine(uuid) {
        this.facet = 'my_activities';
        this.owner__uuid = uuid;
        this.private = true;
        return this;
    }

    recent(uuid) {
        this.facet = 'recent';
        this.owner__uuid = uuid;
        this.private = true;
        return this;
    }

    getRouteParams() {
        let obj = {
            q: this.q,
            tag: this.tag,
            type: this.activity_type,
            facet: this.facet,
        };

        return obj;
    }

}

module.exports = ActivitySearchModel;