var defaultFilter = {
    q: '',
    tag: [],
    disabled: false
};

var AssetSearchModel = function() {
    var model = function() {
        this.clear();
    };

    model.prototype.clear = function() {
        this.filter = $.extend(true, {}, defaultFilter);
        this.facet = undefined;
    };

    model.prototype.addTag = function(tag) {
        if (!_.contains(this.filter.tag, tag)) {
            this.filter.tag.push(tag);
        }
    };

    model.prototype.removeFacet = function(){
        this.facet = undefined;
    };

    model.prototype.removeTag = function(tag) {
        this.filter.tag = _.filter(this.filter.tag, function(t) {
            if (t !== tag) {
                return true;
            } else {
                return false;
            }
        });
    };

    model.prototype.hasFilter = function() {
        return JSON.stringify(defaultFilter) !== JSON.stringify(this.filter);
    };

    return new model();
};

AssetSearchModel.$inject = [];
module.exports = AssetSearchModel;