class StoreController {
    constructor($state, $window, CurrentUser, Room, $rootScope, pageTitleModel) {
        this._$state = $state;
        this._$window = $window;
        this.currentUser = CurrentUser;
        this.room = Room;
        $rootScope.navigateBack = _.bind(this.navigateBack, this);

        pageTitleModel.set('Library');

        if ($state.current.url === '') {
            this.redirectRootForUser(CurrentUser, $state);
        }
        $rootScope.$on('$stateChangeStart', (evt, to, params) => {
            if (to.url === '') {
                this.redirectRootForUser(CurrentUser, $state);
            }
          });
    }

    redirectRootForUser() {
        if (!this.currentUser.isProvider() && this.currentUser.isInGroup('Assessment Users') && !this.currentUser.isInGroup('Activity Users')) {
            setTimeout(() => {
                this._$state.go('app.store.assessments.all');
            });
        }
        else {
            setTimeout(() => {
                this._$state.go('app.store.activities.featured');
            });
        }
    }

    navigateBack() {
        this._$window.history.back();
    }

    isCurrentState(state) {
        return this._$state.includes(state);
    }

    getQueueClasses() {
      return {
        'no-banner-queue': !this.currentUser.isAssessmentUser(),
      };
    }

    getSideNavClasses() {
      return {
        'no-banner-sidenav': !this.currentUser.isAssessmentUser(),
      };
    }

    getMainViewClasses() {
        return {
            'central-content': true,
            'no-banner': !this.currentUser.isAssessmentUser(),
            'queue-open': (this._$state.includes('app.store.activities') || this._$state.includes('app.store.assessments') ||
                           this._$state.includes('app.store.lessons') || this._$state.includes('app.store.games')) && this.room != null,
            'detail-view': this._$state.is('app.store.activities.detail') || this._$state.is('app.store.assessments.detail') ||
                           this._$state.is('app.store.lessons.detail') || this._$state.is('app.store.games.detail')
        };
    }
}

StoreController.$inject = ['$state', '$window', 'CurrentUser', 'Room', '$rootScope', 'pageTitleModel'];
module.exports = StoreController;