function plRecordServiceUpdate(plServiceOpts, plAreasOfConcern, plAssessments,
 plFileAmazon, plClientServices, plServiceDocuments, $timeout, plLodash, plServices, plRecordSPI) {
    return {
        restrict: 'E',
        scope: {
            type: '=',
            model: '=',
            clientService: '=',
            clientServicesLoaded: '=',
            disabled: '=?',
            change: '&?',
        },
        replace: true,
        templateUrl: '/core/pl-records/src/plRecordServiceUpdate/plRecordServiceUpdate.tpl.html',
        link: ($scope) => {
            let initing = true;
            let services = [];
            $scope.plRecordSPI = plRecordSPI;
            $scope.clientServiceType;

            $scope.statusOpts = [
                { value: 'in_process', label: 'In Process' },
                { value: 'completed', label: 'Completed' },
                { value: 'cancelled', label: 'Cancelled' },
                { value: 'hold', label: 'Hold' },
                { value: 'end_of_year', label: 'End of Year' },
            ];

            let clientService = {};

            let awsInfo = {};
            plFileAmazon.getAmazonUrl({ namespace: 'evaluation_reports' })
                .then((resAmazon) => {
                    awsInfo = resAmazon;
                    $scope.model.awsInfo = resAmazon;
                });
            // Get services for areas of concern changing service updating for psychoeducational.
            plServices.get({ with_can_provide: true })
                .then((resServices) => {
                    services = resServices;
                });

            function getOpts() {
                clientService = plClientServices.getInfo($scope.clientService);
                if (clientService) {
                    $scope.clientServiceType = clientService.type;
                    if (clientService.type === $scope.type) {
                        plServiceOpts.getAll(clientService)
                            .then((resOpts) => {
                                $scope.areasOfConcernOpts = plAreasOfConcern.formSelectOpts(resOpts.areasOfConcern);
                                $scope.assessmentsUsedOpts = plAssessments.formSelectOpts(resOpts.assessmentsUsed);
                            });
                    }
                }
            }

            function init() {
                initing = true;
                $scope.areasOfConcernOpts = [];
                $scope.assessmentsUsedOpts = [];
                if (!$scope.model.files) {
                    $scope.model.files = [];
                }
                $scope.existingFiles = [];
                if ($scope.clientService) {
                    getOpts();
                }
                if ($scope.model) {
                    if (!$scope.model.files) {
                        $scope.model.files = [];
                    }
                    $scope.model.documentRequired = false;
                    $scope.model.hasFiles = false;
                    if ($scope.model.documents_expanded) {
                        $scope.existingFiles = $scope.model.documents_expanded.map((doc) => {
                            let index = doc.file_path.lastIndexOf('/');
                            return {
                                uuid: doc.uuid,
                                name: doc.file_path.slice((index + 1), doc.file_path.length),
                            };
                        });
                    }
                }
                setHasFiles();
                $timeout(() => {
                    initing = false;
                }, 500);
            }
            init();
            $scope.$watch('clientServicesLoaded', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal)) {
                    if ($scope.clientService) {
                        init();
                    }
                }
            });
            $scope.$watch('clientService', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal)) {
                    if ($scope.clientService) {
                        init();
                    }
                }
            });

            // If model is updated, we need to re-add info onto it.
            $scope.$watch('model', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal) && $scope.model) {
                    init();
                    if (awsInfo) {
                        $scope.model.awsInfo = awsInfo;
                    }
                }
            });

            $scope.removeFile = (file) => {
                plServiceDocuments.delete(file.uuid)
                    .then(() => {
                        const index = plLodash.findIndex($scope.existingFiles, 'uuid', file.uuid);
                        if (index > -1) {
                            $scope.existingFiles.splice(index, 1);
                        }
                        setHasFiles();
                        $scope.update();
                    });
            };

            $scope.uploadFiles = (filesObj) => {
                $scope.model.files = filesObj.files;
                setHasFiles();
                $scope.update();
            };

            function setHasFiles() {
                $scope.model.hasFiles = (($scope.model.files && $scope.model.files.length) ||
                 ($scope.existingFiles && $scope.existingFiles.length)) ? true : false;
            }

            $scope.update = () => {
                if (!initing) {
                    if ($scope.change) {
                        $scope.change();
                    }
                }
            };

            $scope.changeStatus = () => {
                // If status is complete, at least one document is required.
                $scope.model.documentRequired = ($scope.model.status === 'completed')
                 ? true : false;
                $scope.update();
            };

            $scope.changeAreasOfConcern = () => {
                // Update service in case areas of concern changes it (psychoeducational service).
                $scope.model.service = plServices.selectPsychoeducationalService(services,
                 $scope.model.service, $scope.model.areas_of_concern);
                $scope.update();
            };
        },
    };
}

plRecordServiceUpdate.$inject = ['plServiceOpts', 'plAreasOfConcern', 'plAssessments',
 'plFileAmazon', 'plClientServices', 'plServiceDocuments', '$timeout', 'plLodash', 'plServices', 'plRecordSPI'];
module.exports = plRecordServiceUpdate;
