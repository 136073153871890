/**
 * The base data model
 * @param $log
 */
var TastyModel = function($log, $http, $q) {
    var Model = function() {};

    function makeResourceRequest(method, url, props, context) {
        return $q((resolve, reject) => {
            $http({
                    method: method,
                    url: url,
                    data: props
                })
                // !!SL
                // please don't change the then handler unless you know what ramifications it will have
                // this class is a convenience for creating objects that hit apis backed by the tastypie rest framework
                // so, it's important to copy the data onto the object for this to work properly
                .then((data) => {
                    angular.forEach(data, function(value, key) {
                        context[key] = value;
                    });
                    resolve(context);
                }).catch(() => {
                    reject(context);
                });
        });
    }

    Model.prototype.$patch = function(fields) {
        let props = angular.extend({}, fields);
        var method = 'PATCH';
        var url = this._base_uri + this.resource_uri;

        return makeResourceRequest(method, url, props, this);
    };

    Model.prototype.$save = function(obj) {
        var props = {};
        angular.forEach(this, function(value, key) {
            if (key.charAt(0) !== '$' && key.charAt(0) !== '_') {
                props[key] = value;
            }
        }, this);

        props = angular.extend(props, obj);

        var method = 'POST';
        var url = '';
        if (props.hasOwnProperty('resource_uri')) {
            method = 'PUT';
            url = props.resource_uri;
            if (url.indexOf(this._base_uri) < 0) {
                url = this._base_uri + url;
            }
            delete props.resource_uri;
        } else {
            url = this._base_uri + this._resource_uri;
        }

        return makeResourceRequest(method, url, props, this);
    };

    Model.prototype.$get = function() {
        if (!this.hasOwnProperty('resource_uri')) {
            throw new Error('This model hasn\'t been saved yet.');
        }

        var uri = this._base_uri + this.resource_uri;
        return makeResourceRequest('GET', uri, {}, this);
    };

    Model.prototype.$delete = function() {
        if (!this.hasOwnProperty('resource_uri')) {
            throw new Error('This model hasn\'t been saved yet.');
        }

        var uri = this._base_uri + this.resource_uri;
        var deferred = $q.defer();
        $http.delete(uri)
            .success(angular.bind(this, function() {
                this.$resolved = true;
                deferred.resolve(this);
            }))
            .error(angular.bind(this, function() {
                this.$resolved = true;
                deferred.resolve(this);
            }));
        return deferred.promise;
    };

    // We use a custom array so that we can add some parameters to it.
    var Collection = function(modelClass) {
        this.Model = modelClass;
    };

    Collection.prototype = new Array;

    Collection.prototype.$next = function() {
        if (!_.isString(this._next)) {
            return;
        }

        var deferred = $q.defer();
        $http.get(this._next, this._options).then(
            angular.bind(this, function(u) {
                this.limit = u.data.meta.limit;
                this.totalCount = u.data.meta.total_count;
                this._next = u.data.meta.next;
                this._prev = u.data.meta.previous;
                this.splice(0, this.length);
                for (var i = 0; i < u.data.objects.length; i++) {
                    var model = new this.Model(u.data.objects[i]);
                    this.push(model);
                }
                this.currentPage = Math.floor(this.offset / this.limit);
                this.totalPages = Math.floor(u.data.meta.total_count / this.limit);
                deferred.resolve(this);
            })
        );
        return deferred.promise;
    };

    Collection.prototype.$prev = function() {
        if (!_.isString(this._prev)) {
            return;
        }

        var deferred = $q.defer();
        $http.get(this._prev, this._options).then(
            angular.bind(this, function(u) {
                this.limit = u.data.meta.limit;
                this.totalCount = u.data.meta.total_count;
                this._next = u.data.meta.next;
                this._prev = u.data.meta.previous;
                this.splice(0, this.length);
                for (var i = 0; i < u.data.objects.length; i++) {
                    var model = new this.Model(u.data.objects[i]);
                    this.push(model);
                }
                this.currentPage = Math.floor(this.offset / this.limit);
                this.totalPages = Math.floor(u.data.meta.total_count / this.limit);
                deferred.resolve(this);
            })
        );
        return deferred.promise;
    };

    Collection.prototype.$filter = function(obj) {
        angular.forEach(obj, function(value, key) {
            if (value !== null && value !== '') {
                this._filter[key] = value;
            } else {
                delete this._filter[key];
            }
        }, this);
        return this;
    };

    Collection.prototype.$limit = function(count) {
        this.limit = count;
        return this;
    };

    Collection.prototype.$orderBy = function(sortField) {
        this._orderBy = sortField;
        return this;
    };

    Collection.prototype.$clear = function() {
        this.limit = 20;
        this._orderBy = null;
        this.offset = 0;
        this._filter = {};
        return this;
    };

    Collection.prototype.$fetchPage = function(pageNumber) {
        return this.$fetch({
            offset: this.limit * (pageNumber - 1) // minus 1 to make the first page, page 1
        });
    };

    Collection.prototype.$fetch = function(obj) {
        var params = angular.copy(this._filter);
        params.limit = this.limit;
        params.offset = this.offset;
        if (this._orderBy !== null) {
            params.order_by = this._orderBy;
        }
        params = angular.extend(params, obj);

        // build the querystring
        var query_params = [];
        angular.forEach(params, function(value, key) {
            query_params.push(key + '=' + value);
        }, this);
        var queryString = query_params.length > 0 ? '?' + query_params.join('&') : '';

        var uri = this._base_uri + this._resource_uri;
        var deferred = $q.defer();
        $http.get(uri + queryString).then(

            angular.bind(this, function(u) {
                this.limit = u.data.meta.limit;
                this.totalCount = u.data.meta.total_count;
                this._next = u.data.meta.next;
                this._prev = u.data.meta.previous;
                this.currentPage = Math.floor(u.data.meta.offset / u.data.meta.limit) + 1;
                this.totalPages = Math.floor(u.data.meta.total_count / this.limit);
                this.splice(0, this.length);
                for (var i = 0; i < u.data.objects.length; i++) {
                    var model = new this.Model(u.data.objects[i]);
                    this.splice(i, 0, model);
                }
                deferred.resolve(this);
            })
        );

        return deferred.promise;
    };

    var TastyModel = function(params) {
        if (!params.hasOwnProperty('apiUrl')) {
            throw new Error('You must include an apiUrl in the params.');
        }

        this._base_uri = '';
        if (params.hasOwnProperty('base_uri')) {
            this._base_uri = params.base_uri;
        }

        var resource_uri = params.apiUrl;
        this._resource_uri = params.apiUrl;

        this.Model = function(properties) {
            angular.forEach(properties, function(value, key) {
                this[key] = value;
            }, this);

            // this is shadowed from the TastyResource constructor
            this._resource_uri = resource_uri;
        };
        this.Model.prototype = new Model();
        this.Model.prototype._resource_uri = this._resource_uri;
        this.Model.prototype._base_uri = this._base_uri;

        this.Collection = function() {
            this.limit = 20;
            this.offset = 0;
            this._orderBy = null;
            this.totalCount = null;
            this._next = null;
            this._prev = null;
            this._filter = {};
            this.currentPath = 1;
            this.totalPages = 1;
        };

        this.Collection.prototype = new Collection(this.Model);
        this.Collection.prototype._resource_uri = this._resource_uri;
        this.Collection.prototype._base_uri = this._base_uri;
    };
    return TastyModel;
};

TastyModel.$inject = ['$log', '$http', '$q'];
module.exports = TastyModel;