/**
 * Note model
 * @param $log
 */
var NoteModel = function($log, tastyModel, applications) {

    var Note = new tastyModel({
        apiUrl: '/tasty/v2/note/',
        base_uri: applications.platform.url,
    });

    Object.assign(Note.Model.prototype, {
    });

    Object.assign(Note.Collection.prototype, {
    });

    return Note;
};

NoteModel.$inject = ['$log', 'tastyModel', 'applications'];
module.exports = NoteModel;