function plRadioGroup($timeout) {
    return {
        restrict: 'E',
        scope: {
            options: '=',
            model: '=',
            label: '@?',
            change: '&?',
            focus: '&?',
            blur: '&?',
            disabled: '=?',
            required: '=?',
            name: '@?',
            horizontal: '&?',
        },
        replace: true,
        templateUrl: '/core/pl-inputs/src/plRadioGroup/plRadioGroup.tpl.html',
        link: ($scope) => {
            $scope.getClasses = () => {
                return {
                    horizontal: $scope.horizontal ? true : false,
                };
            };

            $scope.modelObj = {
                model: $scope.model ? $scope.model : '',
            };

            $scope.$watch('modelObj.model', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal)) {
                    $scope.model = newVal;
                    // Need timeout for model to update first.
                    $timeout(() => {
                        if ($scope.change) {
                            $scope.change();
                        }
                    }, 0);
                }
            });
            $scope.$watch('model', (newVal, oldVal) => {
                if (!angular.equals(oldVal, newVal)) {
                    $scope.modelObj.model = newVal;
                }
            });
        },
    };
}

plRadioGroup.$inject = ['$timeout'];
module.exports = plRadioGroup;
