
var PendoLogger = function (analytics_conf) {
  this.user = null;
  this.pendoKey = '';
  this.visitor = {};

  this.account = {};

  this.loadPendo = () => {
    if (!(this.pendoKey && this.pendoKey.length)) return;
    const loadPendoScriptElement = document.createElement('script');
    loadPendoScriptElement.type = 'text/javascript';
    loadPendoScriptElement.innerHTML = `
    window.loadPendo = function(apiKey){
      (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
      v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
          o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
          y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
          z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
    };
    `;
    document.head.appendChild(loadPendoScriptElement);
    window.loadPendo(this.pendoKey);
    window.pendo = window.pendo || [];
  };

  this.getPayrollStatus = (user) => {
    const FULL_TIME_EMPLOYEE = "Full-Time Employee";
    const PART_TIME_EMPLOYEE = "Part-Time Employee";
    const SALARIED_EMPLOYEE = "Salaried Employee";
    const INDEPENDENT_CONTRACTOR = "Independent Contractor";
    const SUBCONTRACTOR = "Subcontractor";
  
    let employmentStatus = user.provider.employment_status;
    switch (employmentStatus) {
      case FULL_TIME_EMPLOYEE:
      case PART_TIME_EMPLOYEE:
      case SALARIED_EMPLOYEE:
        return 'W2';
      case INDEPENDENT_CONTRACTOR:
      case SUBCONTRACTOR:
        return '1099';
      default:
        return null;
    }
  };

  this.setUser = (user, isHijacked = false) => {
    if (!(this.pendoKey && this.pendoKey.length)) return;
    const pendo = window.pendo;
    // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
    // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
    // Call this function in your authentication promise handler or callback when your visitor and account id values are available
    // Please use Strings, Numbers, or Bools for value types.

    let providerData;
    if (user.provider) {
      providerData = {
        discipline: user.providerTypes.map(pt => pt.long_name),
        billing_state: user.provider.billing_state,
        payroll_type: this.getPayrollStatus(user),
        employment_status: user.provider.employment_status,
        complete_onboarding: user.provider.is_onboarding_wizard_complete,
        sub_status: user.provider.provider_sub_status,
      };
    }

    const visitor = {
      isHijacked,
      id: user.uuid,
      email: user.email,
      full_name: `${user.first_name} ${user.last_name}`,
      username: user.username,
      auth_groups: user.groups,
      years_with_presence: moment(user.last_login).diff(
        new Date(user.created),
        'years',
        false
      ),
    };

    this.visitor = Object.assign({}, visitor, providerData);

    this.account = {
      id: this.pendoKey,
    };

    pendo.initialize({
      visitor: this.visitor,
      account: this.account,
    });
  };

  const envPendo = localStorage.getItem('pl-pendo-key') || analytics_conf.pendo;
  this.pendoKey = envPendo === 'undefined' ? null : envPendo;
  this.loadPendo();
};

PendoLogger.$inject = ['analytics_conf', '$rootScope'];
module.exports = PendoLogger;
