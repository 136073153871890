var FavoritesModel = function($log, folderModel) {
    this.clinicalCategories = [
        'Speech Therapy',
        'Occupational Therapy',
        'Counselng'
    ];

    this.path = [];
    this.selectedFavoriteFolder = null;


    this.folders = [];
    this.playlists = [];

    this.defaultFolders = [];

    var f;
    f = new folderModel.Model();
    f.name = "All Favorites";
    f.id = 'allfavorites';
    f.type = 'default';
    f.children = [];
    this.defaultFolders.push(f);

    f = new folderModel.Model();
    f.name = "Incoming";
    f.id = 'incoming';
    f.type = 'default';
    f.children = [];
    this.defaultFolders.push(f);

    f = new folderModel.Model();
    f.name = "Apps I Created";
    f.id = 'appsIcreated';
    f.type = 'default';
    f.children = [];
    this.defaultFolders.push(f);

    f = new folderModel.Model();
    f.name = "Most Used";
    f.id = 'mostused';
    f.type = 'default';
    f.children = [];
    this.defaultFolders.push(f);

    f = new folderModel.Model();
    f.name = "Trash";
    f.id = 'trash';
    f.type = 'trash';
    f.children = [];
    this.defaultFolders.push(f);

    var f1 = new folderModel.Model();
    f1.id = 1;
    f1.name = 'Games';

    var f2 = new folderModel.Model();
    f2.id = 2;
    f2.name = 'Worksheets';

    var f3 = new folderModel.Model();
    f3.id = 3;
    f3.name = 'Language';

    f1.children = [];
    f2.children = [f3];
    f3.children = [];

    this.folders.push(f1);
    this.folders.push(f2);

    this.folders = this.folders.concat(this.defaultFolders);

    this.playlists = [];

/*
    this.playlists = [];
    f = new folderModel.Model();
    f.name = 'Games';
    f.children = [];
    this.playlists.push(f);

    s = new folderModel.Model();
    s.name = 'Language';
    s.children = [];

    w = new folderModel.Model();
    w.name = 'Worksheets';
    w.children = [s];
    this.playlists.push(w);
*/

    this.activities = [];
    var activity = {
        title : "Application Name",
        type : "worksheet",
        tags : [
            'asd',
            'halloween',
            'w-words'
        ],
        timesUsed: 1312
    };

    for(var i=0; i<30; i++) {
        this.activities.push(_.clone(activity));
    }


    var init = function () {
        $log.debug("[FavoritesModel] Creating FavoritesModel");
    };
    init();
};

FavoritesModel.$inject = ['$log', 'folderModel'];
module.exports = FavoritesModel;