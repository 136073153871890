function plServiceOptsFactory($q, plServices, plServiceTypes,
 plAssessments, plAreasOfConcern) {
    const plServiceOpts = {};

    plServiceOpts.getAll = (clientService) => {
        const deferred = $q.defer();

        plServiceOpts.getServicesAndTypes()
            .then(() => {
                const info = plServiceOpts.serviceTypeByClientService(clientService);

                plServiceOpts.assessmentsAndAreasOfConcernByServiceType(info.serviceType.uuid)
                    .then((res1) => {
                        deferred.resolve({
                            service: info.service,
                            serviceType: info.serviceType,
                            assessmentsUsed: res1.assessmentsUsed,
                            areasOfConcern: res1.areasOfConcern,
                        });
                    });
            });

        return deferred.promise;
    };

    plServiceOpts.getServicesAndTypes = () => {
        const deferred = $q.defer();
        let services = [];
        let serviceTypes = [];

        const promises = [];
        const deferreds = {};

        deferreds.services = $q.defer();
        promises.push(deferreds.services.promise);
        plServices.get()
            .then((resServices) => {
                services = resServices;
                deferreds.services.resolve();
            });

        deferreds.serviceTypes = $q.defer();
        promises.push(deferreds.serviceTypes.promise);
        plServiceTypes.get()
            .then((resServiceTypes) => {
                serviceTypes = resServiceTypes;
                deferreds.serviceTypes.resolve();
            });

        $q.all(promises)
            .then(() => {
                deferred.resolve({ services, serviceTypes });
            });

        return deferred.promise;
    };

    plServiceOpts.assessmentsAndAreasOfConcernByServiceType = (serviceTypeUuid) => {
        const deferred = $q.defer();
        let assessmentsUsed = [];
        let areasOfConcern = [];

        const promises = [];
        const deferreds = {};

        deferreds.assessmentsUsed = $q.defer();
        promises.push(deferreds.assessmentsUsed.promise);
        plAssessments.get({ service_types: serviceTypeUuid })
            .then((resAssessmentsUsed) => {
                assessmentsUsed = resAssessmentsUsed;
                deferreds.assessmentsUsed.resolve();
            });

        deferreds.areasOfConcern = $q.defer();
        promises.push(deferreds.areasOfConcern.promise);
        plAreasOfConcern.get({ service_type: serviceTypeUuid })
            .then((resAreasOfConcern) => {
                areasOfConcern = resAreasOfConcern;
                deferreds.areasOfConcern.resolve();
            });

        $q.all(promises)
            .then(() => {
                deferred.resolve({ assessmentsUsed, areasOfConcern });
            });

        return deferred.promise;
    };

    plServiceOpts.serviceTypeByClientService = (clientService) => {
        const service = plServices.getInfo(clientService.service);
        const serviceType = (typeof service.service_type === 'string') ?
         plServiceTypes.getInfo(service.service_type) : service.service_type;
        return { service, serviceType };
    };

    return plServiceOpts;
}
plServiceOptsFactory.$inject = ['$q', 'plServices', 'plServiceTypes',
 'plAssessments', 'plAreasOfConcern'];
module.exports = plServiceOptsFactory;
