function plMayFactory($q, currentUserModel, plTimezone) {
    const plMay = {};
    const _plMay = {};
    _plMay.adminUuids = ['ffff6fb7-ec6a-4c4f-a438-3c721bb82714'];

    _plMay.getUserId = (userUuid1 = null) => {
        return userUuid1 ? userUuid1 : currentUserModel.user.uuid;
    };

    _plMay.isAdmin = (userUuid) => {
        if (_plMay.adminUuids.indexOf(userUuid) > -1) {
            return true;
        }
        return false;
    };

    _plMay.notIfAdmin = (userUuid) => {
        const deferred = $q.defer();
        let may = true;
        if (_plMay.isAdmin(userUuid)) {
            may = false;
        }
        deferred.resolve(may);
        return deferred.promise;
    };

    _plMay.ifAdmin = (userUuid) => {
        const deferred = $q.defer();
        let may = false;
        if (_plMay.isAdmin(userUuid)) {
            may = true;
        }
        deferred.resolve(may);
        return deferred.promise;
    };

    plMay.createDirectService = (userUuid1 = null) => {
        const userUuid = _plMay.getUserId(userUuid1);
        return _plMay.notIfAdmin(userUuid);
    };

    plMay.serviceSelfRefer = (userUuid1 = null) => {
        const userUuid = _plMay.getUserId(userUuid1);
        return _plMay.notIfAdmin(userUuid);
    };

    plMay.viewProviderClients = (userUuid1 = null) => {
        const userUuid = _plMay.getUserId(userUuid1);
        return _plMay.ifAdmin(userUuid);
    };

    plMay.viewUsers = (userUuid1 = null) => {
        const userUuid = _plMay.getUserId(userUuid1);
        return _plMay.ifAdmin(userUuid);
    };

    plMay.viewUserPermissions = (userUuid1 = null) => {
        const userUuid = _plMay.getUserId(userUuid1);
        return _plMay.ifAdmin(userUuid);
    };

    _plMay.eventHasRecord = (event) => {
        return (event.records && event.records.length) ? true : false;
    };

    _plMay.eventHasAppointment = (event) => {
        // If has an event sub-key it is an appointment. If appointment check for uuid.
        return (event.event && event.event.uuid && event.uuid) ? true : false;
    };

    _plMay.appointmentChangedStart = (appointment) => {
        if (!appointment.original_start || !appointment._start ||
         plTimezone.toUTC(appointment.original_start) !== plTimezone.toUTC(appointment._start)) {
            return true;
        }
        return false;
    };

    _plMay.eventIsLocked = (event) => {
        return (event.locked !== undefined) ? event.locked : false;
    };

    _plMay.eventGetType = (event) => {
        let eventToUse = event;
        if (event._event) {
            eventToUse = event._event;
        }
        if (eventToUse.event_type) {
            return eventToUse.event_type;
        } else if (eventToUse.event) {
            return eventToUse.event.event_type;
        }
        return false;
    };

    plMay.eventIsAvailability = (event) => {
        return (_plMay.eventGetType(event) === 'AVAILABILITY') ? true : false;
    };

    plMay.eventIsAssignment = (event) => {
        return (_plMay.eventGetType(event) === 'ASSIGNMENT') ? true : false;
    };

    plMay.eventIsBilling = (event) => {
        return (_plMay.eventGetType(event) === 'BILLING') ? true : false;
    };

    plMay.eventEditRepeating = (event) => {
        if (_plMay.eventHasRecord(event) || _plMay.appointmentChangedStart(event)) {
            return false;
        }
        return true;
    };

    plMay.eventDeleteRepeating = (event) => {
        if (_plMay.eventHasRecord(event) || _plMay.appointmentChangedStart(event)) {
            return false;
        }
        return true;
    };

    plMay.eventEdit = (userUuid1 = null, event) => {
        const userUuid = _plMay.getUserId(userUuid1);

        // check if any records are signed
        let signed = false;
        if (event.records && event.records.length > 0) {
            const signedEvent = event.records.find( (item) => {
                return item.signed;
            } );

            if (signedEvent){
                signed = signedEvent.signed;
            }
        }

        if (!_plMay.eventIsLocked(event) && event.event &&
         event.event.provider && event.event.provider === currentUserModel.user.uuid && !signed) {
            return true;
        }
        return false;
    };

    plMay.eventDelete = (userUuid1 = null, event) => {
        const userUuid = _plMay.getUserId(userUuid1);
        if (!_plMay.eventIsLocked(event) && event.event &&
         event.event.provider && event.event.provider === currentUserModel.user.uuid) {
            return true;
        }
        return false;
    };

    plMay.eventViewDocumentation = (userUuid1 = null, event) => {
        if (plMay.eventIsAssignment(event) || plMay.eventIsAvailability(event)) {
            return false;
        }
        return true;
    };

    plMay.eventEditDocumentation = (userUuid1 = null, event) => {
        if (plMay.eventIsAssignment(event) || plMay.eventIsAvailability(event)) {
            return false;
        }
        // Same as event edit as of now.
        return plMay.eventEdit(userUuid1, event);
    };

    plMay.eventAdd = (userUuid1 = null, calendarUserUuid) => {
        const userUuid = _plMay.getUserId(userUuid1);
        if (userUuid === calendarUserUuid) {
            return true;
        }
        return false;
    };

    return plMay;
}
plMayFactory.$inject = ['$q', 'currentUserModel', 'plTimezone'];
module.exports = plMayFactory;