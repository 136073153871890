class PermissionsHelper  {

    /**
     * Constructor
     * @param currentUserModel
     */
    constructor(currentUserModel) {
        this.$currentUserModel = currentUserModel;
    }
    
    isSchoolStaffUser() {
        return this.$currentUserModel.user.isInGroup('School Staff Providers') || this.$currentUserModel.user.isInGroup('School Staff Admins');
    }

    isPrivatePracticeUser() {
        return this.$currentUserModel.user.isInGroup('Private Practice');
    }
}

PermissionsHelper.$inject = ['currentUserModel'];
module.exports = PermissionsHelper;
