"use strict";

var FlashcardPanelController = require('../flashcardsPanel/flashcardsPanelController');
SelectFlashcardsController.$inject = ['$scope', '$state', 'localStorageService', 'FlashcardsWizardService', '$q', 'imageAssetModel', 'imageStorage', '$timeout', 'guidService'];

function SelectFlashcardsController($scope, $state, localStorageService, FlashcardsWizardService, $q, imageAssetModel, imageStorage, $timeout, guidService) {
    FlashcardPanelController.apply(this, arguments);
    var FlashcardsWizardServiceInstance = FlashcardsWizardService.requestInstance(FlashcardsWizardService.mode);

    var flashcard = FlashcardsWizardService.flashcard;
    $scope.maxCardTitleLength = 50;
    $scope.editorMode = FlashcardsWizardService.mode;
    $scope.showEditPopup = true;

    $scope.flashcard = flashcard;
    $scope.loadedImages = flashcard.images;

    function extractFlashcard(flashcard) {
        var copy = angular.merge({}, flashcard);

        for (var image of copy.images) {
            delete image.cropped;
        }

        return copy;
    }

    $scope.$watch(function () {
        return JSON.stringify(extractFlashcard(flashcard))
    }, function () {
        localStorageService.set('FLASHCARD_' + FlashcardsWizardService.mode, extractFlashcard(flashcard));
    });

    $scope.notifyOnUpdate = function() {
    };


    $scope.activeAssets = [];
    $scope.setActiveAsset = function (asset, isMultiple) {
        if (!asset) {
            return;
        }
        if (!isMultiple) {
            $scope.activeAssets = [asset];
            return;
        }
        if (_.isArray(asset)) {
            $scope.activeAssets = asset;
        } else {
            let result = _.find($scope.activeAssets, (item) => item.id === asset.id);
            if (!result) {
                $scope.activeAssets.push(asset);
            }
        }
        $scope.activeAssets.sort((prevItem, nextItem) => prevItem.id > nextItem.id);
        $scope.closeEditor(true);
    };

    $scope.$watch('activeAssets', function(newAssets) {
        if(1 === newAssets.length || !$scope.loadedImages.length) {
            $scope.openEditor();
            return;
        }
        $scope.closeEditor(true);
    });

    $scope.panelNavigation.current = 'select-flashcards';

    $scope.createEmptyCard = function () {
        let max = getMaxId(flashcard.images);

        let newCard = {
            id: ++max,
            title: '',
            url: '',
            thumbnail_url: ''
        };

        $scope.loadedImages.push(newCard);

        return newCard;
    };

    $scope.handleNewCardClick = function () {
        $scope.setActiveAsset($scope.createEmptyCard());
    };

    $scope.checkAssetsStatus = function(eventOrForce) {
        if(!$scope.activeAssets.length && !$scope.loadedImages.length &&
            (eventOrForce === true || _.get(eventOrForce, 'target.tagName') === 'INPUT')) {
            $scope.handleNewCardClick();
        }
    };

    $scope.handleDeleteCardClick = function() {
        if($scope.activeAssets.length) {
            for (var asset of $scope.activeAssets) {
                var index = $scope.loadedImages.indexOf(asset);

                if (index >= 0) {
                    $scope.loadedImages.splice(index, 1);
                }
            }

            $scope.activeAssets.length = 0;

            $scope.setActiveAsset(_.last($scope.loadedImages));
        }
    };

    $scope.handleSelectCardClick = function(image, isMultiple) {
        $scope.setActiveAsset(image, isMultiple);
    };

    $scope.handleDuplicateCardClick = function() {
        let max = getMaxId(flashcard.images);

        let images = $scope.activeAssets.map((activeAsset) => {
            let card = null;

            for (let image of flashcard.images) {
                if (activeAsset.id === image.id) {
                    card = image;
                    break;
                }
            }

            if (card) {
                return {
                    id: ++max,
                    title: card.title.substr(0, $scope.maxCardTitleLength),
                    url: card.url,
                    cropped: card.cropped,
                    thumbnail_url: card.thumbnail_url
                };
            }

            return null;
        });
        $scope.loadedImages.push.apply($scope.loadedImages, images);
        $scope.setActiveAsset(images, true);
    };

    $scope._successTest = function($models) {
        // used for directive check and test
    };

    $scope._failTest = function() {
        // used for directive check and test
    };

    $scope.isAssetDeletable = function() {
        return $scope.activeAssets.length;
    };

    $scope.isAssetDuplicable = function() {
        return $scope.activeAssets.length;
    };

    $scope.showEditor = false;

    $scope.closeEditor = function (dontClearActiveAsset) {
        $scope.showEditor = false;

        if (!dontClearActiveAsset) {
            $scope.activeAssets = [];
        }
    };
    $scope.openEditor = function () {
        $scope.showEditor = true;
    };

    $scope.toggleEditor = function () {
        $scope.showEditor = !$scope.showEditor;
    };

    $scope.isEditorActive = function () {
        return $scope.showEditor;
    };

    $scope.closePopup = function() {
        console.warn(13);
    };

    $scope.assets_list = FlashcardsWizardServiceInstance.getMyAssetsList();

    $scope.setAssetsListByTab = function(tab) {
        if(tab == $scope.tabsConfig.myUploads){
            $scope.assets_list = FlashcardsWizardServiceInstance.getMyAssetsList();
        } else if (tab == $scope.tabsConfig.allImages) {
            $scope.assets_list = FlashcardsWizardServiceInstance.getAllAssetsList();
        } else if (tab === $scope.tabsConfig.pcsSymbols) {
            $scope.assets_list = FlashcardsWizardServiceInstance.getPCSSymbolsList();
        }
    };

    $scope.toggleCard = function($event, selected_card) {

        let index = $scope.selectedCardIndex(selected_card);

        if(index > -1) {
            $scope.pullCardFromSelected(index);
        } else {
            $scope.pushCardToSelected(selected_card);
        }

        if($scope.selectImage) {

            index = $scope.selectedCardIndex(selected_card);
            if (index == 0) {
                $scope.chooseImageSilently(selected_card);
            }
        }
    };


    $scope.assets_list.search($scope.searchQuery);

    $scope.onSortImages = (list) => {
        $scope.loadedImages = list;
    };

    $scope.titlePlaceholder = {
        'create-memory': 'New Memory Card Set'
    }[$scope.editorMode] || 'New Flashcard Set';

    function getMaxId(images) {
        let max = 0;

        for (let image of images) {
            max = Math.max(max, image.id);
        }

        return max;
    }
}

module.exports = SelectFlashcardsController;
