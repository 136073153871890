function plInputSearchFactory($timeout, plLodash) {
    const plInputSearch = {};

    plInputSearch.removeOpt = (opt, opts) => {
        const index = plLodash.findIndex(opts, 'value', opt.value);
        if (index > -1) {
            opts.splice(index, 1);
        }
        return opts;
    };

    plInputSearch.addOpt = (opt, opts) => {
        opts.push(opt);
        return opts;
    };

    plInputSearch.selectOpt = (opt, opts, selectedOpts, model) => {
        if (opts && opts.length) {
            // See if already selected.
            const index = plLodash.findIndex(selectedOpts, 'value', opt.value);
            if (index < 0) {
                selectedOpts.push(opt);
                opts = plInputSearch.removeOpt(opt, opts);
            }
            // TODO - does not work, would need to pass in $scope..
            // Blank out model (input) value.
            // $timeout(() => {
            //     model = '';
            // }, 250);
        }
        return { opts, selectedOpts };
    };

    plInputSearch.removeSelectedOpt = (opt, opts, selectedOpts) => {
        const index = plLodash.findIndex(selectedOpts, 'value', opt.value);
        if (index > -1) {
            selectedOpts.splice(index, 1);
            opts = plInputSearch.addOpt(opt, opts);
        }
        return { opts, selectedOpts };
    };

    return plInputSearch;
}
plInputSearchFactory.$inject = ['$timeout', 'plLodash'];
module.exports = plInputSearchFactory;
