var RadioInputDirective = function(guidService) {
    return {
        replace: false,
        restrict: 'E',
        scope: {
            /**
             * options should be in this form:
             * [
             *   {
             *     value: "value"
             *     label: "label"
             *   },
             * ]
             */
            options: '=',
        },
        require: 'ngModel',
        templateUrl: '/core/pl-core/src/directives/radioInput/radioInput.tpl.html',
        link: function($scope, element, attrs, ngModel) {
            let ids = {};
            $scope.name = guidService.generateUUID();
            $scope.localData = {value: undefined};
            $scope.getId = function(option) {
                if (!ids[option.value]) {
                    ids[option.value] = guidService.generateUUID();
                }
                return ids[option.value];
            };
            // Necessary to properly update the $scope.data due to
            // pass by reference issues.
            $scope.$watch('localData.value', function(newValue) {
                ngModel.$setViewValue(newValue);
            });
        }
    };
};

RadioInputDirective.$inject = ['guidService'];
module.exports = RadioInputDirective;