function plApiServiceSaveFactory($q, plClientServices, plClient, plApiServiceUploadDocuments) {
    const plApiServiceSave = {};

    plApiServiceSave.save = (serviceObj = {}, client, documents) => {
        const deferred = $q.defer();

        const promises = [];
        const deferreds = {};
        if (client) {
            promises.push(plClient.save(client));
        }
        
        let type = null;
        let clientService = null;
        if (serviceObj.evaluation) {
            type = 'evaluation';
            clientService = serviceObj.evaluation;
        } else if (serviceObj.directService) {
            type = 'directService';
            clientService = serviceObj.directService;
        }
        if (type) {
            deferreds.clientService = $q.defer();
            promises.push(deferreds.clientService.promise);
            plClientServices.save(clientService, type)
                .then((resClientService) => {
                    clientService = resClientService;
                    deferreds.clientService.resolve();
                }, (err) => {
                    deferreds.clientService.reject(err);
                });
        }

        $q.all(promises)
            .then(() => {
                plApiServiceSave.saveDocuments(documents, clientService)
                    .then(() => {
                        deferred.resolve({});
                    }, (err) => {
                        deferred.reject(err);
                    })
            }, (err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    };

    plApiServiceSave.saveDocuments = (documents, clientService) => {
        const deferred = $q.defer();

        const promises = [];
        const deferreds = {};
        if (documents.schoolConsentFiles && documents.schoolConsentFiles.length) {
            deferreds.school = $q.defer();
            promises.push(deferreds.school.promise);
            plApiServiceUploadDocuments.saveType(documents.schoolConsentFiles, 'school_consent_form', clientService, documents.schoolConsentSignedOn)
                .then(() => {
                    deferreds.school.resolve();
                }, (err) => {
                    deferreds.school.reject(err);
                });
        }
        if (documents.parentConsentFiles && documents.parentConsentFiles.length) {
            deferreds.parent = $q.defer();
            promises.push(deferreds.parent.promise);
            plApiServiceUploadDocuments.saveType(documents.parentConsentFiles, 'parent_consent', clientService)
                .then(() => {
                    deferreds.parent.resolve();
                }, (err) => {
                    deferreds.parent.reject(err);
                });
        }
        $q.all(promises)
            .then(() => {
                deferred.resolve({});
            }, (err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    };

    return plApiServiceSave;
}
plApiServiceSaveFactory.$inject = ['$q', 'plClientServices', 'plClient', 'plApiServiceUploadDocuments'];
module.exports = plApiServiceSaveFactory;
