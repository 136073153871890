var DrfActivityModel = function($log, $q, $http, drfModel, tagModel, applications) {

    var Activity = new drfModel({
        apiUrl: `${applications.platform.url}/api/v1/activity/`,
    });

    Object.assign(Activity.Model.prototype, {
        '$use': function() {
            if (!this.hasOwnProperty('resource_uri')) {
                throw new Error('This model hasn\'t been saved yet.');
            }
            var drf = new drfModel({
                apiUrl: this.resource_uri
            });
            var model = new drf.Model().$setKey('use');
            return model.$save().then(() => this.$get());
        },
        '$addTag': function(tag) {
            let deferred = $q.defer();

            if (this.$isSaved()) {
                //     if (!this.hasOwnProperty('resource_uri')) {
                //     throw new Error('This model hasn\'t been saved yet.');
                // }
                // var drf = new drfModel({
                //     apiUrl: this.resource_uri
                // });
                // var model = new drf.Model().$setKey('tag/'+ tag);
                // return model.$save().then(() => this.$get());



                var url = this.resource_uri + 'tag/' + tag + '/';
                var p = $http['post'](url);
                p.then(() => {
                    this.$get().then((got) => deferred.resolve(got), (err) => deferred.reject(err));
                }).
                catch((err) => {
                    deferred.reject(err);
                });
            } else {
                // if the activity hasn't been saved yet
                var newTag = new tagModel.Model();
                newTag.name = tag;
                if (!this.hasOwnProperty('tags')) {
                    this.tags = [];
                }
                this.tags.push(newTag);
                deferred.resolve(this);
            }
            return deferred.promise;
        },
        '$removeTag': function(tag) {
            let deferred = $q.defer();

            if (this.$isSaved()) {
                var url = this.resource_uri + 'tag/' + tag + '/';
                var p = $http['delete'](url);
                p.then(() => {
                    this.$get().then((got) => deferred.resolve(got), (err) => deferred.reject(err));
                }).
                catch((err) => {
                    deferred.reject(err);
                });
            } else {
                // if the activity hasn't been saved yet
                if (!this.hasOwnProperty('tags')) {
                    deferred.resolve(this);
                } else {
                    var index = _.findIndex(this.tags, function(item) {
                        return item.name == tag;
                    });
                    if (index >= 0) {
                        this.tags.splice(index, 1);
                    }
                    deferred.resolve(this);
                }
            }
            return deferred.promise;
        },
        '$favorite': function(is_favorite) {
            var url = this.resource_uri + 'favorite/';
            var method = is_favorite ? 'post' : 'delete';
            return $http({
                method: method,
                url: url,
            }).then(() => this.$get());
        },
        '$getViaSlug': function(slug) {
            var url = this.resource_uri + 'slug/' + slug + '/';
            return $http.get(url).then((data) => {
                angular.forEach(data.data, (value, key) => {
                    this[key] = value;
                    if (key === "id") {
                        this.$setKey(value)
                    }
                });
                return this;
            });
        },
        '$reportInappropriateContent': function() {
            const data = {
                report_type: 'inappropriate_content',
                activity: this.uuid,
            }
            var url = `${applications.platform.url}/api/v1/activity-reported/`;
            return $http.post(
                url,
                data,
            ).then(() => this.$get());
        },
        '$reportCopyrightIssue': function() {
            const data = {
                report_type: 'copyright_issue',
                activity: this.uuid,
            }
            var url = `${applications.platform.url}/api/v1/activity-reported/`;
            return $http.post(
                url,
                data,
            ).then(() => this.$get());
        }
    });

    Object.assign(Activity.Collection.prototype, {});

    return Activity;
};

DrfActivityModel.$inject = ['$log', '$q', '$http', 'drfModel', 'tagModel', 'applications'];
module.exports = DrfActivityModel;