"use strict";

FlashcardsWizardService.$inject = [ 'drfActivityModel',
    'imageAssetModel',
    'currentUserModel',
    'localStorageService',
    'tagModel',
    '$state'
];

function FlashcardsWizardService(drfActivityModel,
                                 imageAssetModel,
                                 currentUserModel,
                                 localStorageService,
                                 tagModel,
                                 $state) {
    var State = function () {
        var me = this,
            card_last_id = 1;
        me.activity = getNewActivity();
        me.images = [];

        me.descriptor = {
            "title":"",
            "cards":[]
        };

        me.prepare = function() {
            prepareDescriptor();
            me.setDescriptor(me.descriptor);

            return me.activity;
        };

        me.clear = function() {
            me.activity = getNewActivity();
            me.images = [];
        };

        me.createEmptyCard = function() {
            let newCard = {
                id: card_last_id,
                title: '',
                url: '',
                thumbnail_url: ''
            };

            card_last_id++;
            return newCard;
        };

        me.setCardLastId = function(last_id) {
            card_last_id = last_id;
        };

        function getNewActivity() {
            let activity =  _.extend(new drfActivityModel.Model(), {
                'activity_type': 'flashcards',
                'type': 'flashcards',
                'tags': [],
                'prepare': () => me.prepare()
            });

            activity.setName = (name) => me.activity.name = name;
            activity.setDescription = (description) => me.activity.description = description;
            activity.setShared = (shared) => me.activity.private = !shared;

            return activity;
        }

        me.getLoadedImages = () => me.images;
        me.pushImages = function(images) {
            me.images.push(...images);
        };

        me.setDescriptor = (descriptor) => me.activity.descriptor = JSON.stringify(descriptor);
        me.getActivityModel =  () => me.activity;

        me.assets_lists = [];

        var addList = function(title, filter, limit, orderBy) {
            var me = this;

            me.assets = new imageAssetModel.Collection();
            me.assets.$limit(limit);
            me.loading = false;
            me.title = title;
            me.page = 0;
            me.totalPages = null;

            me.getNext = function() {
                if(me.hasNext() == false || me.loading) {
                    return;
                }

                me.loading = true;

                me.assets.$next().then(function(result) {
                    me.page += 1;
                    me.totalPages = result.totalPages;
                    me.loading = false;
                });
            };

            me.getPrev = function() {
                if(me.hasPrev() == false || me.loading) {
                    return;
                }

                me.loading = true;

                me.assets.$prev().then(function(result) {
                    me.page -= 1;
                    me.totalPages = result.totalPages;
                    me.loading = false;
                });
            };

            me.hasPrev = function() {
                return me.page > 1;
            };

            me.hasNext = function() {
                return me.totalPages > me.page;
            };

            me.search = _.debounce(function(search) {
                let query = {
                    'q': search,
                    'file_type': filter.file_type || 'image'
                };
                me.loading = true;

                me.assets.$filter(query);
                me.assets.$fetch().then(function(result) {
                    me.page = 1;
                    me.totalPages = result.totalPages;
                    me.loading = false;
                });
            }, 300);

            me.assets.$clear();
            me.assets.$filter(filter);
            me.assets.$limit(limit);
            me.assets.$orderBy(orderBy);
            me.loading = true;

            me.assets.$fetch().then(function(result) {
                me.page = 1;
                me.totalPages = result.totalPages;
                me.loading = false;
            });
        };

        me.assets_lists.push(new addList('My uploads', {
            'file_type': 'image',
            'owner__uuid': currentUserModel.user.uuid,
            'disabled': 'false'
        }, 12, 'id'));

        me.assets_lists.push(new addList('All images', {
            'file_type': 'image',
            'disabled': 'false'
        }, 12, 'id'));

        me.assets_lists.push(new addList('PCS Symbols', {
            'file_type': 'pcs_symbol',
            'disabled': 'false'
        }, 12, 'id'));

        me.getMyAssetsList = function() {
            return me.assets_lists[0];
        };

        me.getAllAssetsList = function() {
            return me.assets_lists[1];
        };

        me.getPCSSymbolsList = function() {
            return me.assets_lists[2];
        };

        me.load = function (activity) {
            var descriptor = JSON.parse(activity.descriptor);

            for (var key in activity) {
                me.activity[key] = activity[key];
            }

            me.descriptor.title = descriptor.title;
            me.descriptor.cards = descriptor.cards;
        };

        function prepareDescriptor() {
            me.descriptor.title = me.activity.name;
            me.descriptor.cards = me.images.map((image) => {
                if (image.title && image.url) {
                    image.type = "both";
                } else if (image.title ) {
                    image.type = "title";
                } else if (image.url) {
                    image.type = "image";
                } else {
                    image.type = "unknown";
                }
                delete image.cropped;
                return image;
            });

            me.descriptor.cards = me.descriptor.cards.filter((image) => {
                return image.title && image.url;
            });

            if (!me.activity.description) {
                me.activity.description = "";
            }

            me.descriptor.description = me.activity.description;
        }
    };

    var StateDecorator = function(state, mode) {
        var me = this,
            activityDecorator = getNewActivityDecorator(),
            FLASHCARDS_EDITOR_IMAGES = 'flashcards_editor_images' + mode,
            images = [];

        me.load = load;
        me.clear = clear;
        me.persist = persist;
        me.removeCard = removeCard;
        me.pushImages = pushImages;
        me.getLoadedImages = getLoadedImages;
        me.getActivityModel = getActivityModel;
        me.reorderLoadedImages = reorderLoadedImages;

        me.createEmptyCard = state.createEmptyCard;
        me.getMyAssetsList = state.getMyAssetsList;
        me.getAllAssetsList = state.getAllAssetsList;
        me.getPCSSymbolsList = state.getPCSSymbolsList;

        function load(activity) {
            var editor = localStorageService.get(FLASHCARDS_EDITOR_IMAGES);

            if (activity) {
                if (editor && (activity.slug === editor.slug)) {
                    images.push.apply(images, editor.images);
                } else {
                    var descriptor = JSON.parse(activity.descriptor);
                    images = descriptor.cards;
                    //activityDecorator.tags = activity.tags;

                    state.load(activity);
                }
            } else {
                images = (editor && editor.images) || [];
            }

            activityDecorator.load();
        }

        function getActivityModel() {
            return activityDecorator;
        }

        function getLoadedImages() {
            return images;
        }

        function reorderLoadedImages(firstItem, secondItem) {
            var indexFirstElement = images.indexOf(firstItem);
            images.splice(indexFirstElement, 1);
            if (!secondItem) {
                images.push(firstItem);
                persist();
                return images;
            }
            var indexSecondElement = images.indexOf(secondItem);
            var secondPart = images.splice(indexSecondElement);
            images.push(firstItem);
            images = images.concat(secondPart);
            persist();
            return images;
        }

        function pushImages(pushedImages) {
            images.push(...pushedImages);
            state.pushImages(pushedImages);
            persist();
        }

        function removeCard(card) {
            images.splice(_.findIndex(images, {id:card.id}), 1);
            persist();
        }

        function clear() {
            /** clear activity LS */
            activityDecorator.clearLocalStorage();
            /** clear state and create fresh model */
            state.clear();
            /** create new activity decorator with fresh state */
            activityDecorator = getNewActivityDecorator();
            /** clear state LS */
            clearLocalStorage();
        }

        function getNewActivityDecorator() {
            return new ActivityDecorator(state.getActivityModel());
        }

        function persist() {
            activityDecorator.setPreviewImage(images[0]);
            localStorageService.set(FLASHCARDS_EDITOR_IMAGES, {
                slug: activityDecorator.getSlug(),
                images: images.map((image) => ({
                    id: image.id,
                    url: image.url,
                    title: image.title,
                    thumbnail_url: image.thumbnail_url
                }))
            });
        }

        function clearLocalStorage() {
            images = [];
            localStorageService.remove(FLASHCARDS_EDITOR_IMAGES);
        }

        function readFromStorage() {
            let container = localStorageService.get(FLASHCARDS_EDITOR_IMAGES);

            if (container) {
                if (container.slug === activityModel.slug) {
                    let imagesFromStorage = container.images;

                    if(!Array.isArray(imagesFromStorage)) {
                        return;
                    }

                    _.forEach(imagesFromStorage, function(image) {
                        state.setCardLastId(image.id + 1);

                        if(!image.showProgress) {
                            pushImages([image]);
                        }
                    });
                }
            }
        }

    };

    var ActivityDecorator = function(activityModel) {
        let me = this,
            activity = activityModel,
            FLASHCARDS_EDITOR = 'flashcards_editor';

        me.prepare = activity.prepare;
        me.tags = activityModel.tags || (activityModel.tags = []);

        me.$addTag = $addTag;
        me.$removeTag = $removeTag;
        me.setName = setName;
        me.setShared = setShared;
        me.onTagsChange = persist;
        me.setDescription = setDescription;
        me.setPreviewImage = setPreviewImage;
        me.clearLocalStorage = clearLocalStorage;

        me.getSlug = () => activity.slug;
        me.getName = () => activity.name;
        me.getShared = () => !activity.private;
        me.getDescription = () => activity.description;

        me.load = load;

        function load() {
            readFromStorage();
        }

        function setName(name) {
            persist();
            activity.setName(name);
        }

        function setShared(shared) {
            persist();
            activity.setShared(shared);
        }

        function setDescription(description) {
            persist();
            activity.setDescription(description);
        }

        function clearLocalStorage() {
            localStorageService.remove(FLASHCARDS_EDITOR + $state.params.mode);
        }

        function setPreviewImage(image) {
            if(!image) {
                return;
            }
            /* It doesn't work on test environment */
            activity.thumbnail_url = image.thumbnail_url;
            /* Large templates generates asynchronously and doesn't use url from activity.large_thumbnail_url */
            activity.large_thumbnail_url = image.thumbnail_url;
        }

        function $addTag(tag) {
            me.tags.push(createTagModel(tag));
            persist();
        }

        function $removeTag(tag) {
            var index = _.findIndex(me.tags, function (item) {
                return item.name == tag;
            });

            if (index >= 0) {
                me.tags.splice(index, 1);
            }

            persist();
        }

        function persist(){
            localStorageService.set(FLASHCARDS_EDITOR + $state.params.mode, {
                tags: me.tags,
                slug: activity.slug,
                name: activity.name,
                private: activity.private,
                description: activity.description
            });
        }

        function readFromStorage() {
            let editor = localStorageService.get(FLASHCARDS_EDITOR + $state.params.mode);

            if (editor && (editor.slug === activity.slug)) {
                activity.name = editor.name;
                activity.private = editor.private;
                activity.description = editor.description;

                let tagsFromStorage = editor.tags;

                if(!Array.isArray(tagsFromStorage)) {
                    return;
                }

                _.forEach(tagsFromStorage, function(tag) {
                    $addTag(tag.name)
                });
            }
        }

        function createTagModel(tag) {
            var tagObject = new tagModel.Model();
            tagObject.name = tag;
            return tagObject;
        }
    };

    var instances = { };

    return {
        requestInstance: function (mode) {
            return instances[mode] || (instances[mode] = new StateDecorator(new State(), mode));
        }
    };
}

module.exports = FlashcardsWizardService;
