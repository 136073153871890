"use strict";

function OnResizeDirective() {
    return {
        restrict: 'A',
        priority: 475,
        compile: function OnResizeCompileFn() {
            return {
                post: function OnResizePreLinkFn(scope, element, attributes) {
                    scope.$watch(function () {
                        let rect = element.get(0).getBoundingClientRect();
                        return {
                            bottom: rect.bottom,
                            height: rect.height,
                            left: rect.left,
                            right: rect.right,
                            top: rect.top,
                            width: rect.width
                        };
                    }, function ($rect) {
                        scope.$evalAsync(attributes['onResize'], {
                            $rect: $rect || element.get(0).getBoundingClientRect(),
                            $position: element.position(),
                            $offset: element.offset(),
                            $width: element.width(),
                            $height: element.height()
                        });
                    }, true);
                }
            };
        }
    }
}

module.exports = OnResizeDirective;