function plOverlayLoadingDirective(plOverlayLoading) {
    return {
        restrict: 'E',
        scope: {
        },
        templateUrl: '/core/pl-overlays/src/plOverlayLoading/plOverlayLoading.tpl.html',
        link: ($scope, $element, $attrs) => {
            $element.addClass('pl-overlay-loading');
        },
    };
}

plOverlayLoadingDirective.$inject = ['plOverlayLoading'];
module.exports = plOverlayLoadingDirective;
