function ActivityDetailController(
    $scope,
    $state,
    $sce,
    $injector,
    reviewModel,
    currentUserModel,
    Activity,
    Room,
    $rootScope,
    $log,
    PreviousState
) {
    // Activities queue is only needed if there's a room
    let activitiesQueue = null;
    if (Room) {
        activitiesQueue = $injector.get('activitiesQueue');
    }
    $scope.queue = activitiesQueue;
    //  $sceProvider.enabled(false);

    $scope.hoverRating = -1;
    $scope.editTitle = false;
    $scope.editDescription = false;
    $scope.showActivityReport = false;

    $scope.prevState = PreviousState;
    $scope.username = currentUserModel.user.username;

    $scope.activity = Activity;

    $scope.isSupport = currentUserModel.user.isInGroup('Service & Support');

    $scope.isPublic = !$scope.activity.private;
    $scope.inappropriateContent = false;
    $scope.copyrightIssue = false;

    $scope.canEdit = () => {
        const isAdmin = currentUserModel.user.isInGroup('Administrator');
        const isOwner = $scope.activity.is_activity_owner;

        return isAdmin || isOwner;
    };

    let descriptor;
    try {
        descriptor = JSON.parse($scope.activity.descriptor);
    } catch (e) {
        $log.error('[ActivityDetailController] No descriptor found for activity', $scope.activity.id, e);
        descriptor = {};
    }

    if (descriptor.videoId) {
        $scope.videoUrl = $sce.trustAsResourceUrl(`https://www.youtube.com/embed/${descriptor.videoId}?rel=0`);
    }

    if (descriptor.cards && ($scope.activity.type === 'flashcards' || $scope.activity.type === 'memory')) {
        $scope.cards = descriptor.cards;
    }

    $scope.reviews = new reviewModel.Collection();
    //  $scope.$orderBy('-created');
    $scope.reviews.limit = 5;
    $scope.reviews.$filter({
        activity__id: $scope.activity.id,
    });
    $scope.reviews.$fetch();

    $scope.myReviews = new reviewModel.Collection();
    $scope.myReviews.$filter({
        activity__id: $scope.activity.id,
        user__uuid: currentUserModel.user.uuid,
    });
    $scope.myReviews.$fetch();

    $scope.publicPrivateChanged = () => {
        $scope.activity.private = !$scope.isPublic;
        $scope.activity.$save();
    }

    $scope.beginEditTitle = () => {
        $scope.editTitle = true;
    };

    $scope.updateTitle = () => {
        $scope.activity.$save().then(() => {
            $state.go('app.store.activities.detail', {
                slug: $scope.activity.slug,
            });
        });
        $scope.editTitle = false;
    };
    $scope.beginEditDescription = () => {
        $scope.editDescription = true;
    };
    $scope.updateDescription = () => {
        $scope.activity.$save();
        $scope.editDescription = false;
    };

    $rootScope.$on('reportActivity', (evt, data) => {
        $scope.showContentReport = true;
    });

    $scope.submitContentReport = (inappropriateContent, copyrightIssue) => {
        $scope.showContentReport = false;
        if (inappropriateContent) {
            $scope.activity.$reportInappropriateContent().then(() => {
                if (copyrightIssue) {
                    $scope.activity.$reportCopyrightIssue();
                }
            });
        } else if (copyrightIssue) {
            $scope.activity.$reportCopyrightIssue();
        }
        $scope.inappropriateContent = false;
        $scope.copyrightIssue = false;
    }

    $scope.cancelContentReport = () => {
        $scope.showContentReport = false;
    }

    $scope.cancelEditTitle = () => {
        $scope.editTitle = false;
        $scope.activity.$get();
    };

    $scope.notRatedYet = () => {
        if ($scope.myReviews === undefined) {
            return false;
        }

        return $scope.myReviews.length === 0;
    };

    $scope.editReview = (review) => {
        review.editing = true;
    };
    $scope.endEditReview = (review) => {
        review.editing = false;
        $scope.reviewAdded();
    };

    $scope.reviewAdded = () => {
        $scope.activity.$get();
        $scope.reviews.$fetch();
        $scope.myReviews.$fetch();
    };

    $scope.tagAdded = (tag) => {
        $scope.activity.$addTag(tag);
    };
    $scope.tagRemoved = (tag) => {
        $scope.activity.$removeTag(tag);
    };

    $scope.navigateBackState = () => {
        if(PreviousState.name && PreviousState.params) {
            $state.go(PreviousState.name, PreviousState.params);
        } else {
            $rootScope.navigateBack(true);
        }
    };
}

ActivityDetailController.$inject = [
    '$scope',
    '$state',
    '$sce',
    '$injector',
    'reviewModel',
    'currentUserModel',
    'Activity',
    'Room',
    '$rootScope',
    '$log',
    'PreviousState'
];
module.exports = ActivityDetailController;
