function plHttpFactory($http, $q, applications, plToast, plErrorMessage) {
    const http = {};

    const urls = {
        appointments: `${applications.apiWorkplace.url}/api/v3/appointments/`,
        apollo: `${applications.apiWorkplace.url}/graphql/v1/`,
        areasOfConcern: `${applications.apiWorkplace.url}/api/v1/areas-of-concern/`,
        assessments: `${applications.apiWorkplace.url}/api/v1/assessments/`,
        availabilitySettings: `${applications.apiWorkplace.url}/api/v2/availability/preferences/`,
        billingCodes: `${applications.apiWorkplace.url}/api/v1/billing_codes/`,
        clients: `${applications.apiWorkplace.url}/api/v1/clients/`,
        clientServices: `${applications.apiWorkplace.url}/api/v2/client-services/`,
        contactTypes: `${applications.apiWorkplace.url}/api/v1/contact-types/`,
        contacts: `${applications.apiWorkplace.url}/api/v1/contacts/`,
        directServices: `${applications.apiWorkplace.url}/api/v1/direct-services/`,
        documentTypes: `${applications.apiWorkplace.url}/api/v1/document-types/`,
        documents: `${applications.apiWorkplace.url}/api/v1/documents/`,
        ethnicities: `${applications.apiWorkplace.url}/api/v1/ethnicities/`,
        evaluations: `${applications.apiWorkplace.url}/api/v3/evaluations/`,
        events: `${applications.apiWorkplace.url}/api/v2/events/`,
        languages: `${applications.apiWorkplace.url}/api/v1/languages/`,
        locations: `${applications.apiWorkplace.url}/api/v1/locations/`,
        metrics: `${applications.apiWorkplace.url}/api/v1/metrics/`,
        metricsPoints: `${applications.apiWorkplace.url}/api/v1/metrics-points/`,
        notesSchemas: `${applications.apiWorkplace.url}/api/v1/notes/schemas/`,
        notesExports: `${applications.apiWorkplace.url}/api/v1/notes/exports/`,
        notesExportsOrganizations: `${applications.apiWorkplace.url}/api/v1/notes/exports/organizations/`,
        organizations: `${applications.apiWorkplace.url}/api/v1/organizations/`,
        providerTypes: `${applications.apiWorkplace.url}/api/v1/provider-types/`,
        providers: `${applications.apiWorkplace.url}/api/v1/providers/`,
        races: `${applications.apiWorkplace.url}/api/v1/races/`,
        records: `${applications.apiWorkplace.url}/api/v1/records/`,
        services: `${applications.apiWorkplace.url}/api/v1/services/`,
        serviceTypes: `${applications.apiWorkplace.url}/api/v1/service-types/`,
        upload: `${applications.apiWorkplace.url}/api/v1/upload/`,

        room: `${applications.platform.url}/storeapi/v1/room/`,
        roomResetWhiteboard: `${applications.platform.url}/storeapi/v1/room/reset_whiteboard/`,
    };

    http.formUrl = (urlKey, httpOptsUrl = false) => {
        if (httpOptsUrl) {
            return httpOptsUrl;
        }
        return (urlKey && urls[urlKey]) ? urls[urlKey] : false;
    };

    http.go = (httpOpts, urlKey = false) => {
        const deferred = $q.defer();

        // Set url
        httpOpts.url = http.formUrl(urlKey, httpOpts.url);
        if (!httpOpts.url) {
            deferred.reject('One of valid urlKey or httpOpts.url is required');
        } else {
            // Set content type since Angular does it wrong (for delete)
            if (!httpOpts.headers) {
                httpOpts.headers = {};
            }
            // http://stackoverflow.com/questions/24829933/angularjs-delete-with-data-sets-wrong-content-type-header
            if (!httpOpts.headers['Content-Type']) {
                httpOpts.headers['Content-Type'] = 'application/json';
            }
            $http(httpOpts)
                .then((res) => {
                    deferred.resolve(res);
                }, (err) => {
                    plToast.toastFailure(plErrorMessage.get(httpOpts.url, err.status, err));
                    deferred.reject(err);
                });
        }
        return deferred.promise;
    };

    http.put = (urlKey, data) => {
        const httpOpts = {
            data,
            method: 'PUT',
        };
        return http.go(httpOpts, urlKey);
    };

    http.save = (urlKey, data) => {
        const httpOpts = {
            data,
            method: data.uuid ? 'PATCH' : 'POST',
            url: data.uuid ? `${http.formUrl(urlKey)}${data.uuid}/` : null,
        };
        return http.go(httpOpts, urlKey);
    };

    http.get = (urlKey, data = {}) => {
        const url = http.formUrl(urlKey);
        const httpOpts = {
            method: 'GET',
            params: data,
            url: data.uuid ? `${url}${data.uuid}/` : url,
        };
        return http.go(httpOpts, urlKey);
    };

    http.delete = (urlKey, data) => {
        if (!data.uuid) {
            throw new Error('Delete requires uuid');
        }
        const httpOpts = {
            method: 'DELETE',
            url: data.uuid ? `${http.formUrl(urlKey)}${data.uuid}/` : null,
        };
        return http.go(httpOpts, urlKey);
    };

    return http;
}
plHttpFactory.$inject = ['$http', '$q', 'applications', 'plToast', 'plErrorMessage'];
module.exports = plHttpFactory;
