/**
 * Edit Rating directive
 *
 * @param $log
 * @param $document
 * @constructor
 */

var AddRatingDirective = function(reviewModel, gameReviewModel) {

    return {
        restrict: 'E',
        scope: {
            activity: "=",
            onAddReview: "&",
            review: "="
        },
        templateUrl: '/toychest/common/directives/addRating/addRating.tpl.html',
        link: function ($scope, $element, $attr) {
            var starContainer = $element.find('nav'),
                transitionReversed = false,
                hoverRating = 0,
                selectedRating = 0;
            $scope.stars = [1, 2, 3, 4, 5];

            var model = reviewModel;
            var fieldName = 'activity';
            if($scope.activity.type === 'game') {
                model = gameReviewModel;
                fieldName = 'game';
            }

            $scope.endHover = function() {
                transitionReversed = true;
                hoverRating = 0;
                setTimeout(function() {
                    transitionReversed = false;
                }, 300);
            };
            $scope.updateHoverLocation = function(event) {
                hoverRating = getRatingFromEvent(event);
            };
            $scope.selectRating = function() {
                selectedRating = hoverRating;
                $(this).parents('main').animate({
                    scrollTop: 500
                }, 400);
            };

            $scope.isRatingSelected = function() {
                return !!selectedRating;
            };

            $scope.submit = function() {
                var newReview;
                if($scope.editExisting) {
                    newReview = $scope.review;
                }else {
                    newReview = new model.Model();
                }
                newReview['title'] = $element.find('.reviewTitle').val();
                newReview['text'] = $element.find('.reviewDescription').val();
                newReview['rating'] = selectedRating;
                newReview[fieldName] = $scope.activity.resource_uri;
                newReview.$save().then(function() {
                    $element.hide();
                    $scope.onAddReview();
                });
            };

            $scope.delete = function() {
                if($scope.editExisting) {
                    $scope.review.$delete().then(function() {
                        $element.hide();
                        $scope.onAddReview();
                    });
                }
            };

            $scope.getStarButtonClasses = function(position) {
                var starVal = !!hoverRating ? hoverRating : selectedRating;
                const classes = {
                    'star-btn': true,
                    'transitionReversed': transitionReversed,
                    'selected': !!selectedRating && !hoverRating,
                    'half': starVal === position - 0.5,
                    'full': starVal >= position,
                };
                return classes;
            };

            function getRatingFromEvent(event) {
                let rating;
                var starNum = $(event.target).index() + 0.5;
                if (event.offsetX > $(event.target).outerWidth()/2) {
                    rating = starNum + 0.5;
                } else {
                    rating = starNum;
                }
                return rating;
            }

            if($scope.review) {
                $scope.editExisting = true;
                $element.find('.reviewTitle').val($scope.review.title);
                $element.find('.reviewDescription').text($scope.review.text);
                hoverRating = $scope.review.rating;
                $scope.selectRating();
            }
        }
    }
};

AddRatingDirective.$inject = ['reviewModel', 'gameReviewModel'];
module.exports = AddRatingDirective;
