var SharedCursorPanelDirective = function ($log, $compile, $timeout, firebaseModel, AssessmentModel, guidService, localStorageService) {
    return {
        restrict: 'AE',
        link: function ($scope, $element, $attr) {

            let mySharedCursor;

            // TODO - it looks like these "magic" constants may come from the size of a sonar ping. Rather than apply
            // the offsets to the hand, maybe they should apply to the sonar ping placing instead.
            const MY_X_OFFSET = 50;
            const MY_Y_OFFSET = 6;
            const falseValue = 'false';

            let myguid = undefined;
            let isHideCursor = true;

            $element.on('click', addSonar);
            $element.on('mousemove', moveMySharedCursor);
            $scope.$watch(() => $attr.sharedcursorpanel, (newValue) => {
                isHideCursor = newValue === falseValue;
                if (isHideCursor) {
                    mySharedCursor && $(mySharedCursor[0]).fadeOut();
                    return;
                }
                initialize();
            });

            function addSonar(event) {
                if (isHideCursor) {
                    return;
                }

                if (mySharedCursor === undefined) {
                    createCursor();
                }
                $timeout.cancel(timeoutPromise);

                var sonar = $compile("<sonar/>")($scope);
                let rect = $element.get(0).getBoundingClientRect();
                var x = event.clientX - rect.left;
                var y = event.clientY - rect.top;
                sonar.offset({left: x, top: y});
                $element.append(sonar);

                if (AssessmentModel.share) {
                    let percentX = x / $element[0].clientWidth;
                    let percentY = y / $element[0].clientHeight;
                    //sonar gets called a lot so let's do a sanity check to make sure the values don't break firebase.
                    if (isNaN(percentX) || isNaN(percentY)) {
                        $log.debug("Error: calculated percent values are NaN");
                        return;
                    }
                    $scope.sonarRef.update({lastclick: firebase.database.ServerValue.TIMESTAMP, percentX: percentX, percentY: percentY});
                } else {
                    $log.debug('not sharing sonar');
                }
            }

            function createCursor() {
                $scope.myCursorRef = firebaseModel.getFirebaseRef('activities/sessions/' + AssessmentModel.getSessionId() + "/" + AssessmentModel.activity.configId + "/cursor/" + myguid);
                $scope.myCursorRef.onDisconnect().remove();
                $scope.sonarRef = firebaseModel.getFirebaseRef('activities/sessions/' + AssessmentModel.getSessionId() + "/" + AssessmentModel.activity.configId + "/sonar/" + myguid);
                $scope.sonarRef.onDisconnect().remove();

                mySharedCursor = $compile("<sharedcursor guid=" + myguid + "/>")($scope);
                $element.append(mySharedCursor);
            }

            function moveMySharedCursor(e) {
                if (isHideCursor) {
                    return;
                }

                if (mySharedCursor === undefined) {
                    createCursor();
                }
                $(mySharedCursor[0]).show();

                let x = e.pageX - MY_X_OFFSET;
                let y = e.pageY - MY_Y_OFFSET;

                // Assessment audio controls are in the workspace but it's been requested that the shared cursor
                // not be rendered there, so we need to treat the controls as being the right edge of the workspace
                // when they are present.
                // TODO - seems expensive to test for this every time. Consider extending AssessementModel so that it
                // knows when we are in audio mode and need to test for the holder.
                const audioHolder = $('.audio-holder');
                if (audioHolder.length > 0) {
                    const offset = audioHolder.offset();
                    if (x > offset.left - MY_X_OFFSET) {
                        return;
                    }
                }
                $(mySharedCursor[0]).offset({top: y, left: x});

                $timeout.cancel(timeoutPromise);
                hideCursor();

                if (AssessmentModel.share) {
                    let rect = $element.offset();
                    let x = e.pageX - rect.left;
                    let y = e.pageY - rect.top;
                    let scaledX = x / $element[0].clientWidth;
                    let scaledY = y / $element[0].clientHeight;
                    sendCursorToFB(scaledX, scaledY);
                }else {
                    $log.debug('not sharing cursor');
                }
            };

            let timeoutPromise;
            var hideCursor = _.debounce(function () {
                timeoutPromise = $timeout(function () {
                    mySharedCursor && $(mySharedCursor[0]).fadeOut();
                }, 3000);
            }, 1000);


            var sendCursorToFB = _.throttle(function (newX, newY) {
                $scope.myCursorRef.update({x: newX, y: newY, lastMoved: firebase.database.ServerValue.TIMESTAMP});
            }, 100, {
                leading: true
            });

            function loadState() {
                $scope.cursorsRef = firebaseModel.getFirebaseRef('activities/sessions/' + AssessmentModel.getSessionId() + "/" + AssessmentModel.activity.configId + "/cursor");
                $scope.cursorsRef.on('child_added', addCursorRef, handleCursorError);

            }

            function addCursorRef(snapshot) {
                if(snapshot.val() ){
                    let sharedCursor = $compile("<sharedcursor guid=" + snapshot.key + "/>")($scope);
                    $element.append(sharedCursor);
                    sharedCursor.hide();
                }
            }

            function handleCursorError(error) {
                $log.debug("Shared cursor error:" + error.code);
            }

            function initialize() {
                myguid = localStorageService.get('user_guid');

                if (myguid === null) {
                    myguid = guidService.generateUUID();
                    localStorageService.set('user_guid', myguid);
                }

                loadState();
            };
            initialize();
        }
    }
};

SharedCursorPanelDirective.$inject = ['$log', '$compile', '$timeout', 'firebaseModel', 'AssessmentModel', 'guidService', 'localStorageService'];
module.exports = SharedCursorPanelDirective;
