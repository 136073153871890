var DynamicContentDirective = function($log, $compile, $timeout, activityModel, dispatcher) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl : '/toys/modules/activity/components/dynamic/dynamicContent.tpl.html',
        link: function ($scope, $element, $attr) {

            $log.debug("[DynamicContentDirective] link function()");

            /*******************************
             * Create components
             *******************************/
            var buildComponents = function(components, parentContainer) {
                if (components) {
                    var keys = _.keys(components);
                    for (var i = 0; i<keys.length; i++) {
                        var component = components[keys[i]];
                        createComponent(component, parentContainer);

                    }
                }
                $timeout(_.bind(function() {
                    dispatcher.dispatch('ComponentEvent-DynamicComponentsCreated', activityModel.activity.config.id, {data: activityModel.activity.config});
                }, this));
            };

            var container;

            var createContainerComponent = function(config) {

                //determine which element to use
                var rootType = config.svg;
                var containerTag;
                if (rootType == "svg") {
                    $log.debug("[DynamicContentDirective] building root svg container");
                    containerTag = "svg";
                }
                else if (rootType == "group") {
                    $log.debug("[DynamicContentDirective] building group svg container");
                    containerTag = "g";
                }
                else if (rootType == undefined || rootType == "none") {
                    $log.debug("[DynamicContentDirective] building with no container");
                    //TODO make sure this works with no container...
                }

                if (containerTag) {
                    var container = document.createElementNS("http://www.w3.org/2000/svg", containerTag);
                    if (containerTag == "svg") {
                        if (config.type == "root" && config.scale) {
                            //setup browser scaling...
                            container.setAttribute("viewBox", "0, 0, " + config.width + " , " + config.height);
                            container.setAttribute("preserveAspectRatio", "xMinYMin meet");
                            container.style.width = "100%";
                            container.style.height = "100%";
                        } else {
                            //otherwise use the explicit dimensions
                            container.setAttribute("ng-attr-width", "{{width}}");
                            container.setAttribute("ng-attr-height", "{{height}}");
                            container.setAttribute("ng-attr-x", "{{x}}");
                            container.setAttribute("ng-attr-y", "{{y}}");
                        }
                    }
                    container.setAttribute("xmlns", "http://www.w3.org/2000/svg");
                    var linkFn = $compile(container);
                    var newScope = $scope.$new();
                    newScope.width = config.width;
                    newScope.height = config.height;
                    newScope.x = config.x;
                    newScope.y = config.y;
                    var element = linkFn(newScope);
                    return element[0];
                }

            };

            var insertRootElement = function(element) {
                if (element) {
                    $log.debug("[DynamicContentDirective] inserting root element");
                    $element.append(element);
                }
            };


            var createComponent = function(config, parentContainer) {

                var type = config.type;
                if (type == "container") {
                    //TODO build the container
                    var component = createContainerComponent(config);
                    //TODO add it to the parent container
                    insertElement(component, parentContainer);
                    //TODO Generate all it's children
                    if (config.components) {
                        buildComponents(config.components, component);
                    }
                } else {
                    var newScope = $scope.$new();
                    var newElTmpl = _.template('<<%= tag %>></<%= tag %>>');
                    var newEl = $(newElTmpl({
                        tag:config.type
                    }));
                    newScope.data = _.clone(config);

                    var linkFn = $compile(newEl[0]);
                    var element = linkFn(newScope);
                    insertElement(element[0], parentContainer);
                }
            };

            var insertElement = function(element, parent) {
                if (element) {
                    $log.debug("[DynamicContentDirective] inserting element");
                    if (parent) {
                        parent.appendChild(element);
                    } else {
                        $element.append(element);
                    }
                }
            };


            /*******************************
             * Initialize
             *******************************/

            var initialize = function() {
                if (!$scope.initialized) {
                    $log.debug("[DynamicContentDirective] initialize");
                    if (activityModel.activity.config) {
                        $log.debug("[DynamicContentDirective] processing activity config...");
                        var rootContainer = createContainerComponent(activityModel.activity.config);
                        container = rootContainer;
                        insertRootElement(rootContainer);
                        buildComponents(activityModel.activity.config.components, container);
                    } else {
                        $log.debug("[DynamicContentDirective] no activity config found!");
                    }
                    $scope.initialized = true;
                    $log.debug("[DynamicContentDirective] initialize complete.");
                    dispatcher.dispatch('ComponentEvent-ComponentInitialized', activityModel.activity.configId, {data: activityModel.activity.config});
                }
            };
            initialize();

        }
    }
};

DynamicContentDirective.$inject = ['$log', '$compile', '$timeout', 'activityModel', 'dispatcher'];
module.exports = DynamicContentDirective;
