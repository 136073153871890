module.exports = angular.module('toys.activity.youtube', [])
	.controller('YoutubeController', require('./YoutubeController'))
	.controller('YoutubeDrawerController', require('./YoutubeDrawerController'))
    .directive('youtubeplayer', require('./YouTubeDirective'))
    .factory('youtubeloader', require('./YouTubeLoader'))

    .directive('youtubeActivity', require('./YoutubeActivityDirective'))
    .directive('youtubeActivityDrawer', require('./YoutubeActivityDrawerDirective'))
;

