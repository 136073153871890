var plTextOverflowDirective = function($timeout) {
    return {
        restrict: 'A',
        link: function(scope, element) {
            $timeout(function() {
                var textDiv = element.find('div');
                if(textDiv.prop('scrollHeight') > parseFloat(textDiv.css('max-height'))) {
                    element.addClass('overflows');
                }
            });
        }
    }
};

plTextOverflowDirective.$inject = ['$timeout'];
module.exports = plTextOverflowDirective;