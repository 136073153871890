function plRemoteLogger () {
  return {
    logResponseError: (response, msg='HTTP Response Error') => {
      Raven.captureException(new Error(msg), {
        extra: {
          config: response.config,
          status: response.status
        }
      });
    },
    log: (/*String*/message, /*obj*/detail) => {
      const extra = {extra: detail}
      Raven.captureMessage(message, extra);
    },
    setUserInfo: (detail) => {
      Raven.setExtraContext(detail);
    },
    setAppInfo: (detail) => {
      Raven.setExtraContext(detail);
    }
  }
}

plRemoteLogger.$inject = [];
module.exports = plRemoteLogger;
