var LiveagentService = function($log, $timeout, liveagent_conf, currentUserModel, remoteLogging) {
    var state = {
        chatAvailable : false
    };
    this.state = state;
    this.startChat = function() {
        if (state.chatAvailable && typeof liveagent !== 'undefined') {
            $timeout(function(){ // collides with youtube api $apply
                liveagent.startChat(liveagent_conf.buttonId);
            }, 1);
        }
    };

    var buttonCallback = function(e) {
        if (e == liveagent.BUTTON_EVENT.BUTTON_AVAILABLE) {
            state.chatAvailable = true;
        }
        if (e == liveagent.BUTTON_EVENT.BUTTON_UNAVAILABLE) {
            state.chatAvailable = false;
        }

        if (e == liveagent.BUTTON_EVENT.BUTTON_ACCEPTED) {
            // if you don't return true here then the chat popup fails
            return true;
        }

        if (e == liveagent.BUTTON_EVENT.BUTTON_REJECTED) {
        }

        $timeout(function(){});
        return false;
    };

    if (typeof liveagent !== 'undefined') {
        liveagent.addButtonEventHandler(liveagent_conf.buttonId, buttonCallback);
        if (currentUserModel.user != null) {
            liveagent.setName(currentUserModel.user.getName());
        } else {
            liveagent.setName('guest');
        }
        liveagent.init(liveagent_conf.endpoint, liveagent_conf.initArg1, liveagent_conf.initArg2);


        // without this, the callback doesn't fire
        liveagent.showWhenOnline(liveagent_conf.buttonId, null);
    } else {
        // Techcheck used this remote log: remoteLogging.logToSentry('liveagent undefined');
        $log.error('liveagent undefined in LiveagentService!');
    }
};
LiveagentService.$inject = ['$log', '$timeout', 'liveagent_conf', 'currentUserModel', 'remoteLogging'];
module.exports = LiveagentService;
