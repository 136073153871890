import ActivitiesController from '../activities/ActivitiesController';

class GamesController extends ActivitiesController {
    constructor($rootScope, $state, $injector, CurrentUser, ActivityTagModel, Room) {
        super($rootScope, $state, $injector, CurrentUser, ActivityTagModel, Room);
    }
}

GamesController.$inject = ['$rootScope', '$state', '$injector', 'CurrentUser', 'ActivityTagModel', 'Room'];
module.exports = GamesController;
