SelectActivityController.$inject = ['$scope', '$state'];

function SelectActivityController($scope, $state) {
    'use strict';

    $scope.activityTypes = [{
        type: 'video',
        title: 'Video',
        state: 'app.store.activities.create.video.select'
    }, {
        type: 'document',
        title: 'Document',
        state: 'app.store.activities.create.document.upload'
    }, {
        type: 'flashcards',
        title: 'Flashcards',
        state: 'app.store.activities.create.flashcards.select',
        params: {
            slug: '',
            mode: 'create-flashcard',
            type: 'flashcards'
        }
    }, {
        type: 'memory',
        title: 'Memory',
        state: 'app.store.activities.create.flashcards.select',
        params: {
            slug: '',
            mode: 'create-memory',
            type: 'memory'
        }
    }];

    $scope.activityCreationList = [];

    $scope.panelNavigation.current = 'select-activity';
    $scope.setSelectActivityPanels();

    $scope.selectActivityToCreate = function(activityType) {
        if (activityType.params) {
            $state.go(activityType.state, activityType.params);
        } else {
            $state.go(activityType.state);
        }
    };
}

module.exports = SelectActivityController;