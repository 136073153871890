function plBrowserCheckOverlay(plBrowser, plOverlayModal, $timeout) {
    return {
        restrict: 'E',
        scope: {
        },
        replace: true,
        templateUrl: '/core/pl-modules/src/plBrowser/plBrowserCheckOverlay/plBrowserCheckOverlay.tpl.html',
        link: ($scope) => {
            $scope.modalId = "browserCheckOverlay";
            const supportedBrowser = plBrowser.isSupported();
            if (!supportedBrowser) {
                // Without timeout the modal does not show - not loaded / ready yet.
                $timeout(() => {
                    plOverlayModal.show($scope.modalId);
                }, 1000);
            }
        },
    };
}

plBrowserCheckOverlay.$inject = ['plBrowser', 'plOverlayModal', '$timeout'];
module.exports = plBrowserCheckOverlay;
