/**
 * AssessmentPDFContentDirective controls the loading and rendering of assessment pdf's
 */
var AssessmentPDFContentDirective = function ($log,
                                              $q,
                                              $window,
                                              $timeout,
                                              $stateParams,
                                              AssessmentModel,
                                              dispatcherService,
                                              guidService) {
    return {
        restrict: "E",
        templateUrl: '/core/toys/app/src/toys/modules/assessment/assessmentpdfcontent/assessmentpdfcontent.tpl.html',
        link: function ($scope, element, attrs) {

            var w = angular.element('.workspace');
            let ready = false;
            let visibilityHandler = false;
            const TOOLBAR_HEIGHT = 60;
            const UUID = guidService.generateUUID();
            const pdfHolderClass = '.pdf-holder';

            var resize = _.debounce(function () {
                var newWidth = AssessmentModel.share ? w.width() : w.width() - DRAWER_WIDTH; //drawer width;
                var newHeight = AssessmentModel.share ? w.height() - TOOLBAR_HEIGHT : w.height();

                render();
                if (AssessmentModel.channel) {
                    AssessmentModel.channel.call({
                        method: 'selectPage',
                        params: $scope.currentPage
                    });
                }
            }, 333);
            angular.element($window).on('resize', resize);

            $scope.$watch(() => sessionStorage.getItem('activeDrawer'), resize);

            let scale; // initialized in FB CB
            let scrollXPercent = 0; // initialized in FB CB
            let scrollYPercent = 0; // initialized in FB CB

            let holder = element.find(pdfHolderClass);

            const DEFAULT_PAGE_NUM = 1;
            const DEFAULT_ROTATION_DEG = 0;

            const DEFAULT_SCALE = 1.0;
            const DRAWER_WIDTH = AssessmentModel.share ? 0 : 192;

            let scrollOffset = {
                x: null,
                y: null
            };

            let updatingScrollFromFb = false;

            function initialize() {
                var descriptor = JSON.parse(AssessmentModel.configModel.descriptor);

                var pdf_url = descriptor['url'];
                $scope.currentIndex = 1;

                var pages = descriptor['pages'];

                _.each(pages, function (page) {
                    $scope.studentPages.push(page.stimulus);
                    if (page.instructions !== undefined) {
                        $scope.isDoubleSided = true;
                        $scope.clinicianPages.push(page.instructions);
                    }
                });

                $scope.totalNumPages = $scope.studentPages.length;

                var holder = element.find(pdfHolderClass);
                holder.on('scroll', scrollPage);

                loadPDF(pdf_url);
                //bind to methods
            }

            $scope.studentPages = [];
            $scope.clinicianPages = [];
            $scope.loading = false;
            $scope.currentZoom = 1.0;
            $scope.offsetX = 0.0;
            $scope.offsetY = 0.0;
            $scope.currentPage = 1;
            $scope.currentIndex = 0;
            $scope.totalNumPages = null;
            $scope.showInstructions = false;
            $scope.isDoubleSided = false;



            let canvas = element.find('canvas');
            let context = canvas[0].getContext('2d');
            let pdf = null;

            let instructionsPage = null;
            let instructionsLoading = false;
            let instructionCanvas = element.find('.instructionsCanvas');
            let instructionsContext = instructionCanvas[0].getContext('2d');

            var updateZoom = function (value) {
                $scope.currentZoom = value;

                if ($scope.options.currentPageObject) {
                    var viewport = $scope.options.currentPageObject.getViewport($scope.currentZoom);

                    $scope.holderWidth = viewport.width;
                    $scope.holderHeight = viewport.height - 10 * $scope.currentZoom;
                }

                if (AssessmentModel.share) {
                    AssessmentModel.saveZoom($scope.currentZoom);
                }
            };

            AssessmentModel.foundationLoaded.then(function () {
                initialize();
                setupDrawerCalls();
                AssessmentModel.channel.bind('changeZoomValue', function (context, params) {
                    $scope.currentZoom = params[0];

                    if ($scope.currentZoom < 1.001) {
                        $scope.offsetX = 0;
                        $scope.offsetY = 0;

                        $timeout(function () {
                        });
                    }

                    if (AssessmentModel.share) {
                        AssessmentModel.saveZoom($scope.currentZoom);
                    }

                    render();
                });
            });

            $scope.isDragged = false;
            $scope.onMouseMove = function (event) {
                if ($scope.isDragged) {
                    var deltaY = scrollOffset.y - event.clientY;
                    var deltaX = scrollOffset.x - event.clientX;
                    positionPage(deltaX, deltaY);
                    saveScrollPosition(deltaX, deltaY);
                    $timeout(function () {
                    });
                }
            };

            $scope.onMouseUp = function () {

                if ($scope.isDragged) {
                    scrollOffset.x = null;
                    scrollOffset.y = null;
                    $timeout(function () {
                    });
                    $scope.isDragged = false;
                }
            };

            $scope.contentOnMouseDown = function (event) {

                scrollOffset.y = holder.scrollTop() + event.clientY;
                scrollOffset.x = holder.scrollLeft() + event.clientX;
                $scope.isDragged = true;
            };

            $scope.setInstructionsVisible = function (value) {
                if (instructionsLoading) {
                    return;
                }
                $scope.showInstructions = value;
                if (pdf) {
                    if ($scope.showInstructions) {
                        var instructionsPageNum = $scope.clinicianPages[$scope.currentIndex];
                        if (instructionsPageNum) {
                            try {
                                pdf.getPage(instructionsPageNum).then(function (p) {
                                    $log.debug('[AssessmentPDFContentDirective] retrieved instructions page');
                                    instructionsLoading = true;
                                    instructionsPage = p;
                                    renderInstructions();
                                });
                            } catch (error) {
                                $log.debug('[AssessmentPDFContentDirective] instructions getPage failed');
                            }

                        }
                    } else {
                        updatePdfHolderOpacity(1.0);
                    }
                }
                $timeout(function () {
                }, 0);
            };

            function loadPDF(key) {
                $scope.secureUrl = AssessmentModel.getProtectedContentUrl(key).then( (data) => {
                    let pdf_url = data.assets[key];
                    $scope.loadingTask = PDFJS.getDocument(pdf_url);
                    $scope.loadingTask.then(function (loaded_pdf) {
                        pdf = loaded_pdf;
                        loadState().then(handleLoadComplete);
                    });
                });
            }

            // Page Changing
            $scope.next = function () {
                if (!$scope.loading && $scope.currentIndex < $scope.totalNumPages - 1) {
                    $scope.currentIndex++;
                    $scope.currentPage = $scope.studentPages[$scope.currentIndex];
                    $scope.assessmentContentGoToPage($scope.currentPage);
                }
                return $scope.currentIndex;
            };

            $scope.jumpTo = function (pageRequest) {

                var newIndex = Math.min($scope.totalNumPages, pageRequest);
                $scope.currentIndex = Math.max(0, newIndex - 1);

                var newPage = $scope.studentPages[$scope.currentIndex];

                $scope.assessmentContentGoToPage(newPage);

                return $scope.currentIndex;
            };

            $scope.prev = function () {
                if (!$scope.loading && $scope.currentIndex > 0) {
                    $scope.currentIndex--;
                    $scope.currentPage = $scope.studentPages[$scope.currentIndex];
                    $scope.assessmentContentGoToPage($scope.currentPage);
                }

                return $scope.currentIndex;
            };

            $scope.assessmentContentGoToPage = function (pageNum) {

                if (AssessmentModel.share) {
                    AssessmentModel.savePage({
                        currentPage: pageNum,
                        UUID: UUID
                    });
                    //when we switch pages, clear any existing scroll positions
                    AssessmentModel.saveScrollPosition(0, 0);
                }
                gotoPageLocal(pageNum);
            };

            function gotoPageLocal(pageNum) {
                $scope.currentPage = pageNum;
                $scope.currentIndex = $scope.studentPages.findIndex(x => x === $scope.currentPage);
                setLoading(true);
                var resolved = function (p) {
                    $scope.options.currentPageObject = p;
                    resize();
                };
                var rejected = function (error) {
                    $log.debug('[AssessmentPDFContentDirective] error going to pdf page (rejected)');
                };

                if (pdf) {
                    try {
                        pdf.getPage(pageNum).then(resolved, rejected);
                    } catch(error) {
                        //if the pdf was destroyed (e.g. user left) after loading pdfjs will throw errors internally.
                    }
                }
            }

            function positionPageX(x) {
                var holder = element.find(pdfHolderClass);
                holder.scrollLeft(x);
            }

            function positionPageY(y) {
                var holder = element.find(pdfHolderClass);
                holder.scrollTop(y);
            }

            function positionPage(x, y) {
                positionPageX(x);
                positionPageY(y);
            }

            function positionPageXFromPercentage(xPercent) {
                positionPageX(xPercent * canvas.width());
            }

            function positionPageYFromPercentage(yPercent) {
                positionPageY(yPercent * canvas.height());
            }

            function positionPageFromPercentage(xPercent, yPercent) {
                positionPageXFromPercentage(xPercent);
                positionPageYFromPercentage(yPercent);
            }

            function calcScale(pdfW, pdfH, containerW, containerH, scale) {
                var scaleX = containerW / pdfW;
                var scaleY = containerH / pdfH;
                var newScale = Math.min(scaleX, scaleY) * scale;
                return newScale;
            }

            $scope.options = {currentPageObject: undefined};

            function visibilityRender() {
                if (document.hidden) {
                    return;
                }
                render();
                visibilityHandler = false;
                document.removeEventListener("visibilitychange", visibilityRender, false);
            }

            function render() {
                if (_.isUndefined($scope.currentPage)
                    || !pdf
                    || _.isUndefined($scope.options.currentPageObject)) {
                    return;
                }

                if (document.hidden) {
                    if (!visibilityHandler) {
                        visibilityHandler = true;
                        document.addEventListener("visibilitychange", visibilityRender, false);
                    }
                    return;
                }
                var page = $scope.options.currentPageObject;

                if (!holder.length) {
                    holder = element.find(pdfHolderClass);
                }
                var viewport = page.getViewport(1);
                let viewportScale = calcScale(viewport.width,
                    viewport.height,
                    holder.width(),
                    holder.height(),
                    $scope.currentZoom);
                viewport = page.getViewport(viewportScale);

                canvas = element.find('.pdf-holder-canvas');
                canvas[0].width = 0;
                canvas[0].width = viewport.width;
                canvas[0].height = viewport.height - 10 * $scope.currentZoom;
                context = canvas[0].getContext('2d');

                var renderContext = {
                    canvasContext: context,
                    viewport: viewport
                };

                page.render(renderContext).promise.then(function () {
                    $log.debug("[AssessmentPDFContentDirective] render complete?");
                    setLoading(false);
                    $timeout(function () {
                        $scope.deferPage.resolve();
                    });
                });

                $scope.setInstructionsVisible(AssessmentModel.getShowInstructions());
            }

            var instructionsHolder = element.find('.instructions-holder');

            function renderInstructions() {
                var viewport = instructionsPage.getViewport(1.0);

                var maxInstructionsWidth = instructionsHolder.width() - instructionCanvas[0].offsetLeft;

                var newScale = calcScale(viewport.width,
                    viewport.height,
                    maxInstructionsWidth,
                    instructionsHolder.height(),
                    1.0);
                viewport = instructionsPage.getViewport(newScale);

                if (viewport.width > maxInstructionsWidth) {
                    instructionCanvas.attr('width', maxInstructionsWidth);
                } else {
                    instructionCanvas.attr('width', viewport.width);
                }

                instructionCanvas.attr('height', viewport.height);

                var instructions = instructionsHolder[0];
                $(instructions).css('top', "0px");
                $(instructions).css('left', "0px");
                updateOpacity(AssessmentModel.getInstructionOpacity());

                var renderContext = {
                    canvasContext: instructionsContext,
                    viewport: viewport
                };
                instructionsPage.render(renderContext).promise.then(function () {
                    setLoading(false);
                    instructionsLoading = false;
                    $timeout(function () {
                    });
                });
            }

            function calculatePagePercentY() {
                var holder = element.find(pdfHolderClass);
                return holder.scrollTop() / canvas.height();
            }

            function calculatePagePercentX() {
                var holder = element.find(pdfHolderClass);
                return holder.scrollLeft() / canvas.width();
            }

            function saveScrollPosition() {
                if (AssessmentModel.share && !updatingScrollFromFb) {

                    scrollYPercent = calculatePagePercentY();
                    scrollXPercent = calculatePagePercentX();
                    AssessmentModel.saveScrollPosition(scrollXPercent, scrollYPercent);
                }
                updatingScrollFromFb = false;
            }

            let scrollPage = _.debounce(function (e) {

                saveScrollPosition(e.currentTarget.scrollLeft, e.currentTarget.scrollTop);
            }, 20);

            function loadState() {

                $scope.deferScrollX = $q.defer();
                $scope.deferScrollY = $q.defer();
                $scope.deferPage = $q.defer();

                dispatcherService.addListener('pageChangeEvent', null, $scope.handlePageChange, this);
                if (AssessmentModel.canUserAccessInstructions()) {
                    dispatcherService.addListener('opacityChangeEvent', null, $scope.handleOpacityChange, this);
                    dispatcherService.addListener('instructionsChangeEvent', null, $scope.handleInstructionsChange, this);
                }
                dispatcherService.addListener('zoomChangeEvent', null, $scope.handleZoomChange, this);
                dispatcherService.addListener('offsetChangeEvent', null, $scope.handleOffsetChange, this);
                dispatcherService.addListener('scrollXChangeEvent', null, $scope.handleScrollXChange, this);
                dispatcherService.addListener('scrollYChangeEvent', null, $scope.handleScrollYChange, this);

                AssessmentModel.loadState();

                if (!AssessmentModel.share) {
                    $scope.jumpTo($scope.currentIndex);
                }
                return $q.all([$scope.deferScrollX.promise, $scope.deferScrollY.promise, $scope.deferPage.promise]);
            }

            function handleLoadComplete() {
                $log.debug("[AssessmentPDFContentDirective] handleLoadComplete");
                if (isCanvasReady()) {
                    positionPageFromPercentage(AssessmentModel.getScrollXPercent(), AssessmentModel.getScrollYPercent());
                    if (!$scope.isDoubleSided) {
                        updatePdfHolderOpacity(1.0);
                    }
                }
            }

            $scope.handleScrollXChange = function (eventType, event) {
                updatingScrollFromFb = true;
                if (isCanvasReady()) {
                    const xPercent = event.data;
                    positionPageXFromPercentage(xPercent);
                }
                $scope.loading = false;
                $scope.deferScrollY.resolve();
            };

            $scope.handleScrollYChange = function (eventType, event) {
                updatingScrollFromFb = true;
                if (isCanvasReady()) {
                    const yPercent = event.data;
                    positionPageYFromPercentage(yPercent);
                }
                $scope.loading = false;
                $scope.deferScrollX.resolve();
            };

            $scope.handleZoomChange = function (eventType, event) {
                if (event.data) {
                    if (event.data !== $scope.currentZoom) {
                        updateZoom(event.data);

                        if ($scope.currentZoom < 1.001) {
                            $scope.offsetX = 0;
                            $scope.offsetY = 0;

                            $timeout(function () {
                            });
                        }
                        render();
                    }
                }
            };

            $scope.handleOpacityChange = function(eventType, event) {
                $log.debug('[AssessmentPDFContentDirective] handling remote opacity change: ' + event.data);
                if (event.data !== null && AssessmentModel.getShowInstructions()) {
                    //update view using instructions opacity if instructions are turned on
                    updateOpacity(event.data);
                }
            };

            $scope.handleInstructionsChange = function(eventType, event) {
                $log.debug('[AssessmentPDFContentDirective] handling remote instructions change: ' + event.data);
                $scope.setInstructionsVisible(event.data);
            };

            function updateOpacity(value) {
                $log.debug('[AssessmentPDFContentDirective] updating instruction opacity: ' + value);
                //update view
                updateInstructionHolderOpacity(value);
                //the pdf opacity is related to the instructions opacity according to the linear equation:
                // pdfOpacity = -0.96 * instructionsOpacity + 1
                let pdfValue = (-0.96 * value) + 1;
                updatePdfHolderOpacity(pdfValue);
            };

            function updateInstructionHolderOpacity(value) {
                $log.debug('[AssessmentPDFContentDirective] setting instructions holder opacity to: ' + value);
                instructionsHolder.css('opacity', value);
            }

            function updatePdfHolderOpacity(value) {
                $log.debug('[AssessmentPDFContentDirective] setting pdf holder opacity to: ' + value);
                holder.css('opacity', value);
            }

            $scope.handleOffsetChange = function (eventType, event) {
                if (event.data) {
                    $scope.offsetX = event.data.x;
                    $scope.offsetY = event.data.y;
                }
            };

            $scope.handlePageChange = function (eventType, event) {
                let data = event.data;
                if (data) {
                    if (data.UUID && data.UUID === UUID) {
                        return; // self-invoked, so just skip it
                    }
                    let currentPage = data.currentPage;
                    if (currentPage !== undefined && currentPage !== $scope.currentPage) {
                        gotoPageLocal(currentPage);
                        ready = true;
                        return;
                    }

                    if (ready) {
                        return;
                    }
                }

                if ($scope.studentPages.length > 0) {
                    $scope.currentPage = $scope.studentPages[0];
                } else {
                    $scope.currentPage = 1;
                }
                gotoPageLocal($scope.currentPage);
                ready = true;
            };

            // catch all for errors.
            $scope.handleActivityError = function (error) {
                $log.debug("[AssessmentPDFContentDirective] activity ref load error:" + error.code);
            };
            $scope.$on('$destroy', () => {
                $log.debug("[AssessmentPDFContentDirective] destroy");
                AssessmentModel.setShowInstructions(false);
                cleanup(pdf);
            });

            function setupDrawerCalls() {
                AssessmentModel.channel.bind('next', function () {
                    return $scope.next();
                });

                AssessmentModel.channel.bind('prev', function () {
                    return $scope.prev();
                });

                AssessmentModel.channel.bind('jumpTo', function (context, params) {
                    return $scope.jumpTo(params);
                });

            }

            function setLoading(value) {
                $scope.loading = value;
                $timeout(function () {
                });
            }

            function isCanvasReady() {
                //simple test for width. we never draw a zero width pdf canvas.
                if (canvas.width() === 0)
                    return false;
                else
                    return true;
            }

            /*******
             * Clean up a loaded pdf or if not loaded yet, destroy the loader. This may cause some rte's in the
             * console especially when the loader is aborted but memory should be released, future networks calls
             * aborted, and subsequent pdf's should load as normal including the instructions.
             * @param pdf
             */
            function cleanup(pdf) {
                if (pdf != null) {
                    try {
                        $log.debug("[AssessmentPDFContentDirective] pdf cleaning up");
                        pdf.cleanup();
                        pdf.destroy().then(function() {
                            $log.debug("[AssessmentPDFContentDirective] pdf destroy complete.");
                        });
                    } catch (error) {
                        //pdfjs throws errors when it interrupts loading on a destroy or cleanup
                        $log.debug(error);
                    }
                }
                if ($scope.loadingTask && !$scope.loadingTask.destroyed) {
                    try {
                        $log.debug("[AssessmentPDFContentDirective] pdf loader destroy");
                        $scope.loadingTask.destroy().then(function() {
                            $log.debug("[AssessmentPDFContentDirective] pdf loader destroy complete.");
                            $scope.loadingTask = null;
                        });
                    } catch (error) {
                        //pdfjs throws errors when it interrupts loading on a destroy or cleanup
                        $log.debug(error);
                    }
                }
            }
        }
    }
};

AssessmentPDFContentDirective.$inject = ['$log',
    '$q',
    '$window',
    '$timeout',
    '$stateParams',
    'AssessmentModel',
    'dispatcherService',
    'guidService'];

module.exports = AssessmentPDFContentDirective;