var SpinnerDirective = function($log, $document) {
    return {
        restrict: 'E',
        scope : {
            active: '='
        },
        templateUrl: '/core/pl-core/src/directives/spinner/spinner.tpl.html',
        link: function ($scope, $element, $attr) {
            if($scope.active == undefined){
                $scope.active = true;
            }
            // $scope.$watch('active', function(){
            //     console.log('Active: ' + $scope.active);
            // })
        }
    }
};

SpinnerDirective.$inject = ['$log', '$document'];
module.exports = SpinnerDirective;
