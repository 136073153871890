var GameResolver = function(drfGameModel) {
    this.resolve = function(params) {
        var game = new drfGameModel.Model();
        var promise = game.$getViaSlug(params.slug);
        return promise;
    };
    return this;
};
GameResolver.$inject = ['drfGameModel'];
module.exports = GameResolver ;
