var ActivityPreviewDirective = function($sce) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            activity: '='
        },
        templateUrl: '/toychest/common/directives/activityPreview/activityPreview.tpl.html',
        link: function($scope) {

            if ($scope.type === 'video') {
                $scope.videoUrl = $sce.trustAsResourceUrl('https://www.youtube.com/embed/' + JSON.parse($scope.activity.descriptor).videoId + '?rel=0');
            }

            $scope.getActivityPreviewClasses = function() {
                return {
                    'activity-preview': true,
                    'video': $scope.type === 'video',
                    'document': $scope.type === 'document'
                };
            };

            $scope.getActivityType = function() {
                if(!$scope.activity) return;
                var types = {
                    'youtube': 'video',
                    'pdfviewer': 'document'
                };
                return types[$scope.activity.activity_type];
            };
        }
    };
};

ActivityPreviewDirective.$inject = ['$sce'];
module.exports = ActivityPreviewDirective;