function plClientServicesFactory(plHttp, $q, plFileAmazon, plServiceDocuments,
 plLodash, plTimezone) {
    const plClientServices = {};
    const _plClientServices = {};
    let _clientServices = [];
    let _serviceLabelMap = {
        consultation_bmh: 'BMH Consultation',
        direct_bmh: 'BMH Direct',
        consultation_ot: 'OT Consultation',
        direct_ot: 'OT Direct',
        consultation_slt: 'SLT Consultation',
        direct_slt: 'SLT Direct',
    };

    _plClientServices.filterActive = (clientServices, activeOnly, excludeLocked) => {
        if (activeOnly) {
            clientServices = clientServices.filter((clientService) => {
                return clientService.is_active;
            });
        }
        if (excludeLocked) {
            clientServices = clientServices.filter((clientService) => {
                return !clientService.locked;
            });
        }
        return clientServices;
    };

    plClientServices.get = (data = {}, getDocuments = false, activeOnly = true, excludeLocked = true) => {
        const deferred = $q.defer();

        plHttp.get('clientServices', data)
            .then((res) => {
                if (res.data && !res.data.results) {
                    res.data.results = [res.data];
                }
                res.data.results = plClientServices.formatFromBackend(res.data.results);
                if (getDocuments) {
                    plClientServices.getDocuments(res.data.results)
                        .then((resClientServices) => {
                            _clientServices = _plClientServices.filterActive(resClientServices, activeOnly, excludeLocked);
                            deferred.resolve(_clientServices);
                        });
                } else {
                    _clientServices = _plClientServices.filterActive(res.data.results, activeOnly, excludeLocked);
                    deferred.resolve(_clientServices);
                }
            }, (err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    };

    plClientServices.getDocuments = (clientServices) => {
        const deferred = $q.defer();

        const promises = [];
        const deferreds = [];
        const clientServicesOutput = [];
        let httpUrl;
        let httpOpts;
        clientServices.forEach((clientService, index) => {
            deferreds[index] = $q.defer();
            promises.push(deferreds[index].promise);
            if (clientService.type === 'evaluation') {
                httpUrl = `${plHttp.formUrl('evaluations')}${clientService.uuid}/documents/`;
                httpOpts = {
                    url: httpUrl,
                    method: 'GET',
                };
                plHttp.go(httpOpts)
                    .then((resDocs) => {
                        if (resDocs.data && resDocs.data.results) {
                            clientService.documents_expanded = resDocs.data.results;
                        }
                        clientServicesOutput[index] = clientService;
                        deferreds[index].resolve();
                    });
            } else {
                clientServicesOutput[index] = clientService;
                deferreds[index].resolve();
            }
        });

        $q.all(promises)
            .then(() => {
                deferred.resolve(clientServicesOutput);
            }, (err) => {
                deferred.reject(err);
            });

        return deferred.promise;
    };

    plClientServices.getOne = (data = {}, getDocuments = true) => {
        const deferred = $q.defer();
        plClientServices.get(data)
            .then((clientServices) => {
                if (getDocuments) {
                    plClientServices.getDocuments(clientServices)
                        .then((resClientServices) => {
                            deferred.resolve(resClientServices[0]);
                        });
                } else {
                    deferred.resolve(clientServices[0]);
                }
            });
        return deferred.promise;
    };

    plClientServices.formatFromBackend = (clientServices) => {
        return clientServices.map((clientService) => {
            // If details, just pull that out and use it.
            if (clientService.details) {
                let newService = Object.assign({}, clientService, clientService.details);
                delete newService.details;
                return newService;
            } else {
                return clientService;
            }
        });
    };

    plClientServices.formSelectOpts = (clientServices, skipEnded = true, recordEnd = moment()) => {
        clientServices = plClientServices.filterSelectOpts(clientServices, skipEnded, recordEnd);
        return clientServices.map((clientService) => {
            return { value: clientService.uuid, label: plClientServices.formSelectLabel(clientService) };
        });
    };

    plClientServices.formSelectLabel = (clientService) => {
        const start = clientService.start_date ? moment(clientService.start_date, plTimezone.dateTimeFormat)
         .format('MM/YYYY') : '';
        const end = clientService.end_date ? moment(clientService.end_date, plTimezone.dateTimeFormat)
         .format('MM/YYYY') : '';
        const time = (start && end) ? `${start} - ${end}` : (start) ? `${start} - no end date` : '';
        const label = _serviceLabelMap[clientService.service_expanded.code] ?
         _serviceLabelMap[clientService.service_expanded.code] : clientService.service_expanded.name;
        return `${label} ${time}`;
    };

    plClientServices.filterSelectOpts = (clientServices, skipEnded = true, recordEnd = moment()) => {
        return clientServices.filter((clientService) => {
            // if end date of record is later than end date of client service, don't show that client service
            return !(skipEnded && clientService.end_date && moment(recordEnd).utc().startOf('day').isAfter(moment(clientService.end_date), 'day'));
        });
    };

    plClientServices.getInfo = (uuid) => {
        const index = plLodash.findIndex(_clientServices, 'uuid', uuid);
        return (index > -1) ? _clientServices[index] : null;
    };

    plClientServices.saveAll = (clientServices) => {
        const deferred = $q.defer();

        const promises = [];
        const deferreds = [];
        clientServices.forEach((clientService, index) => {
            deferreds[index] = $q.defer();
            promises.push(deferreds[index].promise);
            plClientServices.saveOne(clientService)
                .then(() => {
                    deferreds[index].resolve();
                }, (err) => {
                    deferreds[index].reject();
                });
        });

        $q.all(promises)
            .then(() => {
                deferred.resolve();
            }, (err) => {
                deferred.reject(err);
            });

        return deferred.promise;
    };

    plClientServices.saveOne = (clientService) => {
        const deferred = $q.defer();

        if (clientService.type === 'evaluation') {
            const evaluation = plLodash.pick(clientService, ['uuid', 'areas_of_concern', 'status', 'assessments_used', 'service']);
            plHttp.save('evaluations', evaluation)
            .then((res) => {
                deferred.resolve(res.data);
            }, (err) => {
                deferred.reject(err);
            });
        } else {
            // const msg = 'Only evaluations may be updated.';
            // deferred.reject({ msg: msg });
            deferred.resolve({});
        }

        return deferred.promise;
    };

    plClientServices.save = (clientService, type) => {
        const deferred = $q.defer();

        let key = null;
        if (type === 'evaluation') {
            key = 'evaluations';
        } else if (type === 'directService') {
            key = 'directServices';
        }
        if (key) {
            plHttp.save(key, clientService)
                .then((res) => {
                    deferred.resolve(res.data);
                }, (err) => {
                    deferred.reject(err);
                });
        } else {
            deferred.resolve({});
        }

        return deferred.promise;
    };

    plClientServices.uploadAllFiles = (clientServices) => {
        const deferred = $q.defer();

        const promises = [];
        const deferreds = [];
        clientServices.forEach((clientService, index) => {
            deferreds[index] = $q.defer();
            promises.push(deferreds[index].promise);
            if (clientService.files && clientService.files.length) {
                plFileAmazon.uploadBulk(clientService.files, clientService.awsInfo.url,
                 clientService.awsInfo.fields, `${index}_`)
                    .then((resFiles) => {
                        clientService.resFiles = resFiles;
                        deferreds[index].resolve();
                    });
            } else {
                deferreds[index].resolve();
            }
        });
        $q.all(promises)
            .then(() => {
                deferred.resolve(clientServices);
            }, (err) => {
                deferred.reject(err);
            });

        return deferred.promise;
    };

    plClientServices.saveAllFiles = (clientServices) => {
        const deferred = $q.defer();

        plClientServices.uploadAllFiles(clientServices)
            .then((resClientServices) => {
                const promises = [];
                const deferreds = [];

                resClientServices.forEach((clientService, index) => {
                    deferreds[index] = $q.defer();
                    promises.push(deferreds[index].promise);
                    plClientServices.saveFiles(clientService)
                        .then(() => {
                            deferreds[index].resolve();
                        });
                });

                $q.all(promises)
                    .then(() => {
                        deferred.resolve();
                    }, (err) => {
                        deferred.reject(err);
                    });
            });

        return deferred.promise;
    };

    plClientServices.saveFiles = (clientService, docType = 'evaluation_report', signedOn = null) => {
        const deferred = $q.defer();

        if (!clientService.resFiles || !clientService.resFiles.length) {
            deferred.resolve();
        } else {
            const promises = [];
            const deferreds = [];
            clientService.resFiles.forEach((resFile, index) => {
                deferreds[index] = $q.defer();
                promises.push(deferreds[index].promise);
                plClientServices.saveOneFile(clientService, resFile, docType, signedOn)
                    .then(() => {
                        deferreds[index].resolve();
                    }, (err) => {
                        deferreds[index].reject();
                    });
            });
            $q.all(promises)
                .then(() => {
                    deferred.resolve();
                }, (err) => {
                    deferred.reject(err);
                });
        }

        return deferred.promise;
    };

    plClientServices.saveOneFile = (clientService, resFile, docType, signedOn = null) => {
        const deferred = $q.defer();

        const doc = {
            client_service: clientService.uuid,
            file_path: resFile.key,
            client: clientService.client,
        };
        if (signedOn) {
            doc.signed_on = signedOn;
        }
        plServiceDocuments.save(doc, docType)
            .then(() => {
                deferred.resolve();
            }, (err) => {
                deferred.reject(err);
            });

        return deferred.promise;
    };

    return plClientServices;
}
plClientServicesFactory.$inject = ['plHttp', '$q', 'plFileAmazon', 'plServiceDocuments',
 'plLodash', 'plTimezone'];
module.exports = plClientServicesFactory;
