/**
 * An app feature or page context (in a directive) may have
 * specific business logic that can be expressed in unit functions.
 * The business object serves as a common pattern and consolidation
 * point for business logic that can be used in directives or templates.
 */
function PsychAssessmentBO (API, plRecordSPI) {
  const bo = {};
  const data = bo.data = {};

  // NOTA BENE: Activities and Eval Activities are different entities
  //
  // The available ACTIVITIES to choose from are in v1 APIs,
  // and they each have a uuid - activity.uuid, and these are known
  // as the "Activities" or "Assessment Components".
  //
  // Activities can be added to an Evaluation, and those comprise the
  // "Evaluation Activities" or "Evaluation Assessment Components".
  //
  // When retrieving the set of Eval Activities, each of those
  // have their own uuid.
  //
  // Don't confuse an "Eval Activity" and "Activity".
  //
  // EVAL_ACTIVITY is to ACTIVITY
  //   as
  // CLIENT_SERVICE is to SERVICE
  //
  // An EVAL_ACTIVITY is in the v3 APIs and represents a relation between
  // a clientService (eval) and an assessmentComponent (activity)

  // ====================
  // ===== API Data =====
  // ====================

  /**
   * Get the static list of activities that can be added to an evaluation.
   * GET: /api/v1/activities/
   */
  bo.getActivities = async () => {
    const response = await API.activities.get({expand: 'details'}).$promise;
    data.activities = response.results;

    // NOTE: do not sort the activities alphabetically
    // the API returns the list in the desired order
    /*
    data.activities.sort((a,b) => {
      return a.name < b.name ? -1 : (a.name > b.name ? 1 : 0);
    });
    */

    // make activityMap and activityDetailMap
    data.activityDetailsMap = {};
    data.activitiesMap = data.activities.reduce((result, item) => {
      result[item.uuid] = item;
      if (item.details_expanded.length) {
         data.activityDetailsMap = item.details_expanded.reduce((result, item) => {
           result[item.uuid] = item;
           return result;
         }, data.activityDetailsMap);
      }
      return result;
    }, {});

    return data.activities;
  };

  /**
   * Get the set of activities on the evaulation
   * GET: /api/v3/evaluations/:evaluation_uuid/activities/
   */
  bo.getEvaluationActivities = async (eval_uuid) => {
    const response = await API.evaluation_activities.get({evaluation_uuid: eval_uuid}).$promise;
    if (response.results.length) {
      const activeEvalActivities = response.results.filter(item => {
        return item.is_active;
      });

      // display most recently added first
      activeEvalActivities.sort((a,b) => {
        return new Date(b.created).getTime() - new Date(a.created).getTime();
      });

      const result = activeEvalActivities.reduce((result, item) => {
        result.push ({
          activity: bo.findActivityByUuid(item.activity),
          activityDetail: bo.findActivityDetailByUuid(item.activity_detail),
          evalActivityUuid: item.uuid
        });
        return result;
      }, []);

      data.savedEvaluationActivities = result;

      return result;
    }
  };

  /**
   * Add an activity to the evaulation
   * POST: /api/v3/evaluations/:evaluation_uuid/activities/
   */
  bo.saveActivityToEval = async (eval_uuid, activity_uuid, activityDetail_uuid) => {
    try {
      const response = await API.evaluation_activities.save({
        evaluation_uuid: eval_uuid,
        activity: activity_uuid,
        activity_detail: activityDetail_uuid
      }).$promise;
      return response;
    } catch (e) {
      console.error(`-- ERROR -- on evaluation activity POST`, e);
      throw e;
    }
  };

  /**
   * Edit an activity on the evaulation
   * PATCH: /api/v3/evaluations/:evaluation_uuid/activities/:uuid/
   */
  bo.editEvaluationActivity = async (eval_uuid, eval_activity_uuid) => {
    try {
      const response = await API.evaluation_activities.patch({
        evaluation_uuid: eval_uuid,
        uuid: eval_activity_uuid
      }).$promise;
      return response;
    } catch (e) {
      console.error(`-- ERROR -- on evaluation activity PATCH`, e);
      throw e;
    }
  };

  /**
   * Delete an activity from the evaulation
   * DELETE: /api/v3/evaluations/:evaluation_uuid/activities/:uuid/
   */
  bo.deleteEvaluationActivity = async (eval_uuid, eval_activity_uuid) => {
    try {
      const response = await API.evaluation_activities.delete({
        evaluation_uuid: eval_uuid,
        uuid: eval_activity_uuid
      }).$promise;
      return response;
    } catch (e) {
      console.error(`-- ERROR -- on evaluation activity DELETE`, e);
      throw e;
    }
  };

  // ===================
  // ===== HELPERS =====
  // ===================
  bo.findActivityByUuid = (uuid) => {
    return data.activitiesMap[uuid];
  };

  bo.findActivityDetailByUuid = (uuid) => {
    return data.activityDetailsMap[uuid];
  };

  // ------------------
  return bo;
};


PsychAssessmentBO.$inject = ['API', 'plRecordSPI'];
module.exports = PsychAssessmentBO;
