function plErrorMessageFactory() {
    const plErrorMessage = {};
    const _plErrorMessage = {};

    const messages = {
        'v3/evaluations' : [
            { code: 400, status: 'Duplicate exists.', msg: 'Duplicate evaluation already exists.'}, 
        ],
        'invoices/:uuid/retract': [
            // { code: 403, fxn: (err) => {
            //         return err.status; 
            //     }
            // },
            { code: 403, msg: 'This invoice has already been processed and cannot be retracted.'},
        ],
        'api/v1/records' : [
            { code: 400, status: 'Client must be one of the Appointment clients.', msg: 'Failed to save. Client is not part of this appointment. Please add student on your calendar.'}
        ], 
        'evaluations/:uuid': [
            { code: 400, status: 'The evaluation can\'t be completed because it has appointments in the future',
             msg: 'The evaluation can\'t be completed because it has appointments in the future'},
        ]
    };
    const messagesRegEx = {};

    _plErrorMessage.formRegEx = () => {
        let urlRegEx;
        for (let url in messages) {
            urlRegEx = url.replace(/\//g, '\\/').replace(/:uuid/g, '[a-z\\d-]*');
            messagesRegEx[urlRegEx] = messages[url];
        }
    };
    _plErrorMessage.formRegEx();

    _plErrorMessage.formMessage = (messageObj, err) => {
        if (messageObj.fxn) {
            return messageObj.fxn(err);
        } else if (messageObj.msg) {
            return messageObj.msg;
        }
        return null;
    };

    _plErrorMessage.getRouteMessage = (route, code, err) => {
        let index;
        for (let urlRegEx in messagesRegEx) {
            if (route.match(urlRegEx)) {
                index = -1;
                for (let ii = 0; ii < messagesRegEx[urlRegEx].length; ii++) {
                    const sameCode = messagesRegEx[urlRegEx][ii].code === code;
                    const statusExists = messagesRegEx[urlRegEx][ii].status === null;
                    const errorDataStatusExists = err.data && err.data.status && err.data.status[0]
                        && err.data.status[0] === messagesRegEx[urlRegEx][ii].status;
                    if ( sameCode &&
                    (statusExists || errorDataStatusExists)) {
                        index = ii;
                        break;
                    }
                }
                if (index > -1) {
                    return _plErrorMessage.formMessage(messagesRegEx[urlRegEx][index], err);
                }
            } else {
              console.log('-- API error', err)
              if (err && err.data && err.data.non_field_errors && err.data.non_field_errors.length) {
                const errors = err.data.non_field_errors;
                const message = errors.map((item)=>`* ${item}`).join('<br/>');
                return `${message}`;
              }
            }
        }
        return null;
    };

    plErrorMessage.get = (route, code, err) => {
        let message = _plErrorMessage.getRouteMessage(route, code, err);
        if (!message) {
            message = plErrorMessage.getGeneral(code, err);
        }
        return message;
    };

    plErrorMessage.getGeneral = (code, err) => {
        if (code === 403 || code === 401) {
            return 'Unauthorized. Please make sure you are logged in and have privileges to complete this action.';
        }
        return 'Oops, something went wrong. Please try again. If the problem persists please contact us for support.';
    }

    return plErrorMessage;
}
plErrorMessageFactory.$inject = [];
module.exports = plErrorMessageFactory;
