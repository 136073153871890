/**
 * Copied from Angular https://github.com/angular/angular.js/blob/d077966ff1ac18262f4615ff1a533db24d4432a7/src/Angular.js
 * TODO: Is this the best Idea? Should we be doing something else??
 * Return the DOM siblings between the first and last node in the given array.
 * @param {Array} array like object
 * @returns {Array} the inputted object or a jqLite collection containing the nodes
 */
function getBlockNodes(nodes) {
  // TODO(perf): update `nodes` instead of creating a new object?
  var node = nodes[0];
  var endNode = nodes[nodes.length - 1];
  var blockNodes;

  for (var i = 1; node !== endNode && (node = node.nextSibling); i++) {
    if (blockNodes || nodes[i] !== node) {
      if (!blockNodes) {
        blockNodes = $(Array.prototype.slice.call(nodes, 0, i));
      }
      blockNodes.push(node);
    }
  }

  return blockNodes || nodes;
}

/**
 * Notice, see https://github.com/angular/angular.js/blob/master/src/ng/directive/ngIf.js#L3
 * this code is a duplicate of ng-if.
 *
 * TODO: Is this the best Idea? Should we be doing something else??
 *
 * @param {[type]} $log             [description]
 * @param {[type]} $animate         [description]
 * @param {[type]} currentUserModel [description]
 */
var PermissionsDirective = function($log, $animate, currentUserModel) {
	return {
		multiElement: true,
		transclude: 'element',
		priority: 601,
		terminal: true,
		restrict: 'A',
		$$tlb: true,
		link: function($scope, $element, $attr, ctrl, $transclude) {
			var block, childScope, previousElements;
			var permExpression = $attr.permissions;

			$scope.$watch(function() {
				var exp = $attr.permissions;
				if (currentUserModel.user) {
					exp += JSON.stringify(currentUserModel.user.groups);
				}
				return exp;
			}, function permissionsWatchAction() {
				var value = $attr.permissions;
				var isPermitted = false;
				var matchGroups = /(\w+)/g;
				value.replace(matchGroups, function(match, group) {
					if (!isPermitted) {
						isPermitted = currentUserModel.user.isInGroup(group);
					}
				}, 'g');

				if (isPermitted) {
					if (!childScope) {
						$transclude(function(clone, newScope) {
							childScope = newScope;
							clone[clone.length++] = document.createComment(' end permissions: ' + $attr.permissions + ' ');
							// Note: We only need the first/last node of the cloned nodes.
							// However, we need to keep the reference to the jqlite wrapper as it might be changed later
							// by a directive with templateUrl when its template arrives.
							block = {
								clone: clone
							};
							$animate.enter(clone, $element.parent(), $element);
						});
					}
				} else {
					if (previousElements) {
						previousElements.remove();
						previousElements = null;
					}
					if (childScope) {
						childScope.$destroy();
						childScope = null;
					}
					if (block) {
						previousElements = getBlockNodes(block.clone);
						$animate.leave(previousElements).then(function() {
							previousElements = null;
						});
						block = null;
					}
				}
			});
		}
	};
};

PermissionsDirective.$inject = ['$log', '$animate', 'currentUserModel'];
module.exports = PermissionsDirective;