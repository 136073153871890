function plInputSwitchDirective(plInputService) {
    return {
        restrict: 'E',
        require: '?^form',
        scope: {
            model: '=',
            type: '@?',
            name: '@?',
            change: '&?',
            focus: '&?',
            blur: '&?',
            disabled: '=?',
        },
        templateUrl: '/core/pl-inputs/src/plInputSwitch/plInputSwitch.tpl.html',
        link: ($scope, $element, $attrs, formCtrl) => {
            plInputService.createNameIfNone($scope);
            plInputService.setupOnChange($scope, $attrs);
            plInputService.setupFocusAndBlur($scope, $attrs);
            plInputService.addInputToFormForValidation($scope, $element, formCtrl);

            $scope.getStateClasses = function() {
                return {
                    focused: $scope.focused,
                    active: $scope.model,
                    disabled: $scope.disabled,
                    mute: $scope.type === 'mute',
                    view: $scope.type === 'view',
                };
            };
        },
    };
}

plInputSwitchDirective.$inject = ['plInputService'];
module.exports = plInputSwitchDirective;
