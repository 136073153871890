module.exports = FlashcardPanelController;
FlashcardPanelController.$inject = ['$scope'];

function FlashcardPanelController ($scope, $state, localStorageService, FlashcardsWizardService) {
    const activityUpdateMode = 'update';
    const routName = 'app.store.activities.featured';
    let canGoToExit = false;
    $scope.confirmText = 'Changes have been made to this activity but not saved, would you like to exit?';
    $scope.showExitConfirmPopup = false;

    $scope.$on('$stateChangeStart', (event, route) => {
        if (route.name !== routName || canGoToExit || activityUpdateMode !== FlashcardsWizardService.mode) {
            return false;
        }
        event.preventDefault();
        $scope.showExitConfirmPopup = true;
        return false;
    });

    $scope.exitActivityCreator = () => {
        canGoToExit = true;
        if (activityUpdateMode === FlashcardsWizardService.mode)
            localStorageService.remove('FLASHCARD_' + FlashcardsWizardService.mode);
        $state.go(routName);
        $scope.showExitConfirmPopup = false;
    };
}