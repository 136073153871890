"use strict";
let Events = function($log) {
    /**************************************
     * EventQueue
     * @param repo
     * @param methodLatency
     * @constructor
     * ***************************************/
    class Events {
        static get CHILD_ADDED(){return 'child_added'};
        static get CHILD_MOVED(){return 'child_moved'};
        static get CHILD_REMOVED(){return 'child_removed'};
        static get CHILD_CHANGED(){return 'child_changed'};
        static get VALUE(){return 'value'};
        static get ONCOMPLETE(){return 'onComplete'};
        static all() {
            return [Events.CHILD_ADDED, Events.CHILD_MOVED, Events.CHILD_REMOVED,
                    Events.CHILD_CHANGED, Events.VALUE, Events.ONCOMPLETE];
        }
    }

    return Events;
};
Events.$inject = ['$log'];
module.exports = Events;
