function YouTubeDirective ($log, $interval, $window, YouTube, activityModel) {
    return {
        restrict: 'E',
        templateUrl: '/core/toys/app/src/toys/modules/activity/youtube/youtubeplayer.tpl.html',

        link: function(scope, element) {
            $log.debug('[YouTubeDirective] link!');
            let body = angular.element('.workspace');
            activityModel.foundationLoaded.then(function() {
                initialize();
            });

            scope.playerReady = false;
            var playerContainer = element.find('.youtube-player');

            var yt = new YouTube(element.find('.player')[0]);
            yt.on('playerReady', function() {
                scope.playerReady = true;
            });
            yt.on('stateChange', function(data) {
                if(data.state == 'ended') {
                    yt.seek(0);
                    scope.play(false);
                }
            });

            function resizeVideo () {
                scope.playerWidth = playerContainer.width() - 30;
                scope.playerHeight = scope.playerWidth * 9 / 16;
                if (scope.playerHeight > playerContainer.height() - 74) {
                    scope.playerHeight = playerContainer.height() - 74;
                    scope.playerWidth = scope.playerHeight * 16 / 9;
                }
                scope.marginTop = scope.playerHeight/2+22;
                scope.marginLeft = scope.playerWidth/2;
            }

            var videoId = null,
                updateTimeInterval = null,
                saveTimeToFBInterval = null;
            function initialize() {
                scope.progressPercentage = 0;
                scope.muted = false;
                scope.playing = false;
                scope.currentSecond = 0;

                updateTimeInterval = $interval(updateTime, 100);
                saveTimeToFBInterval = $interval(saveTimeToFB, 1000);

                scope.$watch(() => body.width(), resizeVideo);
                scope.$watch(() => sessionStorage.getItem('activeDrawer'), resizeVideo);
                resizeVideo();
                yt.loadLibrary().then(function() {
                    setupReferences();
                    refs.videoId.set(activityModel.activity.config);
                });

                if (activityModel.channel) {
                    activityModel.channel.bind('play', function() {
                        scope.play('playing');
                    });
                }
            }

            scope.$on('$destroy', function() {
                $(window).off('mousemove', onMoveScrubber);
                $(window).off('mouseup', onReleaseScrubber);
                $interval.cancel(updateTimeInterval);
                $interval.cancel(saveTimeToFBInterval);
                destroyReferences();
            });


            var isDraggingScrubber = false;

            scope.jumpTo = function(evt){
                var targetVideoTime = getScrubberPercentage(evt) / 100 * yt.getDuration();
                refs.time.set(targetVideoTime);
            };

            scope.onPressScrubber = function(evt) {
                $(window).on('mousemove', onMoveScrubber);
                $(window).on('mouseup', onReleaseScrubber);
                isDraggingScrubber = true;
                scope.progressPercentage = getScrubberPercentage(evt);
            };

            scope.play = function(state) {
                refs.state.child('playing').set(state);
                refs.time.set(yt.getCurrentTime());
            };
/*
//TODO: Fix the resolver and reinject currentUserModel before re-enabling this.
            $window.addEventListener('beforeunload', function() {
                if(currentUserModel.user.isClinician()) {
                    scope.play(false);
                    $log.debug('[YouTubeDirective] pausing video on clinician exit');
                }
            });
*/
            scope.mute = function(state) {
                refs.state.child('muted').set(state);
            };

            var refs = {};
            function setupReferences() {
                var base = 'activities/sessions/' + activityModel.getSessionId() + '/' + activityModel.activity.configId;

                refs.state = activityModel.getRef(base + '/state');
                refs.state.on('value', handleActivityData, handleActivityError);

                refs.videoId = activityModel.getRef(base + '/videoId');
                refs.videoId.on('value', handleVideoId, handleActivityError);

                refs.time = activityModel.getRef(base + '/time');
                refs.time.on('value', handleTimeActivityData, handleActivityError);
            }

            function destroyReferences() {
                if (refs) {
                    if (refs.state) {
                        refs.state.off('value', handleActivityData);
                    }
                    if (refs.videoId) {
                        refs.videoId.off('value', handleVideoId);
                    }
                    if (refs.time) {
                        refs.time.off('value', handleTimeActivityData);
                    }
                }
            }

            function handleActivityError(error) {
                $log.debug('[YouTubeDirective] activity ref load error:' + error.code);
            }

            function handleVideoId(snap) {
                if(!scope.playerReady) {
                    return false;
                }
                videoId = snap.val();
                if (videoId) {
                    yt.cueVideo(videoId);
                } else {
                    $log.debug('No video id found.');
                }
            }

            function handleActivityData(snap){
                if(!scope.playerReady) {
                    return false;
                }

                $log.debug('[YoutubeDirective] activity data loaded:' + snap.val());
                var sharedData = snap.val();

                if(!sharedData){
                    return;
                }

                //existing shared data has priority over the defaults
                scope.playing = sharedData.playing != undefined ? sharedData.playing : scope.playing;
                scope.muted = sharedData.muted != undefined ? sharedData.muted : scope.muted;

                var state = yt.getState();
                if(scope.playing) {
                    yt.play();
                } else if(scope.playing == false && state != 'videoQueued') {
                    // if the video is queued and you call pause, the video goes blank
                    yt.pause();
                }

                if(scope.muted) {
                    yt.mute(true);
                }else{
                    yt.mute(false);
                }
            }

            function timeDeltaInRange(serverTime, playerTime, range){
                return Math.abs(serverTime - playerTime) < range;
            }

            var RANGE = 2;
            function handleTimeActivityData(snap) {
                if(!scope.playerReady || snap.val() === null) {
                    return false;
                }

                var timeFromServer = snap.val();
                if (typeof timeFromServer === 'undefined') {
                    return;
                }

                var playerTime = yt.getCurrentTime();

                if(timeDeltaInRange(timeFromServer, playerTime, RANGE )){
                    return false;
                }

                scope.currentSecond = timeFromServer;

                //youtube api has a bug. When you seek to second x from second y, and immediately query
                // the currentsecond, it returns y, not x. Hack to fix is to start and then pause.
                if(!scope.playing){
                    yt.play();
                }

                yt.seek(scope.currentSecond);

                if(!scope.playing){
                    yt.pause();
                }
            }

            function saveTimeToFB () {
                if(!scope.playerReady){ return false; }
                if (scope.hasOwnProperty('timeRef')) {
                    refs.time.set(yt.getCurrentTime());
                }
            }

            function updateTime () {
                if (isDraggingScrubber) { return false; }
                if (!scope.playerReady) { return false; }

                var currentSeconds = 0;
                if(yt.getCurrentTime) {
                    currentSeconds = Math.round(yt.getCurrentTime());

                    var minute = Math.floor(currentSeconds / 60);
                    var seconds = currentSeconds % 60;
                    if (seconds < 10) {
                        seconds = '0' + seconds;
                    }

                    scope.currentTime = minute + ':' + seconds;

                    scope.progressPercentage = 100 * (currentSeconds / yt.getDuration());
                }

                setTotalTime();
            }

            function setTotalTime () {
                if(yt.getDuration) {
                    var durationSeconds = Math.round(yt.getDuration());

                    var minute = Math.floor(durationSeconds / 60);
                    var seconds = durationSeconds % 60;
                    if (seconds < 10){
                        seconds = '0' + seconds;
                    }

                    scope.totalTime = minute + ':' + seconds;
                }
            }

            function onMoveScrubber(evt) {
                if (isDraggingScrubber) {
                    scope.progressPercentage = getScrubberPercentage(evt);
                    scope.$apply();
                }
            }
            function onReleaseScrubber(evt) {
                $(window).off('mousemove', onMoveScrubber);
                $(window).off('mouseup', onReleaseScrubber);
                if (isDraggingScrubber) {
                    scope.jumpTo(evt);
                    isDraggingScrubber = false;
                }
            }

            function getScrubberPercentage (evt) {
                var target = element.find('.track')[0],
                    rect = target.getBoundingClientRect(),
                    relativeLeft = evt.pageX - rect.left;
                return Math.max(0, Math.min(100, relativeLeft / target.offsetWidth * 100));
            }
        }
    };
}

YouTubeDirective.$inject = ['$log', '$interval', '$window', 'YouTube', 'activityModel'];
module.exports = YouTubeDirective;

