var ActivityService = function($log, activityModel, guidService, flashCards) {

    this.startup = function(activity) {
        if(activity.id === activityModel.activity.activityId) {
            return activityModel.foundationLoaded;
        }

        this.reset();
        activityModel.setActivityId(activity.id)
                    .setConfigId(activity.slug)
                    .setType(activity.activity_type);

        if (activity.session_id) {
            //enable firebase
            activityModel.setSessionId(activity.session_id).setShared(true);
            $log.debug("[WorkspaceDirective] sharing enabled.");
        } else {
            //skip firebase
            activityModel.setSessionId(guidService.generateUUID()).setShared(false);
            $log.debug("[WorkspaceDirective] sharing disabled.");
        }
        activityModel.initialize(activity.descriptor ? activity : undefined);

        return activityModel.foundationLoaded;
    };

    this.reset = function() {
        flashCards.clean();
        activityModel.reset();
    };

    function init () {
        $log.debug('[ActivityService] Creating activity service');
    }
    init();

};

ActivityService.$inject = ['$log', 'activityModel', 'guidService', 'flashCards'];
module.exports = ActivityService;
