"use strict";
let Snapshot = function($log, Assert, DataNode) {
    class Snapshot {
        constructor(ref, data){
            this._ref = ref;
            this._data = DataNode.convert(data);
            $log.debug("ref: " + ref._id + " Creating snapshot with data: " + this._data);
        }

        exists() {
            return (this._data != null && this._data.getValue() != null);
        };

        val() {
            return _.cloneDeep(this.$convertChildren(this._data.getValue()));
        };

        exportVal() {
            return this.$convertChildren(this._data.exportVal());
        };

        child(childPath) {
            Assert(childPath, 'A childpath is required');

            let parts = childPath.split('/');
            let currData = this._data.getValue();
            //go down the child path and find the data
            _.forEach(parts, _.bind(function (item) {
                currData = currData[item];
            }, this));
            return new Snapshot(this._ref.child(childPath), currData);
        };
/*
        forEach(actionFn) {
            assert(actionFn, 'An actionFn is required');
            _.each(this._data, function (item) {
                return actionFn(item);
            })
        };
*/
        hasChild(childPath) {
            Assert(childPath, 'A childpath is required');

            let childSnap = this.child(childPath);
            return childSnap.exists();
        };

        hasChildren() {
            return this.exists();
        };

        get key() {
            return this._ref.key();
        };

        get name() {
            $log.debug('Firebase.name() being deprecated. Please use Firebase.key() instead.');
            return this.key;
        };

        numChildren() {
            let num = 0;
            //we only count objects not strings
            if (this._data.hasObjectValue()) {
                let data = this._data.getValue();
                num = _.keys(data).length;
            }
            return num;
        };

        get ref() {
            return this._ref;
        };

        getPriority() {
            return this._data.getPriority();
        };

        $convertChildren(node, includePriority) {
            let result = node;
            if (node instanceof DataNode) {
                result = this.$convertChildren(node.value);
            } else {
                let keys = _.keys(result);
                _.forOwn(result, function(val, key, obj) {
                    if (_.isObject(val)) {
                        obj[key] = this.$convertChildren(val);
                    }
                }, this);
            }
            return result;
        }


    }

    return Snapshot;
};
Snapshot.$inject = ['$log', 'Assert', 'DataNode'];
module.exports = Snapshot;