function plServicesFactory(plHttp, $q, plLodash) {
    const plServices = {};
    let _services = [];
    let _psychoeducationalCombined = {
        uuid: '__eval_aoc_combined',
        code: '__eval_aoc_combined',
        name: 'Psychoeducational Evaluation — Assessment',
    };
    let _serviceCategories = {
        evaluation_with_assessment: ['eval_ot', 'eval_slt', 'eval_mhp', 'screening_mhp', 'screening_ot', 'screening_slp', 'eval_aoc_3', 'eval_aoc_2', 'eval_aoc_1', _psychoeducationalCombined.code],
        evaluation_record_review: ['records_review_mhp', 'records_review_ot', 'records_review_slp'],
        therapy: ['direct_ot', 'direct_bmh', 'direct_slt', 'direct_sped', 'consultation_ot', 'consultation_slt', 'consultation_bmh'],
    };
    const _psychoeducationalCombinedCodes = ['eval_aoc_1', 'eval_aoc_2', 'eval_aoc_3'];
    let _psychoeducationalCombinedUuids = [];

    plServices.get = (params = {}) => {
        const deferred = $q.defer();

        if (_services && _services.length) {
            deferred.resolve(_services);
        } else {
            plHttp.get('services', params)
                .then((res) => {
                    _services = res.data.results;
                    deferred.resolve(res.data.results);
                }, (err) => {
                    deferred.reject(err);
                });
        }
        return deferred.promise;
    };

    plServices.getInfo = (uuid) => {
        if (uuid === _psychoeducationalCombined.uuid) {
            return _psychoeducationalCombined;
        }
        const index = plLodash.findIndex(_services, 'uuid', uuid);
        return (index > -1) ? _services[index] : null;
    };

    plServices.filterByServiceType = (services, serviceTypeUuid) => {
        if (!services) {
            return services;
        }
        return services.filter((service) => {
            return (service.service_type.uuid === serviceTypeUuid) ? true : false;
        });
    };

    plServices.filterByCategories = (services, categories = null) => {
        if (!categories || !categories.length || !services) {
            return services;
        }
        let pickFields = [];
        categories.forEach((category) => {
            pickFields = pickFields.concat(_serviceCategories[category]);
        });
        return services.filter((service) => {
            return (pickFields.indexOf(service.code) > -1) ? true : false;
        });
    };

    plServices.getPsychoeducationalUuids = (services) => {
        // Reset.
        _psychoeducationalCombinedUuids = [];
        if (!services) {
            return _psychoeducationalCombinedUuids;
        }
        services.forEach((service) => {
            if (_psychoeducationalCombinedCodes.indexOf(service.code) > -1) {
                _psychoeducationalCombinedUuids.push(service.uuid);
            }
        });
        return _psychoeducationalCombinedUuids;
    };

    plServices.combinePsychoeducational = (services) => {
        let combined = [];
        if (!services) {
            return combined;
        }
        let foundOne = false;
        const combinedCodes = _psychoeducationalCombinedCodes;
        services.forEach((service) => {
            if (combinedCodes.indexOf(service.code) > -1) {
                if (!foundOne) {
                    combined.push(Object.assign({}, service, {
                        uuid: _psychoeducationalCombined.code,
                        code: _psychoeducationalCombined.code,
                        name: _psychoeducationalCombined.name,
                    }));
                    foundOne = true;
                }
            } else {
                combined.push(service);
            }
        });
        return combined;
    };

    plServices.selectPsychoeducationalService = (services, selectedServiceUuid, areasOfConcern = []) => {
        if (!selectedServiceUuid || !services || !services.length) {
            return selectedServiceUuid;
        }
        const uuidsToCheck = plServices.getPsychoeducationalUuids(services).concat([_psychoeducationalCombined.uuid]);
        if (uuidsToCheck.indexOf(selectedServiceUuid) > -1) {
            let index = -1;
            if (areasOfConcern.length === 1) {
                index = plLodash.findIndex(services, 'code', 'eval_aoc_1');
            } else if (areasOfConcern.length === 2) {
                index = plLodash.findIndex(services, 'code', 'eval_aoc_2');
            } else if (areasOfConcern.length >= 3) {
                index = plLodash.findIndex(services, 'code', 'eval_aoc_3');
            }
            if (index > -1) {
                return services[index].uuid;
            }
        }
        return selectedServiceUuid;
    };

    plServices.getPsychoeducationalCombinedService = (services, selectedServiceUuid) => {
        if (!selectedServiceUuid || !services || !services.length) {
            return selectedServiceUuid;
        }
        const uuidsToCheck = plServices.getPsychoeducationalUuids(services);
        if (uuidsToCheck.indexOf(selectedServiceUuid) > -1) {
            return _psychoeducationalCombined.uuid;
        }
        return selectedServiceUuid;
    };

    plServices.formSelectOpts = (services, serviceTypeUuid = null, combinePsychoeducational = false, filterCategories = null) => {
        if (serviceTypeUuid) {
            services = plServices.filterByServiceType(services, serviceTypeUuid);
        }
        if (combinePsychoeducational) {
            services = plServices.combinePsychoeducational(services);
        }
        if (filterCategories) {
            services = plServices.filterByCategories(services, filterCategories);
        }
        return services.map((opt) => {
            return { value: opt.uuid, label: opt.name };
        });
    };

    plServices.getServiceCategory = (serviceUuid) => {
        if (!serviceUuid) {
            return null;
        }
        const service = plServices.getInfo(serviceUuid);
        if (!service) {
            return null;
        }
        for (let cat in _serviceCategories) {
            if (_serviceCategories[cat].indexOf(service.code) > -1) {
                return cat;
            }
        }
        return null;
    };

    plServices.maySelfRefer = (serviceUuid, providerUuid) => {
        const deferred = $q.defer();
        const service = plServices.getInfo(serviceUuid);
        if (!service) {
            deferred.resolve(false);
        } else {
            // Backend currently returns the `can_provide` field based on the logged in user,
            // so the `providerUuid` isn't needed right now. But it should become more configurable
            // later.
            deferred.resolve(service.can_provide);
        }
        return deferred.promise;
    };

    return plServices;
}
plServicesFactory.$inject = ['plHttp', '$q', 'plLodash'];
module.exports = plServicesFactory;
