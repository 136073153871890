function plRecordMetric($timeout, plOverlayDropdown, guidService) {

    return {
        restrict: 'E',
        scope: {
            metricPoint: '=',
            saveMetric: '&?',
        },
        replace: true,
        templateUrl: '/core/pl-records/src/plRecordMetric/plRecordMetric.tpl.html',
        link: ($scope, $element) => {
            $scope.show = {};
            $scope.showFullName = false;
            const instanceId = guidService.generateUUID();
            $scope.metricDescriptionId = `${instanceId}-metric-description`;
            const lastMetricPoint = {
                correct: 0,
                trials: 0,
            };

            $scope.incrementCorrect = () => {
                $scope.metricPoint.correct++;
                $scope.metricPoint.trials++;
                calculateAndSave();
            };

            $scope.incrementWrong = () => {
                $scope.metricPoint.trials++;
                calculateAndSave();
            };

            function calculatePercent() {
                validateTrials();
                $scope.metricPoint.percent_correct = ($scope.metricPoint.trials <=0) ? 0 :
                 (Math.floor($scope.metricPoint.correct / $scope.metricPoint.trials * 100));
            }

            function calculateAndSave() {
                calculatePercent();
                $scope.updateMetric();
            }

            $scope.updateMetric = () => {
                if ($scope.saveMetric) {
                    $scope.saveMetric()($scope.metricPoint);
                }
            };

            $scope.showMetricDetails = (event) => {
                let appendEle = '.name';
                plOverlayDropdown.createSingleDropdown($(event.currentTarget),
                     `#${$scope.metricDescriptionId}`,
                     { updateContent: true }
                     // { container : '.drawer-documentation' , appendTo : '.user-tabs-metrics'}
                    );
            }

            $scope.showInput = (key) => {
                $scope.show[key].input = true;
                $scope.show[key].text = false;
                focusInput(key);
            };

            function focusInput(key) {
                let classname = `metric-ratio-${key}`;
                const input = $element.find(`.${classname} input`);
                $timeout(() => {
                    input[0].focus();
                }, 250);
            }

            $scope.blurInput = (key) => {
                $scope.show[key].text = true;
                $scope.show[key].input = false;
                calculateAndSave();
            }

            $scope.keyInput = (evt, key) => {
                if (evt.keyCode === 13) {
                    $scope.show[key].text = true;
                    $scope.show[key].input = false;
                    calculateAndSave();
                }
            };

            function validateTrials() {
                // let valid = true;
                if ((!$scope.metricPoint.correct && $scope.metricPoint.correct !== 0) || $scope.metricPoint.correct < 0) {
                    // $scope.metricPoint.correct = 0;
                    // valid = false;
                    $scope.metricPoint.correct = lastMetricPoint.correct;
                }
                if ((!$scope.metricPoint.trials && ($scope.metricPoint.trials !== 0 || $scope.metricPoint.correct !== 0))
                 || $scope.metricPoint.trials < 0) {
                    // $scope.metricPoint.trials = 0;
                    // valid = false;
                    $scope.metricPoint.trials = lastMetricPoint.trials;
                }
                if ($scope.metricPoint.trials < $scope.metricPoint.correct) {
                    // $scope.metricPoint.trials = $scope.metricPoint.correct;
                    // valid = false;
                    $scope.metricPoint.trials = lastMetricPoint.trials;
                    $scope.metricPoint.correct = lastMetricPoint.correct;
                }
                // Update for next time.
                lastMetricPoint.trials = $scope.metricPoint.trials;
                lastMetricPoint.correct = $scope.metricPoint.correct;
            }

            function init() {
                $scope.show = {
                    correct: {
                        text: true,
                        input: false,
                    },
                    trials: {
                        text: true,
                        input: false,
                    },
                };
                lastMetricPoint.trials = $scope.metricPoint.trials;
                lastMetricPoint.correct = $scope.metricPoint.correct;
                calculatePercent();
            }
            init();
        },
    };
}

plRecordMetric.$inject = ['$timeout', 'plOverlayDropdown', 'guidService'];
module.exports = plRecordMetric;
