"use strict";

Takeover.$inject = ['$timeout'];

function Takeover($timeout) {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/core/pl-core/src/directives/takeover/takeover.tpl.html',
        scope: {
            activated: '=',
            dismissable: '=',
            animation: '@',
            classBody: '@'
        },
        link: function (scope, element, attrs, controller) {
            var modal = element.find('.modal');
            var lastOffset = 0;
            var defaultAnimation = 'standard';
            if (!scope.animation) {
                scope.animation = defaultAnimation;
            }

            var openTimeline = new TimelineLite();
            var closeTimeline = new TimelineLite();

            var dismiss = function() {
                scope.activated = false;
                scope.$apply();
            };
            var onKeyup = function(e) {
                if (e.keyCode === 27) { dismiss(); }
            };
            var backgroundClicked = function(e) {

                if (!scope.$parent.backgroundClickDisabled) {
                    if (e.target === this || $(e.target).hasClass('dismiss')) {
                        dismiss();
                    }
                }

            };

            scope.closePopup = function() {
                scope.activated = false;
            };

            scope.$watch('activated', function() {
                scope.activated ? openModal() : closeModal();
            });

            function getCenteredTransform() {
                var eleHeight = modal.height();
                var windowHeight = $(window).height();
                return (windowHeight - eleHeight) / 2;
            }

            function setOffsetToTranslate3d(offset) {
                return 'translate3d(0, ' + offset + 'px, 0)';
            }

            var timer = null;
            function updateTransform() {
                if (timer) $timeout.cancel(timer);

                timer = $timeout(function () {
                    var offset = getCenteredTransform();

                    if (lastOffset === offset || offset < 0) {
                        return;
                    }

                    lastOffset = offset;

                    modal.css({
                        transform: setOffsetToTranslate3d(offset)
                    });

                }, 50);
            }

            function showWithoutAnimation() {
                element.css({
                    opacity: 1
                });
                element.fadeIn();
                updateTransform();
            }

            function showWithAnimation() {
                element.show();
                var transfomr3d = setOffsetToTranslate3d(getCenteredTransform());
                closeTimeline.stop().clear();
                openTimeline
                    .clear()
                    .to(element, 0.25, { opacity: 1 })
                    .to(modal, 0.5, { transform:  transfomr3d}, 0)
                    .play(0);
            }

            function openModal() {
                if (scope.dismissable) {
                    $(document).on('keyup', onKeyup);
                    element.on('click', backgroundClicked);
                }
                modal.on('DOMSubtreeModified', updateTransform);
                if (scope.animation === defaultAnimation) {
                    showWithAnimation();
                    return;
                }
                showWithoutAnimation();
            }

            function closeWithAnimation() {
                openTimeline.stop().clear();
                closeTimeline
                    .clear()
                    .to(modal, 0.35, { transform: 'translate3d(0, -400px, 0)' })
                    .to(element, 0.2, { opacity: 0 })
                    .call(function() { this.hide(); }, [], element)
                    .play(0);
                scope.$emit('takeoverClosed');
            }

            function closeWithoutAnimation() {
                element.fadeOut();
                scope.$emit('takeoverClosed');
            }

            function closeModal() {
                if (scope.dismissable) {
                    $(document).off('keyup', onKeyup);
                    element.off('click', backgroundClicked);
                }
                modal.off("DOMSubtreeModified");
                if (scope.animation === defaultAnimation) {
                    closeWithAnimation();
                    return;
                }
                closeWithoutAnimation();
            }
        }
    };
}

module.exports = Takeover;
